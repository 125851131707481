import React from 'react';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

import { ToggleButton, ToggleButtonGroup, Tooltip } from 'common/components/material';
import { FilterExploreViewToggleButtonProps } from '../types';

const FilterExploreViewToggleButton: React.FC<FilterExploreViewToggleButtonProps> = ({
  showFlatList,
  disableColumnView,
  onToggleView,
}) => {
  return (
    <ToggleButtonGroup color="primary" value={showFlatList}>
      <ToggleButton
        value={false}
        size="small"
        onClick={() => onToggleView(false)}
        disabled={disableColumnView}
      >
        <Tooltip title="Column view">
          <ViewColumnOutlinedIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={true} size="small" onClick={() => onToggleView(true)}>
        <Tooltip title="List view">
          <ViewListOutlinedIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default FilterExploreViewToggleButton;
