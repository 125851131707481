import { useMemo } from 'react';
import { map, orderBy } from 'lodash';

import { UseQueryOptions } from 'common/types/Api.type';
import { useEvents, useQuery } from 'common/hooks';
import { AspiredSkill } from 'features/skills';
import { getProfileSkillsApi } from '../profiles.service';
import { useSyncProfileSkillsCache } from './useSyncProfileSkillsCache';
import { EndorsedSkill, SkillsResponseData } from 'features/skills';
import { EVENTS_TO_REFETCH_PROFILE_SKILLS } from '../profile-skills.utils';

export const useGetProfileSkills = (profileId: string, options: UseQueryOptions = {}) => {
  const queryResponse = useQuery(
    ['profile', 'skills', profileId],
    () => getProfileSkillsApi(profileId),
    {
      ...options,
    },
  );

  // It will sync the data from update operation and update the profile skills cache.
  useSyncProfileSkillsCache();

  const skills: SkillsResponseData = queryResponse.data?.data || {};

  const { endorsedSkills, aspiredSkills } = skills;

  const memoizedSkills = useMemo(() => {
    if (!endorsedSkills && !aspiredSkills) {
      return;
    }

    const endorsements = orderBy(
      map(endorsedSkills, ({ skill, score, isEndorsedByLoggedInUser }: EndorsedSkill) => ({
        ...skill,
        score,
        isEndorsedByLoggedInUser,
      })),
      ['name'],
      ['asc'],
    );

    const aspirations = orderBy(
      map(aspiredSkills, (skill: AspiredSkill) => ({
        ...skill,
        isAspiredSkill: true,
      })),
      ['name'],
      ['asc'],
    );

    return {
      endorsedSkills: endorsements,
      aspiredSkills: aspirations,
    };
  }, [endorsedSkills, aspiredSkills]);

  useEvents(EVENTS_TO_REFETCH_PROFILE_SKILLS, () => {
    queryResponse.refetch();
  });

  return {
    ...queryResponse,
    data: memoizedSkills,
  };
};
