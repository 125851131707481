import { useCountdown, useMutation } from 'common/hooks';

import { sendCompleteAccountSetupLinkApi } from '../accounts.service';
import { EMAIL_RESEND_DELAY } from '../accounts.constants';

export const useSendCompleteAccountSetupLink = () => {
  const { countdownDisplay, countdownTime, setCountdownTime } = useCountdown();
  const mutationResponse = useMutation(sendCompleteAccountSetupLinkApi, {
    showSuccessSnackbar: true,
    onSuccess: () => {
      setCountdownTime(EMAIL_RESEND_DELAY);
    },
  });
  return {
    ...mutationResponse,
    countdownDisplay,
    countdownTime,
  };
};
