import { PaletteMode } from '@mui/material';
import { FileSubmitData } from 'common/types/File.type';
import { titleCase } from './app.utils';

// Transforms file for the profile or organization image uploader
export const transformFileToFormData = (data: FileSubmitData, id: string) => {
  let file: File | undefined;

  if (data.file && data.file.length > 0) {
    file = data.file[0];
  }

  const formData = new FormData();
  formData.append('id', id);
  if (file) {
    formData.append('file', file);
  }

  return formData;
};

export const openDownloadPrompt = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getThemeImagePath = (type: string, mode: PaletteMode, filename: string) => {
  const modeFolder = titleCase(mode);
  return `/images/Theme/${type}/${modeFolder}/${filename}`;
};
