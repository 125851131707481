import { RefObject, useEffect } from 'react';
import { Viewer as ToastUIViewer } from '@toast-ui/react-editor';
import { truncateMarkdown } from 'libs/markdown-truncate';

export const useCXViewerTruncateMarkdown = (
  value: string,
  ref: RefObject<ToastUIViewer>,
  isExpanded: boolean,
  maxLength?: number,
) => {
  useEffect(() => {
    let transformedValue = truncateMarkdown(value || '', {
      limit: isExpanded ? null : maxLength,
      ellipsis: !isExpanded,
    });

    // TODO This is temporary fix, until we find out any alternative way to truncate the markdown + add ellipsis after truncation.
    // Remove the incorrect ****** from the response as the `markdown-truncate` library sometimes attaches these stars when there is some markdown elements in the text:
    transformedValue = isExpanded
      ? transformedValue
      : transformTruncatedText(transformedValue);
    ref?.current?.getInstance().setMarkdown(transformedValue);
  }, [value, ref, isExpanded, maxLength]);
};

const transformTruncatedText = (input: string) => {
  const regex = /\*{3,}(\.\.\.)?$/g;
  return input.replace(regex, '...');
};
