import { useEffect, useState } from 'react';
import { Box } from 'common/components/material';

import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { HierarchicalExplorerItemAction } from 'features/data-explorer';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { useFilterState } from 'features/explore/hooks';
import { ActiveFilters, getFilterCount } from 'features/search';
import { FILTER_EXPLORER_CONFIG } from 'features/data-explorer/filter-explorer.config';
import { ExplorerSelectorDropdownComponent } from './components/explorer-selector-dropdown.component';
import { Backdrop } from '@mui/material';
import { FormOperationsEnum } from 'common/enum/Form.enum';
import { ExploreSelectorButtonComponent } from './components/explore-selector-button.component';

/**
 * InitialFilters: These are filters responsible for selecting or clearing all the filters.
 * onChange: With this we can inform its parent container what are the selected items along with the type.
 */
export const ExploreSelectorComponent: React.FC<{
  contentType: ExploreTabEnum;
  type: ExplorerDataTypeEnum;
  initialFilters?: ActiveFilters;
  onChange: (activeFilters: ActiveFilters, type: ExplorerDataTypeEnum) => void;
}> = ({ contentType, type, initialFilters = {}, onChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const config = FILTER_EXPLORER_CONFIG[type];

  const { activeFilters, activeFilterMap, toggleFilterSelection } = useFilterState(
    type,
    initialFilters,
  );

  const onItemAction = (action: HierarchicalExplorerItemAction) => {
    if (action.actionType !== FormOperationsEnum.EXPAND) {
      const activeFilters = toggleFilterSelection(
        action.data,
        action.value,
        action.mapping,
        type,
      );
      onChange(activeFilters as ActiveFilters, type);
    }
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleEscapeKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  const selectedItemsCount = getFilterCount(activeFilters, type);
  const buttonLabel = selectedItemsCount ? config.header : `+ ${config.header}`;

  return (
    <Box position="relative">
      <ExploreSelectorButtonComponent
        key={type}
        value={type}
        label={buttonLabel}
        isActive={dropdownOpen}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        badgeLabel={!!selectedItemsCount ? `${selectedItemsCount}` : ''}
      />
      {dropdownOpen && (
        <ExplorerSelectorDropdownComponent
          contentType={contentType}
          type={type}
          onItemAction={onItemAction}
          activeFilterMap={activeFilterMap}
        />
      )}
      <Backdrop open={dropdownOpen} onClick={closeDropdown} sx={{ zIndex: 9997 }} invisible />
    </Box>
  );
};
