import React from 'react';

import DesktopApplicationBar from './DesktopApplicationBar';
import MobileApplicationBar from './MobileApplicationBar';

import useView from 'common/hooks/useView';

type HeaderProps = {
  showAllMenuItems?: boolean;
  isLoading?: boolean;
};

const ResponsiveHeaderBar: React.FC<HeaderProps> = ({ showAllMenuItems, isLoading }) => {
  const { isDesktopView } = useView();

  return isDesktopView ? (
    <DesktopApplicationBar showAllMenuItems={showAllMenuItems} isLoading={isLoading} />
  ) : (
    <MobileApplicationBar showAllMenuItems={showAllMenuItems} isLoading={isLoading} />
  );
};

export default ResponsiveHeaderBar;
