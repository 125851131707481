import { useQuery, useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { ErrorResponse, UseQueryOptions } from 'common/types/Api.type';
import queryClient from 'common/utils/QueryClient';
import { transformCodeVerificationError } from 'features/accounts/accounts.utils';
import { get } from 'lodash';
import { verifyAccountApi } from '../accounts.service';

export const useVerifyAccount = (secretCode: string, opts?: UseQueryOptions) => {
  const { goTo } = useRouter();
  return useQuery(['verifyAccount', secretCode], () => verifyAccountApi(secretCode), {
    showSuccessSnackbar: true,
    showErrorSnackbar: true,
    showValidationErrorSnackbar: true,
    onError: (error: ErrorResponse) => {
      const transformedError = transformCodeVerificationError(error);
      // We'll get an email in the error message if the code is expired
      const email = get(transformedError, 'email');

      if (email) {
        // Show the expired code screen
        goTo({
          pathname: AppRoutesEnum.ACCOUNTS_VERIFY_EMAIL,
          search: {
            email,
            isExpired: true,
          },
        });
      } else {
        goTo(AppRoutesEnum.ACCOUNTS_LOGIN);
      }
    },
    onSettled: () => {
      queryClient.clear();
    },
    ...opts,
  });
};
