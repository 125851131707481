import { styled } from '@mui/material';
import Button from 'common/components/material/Button';
import Card from 'common/components/material/Card';
import Chip from 'common/components/material/Chip';

export const ExploreOrganizationEventCardBase = styled(Card, {
  shouldForwardProp: (prop) => {
    return prop !== 'fullWidth';
  },
})<{
  fullWidth?: boolean;
}>(({ fullWidth, theme }) => ({
  width: fullWidth ? '100%' : '15rem',
  padding: '12px',
  display: 'flex',
  /*
   * This has the same effect of using `height: 100`, without
   * requiring the parent component to have an explicit height
   * set.
   *
   * Note 1: when using Flexbox, components rarely get their
   * height or width explicity set, so using `height: 100%`
   * will not work as expected.
   *
   * Note 2: `alignItems: 'stretch'` is the default, so could
   * easily be omitted; however, I would not have an anchor
   * point to attach this piece of documentation to, so
   * here it goes.
   */
  alignItems: 'stretch',
  flexDirection: 'column',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.LinkContainer.StrokeDefault}`,
}));

export const StyledOrganizationEventTypeChip = styled(Chip, {
  shouldForwardProp: (prop) => {
    return prop !== 'borderColor' && prop !== 'background' && prop !== 'showBorder';
  },
})<{
  borderColor?: string;
  background?: string;
  showBorder?: boolean;
}>(({ background, borderColor, showBorder, theme }) => ({
  alignSelf: 'flex-start',
  borderRadius: '6px',
  border: showBorder ? `1px solid ${borderColor}` : '',
  height: '22px',
  fontSize: '12px',
  paddingLeft: '4px',
  background: background,
  '& .MuiChip-label': {
    color: theme.palette.EventTagChip.Text,
    paddingInline: 4,
    fontWeight: 500,
  },
}));

export const OrganizationEventButton = styled(Button)(({ theme }) => ({
  btntype: 'tertiary',
  borderRadius: '8px',
  fontSize: theme.typography.button2.fontSize,
  fontWeight: 500,
}));
