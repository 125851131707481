import { StyledSearchOption } from 'common/components/navigation/styles';
import { ListItemText } from 'common/components/material/ListItemButton';
import { ProfileSearchOptionItem } from './profile-search-option-item.component';
import { IProfileSearchSuggestion } from 'features/profiles/interfaces/profile-search-suggestion.interface';
import { useTheme } from '@mui/material';

export const ProfileSearchOption: React.FC<{
  profile: IProfileSearchSuggestion;
  searchTerm?: string;
  active?: boolean;
  onClick?: (profile: IProfileSearchSuggestion) => void;
}> = ({ profile, searchTerm, active, onClick }) => {
  const { palette } = useTheme();

  return (
    <StyledSearchOption
      key={profile.id}
      onMouseDown={() => onClick && onClick(profile)}
      dense={true}
      sx={{
        paddingY: '1px',
        backgroundColor: active ? palette.DropdownList.Hover : undefined,
      }}
    >
      <ListItemText>
        <ProfileSearchOptionItem profile={profile} searchTerm={searchTerm} />
      </ListItemText>
    </StyledSearchOption>
  );
};
