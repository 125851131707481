import { useQuery } from 'common/hooks';
import { getRecommendedSkillsApi } from '../services/skills.service';

export const useGetRecommendedSkills = () => {
  const queryResponse = useQuery(['recommendedSkills'], getRecommendedSkillsApi);
  return {
    ...queryResponse,
    data: queryResponse.data?.data?.skills,
  };
};
