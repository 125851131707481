import { getOrganizationRoleApi } from '../organization-role.service';
import { UseQueryOptions } from 'common/types/Api.type';
import { useEvents, useQuery } from 'common/hooks';
import { transformOrganizationRoles } from '../utils';

export const useGetOrganizationRole = (
  profileId: string = '',
  organizationId: string = '',
  options: UseQueryOptions = {},
) => {
  const queryKey = [organizationId, profileId, 'organizationRoles'];

  const queryResponse = useQuery(
    queryKey,
    () => getOrganizationRoleApi(profileId, organizationId),
    {
      enabled: !!profileId,
      select: (response) => {
        return {
          ...response,
          data: {
            currentOrganizationRoles: transformOrganizationRoles(
              response.data.currentOrganizationRoles,
            ),
            previousOrganizationRoles: transformOrganizationRoles(
              response.data.previousOrganizationRoles,
            ),
          },
        };
      },
      ...options,
    },
  );

  useEvents(['OrganizationRoleUpdated', 'OrganizationRoleDeleted'], () =>
    queryResponse.refetch(),
  );

  return {
    ...queryResponse,
    data: queryResponse.data?.data || {},
  };
};
