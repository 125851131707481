import React from 'react';

import MUIBadge, { BadgeProps as MUIBadgeProps } from '@mui/material/Badge';

export type BadgeProps = MUIBadgeProps;

const Badge: React.FC<BadgeProps> = React.forwardRef((props, ref) => {
  const { children, ...badgeProps } = props;

  return (
    <MUIBadge {...badgeProps} ref={ref}>
      {children}
    </MUIBadge>
  );
});

export default Badge;
