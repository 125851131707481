import { useMemo } from 'react';
import * as yup from 'yup';

import { SkillSchema } from 'features/skills';
import { SKILLS_MAX_LIMIT } from 'common/constants/common.constants';
import { TagSchema } from 'features/tags/schemas/tag.schema';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

export const useOpportunityFormSchema = () => {
  const { canWrite: canAddSkills } = useFeatureAccess(FeaturesEnum.SKILLS);

  const skillYupCondition = yup
    .array()
    .of(SkillSchema)
    .label('skills')
    .max(SKILLS_MAX_LIMIT, `Max ${SKILLS_MAX_LIMIT} skills allowed`)
    .min(1, 'At least 1 skill is required')
    .required('At least 1 skill is required');

  const schemaShape = {
    title: yup.string().label('title').required('Title is required'),
    tags: yup.array().of(TagSchema).label('tags'),
    manager: yup
      .array()
      .label('manager')
      .min(1, 'Manager is required')
      .required('Manager is required'),
  } as Record<string, any>;

  if (canAddSkills) {
    schemaShape['skills'] = skillYupCondition;
  }

  return useMemo(() => {
    return yup.object(schemaShape).required();
  }, [canAddSkills]);
};
