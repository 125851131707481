import React from 'react';

import { DEFAULT_APP_BAR_HEIGHT } from 'common/components/header/ApplicationBar';
import { useVirtualKeyboardVisibility } from 'common/hooks';
import { Box } from 'common/components/material';

type StickyHeaderContainerProps = {
  children: React.ReactNode;
  px?: number;
  pt?: number;
  pb?: number;
  topOffset?: number;
  appBarHeight?: number;
  fullWidth?: boolean;
  disablePointerEvents?: boolean;
  bgColor?: string;
  direction?: string;
  display?: string;
  justifyContent?: string;
};

const StickyHeaderContainer: React.FC<StickyHeaderContainerProps> = ({
  children,
  px,
  pt,
  pb,
  topOffset = 0,
  appBarHeight,
  disablePointerEvents = false,
  bgColor,
  direction,
  display,
  justifyContent,
}) => {
  const isVirtualKeyboardOpen = useVirtualKeyboardVisibility();

  const padding = px && px / 2;
  const rawAppBarHeightValue = +DEFAULT_APP_BAR_HEIGHT.replace('rem', '');
  const defaultTopValue = `${(appBarHeight ?? rawAppBarHeightValue) + topOffset}rem`;

  return (
    <Box
      sx={{
        pointerEvents: disablePointerEvents ? 'none' : 'all',
        position: 'sticky',
        zIndex: 100,
        paddingLeft: `${padding}rem`,
        paddingRight: `${padding}rem`,
        paddingTop: `${pt}rem`,
        paddingBottom: `${pb}rem`,
        top: isVirtualKeyboardOpen ? 0 : defaultTopValue,
        background: bgColor || '',
        flexDirection: direction || null,
        display: display || null,
        justifyContent: justifyContent || 'initial',
      }}
    >
      {children}
    </Box>
  );
};

export default StickyHeaderContainer;
