import { styled } from '@mui/material/styles';
import { Button } from 'common/components/material';
import { getCommonEditorContentStyles } from './cx-editor-common.styles';

export const CXViewerStyledContainer = styled('div')(({ theme }) => ({
  ...getCommonEditorContentStyles(theme),
  zIndex: 0,
  '& .toastui-editor-contents': {
    fontFamily: theme.typography.fontFamily,
    fontWeight: '400',
    fontSize: '0.875rem',
    color: theme.palette.Text.Paragraph,
    position: 'relative',
    zIndex: 0,
  },
  '& .toastui-editor-contents p': {
    color: theme.palette.Text.Paragraph,
  },
  '& .toastui-editor-contents blockquote': {
    borderColor: theme.palette.Text.Paragraph,
    color: theme.palette.Text.Paragraph,
  },
  '& .toastui-editor-contents blockquote p': {
    color: theme.palette.Text.Paragraph,
  },
  '& .toastui-editor-contents ul > li::before': {
    marginTop: '9px',
    backgroundColor: theme.palette.Text.Paragraph,
  },
  '& .toastui-editor-contents ol > li::before': {
    color: theme.palette.Text.Paragraph,
  },
}));

export const CXViewerExpandBtn = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  padding: 0,
  color: theme.palette.Buttons.Tertiary.LabelDefault,
  '&:hover': {
    backgroundColor: theme.palette.Buttons.Tertiary.BgDefault,
  },
}));
