import { useParams, useSearchParams } from 'react-router-dom';

import {
  getMonthIndexFromString,
} from 'common/utils/date-time.utils';
import { useGetOrganizationSummaryEvents } from '../hooks';
import OrganizationEventsSideBarPanel from './organization-event-sidebar-panel';

const OrganizationEventsSideBarPanelWrapper = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();

  /*
    useParams hook needs to be rendered lower in the ReactTree than the route defining the route match params and in here it's not.
    useParams() returns the following object in this case
     {
      *:'month/Dec/2023,
      organizationName:'ion',
     }
    and thus destructuring month, year from useParams() returns undefined 
  */

  const urlParams = params['*']?.split('/') ?? [];
  const eventFilterType = urlParams[0];
  const selectedDateParam = searchParams.get('selectedDate');

  let month = new Date().getMonth(),
    year = new Date().getFullYear();

  if (selectedDateParam) {
    const selectedDate = new Date(selectedDateParam);
    month = selectedDate.getMonth();
    year = selectedDate.getFullYear();
  } else if (urlParams.length > 2) {
    month = getMonthIndexFromString(urlParams[2]);
    year = parseInt(urlParams[3]);
  }

  const { data: organizationEventData, isLoading } = useGetOrganizationSummaryEvents({
    startDate: new Date(year, month, 1),
    endDate: new Date(year, month + 1, 0),
    eventFilterType,
    eventTypes: searchParams.get('eventType')?.split(','),
  });

  return (
    <OrganizationEventsSideBarPanel
      organizationEventData={organizationEventData}
      isLoading={isLoading}
    />
  );
};

export default OrganizationEventsSideBarPanelWrapper;
