import { HTML_TAG_REMOVE_REGEX, MARKDOWN_HYPERLINK_REGEX } from './toast-ui.constants';

/**
 * In this function we are performing two operations:
 * 1. We are removing all the html tags from the markdown value
 * 2. We are adding protocols to all the hyperlinks where user has missed to add it.
 */
export const getTransformedValue = (value: string) => {
  const santizedValue = value.replace(HTML_TAG_REMOVE_REGEX, '');
  return santizedValue.replace(MARKDOWN_HYPERLINK_REGEX, (_, p1, p2) => {
    return `[${p1}](https://${p2})`;
  });
};
