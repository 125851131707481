import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material';

import Button, { ButtonProps } from 'common/components/material/Button';

type AddRemoveButtonProps = ButtonProps & {
  isAddOperation?: boolean;
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => {
    return prop !== 'isAddOperation';
  },
})<AddRemoveButtonProps>(({ theme, isAddOperation }) => ({
  height: 26,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 500,
  minWidth: 'unset',
  color: isAddOperation
    ? theme.palette.Buttons.Tertiary.LabelDefault
    : theme.palette.SpecialButtons.Delete.LabelDefault,
  textTransform: 'capitalize',
  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  '&:hover': {
    color: isAddOperation
      ? theme.palette.Buttons.Tertiary.LabelHover
      : theme.palette.SpecialButtons.Delete.LabelHover,
    backgroundColor: isAddOperation
      ? theme.palette.Buttons.Tertiary.BgHover
      : theme.palette.SpecialButtons.Delete.BgHover,
  },
}));

const AddRemoveButton: React.FC<AddRemoveButtonProps> = ({
  isAddOperation,
  onClick,
  ...props
}) => {
  const AddRemoveIcon = isAddOperation ? <AddIcon /> : <RemoveIcon />;

  return (
    <StyledButton
      variant="text"
      onClick={onClick}
      size="small"
      startIcon={AddRemoveIcon}
      isAddOperation={isAddOperation}
      {...props}
    >
      {isAddOperation ? 'Add' : 'Remove'}
    </StyledButton>
  );
};

export default AddRemoveButton;
