import { useQuery, useSession } from 'common/hooks';

import { fetchSearchCategoriesResultsCount } from '../search.service';
import { useSearchParams } from 'react-router-dom';
import { mapSearchParamsObject } from '../search.utils';
import { UseQueryOptions } from 'react-query';

export const useGetSearchResultsCount = (options?: UseQueryOptions) => {
  const { account: user } = useSession();
  const [searchParams] = useSearchParams();

  const query = searchParams.get('query') || '';
  const searchFilters = mapSearchParamsObject(searchParams, false, [
    'columns',
    'pinnedl',
    'pinnedr',
    /**
     * Ideally, we would include 'sort' in the list of omitted params to avoid
     * refetching the results count when we change the sort on the Data Grid view.
     * For some reason we're getting an issue where the MUI grid virtual scrollbar loses
     * its ref and gives us an error trying to reference its scrollTop on a null value when
     * we scroll down and then try to sort.
     *
     * https://github.com/mui/mui-x/issues/13301
     *
     * Letting the counts get refetched upon sort somehow prevents this. Still haven't
     * found out why. - Alister
     */
    // 'sort',
  ]);

  const queryResponse = useQuery(
    ['search-count', user?.organizationId, query || '', searchFilters],
    () =>
      fetchSearchCategoriesResultsCount({
        term: query,
        ...searchFilters,
      }),
    { ...options, enabled: (options?.enabled ?? true) && !!user?.organizationId },
  );

  return {
    ...queryResponse,
    data: queryResponse?.data?.data,
    isLoading: queryResponse.isLoading,
  };
};
