import { styled, useTheme } from '@mui/material';
import { VerticalScrollableContainer } from 'common/components/container';
import { CircularProgress } from 'common/components/material';
import { Stack, Typography } from 'common/components/material';

import React from 'react';
import InfiniteScrollContainer from '../InfiniteScrollContainer';
import NotificationContainer from './NotificationContainer';
import { NotificationDrawerProps, useGetRecentNotifications } from 'features/notifications';

export const INIT_PROPS: NotificationDrawerProps = {
  onClose: () => {},
};
const FlexContainer = styled('div')({
  display: 'flex',
});

const NotificationDrawer: React.FC<NotificationDrawerProps> = () => {
  const { palette } = useTheme();

  return (
    <>
      <VerticalScrollableContainer
        disableGutters
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: ' column',
          justifyContent: 'space-between',
        }}
      >
        <Stack>
          <InfiniteScrollContainer
            infiniteApiHook={useGetRecentNotifications}
            InfiniteListContainer={NotificationContainer}
            LoaderComponent={() => (
              <CircularProgress sx={{ alignSelf: 'center' }} size={100} />
            )}
            showFooter={false}
          />
        </Stack>
        <Stack alignItems="center">
          <FlexContainer>
            <Typography
              variant="body4"
              color={palette.Text.SubHeadline}
              sx={{ paddingBottom: 2 }}
            >
              You’re all caught up!
            </Typography>
          </FlexContainer>
        </Stack>
      </VerticalScrollableContainer>
    </>
  );
};

export default NotificationDrawer;
