import { RatingScoreMapping } from '../skills.constants';
import { RatingsEnum } from '../skills.enums';
import { Rating } from '../types';

export const getAllRatings = (): Rating[] => {
  return [
    { label: RatingsEnum.NOVICE, score: RatingScoreMapping[RatingsEnum.NOVICE] },
    { label: RatingsEnum.INTERMEDIATE, score: RatingScoreMapping[RatingsEnum.INTERMEDIATE] },
    { label: RatingsEnum.ADVANCED, score: RatingScoreMapping[RatingsEnum.ADVANCED] },
    { label: RatingsEnum.EXPERT, score: RatingScoreMapping[RatingsEnum.EXPERT] },
  ];
};

export const getRatingLabel = (rating?: number) => {
  if (rating === undefined) return 'Add Rating';
  if (rating <= RatingScoreMapping[RatingsEnum.NOVICE]) {
    return RatingsEnum.NOVICE;
  }
  if (rating <= RatingScoreMapping[RatingsEnum.INTERMEDIATE]) {
    return RatingsEnum.INTERMEDIATE;
  }
  if (rating <= RatingScoreMapping[RatingsEnum.ADVANCED]) {
    return RatingsEnum.ADVANCED;
  }
  return RatingsEnum.EXPERT;
};
