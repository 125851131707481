import axios from 'common/utils/axios';
import { UpdateCompanyContract } from 'features/admin/types';
import { ValidateVisibleEmailRequestParams } from './types';

const companiesApi = axios('companies');

export const verifyCompanyWebsiteApi = (website: string) => {
  return companiesApi.get('verify-website', { params: { website } });
};

export const verifyCompanySubUrlApi = (subUrl: string) => {
  return companiesApi.get('verify-sub-url', { params: { subUrl } });
};

export const getCompanyRolesApi = () => {
  return companiesApi.get('roles');
};

export const validateVisibleEmailApi = (params: ValidateVisibleEmailRequestParams) => {
  return companiesApi.get('validate-visible-email', { params });
};

export const isHrisManagedOrganization = () => {
  return companiesApi.get('is-hris-managed');
};

export const verifyCompanyNameApi = (name: string) => {
  return companiesApi.get('verify-name', { params: { name } });
};

export const updateCompanyApi = ({ id, ...data }: UpdateCompanyContract) => {
  return companiesApi.put(id, data);
};

export const uploadLogoApi = (data: FormData) => {
  const id = data.get('id');
  const hasFile = data.get('file');

  if (!hasFile) {
    return companiesApi.delete(`${id}/logo`);
  }

  data.delete('id');
  return companiesApi.put(`${id}/logo`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
