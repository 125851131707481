import { values } from 'lodash';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import {
  ExploreOrganizationTabType,
  ExploreOrganizationTabsConfig,
} from 'features/org-root/config/explore-organization-tabs.config';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { FilterMenuItem } from './types/filter-menu-item.type';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { ContentTypeFiltersMenuItemConfig } from './types/filter-explorer-props.type';

export const COMING_SOON_TEXT = {
  alumni: 'Explore your company’s full community of current and former employees.',
  behaviors: 'Understand yourself. Understand others. Help others understand you.',
  projects: 'Connect dots and discover more about your company’s initiatives.',
  reviews: 'A single source of truth for detailed performance insights.',
};

export const HEADING_TEXT_INVITE_USERS = "Let's get started";
export const SUBHEADING_TEXT_INVITE_USERS = 'Invite others to join your organization';
export const HEADING_TEXT = 'No results found';
export const SUBHEADING_TEXT = 'Please try something else.';
export const NO_RESULTS_TEXT = 'No results found.';
export const NO_FILTERS_TEXT = 'No filters available.';

const ASPIRATIONS_CHILDREN_FILTER = [
  {
    key: ExplorerDataTypeEnum.ASPIRATION_CITY,
    label: 'City',
  },
  {
    key: ExplorerDataTypeEnum.ASPIRATION_SKILL,
    label: 'Skills',
  },
  {
    key: ExplorerDataTypeEnum.ASPIRATION_DEPARTMENT,
    label: 'Department',
  },
  {
    key: ExplorerDataTypeEnum.ASPIRATION_DIVISION,
    label: 'Division',
  },
  {
    key: ExplorerDataTypeEnum.ASPIRATION_TEAM,
    label: 'Team',
  },
  {
    key: ExplorerDataTypeEnum.ASPIRATION_PRODUCT,
    label: 'Product',
  },
] as FilterMenuItem[];

const ASPIRATION_FILTER = {
  key: ExplorerDataTypeEnum.ASPIRATION,
  label: 'Aspirations',
  children: ASPIRATIONS_CHILDREN_FILTER,
} as FilterMenuItem;

export const GLOBAL_FILTER_MENU_ITEMS = [
  {
    key: ExplorerDataTypeEnum.WORK_LOCATION,
    label: 'Office Location',
  },
  {
    key: ExplorerDataTypeEnum.DEPARTMENT,
    label: 'Department',
  },
  {
    key: ExplorerDataTypeEnum.DIVISION,
    label: 'Division',
  },
  {
    key: ExplorerDataTypeEnum.TEAM,
    label: 'Team',
  },
  {
    key: ExplorerDataTypeEnum.PRODUCT,
    label: 'Product',
  },
  {
    key: ExplorerDataTypeEnum.GROUP,
    label: 'Groups',
  },
  {
    key: ExplorerDataTypeEnum.REPORTS_TO,
    label: 'Reporting Line',
  },
  {
    key: ExplorerDataTypeEnum.SKILL,
    label: 'Skills',
  },
  {
    key: ExplorerDataTypeEnum.PRIOR_COMPANIES,
    label: 'Prior Companies',
  },
  ASPIRATION_FILTER,
  {
    key: ExplorerDataTypeEnum.EVENT_TYPE,
    label: 'Event Type',
  },
  {
    key: ExplorerDataTypeEnum.WIN_PERIOD,
    label: 'Win Period',
  },
];

export const EXPLORE_TABS: ExploreOrganizationTabType[] = [
  ExploreOrganizationTabsConfig.PEOPLE,
  ExploreOrganizationTabsConfig.OPPORTUNITIES,
  ExploreOrganizationTabsConfig.PRAISE,
  ExploreOrganizationTabsConfig.COMPANY_UPDATES,
  ExploreOrganizationTabsConfig.EVENTS,
  ExploreOrganizationTabsConfig.VIDEOS,
  // ExploreOrganizationTabsConfig.QUICK_LINKS,
  ExploreOrganizationTabsConfig.WIN_LIST,
];

export const EXPLORE_HOME_TABS: ExploreOrganizationTabType[] = values(
  ExploreOrganizationTabsConfig,
);

export const DATA_GRID_PARAM_KEYS = ['sort', 'pinnedl', 'pinnedr', 'columns'];

// Keys for search params that aren't included in the list of filters
export const ADDITIONAL_SEARCH_PARAM_KEYS = [
  'query',
  'includeIndirectReports',
  ...DATA_GRID_PARAM_KEYS,
];

export const META_FILTERS_MAPPING = {
  includeIndirectReports: 'reportsTo',
} as Record<string, string>;

export const STATIC_DATA_FILTERS = [
  ExplorerDataTypeEnum.WORK_LOCATION,
  ExplorerDataTypeEnum.DEPARTMENT,
  ExplorerDataTypeEnum.DIVISION,
  ExplorerDataTypeEnum.PRODUCT,
  ExplorerDataTypeEnum.GROUP,
];

export const exploreHighlightLabelMap: Record<string, string> = {
  externalId: 'Employee Id',
  skill: 'Skill',
  aspiration: 'Aspiration',
  jobFunction: 'Job Function',
  jobDescription: 'Job Description',
  bio: 'Bio',
};

export const EXPLORE_PAGE_PADDING = '1rem';
export const EXPLORE_PAGE_PADDING_WITHOUT_HEADER = '0.3rem';

export const COMMON_FILTERS = [
  {
    name: ExplorerDataTypeEnum.WORK_LOCATION,
    feature: FeaturesEnum.TAGGINGS,
  },
  {
    name: ExplorerDataTypeEnum.DEPARTMENT,
    feature: FeaturesEnum.TAGGINGS,
  },
  {
    name: ExplorerDataTypeEnum.DIVISION,
    feature: FeaturesEnum.TAGGINGS,
  },
  {
    name: ExplorerDataTypeEnum.PRODUCT,
    feature: FeaturesEnum.TAGGINGS,
  },
  {
    name: ExplorerDataTypeEnum.GROUP,
    feature: FeaturesEnum.TAGGINGS,
  },
];

/**
 * This defines the global filters for each content type
 */
export const ACTIVE_TAB_FILTERS_CONFIG = {
  [ExploreTabEnum.PEOPLE]: [
    {
      name: ExplorerDataTypeEnum.WORK_LOCATION,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.DEPARTMENT,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.DIVISION,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.PRODUCT,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.GROUP,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.REPORTS_TO,
      feature: FeaturesEnum.OTHER_PROFILE,
    },
    {
      name: ExplorerDataTypeEnum.SKILL,
      feature: FeaturesEnum.SKILLS,
    },
    {
      name: ExplorerDataTypeEnum.PRIOR_COMPANIES,
      feature: FeaturesEnum.EXPERIENCE,
    },
    {
      name: ExplorerDataTypeEnum.ASPIRATION,
      feature: FeaturesEnum.ASPIRATIONS,
    },
  ],
  [ExploreTabEnum.OPPORTUNITIES]: [
    ...COMMON_FILTERS,
    {
      name: ExplorerDataTypeEnum.SKILL,
      feature: FeaturesEnum.SKILLS,
    },
  ],
  [ExploreTabEnum.PRAISE]: [
    ...COMMON_FILTERS,
    {
      name: ExplorerDataTypeEnum.SKILL,
      feature: FeaturesEnum.SKILLS,
    },
  ],
  [ExploreTabEnum.COMPANY_UPDATES]: [...COMMON_FILTERS],
  [ExploreTabEnum.ORGANIZATION_EVENTS]: [
    ...COMMON_FILTERS,
    {
      name: ExplorerDataTypeEnum.EVENT_TYPE,
      feature: FeaturesEnum.ALL_ORGANIZATION_EVENTS,
    },
  ],
  [ExploreTabEnum.VIDEOS]: [...COMMON_FILTERS],
  [ExploreTabEnum.WIN_LIST]: [
    {
      name: ExplorerDataTypeEnum.WIN_PERIOD,
      feature: FeaturesEnum.WINS,
    },
    {
      name: ExplorerDataTypeEnum.WORK_LOCATION,
      feature: FeaturesEnum.TAGGINGS,
    },
    {
      name: ExplorerDataTypeEnum.DEPARTMENT,
      feature: FeaturesEnum.TAGGINGS,
    },
    {
      name: ExplorerDataTypeEnum.DIVISION,
      feature: FeaturesEnum.TAGGINGS,
    },
    {
      name: ExplorerDataTypeEnum.PRODUCT,
      feature: FeaturesEnum.TAGGINGS,
    },
    {
      name: ExplorerDataTypeEnum.REPORTS_TO,
      feature: FeaturesEnum.OTHER_PROFILE,
    },
    {
      name: ExplorerDataTypeEnum.SKILL,
      feature: FeaturesEnum.SKILLS,
    },
    {
      name: ExplorerDataTypeEnum.GROUP,
      feature: FeaturesEnum.TAGGINGS,
    },
  ],
} as ContentTypeFiltersMenuItemConfig;

/**
 * This defines the global filters for each content type
 */
export const HORIZONTAL_FILTERS_CONFIG = {
  [ExploreTabEnum.PEOPLE]: [
    {
      name: ExplorerDataTypeEnum.WORK_LOCATION,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.DEPARTMENT,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.DIVISION,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.GROUP,
      feature: FeaturesEnum.ALL_STATIC_DATA_PAGES,
    },
    {
      name: ExplorerDataTypeEnum.REPORTS_TO,
      feature: FeaturesEnum.OTHER_PROFILE,
    },
  ],
  [ExploreTabEnum.OPPORTUNITIES]: [
    ...COMMON_FILTERS,
    {
      name: ExplorerDataTypeEnum.SKILL,
      feature: FeaturesEnum.SKILLS,
    },
  ],
  [ExploreTabEnum.PRAISE]: [
    ...COMMON_FILTERS,
    {
      name: ExplorerDataTypeEnum.SKILL,
      feature: FeaturesEnum.SKILLS,
    },
  ],
  [ExploreTabEnum.COMPANY_UPDATES]: [...COMMON_FILTERS],
  [ExploreTabEnum.ORGANIZATION_EVENTS]: [
    ...COMMON_FILTERS,
    {
      name: ExplorerDataTypeEnum.EVENT_TYPE,
      feature: FeaturesEnum.ALL_ORGANIZATION_EVENTS,
    },
  ],
  [ExploreTabEnum.VIDEOS]: [...COMMON_FILTERS],
  [ExploreTabEnum.WIN_LIST]: [
    {
      name: ExplorerDataTypeEnum.WIN_PERIOD,
      feature: FeaturesEnum.WINS,
    },
    {
      name: ExplorerDataTypeEnum.REPORTS_TO,
      feature: FeaturesEnum.OTHER_PROFILE,
    },
    {
      name: ExplorerDataTypeEnum.DEPARTMENT,
      feature: FeaturesEnum.TAGGINGS,
    },
    {
      name: ExplorerDataTypeEnum.DIVISION,
      feature: FeaturesEnum.TAGGINGS,
    },
    {
      name: ExplorerDataTypeEnum.PRODUCT,
      feature: FeaturesEnum.TAGGINGS,
    },
    {
      name: ExplorerDataTypeEnum.GROUP,
      feature: FeaturesEnum.TAGGINGS,
    },
  ],
} as ContentTypeFiltersMenuItemConfig;
