import React from 'react';
import { useDebounce, useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { searchTagsApi } from '../services/taggings.service';
import { transformTags } from '../utils/tags.utils';
import { TagContentType } from '../enums/tag-content-type.enum';

export const useSearchTags = (
  searchTagsParam: {
    term?: string;
    tagId?: string;
    includeDescandants?: boolean;
    contentType?: TagContentType;
    size?: number;
  },
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();
  let { term, tagId, includeDescandants, contentType, size } = searchTagsParam;
  if (tagId === user.organizationId) tagId = undefined;

  const debouncedTerm = useDebounce(term);
  const queryKey = [user?.organizationId, 'tags', contentType];

  if (tagId) {
    queryKey.push(tagId);
  }

  if (debouncedTerm) {
    queryKey.push(debouncedTerm);
  }

  const queryResponse = useQuery(
    queryKey,
    React.useCallback(
      () => searchTagsApi(debouncedTerm, tagId, includeDescandants, contentType, size),
      [debouncedTerm, tagId, includeDescandants, contentType, size],
    ),
    {
      showErrorSnackbar: false,
      ...options,
      select: (response) => {
        return {
          ...response,
          data: {
            tags: transformTags(response.data.tags),
          },
        };
      },
    },
  );

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.tags,
  };
};
