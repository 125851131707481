import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { DetailedReview } from 'features/reviews/types/review.type';

import { isNil } from 'lodash';
import { getReviewDetailsApi } from '../reviews.service';

export const useGetReviewDetails = (reviewId = '', options: UseQueryOptions = {}) => {
  const { account: user, profile, isProfileFetching } = useSession();

  const queryKey = ['reviewDetails', reviewId, user?.organizationId];
  const queryResponse = useQuery(queryKey, () => getReviewDetailsApi(reviewId), {
    enabled: !isNil(profile) && !isProfileFetching && !!reviewId,
    refetchOnMount: false,
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data as DetailedReview,
  };
};
