import { useSearchParams } from 'react-router-dom';

import DownloadIcon from 'common/components/image/DownloadIcon';
import { Button } from 'common/components/material';
import { useExportProfiles } from 'features/profiles';
import { mapSearchParamsObject } from 'features/search';

export const ProfilesExportButton = () => {
  const [searchParams] = useSearchParams();
  const { mutate: exportProfiles, isLoading: isDownloading } = useExportProfiles();

  const onDownload = () => {
    const query = searchParams.get('query') || '';
    const filters = mapSearchParamsObject(searchParams);
    exportProfiles({
      term: query,
      ...filters,
    });
  };

  return (
    <Button
      btntype="label"
      size="small"
      startIcon={<DownloadIcon />}
      disabled={isDownloading}
      onClick={onDownload}
    >
      {isDownloading ? 'Exporting...' : 'Export'}
    </Button>
  );
};
