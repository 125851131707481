import { OrganizationRolesEnum } from 'common/enum/Organization.enum';
import { useQuery } from 'common/hooks';
import { getCompanyRolesApi } from '../companies.service';

export type SelectableOrganizationRole = Exclude<OrganizationRolesEnum, OrganizationRolesEnum.SUPER_ADMIN>;

export const useGetCompanyRoles = () => {
  const queryResponse = useQuery(['companyRoles'], () => getCompanyRolesApi(), {
    showErrorSnackbar: false,
    staleTime: Infinity,
  });

  return {
    ...queryResponse,
    data: (queryResponse.isSuccess ? queryResponse.data?.data?.roles : []) as SelectableOrganizationRole[],
  };
};
