import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

const useVirtualKeyboardVisibility = (minBrowserToolbarHeight = 200) => {
  const [visible, setVisible] = useState(isVisible(minBrowserToolbarHeight));

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    function handler() {
      if (visible !== isVisible(minBrowserToolbarHeight)) {
        setVisible(!visible);
      }
    }
    window.visualViewport?.addEventListener('resize', handler);
    return () => {
      window.visualViewport?.removeEventListener('resize', handler);
    };
  }, [visible, minBrowserToolbarHeight]);

  return visible;
};

function isVisible(minDelta: number) {
  return window.screen.height - document.body.clientHeight > minDelta;
}

export default useVirtualKeyboardVisibility;
