import { BaseStaticDataInfo } from '../types';
import {
  OrganizationalTagPagesTypeEnum,
  OrganizationalStaticDataTypeEnum,
} from '../static-data.enum';
import { StaticDataTypeDisplayName } from '../static-data.constants';

export class BaseStaticDataModel {
  protected _data: BaseStaticDataInfo;

  constructor(data: BaseStaticDataInfo) {
    this._data = data || {};
  }

  get id(): string {
    return this._data.id;
  }

  get name(): string {
    return this._data.name;
  }

  get fullName(): string {
    return this._data.fullName || this._data.completeName || this._data.name;
  }

  get isVisible(): boolean {
    return !!this._data.isVisible;
  }

  get photo(): string | undefined {
    return this._data.photo;
  }

  get staticDataType(): OrganizationalStaticDataTypeEnum | OrganizationalTagPagesTypeEnum {
    return this._data?.staticDataType || OrganizationalStaticDataTypeEnum.WORK_LOCATION;
  }

  get typeDisplayName(): string {
    return StaticDataTypeDisplayName[
      this._data?.staticDataType || OrganizationalStaticDataTypeEnum.WORK_LOCATION
    ];
  }
}
