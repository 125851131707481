import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from 'common/components/material';
import { MenuItem } from 'common/components/material/MenuList';
import { MenuButtonItemProps } from 'features/global-create-button';

const MenuButtonItem: React.FC<MenuButtonItemProps> = ({
  onClick,
  children,
  label,
  spacing = 2,
  variant = 'body2',
  padding,
  borderRadius = 0,
  ...menuItemProps
}) => {
  const { palette } = useTheme();

  return (
    <MenuItem
      sx={{
        color: palette.LinkText.Default,
        '&:hover': {
          color: palette.LinkText.Active,
          background: palette.Buttons.Tertiary.BgHover,
          borderRadius,
        },
        '&:active': {
          background: palette.Buttons.Tertiary.BgPressed,
          borderRadius,
        },
        padding,
      }}
      onClick={onClick}
      {...menuItemProps}
    >
      <Stack direction="row" alignItems="center" spacing={spacing}>
        {children}
        <Typography variant={variant} fontWeight={500}>
          {label}
        </Typography>
      </Stack>
    </MenuItem>
  );
};

export default MenuButtonItem;
