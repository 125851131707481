import * as _ from 'lodash';
import { useMemo } from 'react';
import { useAuthenticationContext } from 'common/context/hooks/useAuthentication';
import useQuery from 'common/hooks/useQuery';
import { UseQueryOptions } from 'common/types/Api.type';
import { Account } from 'features/accounts/types/accounts.type';
import useLocalStorage from '../../../common/hooks/useLocalStorage';
import { getAccountApi } from '../accounts.service';
import { useEvents } from 'common/hooks';
import { settled } from 'common/types/Event-type.type';
import { useFeatureAccessContext } from 'features/feature-access/feature-accesses.provider';

export const useGetAccount = (options?: UseQueryOptions) => {
  const { setItemValue: setRecentEmail } = useLocalStorage('recent-email');
  const { setItemValue: setRecentSubUrl } = useLocalStorage('recent-suburl');
  const { isSessionInitialized, setIsSessionInitialized } = useAuthenticationContext();
  const featureAccessContext = useFeatureAccessContext();
  const account = useQuery(['accounts', 'me'], getAccountApi, {
    showErrorSnackbar: false,
    showSuccessSnackbar: false,
    showValidationErrorSnackbar: false,
    onSuccess: (data: { data: Account }) => {
      if (!isSessionInitialized) {
        setIsSessionInitialized(true);
      }
      const { data: account } = data;
      if (!_.isNil(account)) {
        setRecentEmail(account.email);
        if (account.subUrl) {
          setRecentSubUrl(account.subUrl);
        }
      }
      const { updateFeatureAccess } = featureAccessContext;
      if (data.data && updateFeatureAccess) {
        updateFeatureAccess(data.data);
      }
    },
    onError: () => {
      if (!isSessionInitialized) {
        setIsSessionInitialized(true);
      }
    },
    enabled: !isSessionInitialized,
    ...options,
  });

  useEvents(settled('UserRolesModified'), () => account.refetch());

  return {
    ...account,
    data: useMemo(() => account.data?.data, [account.data]),
  };
};
