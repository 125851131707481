import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import { Typography } from 'common/components/material';
import OrganizationEventLocationTypeSelector from './organization-event-location-type-selector.component';
import { OrganizationEventLocationTypeEnum } from '../organization-events.enum';
import { FormTextField, WorkLocationSelector } from 'common/components/input';
import { OrganizationEventLocationFieldsProps } from '../types/organization-event-location-fields-props.type';
import { getWorkLocationWithOffice } from 'features/static-data';

// Part of the form in OrganizationEventDrawer
const OrganizationEventLocationFields: React.FC<OrganizationEventLocationFieldsProps> = ({
  eventLocationType,
  officeLocation,
  onLocationChange,
}) => {
  const { trigger } = useFormContext();
  const { palette } = useTheme();

  const [locationType, setLocationType] = useState<OrganizationEventLocationTypeEnum>(
    eventLocationType || OrganizationEventLocationTypeEnum.OFFICE,
  );

  const handleLocationTypeChange = (locationType: OrganizationEventLocationTypeEnum) => {
    if (locationType !== OrganizationEventLocationTypeEnum.ONLINE) {
      // Clear "URL required" validation error
      trigger('eventUrl');
    }

    setLocationType(locationType);
  };

  const showVenueField = locationType === OrganizationEventLocationTypeEnum.VENUE;
  const showOfficeField = locationType === OrganizationEventLocationTypeEnum.OFFICE;

  const transformedOfficeLocation = officeLocation
    ? getWorkLocationWithOffice(officeLocation)
    : null;

  return (
    <>
      <Typography variant="body1" fontWeight={500} color={palette.Text.Headline}>
        Location
      </Typography>
      <OrganizationEventLocationTypeSelector
        name="eventLocationType"
        value={locationType}
        onChange={handleLocationTypeChange}
      />
      {showOfficeField && (
        <WorkLocationSelector
          name="location"
          label="Office"
          value={transformedOfficeLocation || 'TBC'}
          onChange={onLocationChange}
        />
      )}
      {showVenueField && <FormTextField name="venue" label="Venue" />}

      <FormTextField name="eventUrl" label="URL (Optional)" />
    </>
  );
};

export default OrganizationEventLocationFields;
