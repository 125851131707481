import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { get } from 'lodash';
// TODO: We should update all instances of x-date-pickers to v6 when we have the time.
// This particular update was needed for the improved masking when manually typing
// a time. This change was made as part of the last-minute updates for ION Go-Live.
import { AdapterDateFns } from '@mui/x-date-pickers-v6/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-v6/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers-v6/DesktopTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers-v6/timeViewRenderers';
import { useTheme } from '@mui/material/styles';

import TextField from './TextField';
import { FieldName } from 'common/types/Form.type';
import { usePopoverState } from 'common/hooks';
import FloatingMenu, { FloatingMenuItem } from '../core/FloatingMenu';
import { generateTimeOptions } from 'common/utils/date-time.utils';
import theme from 'common/theme';

type TimePickerProps = {
  label: string;
  name: string;
  // HH:mm
  initTime?: string | null;
  minTime?: string;
  onTimeSelected?: (value: string | null) => void;
};

const TIME_DROPDOWN_HEIGHT = 200;

const TimePicker: React.FC<TimePickerProps> = ({
  label,
  name,
  initTime,
  minTime,
  onTimeSelected,
}) => {
  const { palette } = useTheme();
  const { control, formState } = useFormContext();
  const { anchorElement, openPopover, closePopover } = usePopoverState();
  const inputPosition = anchorElement?.getBoundingClientRect() || { top: 0, height: 0 };
  // Adjust the dropdown menu placement so it doesn't cover the input depending on the scroll position.
  const shouldShowDropDownBelow =
    inputPosition.top + inputPosition.height + TIME_DROPDOWN_HEIGHT < window.innerHeight;

  const { errors } = formState;
  const displayedErrorMessage = get(errors, `${name}.message`);

  const initMoment = moment(initTime, 'HH:mm');
  const initValue = initMoment.isValid() ? initMoment.toDate() : null;

  const [time, setTime] = useState<Date | null>(initValue);

  const [dropdownWidth, setDropdownWidth] = useState(0);

  const timeOptionsRef = useRef<FloatingMenuItem[]>([]);
  const minTimeDateObjectRef = useRef<Date>();

  useEffect(() => {
    minTimeDateObjectRef.current = minTime ? moment(minTime, 'HH:mm').toDate() : undefined;
    timeOptionsRef.current = generateTimeOptions(minTime);
  }, [minTime]);

  const formatTime = (timeValue: Date) => {
    return moment(timeValue).format('HH:mm');
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (
      e.target instanceof HTMLElement &&
      e.target.className?.includes('MuiInputBase-input')
    ) {
      setDropdownWidth(e.target.parentElement?.clientWidth as number);
      openPopover(e);
    }
  };

  const handleDropdownClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    closePopover();
  };

  return (
    <Controller
      control={control}
      name={name as FieldName}
      render={({ field: { onChange, value, ...restField } }) => {
        const handleDropdownSelect = (item: FloatingMenuItem) => {
          const dateObj = moment(item.value, 'HH:mm').toDate();
          anchorElement?.blur();
          setTime(dateObj);
          onChange(item.value);
          onTimeSelected && onTimeSelected(item.value);
          closePopover();
        };

        const timePickerValue = time || value ? moment(value, 'HH:mm').toDate() : null;

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopTimePicker
              label={label}
              value={timePickerValue}
              minTime={minTimeDateObjectRef.current}
              onChange={(newValue: Date | null) => {
                onChange(newValue ? formatTime(newValue) : null);
                onTimeSelected && onTimeSelected(newValue ? formatTime(newValue) : null);
                setTime(newValue);
              }}
              onAccept={(newValue: Date | null) => {
                onChange(newValue ? formatTime(newValue) : null);
              }}
              slots={{
                textField: TextField,
              }}
              slotProps={{
                textField: {
                  onClick: handleInputClick,
                  onBlur: closePopover,
                  error: !!displayedErrorMessage,
                  helperText: displayedErrorMessage,
                },
                desktopPaper: {
                  sx: {
                    backgroundColor: palette.Container.Bg,
                    '& .MuiClockNumber-root, & .MuiButtonBase-root': {
                      color: palette.Text.Paragraph,
                    },
                    '& .MuiButtonBase-root.Mui-disabled': {
                      color: palette.Buttons.Primary.LabelDisabled,
                    },
                  },
                },
              }}
              views={['hours', 'minutes']}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              }}
              {...restField}
            />
            <FloatingMenu
              autoFocus={false}
              disableAutoFocus={true}
              anchorEl={anchorElement}
              anchorOrigin={{
                vertical: shouldShowDropDownBelow ? 'bottom' : 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: shouldShowDropDownBelow ? 'top' : 'bottom',
                horizontal: 'left',
              }}
              items={timeOptionsRef.current}
              onClose={handleDropdownClose}
              onSelect={handleDropdownSelect}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: TIME_DROPDOWN_HEIGHT,
                    minWidth: dropdownWidth,
                  },
                },
              }}
              itemProps={{
                sx: { fontSize: theme.typography.body2.fontSize },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default TimePicker;
