import { camelCase, isNil, reduce, reject, sortBy, split, toUpper } from 'lodash';
import { StaticDataTypeEnum, WorkLocationSubdivisionEnum } from '../static-data.enum';
import { LocationDetails, LocationIQDetails, WorkLocation } from '../types';
import { OrganizationTypeEnum } from 'common/enum/Organization.enum';

export const getStaticDataOptionProps = (staticDataType: StaticDataTypeEnum) => {
  switch (staticDataType) {
    case StaticDataTypeEnum.LOCATIONS:
      return {
        keyProp: 'osmId',
        labelProp: 'address',
      };
    default:
      return {
        keyProp: 'id',
        labelProp: 'name',
      };
  }
};

export const getHierarchicalString = (fullName?: string) => {
  return isNil(fullName) ? fullName : split(fullName, ' - ').join(' > ');
};

export const getStaticDataMap = (staticData?: string[]) => {
  return reduce(staticData, (acc, key) => ({ ...acc, [camelCase(key)]: key }), {});
  // => { department: 'DEPARTMENT', officeLocation, 'WORK_LOCATION', ... }
};

export const getWorkLocationWithOffice = (
  loc: WorkLocation,
  subdivision: WorkLocationSubdivisionEnum = WorkLocationSubdivisionEnum.WORK_LOCATION,
) => {
  return loc
    ? {
        ...loc,
        location: getWorkLocationFullName(loc, subdivision),
      }
    : loc;
};

export const transformWorkLocations = (workLocations: WorkLocation[]) => {
  const workLocationsList = workLocations.map((loc: WorkLocation) =>
    getWorkLocationWithOffice(loc),
  );
  return sortBy(workLocationsList, ['location']);
};

const getWorkLocationFullName = (
  loc: WorkLocation,
  subdivision: WorkLocationSubdivisionEnum = WorkLocationSubdivisionEnum.WORK_LOCATION,
) => {
  const segments = [loc.country.name];

  if (subdivision !== WorkLocationSubdivisionEnum.COUNTRY) {
    segments.push(loc.city.name);
  }

  if (subdivision === WorkLocationSubdivisionEnum.WORK_LOCATION) {
    segments.push(loc.name);
  }

  const appendedLocation = reject(segments, (value) => !value);
  return appendedLocation.join(' - ');
};

export const extractLocationIQDetailsFromWorkLocation = (
  workLocation: WorkLocation,
): LocationIQDetails => {
  const location = workLocation as LocationDetails;
  if (!location) return location;

  const locationIQDetails = {
    city: location.city.name,
    country: location.country.name,
    osmId: location.osmId,
    osmType: location.osmType,
    state: location.state,
  } as LocationIQDetails;

  locationIQDetails.address = `${locationIQDetails.city}${
    locationIQDetails.state ? ', ' + locationIQDetails.state : ''
  }, ${locationIQDetails.country}`;
  return locationIQDetails;
};

export const checkIsCompanyOverview = (staticDataId?: string, staticDataType?: string) => {
  return (
    !(staticDataId && staticDataType) ||
    toUpper(staticDataType) === OrganizationTypeEnum.COMPANY
  );
};
