import { useQuery, useSession } from 'common/hooks';
import { isHrisManagedOrganization } from '../companies.service';

export const useGetIsOrganizationHrisManaged = () => {
  const { account: user } = useSession();

  const organizationId = user?.organizationId;

  const queryResponse = useQuery([organizationId, 'isHrisManaged'], () =>
    isHrisManagedOrganization(),
  );

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse.data?.data?.isManaged : true,
  };
};
