import { StaticDataTypeEnum } from 'features/static-data/static-data.enum';
import { useQuery } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getStaticDataApi } from '../services/static-data.service';
import { SearchParams } from 'features/search';

const useGetStaticData = (
  staticDataType: StaticDataTypeEnum,
  params: SearchParams,
  options: UseQueryOptions = {},
) => {
  const queryKey = [staticDataType] as string[];

  if (params.term) {
    queryKey.push(params.term);
  }

  const queryResponse = useQuery(queryKey, () => getStaticDataApi(staticDataType, params), {
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.[staticDataType],
  };
};

export default useGetStaticData;
