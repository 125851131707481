import MUIDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import Stack from './Stack';
import Typography from './Typography';
import CloseButton from 'common/components/navigation/CloseButton';
import { useView } from 'common/hooks';
import React from 'react';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const DEFAULT_DRAWER_WIDTH = 675;

type Anchor = 'top' | 'left' | 'bottom' | 'right';
type Variant = 'temporary' | 'persistent';

export type DrawerProps = {
  open: boolean;
  toggleDrawer: (e: React.MouseEvent | React.KeyboardEvent) => void;
  anchor: Anchor;
  children: React.ReactNode;
  showHeader?: boolean;
  header?: React.ReactElement;
  width?: number;
  variant?: Variant;
  /**
   * There exist two types of drawers inside MUI: temporary and persistent.
   *
   * Temporary drawers have a backdrop, and custom logic so that the drawer
   * can be toggled closed with a click-away or by pressing the Esc key.
   * These are mostly used for drawers that only contain a bunch of links
   * (e.g., a hamburger menu).
   *
   * Persistent drawers on the other hand do not have a backdrop, and are
   * usually used to render richer interfaces users will use for a longer
   * period of time.  Closing the drawer needs to be done manually
   * (i.e., no click-away / Esc key down support).
   *
   * What if you wanted the look and feel of a "temporary" drawer
   * (i.e., the backdrop), but still wanted to inibit the automatic dismissal
   * of the drawer on click-away/Esc key being down?  That's what the
   * `keepBackdrop` flag is for: in this case, all you ave to do is pass
   * `variant='temporary'` and `keepBackdrop=true`.
   */
  keepBackdrop?: boolean;
  showCloseButton?: boolean;
  label?: string | React.ReactNode;
  customPaperStyle?: React.CSSProperties;
};

const Drawer: React.FC<DrawerProps> = ({
  anchor,
  open,
  toggleDrawer,
  children,
  showHeader = true,
  header,
  width = DEFAULT_DRAWER_WIDTH,
  variant = 'temporary',
  keepBackdrop = false,
  showCloseButton = true,
  label,
  customPaperStyle,
}) => {
  const { isMobileView } = useView();
  const theme = useTheme();

  const onDrawerClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (variant === 'temporary' && keepBackdrop) {
      event.stopPropagation();
      return;
    }
    toggleDrawer(event);
  };

  const paperStyle = {
    backgroundColor: theme.palette.Container.Bg,
    backgroundImage: 'none',
    boxShadow: AppBoxShadows.shadow4,
    color: theme.palette.Text.Headline,
    paddingRight:
      variant === 'temporary' && anchor !== 'bottom' && anchor !== 'top' ? '0.8rem' : 'auto',
    ...customPaperStyle,
  };

  return (
    <MUIDrawer
      variant={variant}
      anchor={anchor}
      open={open}
      onClose={onDrawerClose}
      sx={{ [`& .MuiDrawer-paper`]: { ...paperStyle } }}
    >
      <Stack
        height={anchor === 'bottom' ? '100%' : '100vh'}
        width={anchor === 'bottom' ? 'auto' : width}
        role="presentation"
      >
        {header ||
          (showHeader && (
            <DrawerHeader
              theme={theme}
              sx={{
                justifyContent: label ? 'space-between' : 'flex-end',
              }}
            >
              {React.isValidElement(label) ? (
                label
              ) : (
                <Typography
                  variant={isMobileView ? 'h4' : 'h2'}
                  fontWeight={700}
                  ml={2}
                  color={theme.palette.Text.Headline}
                >
                  {label}
                </Typography>
              )}

              {showCloseButton && <CloseButton clickHandler={toggleDrawer}></CloseButton>}
            </DrawerHeader>
          ))}
        {children}
      </Stack>
    </MUIDrawer>
  );
};

export default Drawer;
