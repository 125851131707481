import { Button } from '../material';
import { CXAddButtonProps } from './cx-add-button.props';

const CXAddButton: React.FC<CXAddButtonProps> = ({
  label = 'Add',
  onClick,
  isVisible,
  ...props
}) => {
  return isVisible ? (
    <Button
      btntype="primary"
      size="small"
      onClick={onClick}
      {...props}
    >
      {label}
    </Button>
  ) : (
    <></>
  );
};

export default CXAddButton;
