import axios from 'common/utils/axios';
import { CreateProfileRequestParams } from 'features/create-profile/types/create-profile-request-params.type';
import { UpdateSkillRating } from 'features/skills';
import {
  FetchManagerOptionsRequestParams,
  GetManagersRequestParams,
  GetProfileParams,
  UpdateProfileRequestParams,
} from './types';
import { AxiosHeaders } from 'axios';
import { PageParams } from 'common/types/Api.type';
import { ManageProfileGroupsRequest } from './types/manage-profile-groups-request';

const profilesApi = axios('profiles');
const profilesExportApi = axios('profiles', { includeHeaders: true });

export const onboardApi = (data: CreateProfileRequestParams) => {
  return profilesApi.post('onboarding', data);
};

export const updateAspiredSkillApi = ({ profileId, ...data }: UpdateSkillRating) => {
  return profilesApi.post(`${profileId}/aspiration`, data);
};

export const deleteAspiredSkillApi = (profileId: string, skillId: string) => {
  const data = { skillIds: [skillId] };
  return profilesApi.delete(`${profileId}/aspirations`, { data });
};

export const getProfileApi = (params?: GetProfileParams) => {
  return profilesApi.get('', { params });
};

export const getUberManagerIdsApi = (profileId: string) => {
  return profilesApi.get(`${profileId}/uber-manager-ids`);
};

export const getL0ManagerIdApi = () => {
  return profilesApi.get('l0-manager-id');
};

export const fetchManagerOptionsApi = (
  profileId: string,
  data: FetchManagerOptionsRequestParams,
) => {
  return profilesApi.post(`${profileId}/manager-options`, data);
};

export const deleteEndorsedSkillApi = (profileId: string, skillId: string) => {
  const data = { skillIds: [skillId] };
  return profilesApi.delete(`${profileId}/skill-endorsements`, { data });
};

export const getProfileSkillsApi = (profileId: string) => {
  return profilesApi.get(`${profileId}/skills`);
};

export const getNewJoinersApi = (bulkJoinId: string, pageParams: PageParams = {}) => {
  const { page = 1, size = 10 } = pageParams;
  return profilesApi.get('new-joiners', { params: { bulkJoinId, page, size } });
};

export const updateProfileApi = ({ id, ...data }: UpdateProfileRequestParams) => {
  return profilesApi.put(id, data);
};

export const markPrivacyPolicyAsReviewedApi = () => {
  return profilesApi.put(`mark-privacy-policy-as-reviewed`);
};

export const uploadProfilePictureApi = (data: FormData) => {
  const id = data.get('id');
  const hasFile = data.get('file');

  if (!hasFile) {
    return profilesApi.delete(`${id}/photo`);
  }

  data.delete('id');
  return profilesApi.put(`${id}/photo`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateEndorsedSkillApi = ({ profileId, ...data }: UpdateSkillRating) => {
  return profilesApi.post(`${profileId}/skill-endorsement`, data);
};

export const getFeedsByProfileIdApi = (profileId: string, params: any) => {
  const page = params.pageParam?.page || 1;
  return profilesApi.get(`${profileId}/feeds`, { params: { page } });
};

export const getManagersApi = (queryParams: GetManagersRequestParams) => {
  const { page = 1, size = 10 } = queryParams;
  return profilesApi.get('managers', { params: { ...queryParams, page, size } });
};

export const getGroupsApi = (profileId: string) => {
  return profilesApi.get(`${profileId}/groups`);
};

export const manageGroupsApi = ({ profileId, groupIds }: ManageProfileGroupsRequest) => {
  return profilesApi.put(`${profileId}/groups`, { groupIds });
};

export const exportProfiles = async ({
  columns,
  ...queryParams
}: Record<string, string> = {}): Promise<{ data: Blob; headers: AxiosHeaders }> => {
  const params = { ...queryParams };

  if (columns) {
    const columnsArray = columns.split(',');
    const visibleColumns = columnsArray
      .filter((column: string) => column[0] !== '_')
      .join(',');
    params.columns = visibleColumns;
  }

  return profilesExportApi.get('export', {
    responseType: 'blob',
    params,
  });
};
