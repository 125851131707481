import { ContentType } from 'common/enum/ContentType.enum';
import axios from 'common/utils/axios';
import { useQuery } from 'common/hooks';
import { Organization } from 'common/types/Organization.type';

const api = axios('role-access');

const getEntitledOrganizationsApi = (contentType: ContentType) => {
  return api.get('entitled', { params: { contentType } });
};

// TODO: SG: Still need to figure out what should we call this, because it is taggable as of now, but in future we might use this API for getting entitled organizations where a user can create content.
const useGetEntitledOrganizations = (contentType: ContentType) => {
  const queryResponse = useQuery(
    ['entitled-resources', contentType],
    () => getEntitledOrganizationsApi(contentType),
    {
      select: (response) => {
        return {
          ...response,
          data: response.data.companies as Organization[],
        };
      },
    },
  );

  return {
    ...queryResponse,
    data: queryResponse?.data?.data,
  };
};

export default useGetEntitledOrganizations;
