import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Typography } from 'common/components/material';
import { FormattedPhraseProps } from './formatted-phrase.type';
import { FontStyleEnum } from './formatted-phrase.enum';

export const FormattedPhrase: React.FC<FormattedPhraseProps> = ({
  content = [],
  variant = 'body3',
}) => {
  const { palette } = useTheme();

  return (
    <Typography variant={variant} color={palette.Text.SubHeadline}>
      {content.map((phrase, index: number) => {
        if (!phrase.style) {
          return phrase.text;
        }

        switch (phrase.style) {
          case FontStyleEnum.BOLD:
          default:
            return (
              <Typography
                key={index}
                variant={variant}
                color={palette.Text.Headline}
                fontWeight={700}
              >
                {phrase.text}
              </Typography>
            );
        }
      })}
    </Typography>
  );
};
