import { useInfiniteQuery, useSession } from 'common/hooks';
import { getNextPageParam } from '../../org-root/utils/infiniteScrollApi.utils';
import { getNotificationsApi } from '../notifications.service';

export const useGetRecentNotifications = () => {
  const { hasOrganization, account: user } = useSession();

  const queryResponse = useInfiniteQuery(
    ['recentNotifications', user?.organizationId],
    getNotificationsApi,
    {
      enabled: hasOrganization,
      getNextPageParam,
      entityKey: 'notifications',
    },
  );

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse.data.pages : [],
  };
};
