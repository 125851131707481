import React from 'react';

import useDrawer from 'common/hooks/useDrawer';

import { DrawerIdEnum } from '../../enums/drawer-id.enum';
import SkillActionCard from './SkillActionCard';
import SkillActionButton from './SkillActionButton';
import { SkillActionPraiseCardProps } from 'features/skills';

const SkillActionPraiseCard: React.FC<SkillActionPraiseCardProps> = ({ skill, person }) => {
  const { closeDrawer, openDrawer } = useDrawer();
  const togglePraiseDrawer = () => {
    closeDrawer(DrawerIdEnum.SKILLS_ACTION);
    const praise = {
      receiver: person,
      skills: [skill.skill],
    };
    openDrawer(DrawerIdEnum.PRAISE, { praise });
  };
  const title = `Would you like to tell others how ${person.firstName} has demonstrated this skill?`;

  return (
    <SkillActionCard text={title}>
      <SkillActionButton
        label="Send praise"
        subLabel={`Recognize how ${person.firstName}'s skills make a difference`}
        action={togglePraiseDrawer}
      />
    </SkillActionCard>
  );
};

export default SkillActionPraiseCard;
