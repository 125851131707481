import { filter } from 'lodash';

import { WorkLocationSubdivisionEnum } from 'features/static-data/static-data.enum';
import {
  createStaticDataApi,
  deleteStaticDataApi,
  getManagedStaticDataApi,
  toggleVisibilityApi,
  updateStaticDataApi,
} from '../static-data/services/static-data.service';
import {
  createWorkLocationApi,
  deleteWorkLocationApi,
  updateWorkLocationApi,
} from '../static-data/services/work-location.service';
import HierarchicalExplorerForm from 'features/data-explorer/components/hierarchical-explorer-form.component';
import ExplorerWorkLocationForm from 'features/data-explorer/components/explorer-work-location-form.component';
import CitySelector from './components/city-selector.component';
import { ExplorerDataMapItem, HierarchicalDataMapping } from './types/explorer-data.type';
import { ExplorerDataTypeEnum } from './data-explorer.enum';
import { ExplorerConfig, ExplorerLevelConfig } from './types';
import { BASE_EXPLORER_LEVEL_CONFIG } from './base-explorer.config';
import {
  ASSIGNED_WORK_LOCATION_OPTIONS,
  CITY_OPTIONS,
  UNASSIGNED,
  UNASSIGNED_WORK_LOCATION_OPTIONS,
} from './data-explorer.constants';
import { AddCityButton } from './components/add-city-button.component';
import { UpdateCityButton } from './components/update-city-button.component';

const getBaseAdminConfig = (isHrisManagedOrganization: boolean) => {
  return {
    getApiFunction: getManagedStaticDataApi,
    createApiFunction: createStaticDataApi,
    updateApiFunction: updateStaticDataApi,
    deleteApiFunction: deleteStaticDataApi,
    toggleVisibilityApiFunction: toggleVisibilityApi,
    getApiParamKeys: ['type'],
    showAddButton: !isHrisManagedOrganization,
    showBackButtonInHierarchicalView: false,
  };
};

const getBaseAdminLevelConfig = (isHrisManagedOrganization: boolean) =>
  ({
    ...BASE_EXPLORER_LEVEL_CONFIG,
    editable: true,
    deletable: !isHrisManagedOrganization,
    submitOnChange: true,
    FormComponent: HierarchicalExplorerForm,
  } as ExplorerLevelConfig);

const defaultTopLevelSelect = (items: ExplorerDataMapItem[]) => {
  return filter(items, (item) => !item.parentId);
};

const defaultChildSelect = (items: ExplorerDataMapItem[], parentId: string) => {
  if (!parentId) {
    return [];
  }
  return filter(items, { parentId });
};

export const getAdminExplorerConfig = (isHrisManagedOrganization: boolean) =>
  ({
    [ExplorerDataTypeEnum.DEPARTMENT]: {
      ...getBaseAdminConfig(isHrisManagedOrganization),
      header: 'Department',
      levels: [
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 1',
          select: defaultTopLevelSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 2',
          select: defaultChildSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 3',
          select: defaultChildSelect,
        },
      ],
    },
    [ExplorerDataTypeEnum.DIVISION]: {
      ...getBaseAdminConfig(isHrisManagedOrganization),
      header: 'Division',
      levels: [
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 1',
          select: defaultTopLevelSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 2',
          select: defaultChildSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 3',
          select: defaultChildSelect,
        },
      ],
    },
    [ExplorerDataTypeEnum.PRODUCT]: {
      ...getBaseAdminConfig(isHrisManagedOrganization),
      header: 'Product',
      levels: [
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 1',
          select: defaultTopLevelSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 2',
          select: defaultChildSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 3',
          select: defaultChildSelect,
        },
      ],
    },
    [ExplorerDataTypeEnum.TEAM]: {
      ...getBaseAdminConfig(isHrisManagedOrganization),
      header: 'Team',
      levels: [
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 1',
          select: defaultTopLevelSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 2',
          select: defaultChildSelect,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Level 3',
          select: defaultChildSelect,
        },
      ],
    },
    [ExplorerDataTypeEnum.GROUP]: {
      ...getBaseAdminConfig(false),
      header: 'Groups',
      levels: [
        {
          ...getBaseAdminLevelConfig(false),
          header: 'Level 1',
          select: defaultTopLevelSelect,
        },
        {
          ...getBaseAdminLevelConfig(false),
          header: 'Level 2',
          select: defaultChildSelect,
        },
        {
          ...getBaseAdminLevelConfig(false),
          header: 'Level 3',
          select: defaultChildSelect,
        },
        {
          ...getBaseAdminLevelConfig(false),
          header: 'Level 4',
          select: defaultChildSelect,
        },
      ],
    },
    [ExplorerDataTypeEnum.WORK_LOCATION]: {
      header: 'Office Location',
      getApiParamKeys: ['type'],
      getApiFunction: getManagedStaticDataApi,
      createApiFunction: createWorkLocationApi,
      updateApiFunction: updateWorkLocationApi,
      deleteApiFunction: deleteWorkLocationApi,
      toggleVisibilityApiFunction: toggleVisibilityApi,
      showAddButton: !isHrisManagedOrganization,
      levels: [
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'City',
          itemLabelKey: 'fullName',
          editable: false,
          submitOnChange: false,
          select: (items: ExplorerDataMapItem[]) => {
            return filter(items, { type: WorkLocationSubdivisionEnum.CITY });
          },
          FormComponent: CitySelector,
          getMenuOptions: () => CITY_OPTIONS,
        },
        {
          ...getBaseAdminLevelConfig(isHrisManagedOrganization),
          header: 'Office',
          select: (items: ExplorerDataMapItem[], parentId: string) => {
            return filter(items, {
              type: WorkLocationSubdivisionEnum.WORK_LOCATION,
              parentId,
            });
          },
          getMenuOptions: (mapping?: HierarchicalDataMapping, data?: ExplorerDataMapItem) => {
            const isParentUnassigned =
              mapping?.[`${data?.parentId}`]?.fullName === UNASSIGNED;
            return isParentUnassigned
              ? UNASSIGNED_WORK_LOCATION_OPTIONS
              : ASSIGNED_WORK_LOCATION_OPTIONS;
          },
          FormComponent: ExplorerWorkLocationForm,
          ParentFormComponent: CitySelector,
          AddParentComponent: AddCityButton,
          EditParentComponent: UpdateCityButton,
        },
      ],
    },
  } as { [key: string]: ExplorerConfig });
