import { useMutation, useSession } from 'common/hooks';
import queryClient from 'common/utils/QueryClient';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { FormOperationsEnum } from 'common/enum/Form.enum';
import { ExplorerConfig } from '../types';

type ManageExplorerDataParams = {
  config: ExplorerConfig;
  dataType: ExplorerDataTypeEnum;
};

const getApiFunction = (config: ExplorerConfig, actionType: FormOperationsEnum) => {
  switch (actionType) {
    case FormOperationsEnum.CREATE:
      return config.createApiFunction;
    case FormOperationsEnum.UPDATE:
      return config.updateApiFunction;
    case FormOperationsEnum.DELETE:
      return config.deleteApiFunction;
    case FormOperationsEnum.TOGGLE_VISIBILITY:
      return config.toggleVisibilityApiFunction;
  }
};

export const useManageExplorerData = ({ config, dataType }: ManageExplorerDataParams) => {
  const { account } = useSession();
  const organizationId = account.organizationId || '';

  return useMutation(
    ({ actionType, ...params }: any) => {
      const apiFunction = getApiFunction(config, actionType);
      return apiFunction && apiFunction(params);
    },
    {
      showSuccessSnackbar: true,
      onSuccess: (data, params) => {
        queryClient.invalidateQueries([organizationId, 'static-data', dataType]);
        // We can safely assume newly created data won't be attached to a profile
        // at this point, so we only invalidate on update or delete.
        if (params.actionType !== 'create') {
          queryClient.invalidateQueries([organizationId, 'profile']);
        }
      },
    },
  );
};
