import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Typography } from 'common/components/material';
import { Project } from '../types';
import SkillChip from 'features/org-root/components/SkillChip';
import { HorizontalScrollableContainer } from 'common/components/container';
import { CXViewer } from 'libs/toast-ui';
import { CXAvatar } from 'common/components/cx-avatar/cx-avatar';
import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { TagChips } from 'features/tags/components/tag-chips.component';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';

const ProjectAccordionDetails: React.FC<{ project: Project }> = ({ project }) => {
  const { getOrgRoute } = useRouter();
  const { palette } = useTheme();

  const { skills, description, owner, tags = [] } = project;
  const { fullName, photo, id: profileId } = owner || {};

  const { canRead: canNavigateToProfile } = useFeatureAccess(FeaturesEnum.OTHER_PROFILE);
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    owner,
  );

  const ownerProfileRoute =
    canNavigateToProfile && permissions.interact && profileId
      ? getOrgRoute(AppRoutesEnum.PROFILE, { profileId })
      : '';

  const scrolledSkillsElement = (
    <Stack py={0.5} direction="row" spacing={1}>
      {skills?.map(({ isMatch, ...skill }) => (
        <SkillChip key={skill.id} skill={skill} isSelected={isMatch} />
      ))}
    </Stack>
  );

  return (
    <Stack>
      {!!skills.length && (
        <Box width="98%">
          <HorizontalScrollableContainer scrolledListElement={scrolledSkillsElement} />
        </Box>
      )}
      {!!description && <CXViewer value={description} maxLength={Infinity} />}
      <TagChips tags={tags} />
      {profileId && (
        <Stack direction="row" spacing={2} pt={1.5} alignItems="center">
          <Typography variant="caption" fontWeight={500} color={palette.Text.SubHeadline}>
            Owner
          </Typography>
          <Link href={ownerProfileRoute}>
            <Stack direction="row" spacing={0.75} alignItems="center">
              <CXAvatar src={photo} alt={fullName} size="small" />
              <Typography variant="body4" fontWeight={500} color={palette.LinkText.Default}>
                {fullName}
              </Typography>
            </Stack>
          </Link>
        </Stack>
      )}
    </Stack>
  );
};

export default ProjectAccordionDetails;
