import React from 'react';
import ImageIcon from '@mui/icons-material/Image';

import { CXAvatarUploader } from '../cx-avatar/cx-avatar-uploader';
import { ImageAvatarProps } from './image-avatar.type';

/*
 * Image avatar is using CXAvatarUploader since it is required for feature uploader to use ImageAvatar as a base. For instance: Company
 */
export const ImageAvatar: React.FC<ImageAvatarProps> = ({
  organizationName,
  src,
  size = 'large',
  readOnly = true,
  ...avatarProps
}) => {
  return (
    <CXAvatarUploader
      src={src}
      alt={organizationName}
      size={size}
      icon={ImageIcon}
      readOnly={readOnly}
      {...avatarProps}
    ></CXAvatarUploader>
  );
};
