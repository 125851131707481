import { useEvents, useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getProfileProjectsApi } from '../services/projects.service';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { EVENTS_TO_REFETCH_PROJECTS } from '../project.utils';
import { GetProfileProjectsFilters } from '../types/project-server-request.type';

export const useGetProfileProjects = (
  profileId: string,
  filters: GetProfileProjectsFilters = {},
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();
  const queryResponse = useQuery(
    [user.organizationId, profileId, 'projects', filters],
    () => getProfileProjectsApi(profileId, filters),
    {
      enabled: !!profileId,
      select: (response) => {
        return {
          ...response,
          data: response.data,
        };
      },
      ...options,
    },
  );

  useEvents(EVENTS_TO_REFETCH_PROJECTS as Event[], () => queryResponse.refetch());

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse?.data.data : [],
  };
};
