import CXAddButton from 'common/components/cx-add-button/cx-add-button.component';
import { useDrawer } from 'common/hooks';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';

export const OpportunityCreateActionButton: React.FC = () => {
  const { openDrawer } = useDrawer();
  const { canWrite: canCreateOpportunity } = useFeatureAccess(FeaturesEnum.OPPORTUNITIES);

  const handleClickAddNewEvent = () => {
    openDrawer(DrawerIdEnum.OPPORTUNITY, {
      opportunity: {},
    });
  };

  return (
    <CXAddButton
      label="Add Opportunity"
      onClick={handleClickAddNewEvent}
      isVisible={canCreateOpportunity}
    />
  );
};
