import { PaletteMode } from '@mui/material';
import { useLocalStorage } from 'common/hooks';
import { ThemeEnum } from 'common/theme/theme-type.enum';
import { useState } from 'react';

export const useThemeContext = () => {
   const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
   const { value: currentTheme, setItemValue: setCurrentTheme } = useLocalStorage('current-theme');

   const [theme, setTheme] = useState<PaletteMode>(currentTheme ? currentTheme : systemTheme);

   const toggleTheme = () => {
      const newTheme = theme === ThemeEnum.LIGHT ? 'dark' : 'light'
      setTheme(newTheme);
      setCurrentTheme(newTheme);
   };

   return {
      theme,
      toggleTheme,
   };
};
