import React from 'react';
import { useTheme } from '@mui/material';

import CXIcon from 'common/components/cx-icon/cx-icon.component';
import { OrganizationEvent } from '../types';
import { EVENT_TYPE_ICONS_MAPPING } from '../utils';
import { StyledOrganizationEventTypeChip } from '../styles';

const OrganizationEventTypeChip: React.FC<{
  event: OrganizationEvent;
  showBorder?: boolean;
}> = ({ event, showBorder = false }) => {
  const { palette } = useTheme();
  const { eventType } = event;

  const eventChipColors = palette.EventTagChip[eventType.type];
  const { Background, IconFill } = eventChipColors;

  const label = eventType.subType;
  const EventChipIcon = EVENT_TYPE_ICONS_MAPPING[label];

  return (
    <StyledOrganizationEventTypeChip
      icon={
        <CXIcon Icon={EventChipIcon.muiIcon} svg={EventChipIcon.svg} color={IconFill} />
      }
      borderColor={IconFill}
      background={Background}
      label={label}
      size={'small'}
      showBorder={showBorder}
    ></StyledOrganizationEventTypeChip>
  );
};

export default OrganizationEventTypeChip;
