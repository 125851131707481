import { pick } from 'lodash';

import { useSession, useInfiniteQuery } from 'common/hooks';
import {
  createCursorQueryParams,
  getNextCursorParam,
  getNextPageParam,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import { GetPaginatedExplorerDataParams } from '../types/get-paginated-explorer-data-params.type';
import { PaginationType } from '../data-explorer.enum';

export const useGetPaginatedExplorerData = ({
  config,
  type,
  ...params
}: GetPaginatedExplorerDataParams) => {
  const { account: user } = useSession();
  const { contentType, ...selectedFilters } = params;
  const queryKey = [user?.organizationId, contentType, 'explorer', type, selectedFilters];

  const apiParams = pick(params, config.getApiParamKeys);
  const isCursorPagination = config.paginationType === PaginationType.CURSOR;

  const getExplorerData = ({ pageParam }: any) => {
    const queryParams = isCursorPagination
      ? createCursorQueryParams(pageParam, apiParams)
      : { ...apiParams, ...pageParam };
    return config.getApiFunction(queryParams);
  };

  const queryResponse = useInfiniteQuery(queryKey, getExplorerData, {
    getNextPageParam: isCursorPagination ? getNextCursorParam : getNextPageParam,
    entityKey: config.entityKey || 'data',
  });

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  return {
    ...queryResponse,
    data,
  };
};
