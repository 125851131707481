import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { get } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { useDeferredState } from 'common/hooks';
import { FieldName } from 'common/types/Form.type';
import TextField from './TextField';

type DatePickerProps = {
  label?: string;
  name?: string;
  initDate?: string | null;
  disablePast?: boolean;
  disabled?: boolean;
  minDate?: Date;
  onDateChanged?: (value: string | null) => void;
  onDateSelected?: (value: string | null) => void;
};

const convertToDateString = (date: string | null) => {
  return date && moment(date).format('YYYY-MM-DD');
};

const convertToUTCString = (date: string | null) => {
  return date && moment(date).utc().toDate().toISOString();
};

const DatePicker: React.FC<DatePickerProps> = ({
  label = '',
  name = '',
  initDate = null,
  disabled,
  disablePast,
  minDate,
  onDateChanged,
  onDateSelected,
}) => {
  const { palette } = useTheme();
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const displayedErrorMessage = get(errors, `${name}.message`);

  const [date, setDate] = useDeferredState<string | null>(convertToUTCString(initDate));

  return (
    <Controller
      control={control}
      name={name as FieldName}
      render={({ field: { onChange, value, ...restField } }: any) => {
        const changeHandler = (newValue: string | null) => {
          onChange(convertToDateString(newValue));
          onDateChanged && onDateChanged(convertToDateString(newValue));
          setDate(convertToUTCString(newValue));
        };
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={label}
              value={initDate ? date : convertToUTCString(value)}
              disableMaskedInput
              disabled={disabled}
              inputFormat="MMM dd, yyyy"
              // without "selected" props, controlled datepicker will show local date
              selected={value}
              onChange={(newValue: string | null) => {
                newValue && moment(newValue).isValid()
                  ? changeHandler(newValue)
                  : changeHandler(null);
              }}
              onAccept={(newValue: string | null = null) => {
                onDateSelected && onDateSelected(convertToDateString(newValue));
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={!!displayedErrorMessage}
                  helperText={displayedErrorMessage}
                />
              )}
              disablePast={disablePast}
              minDate={minDate}
              PaperProps={{
                sx: {
                  backgroundColor: palette.Container.Bg,
                  color: palette.Text.Headline,
                  '& .MuiTypography-caption, & .MuiIconButton-root': {
                    color: palette.Text.Paragraph,
                  },
                  '& .MuiIconButton-root.Mui-disabled': {
                    color: palette.Buttons.Primary.LabelDisabled,
                  },
                  '& .MuiPickersDay-root': {
                    backgroundColor: palette.Container.Bg,
                    color: palette.Text.Paragraph,
                  },
                  '& .MuiPickersDay-today': {
                    borderColor: palette.Buttons.Primary.BgDefault,
                  },
                  '& .MuiPickersDay-root:hover': {
                    backgroundColor: palette.Buttons.Tertiary.BgHover,
                  },
                  '& .MuiPickersDay-root.Mui-selected': {
                    backgroundColor: palette.Buttons.Primary.BgDefault,
                    color: palette.Buttons.Primary.LabelDefault,
                  },
                },
              }}
              {...restField}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DatePicker;
