import React from 'react';

import MUITooltip, {
  TooltipProps as MUITooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material';

export type TooltipProps = MUITooltipProps & { maxWidth?: string };

export const StyledTooltip = styled(({ className, maxWidth, ...props }: TooltipProps) => {
  return <MUITooltip {...props} classes={{ popper: className }} arrow />;
})(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 12,
    color: theme.palette.ToolTip.Text,
    backgroundColor: theme.palette.ToolTip.Bg,
    '& .MuiTooltip-arrow': {
      color: theme.palette.ToolTip.Bg,
    },
    ...(maxWidth ? { maxWidth } : {}),
  },
}));

export default StyledTooltip;
