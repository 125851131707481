import { useContext, useEffect } from 'react';
import { castArray } from 'lodash';

import { ApplicationContext } from 'common/context/application-context';
import { EventType, EventTypeSettled } from 'common/types/Event-type.type';

/**
 * @param eventType
 * We need to use the IDE intellisense to get the autocomplete which will suggest us
 * the supported type of events in our system if we want to use the existing one.
 * If we have introduced the new the event, then we need to add in eventType union type
 * and it will start occuring in autocomplete dropdown of IDE for those types.
 */

export type Event = EventType | EventTypeSettled;

const useEvents = <D>(eventType: Event | Event[], callback: (data: D) => void): void => {
  const { eventManager } = useContext(ApplicationContext);
  useEffect(() => {
    const eventTypes = castArray(eventType);
    eventTypes.forEach((eventType) => {
      eventManager.subscribe(eventType, callback);
    });
    return () => {
      eventTypes.forEach((eventType) => {
        eventManager.unsubscribe(eventType, callback);
      });
    };
  }, [callback, eventManager, eventType]);
};

export default useEvents;
