import React, { useState } from 'react';
import _ from 'lodash';

import { Button, Stack } from 'common/components/material';
import Dialog from 'common/components/material/Dialog';
import { useFilterState } from '../hooks';
import FilterMenu from './FilterMenu';
import FilterTextButton from './FilterTextButton';
import FilterChipList from './FilterChipList';
import { FilterModalProps } from '../types';
import { BaseFilterType, Filter, FilterSearchParams } from 'features/search';
import FilterExplorer from './FilterExplorer';
import { WorkLocationSubdivisionEnum } from 'features/static-data';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';

const FilterModal: React.FC<FilterModalProps> = ({
  items,
  children,
  initialFilters,
  selectedTab,
  onApply,
  onClose,
  ...props
}) => {
  const [filterType, setFilterType] = useState<BaseFilterType | null>(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<FilterSearchParams>(
    {},
  );

  const {
    activeFilters,
    activeFilterMap,
    filterList,
    toggleFilterSelection,
    setFilters,
    clearFilters,
  } = useFilterState(filterType, initialFilters);

  const handleRemoveFilter = (filter: Filter) => {
    const calculatedFilterType = Object.values(WorkLocationSubdivisionEnum).includes(
      filter.filterType as WorkLocationSubdivisionEnum,
    )
      ? WorkLocationSubdivisionEnum.WORK_LOCATION
      : filter.filterType;

    toggleFilterSelection(
      filter,
      false,
      undefined,
      calculatedFilterType as ExplorerDataTypeEnum,
    );
  };

  const handleApply = () => {
    onApply(activeFilters, additionalSearchParams);
  };

  const handleCancel = () => {
    setFilters(initialFilters);
    onClose();
  };

  const goBackToMenu = () => {
    setFilterType(null);
  };

  const renderFilterContent = () => {
    if (!filterType) {
      return (
        <Stack flex={1}>
          <FilterMenu
            items={items}
            activeFilters={activeFilters}
            onSelect={setFilterType}
            selectedTab={selectedTab}
          />
        </Stack>
      );
    }

    return (
      <FilterExplorer
        filterType={filterType}
        activeFilterMap={activeFilterMap}
        onToggleSelection={toggleFilterSelection}
        onToggleMetaFilter={setAdditionalSearchParams}
        onClickBack={goBackToMenu}
        contentType={selectedTab}
      />
    );
  };

  return (
    <Dialog data-testid="filter-modal" onClose={handleCancel} maxWidth={false} {...props}>
      <Stack height="80vh" width="65rem" maxWidth="100%">
        <Stack direction="row" alignItems="center" p={2} spacing={1} minHeight="4rem">
          <FilterTextButton highlightText={!!filterList.length} />
          <FilterChipList
            filters={filterList}
            // Expand to a maximum of two rows before showing a scrollbar
            maxHeight="80px"
            onDelete={handleRemoveFilter}
          />
        </Stack>

        {renderFilterContent()}

        <Stack direction="row" justifyContent="space-between" p={2}>
          <Button
            btntype="tertiary"
            onClick={clearFilters}
            disabled={_.isEmpty(activeFilterMap)}
            data-testid="clear-all-filters"
          >
            Clear All
          </Button>
          <Stack direction="row" spacing={1}>
            <Button btntype="tertiary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button btntype="primary" onClick={handleApply} data-testid="apply-filter-button">
              Apply
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default FilterModal;
