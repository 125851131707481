import * as yup from 'yup';

export const PasswordSchema = yup
  .string()
  .label('Password')
  .matches(
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-])/,
    'Password must be at least 8 characters and contains a number, an uppercase letter, a lowercase letter, and a special character : # ? ! @ $ % ^ & * -',
  )
  .min(6)
  .max(64)
  .required();
