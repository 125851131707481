import axios from 'common/utils/axios';
import {
  GetStaticDataDetailsParams,
  UpdateStaticDataDescriptionParams,
} from 'features/static-data';
import { TagContentType } from '../enums/tag-content-type.enum';
import { FeedTypeEnum } from 'features/feeds/enums/feed-type.enum';

const taggingsApi = axios('taggings');

export const searchTagsApi = (
  term?: string,
  tagId?: string,
  includeDescandants?: boolean,
  contentType?: TagContentType,
  size?: number,
) => {
  return taggingsApi.get('tags/search', {
    params: { term, tagId, includeDescandants, contentType, size },
  });
};

export const getTagsActivities = (
  params: any,
  tagId: string,
  taggableType?: FeedTypeEnum,
) => {
  const page = params.pageParam?.page || 1;
  return taggingsApi.get('tag-activities', {
    params: { page, tagId, taggableType },
  });
};

export const getTagDetailsApi = (params: GetStaticDataDetailsParams) => {
  return taggingsApi.get(`${params.type}/${params.id}/details`);
};

export const getIsTagVisible = (params: GetStaticDataDetailsParams) => {
  return taggingsApi.get(`${params.type}/${params.id}/is-visible`);
};

export const updateTagDescriptionApi = ({
  id,
  type,
  description,
}: UpdateStaticDataDescriptionParams) => {
  return taggingsApi.put(`${type}/${id}/description`, { description });
};

export const updateTagPhotoApi = (data: FormData) => {
  const id = data.get('id');
  const type = data.get('type');
  const hasFile = data.get('file');

  if (!hasFile) {
    return taggingsApi.delete(`${type}/${id}/photo`);
  }

  data.delete('id');
  return taggingsApi.put(`${type}/${id}/photo`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
