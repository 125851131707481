import { styled } from '@mui/material/styles';
import { Stack } from 'common/components/material';

const StackedListItemContainer = styled(Stack)({
  '& > .MuiCard-root': {
    borderRadius: 0,
  },
  '& > .MuiCard-root:first-of-type': {
    borderTopLeftRadius: '0.75rem',
    borderTopRightRadius: '0.75rem',
  },
  '& > .MuiCard-root:last-of-type': {
    borderBottomLeftRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
  },
  borderRadius: '0.75rem',
});

export default StackedListItemContainer;
