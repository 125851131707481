import React, { useState } from 'react';

import { FormAutocomplete } from 'common/components/input';
import { Stack } from 'common/components/material';
import { useDebounce } from 'common/hooks';
import { validateMinLength } from 'common/utils/app.utils';

import AspirationChip from 'features/aspirations/components/aspiration-chip.component';
import EndorsedSkillChip from 'features/org-root/components/EndorsedSkillChip';
import { SkillSelectorProps, useGetSkills } from 'features/skills';

const SkillSelector: React.FC<SkillSelectorProps> = ({
  label,
  formFieldProps,
  readOnly,
  value,
  isAspiration,
  headerHelperText,
  footerHelperText,
  limit = undefined,
  multiple = true,
  onChange,
  ...skillSelectorProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue);

  const { data: skills, isLoading: isLoadingSkills } = useGetSkills(
    { term: debouncedInputValue },
    { enabled: validateMinLength(debouncedInputValue, 1) },
  );

  return (
    <Stack spacing={1}>
      {headerHelperText}
      <FormAutocomplete
        autoComplete
        multiple={multiple}
        loading={isLoadingSkills}
        label={label}
        limit={limit}
        getOptionLabel={(option) => option?.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        ChipComponent={isAspiration ? AspirationChip : EndorsedSkillChip}
        onInputChange={(e, value, reason) => {
          /**
           * This callback fired on input blur or when the
           * dropdown appeared, even with clearOnBlur set to false.
           * Had to ignore these reset events to prevent clearing
           * the input prematurely.
           */
          if (reason === 'reset') {
            return;
          }
          setInputValue(value);
        }}
        onChange={(value) => {
          setInputValue('');
          onChange(value);
        }}
        options={skills || []}
        noOptionsText="No matching skills"
        formFieldProps={formFieldProps}
        value={value}
        customReadOnly={readOnly}
        inputValue={inputValue}
        {...skillSelectorProps}
      />
      {footerHelperText}
    </Stack>
  );
};

export default SkillSelector;
