import { useState } from 'react';

const DEFAULT_DELAY = 500;

const useDebouncedMutate = (mutate: Function, delay = DEFAULT_DELAY) => {
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>();

  return (...args: any[]) => {
    return new Promise((resolve) => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
      setTimeoutRef(
        setTimeout(() => {
          resolve(mutate(...args));
        }, delay),
      );
    });
  };
};

export default useDebouncedMutate;
