import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';

import { deleteCompanyUpdateApi } from '../company-updates.service';
import { CompanyUpdateEventData } from '../types';

export const useDeleteCompanyUpdate = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } =
    useEventEmitter<CompanyUpdateEventData>('CompanyUpdateDeleted');

  return useMutation(deleteCompanyUpdateApi, {
    showSuccessSnackbar: true,
    ...options,
    onSuccess: () => {
      emitWithSettledAfter(5000);
    },
  });
};
