import React from 'react';

import { Button, Stack, Typography } from 'common/components/material';
import { ConfirmationIconType } from 'common/enum/ConfirmationIconType.enum';
import BottomConfirmationDrawer from './BottomConfirmationDrawer';

type ActionConfirmationDrawerProps = {
  subtitle?: string;
  confirmButtonHandler: () => void;
  toggleDrawer: (e: React.MouseEvent | React.KeyboardEvent) => void;
};

export const INIT_PROPS: ActionConfirmationDrawerProps = {
  confirmButtonHandler: () => {},
  toggleDrawer: () => {},
};

const TITLE = 'Are you sure?';

const SubmitFormConfirmationDrawer: React.FC<ActionConfirmationDrawerProps> = ({
  subtitle,
  toggleDrawer,
  confirmButtonHandler,
}) => {
  const drawerTitle = (
    <Stack textAlign="center" spacing={2}>
      <Typography variant="h3">{TITLE}</Typography>
      {subtitle && <Typography variant="body2">{subtitle}</Typography>}
    </Stack>
  );

  const cancelButton = (
    <Button btntype="tertiary" size="large" onClick={toggleDrawer}>
      Cancel
    </Button>
  );

  const confirmButton = (
    <Button btntype="primary" size="large" onClick={confirmButtonHandler}>
      Confirm
    </Button>
  );

  return (
    <BottomConfirmationDrawer
      title={drawerTitle}
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      iconType={ConfirmationIconType.SAVE}
    />
  );
};

export default SubmitFormConfirmationDrawer;
