import { useState } from 'react';

import useDrawer from 'common/hooks/useDrawer';

import Drawer from 'common/components/material/Drawer';
import { DRAWERS, DrawerType, DRAWER_FC_MAPPING } from './DrawerManager.config';
import { DrawerIdEnum } from '../enums/drawer-id.enum';
import { useDrawerConfig } from '../hooks/useDrawerConfig';

const DrawerManager = () => {
  const [drawers, setDrawers] = useState<DrawerType[]>(DRAWERS);
  const { closeDrawer } = useDrawer();
  const { getDrawerConfig } = useDrawerConfig();

  useDrawer((drawerState: DrawerType) => {
    setDrawers((previousDrawers: DrawerType[]) => {
      return previousDrawers.map((drawer: DrawerType) => {
        if (drawer.drawerId !== drawerState.drawerId) {
          return drawer;
        }
        const open = drawerState.open;

        return {
          drawerId: drawer.drawerId,
          open,
          drawerProps: {
            ...drawer.drawerProps,
            ...drawerState.drawerProps,
            open,
          },
        };
      });
    });
  });

  const handleToggleDrawer = (drawerId: DrawerIdEnum, drawerProps: any) => {
    closeDrawer(drawerId);
    const { onClose } = drawerProps;
    onClose && onClose();
  };

  return (
    <>
      {drawers.map(({ drawerId, drawerProps }) => {
        const DrawerFC = DRAWER_FC_MAPPING[drawerId];
        const drawerConfig = getDrawerConfig(drawerId);
        drawerConfig.open = drawerProps.open;
        return (
          <Drawer
            key={drawerId}
            {...drawerConfig}
            toggleDrawer={() => {
              handleToggleDrawer(drawerId, drawerProps);
            }}
          >
            <DrawerFC key={drawerId} {...drawerProps} />
          </Drawer>
        );
      })}
    </>
  );
};

export default DrawerManager;
