import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { deleteOpportunityApi } from '../opportunities.service';

export const useDeleteOpportunity = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter('OpportunityDeleted');

  return useMutation(deleteOpportunityApi, {
    showSuccessSnackbar: true,
    onSuccess: () => {
      emitWithSettledAfter(5000);
    },
    ...options,
  });
};
