import React from 'react';
import { Stack, Typography } from 'common/components/material';
import { MediaCardProps } from '../types/media-card-props.type';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { getFileNameAndFileExtension } from '../media.utils';
import { MediaTileCard } from '../styles/media.styles';

const MediaCard: React.FC<MediaCardProps & { disabled?: boolean }> = ({
  isActive = false,
  disabled = false,
  onClick,
  title,
  iconElement,
  titleColor,
  subLabelColor,
  subLabel,
  onSubLabelClick,
}) => {
  const { fileName, extension } = getFileNameAndFileExtension(title);
  return (
    <MediaTileCard disabled={disabled} isActive={isActive} onClick={onClick}>
      <Stack flexDirection="row" alignItems="center" pl="1rem">
        {iconElement}
        <Stack ml={2}>
          <Stack flexDirection="row" pr={2}>
            <EllipsisText variant="body2" color={titleColor} fontWeight={500} lineclamp={1}>
              {fileName ? `${fileName}.` : ''}
            </EllipsisText>
            <Typography variant="body2" color={titleColor} fontWeight={500}>
              {extension}
            </Typography>
          </Stack>
          <Typography variant="body4" color={subLabelColor} onClick={onSubLabelClick}>
            {subLabel}
          </Typography>
        </Stack>
      </Stack>
    </MediaTileCard>
  );
};

export default MediaCard;
