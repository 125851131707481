import { useTheme } from '@mui/material/styles';
import { HorizontalScrollableContainer } from 'common/components/container';
import { Stack, Typography } from 'common/components/material';
import { TagChipsProps } from '../types/tag-chips.type';
import TagChip from './tag-chip.component';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { useGetTransformedTags } from '../hooks/useGetTransformedTags';

export const TagChips: React.FC<TagChipsProps> = ({
  tags,
  label = 'Posted In',
  feature = FeaturesEnum.TAGGINGS,
}) => {
  const { palette } = useTheme();
  const { canRead: hasAccessToTagging } = useFeatureAccess(feature);
  const transformedTags = useGetTransformedTags(tags);

  if (!hasAccessToTagging || !transformedTags?.length) {
    return <></>;
  }

  const chips = (
    <Stack
      pt={0}
      direction="row"
      spacing={1}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {transformedTags.map((tag) => (
        <TagChip key={tag.id} tag={tag} />
      ))}
    </Stack>
  );

  return (
    <Stack alignItems="center" maxWidth="100%" direction="row" mt={{ xs: 1 }}>
      {label && (
        <Typography variant="body4" color={palette.Text.Caption} whiteSpace="nowrap" mr={1}>
          {label}
        </Typography>
      )}
      <Stack width={label ? '95%' : '100%'} py={0} overflow="hidden">
        <HorizontalScrollableContainer
          scrolledListElement={chips}
          // this container requires this to make the tags container not
          // wiggle vertically in explore people cards
          sx={{ py: '12px' }}
        />
      </Stack>
    </Stack>
  );
};
