import { WinsPeriodStatusEnum } from '../wins-period-status.enum';

export const getWinsBannerInitialText = (
  periodStatus: WinsPeriodStatusEnum,
  currentPeriodName?: string,
  daysToExpire?: number,
) => {
  switch (periodStatus) {
    case WinsPeriodStatusEnum.WINS_EXPIRED:
      return `You haven't created any WINs for ${currentPeriodName}`;
    case WinsPeriodStatusEnum.WINS_EXPIRING:
      return daysToExpire != null && daysToExpire <= 1
        ? `Your ${currentPeriodName} WINs are going to expire`
        : `Your ${currentPeriodName} WINs are going to expire in`;
  }
};

export const MAX_WIN_LIMIT = 3;
