import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getNextWinPeriodApi } from 'features/projects/services/win-periods.service';

export const useGetNextWinPeriod = (options?: UseQueryOptions) => {
  const { account: user } = useSession();
  const queryResponse = useQuery(
    ['next-win-period', user.organizationId],
    getNextWinPeriodApi,
    options,
  );

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse?.data.data : {},
  };
};
