import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

import { Button, Typography } from 'common/components/material';
import theme from 'common/theme';
import { ButtonProps } from 'common/components/material/Button';

const StyledAddButton = styled(Button)({
  ...theme.typography.button2,
  alignSelf: 'flex-start',
  borderRadius: '8px',
});

const AddStaticDataButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <StyledAddButton size="small" btntype="tertiary" startIcon={<AddIcon />} {...props}>
      <Typography variant="caption" fontWeight="500" noWrap>
        {children}
      </Typography>
    </StyledAddButton>
  );
};

export default AddStaticDataButton;
