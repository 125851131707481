import React from 'react';

import { Card, Typography } from 'common/components/material';
import { SkillActionCardProps } from 'features/skills';

const SkillActionCard: React.FC<SkillActionCardProps> = ({ text, children }) => {
  return (
    <Card variant="outlined" sx={{ padding: 1.5, boxShadow: 'none' }}>
      <Typography variant="body1" fontWeight={600} mb={1}>
        {text}
      </Typography>
      {children}
    </Card>
  );
};

export default SkillActionCard;
