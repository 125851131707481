import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { FetchReporteesRequestParams } from '../types';
import { getReporteesApi } from '../search.service';

export const useFetchReportees = (
  { term, includeSelf }: FetchReporteesRequestParams,
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();

  const queryResponse = useQuery(
    ['search', 'reportees', user?.organizationId, term, includeSelf],
    () => getReporteesApi({ term, includeSelf }),
    {
      ...options,
    },
  );
  const searchModel = queryResponse.data?.data?.profiles;

  return {
    ...queryResponse,
    data: searchModel,
  };
};
