import axios from 'common/utils/axios';

const reviewsApi = axios('reviews');

export const getReviewDetailsApi = (reviewId: string) => {
  return reviewsApi.get(`/${reviewId}`);
};

export const createReviewApi = ({ ...data }: any) => {
  return reviewsApi.post('', data);
};

export const submitReviewApi = (reviewId: string, { ...data }: any) => {
  return reviewsApi.put(`/${reviewId}`, data);
};

export const getReviewsApi = (params: any, profileId: string) => {
  const page = params.pageParam?.page || 1;
  return reviewsApi.get('', { params: { page, profileId } });
};
