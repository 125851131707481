import { get, pick, toNumber } from 'lodash';
import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { updateOrganizationRoleApi } from '../organization-role.service';
import { Skill } from 'features/skills';
import { OrgStaticData } from 'features/static-data';

export const useUpdateOrganizationRole = (options: UseMutationOptions = {}) => {
  const { emit } = useEventEmitter('OrganizationRoleUpdated');
  const mutationResponse = useMutation(updateOrganizationRoleApi, {
    showSuccessSnackbar: true,
    transformInput: (organizationRoleForm: any) => {
      const organizationRole = pick(organizationRoleForm, [
        'id',
        'jobTitle',
        'jobDescription',
        'startMonth',
        'isCurrentRole',
        'endMonth',
        'location',
      ]);

      const skills = get(organizationRoleForm, 'skills', []) as Skill[];
      const products = get(organizationRoleForm, 'products', []) as OrgStaticData[];
      const startYear = get(organizationRoleForm, 'startYear', null);
      const jobFunctionId = get(organizationRoleForm, 'jobFunction.id', null);

      return {
        ...organizationRole,
        skillIds: skills.map((skill: Skill) => skill.id),
        jobFunctionId,
        startYear: startYear ? toNumber(startYear) : null,
        endYear: get(organizationRoleForm, 'endYear', null),
        departmentId: get(organizationRoleForm, 'department.id', null),
        divisionId: get(organizationRoleForm, 'division.id', null),
        productIds: products.map((product: OrgStaticData) => product.id),
      };
    },
    onSuccess: () => {
      emit();
    },
    ...options,
  });
  return mutationResponse;
};

export default useUpdateOrganizationRole;
