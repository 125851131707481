import { SxProps, styled } from '@mui/material/styles';
import SearchInput, { SearchInputProps } from './SearchInput';

const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  '&.MuiTextField-root': {
    backgroundColor: theme.palette.Container.Bg,
    [theme.breakpoints.up('md')]: {
      '.MuiInputBase-root': {
        flexWrap: 'wrap',
        padding: 0,
        '.MuiInputBase-input': {
          width: 'auto',
          fontSize: theme.typography.caption.fontSize,
          flex: 1,
        },
      },
    },
  },
}));

const AppBarSearchInput: React.FC<SearchInputProps & { isExpanded?: boolean }> = ({
  isExpanded,
  ...props
}) => {
  const style = isExpanded
    ? {
        '&.MuiTextField-root': {
          position: 'absolute',
          zIndex: 20,
          // Keep the top of the search input the same level above the create button
          top: '-3px',

          '.MuiInputBase-root': {
            paddingY: '0.5rem',
          },

          '.MuiInputBase-input': {
            marginTop: '0.5rem',
            minWidth: '30%',
          },
        },
      }
    : ({} as SxProps);
  return <StyledSearchInput {...props} sx={style} />;
};

export default AppBarSearchInput;
