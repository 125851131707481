import FilledNotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import React from 'react';

import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import MenuItemButton from './MenuItemButton';

const ManageNotificationsMenuItem: React.FC = () => {
  const { goTo } = useRouter();
  const goToManageNotifications = () => {
    goTo(AppRoutesEnum.MANAGE_NOTIFICATIONS);
  };

  return (
    <MenuItemButton onClick={goToManageNotifications} label="Manage Notifications">
      <FilledNotificationsOutlinedIcon />
    </MenuItemButton>
  );
};

export default ManageNotificationsMenuItem;
