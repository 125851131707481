import React from 'react';

import SkillRatingReadView from 'features/org-root/components/SkillRatingReadView';

import { Box, Stack } from 'common/components/material';
import SkillActionCard from './SkillActionCard';
import InteractiveEndorsedSkillRating from '../InteractiveEndorsedSkillRating';
import SectionLabel from './SectionLabel';
import { useUpdateEndorsedSkill } from 'features/profiles';
import { SkillActionRateCardProps } from 'features/skills';

const SkillActionRateCard: React.FC<SkillActionRateCardProps> = ({
  endorsedSkill,
  endorsee,
  isLoggedInUser,
}) => {
  const { submit: updateRating } = useUpdateEndorsedSkill(
    endorsee.id,
    endorsedSkill?.skill.id,
  );

  const handleRateSkill = (e: React.MouseEvent, value: number | null) => {
    e.stopPropagation();
    if (value) {
      updateRating({ rating: value });
    }
  };

  const subject = isLoggedInUser ? 'your' : `${endorsee.firstName}'s`;
  const title = `Can you rate ${subject} level in this skill?`;

  return (
    <SkillActionCard text={title}>
      <Stack spacing={2}>
        {!!endorsedSkill.score && (
          <Box>
            <SectionLabel sx={{ mb: '0.2rem' }}>Avg rating</SectionLabel>
            <SkillRatingReadView rating={endorsedSkill.score} />
          </Box>
        )}
        <Box>
          <SectionLabel>Rate this skill</SectionLabel>
          <InteractiveEndorsedSkillRating
            score={endorsedSkill.loggedInUserRating}
            action={handleRateSkill}
          />
        </Box>
      </Stack>
    </SkillActionCard>
  );
};

export default SkillActionRateCard;
