import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { get } from 'lodash';
import { submitReviewApi } from '../reviews.service';

export const useSubmitReview = (reviewId = '', options: UseMutationOptions = {}) => {
  const mutationResponse = useMutation((data: any) => submitReviewApi(reviewId, data), {
    showSuccessSnackbar: true,
    transformInput: (body: any) => {
      const highStdSkills = get(body, 'highStdSkills').map((skill: any) => skill.id);
      const lowStdSkills = get(body, 'lowStdSkills').map((skill: any) => skill.id);
      return {
        revieweeId: body.revieweeId,
        highStdSkills,
        lowStdSkills,
        rating: body.rating,
        comment: body.comment,
      };
    },
    ...options,
  });
  return mutationResponse;
};
