import { getOrganizationEventsSummaryApi } from '../organization-events.service';
import { useEvents, useSession, useQuery } from 'common/hooks';
import { useSearchParams } from 'react-router-dom';
import { mapSearchParamsObject } from 'features/search';
import { GetOrganizationEventsSummaryParams } from '../types';
import { settled } from 'common/types/Event-type.type';
import { UseQueryOptions } from 'common/types/Api.type';

export const useGetOrganizationSummaryEvents = (
  params: GetOrganizationEventsSummaryParams,
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const filters = mapSearchParamsObject(searchParams) as { [prop: string]: any };

  // we are anyway passing eventTypes via params, so no need to pass the URL searchParam to api.
  delete filters.eventType;
  delete filters.selectedDate;

  if (params?.tags) {
    filters.tags = params?.tags;
  }

  if (params?.eventTypes?.length) {
    filters.eventTypes = params?.eventTypes;
  }

  if (params?.groupBy) {
    filters.groupBy = params?.groupBy;
  }

  if (params?.eventFilterType) {
    filters.eventFilterType = params?.eventFilterType;
  }

  filters.startDate = params.startDate;
  filters.endDate = params.endDate;
  filters.limit = params.limit || 10000;

  const queryKey = [user.organizationId, 'organization-events', filters];

  if (query) {
    queryKey.push(query);
  }

  const queryParams = {
    term: query,
    ...filters,
    localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const queryResponse = useQuery(
    queryKey,
    () => getOrganizationEventsSummaryApi(queryParams),
    {
      ...options,
    },
  );

  const response = {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse?.data?.data?.organizationEvents : [],
  };

  useEvents(settled('OrganizationEventsModified'), () => response.refetch());

  return response;
};
