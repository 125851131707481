import React from 'react';
import { get, isEmpty, filter, isArray, every } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import MUIAutocomplete, { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import { ReactComponent as DeleteChipIcon } from 'common/svg-icons/cancelIcon.svg';

import TextField from './TextField';
import { AutocompleteProps } from 'common/types/Form.type';

import { Paper, Typography } from './';
import { StyledSkillChip } from 'features/skills';

const Autocomplete: React.FC<AutocompleteProps> = React.forwardRef((props, ref) => {
  const {
    children,
    label,
    placeholder,
    formFieldProps,
    baseInputProps = {},
    helperText,
    value,
    multiple,
    customReadOnly,
    hasIconChip,
    limit,
    ChipComponent = StyledSkillChip,
    getOptionLabel,
    getOptionAvatar,
    onChange,
    errorMessage,
    errorsArray,
    options,
    optionKey = 'id',
    optionTextProps = {},
    showArrowIcon = false,
    disabledIds = [],
    ...autocompleteProps
  } = props;

  const { palette } = useTheme();
  const fixedValues = filter(value, (v) => disabledIds.includes(v?.id));

  const getLabel = (option: any) => {
    if (typeof option === 'string') {
      return option;
    }
    return getOptionLabel ? getOptionLabel(option) : option.label;
  };

  const getAvatar = (option: any) => {
    return getOptionAvatar ? getOptionAvatar(option) : option.photo;
  };

  const renderTags = (value: readonly any[], getTagProps: AutocompleteRenderGetTagProps) => {
    const values = [...fixedValues, ...filter(value, (v) => !disabledIds.includes(v?.id))];

    return values.map((option: any, index: number) => {
      const tagProps = getTagProps({ index });
      const color = get(errorsArray, `[${index}]`) ? 'error' : 'secondary';
      let { onDelete, disabled, ...restProps } = tagProps;

      if (option?.id && !disabled && !isEmpty(disabledIds)) {
        disabled = disabledIds.includes(option.id);
      }

      return (
        <ChipComponent
          clickable={!customReadOnly}
          label={getLabel(option)}
          color={!customReadOnly || !disabled ? color : undefined}
          avatar={hasIconChip ? getAvatar(option) : undefined}
          deleteIcon={
            disabled ? (
              <></>
            ) : (
              <DeleteChipIcon fill={palette.SelectionChips.Primary.LabelAndIconDefault} />
            )
          }
          variant="filled"
          disabled={disabled}
          {...restProps}
          // Remove the tag on click
          onClick={!customReadOnly ? onDelete : undefined}
          onDelete={!customReadOnly ? onDelete : undefined}
        />
      );
    });
  };

  const handleChange = (value: any) => {
    if (isArray(value)) {
      const values = [...fixedValues, ...filter(value, (v) => !disabledIds.includes(v?.id))];
      onChange && onChange(values);
      return;
    }

    onChange && onChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (limit && value?.length >= limit) {
      event.preventDefault();
    }
  };

  return (
    <MUIAutocomplete
      filterSelectedOptions
      multiple={multiple}
      renderTags={multiple ? renderTags : undefined}
      disabled={customReadOnly}
      disableClearable={every(value, (v) => disabledIds.includes(v?.id))}
      // For preloaded options, if the limit is crossed, we need to restrict users to select any preloaded option, so this will take care of it.
      options={multiple && limit && value?.length >= limit ? [] : options}
      PaperComponent={(props) => (
        <Paper
          {...props}
          sx={{
            backgroundColor: palette.DropdownList.Bg,
            color: palette.DropdownList.Label,
          }}
        />
      )}
      PopperComponent={(props) => (
        <Popper
          {...props}
          sx={{
            '& .MuiAutocomplete-groupLabel': {
              backgroundColor: palette.DropdownList.Bg,
            },
          }}
        />
      )}
      renderInput={(defaultFieldProps) => {
        const { InputProps: MUIInputProps, inputProps, ...defaultProps } = defaultFieldProps;
        const {
          InputProps: customMUIInputProps,
          inputProps: customInputProps,
          ...customProps
        } = formFieldProps || {};
        return (
          <TextField
            variant="outlined"
            label={label}
            placeholder={multiple && value?.length ? '' : placeholder}
            error={!!errorMessage}
            helperText={errorMessage || helperText}
            onKeyDown={handleKeyDown}
            {...defaultProps}
            {...customProps}
            InputProps={{
              ...MUIInputProps,
              ...(customMUIInputProps || {}),
            }}
            autoFocus={autocompleteProps.autoFocus}
            inputProps={{ ...inputProps, ...customInputProps, ...baseInputProps }}
          />
        );
      }}
      getOptionLabel={getOptionLabel}
      // Highlight matched term in search results
      // https://mui.com/components/autocomplete/#highlights
      renderOption={(props, option: any, { inputValue }) => {
        const label = getLabel(option);
        const matches = match(label, inputValue, { insideWords: true });
        const parts = parse(label, matches);

        return (
          <li {...props} key={get(option, optionKey) ?? option}>
            <Typography {...optionTextProps}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 600 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Typography>
          </li>
        );
      }}
      onChange={(e, value) => handleChange(value)}
      value={value}
      clearIcon={<CancelIcon fontSize="small" />}
      popupIcon={showArrowIcon ? <ArrowDropDownIcon></ArrowDropDownIcon> : <></>}
      ref={ref}
      {...autocompleteProps}
    />
  );
});

export default Autocomplete;
