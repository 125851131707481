import '@toast-ui/editor/dist/toastui-editor.css';
import { createRef } from 'react';
import { debounce } from 'lodash';
import { Editor as ToastUIEditor } from '@toast-ui/react-editor';

import { defaultEditorConfig } from '../toast-ui-editor.config';
import { CXEditorProps } from '../types';
import { EDITOR_UPDATE_DELAY } from '../toast-ui.constants';
import { CXEditorStyledContainer } from '../styles';
import { getTransformedValue } from '../toast-ui-editor.utils';

/**
 * This component wraps the ToastUIEditor component.
 */
export const CXEditor = ({ autoFocus, ...props }: CXEditorProps) => {
  const ref = createRef<ToastUIEditor>();

  const onChange = () => {
    const value = ref?.current?.getInstance().getMarkdown() || '';
    const transformedValue = getTransformedValue(value);
    props.onChange(transformedValue.trim() as string);
  };

  const debouncedOnChange = debounce(onChange, EDITOR_UPDATE_DELAY);

  const addImageBlobHook = () => {
    props.onAddImageHookTrigger();
  };

  return (
    <CXEditorStyledContainer>
      <ToastUIEditor
        ref={ref}
        {...defaultEditorConfig}
        height="15rem"
        autofocus={autoFocus || false}
        initialValue={props.initialValue}
        placeholder={props.placeholder}
        onChange={debouncedOnChange}
        hooks={{
          addImageBlobHook,
        }}
      />
    </CXEditorStyledContainer>
  );
};
