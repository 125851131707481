import { styled } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const ExpandIcon = styled(KeyboardArrowRightIcon)(({ theme }) => {
  return {
    borderRadius: '50%',
    fontSize: '1.2rem',
    color: theme.palette.Icons.Tertiary.IconFillDefault,
  };
});

export const CollapseIcon = styled(ExpandIcon)({
  transform: `rotate(90deg)`,
});
