import axios from 'common/utils/axios';

const winPeriodsApi = axios('win-periods');

export const getCurrentWinPeriodApi = () => {
  return winPeriodsApi.get('current');
};

export const getNextWinPeriodApi = () => {
  return winPeriodsApi.get('next');
};

export const getPreviousWinPeriodApi = () => {
  return winPeriodsApi.get('previous');
};

export const getWinPeriodsApi = () => {
  return winPeriodsApi.get('');
};

export const searchWinPeriodsApi = (params: any) => {
  return winPeriodsApi.get('search', { params });
};
