import { useQuery, useSession } from 'common/hooks';
import { recentSearchesApi } from '../search.service';
import { ISearchSuggestion } from '../interfaces/search-suggestion.interface';
import { UseQueryOptions } from 'common/types/Api.type';

export const useGetRecentSearches = (options?: UseQueryOptions) => {
  const { account: user } = useSession();

  const { data, isLoading, ...query } = useQuery(
    [user?.organizationId, 'recent-search'],
    () => recentSearchesApi(),
    {
      ...options,
    },
  );

  const { recentSearches } = data?.data || {};

  return {
    ...query,
    isLoading,
    recentSearches: (recentSearches || []) as ISearchSuggestion[],
  };
};
