import { alpha, styled } from '@mui/material/styles';
import { CXAvatar } from './cx-avatar';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from 'common/components/material/Link';
import { AvatarLinkProps } from './cx-avatar.types';

export const StyledCXAvatar = styled(CXAvatar)(({ theme }) => ({
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  '&:hover': {
    backgroundColor: alpha(theme.palette.EmptyPlaceholderIcons.Background, 0.5),
    border: `2px solid ${theme.palette.LinkContainer.BgDefault}`,
    '.MuiAvatar-root, .MuiSvgIcon-root, .MuiAvatar-img': {
      opacity: 0.5,
    },
  },
  '&:hover + .action-icon': {
    display: 'inline-block',
  },
  '&:hover:has(+ .add-icon)': {
    outline: `2px dashed ${theme.palette.Icons.Primary.BgDefault}`,
  },
  '&:hover:has(+ .delete-icon)': {
    outline: `2px dashed ${theme.palette.SpecialButtons.Delete.LabelHover}`,
  },
}));

export const CXAvatarUploadAddIcon = styled(AddIcon)(({ theme }) => ({
  color: theme.palette.Icons.Primary.BgDefault,
  fontSize: '2rem',
  display: 'none',
  position: 'absolute',
  pointerEvents: 'none',
}));

export const CXAvatarUploadDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.SpecialButtons.Delete.LabelHover,
  fontSize: '2rem',
  display: 'none',
  position: 'absolute',
  pointerEvents: 'none',
}));

export const CXAvatarHiddenFileInput = styled('input')({
  display: 'none',
});

export const CXAvatarLink = styled((props: any) => <Link {...props} />, {
  shouldForwardProp: (prop) => {
    return prop !== 'isDisabled';
  },
})<AvatarLinkProps>(({ isDisabled, theme }) => ({
  color: isDisabled
    ? theme.palette.Buttons.Tertiary.BgDisabled
    : theme.palette.Buttons.Tertiary.LabelDefault,
  cursor: isDisabled ? 'default' : 'pointer',
}));
