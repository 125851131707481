import { useContext, useState } from 'react';

import { AuthenticationContext } from 'common/types/authentication-context.type';
import { ApplicationContext } from '../application-context';

export const useAuthenticationContext = () => {
  const applicationContext = useContext(ApplicationContext);
  return applicationContext.authenticationContext;
};

export const useAuthentication = (): AuthenticationContext => {
  const [isSessionInitialized, setIsSessionInitialized] = useState<boolean>(false);

  return {
    isSessionInitialized,
    setIsSessionInitialized,
  };
};
