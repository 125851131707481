import { useTheme } from '@mui/material/styles';
import List, { ListSubheader } from 'common/components/material/List';
import { Grid, Typography } from 'common/components/material';

const StaticDataSuggestionsList: React.FC = ({ children }) => {
  const { palette } = useTheme();

  return (
    <List
      subheader={
        <ListSubheader sx={{ lineHeight: '2.5rem', backgroundColor: palette.Container.Bg }}>
          <Grid display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body3" fontWeight={700} color={palette.DropdownList.Label}>
              Apply Filters
            </Typography>
          </Grid>
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
};

export default StaticDataSuggestionsList;
