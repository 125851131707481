import React from 'react';

import { List } from 'common/components/material';

import AddStaticDataButton from '../../org-root/components/StaticData/AddStaticDataButton';
import BaseDataExplorerColumn from './base-data-explorer-column.component';
import { HierarchicalExplorerColumnProps } from '../types';
import { UNASSIGNED } from '../data-explorer.constants';

const HierarchicalExplorerColumn: React.FC<HierarchicalExplorerColumnProps> = ({
  index,
  data,
  dataType,
  isReadView,
  showAddButton = true,
  parentId,
  editMode,
  mapping,
  config,
  disabled,
  renderItem,
  onItemAction,
  onToggleEditMode,
}) => {
  const { header, FormComponent } = config;
  const hasUnassignedParent = mapping[`${parentId}`]?.fullName === UNASSIGNED;
  const isParentVisible = !parentId || mapping[`${parentId}`]?.isVisible;
  const addButtonText = index && !data.length ? 'Add Child' : 'Add New';
  const addButtonType = index && !data.length ? 'secondary' : 'tertiary';
  const showForm = editMode && !!FormComponent;

  showAddButton =
    !!showAddButton &&
    !!isParentVisible &&
    !isReadView &&
    !editMode &&
    (index === 0 || !!parentId) &&
    !hasUnassignedParent;

  const toggleEditMode = (showEditMode: boolean) => {
    const columnId = showEditMode ? index : null;
    onToggleEditMode && onToggleEditMode(columnId);
  };

  const handleFormChange = (dataToSubmit: any) => {
    const actionType = config.submitOnChange ? 'create' : 'proceed-form';
    onItemAction({ data, actionType, value: dataToSubmit });
  };

  return (
    <BaseDataExplorerColumn label={header}>
      {!!data.length && (
        <List className="editable" disablePadding>
          {data.map((staticData) => renderItem(staticData))}
        </List>
      )}
      {showAddButton && (
        <AddStaticDataButton btntype={addButtonType} onClick={() => toggleEditMode(true)}>
          {addButtonText}
        </AddStaticDataButton>
      )}
      {showForm && (
        <FormComponent
          mapping={mapping}
          parentId={parentId}
          dataType={dataType}
          disabled={disabled}
          onChange={handleFormChange}
          onClose={() => toggleEditMode(false)}
        />
      )}
    </BaseDataExplorerColumn>
  );
};

export default HierarchicalExplorerColumn;
