import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import MenuItemButton from './MenuItemButton';
import { validateUrlProtocol } from 'common/utils/app.utils';
import { Link } from 'common/components/material';

const OrganizationLinkMenuItem: React.FC<{
  Icon: typeof SvgIcon;
  label: string;
  url: string;
}> = ({ Icon, label, url }) => {
  const href = validateUrlProtocol(url);
  /*
   * We need to close the enclosing drawer on click; however when we use a native
   * link and let the user agent manage it, the even will never make it to the
   * parent component, leaving the drawer open.
   *
   * We could manually close the drawer, from here, but what if we used this
   * component inside a different drawer?
   *
   * So the solution is to register a click handler, manually open the URL in a new
   * page, and make sure the click event reaches the parent component (i.e. default
   * behavior unless `preventDefault()` or `stopPropagation()` is called).
   */
  const preventDefault = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };
  const onClick = () => {
    window.open(href, '_blank');
  };
  return (
    <Link href={href} onClick={preventDefault}>
      <MenuItemButton onClick={onClick} label={label}>
        <Icon />
      </MenuItemButton>
    </Link>
  );
};

export default OrganizationLinkMenuItem;
