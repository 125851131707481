import { filter } from 'lodash';

import { WorkLocationSubdivisionEnum } from 'features/static-data/static-data.enum';
import {
  getOrganizationalAspirationStaticDataApi,
  getOrganizationalStaticDataApi,
} from '../static-data/services/static-data.service';
import { ExplorerDataMapItem } from './types/explorer-data.type';
import { ExplorerDataTypeEnum, PaginationType } from './data-explorer.enum';
import { ExplorerConfig, ExplorerLevelConfig } from './types';
import {
  BASE_EXPLORER_LEVEL_CONFIG,
  defaultChildSelect,
  defaultTopLevelSelect,
} from './base-explorer.config';
import { getManagersApi } from 'features/profiles/profiles.service';
import IncludeIndirectReportsCheckbox from 'features/explore/components/IncludeIndirectReportsCheckbox';
import { getEndorsedSkillsApi } from 'features/skills/services/skill-endorsements.service';
import { Skill } from 'features/skills';
import { getProfilePriorCompanyNamesApi } from 'features/company-names/company-name.service';
import { getAspiredSkillsApi } from 'features/aspirations/aspiration.service';
import { getOrganizationEventTypesHierarchy } from 'features/organization-events/organization-events.service';
import { searchWinPeriodsApi } from 'features/projects/services/win-periods.service';

export const BASE_API_PARAM_KEYS_WITH_RELATED_PAGES = [
  'department',
  'division',
  'team',
  'product',
  'group',
  'city',
  'country',
  'workLocation',
  'aspirationCity',
  'aspirationDepartment',
  'aspirationDivision',
  'aspirationTeam',
  'aspirationProduct',
];

const BASE_API_PARAM_KEYS = [
  ...BASE_API_PARAM_KEYS_WITH_RELATED_PAGES,
  'contentType',
  'reportsTo',
  'skill',
  'priorCompanies',
  'aspirationSkill',
  'excludeChildren',
  'eventType',
  'winPeriod',
];

const BASE_FILTER_CONFIG = {
  getApiParamKeys: ['type', ...BASE_API_PARAM_KEYS],
  showBackButtonInHierarchicalView: true,
  includeUnassignedOption: false,
  getApiFunction: getOrganizationalStaticDataApi,
};

const BASE_FILTER_LEVEL_CONFIG = {
  ...BASE_EXPLORER_LEVEL_CONFIG,
} as ExplorerLevelConfig;

export const FILTER_EXPLORER_CONFIG: { [key: string]: ExplorerConfig } = {
  [ExplorerDataTypeEnum.DEPARTMENT]: {
    ...BASE_FILTER_CONFIG,
    includeUnassignedOption: true,
    header: 'Department',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.DIVISION]: {
    ...BASE_FILTER_CONFIG,
    includeUnassignedOption: true,
    header: 'Division',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.PRODUCT]: {
    ...BASE_FILTER_CONFIG,
    includeUnassignedOption: true,
    header: 'Product',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.TEAM]: {
    ...BASE_FILTER_CONFIG,
    includeUnassignedOption: true,
    header: 'Team',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.WORK_LOCATION]: {
    ...BASE_FILTER_CONFIG,
    excludeFilterTypes: [
      ExplorerDataTypeEnum.WORK_LOCATION,
      WorkLocationSubdivisionEnum.COUNTRY,
      WorkLocationSubdivisionEnum.CITY,
    ],
    header: 'Location',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        header: 'Country',
        select: (items: ExplorerDataMapItem[]) => {
          return filter(items, { type: WorkLocationSubdivisionEnum.COUNTRY });
        },
      },
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        header: 'City',
        select: (items: ExplorerDataMapItem[], parentId?: string) => {
          return filter(items, { type: WorkLocationSubdivisionEnum.CITY, parentId });
        },
      },
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        header: 'Office Name',
        select: (items: ExplorerDataMapItem[], parentId?: string) => {
          return filter(items, { type: WorkLocationSubdivisionEnum.WORK_LOCATION, parentId });
        },
      },
    ],
  },
  [ExplorerDataTypeEnum.GROUP]: {
    ...BASE_FILTER_CONFIG,
    includeUnassignedOption: true,
    header: 'Groups',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.REPORTS_TO]: {
    getApiParamKeys: ['term', ...BASE_API_PARAM_KEYS],
    isPaginated: true,
    paginationType: PaginationType.PAGE,
    CustomHeaderActionComponent: IncludeIndirectReportsCheckbox,
    getApiFunction: getManagersApi,
    entityKey: 'profiles',
    header: 'Reporting Line',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: (profiles: ExplorerDataMapItem[]) => {
          return profiles.map(
            (profile) =>
              ({
                id: profile.id,
                name: profile.fullName,
                type: ExplorerDataTypeEnum.REPORTS_TO,
              } as ExplorerDataMapItem),
          );
        },
      },
    ],
  },
  [ExplorerDataTypeEnum.SKILL]: {
    getApiParamKeys: ['term', ...BASE_API_PARAM_KEYS],
    isPaginated: true,
    paginationType: PaginationType.PAGE,
    getApiFunction: getEndorsedSkillsApi,
    entityKey: 'skills',
    header: 'Skills',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: (skills: Skill[]) => {
          return skills.map((skill) => ({
            id: skill.id,
            name: skill.name,
            type: ExplorerDataTypeEnum.SKILL,
          }));
        },
      },
    ],
  },
  [ExplorerDataTypeEnum.PRIOR_COMPANIES]: {
    getApiParamKeys: ['term', ...BASE_API_PARAM_KEYS],
    isPaginated: true,
    paginationType: PaginationType.PAGE,
    getApiFunction: getProfilePriorCompanyNamesApi,
    entityKey: 'companyNames',
    header: 'Prior Companies',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: (companyNames: ExplorerDataMapItem[]) => {
          return companyNames.map(
            (companyName) =>
              ({
                id: companyName.id,
                name: companyName.title,
                type: ExplorerDataTypeEnum.PRIOR_COMPANIES,
              } as ExplorerDataMapItem),
          );
        },
      },
    ],
  },
  [ExplorerDataTypeEnum.ASPIRATION_DEPARTMENT]: {
    ...BASE_FILTER_CONFIG,
    getApiFunction: getOrganizationalAspirationStaticDataApi,
    header: 'Aspiration-Department',
    transform: (items: ExplorerDataMapItem[]) => {
      return items.map((item) => ({
        ...item,
        type: ExplorerDataTypeEnum.ASPIRATION_DEPARTMENT,
      }));
    },
    includeUnassignedOption: true,
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.ASPIRATION_DIVISION]: {
    ...BASE_FILTER_CONFIG,
    getApiFunction: getOrganizationalAspirationStaticDataApi,
    header: 'Aspiration-Division',
    transform: (items: ExplorerDataMapItem[]) => {
      return items.map((item) => ({
        ...item,
        type: ExplorerDataTypeEnum.ASPIRATION_DIVISION,
      }));
    },
    includeUnassignedOption: true,
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.ASPIRATION_PRODUCT]: {
    ...BASE_FILTER_CONFIG,
    getApiFunction: getOrganizationalAspirationStaticDataApi,
    header: 'Aspiration-Product',
    transform: (items: ExplorerDataMapItem[]) => {
      return items.map((item) => ({
        ...item,
        type: ExplorerDataTypeEnum.ASPIRATION_PRODUCT,
      }));
    },
    includeUnassignedOption: true,
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.ASPIRATION_TEAM]: {
    ...BASE_FILTER_CONFIG,
    getApiFunction: getOrganizationalAspirationStaticDataApi,
    header: 'Aspiration-Team',
    transform: (items: ExplorerDataMapItem[]) => {
      return items.map((item) => ({
        ...item,
        type: ExplorerDataTypeEnum.ASPIRATION_TEAM,
      }));
    },
    includeUnassignedOption: true,
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
      { ...BASE_FILTER_LEVEL_CONFIG, select: defaultChildSelect },
    ],
  },
  [ExplorerDataTypeEnum.ASPIRATION_CITY]: {
    ...BASE_FILTER_CONFIG,
    header: 'Aspiration-City',
    getApiFunction: getOrganizationalAspirationStaticDataApi,
    transform: (items: ExplorerDataMapItem[]) => {
      return items.reduce((aspiredCities, item) => {
        if (item.type === WorkLocationSubdivisionEnum.CITY) {
          const aspiredCity = {
            ...item,
            type: ExplorerDataTypeEnum.ASPIRATION_CITY,
            name: item.fullName || item.name,
          };
          aspiredCities.push(aspiredCity);
        }
        return aspiredCities;
      }, [] as ExplorerDataMapItem[]);
    },
    levels: [{ ...BASE_FILTER_LEVEL_CONFIG, header: 'City' }],
  },
  [ExplorerDataTypeEnum.ASPIRATION_SKILL]: {
    getApiParamKeys: ['term', ...BASE_API_PARAM_KEYS],
    isPaginated: true,
    paginationType: PaginationType.PAGE,
    getApiFunction: getAspiredSkillsApi,
    entityKey: 'skills',
    header: 'Aspiration-Skills',
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: (skills: Skill[]) => {
          return skills.map((skill) => ({
            id: skill.id,
            name: skill.name,
            type: ExplorerDataTypeEnum.ASPIRATION_SKILL,
          }));
        },
      },
    ],
  },
  [ExplorerDataTypeEnum.EVENT_TYPE]: {
    ...BASE_FILTER_CONFIG,
    getApiFunction: getOrganizationEventTypesHierarchy,
    header: 'Event Type',
    includeUnassignedOption: false,
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultTopLevelSelect,
      },
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultChildSelect,
      },
      {
        ...BASE_FILTER_LEVEL_CONFIG,
        select: defaultChildSelect,
      },
    ],
  },
  [ExplorerDataTypeEnum.WIN_PERIOD]: {
    getApiParamKeys: ['term', ...BASE_API_PARAM_KEYS],
    getApiFunction: searchWinPeriodsApi,
    entityKey: 'winPeriod',
    header: 'Quarter',
    includeUnassignedOption: false,
    transform: (items: ExplorerDataMapItem[]) => {
      return items.map((item) => ({
        ...item,
        type: ExplorerDataTypeEnum.WIN_PERIOD,
      }));
    },
    levels: [
      {
        ...BASE_FILTER_LEVEL_CONFIG,
      },
    ],
  },
};
