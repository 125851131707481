import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Stack, Typography } from 'common/components/material';
import Autocomplete from 'common/components/material/Autocomplete';
import { AutocompleteProps } from 'common/types/Form.type';
import { ToggleVisibilityContainer } from 'common/components/container';

type MultiTextInputProps = Omit<AutocompleteProps, 'options' | 'onChange'> & {
  items: string[];
  itemCountLabel?: string;
  delimiter?: string | RegExp;
  onChange: (items: string[]) => void;
};

// Matches commas or whitespace
const DEFAULT_DELIMITER = /,|\s/g;

const StyledTextInput = styled(Autocomplete)({
  '& .MuiInputBase-root': {
    alignContent: 'baseline',
    alignSelf: 'baseline',
    minHeight: '8rem',
  },
});

const MultiTextInput: React.FC<MultiTextInputProps> = ({
  formFieldProps,
  onChange,
  items,
  delimiter = DEFAULT_DELIMITER,
  itemCountLabel,
  ...multiTextInputProps
}) => {
  const [inputValue, setInputValue] = useState('');

  const createItems = (values: string[]) => {
    if (!values.length) {
      return;
    }

    const updatedItems = [...items, ...values];

    onChange(updatedItems);
    setInputValue('');
  };

  // Push input to the array of items if it detects a delimiter
  const updateInputValue = (e: React.SyntheticEvent, value: string) => {
    if (value.match(delimiter)) {
      const valueArray = value.split(delimiter).filter((item) => !!item);
      createItems(valueArray);
    } else {
      setInputValue(value);
    }
  };

  const handleBlur = () => {
    const value = inputValue ? [inputValue] : [];
    createItems(value);
  };

  return (
    <Stack spacing={1}>
      <ToggleVisibilityContainer isVisible={!!items.length}>
        <Typography display="block" textAlign="right" variant="caption">
          {items.length} {itemCountLabel || 'item(s)'} selected
        </Typography>
      </ToggleVisibilityContainer>
      <StyledTextInput
        multiple
        freeSolo
        options={[]}
        onInputChange={updateInputValue}
        getOptionLabel={(option: any) => option}
        inputValue={inputValue}
        value={items}
        onChange={onChange}
        onBlur={handleBlur}
        sx={{ alignContent: 'baseline', alignItems: 'baseline' }}
        {...multiTextInputProps}
      />
    </Stack>
  );
};

export default MultiTextInput;
