import React from 'react';

import { AvatarGroup, Tooltip } from 'common/components/material';
import { UserAvatarGroupProps } from '../types/user-avatar-props.type';
import { DefaultAvatar, StyledAvatarCount } from '../styles/user-avatar.styles';

export const UserAvatarGroup: React.FC<UserAvatarGroupProps> = ({
  persons = [],
  onClick,
  total = persons.length,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick && onClick(e);
  };
  const style = onClick && { cursor: 'pointer' };
  const remainingReactions = total - persons.length;
  return (
    <AvatarGroup sx={style} spacing={13} onClick={handleClick} max={4}>
      {persons?.map((person, id) => (
        <Tooltip key={`${person?.id}-${id}`} title={person?.fullName || ''}>
          <DefaultAvatar
            size="small"
            alt={person?.fullName}
            sx={{ zIndex: id + 1 }}
            src={person?.photo}
          />
        </Tooltip>
      ))}
      {remainingReactions > 0 && <StyledAvatarCount>+{remainingReactions}</StyledAvatarCount>}
    </AvatarGroup>
  );
};
