import SvgIcon from '@mui/material/SvgIcon';
import InsertPhoto from '@mui/icons-material/InsertPhoto';
import LinkIcon from '@mui/icons-material/Link';
import Videocam from '@mui/icons-material/Videocam';
import { Palette } from '@mui/material/styles';
import { IMAGE_UPLOADER_PROMPT_STATES } from '../media.constants';
import { Stack } from 'common/components/material';

export const getTitleAndSubLabelColor = (
  palette: Palette,
  isActive?: boolean,
  isDisabled?: boolean,
  isSublabel?: boolean,
) => {
  if (isDisabled) {
    return palette.Buttons.Tertiary.LabelDisabled;
  }

  return isSublabel
    ? palette.Tabs.Secondary.LabelDefault
    : isActive
    ? palette.Tabs.Secondary.LabelClicked
    : palette.Text.Headline;
};

export const getStyledIcon = (
  Icon: typeof SvgIcon,
  palette: Palette,
  isActive?: boolean,
  isDisabled?: boolean,
) => {
  const isEnabled = isActive || !isDisabled;
  const bgcolor = isEnabled
    ? palette.Buttons.Secondary.BgDefault
    : palette.Buttons.Secondary.BgDisabled;
  return (
    <Stack
      width="2.625rem"
      height="2.625rem"
      bgcolor={bgcolor}
      justifyContent="center"
      alignItems="center"
      borderRadius=".375rem"
    >
      <Icon
        sx={{
          fontSize: '1.5rem',
          color: isEnabled
            ? palette.LinkText.Active
            : palette.Buttons.Secondary.LabelDisabled,
        }}
      />
    </Stack>
  );
};

export const getPhotoConfig = (
  palette: Palette,
  uploaderState: string,
  isActive?: boolean,
  isThumbnail?: boolean,
  imageName?: string,
  isDisabled?: boolean,
) => {
  switch (uploaderState) {
    case IMAGE_UPLOADER_PROMPT_STATES.BROWSE:
      return {
        iconElement: getStyledIcon(InsertPhoto, palette, isActive, isDisabled),
        title: isThumbnail ? 'Add Thumbnail (Optional)' : 'Add Image (Max 5MB) ',
        subLabel: `recommended 564 X 295px`,
        titleColor: getTitleAndSubLabelColor(palette, isActive, isDisabled, false),
        subLabelColor: getTitleAndSubLabelColor(palette, isActive, isDisabled, true),
      };
    case IMAGE_UPLOADER_PROMPT_STATES.SUCCESS:
      return {
        icon: `/images/success.svg`,
        title: imageName,
        subLabel: `Remove`,
        titleColor: palette.Text.Headline,
        subLabelColor: palette.SpecialButtons.Delete.LabelPressed,
      };
    default:
      return {
        icon: `/images/unsuccessful.svg`,
        title: 'Unsuccessful',
        subLabel: `Browse`,
        titleColor: palette.Text.Headline,
        subLabelColor: palette.Buttons.Secondary.SecondaryLabel,
      };
  }
};

export const getVideoConfig = (
  palette: Palette,
  isActive?: boolean,
  isDisabled?: boolean,
) => {
  return {
    title: 'Link a Video',
    subLabel: 'Add URL',
    iconElement: getStyledIcon(Videocam, palette, isActive, isDisabled),
    titleColor: getTitleAndSubLabelColor(palette, isActive, isDisabled),
    subLabelColor: getTitleAndSubLabelColor(palette, isActive, isDisabled, true),
  };
};

export const getWeblinkConfig = (
  palette: Palette,
  isActive?: boolean,
  isDisabled?: boolean,
) => {
  return {
    title: 'Link a page',
    subLabel: 'Add URL',
    iconElement: getStyledIcon(LinkIcon, palette, isActive, isDisabled),
    titleColor: getTitleAndSubLabelColor(palette, isActive, isDisabled),
    subLabelColor: getTitleAndSubLabelColor(palette, isActive, isDisabled, true),
  };
};
