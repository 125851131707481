import React, { createContext, ReactChild, useCallback, useContext, useState } from 'react';

import { FeaturesEnum } from './features.enum';
import { SystemFeatureAccessEnum } from './enums/feature-access.enum';
import { DEFAULT_SYSTEM_FEATURE_ACCESS } from './feature-access.constants';

export const FeatureAccessContext = createContext({} as Record<string, any>);

export const useFeatureAccessContext = () => useContext(FeatureAccessContext);

export const FeatureAccessProvider: React.FC<{ children: ReactChild }> = (props) => {
  const [featureAccess, setFeatureAccess] = useState({} as Record<string, any>);
  const [featuresEnabled, setFeatureEnabled] = useState([] as string[]);

  const hasAccessPermission = useCallback(
    (
      feature: FeaturesEnum,
      permissionKey: SystemFeatureAccessEnum = SystemFeatureAccessEnum.READ,
    ) => {
      const permission = featureAccess[feature] ?? {};
      return permission[permissionKey] ?? false;
    },
    [featureAccess],
  );

  const getAccessPermission = useCallback(
    (feature: FeaturesEnum): SystemFeatureAccessEnum => {
      return featureAccess[feature] ?? DEFAULT_SYSTEM_FEATURE_ACCESS;
    },
    [featureAccess],
  );

  const checkFeatureEnabled = useCallback(
    (feature: FeaturesEnum) => {
      return featuresEnabled.includes(feature);
    },
    [featuresEnabled],
  );

  const updateFeatureAccess = useCallback(
    (data: Record<string, any>) => {
      const { featureAccess } = data;
      setFeatureAccess(featureAccess);
      setFeatureEnabled(Object.keys(featureAccess));
    },
    [setFeatureAccess, setFeatureEnabled],
  );

  const ctx = {
    hasAccessPermission,
    getAccessPermission,
    checkFeatureEnabled,
    updateFeatureAccess,
  };

  return (
    <FeatureAccessContext.Provider value={ctx}>
      {props.children}
    </FeatureAccessContext.Provider>
  );
};
