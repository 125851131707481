import * as yup from 'yup';
import { TagSchema } from 'features/tags/schemas/tag.schema';
import { validateAccomplishedMonth, validateYear } from './post.utils';

export const PostFormSchema = yup.object({
  title: yup.string().label('title').required('Title is required for a Post.'),
  description: yup.string().label('description').required('Description is required'),
  tags: yup.array().of(TagSchema).label('tags'),
  addToSelfBob: yup.bool().optional(),
  visibleTo: yup.array().of(
    yup
      .object({
        id: yup.string().label('id').required(),
        name: yup.string().label('name').required(),
      })
      .optional(),
  ),
  accomplishedMonth: yup
    .string()
    .label('Accomplished Month')
    .when(['addToSelfBob'], {
      is: true,
      then: yup.string().required('Accomplished Month is required'),
      otherwise: yup.string().nullable(),
    })
    .test('accomplishedYear', 'Accomplished dates must be in the past.', (value, context) =>
      validateAccomplishedMonth(context),
    ),
  accomplishedYear: yup
    .number()
    .label('Accomplished Year')
    .when(['addToSelfBob'], {
      is: true,
      then: yup.number().typeError('').required('Accomplished Year is required').nullable(),
      otherwise: yup.number().typeError('').nullable(),
    })
    .test('accomplishedYear', 'Accomplished dates must be in the past.', (value, context) =>
      validateYear(context),
    ),
});
