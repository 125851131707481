import { some, toLower, cloneDeep, get, camelCase } from 'lodash';
import { ExplorerDataFilter } from '../types/explorer-data-filter.type';
import queryClient from 'common/utils/QueryClient';
import { ActiveFilterMap, Filter } from 'features/search/types/filters.type';
import { getParentFilterType } from 'features/search/search.utils';
import {
  ExplorerDataItemType,
  ExplorerDataMapItem,
  HierarchicalDataMapping,
} from '../types/explorer-data.type';
import { ExplorerLevelConfig } from '../types';
import { ExplorerDataTypeEnum } from '../data-explorer.enum';
import { MenuItemArrowDirectionEnum } from '../data-explorer.enum';

export const getColumnData = (
  dataIndex: number,
  dataList: ExplorerDataMapItem[],
  activeDataList: ExplorerDataMapItem[],
  config: ExplorerLevelConfig,
  includeUnassignedOption?: boolean,
) => {
  const activeParent = activeDataList[dataIndex - 1];

  // Child columns only get rendered when their parent is active
  if (dataIndex && !activeParent) {
    return [];
  }

  let columnData = config.select ? config.select(dataList, activeParent?.id) : dataList;

  /**
   * In a hierarchical static data filter, 'unassigned' can be selected
   * as a filter which limits searches to an exact match
   * of the parent filter (excluding child matches).
   */
  if (activeParent && includeUnassignedOption && activeParent.hasResult) {
    columnData = cloneDeep(columnData || []);
    columnData.push({
      id: activeParent.id,
      name: '(unassigned)',
      fullName: `${activeParent.fullName} (unassigned)`,
      excludeChildren: true,
      type: activeParent.type,
      children: [],
    });
  }

  return columnData;
};

export const hasSelectedUnassigned = (
  data: ExplorerDataFilter,
  activeFilterMap: ActiveFilterMap,
  exploreDataType: ExplorerDataTypeEnum,
) => {
  const activeFilterMapKey = getActiveFilterMapKey(
    data.id,
    exploreDataType as ExplorerDataTypeEnum,
  );
  return !data.excludeChildren && activeFilterMap[activeFilterMapKey]?.excludeChildren;
};

export const hasSelectedChild = (
  data: ExplorerDataFilter,
  activeFilterMap: ActiveFilterMap,
  mapping: HierarchicalDataMapping,
  exploreDataType: ExplorerDataTypeEnum,
): boolean => {
  if (!data.children || !mapping) {
    return false;
  }

  if (hasSelectedUnassigned(data, activeFilterMap, exploreDataType)) {
    return true;
  }

  return some(data.children, (childId: string) => {
    const activeFilterMapKey = getActiveFilterMapKey(
      childId,
      exploreDataType as ExplorerDataTypeEnum,
    );
    const childIsSelected = !!activeFilterMap[activeFilterMapKey];
    const childData = mapping[childId];

    if (childIsSelected) {
      return true;
    } else if (childData.children?.length) {
      return hasSelectedChild(childData, activeFilterMap, mapping, exploreDataType);
    }
    return false;
  });
};

export const hasSelectedParent = (
  filter: Filter,
  activeFilterMap: ActiveFilterMap,
  exploreDataType: ExplorerDataTypeEnum,
  staticDataMapping?: HierarchicalDataMapping,
): boolean => {
  const parentId = filter.excludeChildren ? filter.id : filter.parentId;
  const filterKey = getActiveFilterMapKey(
    parentId as string,
    exploreDataType as ExplorerDataTypeEnum,
  );
  const parentIsSelected =
    parentId && activeFilterMap[filterKey] && !activeFilterMap[filterKey].excludeChildren;

  if (parentId && !parentIsSelected && staticDataMapping) {
    const parent = staticDataMapping[parentId];
    const parentFilter = {
      ...parent,
      filterType: getParentFilterType(filter.filterType),
    };

    return hasSelectedParent(
      parentFilter,
      activeFilterMap,
      exploreDataType,
      staticDataMapping,
    );
  }

  return !!parentIsSelected;
};

export const isSelectedFilter = (
  filter: Filter,
  activeFilterMap: ActiveFilterMap,
  exploreDataType: ExplorerDataTypeEnum,
  staticDataMapping?: HierarchicalDataMapping,
) => {
  const parentIsSelected = hasSelectedParent(
    filter,
    activeFilterMap,
    exploreDataType,
    staticDataMapping,
  );

  const activeFilterMapKey = getActiveFilterMapKey(
    filter.id,
    exploreDataType as ExplorerDataTypeEnum,
  );
  const isDirectlySelected = filter.excludeChildren
    ? !!activeFilterMap[activeFilterMapKey]
    : activeFilterMap[activeFilterMapKey] &&
      !activeFilterMap[activeFilterMapKey].excludeChildren;

  return parentIsSelected || isDirectlySelected;
};

export const setStaticDataPlaceholder = (
  queryKey: string[],
  staticData: ExplorerDataMapItem,
) => {
  const queryData = queryClient.getQueryData(queryKey);

  if (!queryData) {
    return;
  }

  const newQueryData = cloneDeep(queryData);
  const staticDataList: ExplorerDataMapItem[] = get(newQueryData, 'data.result', []);
  staticDataList.push(staticData);

  queryClient.setQueryData(queryKey, newQueryData);
};

export const createHierarchicalDataMapping = (
  staticDataList: ExplorerDataMapItem[],
  staticDataType: ExplorerDataItemType,
) => {
  const mapping: HierarchicalDataMapping = {};

  mapping.root = {
    id: '',
    name: '',
    // Populated by ids of static data without a parent
    children: [],
    type: staticDataType,
  };

  staticDataList.forEach((staticData) => {
    const { id, name, fullName, parentId, type, entityData, isVisible } = staticData;

    if (mapping[id]) {
      mapping[id].name = name;
      mapping[id].fullName = fullName;
      mapping[id].parentId = parentId;
      mapping[id].entityData = entityData;
      mapping[id].isVisible = isVisible;
      mapping[id].type = type;
    } else {
      mapping[id] = { ...staticData, children: [] };
    }

    if (parentId) {
      if (!mapping[parentId]) {
        mapping[parentId] = {
          id: parentId,
          // Placeholder. Will be overwritten when the loop gets to the parent
          name: '',
          type,
          children: [],
        };
      }

      mapping[parentId].children?.push(id);
      mapping[parentId].isExpanded = false;
    } else {
      mapping.root.children?.push(id);
      mapping.root.isExpanded = false;
    }
  });

  return mapping;
};

export const getActiveFilterMapKey = (id: string, exploreDataType: ExplorerDataTypeEnum) => {
  if (exploreDataType === ExplorerDataTypeEnum.EVENT_TYPE) {
    const camelCaseDataType = camelCase(exploreDataType);
    return `${id}_${toLower(camelCaseDataType)}`;
  }

  return exploreDataType ? `${id}_${toLower(exploreDataType)}` : `${id}`;
};

export const getMenuItemArrowRotation = (arrowDirection: MenuItemArrowDirectionEnum) => {
  switch (arrowDirection) {
    case MenuItemArrowDirectionEnum.UP:
      return 270;
    case MenuItemArrowDirectionEnum.DOWN:
      return 90;
    default:
      return 0;
  }
};

export const convertExplorerDataToFilter = (data: ExplorerDataMapItem): Filter => {
  return {
    id: data.id,
    name: data.fullName || data.name,
    excludeChildren: data.excludeChildren,
    parentId: data.parentId,
    filterType: data.type,
  };
};
