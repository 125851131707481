import { PropsWithChildren } from 'react';
import TreeItem from './cx-tree-item';
import { CXTreeItemContentProps, CXTreeViewItemRendererProps } from '../types/cx-tree.types';

function CXTreeViewItemRenderer<T>({
  data,
  nodeId,
  customTreeItemProps,
  CustomTreeItemComponent,
}: PropsWithChildren<CXTreeViewItemRendererProps<T>>) {
  const node = data[nodeId] as Record<string, any>;

  return (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      ContentComponent={CustomTreeItemComponent}
      ContentProps={{ node: node, customTreeItemProps } as CXTreeItemContentProps<T>}
    >
      {node.children.length > 0
        ? node.children.map((childNodeId: string) => (
          <CXTreeViewItemRenderer
            key={childNodeId}
            data={data}
            nodeId={childNodeId}
            customTreeItemProps={customTreeItemProps}
            CustomTreeItemComponent={CustomTreeItemComponent}
          ></CXTreeViewItemRenderer>
        ))
        : null}
    </TreeItem>
  );
}

export default CXTreeViewItemRenderer;
