import * as yup from 'yup';

export const EducationSchema = yup.object({
  degree: yup.string().label('Degree').required(),
  fieldOfStudy: yup.string().label('Field of Study').required(),
  institution: yup
    .object({ id: yup.string(), name: yup.string() })
    .typeError('University is a required field')
    .label('University')
    .required(),
  yearOfCompletion: yup
    .number()
    .typeError('')
    .label('Year')
    .transform((year) => (year ? parseInt(year, 10) : null))
    .min(1900, 'Min year is 1900')
    .nullable(true),
});
