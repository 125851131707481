import { useQuery } from 'common/hooks';
import { getSkillsApi } from '../services/skills.service';
import { UseQueryOptions } from 'common/types/Api.type';
import { SearchParams } from 'features/search';

export const useGetSkills = (params: SearchParams, options: UseQueryOptions = {}) => {
  const queryKey = ['skills'];

  if (params.term) {
    queryKey.push(params.term);
  }

  const queryResponse = useQuery(queryKey, () => getSkillsApi(params), {
    showErrorSnackbar: false,
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.skills,
  };
};
