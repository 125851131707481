import { useSearchParams } from 'react-router-dom';
import { useInfiniteQuery, useSession, useEvents } from 'common/hooks';
import {
  createCursorQueryParams,
  getNextCursorParam,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import { getCompanyUpdatesApi } from '../company-updates.service';
import { mapSearchParamsObject } from 'features/search';
import { EVENTS_TO_REFETCH_COMPANY_UPDATES } from '../company-updates-utils';
import { Event } from 'common/hooks/eventHooks/useEvents';

export const useGetCompanyUpdates = ({
  tags = [],
  hasVideo = false,
}: {
  tags: string[];
  hasVideo?: boolean;
}) => {
  const { account: user, hasOrganization } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const filters = mapSearchParamsObject(searchParams);

  if (tags && !tags.includes(user?.organizationId)) {
    filters.tags = tags;
  }

  if (hasVideo) {
    filters.hasVideo = true;
  }

  const getCompanyUpdates = (params: any) => {
    const queryParams = createCursorQueryParams(params.pageParam, {
      term: query,
      ...filters,
    });
    return getCompanyUpdatesApi(queryParams);
  };

  const queryKey = ['company-updates', user?.organizationId, filters];

  if (query) {
    queryKey.push(query);
  }

  const queryResponse = useInfiniteQuery(queryKey, getCompanyUpdates, {
    getNextPageParam: getNextCursorParam,
    entityKey: 'companyUpdates',
    enabled: hasOrganization,
  });

  useEvents(EVENTS_TO_REFETCH_COMPANY_UPDATES as Event[], () => {
    queryResponse.refetch();
  });

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  return {
    ...queryResponse,
    data,
  };
};
