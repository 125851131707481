import { Palette } from '@mui/material/styles';
import { Option as OptionType } from 'common/components/input/StatusDropdownButton';

export const getProjectStatusOptions = (palette: Palette): OptionType[] => {
  return [
    {
      value: 'OPEN',
      label: 'OPEN',
      dropdownLabel: 'OPEN',
      bgColor: palette.StatusDropdown.Open.BgDefault,
      color: palette.StatusDropdown.Open.LabelAndIconDefault,
    },
    {
      value: 'DONE',
      label: 'DONE',
      dropdownLabel: 'DONE',
      bgColor: palette.StatusDropdown.Closed.BgDefault,
      color: palette.StatusDropdown.Closed.LabelAndIconDefault,
    },
  ];
};

/* Last minute changes for Show & Tell */
export const PROJECT_DRAWER_SUBTITLE =
  'Assign a Win or post an Opportunity to the entire organization.';

export const SEND_FEEDBACK_ICON_LINK = '/images/sendFeedbackIcon.svg';
export const SEND_FEEDBACK_TITLE = 'Send Feedback';
export const MAX_OPEN_WIN = 3;
export const WIN_TITLE = `What's Important Now (WIN)`;
