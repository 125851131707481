import { isEmpty } from 'lodash';
import React from 'react';

import ApartmentRounded from '@mui/icons-material/ApartmentRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useTheme } from '@mui/material/styles';
import { Avatar, Link, Stack, Tooltip } from 'common/components/material';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { AVATAR_SIZE_PX, StyledChip, TagLabel } from '../styles/tag-chip.styles';
import { TagChipProps } from '../types/tag-chip-props.type';
import { getOptimizedImageUrl } from 'features/media/media.utils';

const TagChip: React.FC<TagChipProps> = ({ tag, variant = 'outlined', ...props }) => {
  const { palette } = useTheme();

  const tagName = tag.displayName;
  const tagCompleteName = tag.completeName || tag.displayName;

  const content = (
    <Tooltip title={tagCompleteName}>
      <Stack direction="row" alignItems="center" spacing={1} paddingLeft={0.1}>
        <TagLabel className="tag-label" variant="body4" fontWeight={500}>
          {tagName}
        </TagLabel>
        <ChevronRightRoundedIcon
          sx={{ color: palette.Icons.Tertiary.IconFillDefault }}
          className="tag-icon"
        />
      </Stack>
    </Tooltip>
  );

  const iconURL = getOptimizedImageUrl(tag.icon, AVATAR_SIZE_PX, AVATAR_SIZE_PX);
  const tagLinkProps = !tag.readonly
    ? {}
    : {
        component: Link,
        href: AppRoutesEnum.STATIC_DATA_SUMMARY,
        params: { staticDataId: tag.id, staticDataType: tag.type },
        state: { resetHistoryIndex: false },
      };

  return (
    <StyledChip
      clickable
      avatar={
        !isEmpty(tag.icon) ? (
          <Avatar
            src={iconURL ?? tag.icon}
            alt={tagName}
            sx={{ backgroundColor: palette.EmptyPlaceholderIcons.Background }}
            imgProps={{ loading: 'lazy' }}
          ></Avatar>
        ) : undefined
      }
      icon={isEmpty(tag.icon) ? <ApartmentRounded /> : undefined}
      label={content}
      variant={variant}
      key={tag.id}
      sx={{
        color: tag.highlighted ? palette.StaticDataChip.TextActive : undefined,
        border: tag.highlighted
          ? `1px solid ${palette.StaticDataChip.TextActive}`
          : undefined,
      }}
      {...{
        // Attempting to directly set the `component` prop in `StyledChip` results in a compilation error,
        // even though the `Chip` component itself supports the `component` prop.
        ...tagLinkProps,
        ...props,
      }}
    />
  );
};

export default TagChip;
