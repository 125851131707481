import { useContext, useEffect, useRef } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { History, Transition } from 'history';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * Returns a Transition object with a the retry method which will unblock
 * and retry the blocked navigation attempt.
 *
 * Based on: https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 */

const useBlocker = (blocker: Function, shouldBlock = true) => {
  const { navigator } = useContext(NavigationContext);

  const unblockRef = useRef<() => void>();
  const transitionRef = useRef({});

  useEffect(() => {
    if (!shouldBlock) {
      return;
    }

    unblockRef.current = (navigator as History).block((transition: Transition) => {
      transitionRef.current = {
        ...transition,
        retry() {
          unblockRef.current?.();
          transition.retry();
        },
      };

      blocker(transitionRef.current);
    });

    return () => {
      unblockRef.current?.();
    };
  }, [navigator, blocker, shouldBlock]);

  return transitionRef.current as Transition;
};

export default useBlocker;
