import React from 'react';

const EmptyStaticDataImage: React.FC = () => {
  return (
    <img
      height={56}
      width={56}
      src="/images/emptyStaticData.svg"
      alt="No related pages found"
    />
  );
};

export default EmptyStaticDataImage;
