import { PaginatedExplorerProps } from '../types/paginated-explorer-props.type';
import BaseDataExplorer from './base-data-explorer.component';
import HierarchicalExplorerEmptyView from './hierarchical-explorer-empty-view.component';
import { useGetPaginatedExplorerData } from '../hooks/useGetPaginatedExplorerData';
import InfiniteScrollContainer from 'features/org-root/components/InfiniteScrollContainer';
import BaseDataExplorerColumn from './base-data-explorer-column.component';
import { List } from 'common/components/material';
import FilterListItem from 'features/explore/components/FilterListItem';
import { useDebounce } from 'common/hooks';
import { FILTER_EXPLORER_CONFIG } from '../filter-explorer.config';
import { transformActiveFiltersMapToObject } from 'features/search';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';

const PaginatedExplorer: React.FC<PaginatedExplorerProps> = ({
  dataType,
  headerActions,
  secondaryHeaderActions,
  activeFilterMap,
  searchTerm = '',
  showTitle,
  onClickBack,
  onItemAction,
  contentType = ExploreTabEnum.PEOPLE,
}) => {
  const config = FILTER_EXPLORER_CONFIG[dataType];
  const { header, levels, excludeFilterTypes } = config;
  // PaginatedExplorer only has 1 level
  const levelConfig = levels?.[0] || {};
  const debouncedSearchTerm = useDebounce(searchTerm);

  const selectedSearchFilters = transformActiveFiltersMapToObject(
    activeFilterMap,
    excludeFilterTypes || [dataType],
  );

  return (
    <BaseDataExplorer
      title={showTitle ? header : ''}
      onClickBack={onClickBack}
      headerActions={headerActions}
      secondaryHeaderActions={secondaryHeaderActions}
    >
      <BaseDataExplorerColumn>
        <List disablePadding>
          <InfiniteScrollContainer
            key={dataType}
            infiniteApiHook={useGetPaginatedExplorerData}
            InfiniteListContainer={({ data = [] }) => {
              if (!data.length) {
                return (
                  <HierarchicalExplorerEmptyView
                    isReadView={true}
                    searchTerm={debouncedSearchTerm}
                  />
                );
              }

              const dataList = levelConfig.select ? levelConfig.select(data) : data;
              return (
                <>
                  {dataList.map((data: any) => (
                    <FilterListItem
                      key={data.id}
                      data={data}
                      dataType={dataType}
                      activeFilterMap={activeFilterMap}
                      labelKey={levelConfig.itemLabelKey}
                      config={levelConfig}
                      onItemAction={(itemAction) =>
                        onItemAction({ ...itemAction, type: dataType })
                      }
                    />
                  ))}
                </>
              );
            }}
            showFooter={false}
            apiParams={{
              config,
              type: dataType,
              term: debouncedSearchTerm,
              contentType,
              ...selectedSearchFilters,
            }}
          />
        </List>
      </BaseDataExplorerColumn>
    </BaseDataExplorer>
  );
};

export default PaginatedExplorer;
