import React from 'react';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import { InputValidationIndicatorProps } from 'common/types/Form.type';
import { CircularProgress, InputAdornment } from 'common/components/material';

const SuccessIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const ErrorIcon = styled(ErrorOutline)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const InputValidationIndicator: React.FC<InputValidationIndicatorProps> = ({
  isValid,
  isLoading,
  isError,
}) => {
  if (!isValid && !isLoading && !isError) {
    return <></>;
  }

  let indicator;

  if (isValid) {
    indicator = <SuccessIcon />;
  } else if (isError) {
    indicator = <ErrorIcon />;
  } else {
    indicator = <CircularProgress size={24} />;
  }

  return (
    <InputAdornment position="end" sx={{ px: 1 }}>
      {indicator}
    </InputAdornment>
  );
};

export default InputValidationIndicator;
