import React from 'react';

import MUILinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

const LinearProgress: React.FC<LinearProgressProps> = (props) => {
  const { children, ...linearProgressProps } = props;

  return <MUILinearProgress {...linearProgressProps}>{children}</MUILinearProgress>;
};

export default LinearProgress;
