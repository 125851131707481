import React from 'react';

import Button, { ButtonProps } from 'common/components/material/Button';

export const UpdateCityButton: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <Button
      btntype="label"
      size="small"
      onClick={onClick}
      {...props}
    >
      Update City
    </Button>
  );
};
