export enum FeedTypeEnum {
  COMPANY_UPDATE = 'COMPANY_UPDATE',
  NEW_JOINER = 'NEW_JOINER',
  OPPORTUNITY = 'OPPORTUNITY',
  PRAISE = 'PRAISE',
  PROJECT = 'PROJECT',
  REVIEW = 'REVIEW',
  ORGANIZATION_EVENT = 'ORGANIZATION_EVENT',
  POST = 'POST',
  WIN_LIST = 'WIN_LIST',
}
