import * as yup from 'yup';

export const ReviewSubmitFormSchema = yup.object({
  highStdSkills: yup
    .array()
    .label('High Standard Skills')
    .min(1, 'Atleast 1 High Standard Skill is required')
    .required('High Standard Skills are required'),
  lowStdSkills: yup
    .array()
    .label('Low Standard Skills')
    .min(1, 'Atleast 1 Low Standard Skill is required')
    .required('Low Standard Skills are required'),
  rating: yup.number().label('Rating').required('Rating is required'),
  comment: yup.string().label('Comment').required('Comment are required'),
});
