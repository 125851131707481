import { Palette, Theme, styled } from '@mui/material/styles';
import Chip from 'common/components/material/Chip';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const getBaseChipStyle = (theme: Theme) => {
  return {
    maxWidth: '100%',
    boxShadow: AppBoxShadows.shadow1,
    padding: '1px',
    border: `1px solid ${theme.palette.SkillChip.StrokeDefault}`,
    '& .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...theme.typography.body4,
      padding: '8px',
      height: '24px',
      borderRadius: '16px',
    },
    '&.MuiChip-filled': {
      backgroundColor: theme.palette.SelectionChips.Primary.BgDefault,
      border: 'none',
    },
    '& .MuiChip-deleteIcon': {
      marginRight: '0.625rem',
    },
    '&:hover.MuiChip-root': {
      backgroundColor: theme.palette.SkillChip.BgDefault,
      boxShadow: AppBoxShadows.shadow2,
    },
    '&.MuiChip-deletable, &:hover.MuiChip-deletable': {
      boxShadow: 'none',
    },
    '&:hover.MuiChip-filled': {
      backgroundColor: theme.palette.SelectionChips.Primary.BgHover,
    },
  };
};

const getCXChipBackgroundColor = (variant: string = 'default', palette: Palette) => {
  switch (variant) {
    case 'filled':
      return palette.SelectionChips.Primary.BgDefault;
    case 'outlined':
      return palette.SelectionChips.Secondary.BgDefault;
    default:
      return palette.Badge.BgDefault;
  }
};

const getCXChipColor = (variant: string = 'default', palette: Palette) => {
  switch (variant) {
    case 'filled':
      return palette.SelectionChips.Primary.LabelAndIconDefault;
    case 'outlined':
      return palette.SelectionChips.Secondary.LabelAndIconDefault;
    default:
      return palette.Badge.CountDefault;
  }
};

export const StyledChip = styled(Chip)(({ variant, theme }) => ({
  backgroundColor: getCXChipBackgroundColor(variant, theme.palette),
  color: getCXChipColor(variant, theme.palette),
  padding: '0 2px 1px',
  height: '1.2rem',
  '&:hover': {
    backgroundColor: theme.palette.Badge.BgHover,
    color: theme.palette.Badge.CountHover,
  },
}));
