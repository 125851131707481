import React from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import { StyledSearchOption } from 'common/components/navigation/styles';
import { StaticDataSearchOption } from './static-data-search-option.component';
import { Stack, Typography } from 'common/components/material';
import { ListItemText } from 'common/components/material/ListItemButton';
import { ProfileSearchOption } from './profile-search-option.component';
import { useTheme } from '@mui/material';
import { ISearchSuggestion } from '../interfaces/search-suggestion.interface';
import { IStaticDataSearchSuggestion } from 'features/static-data/interfaces/static-data-search-suggestion.interface';
import { IProfileSearchSuggestion } from 'features/profiles/interfaces/profile-search-suggestion.interface';

export const RecentSearchOption: React.FC<{
  recentSearch: ISearchSuggestion;
  active?: boolean;
  onClick?: (
    selectedSuggestion?: ISearchSuggestion,
    navigationChangePending?: boolean,
  ) => void;
}> = ({ recentSearch, active, onClick }) => {
  const { palette } = useTheme();

  switch (recentSearch.type) {
    case 'PROFILE':
      const profile = recentSearch.data as IProfileSearchSuggestion;
      return (
        <ProfileSearchOption
          profile={profile}
          active={active}
          onClick={() => onClick && onClick(recentSearch)}
        />
      );
    case 'STATIC_DATA':
      const staticData = recentSearch.data as IStaticDataSearchSuggestion;
      return (
        <StaticDataSearchOption
          staticData={staticData}
          active={active}
          onSelect={(_, navigationChangePending) =>
            onClick && onClick(recentSearch, navigationChangePending)
          }
        />
      );
    case 'TEXT':
      return (
        <StyledSearchOption
          dense={true}
          sx={{
            paddingY: '1px',
            backgroundColor: active ? palette.DropdownList.Hover : undefined,
          }}
          onClick={() => onClick && onClick(recentSearch)}
        >
          <ListItemText>
            <Stack
              direction="row"
              color={palette.Text.Caption}
              display="flex"
              alignItems="center"
              spacing={1}
            >
              <RestoreIcon />
              <Typography width="100%" variant="body4">
                {recentSearch.data}
              </Typography>
            </Stack>
          </ListItemText>
        </StyledSearchOption>
      );
    default:
      return <></>;
  }
};
