import React, { useState } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';
import { camelCase, isNil } from 'lodash';
import ApartmentRounded from '@mui/icons-material/ApartmentRounded';

import { titleCase } from 'common/utils/app.utils';
import { LinkTileContainer } from 'common/components/link-tile-with-avatar/link-tile-container.component';
import { RelatedPage } from '../types/related-page.type';
import LinkTileWithAvatarContent from 'common/components/link-tile-with-avatar/link-tile-with-avatar-content.component';
import { Link, Stack } from 'common/components/material';
import { UserAvatarGroup } from 'features/profiles/components/user-avatar-group';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { useRouter } from 'common/hooks';
import { KeyContact } from 'features/key-contacts/types/key-contact';
import KeyContactsModal from './key-contacts-modal.component';

const StaticDataPageLinkItem: React.FC<{
  relatedPage: RelatedPage;
  showCompleteName?: boolean;
  onClickKeyContacts?: (keyContacts: KeyContact[]) => void;
}> = ({ relatedPage, showCompleteName = false, onClickKeyContacts }) => {
  const { id, name, completeName, type, photo, keyContacts, profileCount } = relatedPage;
  const hasKeyContacts = !!keyContacts.length;

  const [openKeyContactsModal, setOpenKeyContactsModal] = useState(false);

  const { palette } = useTheme();
  const { getOrgRoute, goTo } = useRouter();
  const relatePageRoute = getOrgRoute(AppRoutesEnum.STATIC_DATA_SUMMARY, {
    staticDataId: id,
    staticDataType: type,
  });

  const typeProp = camelCase(type);
  const profileSearchRoute = getOrgRoute(
    AppRoutesEnum.EXPLORE_PEOPLE,
    {},
    {
      [typeProp]: id,
    },
  );

  const titleText = showCompleteName ? completeName || name : name;

  /**
   * Page navigation would normally be handled by the LinkTileContainer,
   * but we get a warning when a link (employee count) is placed inside
   * another link (related page). To avoid this, the container itself handles
   * navigation when any part of it other than the inner links are clicked.
   */
  const handleClickContainer = () => {
    goTo(relatePageRoute);
  };

  const handleClickKeyContacts = (e: React.MouseEvent) => {
    // Prevent navigating to related page
    e.preventDefault();
    !isNil(onClickKeyContacts)
      ? onClickKeyContacts(keyContacts)
      : setOpenKeyContactsModal(true);
  };

  return (
    <>
      <Stack
        pb={1}
        overflow="hidden"
        sx={{
          cursor: 'pointer',
          borderRadius: '0.5rem',
          '&:hover': {
            backgroundColor: palette.LinkContainer.BgHover,
          },
        }}
        onClick={handleClickContainer}
      >
        <LinkTileContainer
          route={relatePageRoute}
          showBorder={false}
          sx={{ paddingBottom: '0.25rem', background: 'none' }}
        >
          <LinkTileWithAvatarContent
            title={titleText}
            subtitle={titleCase(type)}
            photo={photo}
            subtitleVariant="caption"
            icon={ApartmentRounded}
          />
        </LinkTileContainer>
        <Stack direction="row" alignItems="center" pl={6.5}>
          {hasKeyContacts && (
            <>
              <UserAvatarGroup
                persons={keyContacts.slice(0, 3)}
                total={keyContacts.length}
                onClick={handleClickKeyContacts}
              />
              <CircleIcon
                sx={{
                  height: '3px',
                  width: '3px',
                  lineHeight: 1,
                  verticalAlign: 'middle',
                  marginX: '0.5rem',
                  color: palette.Text.Caption,
                }}
              />
            </>
          )}
          <Link
            href={profileSearchRoute}
            variant="caption"
            fontWeight={500}
            color={palette.Text.Caption}
            onClick={(e) => e.stopPropagation()}
            sx={{
              '&:hover': {
                color: palette.LinkText.Active,
              },
            }}
          >
            {profileCount.toLocaleString()} employees
          </Link>
        </Stack>
      </Stack>
      {openKeyContactsModal && (
        <KeyContactsModal
          keyContacts={keyContacts}
          open={openKeyContactsModal}
          onClose={() => setOpenKeyContactsModal(false)}
        />
      )}
    </>
  );
};

export default StaticDataPageLinkItem;
