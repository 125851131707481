import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material';

import { OrganizationEvent } from '../types';
import { Box, Divider, Stack, Typography } from 'common/components/material';
import { mapSearchParamsObject } from 'features/search';
import { useSearchParams } from 'react-router-dom';
import { formatDate } from 'common/utils/date-time.utils';

const OrganizationEventPanelListView: React.FC<{
  events: OrganizationEvent[];
  OrganizationEventPanelCardComponent: React.FC<any>;
  date: string;
  scrollIntoView?: boolean;
}> = ({ date, events, OrganizationEventPanelCardComponent, scrollIntoView = true }) => {
  const { palette } = useTheme();
  const dayContainerRef = useRef<HTMLDivElement | null>(null);
  const dayOfMonth = moment(date).format('ddd, DD MMM');

  const [searchParams] = useSearchParams();
  const currentFilters = mapSearchParamsObject(searchParams) as { [prop: string]: any };
  const selectedDate = currentFilters['selectedDate']
    ? currentFilters['selectedDate']
    : moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (scrollIntoView && selectedDate === date && dayContainerRef.current) {
      dayContainerRef.current.scrollIntoView({
        behavior: 'auto',
      });
    }
  }, [scrollIntoView, dayContainerRef, date, selectedDate]);

  return (
    <Stack ref={dayContainerRef}>
      <Box
        paddingX={1.25}
        sx={{
          position: 'sticky',
          top: '0',
          zIndex: 100,
          background: palette.EventTagChip.Background,
        }}
      >
        <Typography variant="body4" fontWeight={500} color={palette.Text.Headline}>
          {dayOfMonth}
          {formatDate(date) === formatDate(new Date()) ? ' | Today' : ''}
        </Typography>
      </Box>

      <Stack spacing={1} p={1}>
        {events?.map((event, index) => {
          const isLastEvent = index === events.length - 1;
          return (
            <Box key={event.id}>
              <OrganizationEventPanelCardComponent event={event} />
              {!isLastEvent && <Divider />}
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default OrganizationEventPanelListView;
