import { useArrayFieldError } from 'common/hooks';
import React, { ForwardedRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CXEditorFieldProps } from '../types/cx-editor-field-props.type';
import { CXEditorRenderer } from './cx-editor-renderer.component';

/**
 * It is a editor text field, which allows developers to integrate it with react form.
 */
export const CXEditorField = React.forwardRef(
  (props: CXEditorFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { formState, control, setError, clearErrors } = useFormContext();
    const { errors } = formState;
    const { errorMessage } = useArrayFieldError(errors?.[props.name], props.name);

    // TODO: implement proper support for adding a label to the component, but also
    // to render the component in a disabled state (CNXT-1494)
    if (props.disabled) {
      throw new Error(
        'Support for rendering a disabled CXEditorField component has not been implemented yet',
      );
    }
    if (props.label && props.placeholder) {
      throw new Error(
        'Support for rendering a CXEditorField component with both a label and a placeholder has not been implemented yet',
      );
    }

    /**
     * Toast-ui editor has addImageBlobHook that is triggered whene user paste an image on editor.
     * Since we have no support to add image from toolbar, so we need to restrict user from pasting
     * any image on it.
     * We are setting error manually whenever user paste an image and clear it out after 5 secs.
     */
    const onImagePaste = () => {
      setError(props.name, { type: 'custom', message: 'File type not supported' });

      setTimeout(() => {
        clearErrors(props.name);
      }, 5000);
    };

    return (
      <Controller
        control={control}
        name={props.name}
        defaultValue={props.value || props.defaultValue}
        render={CXEditorRenderer({
          placeholder: props.placeholder ?? props.label,
          autoFacus: props.autoFocus,
          onChange: props.onChange,
          onAddImageHookTrigger: onImagePaste,
          errorMessage,
          ref,
        })}
      />
    );
  },
);
