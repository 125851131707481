export const PRAISE_LIMIT = 1;
export const PRIASE_DRAWER_TITLE = 'Send Praise';
export const PRAISE_DRAWER_HELPER = 'All Praises are posted on the home feed';
export const PRIASE_EDIT_DRAWER_TITLE = 'Edit Praise';
export const PRAISE_INPUT_NAME = 'description';
export const PRAISE_HEADLINE_MAX_LENGTH = 60;
export const PRAISE_DESC_MAX_LENGTH = 250;
export const PRAISE_INPUT_LABEL = "Share what they've accomplished";
export const PRAISE_INPUT_PLACEHOLDER = `Sample script: “Thank you for going the extra mile by acting as a sounding board for me while I prepared my client proposal! It really made a difference in the end product. I’m happy we're coworkers!”`;
export const PRIASE_SKILLS_INPUT_NAME = 'skills';
export const PRAISE_SKILLS_INPUT_LABEL = 'Demonstrated Skills';
export const PRAISE_LEADERBOARD_DURATION = 90;
