export enum StaticDataTypeEnum {
  INDUSTRIES = 'industries',
  LOCATIONS = 'locations',
  JOB_TITLES = 'jobTitles',
  INSTITUTIONS = 'institutions',
}

export enum OrganizationalStaticDataTypeEnum {
  DEPARTMENT = 'DEPARTMENT',
  DIVISION = 'DIVISION',
  WORK_LOCATION = 'WORK_LOCATION',
  TEAM = 'TEAM',
  PRODUCT = 'PRODUCT',
  GROUP = 'GROUP',
}

export enum OrganizationalAspirationStaticDataTypeEnum {
  ASPIRATION_DEPARTMENT = 'ASPIRATION_DEPARTMENT',
  ASPIRATION_DIVISION = 'ASPIRATION_DIVISION',
  ASPIRATION_CITY = 'ASPIRATION_CITY',
  ASPIRATION_TEAM = 'ASPIRATION_TEAM',
  ASPIRATION_PRODUCT = 'ASPIRATION_PRODUCT',
  ASPIRATION_SKILL = 'ASPIRATION_SKILL',
}

export enum OrganizationalTagPagesTypeEnum {
  DEPARTMENT = 'DEPARTMENT',
  DIVISION = 'DIVISION',
  WORK_LOCATION = 'WORK_LOCATION',
  TEAM = 'TEAM',
  PRODUCT = 'PRODUCT',
  GROUP = 'GROUP',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
}

export enum WorkLocationSubdivisionEnum {
  COUNTRY = 'COUNTRY',
  CITY = 'CITY',
  WORK_LOCATION = 'WORK_LOCATION',
}

export const ORDERED_PROFILE_STATIC_DATA = [
  OrganizationalStaticDataTypeEnum.WORK_LOCATION,
  OrganizationalStaticDataTypeEnum.DEPARTMENT,
  OrganizationalStaticDataTypeEnum.PRODUCT,
  OrganizationalStaticDataTypeEnum.DIVISION,
  OrganizationalStaticDataTypeEnum.TEAM,
];

export enum StaticDataExplorerTypeEnum {
  FILTER = 'FILTER',
  MANAGER = 'MANAGER',
}
