import React from 'react';

import { MUIRef } from 'common/types/MUIRef.type';

import { UserAvatarProps } from '../types/user-avatar-props.type';
import { CXAvatar } from 'common/components/cx-avatar/cx-avatar';
import { StatusIndicator } from '../styles/user-avatar.styles';

export const UserAvatar: React.FC<UserAvatarProps> = React.forwardRef(
  (
    { fullName, src, size = 'medium', showStatusIndicator = false, ...avatarProps },
    ref: MUIRef,
  ) => {
    return (
      <StatusIndicator
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        invisible={!showStatusIndicator}
      >
        <CXAvatar src={src} alt={fullName} size={size} ref={ref} {...avatarProps} />
      </StatusIndicator>
    );
  },
);
