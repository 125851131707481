import React from 'react';

import { useSession } from 'common/hooks';
import { validateUrlProtocol } from 'common/utils/app.utils';

import { QuickLinkItem } from 'features/quick-links/styles';
import QuickLinkLabel from 'features/quick-links/components/quick-link-label.component';
import { useGetQuickLinks } from 'features/quick-links/hooks/useGetQuickLinks';
import { QuickLinkTypeEnum } from 'features/quick-links/quick-link-type.enum';
import { StyledListItem } from './styles/menu-item-button.styles';
import MenuItemsHeader from './menu-items-header.component';

const QuickLinkMenuItems: React.FC = () => {
  const { account: user } = useSession();

  const { data: quickLinks, isLoading } = useGetQuickLinks({
    linkedEntityId: user?.organizationId,
    quickLinkType: QuickLinkTypeEnum.ORGANIZATION,
  });

  const openLink = (url: string, target: any) => {
    const validatedUrl = validateUrlProtocol(url);
    window.open(validatedUrl, target);
  };

  if (isLoading || !quickLinks.length) {
    return <></>;
  }

  return (
    <MenuItemsHeader label="Company Quick Links" dense>
      {quickLinks.map((link) => {
        return (
          <QuickLinkItem
            onClick={() => openLink(link.url, '_blank')}
            sx={{ paddingLeft: '0rem' }}
            key={link.id}
          >
            <StyledListItem dense>
              <QuickLinkLabel rawLabel={link.label} subLabel={link.description} />
            </StyledListItem>
          </QuickLinkItem>
        );
      })}
    </MenuItemsHeader>
  );
};

export default QuickLinkMenuItems;
