import { IdentityProviderTypeEnum } from 'common/enum/IdentityProviderType.enum';
import { useLocalStorage, useMutation, useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import axios from 'common/utils/axios';

const AuthApi = axios('auth');

const switchOrganizationApi = (organizationId: string, verificationCode?: string) => {
  return AuthApi.post('switch-organization', { organizationId, verificationCode });
};

export const useSwitchOrganization = () => {
  const { updateOrganization, goTo } = useRouter();
  const { setItemValue: setRecentSubUrl } = useLocalStorage('recent-suburl');
  const { setOrganizationOnSession } = useSession();
  const { value: recentEmail } = useLocalStorage('recent-email');

  return useMutation(switchOrganizationApi, {
    onSuccess: (res) => {
      const hasSetPassword = res?.data?.hasSetPassword;
      const isAuthenticated = res?.data?.isAuthenticated;
      const identityProvider = res?.data?.identityProvider;
      const organizationId = res?.data?.organizationId;
      const subUrl = res?.data?.subUrl;

      if (isAuthenticated) {
        setOrganizationOnSession(organizationId, subUrl);
        updateOrganization(subUrl);
        setRecentSubUrl(subUrl);
        return;
      }

      if (!isAuthenticated) {
        if (identityProvider?.type !== IdentityProviderTypeEnum.PASSWORD) {
          window.location.replace(identityProvider?.authenticationUrl);
        } else {
          setOrganizationOnSession(organizationId, subUrl);
          if (!hasSetPassword) {
            goTo(
              AppRoutesEnum.ACCOUNTS_INCOMPLETE_SETUP,
              {},
              { state: { email: recentEmail } },
            );
          }
        }

        return;
      }
    },
  });
};
