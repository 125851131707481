import { ManageProjectRequest, WinPeriod } from 'features/projects';
import { useCallback, useEffect, useState } from 'react';
import { find } from 'lodash';
import { MAX_WIN_LIMIT } from '../utils/wins-expiration.utils';

export const useManageDraftWins = (nextPeriod: WinPeriod) => {
  const [draftProjects, setDraftProjects] = useState<ManageProjectRequest[]>([]);
  const [isMaxLimitReached, setMaxLimitReached] = useState<boolean>(false);

  useEffect(() => {
    setMaxLimitReached(draftProjects.length >= MAX_WIN_LIMIT);
  }, [draftProjects.length]);

  const createProject = (project: ManageProjectRequest) => {
    if (draftProjects.length > MAX_WIN_LIMIT) {
      return;
    }
    setDraftProjects((existingProjects) => [
      ...existingProjects,
      { ...project, sourceProjectId: project?.id, period: nextPeriod },
    ]);
  };

  const removeProject = (projectToRemove: ManageProjectRequest) => {
    setDraftProjects((existingProjects) =>
      existingProjects.filter((project) =>
        projectToRemove.id
          ? project.id !== projectToRemove.id
          : project.title !== projectToRemove.title,
      ),
    );
  };

  const checkIfProjectAlreadyCopied = useCallback(
    (project: ManageProjectRequest): boolean => {
      return !!find(draftProjects, { id: project.id });
    },
    [draftProjects],
  );

  return {
    isMaxLimitReached,
    draftProjects,
    createProject,
    removeProject,
    checkIfProjectAlreadyCopied,
  };
};
