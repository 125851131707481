import React, { useEffect } from 'react';

import FormTextField from './FormTextField';
import { InputAdornment } from 'common/components/material';
import InputValidationIndicator from 'common/components/input/InputValidationIndicator';
import { useApiValidationError, useDebounce, useDeferredState } from 'common/hooks';
import { validateMinLength } from 'common/utils/app.utils';
import * as _ from 'lodash';
import { SubUrlInputProps, useVerifyCompanySubUrl } from 'features/companies';

const DEFAULT_NAME = 'subUrl';
const DEFAULT_LABEL = 'ConnectX URL';
const SUB_URL_REGEX = /^[a-zA-Z0-9-]+$/;

const SubUrlInput: React.FC<SubUrlInputProps> = ({
  name = DEFAULT_NAME,
  label = DEFAULT_LABEL,
  onChange,
  initialValue,
  shouldValidateInitialValue,
  ...props
}) => {
  const baseUrl = window.location.origin;

  const [inputValue, setInputValue] = useDeferredState(initialValue || '');

  const debouncedInputValue = useDebounce(inputValue);
  const valueChanged = initialValue !== debouncedInputValue;

  const {
    data: subUrlVerificationResult,
    isLoading: isVerifyingSubUrl,
    mutate: verifySubUrlAvailability,
    error,
  } = useVerifyCompanySubUrl();

  useEffect(() => {
    if (
      validateMinLength(debouncedInputValue) &&
      (valueChanged || shouldValidateInitialValue)
    ) {
      verifySubUrlAvailability(debouncedInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  useEffect(() => {
    handleInputChange(initialValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleInputChange = (newSubUrl: string) => {
    setInputValue(newSubUrl);
    onChange && onChange(newSubUrl);
  };

  useApiValidationError(
    !!subUrlVerificationResult || (!valueChanged && !shouldValidateInitialValue),
    name,
    handleInputChange,
  );

  return (
    <FormTextField
      name={name}
      label={label}
      errorMessage={
        valueChanged && !!debouncedInputValue
          ? error?.message || _.get(error, 'errors[0].messages[0]')
          : ''
      }
      onChange={handleInputChange}
      onKeyDown={(event) => {
        if (!SUB_URL_REGEX.test(event.key)) {
          event.preventDefault();
        }
      }}
      value={inputValue}
      {...props}
      InputProps={{
        startAdornment: <InputAdornment position="start">{baseUrl}/</InputAdornment>,
        endAdornment: (
          <InputValidationIndicator
            isValid={!!subUrlVerificationResult && validateMinLength(inputValue)}
            isLoading={isVerifyingSubUrl}
            isError={valueChanged && !!error?.message}
          />
        ),
      }}
    />
  );
};

export default SubUrlInput;
