import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Typography } from 'common/components/material';
import { getEmptyFilterText } from 'features/explore';
import EmptyStaticDataView from 'features/admin/components/EmptyStaticDataView';

type HierarchicalExplorerEmptyViewProps = {
  isReadView: boolean;
  searchTerm: string;
  onClick?: () => void;
};

const HierarchicalExplorerEmptyView: React.FC<HierarchicalExplorerEmptyViewProps> = ({
  isReadView,
  searchTerm,
  onClick,
}) => {
  const { palette } = useTheme();
  if (isReadView) {
    return (
      <Typography color={palette.Text.SubHeadline} m={1} variant="body1">
        {getEmptyFilterText(!!searchTerm)}
      </Typography>
    );
  }

  return <EmptyStaticDataView onClick={onClick} />;
};

export default HierarchicalExplorerEmptyView;
