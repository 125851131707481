export const SIZE_STYLE = {
  xxxsmall: {
    avatar: {
      height: 18,
      width: 18,
    },
    icon: {
      size: 14,
    },
  },
  xxsmall: {
    avatar: {
      height: 26,
      width: 26,
    },
    icon: {
      height: 9.75,
      width: 9.75,
      size: 9.75,
    },
  },
  xsmall: {
    avatar: {
      height: 20,
      width: 20,
    },
    icon: {
      size: 14,
    },
  },
  small: {
    avatar: {
      height: 26,
      width: 26,
    },
    icon: {
      size: 20,
    },
  },
  mediumSmall: {
    avatar: {
      height: 26,
      width: 26,
    },
    icon: {
      size: 16,
      height: 16,
      width: 16,
    },
  },
  medium: {
    avatar: {
      height: 32,
      width: 32,
    },
    icon: {
      size: 26,
    },
  },
  mediumLarge: {
    avatar: {
      height: 42,
      width: 42,
    },
    icon: {
      size: 30,
    },
  },
  large: {
    avatar: {
      height: 56,
      width: 56,
    },
    icon: {
      size: 36,
    },
  },
  xlarge: {
    avatar: {
      height: 86,
      width: 86,
    },
    icon: {
      size: 42,
      fontSize: '2.6rem',
    },
  },
  xxlarge: {
    avatar: {
      height: 115,
      width: 115,
    },
    icon: {
      size: 64,
      fontSize: '3.45rem',
    },
  },
  xxxlarge: {
    avatar: {
      height: 130,
      width: 130,
    },
    icon: {
      size: 82,
    },
  },
};
