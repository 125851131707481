import React from 'react';

import MUIBottomNavigation, { BottomNavigationProps } from '@mui/material/BottomNavigation';
import MUIBottomNavigationAction, {
  BottomNavigationActionProps,
} from '@mui/material/BottomNavigationAction';

export const BottomNavigationAction: React.FC<BottomNavigationActionProps> = (
  bottomNavigationProps,
) => {
  return <MUIBottomNavigationAction {...bottomNavigationProps} />;
};

const BottomNavigation: React.FC<BottomNavigationProps> = (props) => {
  const { children, ...bottomNavigationProps } = props;

  return <MUIBottomNavigation {...bottomNavigationProps}>{children}</MUIBottomNavigation>;
};

export default BottomNavigation;
