import { getMonthIndexFromString } from 'common/utils/date-time.utils';

export const validateAccomplishedMonth = (context: Record<string,any>) => {
  if (!context.parent.addToSelfBob) {
    return true;
  }

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const accomplishedMonth = getMonthIndexFromString(context.parent.accomplishedMonth);

  // If year is in the past, any month is valid
  if (context.parent.accomplishedYear < currentYear) {
    return true;
  }

  // If year is current, month must be less than or equal to current
  return accomplishedMonth <= currentMonth;
};

export const validateYear = (context: Record<string,any>) => {
  if (!context.parent.addToSelfBob) {
    return true;
  }

  return context.parent.accomplishedYear <= new Date().getFullYear();
};
