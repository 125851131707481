import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { styled } from '@mui/material/styles';

import AppBoxShadows from 'common/theme/AppBoxShadows';

const scrollButtonBaseStyle = {
  backgroundColor: 'white',
  width: '2.125rem',
  height: '2.125rem',
  padding: '0.375rem',
  borderRadius: '0.5rem',
  boxShadow: AppBoxShadows.shadow4,
};

//same as CloseButton.tsx style
export const ScrollLeftButton = styled(KeyboardBackspaceIcon)<{
  onClick: (e: React.MouseEvent) => void;
}>(({ theme }) => ({
  ...scrollButtonBaseStyle,
  border: `1px solid ${theme.palette.Container.Stroke}`,
  background: theme.palette.Buttons.Tertiary.BgDefault,
  color: theme.palette.Icons.Tertiary.IconFillDefault,
  '&:hover, &:focus': {
    borderColor: theme.palette.Stroke.Active,
    color: theme.palette.Icons.Tertiary.IconFillHover,
    backgroundColor: theme.palette.LinkContainer.BgHover,
  },
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translate(-75%, -50%)',
}));

export const ScrollRightButton = styled(KeyboardBackspaceIcon)<{
  onClick: (e: React.MouseEvent) => void;
}>(({ theme }) => ({
  ...scrollButtonBaseStyle,
  border: `1px solid ${theme.palette.Container.Stroke}`,
  background: theme.palette.Buttons.Tertiary.BgDefault,
  color: theme.palette.Icons.Tertiary.IconFillDefault,
  '&:hover, &:focus': {
    borderColor: theme.palette.Stroke.Active,
    color: theme.palette.Icons.Tertiary.IconFillHover,
    backgroundColor: theme.palette.LinkContainer.BgHover,
  },
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(75%, -50%) scaleX(-1)',
}));
