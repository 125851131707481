import { useMutation, useDebouncedMutate } from 'common/hooks';
import { verifyCompanyWebsiteApi } from '../companies.service';

export const useVerifyCompanyWebsite = () => {
  const mutationResponse = useMutation(verifyCompanyWebsiteApi, {
    showErrorSnackbar: false,
  });
  const debouncedMutate = useDebouncedMutate(mutationResponse.mutate);

  return {
    ...mutationResponse,
    debouncedMutate,
  };
};
