import { useFeatureAccessContext } from 'features/feature-access/feature-accesses.provider';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { useCallback } from 'react';

export type EntitledTabs = {
  feature: FeaturesEnum;
};

export const useEntitledTabsGuard = () => {
  const { checkFeatureEnabled } = useFeatureAccessContext();

  return useCallback((entitledTabs: EntitledTabs[] = []) => {
    return entitledTabs.filter((tab) => {
      return tab.feature ? checkFeatureEnabled(tab.feature as FeaturesEnum) : true;
    });
  }, []);
};
