import { isEqual, orderBy } from 'lodash';

import { useEvents, useQuery } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { QuickLinkTypeEnum } from '../quick-link-type.enum';
import { getQuickLinksApi } from '../quick-links.service';
import { QuickLinkRequestParams } from '../types';
import { settled } from 'common/types/Event-type.type';

const MAX_LINK_ON_PROFILE = 10;

export const useGetQuickLinks = (
  { linkedEntityId, quickLinkType }: QuickLinkRequestParams,
  options: UseQueryOptions = {},
) => {
  const quickLinkOnProfile = isEqual(quickLinkType, QuickLinkTypeEnum.PROFILE);
  const limit = quickLinkOnProfile ? MAX_LINK_ON_PROFILE : null;

  const queryResponse = useQuery(
    ['quickLinks', quickLinkType, linkedEntityId],
    () => getQuickLinksApi(linkedEntityId, quickLinkType),
    {
      enabled: !!linkedEntityId,
      ...options,
    },
  );

  const data = orderBy(queryResponse.data?.data, ['order'], ['asc']);
  const lastOrder = data[data?.length - 1]?.order;
  useEvents(settled('QuickLinkModified'), () => queryResponse.refetch());

  return {
    ...queryResponse,
    isLoading: queryResponse.isLoading,
    data: data || [],
    linkedEntityId,
    quickLinkType,
    lastOrder,
    limit,
  };
};
