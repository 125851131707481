import React from 'react';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useTheme } from '@mui/material/styles';
import { Stack } from '../material';

const EmptyStaticDataImage: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Stack
      bgcolor={palette.EmptyPlaceholderIcons.Background}
      width={150}
      height={150}
      borderRadius={'50%'}
      alignItems="center"
      justifyContent="center"
    >
      <TextSnippetIcon sx={{ color: palette.EmptyPlaceholderIcons.IconFill, fontSize: 56 }} />
    </Stack>
  );
};

export default EmptyStaticDataImage;
