import React from 'react';
import { PaletteMode } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useRouter, useView } from 'common/hooks';
import { getThemeImagePath } from 'common/utils/file.utils';

const getDeviceLogoMetaDataConfig = (mode: PaletteMode) => {
  return {
    DESKTOP: {
      width: '100px',
      src: getThemeImagePath('ConnectX', mode, 'ConnectXLogo.png'),
    },
    OTHER_DEVICES: {
      height: '35px',
      src: '/images/connectx.svg',
    },
  };
};

const StyledLogo = styled('img')({
  cursor: 'pointer',
});

const ConnectXLogo: React.FC<{ device?: 'DESKTOP' | 'OTHER_DEVICES' }> = ({ device }) => {
  const { goHome } = useRouter();
  const { isLargeScreenView } = useView();
  const { palette } = useTheme();

  const metaDataConfig = getDeviceLogoMetaDataConfig(palette.mode);
  const logoMetadata = device
    ? metaDataConfig[device]
    : isLargeScreenView
    ? metaDataConfig.DESKTOP
    : metaDataConfig.OTHER_DEVICES;

  const redirectToExplore = (e: React.MouseEvent) => {
    e.stopPropagation();
    goHome({}, { state: { resetHistoryIndex: true } });
  };

  return <StyledLogo onClick={redirectToExplore} {...logoMetadata} alt="ConnectX Logo" />;
};

export default ConnectXLogo;
