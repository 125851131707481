import React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTheme } from '@mui/material/styles';

import { Button } from 'common/components/material';
import { CXChip } from 'common/components/cx-chip';
import { FilterTextButtonProps } from '../types';

const FilterTextButton: React.FC<FilterTextButtonProps> = ({
  label = 'Filters',
  filterCount,
  highlightText,
  onClick,
}) => {
  const { palette } = useTheme();

  const fontColor =
    highlightText || filterCount ? palette.LinkText.Active : palette.LinkText.Default;

  return (
    <Button
      btntype="label"
      size="small"
      onClick={onClick}
      data-testid="filter-all-button"
      sx={{
        '& .MuiButton-startIcon': {
          marginRight: '4px',
        },
        color: fontColor,
      }}
      startIcon={<FilterAltIcon sx={{ fontSize: '1.25rem' }} />}
      endIcon={
        !!filterCount ? (
          <CXChip label={filterCount} variant="filled" sx={{ lineHeight: '1.2rem' }} />
        ) : (
          <></>
        )
      }
    >
      {label}
    </Button>
  );
};

export default FilterTextButton;
