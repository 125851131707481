import { useMemo } from 'react';
import { AnyObjectSchema } from 'yup';

/**
 * Function to conditionally merge schemas based on an array of
 * booleans (includedSchemasConfig) corresponding to the index of
 * the schema to be included.
 */
const mergeSchemas = (schemas: AnyObjectSchema[], includedSchemasConfig?: boolean[]) => {
  const schemasToMerge = includedSchemasConfig
    ? schemas.filter((schema, index) => {
        return includedSchemasConfig[index];
      })
    : schemas;

  const [first, ...rest] = schemasToMerge;

  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

  return merged;
};

const useMergeSchemas = (schemas: AnyObjectSchema[], includedSchemasConfig?: boolean[]) => {
  return useMemo(
    () => mergeSchemas(schemas, includedSchemasConfig),
    [schemas, includedSchemasConfig],
  );
};

export default useMergeSchemas;
