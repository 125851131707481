import axios from 'common/utils/axios';
import {
  DeleteWorkLocationParams,
  UpdateWorkLocationParams,
  WorkLocationRequestParams,
} from '../types';

const api = axios('work-locations');

export const getWorkLocationsApi = () => {
  return api.get('');
};

export const createWorkLocationApi = (params: WorkLocationRequestParams) => {
  return api.post('', params);
};

export const deleteWorkLocationApi = ({ id, byLocationId }: DeleteWorkLocationParams) => {
  const path = byLocationId ? `location/${id}` : id;
  return api.delete(path);
};

export const updateWorkLocationApi = ({ id, ...params }: UpdateWorkLocationParams) => {
  return api.put(id, params);
};
