import React, { useState } from 'react';
import moment from 'moment';
import { styled, useTheme } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import Button from './Button';
import Container from './Container';
import InputAdornment from './InputAdornment';
import Popover from './Popover';
import TextField from './TextField';
import { convertToDateString } from 'common/utils/date-time.utils';
import Box from './Box';

type DateRangePickerProps = {
  onDatesSelected: (startDate: string, endDate: string) => void;
  label?: string;
  defaultStartDate?: string;
  defaultEndDate?: string;
  dateFormat?: string;
  addMonthInToDate?: number;
};

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween?: boolean;
  isFirstDay?: boolean;
  isLastDay?: boolean;
};

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ dayIsBetween, isFirstDay, isLastDay, theme }) => ({
  '&.MuiButtonBase-root.MuiPickersDay-root': {
    color: theme.palette.Text.Paragraph,
    backgroundColor: theme.palette.Container.Bg,
    '&.MuiPickersDay-today': {
      borderColor: theme.palette.Buttons.Primary.BgDefault,
    },
    '&.Mui-selected, &.Mui-selected:focus': {
      backgroundColor: theme.palette.Buttons.Primary.BgDefault,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.Buttons.Primary.BgHover,
      borderColor: theme.palette.Buttons.Primary.BgHover,
    },
    '&:hover': {
      backgroundColor: theme.palette.Buttons.Tertiary.BgHover,
      border: `1px solid ${theme.palette.Buttons.Primary.BgDefault}`,
    },
    ...(dayIsBetween && {
      borderRadius: 0,
      backgroundColor: theme.palette.Buttons.Primary.BgDefault,
      color: theme.palette.Buttons.Primary.LabelDefault,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.Buttons.Primary.BgHover,
      },
    }),
    ...(isFirstDay && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  },
})) as React.ComponentType<CustomPickerDayProps>;

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  label = '',
  onDatesSelected,
  defaultStartDate = convertToDateString(),
  addMonthInToDate,
  defaultEndDate = convertToDateString(moment().add(addMonthInToDate, 'month').toDate()),
  dateFormat = 'MMM DD, YYYY',
}) => {
  const { palette } = useTheme();
  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);
  const [currentSelectedDate, setCurrentSelectedDate] = useState<Date | null>(new Date());
  const [datePickerElement, setDatePickerElement] = useState<HTMLElement | null>(null);

  const renderRangePicker = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    if (!startDate || !endDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const formattedDate = convertToDateString(date);
    const dayIsBetween = moment(formattedDate).isBetween(startDate, endDate, 'day', '[]');
    const isFirstDay = moment(formattedDate).isSame(startDate, 'day');
    const isLastDay = moment(formattedDate).isSame(endDate, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const handleDateRangeChange = (newDateSelected: Date | null) => {
    if (!newDateSelected) {
      return;
    }
    if (!moment(startDate).isSame(endDate, 'day')) {
      setStartDate(convertToDateString(newDateSelected));
      setEndDate(convertToDateString(newDateSelected));
    } else if (moment(newDateSelected).isAfter(startDate, 'day')) {
      setEndDate(convertToDateString(newDateSelected));
    } else {
      setStartDate(convertToDateString(newDateSelected));
      setEndDate(convertToDateString(newDateSelected));
    }
    setCurrentSelectedDate(newDateSelected);
  };

  // The MUI DatePicker triggers a new date change if we change the year, in that case we have to handle start and end dates accordingly.
  const handleYearChange = (newDateSelected: Date) => {
    if ((moment(newDateSelected).isBefore(startDate), 'day')) {
      setStartDate(convertToDateString(newDateSelected));
      setEndDate(convertToDateString(newDateSelected));
    } else {
      setEndDate(startDate);
    }
  };

  const handleDatesSelected = () => {
    setDatePickerElement(null);
    onDatesSelected(startDate, endDate);
  };

  const openDateRangePicker = (event: React.MouseEvent<HTMLElement>) => {
    setDatePickerElement(event.currentTarget as HTMLElement);
  };

  return (
    <>
      <TextField
        label={label}
        value={`${moment(startDate).format(dateFormat)} - ${moment(endDate).format(
          dateFormat,
        )}`}
        onClick={openDateRangePicker}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ cursor: 'pointer' }} position="end">
              <CalendarTodayIcon />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id="pop-over-dp"
        open={!!datePickerElement}
        onClose={handleDatesSelected}
        anchorEl={datePickerElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Container disableGutters sx={{ padding: 1, backgroundColor: palette.Container.Bg }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              label="Date Range Picker"
              value={currentSelectedDate}
              onChange={(newValue) => {
                handleDateRangeChange(newValue);
              }}
              onYearChange={handleYearChange}
              renderDay={renderRangePicker}
              renderInput={(params) => <TextField {...params} />}
              components={{
                PaperContent: Box,
              }}
              componentsProps={{
                paperContent: {
                  sx: {
                    '&': {
                      backgroundColor: palette.Container.Bg,
                      color: palette.Text.Headline,
                    },
                    '& .MuiTypography-root, & .MuiIconButton-root': {
                      color: palette.Text.Paragraph,
                    },
                  },
                },
              }}
            />
            <Button
              sx={{ marginTop: -1, width: '100%' }}
              variant="contained"
              onClick={handleDatesSelected}
              size="medium"
            >
              OK
            </Button>
          </LocalizationProvider>
        </Container>
      </Popover>
    </>
  );
};

export default DateRangePicker;
