import { Palette } from '@mui/material/styles';

export const getOpportunityStatusOptions = (palette: Palette) => [
  {
    value: 'OPEN',
    label: 'OPEN',
    dropdownLabel: 'OPEN',
    bgColor: palette.StatusDropdown.Open.BgDefault,
    color: palette.StatusDropdown.Open.LabelAndIconDefault,
  },
  {
    value: 'CLOSED',
    label: 'CLOSED',
    dropdownLabel: 'CLOSED',
    bgColor: palette.StatusDropdown.Closed.BgDefault,
    color: palette.StatusDropdown.Closed.LabelAndIconDefault,
  },
];

export const ASSIGN_OPPORTUNITY_ICON_LINK = '/images/assignOpportunityIcon.svg';
export const ASSIGN_OPPORTUNITY_TITLE = 'Assign';
export const FIND_RESOURCE_TITLE = 'Find Resources';
export const GET_IN_TOUCH = 'Get in touch';
export const OPPORTUNITY_DRAWER_HELPER = 'All Opportunities are posted on the home feed';
