import { useTheme } from '@mui/material/styles';
import { Container, Typography } from 'common/components/material';
import { useView } from 'common/hooks';
import { NotificationActivityLabelProps } from 'features/notifications';

const NotificationActivityLabel: React.FC<NotificationActivityLabelProps> = ({
  days = 30,
}) => {
  const { isMobileView } = useView();
  const { palette } = useTheme();
  return (
    <Container
      sx={{
        color: palette.Text.SubtleText,
        backgroundColor: palette.CommentBg,
        paddingY: isMobileView ? '0.25rem' : '0.625rem',
      }}
    >
      <Typography
        variant={isMobileView ? 'body4' : 'body2'}
        sx={{ color: palette.Text.SubtleText }}
      >
        Activity from last {days} days
      </Typography>
    </Container>
  );
};

export default NotificationActivityLabel;
