import { useEffect, useState } from 'react';
import { cloneDeep, each, differenceWith, reject, camelCase } from 'lodash';
import { useFilterReducer } from './';
import {
  ActiveFilterMap,
  ActiveFilters,
  Filter,
  FilterType,
  getParentFilterType,
  mapSearchFiltersObject,
} from 'features/search';
import { getActiveFilterMapKey, hasSelectedParent } from 'features/static-data';
import { HierarchicalDataMapping } from 'features/data-explorer/types/explorer-data.type';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { PrinciplesFilterTypeEnum } from 'features/principles';
import { getTransformedFilterList } from '../explore.utils';
import { FILTER_EXPLORER_CONFIG } from 'features/data-explorer/filter-explorer.config';
import { ExplorerConfig } from 'features/data-explorer';

export const useFilterState = (
  filterType: ExplorerDataTypeEnum | PrinciplesFilterTypeEnum | null,
  initialFilters: ActiveFilters,
  initializer?: () => ActiveFilters,
) => {
  // Determines which filter chips are shown and what gets sent to the server
  const [activeFilters, updateFilters] = useFilterReducer(initialFilters, initializer);
  // Determines which filter checkboxes are ticked
  const [activeFilterMap, setActiveFilterMap] = useState<ActiveFilterMap>({});
  const config = filterType ? FILTER_EXPLORER_CONFIG[filterType] : ({} as ExplorerConfig);

  useEffect(() => {
    updateFilters({ type: 'update', state: cloneDeep(initialFilters) });
    const filtersMap = mapSearchFiltersObject(initialFilters);
    setActiveFilterMap(filtersMap);
  }, [initialFilters, updateFilters]);

  const toggleFilterSelection = (
    filter: Filter,
    selected: boolean,
    mapping?: HierarchicalDataMapping,
    exploreDataType?: ExplorerDataTypeEnum | null,
  ) => {
    const { filterType: subFilterType } = filter;
    const appliedFilterType = subFilterType || filterType;
    const filterKey = camelCase(appliedFilterType || '');

    if (!filterKey) {
      return;
    }

    const updatedFilters = cloneDeep(activeFilters);

    const parentIsSelected = hasSelectedParent(
      filter,
      activeFilterMap,
      exploreDataType as ExplorerDataTypeEnum,
      mapping,
    );

    const actionType = selected ? 'add' : 'remove';

    const newActiveFilterMap = { ...activeFilterMap };

    if (actionType === 'add') {
      newActiveFilterMap[
        getActiveFilterMapKey(filter.id, exploreDataType as ExplorerDataTypeEnum)
      ] = filter;

      if (!updatedFilters[filterKey]) {
        updatedFilters[filterKey] = [];
      }

      const siblingFilterMetadata =
        mapping && getSiblingFilterMetadata(filter, mapping, exploreDataType);

      // Remove the filter's 'unassigned' option
      updatedFilters[filterKey] = reject(updatedFilters[filterKey], {
        id: filter.id,
      });
      updatedFilters.excludeChildren = reject(updatedFilters.excludeChildren, {
        id: filter.id,
      });

      const children = !filter.excludeChildren && mapping && mapping[filter.id].children;

      if (mapping && children) {
        const removedFilters = removeChildFilters(
          children,
          newActiveFilterMap,
          mapping,
          exploreDataType,
        );
        each(removedFilters, (removedFilter) => {
          const childFilterKey = camelCase(removedFilter.filterType);
          if (updatedFilters[childFilterKey]?.length) {
            updatedFilters[childFilterKey] = reject(updatedFilters[childFilterKey], {
              id: removedFilter.id,
            });
          }
          if (updatedFilters.excludeChildren?.length) {
            updatedFilters.excludeChildren = reject(updatedFilters.excludeChildren, {
              id: removedFilter.id,
            });
          }
        });
      }

      /**
       * When selecting a filter we need to know if all other filters
       * in the column is already selected. If true, then the parent filter
       * should also be selected. This is not relevant for the first column.
       */
      if (mapping && siblingFilterMetadata?.allSelectedInColumn) {
        const parentFilters = addParentFilters(
          filter,
          newActiveFilterMap,
          mapping,
          exploreDataType,
        );

        /**
         * If parent filter becomes selected, then all their children's filter chips are also removed
         * activeFilters: existing active filters + current added filter
         * parentFilters: ancestors of current filter
         */
        updatedFilters[filterKey] = differenceWith(
          [filter, ...updatedFilters[filterKey]],
          parentFilters,
          (activeFilter: Filter, parentFilter: Filter) => {
            const matched =
              activeFilter.parentId === parentFilter.id ||
              activeFilter.id === parentFilter.id;
            // Remove children filter map as parent filter map exist
            if (matched) {
              delete newActiveFilterMap[
                getActiveFilterMapKey(
                  activeFilter.id,
                  exploreDataType as ExplorerDataTypeEnum,
                )
              ];
            }
            return matched;
          },
        );

        const highestSelectedParent = parentFilters[parentFilters.length - 1];
        // If all child filters are selected, we only need to show the parent filter chip
        newActiveFilterMap[
          getActiveFilterMapKey(
            highestSelectedParent.id,
            exploreDataType as ExplorerDataTypeEnum,
          )
        ] = highestSelectedParent;

        // Check hierarchical data other than static data
        const highestSelectedKey = camelCase(highestSelectedParent.filterType) || filterKey;
        if (!updatedFilters?.[highestSelectedKey]) updatedFilters[highestSelectedKey] = [];
        updatedFilters[highestSelectedKey].push(highestSelectedParent);

        // In case city filter left when country filter presents
        const parentFilterType = getParentFilterType(appliedFilterType);
        if (parentFilterType !== appliedFilterType) {
          const parentFilterKey = camelCase(parentFilterType);
          updatedFilters[parentFilterKey] = reject(updatedFilters[parentFilterKey], {
            parentId: highestSelectedParent.id,
          });
        }

        each(parentFilters, (removedFilter) => {
          updatedFilters.excludeChildren = reject(updatedFilters.excludeChildren, {
            id: removedFilter.id,
          });
        });
      } else {
        updatedFilters[filterKey].push(filter);
        if (filter.excludeChildren) {
          if (!updatedFilters.excludeChildren) {
            updatedFilters.excludeChildren = [];
          }
          updatedFilters.excludeChildren.push(filter);
        }
      }
    }

    if (actionType === 'remove') {
      delete newActiveFilterMap[
        getActiveFilterMapKey(filter.id, exploreDataType as ExplorerDataTypeEnum)
      ];

      updatedFilters[filterKey] = reject(updatedFilters[filterKey], {
        id: filter.id,
      });

      if (filter.excludeChildren) {
        updatedFilters.excludeChildren = reject(updatedFilters.excludeChildren, {
          id: filter.id,
        });
      }

      if (parentIsSelected && mapping) {
        // If a child filter is deselected, then so must its parents
        const removedParentIds = removeParentFilters(
          filter,
          newActiveFilterMap,
          mapping,
          exploreDataType,
        );

        let parentFilterType: FilterType;

        each(removedParentIds, (id) => {
          const parent = mapping[id];
          const childFilterType = parentFilterType || appliedFilterType;

          parentFilterType = getParentFilterType(childFilterType);
          const parentFilterKey = camelCase(parentFilterType);

          // Remove parent filter chips
          updatedFilters[parentFilterKey] = reject(updatedFilters[parentFilterKey], {
            id,
          });

          // Check if parent and grand parent has Unassigned
          const isUnassignedAdded = id !== filter.id ?? !filter.excludeChildren;
          if (config.includeUnassignedOption && parent?.hasResult && isUnassignedAdded) {
            const unassignedFilter = {
              id,
              name: `${parent.fullName || parent.name} (unassigned)`,
              filterType: parentFilterType,
              excludeChildren: true,
            };

            newActiveFilterMap[
              getActiveFilterMapKey(id, exploreDataType as ExplorerDataTypeEnum)
            ] = unassignedFilter;
            updatedFilters[parentFilterKey].push(unassignedFilter);
          }

          const grandParentId = parent.parentId;
          const grandParentWasSelected =
            !!grandParentId &&
            activeFilterMap[
              getActiveFilterMapKey(grandParentId, exploreDataType as ExplorerDataTypeEnum)
            ];

          if (grandParentWasSelected && !grandParentWasSelected.excludeChildren) {
            const { siblings: parentSiblings } = getSiblingFilterMetadata(
              {
                ...parent,
                filterType: parentFilterType,
              },
              mapping,
            );

            each(parentSiblings, (parentSibling) => {
              newActiveFilterMap[
                getActiveFilterMapKey(
                  parentSibling.id,
                  exploreDataType as ExplorerDataTypeEnum,
                )
              ] = parentSibling;
              updatedFilters[parentFilterKey].push(parentSibling);
            });
          }
        });

        const { siblings } = getSiblingFilterMetadata(filter, mapping);

        // If parent was selected, then siblings were only implicitly selected.
        // Therefore we explicitly select them here
        each(siblings, (sibling) => {
          newActiveFilterMap[
            getActiveFilterMapKey(sibling.id, exploreDataType as ExplorerDataTypeEnum)
          ] = sibling;
          updatedFilters[filterKey].push(sibling);
        });
      }
    }

    updateFilters({ type: 'update', state: updatedFilters });
    setActiveFilterMap(newActiveFilterMap);
    return updatedFilters;
  };

  const addParentFilters = (
    filter: Filter,
    filterMap: ActiveFilterMap,
    mapping: HierarchicalDataMapping,
    exploreDataType?: ExplorerDataTypeEnum | null,
  ): Filter[] => {
    const parentId = filter.excludeChildren ? filter.id : filter.parentId;
    const parent = parentId && mapping[parentId];

    let addedParentFilters = [];

    if (!parent) {
      return [];
    }

    const { type, ...parentProps } = parent;

    const parentFilterType = getParentFilterType(filter.filterType);
    const parentFilter = {
      ...parentProps,
      filterType: parentFilterType,
    };

    addedParentFilters.push(parentFilter);

    filterMap[getActiveFilterMapKey(parent.id, exploreDataType as ExplorerDataTypeEnum)] =
      parentFilter;

    const { allSelectedInColumn } = getSiblingFilterMetadata(
      parentFilter,
      mapping,
      exploreDataType,
    );

    if (allSelectedInColumn) {
      const grandParentFilters = addParentFilters(
        parentFilter,
        filterMap,
        mapping,
        exploreDataType,
      );
      addedParentFilters = addedParentFilters.concat(grandParentFilters);
    }

    return addedParentFilters;
  };

  // Recursively removes parents from the filter map and returns a list of removed ids
  const removeParentFilters = (
    filter: Filter,
    filterMap: ActiveFilterMap,
    mapping: HierarchicalDataMapping,
    exploreDataType?: ExplorerDataTypeEnum | null,
  ): string[] => {
    const parentId = filter.excludeChildren
      ? mapping[filter.id]?.id
      : mapping[filter.id]?.parentId;
    const parentIsSelected = hasSelectedParent(
      filter,
      filterMap,
      exploreDataType as ExplorerDataTypeEnum,
      mapping,
    );
    let removedIds: string[] = [];

    if (!parentId || !parentIsSelected) {
      return removedIds;
    }

    delete filterMap[
      getActiveFilterMapKey(parentId, exploreDataType as ExplorerDataTypeEnum)
    ];

    removedIds.push(parentId);

    const parent = mapping[parentId];
    const grandParentId = parent.parentId;
    const parentFilter = {
      ...parent,
      filterType: getParentFilterType(filter.filterType),
    };

    if (grandParentId) {
      const removedGrandParentIds = removeParentFilters(
        parentFilter,
        filterMap,
        mapping,
        exploreDataType,
      );
      removedIds = removedIds.concat(removedGrandParentIds);
    }

    return removedIds;
  };

  const removeChildFilters = (
    childIds: string[],
    filterMap: ActiveFilterMap,
    mapping: HierarchicalDataMapping,
    exploreDataType?: ExplorerDataTypeEnum | null,
  ) => {
    let removedFilters: Filter[] = [];

    each(childIds, (childId) => {
      const filterMapKey = getActiveFilterMapKey(
        childId,
        exploreDataType as ExplorerDataTypeEnum,
      );
      const childFilter = filterMap[filterMapKey];
      if (childFilter) {
        removedFilters.push({
          ...childFilter,
        });
        delete filterMap[filterMapKey];
      }

      const grandchildIds = mapping[childId].children;
      if (grandchildIds) {
        const removedGranchildIds = removeChildFilters(
          grandchildIds,
          filterMap,
          mapping,
          exploreDataType,
        );
        removedFilters = removedFilters.concat(removedGranchildIds);
      }
    });

    return removedFilters;
  };

  const getSiblingFilterMetadata = (
    filter: Filter,
    mapping: HierarchicalDataMapping,
    exploreDataType?: ExplorerDataTypeEnum | null,
  ) => {
    const siblings: Filter[] = [];
    let { id, parentId, filterType, excludeChildren } = filter;
    parentId = excludeChildren ? id : parentId;

    let allSelectedInColumn = !!parentId;

    if (parentId) {
      const parent = mapping[parentId];
      // Start false if there is 'unassigned' in the same column
      allSelectedInColumn = !parent?.hasResult;

      const parentFilterMap =
        activeFilterMap[
          getActiveFilterMapKey(parentId, exploreDataType as ExplorerDataTypeEnum)
        ];

      // If 'unassigned' in the same column is checked
      if (excludeChildren || !!parentFilterMap?.excludeChildren) {
        allSelectedInColumn = true;
      }

      each(mapping, (mapItem) => {
        if (mapItem.id !== filter.id && mapItem.parentId === parentId) {
          siblings.push({ ...mapItem, filterType });
          const siblingFilterMap =
            activeFilterMap[
              getActiveFilterMapKey(mapItem.id, exploreDataType as ExplorerDataTypeEnum)
            ];
          if (!siblingFilterMap || siblingFilterMap.excludeChildren) {
            allSelectedInColumn = false;
          }
        }
      });
    }

    return { siblings, allSelectedInColumn };
  };

  const clearFilters = () => {
    updateFilters({ type: 'clear' });
    setActiveFilterMap({});
  };

  const setFilters = (filters: ActiveFilters) => {
    updateFilters({ type: 'update', state: cloneDeep(filters) });
    const newActiveFilterMap = mapSearchFiltersObject(activeFilters);
    setActiveFilterMap(newActiveFilterMap);
  };

  return {
    activeFilters,
    activeFilterMap,
    filterList: getTransformedFilterList(activeFilters),
    toggleFilterSelection,
    setFilters,
    clearFilters,
  };
};
