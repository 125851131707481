import { get, isNil } from 'lodash';

import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';
import { Review, ReviewStatusEnum } from 'features/reviews';
import { useSession } from 'common/hooks';
import { OpportunityStatusEnum } from 'features/opportunities';
import { Praise } from 'features/praise';
import { ProfileVisibilityEnum } from 'features/profiles';
import { FeaturesEnum } from '../features.enum';
import { useFeatureAccess } from './useFeatureAccess';

export type InstancePermissions = {
  read: boolean;
  create: boolean;
  edit: boolean;
  interact: boolean;
};

const DEFAULT_PERMISSIONS = {
  read: true,
  create: false,
  edit: false,
  interact: false,
};

const useInstancePermissions = <T>(
  resourceType: InstancePermissionsResourcesEnum,
  resource: T,
) => {
  const { profile: loggedInUser } = useSession();
  const loggedInUserId = get(loggedInUser, 'id');

  // get permission to access static data
  const staticDataId = get(resource, 'id') as string;
  const { canWrite: canEditSomeStaticData } = useFeatureAccess(
    FeaturesEnum.SOME_STATIC_DATA_PAGES,
  );
  const { canWrite: canEditCurrentStaticData } = useFeatureAccess(
    FeaturesEnum.SOME_STATIC_DATA_PAGES,
    { pageId: staticDataId },
  );
  const { canWrite: canEditAllStaticData } = useFeatureAccess(
    FeaturesEnum.ALL_STATIC_DATA_PAGES,
  );

  // get permission to access quick link
  const profileId = get(resource, 'profileId');
  const linkedEntityId = get(resource, 'linkedEntityId') as string;

  const { canWrite: canEditOwnProfileQuickLink } = useFeatureAccess(
    FeaturesEnum.OWN_PROFILE_QUICK_LINKS,
  );

  const isEditingTheirOwnQuickLink =
    loggedInUserId === profileId && canEditOwnProfileQuickLink;
  const { canWrite: canEditCurrentQuickLinkPage } = useFeatureAccess(
    FeaturesEnum.SOME_STATIC_DATA_PAGES,
    { pageId: linkedEntityId },
  );

  const { canWrite: canEditOtherProfileQuickLink } = useFeatureAccess(
    FeaturesEnum.OTHER_PROFILE_QUICK_LINKS,
  );
  const { canWrite: canEditOrganizationQuickLink } = useFeatureAccess(
    FeaturesEnum.ORGANIZATION_QUICK_LINKS,
  );

  let featureAccessToEditAnyData;
  switch (resourceType) {
    case InstancePermissionsResourcesEnum.PROFILE:
      featureAccessToEditAnyData = FeaturesEnum.OTHER_PROFILE;
      break;
    case InstancePermissionsResourcesEnum.PROJECT:
      featureAccessToEditAnyData = FeaturesEnum.OTHER_WINS;
      break;
    case InstancePermissionsResourcesEnum.POST:
      featureAccessToEditAnyData = FeaturesEnum.OTHER_POST;
      break;
    case InstancePermissionsResourcesEnum.COMPANY_UPDATE:
      featureAccessToEditAnyData = FeaturesEnum.ALL_COMPANY_UPDATES;
      break;
    case InstancePermissionsResourcesEnum.ORGANIZATION_EVENT:
      featureAccessToEditAnyData = FeaturesEnum.ALL_ORGANIZATION_EVENTS;
      break;
    default:
      // since we cannot run a hook conditionally,
      // we set the this dummy feature to only get a default permission
      // from useFeatureAccess hook
      featureAccessToEditAnyData = FeaturesEnum.DUMMY_FEATURE;
      break;
  }
  const { canWrite } = useFeatureAccess(featureAccessToEditAnyData);
  let canEdit = !!canWrite;

  if (!isNil(loggedInUser)) {
    const permissions = { ...DEFAULT_PERMISSIONS };
    const loggedInUserId = get(loggedInUser, 'id');
    const resourceBelongsToLoggedInOrganization =
      (loggedInUser.getOrgId() || '') === get(resource, 'organizationId');
    permissions.interact = resourceBelongsToLoggedInOrganization;

    switch (resourceType) {
      case InstancePermissionsResourcesEnum.PROFILE:
        const visibility = get(resource, 'visibility');

        permissions.edit = canEdit || loggedInUserId === get(resource, 'id');
        permissions.interact = isNil(visibility)
          ? true
          : visibility === ProfileVisibilityEnum.FULL;
        break;
      case InstancePermissionsResourcesEnum.PROJECT:
        const ownerId = get(resource, 'ownerId') || get(resource, 'owner.id');
        const assignerId = get(resource, 'assignerId') || get(resource, 'assigner.id');
        permissions.edit =
          resourceBelongsToLoggedInOrganization &&
          (loggedInUserId === ownerId || loggedInUserId === assignerId || canEdit);
        break;
      case InstancePermissionsResourcesEnum.PRAISE:
        const praise = resource as unknown as Praise;
        const receiverIsActive = get(praise, 'receiver.isActiveInOrganization', true);
        permissions.create = loggedInUserId !== get(praise, 'receiver.id');
        permissions.edit =
          resourceBelongsToLoggedInOrganization &&
          loggedInUserId === get(praise, 'giver.id') &&
          receiverIsActive;
        break;
      case InstancePermissionsResourcesEnum.REVIEW:
        const review = resource as unknown as Review;
        const revieweeId = get(review, 'reviewee.id');
        const reviewerId = get(review, 'reviewer.id');
        const revieweeIsActive = get(review, 'reviewee.isActiveInOrganization', true);
        const reviewStatus = get(review, 'status');
        permissions.read =
          reviewStatus === ReviewStatusEnum.COMPLETED &&
          [revieweeId, reviewerId].includes(loggedInUserId);
        permissions.edit =
          reviewStatus === ReviewStatusEnum.NOT_STARTED &&
          reviewerId === loggedInUserId &&
          revieweeIsActive;
        break;
      case InstancePermissionsResourcesEnum.OPPORTUNITY:
        permissions.edit =
          (loggedInUserId === get(resource, 'assigner.id') ||
            loggedInUserId === get(resource, 'manager.id')) &&
          get(resource, 'status') === OpportunityStatusEnum.OPEN &&
          resourceBelongsToLoggedInOrganization;
        break;
      case InstancePermissionsResourcesEnum.POST:
        permissions.edit =
          resourceBelongsToLoggedInOrganization &&
          (loggedInUserId === get(resource, 'creator.id') || canEdit);
        break;
      case InstancePermissionsResourcesEnum.QUICK_LINK:
        canEdit =
          isEditingTheirOwnQuickLink ||
          canEditCurrentQuickLinkPage ||
          canEditAllStaticData ||
          canEditOtherProfileQuickLink ||
          canEditOrganizationQuickLink;
        permissions.create = canEdit;
        permissions.edit = canEdit;
        break;
      case InstancePermissionsResourcesEnum.COMPANY_UPDATE:
      case InstancePermissionsResourcesEnum.ORGANIZATION_EVENT:
        const creatorId = get(resource, 'creatorId');
        permissions.create = canEdit || loggedInUserId === creatorId;
        permissions.edit =
          resourceBelongsToLoggedInOrganization && (canEdit || loggedInUserId === creatorId);
        break;
      case InstancePermissionsResourcesEnum.STATIC_DATA:
        canEdit = canEditAllStaticData || canEditCurrentStaticData;
        permissions.create = canEdit;
        permissions.edit = canEdit;
        break;
      case InstancePermissionsResourcesEnum.GROUP:
        canEdit = canEditAllStaticData || canEditSomeStaticData;
        permissions.create = canEdit;
        permissions.edit = canEdit;
        break;
    }
    return { permissions };
  }
  return { permissions: DEFAULT_PERMISSIONS };
};

export default useInstancePermissions;
