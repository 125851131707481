import { BobEvents } from 'features/bobs/types/bob-events.type';
import { CompanyUpdatesEvents } from 'features/company-updates/types/company-update-events.type';
import { OpportunityEvents } from 'features/opportunities/types/opportunity-events.type';
import { OrganizationEventEventsType } from 'features/organization-events/types/organization-event-events.type';
import { OrganizationRoleEvents } from 'features/organization-roles/organization-role-event.types';
import { PostEvents } from 'features/posts/types/post-events.type';
import { PraiseEvents } from 'features/praise';
import { ProjectEvents } from 'features/projects/types/project-events.type';
import { FilterEvents } from 'features/search/types/filter-events.type';
import { SkillEvents } from 'features/skills/types/skill-events.type';
import { StaticDataEvents } from 'features/static-data/static-data-event-types';

export type EventType =
  | SkillEvents
  | PraiseEvents
  | CompanyUpdatesEvents
  | OpportunityEvents
  | ProjectEvents
  | OrganizationEventEventsType
  | PostEvents
  | FilterEvents
  | BobEvents
  | StaticDataEvents
  | OrganizationRoleEvents
  | 'UserRolesModified'
  | 'ProfileGroupsModified'
  | 'MyPagesUpdated'
  | 'OpenPhotoUploader';

export type EventTypeSettled = `${EventType}-Settled`;

export function settled(eventType: EventType) {
  return (eventType + '-Settled') as EventTypeSettled;
}
