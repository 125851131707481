import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';

import FormTextField from './FormTextField';
import InputValidationIndicator from 'common/components/input/InputValidationIndicator';
import { useApiValidationError, useDebounce } from 'common/hooks';
import { validateMinLength } from 'common/utils/app.utils';
import { CompanyWebsiteInputProps, useVerifyCompanyWebsite } from 'features/companies';

const DEFAULT_NAME = 'website';
const DEFAULT_LABEL = 'Company Website';

const CompanyWebsiteInput: React.FC<CompanyWebsiteInputProps> = ({
  name = DEFAULT_NAME,
  label = DEFAULT_LABEL,
  onChange,
  initialValue = '',
  shouldValidateInitialValue,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(initialValue || '');

  const debouncedInputValue = useDebounce(inputValue);
  const valueChanged = initialValue !== debouncedInputValue;

  const {
    data: websiteVerificationResult,
    isLoading: isVerifyingWebsite,
    mutate: verifyWebsiteAvailability,
    error,
  } = useVerifyCompanyWebsite();

  useEffect(() => {
    if (
      validateMinLength(debouncedInputValue) &&
      (valueChanged || shouldValidateInitialValue)
    ) {
      verifyWebsiteAvailability(debouncedInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  useEffect(() => {
    handleInputChange(initialValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleInputChange = (newWebsite: string) => {
    setInputValue(newWebsite);
    onChange && onChange(newWebsite);
  };

  useApiValidationError(
    !!websiteVerificationResult || (!valueChanged && !shouldValidateInitialValue),
    name,
    handleInputChange,
  );

  return (
    <FormTextField
      name={name}
      label={label}
      errorMessage={
        valueChanged && !!debouncedInputValue
          ? error?.message || _.get(error, 'errors[0].messages[0]')
          : ''
      }
      onChange={handleInputChange}
      value={inputValue || ''}
      {...props}
      InputProps={{
        endAdornment: (
          <InputValidationIndicator
            isValid={!!websiteVerificationResult && validateMinLength(inputValue)}
            isLoading={isVerifyingWebsite}
            isError={valueChanged && !!error?.message}
          />
        ),
      }}
    />
  );
};

export default CompanyWebsiteInput;
