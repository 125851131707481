import { useLocalStorage, useMutation, useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import queryClient from 'common/utils/QueryClient';
import { logoutApi } from '../accounts.service';

export const useLogout = () => {
  const { goTo } = useRouter();
  const { setOrganizationOnSession } = useSession();
  const { setItemValue: setRecentEmail } = useLocalStorage('recent-email');
  const { setItemValue: setRecentSuburl } = useLocalStorage('recent-suburl');

  return useMutation(logoutApi, {
    showErrorSnackbar: false,
    onSettled: () => {
      queryClient.clear();
      setRecentEmail(null);
      setRecentSuburl(null);
      setOrganizationOnSession(null, null);
      goTo(AppRoutesEnum.ACCOUNTS_LOGIN);
    },
  });
};
