import axios from 'common/utils/axios';
import {
  CreatePraiseRequest,
  GetPraiseLeaderboardParams,
  Praise,
  UpdatePraiseRequest,
} from './types';

const praiseApi = axios('praises');

export const deletePraiseApi = ({ id }: Praise) => {
  return praiseApi.delete(id);
};

export const createPraiseApi = ({ ...data }: CreatePraiseRequest) => {
  return praiseApi.post('', data);
};

export const updatePraiseApi = ({ id, ...data }: UpdatePraiseRequest) => {
  return praiseApi.put(id, data);
};

export const getPraiseApi = (params: any) => {
  return praiseApi.get('search', { params });
};

export const getPraiseLeaderboardApi = (params: GetPraiseLeaderboardParams) => {
  return praiseApi.get(
    `leaderboard?tagId=${params.id}&tagType=${params.type}&duration=${params.duration}`,
  );
};

export const getPraiseHeadlineOptionsApi = () => {
  return praiseApi.get(`headline-options`);
};

export const getLatestPraiseApi = (tagId?: string) => {
  return praiseApi.get('latest', { params: { tagId } });
};

export const getLatestProfilePraisesApi = (profileId: string) => {
  return praiseApi.get('recent', { params: { profileId } });
};
