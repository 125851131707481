import React from 'react';
import { Navigate } from 'react-router-dom';

import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { useRouter } from 'common/hooks';

type NavigateToOrgRouteProps = {
  route: string;
  state?: any;
  params?: any;
  replace?: boolean;
};
const NavigateToOrgRoute: React.FC<NavigateToOrgRouteProps> = ({
  route = AppRoutesEnum.EXPLORE_PEOPLE,
  state = {},
  params = {},
  replace = false,
}) => {
  const { getOrgRoute } = useRouter();
  const orgRoute = getOrgRoute(route, params);

  return <Navigate to={orgRoute} replace={replace} state={state} />;
};

export default NavigateToOrgRoute;
