import { useMutation, useSession } from 'common/hooks';
import queryClient from 'common/utils/QueryClient';
import { UpdateStaticDataDescriptionParams } from '../types';
import { updateTagDescriptionApi } from 'features/tags/services/taggings.service';

export const useUpdateStaticDataDescription = () => {
  const { account } = useSession();
  const organizationId = account?.organizationId || '';

  return useMutation(updateTagDescriptionApi, {
    showSuccessSnackbar: true,
    onSuccess: (result, params: UpdateStaticDataDescriptionParams) => {
      queryClient.invalidateQueries([organizationId, 'static-data', params.type, params.id]);
    },
  });
};
