import axios from 'common/utils/axios';
import { SearchParams } from 'features/search';

const skillsApi = axios('skills');

export const getRecommendedSkillsApi = () => {
  return skillsApi.get('recommended');
};

export const getSkillsApi = (params: SearchParams) => {
  return skillsApi.get('', { params });
};
