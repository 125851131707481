import { styled } from '@mui/material';
import { CXAvatar } from 'common/components/cx-avatar/cx-avatar';
import Badge, { BadgeProps } from 'common/components/material/Badge';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const DefaultAvatar = styled(CXAvatar)(({ theme }) => ({
  width: 26,
  height: 26,
  backgroundColor: theme.palette.EmptyPlaceholderIcons.Background,
}));

export const StatusIndicator = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    boxShadow: AppBoxShadows.shadow1,
    // TODO: Set color based on user's status
    backgroundColor: theme.palette.Success,
  },
}));

export const StyledAvatarCount = styled('div')(({ theme }) => ({
  width: 26,
  height: 26,
  backgroundColor: theme.palette.EmptyPlaceholderIcons.Background,
  color: theme.palette.EmptyPlaceholderIcons.IconFill,
  fontSize: 14,
  borderRadius: '2rem',
  marginTop: 2,
  zIndex: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
