import { useQuery } from 'common/hooks';
import { transformCodeVerificationError } from 'features/accounts/accounts.utils';

import { UseQueryOptions } from 'common/types/Api.type';

import { verifyPasswordResetCodeApi } from '../accounts.service';

export const useVerifyPasswordResetCode = (
  resetCode: string,
  options: UseQueryOptions = {},
) => {
  const queryResponse = useQuery([resetCode], () => verifyPasswordResetCodeApi(resetCode), {
    showErrorSnackbar: true,
    ...options,
  });

  return {
    ...queryResponse,
    isVerifiying: queryResponse.isFetching || queryResponse.isLoading,
    data: queryResponse.data?.data,
    error: transformCodeVerificationError(queryResponse.error),
  };
};
