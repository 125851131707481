import { get } from 'lodash';

import queryClient from 'common/utils/QueryClient';
import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { useEventEmitter, useSession } from 'common/hooks';
import { SkillEndorsementEventData } from 'common/types/Event-Data.type';
import { updateEndorsedSkillApi } from '../profiles.service';
import { EndorsedSkillResponse, SkillEndorsementsResponse } from 'features/skills';

export const useUpdateEndorsedSkill = (
  profileId: string,
  skillId: string,
  options: UseMutationOptions = {},
) => {
  const { account: user } = useSession();
  const { emit } = useEventEmitter<SkillEndorsementEventData>('SkillEndorsement');

  return useMutation(updateEndorsedSkillApi, {
    showSuccessSnackbar: true,
    transformInput: (input: any) => ({ profileId, skillId, rating: get(input, 'rating') }),
    onSuccess: (res: EndorsedSkillResponse) => {
      const skillEndorsementQueryData = queryClient.getQueryData([
        'profile',
        'skill-endorsements',
        profileId,
        skillId,
      ]) as SkillEndorsementsResponse;

      if (skillEndorsementQueryData) {
        skillEndorsementQueryData.data.endorsedSkills[0] = {
          ...res.data,
        };
        queryClient.setQueryData(
          ['profile', 'skill-endorsements', profileId, skillId],
          skillEndorsementQueryData,
        );
      }

      emit({
        currentOrganization: user?.organizationId,
        profileId,
        skillId,
        data: res.data,
      });
    },
    ...options,
  });
};
