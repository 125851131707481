import React from 'react';

import { Container } from 'common/components/material';
import { ContainerProps } from 'common/components/material/Container';

type SetupContainerProps = {
  children: React.ReactNode;
};

const SetupContainer: React.FC<SetupContainerProps & ContainerProps> = (
  setupContainerProps,
) => {
  const { children, ...props } = setupContainerProps;

  return (
    <Container maxWidth="lg" sx={{ height: '100%' }} {...props}>
      {children}
    </Container>
  );
};

export default SetupContainer;
