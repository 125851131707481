import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDataExplorerStateParams } from '../types';

// Keeps track of what part of the hierarchical data to display
export const useDataExplorerState = <GenericStaticData extends Record<string, any>>({
  maxColumns,
  title,
  showFlatList,
  onExit,
}: useDataExplorerStateParams) => {
  // Which static data is currently active/expanded (displaying children)
  const [activeDataList, setActiveDataList] = useState<Array<GenericStaticData>>([]);
  // In mobile view, this is the name of the parent of the currently visible column of data
  const [activeTitle, setActiveTitle] = useState('');
  // In mobile view, this is the index of the currently visible column
  const [activeColumn, setActiveColumn] = useState(0);

  const handleDataSelect = (data: GenericStaticData, index: number) => {
    const hasChildren = !isEmpty(data.children);

    const newActiveDataList = Array.from({ length: index + 1 })
      .map((e, listIndex: number) => {
        const currentActiveData = activeDataList[listIndex];
        // Do nothing for parent column or if already active
        if (currentActiveData?.id === data.id || listIndex < index) {
          return currentActiveData;
        } else if (listIndex === index) {
          return data;
        }
        return null;
      })
      .filter((data) => !!data) as GenericStaticData[];

    if (hasChildren && maxColumns === 1) {
      setActiveColumn(index + 1);
      setActiveTitle(data.name);
    }

    setActiveDataList(newActiveDataList);
  };

  const goBackOneColumn = () => {
    if (activeColumn === 0) {
      // No more columns to go back to (typically means exiting the data explorer view)
      onExit && onExit();
    } else if (activeColumn === 1) {
      setActiveTitle('');
    } else {
      const parent = activeDataList[activeColumn - 2];
      setActiveTitle(parent?.name || '');
    }

    setActiveColumn(activeColumn - 1);
  };

  const resetActiveColumn = () => {
    setActiveColumn(0);
  };

  useEffect(() => {
    if (showFlatList) {
      resetActiveColumn();
    }
  }, [showFlatList]);

  return {
    activeColumn,
    activeDataList,
    displayedTitle: maxColumns === 1 ? activeTitle || title : title,
    handleDataSelect,
    goBackOneColumn,
    resetActiveColumn,
    setActiveDataList,
  };
};
