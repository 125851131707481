import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import MockApiResponse from 'common/utils/mockApiResponse';
import { ORGANIZATION_ID_HEADER_KEY, ORGANIZATION_ID_KEY } from './app.utils';

const BASE_API_URL = '/api';
const CAN_USE_MOCK_API = process.env.REACT_APP_CAN_USE_MOCK_APIS as string;

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

type MockedDataType = {
  data: any;
  errors?: any[];
  message?: string;
  statusCode?: number;
};

type MockedAxiosResponse = AxiosResponse & {
  message?: string | undefined;
  errors?: any | undefined;
  data?: any | undefined;
};

const getMockedData: any = (
  resource: string,
  configUrlFunction: string,
  configData: any,
  failMockApi: boolean,
) => {
  const mockedResource: any = MockApiResponse[resource];

  return mockedResource[configUrlFunction](configData, failMockApi);
};

const getMockedApiResponse = (
  mockedData: MockedDataType,
  failMockApi: boolean,
  config: any,
): MockedAxiosResponse => {
  if (failMockApi) {
    return {
      status: mockedData.statusCode || 401,
      statusText: 'FAILED',
      headers: DEFAULT_HEADERS,
      config,
      request: {},
      data: mockedData,
    };
  }

  return {
    data: mockedData,
    status: 200,
    statusText: 'OK',
    headers: DEFAULT_HEADERS,
    config,
    request: {},
  };
};

const axiosApi = (
  resource?: string,
  options: { includeHeaders?: boolean } & AxiosRequestConfig = {},
) => {
  const baseURLSuffix = resource ? `${resource}/` : '';
  const { includeHeaders, ...requestHeaders } = options;
  const axiosInstance = axios.create({
    baseURL: `${BASE_API_URL}/${baseURLSuffix}`,
    headers: DEFAULT_HEADERS,
    withCredentials: true,
    ...requestHeaders,
  });

  // In PROD env, CAN_USE_MOCK_API = false, hence we never hit the mock APIs in PROD.
  if (CAN_USE_MOCK_API === 'true') {
    /**
     * We intercept each request to check if the API needs to hit the server,
     * or return the mock response/error.
     *
     * In order to mock any API, we need to pass data object containing the following keys:
     * - isMockApi: true
     * - failMockApi: true  (In case we wish the API to error out.)
     *
     * The adapter function here is to resolve the request here in the `request-intercepter`
     * itself in case we wish to mock the API.
     */
    axiosInstance.interceptors.request.use((config) => {
      const { isMockApi, failMockApi, ...data } = config.data || {};

      if (isMockApi) {
        config.adapter = (config) => {
          const mockedData: any = getMockedData(resource, config.url, data, failMockApi);

          return new Promise((resolve, reject) => {
            if (failMockApi) {
              return reject(getMockedApiResponse(mockedData, failMockApi, config));
            }
            return resolve(getMockedApiResponse(mockedData, failMockApi, config));
          });
        };
      }

      return config;
    });
  }

  axiosInstance.interceptors.request.use((config) => {
    // If there is existing session going on in current browser tab, pass that organization id on header, if session is not active or tab is new, pass the default fallback organization id.
    config.headers[ORGANIZATION_ID_HEADER_KEY] =
      sessionStorage.getItem(ORGANIZATION_ID_KEY) ||
      localStorage.getItem(ORGANIZATION_ID_KEY);
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (options.includeHeaders) {
        return {
          data: response.data,
          headers: response.headers,
        };
      }
      return response.data;
    },
    (error) => {
      if (error?.response.status === 401) {
        window.location.reload();
      }

      throw error;
    },
  );

  return axiosInstance;
};

export default axiosApi;
