import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppRoutesEnum from './AppRoutes.enum';

import { ProtectedRoute } from './components';
import { PageLoader } from 'common/components/loaders';
import { NavigateToOrgRoute } from 'common/components/navigation';
import AccountsRouter from 'features/accounts/accounts.router';

const CreateProfile = React.lazy(() => import('features/create-profile'));
const CreateOrganization = React.lazy(() => import('features/create-organization'));
const CreateCompany = React.lazy(() => import('features/create-companies'));
const SwitchOrganization = React.lazy(() => import('features/switch-organization'));
const OrgRoot = React.lazy(() => import('features/org-root'));

const AppRouter: React.FC = () => {
  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path={AppRoutesEnum.ACCOUNTS_ROOT} element={<AccountsRouter />} />
          <Route
            path={AppRoutesEnum.CREATE_ORGANIZATION}
            element={<ProtectedRoute element={<CreateOrganization />} />}
          />
          <Route
            path={AppRoutesEnum.CREATE_PROFILE}
            element={<ProtectedRoute element={<CreateProfile />} />}
          />
          <Route
            path={AppRoutesEnum.CREATE_COMPANY}
            element={<ProtectedRoute element={<CreateCompany />} />}
          />
          <Route path={AppRoutesEnum.SWITCH_ORGANIZATION} element={<SwitchOrganization />} />
          <Route
            path={AppRoutesEnum.ORGANIZATION_ROOT}
            element={<ProtectedRoute isOrgRoute={true} element={<OrgRoot />} />}
          />
          <Route
            path="*"
            element={
              <ProtectedRoute element={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />} />
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRouter;
