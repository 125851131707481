import React from 'react';
import { FormProvider } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

import useForm from 'common/hooks/useForm';
import useSession from 'common/hooks/useSession';
import StaticDataSelector from 'common/components/input/StaticDataSelector';
import InputAdornment from 'common/components/material/InputAdornment';
import { InlineIconButton } from 'features/org-root/org-root.styles';
import { LocationIQDetails, StaticData } from 'features/static-data/types/static-data.type';
import {
  StaticDataTypeEnum,
  WorkLocationSubdivisionEnum,
} from 'features/static-data/static-data.enum';
import { HierarchicalExplorerFormProps } from 'features/data-explorer/types/hierarchical-explorer-form-props.type';
import { setStaticDataPlaceholder } from 'features/data-explorer/utils/data-explorer.utils';
import { ExplorerDataTypeEnum } from '../data-explorer.enum';

const ExplorerCitySelector: React.FC<HierarchicalExplorerFormProps> = ({
  onChange,
  onClose,
}) => {
  const form = useForm();
  const { account: user } = useSession();

  return (
    <FormProvider {...form}>
      <StaticDataSelector
        formFieldProps={{
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <InlineIconButton onClick={() => onClose()}>
                  <CloseIcon color="primary" />
                </InlineIconButton>
              </InputAdornment>
            ),
          },
        }}
        autoFocus
        label=""
        name="city"
        type={StaticDataTypeEnum.LOCATIONS}
        placeholder="Type to search city"
        onChange={(staticData: StaticData) => {
          if (!staticData) {
            onClose();
          }

          const location = staticData as LocationIQDetails;

          const placeholderLocation = {
            id: location.osmId,
            name: location.city,
            fullName: `${location.country} - ${location.city}`,
            type: WorkLocationSubdivisionEnum.CITY,
            entityData: {
              osmId: location.osmId,
              osmType: location.osmType,
            },
          };

          setStaticDataPlaceholder(
            [user.organizationId, 'static-data', ExplorerDataTypeEnum.WORK_LOCATION],
            placeholderLocation,
          );

          onChange(placeholderLocation);
        }}
      />
    </FormProvider>
  );
};

export default ExplorerCitySelector;
