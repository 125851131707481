import { yupResolver } from '@hookform/resolvers/yup';
import { styled, useTheme } from '@mui/material/styles';
import { ImageAvatar } from '../image-avatar/image-avatar';
import {
  Button,
  CircularProgress,
  Divider,
  Link,
  Stack,
  Typography,
} from 'common/components/material';
import Dialog, { DialogProps } from 'common/components/material/Dialog';
import { IdentityProviderTypeEnum } from 'common/enum/IdentityProviderType.enum';
import { useForm, useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import { Organization } from 'common/types/Organization.type';
import {
  Account,
  AccountRequestParams,
  LoginSchema,
  useLogin,
  useSwitchOrganization,
} from 'features/accounts';
import Password from 'features/accounts/components/Password';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';

export type SwitchOrganizationModalProps = {
  open: boolean;
  organization?: Organization;
  onClose: () => void;
};

const OrganizationNameContainer = styled(Stack)(({ theme }) => ({
  padding: '0.5rem 0.3rem',
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.LinkContainer.StrokeDefault}`,
  boxShadow: AppBoxShadows.shadow1,
}));

const StyledDialog: React.FC<DialogProps> = styled((props: DialogProps) => (
  <Dialog {...props} />
))({
  padding: 0,
  minWidth: '20rem',
  minHeight: '5.313rem',
  maxHeight: '26.25rem',
  fontSize: '1rem',
});

const SwitchOrganizationModal: React.FC<SwitchOrganizationModalProps> = (props) => {
  const { organization, ...otherProps } = props;
  const { palette } = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { goTo, updateOrganization } = useRouter();
  const { account: user } = useSession();
  const login = useLogin();
  const switchOrganization = useSwitchOrganization();

  useEffect(() => {
    if (!switchOrganization.isLoading && !switchOrganization.isSuccess && organization) {
      switchOrganization.submit(organization.id);
    }
    if (
      switchOrganization.isSuccess &&
      switchOrganization.data?.data?.identityProvider?.type ===
        IdentityProviderTypeEnum.PASSWORD &&
      !switchOrganization.data?.data?.isAuthenticated
    ) {
      setShowPassword(true);
    }
    if (
      switchOrganization.error &&
      (switchOrganization.error.statusCode === 401 ||
        switchOrganization.error.statusCode === 403)
    ) {
      goTo(AppRoutesEnum.ACCOUNTS_LOGIN);
    }
  }, [
    switchOrganization.isSuccess,
    switchOrganization.isLoading,
    switchOrganization.error,
    organization,
    switchOrganization,
    goTo,
  ]);


  type LoginForm = { email: string, password: string, subUrl: string };
  const form = useForm<LoginForm>({
    validationErrors: login.validationErrors,
    resolver: yupResolver(LoginSchema),
    defaultValues: { email: user?.email, subUrl: organization?.subUrl },
  });

  const handleLogin = (data: AccountRequestParams) => {
    login.mutate(data, {
      onSuccess: (data, variables, context) => {
        const { data: account } = data as { data: Account };
        if (account?.subUrl) {
          updateOrganization(account?.subUrl);
        }
      },
    });
  };

  return (
    <>
      {organization && (
        <StyledDialog
          title="Switch Organization"
          disableScrollLock={true}
          scroll="body"
          closeButtonEnabled={true}
          {...otherProps}
        >
          <>
            <Divider variant="middle" />
            <Stack padding={2}>
              <OrganizationNameContainer direction="row" alignItems="center" marginBottom={3}>
                <ImageAvatar
                  src={organization.logoSrc}
                  organizationName={organization.name}
                  size="xxsmall"
                />
                <Typography
                  variant="body3"
                  fontWeight={500}
                  color={palette.Text.SubHeadline}
                  marginLeft={2}
                >
                  {organization?.name || ''}
                </Typography>
              </OrganizationNameContainer>
              <FormProvider {...form}>
                <form noValidate onSubmit={form.handleSubmit(handleLogin)}>
                  {showPassword && (
                    <>
                      <Password />
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginTop={4}
                      >
                        <Link color="primary" href={AppRoutesEnum.ACCOUNTS_FORGOT_PASSWORD}>
                          Forgot password?
                        </Link>
                        <Button
                          type="submit"
                          btntype="primary"
                          sx={{ minWidth: '6rem' }}
                          disabled={login.isLoading || login.isSuccess}
                        >
                          Login
                        </Button>
                      </Stack>
                    </>
                  )}
                  {!showPassword && (
                    <Stack direction="column" alignItems="center">
                      <CircularProgress />
                    </Stack>
                  )}
                </form>
              </FormProvider>
            </Stack>
          </>
        </StyledDialog>
      )}
    </>
  );
};
export default SwitchOrganizationModal;
