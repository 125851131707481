import { useTheme } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box, Stack, Typography } from 'common/components/material';
import { ManageProjectRequest, WinPeriod } from 'features/projects';
import { ProjectActionButton } from 'features/projects/styles/project-item-header.styles';

export const ExistingProjectHeaderActions: React.FC<{
  project: ManageProjectRequest;
  winPeriod: WinPeriod;
  isDisabled: boolean;
  isCopyButtonVisible: boolean;
  createProject: (project: ManageProjectRequest) => void;
}> = ({ project, winPeriod, isDisabled, isCopyButtonVisible, createProject }) => {
  const { palette } = useTheme();
  const handleCopyProject = (e: React.MouseEvent) => {
    e.stopPropagation();
    createProject(project);
  };

  return (
    <Box mr={2}>
      {isCopyButtonVisible ? (
        <ProjectActionButton
          btntype="secondary"
          disabled={isDisabled}
          onClick={handleCopyProject}
          sx={{ color: palette.Extras.Extra11, mr: 0 }}
        >
          Copy to {winPeriod.name}
        </ProjectActionButton>
      ) : (
        <Stack direction="row" spacing={0.75} alignItems="center" minWidth={140}>
          <CheckRoundedIcon sx={{ color: palette.Extras.Extra11, width: 20 }} />
          <Typography variant="caption" fontWeight={500} color={palette.Extras.Extra11}>
            Copied to {winPeriod.name}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
