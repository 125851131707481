import { SystemFeatureAccessEnum } from './enums/feature-access.enum';
import { FeaturesEnum } from './features.enum';
import {
  getContentCreationAccess,
  getOwnProfileAccess,
  getSearchSuggestAccess,
  getUserSettingsAccess,
} from './utils/feature-accesses.utils';
import { FeatureAccessStrategies } from './types/feature-access.type';

export const DEFAULT_SYSTEM_FEATURE_ACCESS = {
  [SystemFeatureAccessEnum.READ]: false,
  [SystemFeatureAccessEnum.WRITE]: false,
};

export const DEFAULT_FEATURE_ACCESS_PERMISSION = {
  canRead: true,
  canWrite: false,
};

export const featureAccessStrategies = {
  [FeaturesEnum.OWN_PROFILE]: getOwnProfileAccess,
  [FeaturesEnum.CONTENT_CREATION_WIDGET]: getContentCreationAccess,
  [FeaturesEnum.USER_SETTINGS]: getUserSettingsAccess,
  [FeaturesEnum.SEARCH_SUGGESTION]: getSearchSuggestAccess,
} as FeatureAccessStrategies;
