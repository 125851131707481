import React, { useEffect, useRef, useState } from 'react';
import { FormAutocomplete } from 'common/components/input';
import { useDebounce, useDeferredState, useSession, useView } from 'common/hooks';
import { useSearchTags } from 'features/tags/hooks/useSearchTags';
import { Tag } from 'features/tags/types';
import { Typography } from 'common/components/material';
import { CXEditIcon } from 'common/components/cx-edit-icon';
import { Stack, useTheme } from '@mui/material';
import { AutocompleteProps } from 'common/types/Form.type';
import { TagContentType } from 'features/tags/enums/tag-content-type.enum';
import { StaticDataAvatarComponent } from 'features/static-data/components/static-data-avatar.component';
import { OrganizationalTagPagesTypeEnum } from 'features/static-data';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

export type OnBehalfOfSelectorProps = Omit<
  AutocompleteProps,
  'options' | 'onChange' | 'label'
> & {
  tag: Tag | null;
  label?: string;
  onChange: (tag: Tag | null) => void;
  contentType?: TagContentType;
};

const OnBehalfOfSelector: React.FC<OnBehalfOfSelectorProps> = ({
  tag,
  name,
  label,
  onChange,
  contentType,
  ...onBehalfOfSelectorSelectorProps
}) => {
  const { isMobileView } = useView();
  const { palette } = useTheme();
  const autocompleteRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState('');
  const [onBehalfOf, setOnBehalfOf] = useDeferredState<Tag | null>(tag);
  const [isDropdownVisible, setIsDropdownVisible] = useState(!onBehalfOf);
  const [options, setOptions] = useState<Tag[]>(onBehalfOf ? [onBehalfOf] : []);
  const { canWrite: canEditAllStaticDataPages } = useFeatureAccess(
    FeaturesEnum.ALL_STATIC_DATA_PAGES,
  );

  const debouncedInputValue = useDebounce(inputValue);

  const optionLabel = (option: Tag) => option.completeName || option.name;
  const { isLoading } = useSearchTags(
    {
      term: debouncedInputValue,
      contentType,
      size: 250,
    },
    {
      enabled: true,
      onSuccess: ({ data }) => {
        const { tags } = data;
        setOptions((prevOptions) => {
          const existingOptions = new Set(prevOptions.map((tag: Tag) => tag.id));
          const newTags = tags.filter((tag: Tag) => !existingOptions.has(tag.id));
          return [...prevOptions, ...newTags];
        });
      },
    },
  );

  const { currentOrganization, profile } = useSession();
  /**
   * Add the Company tag to the list of options for admins and content admins.
   * Don't do that if in the list already!
   */
  useEffect(() => {
    if (canEditAllStaticDataPages) {
      const company = currentOrganization();
      if (company && !options.find((o) => o.id === company.id)) {
        setOptions((prevOptions) => [
          {
            id: company.id,
            name: company.name,
            displayName: company.name,
            completeName: company.name,
            icon: company.logo,
            type: 'COMPANY',
          },
          ...prevOptions,
        ]);
      }
    }
  }, [profile, currentOrganization, options, canEditAllStaticDataPages]);

  // Handler to focus the input and show the dropdown
  const handleEditClick = () => {
    setIsDropdownVisible(true);
    setTimeout(() => {
      const input = autocompleteRef.current?.querySelector('input');
      if (input) {
        input.focus();
        input.select();
      }
    }, 100); // Adding a slight delay to ensure the input is ready to be focused
  };

  return (
    <Stack
      direction={isMobileView && isDropdownVisible ? 'column' : 'row'}
      alignItems={isMobileView && isDropdownVisible ? 'normal' : 'center'}
    >
      {!isDropdownVisible && (
        <Typography
          variant={isMobileView ? 'body4' : 'body3'}
          color={palette.Text.Caption}
          marginRight={0.7}
        >
          {label}
        </Typography>
      )}
      <FormAutocomplete
        autoComplete
        autoFocus={isDropdownVisible}
        openOnFocus={true}
        selectOnFocus={true}
        hidden={!isDropdownVisible}
        loading={isLoading}
        name="onBehalfOf"
        label="Post on behalf of"
        multiple={false}
        groupBy={(option) => option.type}
        getOptionLabel={(option) => optionLabel(option)}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        onInputChange={(e, value) => {
          setInputValue(value);
        }}
        onChange={(value: Tag) => {
          setInputValue('');
          setOnBehalfOf(value);
          onChange && onChange(value);
          if (value) setIsDropdownVisible(false);
        }}
        options={options}
        noOptionsText="No options found"
        value={onBehalfOf}
        inputValue={inputValue}
        ref={autocompleteRef} // Attach the ref to the input element
        {...onBehalfOfSelectorSelectorProps}
      />

      {!isDropdownVisible && onBehalfOf && (
        <Stack
          direction="row"
          spacing={0.5}
          justifyContent="center"
          alignItems="center"
          color={palette.Text.Caption}
        >
          <StaticDataAvatarComponent
            src={onBehalfOf?.icon}
            fullName={onBehalfOf?.completeName || onBehalfOf?.name}
            size="xsmall"
            isLocation={
              onBehalfOf?.type === OrganizationalTagPagesTypeEnum.CITY ||
              onBehalfOf?.type === OrganizationalTagPagesTypeEnum.COUNTRY
            }
          />
          <EllipsisText
            title={onBehalfOf?.completeName || onBehalfOf?.name}
            ml={0.7}
            variant="body4"
            lineclamp={1}
          >
            {onBehalfOf?.name}
          </EllipsisText>
          <CXEditIcon onClick={handleEditClick} size="xsmall" />
        </Stack>
      )}
    </Stack>
  );
};

export default OnBehalfOfSelector;
