import { useParams } from 'react-router-dom';
import { useSession } from 'common/hooks';

const useParamsProfileId = () => {
  const { profileId } = useParams();
  const { profile: loggedInProfile } = useSession();

  const isLoggedInUser = profileId === loggedInProfile?.id;

  return {
    profileId: profileId || '',
    isLoggedInUser,
  };
};

export default useParamsProfileId;
