import React, { useState } from 'react';
import { get, isEmpty, isNil } from 'lodash';

import { useView } from 'common/hooks';

import HierarchicalExplorerColumn from './hierarchical-explorer-column.component';
import BaseDataExplorer from './base-data-explorer.component';
import { getColumnData } from 'features/static-data';
import { useDataExplorerState } from 'features/explore';
import HierarchicalExplorerEmptyView from './hierarchical-explorer-empty-view.component';
import { useManageExplorerData } from 'features/data-explorer/hooks/useManageExplorerData';
import { useGetExplorerData } from 'features/data-explorer/hooks';
import { ExplorerDataMapItem } from 'features/data-explorer/types/explorer-data.type';
import { HierarchicalExplorerItemAction, HierarchicalExplorerProps } from '../types';
import HierarchicalExplorerColumnContainer from './hierarchical-explorer-column-container.component';
import { FormOperationsEnum } from 'common/enum/Form.enum';
import { getAdminExplorerConfig } from '../admin-explorer.config';
import { FILTER_EXPLORER_CONFIG } from '../filter-explorer.config';
import { useGetIsOrganizationHrisManaged } from 'features/companies/hooks/useGetIsHrisManagedOrganization';
import { transformActiveFiltersMapToObject } from 'features/search';

const HierarchicalExplorer: React.FC<HierarchicalExplorerProps> = ({
  dataType,
  showTitle = true,
  activeFilterMap,
  isReadView,
  headerActions,
  searchTerm = '',
  showFlatList = false,
  ListItemComponent,
  onItemAction,
  onClickBack,
  contentType,
}) => {
  const { data: isHrisManagedOrganization } = useGetIsOrganizationHrisManaged();
  const config = isReadView
    ? FILTER_EXPLORER_CONFIG[dataType]
    : getAdminExplorerConfig(isHrisManagedOrganization)[dataType];

  const {
    header,
    levels: levelConfigs,
    showBackButtonInHierarchicalView,
    showAddButton,
    excludeFilterTypes,
  } = config;
  const { isDesktopView } = useView();
  const [dataIdToEdit, setDataIdToEdit] = useState<string | number | null>(null);

  const selectedSearchFilters = transformActiveFiltersMapToObject(
    activeFilterMap,
    excludeFilterTypes || [dataType],
  );

  const { data, isLoading } = useGetExplorerData({
    config,
    type: dataType,
    includeMapping: true,
    searchTerm,
    contentType: contentType || 'people',
    isReadView,
    ...selectedSearchFilters,
  });
  const staticDataList = data?.result || [];
  const mapping = data?.mapping || {};

  const { mutate, isLoading: isSubmitting } = useManageExplorerData({
    config,
    dataType,
  });

  const maxColumns = isDesktopView ? levelConfigs.length : 1;
  const showEmptyView = isEmpty(staticDataList) && !isLoading && isNil(dataIdToEdit);
  const showBackButton = showBackButtonInHierarchicalView || maxColumns === 1;

  const {
    displayedTitle,
    activeColumn,
    activeDataList,
    handleDataSelect,
    goBackOneColumn,
    setActiveDataList,
  } = useDataExplorerState<ExplorerDataMapItem>({
    title: header,
    maxColumns,
    showFlatList,
    onExit: onClickBack,
  });

  const toggleEditMode = (
    staticDataId: string | number | null,
    data?: ExplorerDataMapItem,
  ) => {
    // Passing data here means we'll open the child column input form after adding the first column data
    if (data) {
      handleDataSelect(data, 0);
    }

    setDataIdToEdit(staticDataId);
  };

  const handleDeleteStaticData = (id?: string) => {
    const activeIndex = activeDataList.findIndex((activeData) => id === activeData.id);
    if (activeIndex > -1) {
      const newActiveData = activeDataList.slice(0, activeIndex);
      setActiveDataList(newActiveData);
    }
  };

  const handleSelect = (data: ExplorerDataMapItem, index: number) => {
    handleDataSelect(data, index);
    toggleEditMode(null);
  };

  const handleItemAction = (
    actionProps: HierarchicalExplorerItemAction,
    dataIndex: number,
  ) => {
    const { data, actionType, mapping, value } = actionProps;
    const exploreItem = mapping ? mapping[data.id] : data;

    switch (actionType) {
      case FormOperationsEnum.EXPAND:
        handleSelect(exploreItem, dataIndex);
        break;
      case FormOperationsEnum.EDIT:
      case FormOperationsEnum.ADD_OR_UPDATE_CITY:
        toggleEditMode(data.id);
        break;
      case FormOperationsEnum.CLOSE_EDIT:
        toggleEditMode(null);
        break;
      case FormOperationsEnum.CREATE:
      case FormOperationsEnum.UPDATE:
      case FormOperationsEnum.DELETE:
      case FormOperationsEnum.TOGGLE_VISIBILITY:
        mutate(
          { ...value, actionType },
          {
            onSuccess: () => {
              if (actionType === FormOperationsEnum.DELETE) {
                handleDeleteStaticData(value.id);
              }

              setDataIdToEdit(null);
            },
          },
        );
        break;
      case 'proceed-form':
        toggleEditMode(dataIndex + 1, value);
        break;
      default:
        onItemAction && onItemAction(actionProps);
    }
  };

  // Called for each # of columns we pass to DataExplorer.
  const renderColumn = (columnIndex: number) => {
    /**
     * columnIndex refers to the column being rendered, while
     * dataIndex refers to the level of the static data hierarchy.
     * These two values can be different on mobile view when we can only show
     * 1 column, but can still traverse up/down the hierarchy.
     */
    const dataIndex = columnIndex + activeColumn;
    const columnConfig = levelConfigs[dataIndex];
    // Array of items being rendered
    const columnData = showFlatList
      ? staticDataList
      : getColumnData(
          dataIndex,
          staticDataList,
          activeDataList,
          columnConfig,
          config.includeUnassignedOption,
        );

    return (
      <HierarchicalExplorerColumn
        key={dataIndex}
        index={dataIndex}
        data={columnData || []}
        parentId={dataIndex ? activeDataList[dataIndex - 1]?.id : undefined}
        mapping={mapping}
        showAddButton={!!showAddButton}
        config={columnConfig}
        renderItem={(staticData) => {
          const labelKey = showFlatList
            ? columnConfig.searchItemLabelKey
            : columnConfig.itemLabelKey;
          const hasChildren =
            !staticData.excludeChildren && !!get(mapping[staticData.id], 'children.length');
          return (
            <ListItemComponent
              key={staticData.id}
              data={staticData}
              dataType={dataType}
              labelKey={labelKey}
              config={columnConfig}
              mapping={mapping}
              activeFilterMap={activeFilterMap}
              active={!showFlatList && staticData.id === activeDataList[dataIndex]?.id}
              showArrow={!showFlatList && hasChildren}
              editMode={staticData.id === dataIdToEdit}
              disabled={isSubmitting}
              onItemAction={(itemAction) =>
                handleItemAction({ ...itemAction, mapping, type: dataType }, dataIndex)
              }
            />
          );
        }}
        dataType={dataType}
        isReadView={isReadView}
        editMode={dataIdToEdit === dataIndex}
        onToggleEditMode={toggleEditMode}
        onItemAction={(itemAction) => handleItemAction({ ...itemAction, mapping }, dataIndex)}
        disabled={isSubmitting}
      />
    );
  };

  const openNewStaticDataForm = () => {
    // Opens form to add new static data on the first column
    toggleEditMode(0);
  };

  return (
    <BaseDataExplorer
      title={showTitle ? displayedTitle : ''}
      onClickBack={showBackButton ? goBackOneColumn : undefined}
      emptyView={
        <HierarchicalExplorerEmptyView
          isReadView={isReadView}
          searchTerm={searchTerm}
          onClick={openNewStaticDataForm}
        />
      }
      showEmptyView={showEmptyView}
      headerActions={headerActions}
      isLoading={isLoading}
    >
      <HierarchicalExplorerColumnContainer
        columnCount={showFlatList ? 1 : maxColumns}
        renderColumn={renderColumn}
        showColumnDividers={isDesktopView && !showFlatList}
      />
    </BaseDataExplorer>
  );
};

export default HierarchicalExplorer;
