import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';

import MenuItemButton from './MenuItemButton';
import { useSession } from 'common/hooks';
import { useLogout } from 'features/accounts';

const LogoutMenuItem: React.FC = () => {
  const { account } = useSession();
  const { submit } = useLogout();
  const { palette } = useTheme();

  const handleClick = () => {
    submit();
  };

  return (
    <MenuItemButton
      onClick={handleClick}
      label={`Logout ${account?.email}`}
      listItemIconSx={{ color: palette.SpecialButtons.Delete.LabelHover }}
      listItemTextSx={{ color: palette.SpecialButtons.Delete.LabelHover }}
      listItemSx={{
        ':hover': {
          background: palette.SpecialButtons.Delete.BgHover,
          '.MuiTypography-root': { color: palette.SpecialButtons.Delete.LabelHover },
          '.MuiSvgIcon-root': { color: palette.SpecialButtons.Delete.LabelHover },
        },
      }}
    >
      <LogoutIcon />
    </MenuItemButton>
  );
};

export default LogoutMenuItem;
