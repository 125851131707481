import React from 'react';
import MUIChip, { ChipProps as MUIChipProps } from '@mui/material/Chip';
import { MUIRef } from 'common/types/MUIRef.type';

export type ChipProps = MUIChipProps;

const Chip: React.FC<ChipProps> = React.forwardRef((props: ChipProps, ref: MUIRef) => {
  const { children, ...chipProps } = props;

  return (
    <MUIChip ref={ref} {...chipProps}>
      {children}
    </MUIChip>
  );
});

export default Chip;
