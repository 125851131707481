import React, { useState } from 'react';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useTheme } from '@mui/material/styles';
import { isNil } from 'lodash';

import { ElevationScroll, ToggleVisibilityContainer } from 'common/components/container';
import {
  ConnectXLogo,
  HomeIcon,
  OrgChartIcon,
  ProfileIcon,
  SvgIconState,
} from 'common/components/image';
import { Grid, Stack } from 'common/components/material';
import {
  NavigationIconButton,
  OrganizationDropdown,
  SearchBar,
} from 'common/components/navigation';
import { NavigationTabEnum } from 'common/enum/NavigationTab.enum';
import { useDrawer, useRouter, useSession } from 'common/hooks';
import useSetActiveNavigationTab, {
  NavigationState,
} from 'common/hooks/useSetActiveNavigationTab';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import GlobalCreateButton from 'features/org-root/components/GlobalCreateButton';
import NotificationIcon from 'features/org-root/components/Notifications/NotificationIcon';
import ApplicationBar from './ApplicationBar';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { useGetL0ManagerId } from 'features/profiles/hooks/useGetL0ManagerId';

type DesktopApplicationBarProps = {
  showAllMenuItems?: boolean;
  isLoading?: boolean;
};
const DesktopApplicationBar: React.FC<DesktopApplicationBarProps> = ({
  showAllMenuItems = true,
  isLoading,
}) => {
  const { profile, organizationProfile, hasOrganization } = useSession();
  const canShowAllMenuItems = showAllMenuItems && !isLoading && !isNil(profile);
  const { openDrawer } = useDrawer();
  const { palette } = useTheme();

  const [activeTab, setActiveTab] = useState<NavigationState>({});
  useSetActiveNavigationTab(setActiveTab);

  const { goTo, goHome } = useRouter();

  const isGuestProfile = !!organizationProfile?.isGuestProfile;

  const { data: l0ManagerId } = useGetL0ManagerId({
    enabled: isGuestProfile,
  });

  const goToProfile = () => {
    setActiveTab((navigationState: NavigationState) => ({
      currentTab: NavigationTabEnum.PROFILE,
      previousTab: navigationState.currentTab,
    }));
    goTo(
      AppRoutesEnum.PROFILE,
      { profileId: profile.id },
      { state: { resetHistoryIndex: true } },
    );
  };

  const goToOrgChart = () => {
    // avoid UI breaking when employee-hierarchical-count-cache is unsynced
    if ((isGuestProfile && !l0ManagerId) || !profile?.id) return;

    setActiveTab((navigationState: NavigationState) => ({
      currentTab: NavigationTabEnum.ORG_CHART,
      previousTab: navigationState.currentTab,
    }));
    goTo(
      AppRoutesEnum.ORG_CHART_PROFILE,
      {
        id: isGuestProfile ? l0ManagerId : profile.id,
      },
      { state: { resetHistoryIndex: true } },
    );
  };

  const goToHome = () => {
    setActiveTab((navigationState: NavigationState) => ({
      currentTab: NavigationTabEnum.HOME,
      previousTab: navigationState.currentTab,
    }));
    goHome({}, { state: { resetHistoryIndex: true } });
  };

  const toggleMenuDrawer = () => {
    if (isLoading) {
      return;
    }
    openDrawer(DrawerIdEnum.MENU, {});
  };

  const toggleSettingsDrawer = () => {
    if (isLoading) {
      return;
    }
    openDrawer(DrawerIdEnum.SETTINGS, {});
  };

  return (
    <>
      <ElevationScroll>
        <ApplicationBar>
          <Grid container alignItems="center">
            <Grid container spacing={2} item md={3} flexWrap="nowrap">
              <Grid item display="flex" alignItems="center">
                <NavigationIconButton
                  onClick={toggleMenuDrawer}
                  tooltip="Menu"
                  isActive={activeTab.currentTab === NavigationTabEnum.ADMIN}
                >
                  <SvgIconState
                    ActiveIcon={MenuOutlinedIcon}
                    DisabledIcon={MenuOutlinedIcon}
                    isActive={activeTab.currentTab === NavigationTabEnum.ADMIN}
                  />
                </NavigationIconButton>
              </Grid>
              <Grid item display="flex" alignItems="center">
                <ConnectXLogo />
              </Grid>
              {hasOrganization && canShowAllMenuItems && (
                <Grid item display="flex" minWidth={0}>
                  <OrganizationDropdown />
                </Grid>
              )}
            </Grid>
            <Grid item xs={4} md={6}>
              <ToggleVisibilityContainer isVisible={canShowAllMenuItems}>
                <Stack direction="row" spacing={2}>
                  <SearchBar />
                  <GlobalCreateButton />
                </Stack>
              </ToggleVisibilityContainer>
            </Grid>
            <Grid
              container
              item
              md={3}
              spacing={2}
              justifyContent="right"
              alignItems="center"
            >
              <Grid item>
                <ToggleVisibilityContainer isVisible={canShowAllMenuItems}>
                  <NavigationIconButton
                    onClick={goToHome}
                    tooltip="Home"
                    isActive={activeTab.currentTab === NavigationTabEnum.HOME}
                  >
                    <HomeIcon isActive={activeTab.currentTab === NavigationTabEnum.HOME} />
                  </NavigationIconButton>
                </ToggleVisibilityContainer>
              </Grid>
              {hasOrganization && (
                <FeatureGuard features={[{ feature: FeaturesEnum.ORG_CHART }]}>
                  <Grid item>
                    <ToggleVisibilityContainer isVisible={canShowAllMenuItems}>
                      <NavigationIconButton
                        onClick={goToOrgChart}
                        tooltip="Org Chart"
                        isActive={activeTab.currentTab === NavigationTabEnum.ORG_CHART}
                      >
                        <OrgChartIcon
                          color={palette.Icons.Tertiary.IconFillDefault}
                          isActive={activeTab.currentTab === NavigationTabEnum.ORG_CHART}
                        />
                      </NavigationIconButton>
                    </ToggleVisibilityContainer>
                  </Grid>
                </FeatureGuard>
              )}
              <FeatureGuard features={[{ feature: FeaturesEnum.OWN_PROFILE }]}>
                <Grid item>
                  <ToggleVisibilityContainer isVisible={canShowAllMenuItems}>
                    <NavigationIconButton
                      data-role="profile-icon"
                      onClick={goToProfile}
                      tooltip="Profile"
                      isActive={activeTab.currentTab === NavigationTabEnum.PROFILE}
                    >
                      <ProfileIcon
                        photo={profile?.photo}
                        isActive={activeTab.currentTab === NavigationTabEnum.PROFILE}
                      />
                    </NavigationIconButton>
                  </ToggleVisibilityContainer>
                </Grid>
              </FeatureGuard>
              {hasOrganization && (
                <Grid item>
                  <ToggleVisibilityContainer isVisible={canShowAllMenuItems}>
                    <NavigationIconButton tooltip="Notifications" isActive={false}>
                      <NotificationIcon />
                    </NavigationIconButton>
                  </ToggleVisibilityContainer>
                </Grid>
              )}

              <Grid item>
                <NavigationIconButton
                  onClick={toggleSettingsDrawer}
                  tooltip="Settings"
                  isActive={activeTab.currentTab === NavigationTabEnum.ADMIN}
                >
                  <SvgIconState
                    ActiveIcon={SettingsOutlinedIcon}
                    DisabledIcon={SettingsOutlinedIcon}
                    isActive={activeTab.currentTab === NavigationTabEnum.ADMIN}
                  />
                </NavigationIconButton>
              </Grid>
            </Grid>
          </Grid>
        </ApplicationBar>
      </ElevationScroll>
    </>
  );
};

export default DesktopApplicationBar;
