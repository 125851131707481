import React from 'react';
import MUICollapse from '@mui/material/Collapse';
import { CollapseProps } from './collapse.type';

const Collapse: React.FC<CollapseProps> = (props) => {
  const { children, ...chipProps } = props;
  return <MUICollapse {...chipProps}>{children}</MUICollapse>;
};

export default Collapse;
