import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from 'common/components/material';
import { UserAvatarGroup } from 'features/profiles/components/user-avatar-group';
import EndorsersModal from 'common/components/modals/EndorsersModal';
import { EndorsersAvatarGroupProps } from 'features/skills';
import { useDrawer } from 'common/hooks';
import { DrawerIdEnum } from '../enums/drawer-id.enum';

const EndorsersAvatarGroup: React.FC<EndorsersAvatarGroupProps> = ({
  endorsers,
  totalEndorsers,
  endorseeId,
  skillId,
}) => {
  const [openEndorsersDialog, setOpenEndorsersDialog] = useState(false);
  const { closeDrawer } = useDrawer();
  const { palette } = useTheme();

  return (
    <>
      <Stack flexDirection={{ xs: 'row', md: 'row-reverse' }}>
        {!!endorsers?.length ? (
          <UserAvatarGroup
            persons={endorsers}
            total={totalEndorsers}
            onClick={() => setOpenEndorsersDialog(true)}
          />
        ) : (
          <Typography variant="caption" color={palette.Text.SubtleText}>
            No Endorsements
          </Typography>
        )}
      </Stack>

      {openEndorsersDialog && (
        <EndorsersModal
          endorseeId={endorseeId}
          skillId={skillId}
          open={openEndorsersDialog}
          onClose={() => setOpenEndorsersDialog(false)}
          onClick={() => closeDrawer(DrawerIdEnum.SKILLS_ACTION)}
        ></EndorsersModal>
      )}
    </>
  );
};

export default EndorsersAvatarGroup;
