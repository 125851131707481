import React from 'react';
import { useTheme } from '@mui/material/styles';

import { AppBar, Toolbar } from 'common/components/material';
import { useVirtualKeyboardVisibility } from 'common/hooks';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const DEFAULT_APP_BAR_HEIGHT = '3.875rem';

type ApplicationBarProps = {
  children: React.ReactElement;
  customHeight?: string;
};

const ApplicationBar: React.FC<ApplicationBarProps> = ({ children, customHeight }) => {
  const isVirtualKeyboardOpen = useVirtualKeyboardVisibility();
  const { palette } = useTheme();
  return (
    <AppBar
      position={isVirtualKeyboardOpen ? 'relative' : 'sticky'}
      sx={{
        height: customHeight || DEFAULT_APP_BAR_HEIGHT,
        backgroundColor: palette.Container.Bg,
        boxShadow: AppBoxShadows.shadow1,
        backgroundImage: 'none',
      }}
    >
      <Toolbar
        sx={{
          maxWidth: 'lg',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
          alignSelf: 'center',
        }}
        variant="dense"
      >
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default ApplicationBar;
