import React from 'react';
import List from 'common/components/material/List';
import { StyledSearchOption } from 'common/components/navigation/styles';
import { Typography } from 'common/components/material';
import { ListItemText } from 'common/components/material/ListItemButton';
import { useTheme } from '@mui/material';

export const RecentSearchList: React.FC<{
  showNoResults: boolean;
}> = ({ showNoResults, children }) => {
  const { palette } = useTheme();

  const noRecentSearchItemElm = (
    <StyledSearchOption
      color={palette.Text.Caption}
      key="no-result"
      dense={true}
      sx={{ paddingY: '1px' }}
    >
      <ListItemText>
        <Typography width="100%" variant="body4" padding="13px">
          No Recent Searches
        </Typography>
      </ListItemText>
    </StyledSearchOption>
  );

  return (
    <List data-analytics-tag="search_suggest_recent_search">
      {showNoResults ? noRecentSearchItemElm : children}
    </List>
  );
};
