import React, { useEffect, useState } from 'react';
import { partition, capitalize } from 'lodash';
import { styled, darken, Theme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  Menu,
  MenuItem,
  MenuProps,
  MenuItemProps,
} from 'common/components/material/MenuList';
import { Button } from 'common/components/material';
import theme from 'common/theme';

export type Option = {
  label: string;
  value: any;
  dropdownLabel: string;
  color: string;
  bgColor: string;
  bgHover?: string;
};

const getSizeStyle = (theme: Theme, size: string) => {
  switch (size) {
    case 'xsmall':
      return {
        fontSize: theme.typography.caption2.fontSize,
      };
    case 'small':
      return {
        fontSize: theme.typography.caption.fontSize,
      };
    default:
      return {
        fontSize: theme.typography.body2.fontSize,
      };
  }
};

type StatusDropdownButtonProps = {
  value: any;
  options: Option[];
  readOnly?: boolean;
  disabled?: boolean;
  size?: 'xsmall' | 'small' | 'medium';
  onClick?: (value: any) => void;
};

const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  marginTop: 3,
  '.MuiPaper-root': {
    borderRadius: 6,
    border: `0.1rem solid ${theme.palette.Container.Stroke}`,
  },
  '.MuiList-root': {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => {
    return prop !== 'color';
  },
})<MenuItemProps>(({ color }) => ({
  color,
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'space-between',
  lineHeight: 'normal',
  padding: '2px 5px',
}));

const StatusDropdownButton: React.FC<StatusDropdownButtonProps> = ({
  value: initialValue,
  options,
  readOnly,
  size = 'medium',
  disabled,
  onClick,
}) => {
  const [anchorDropdown, setAnchorDropdown] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorDropdown);
  const [value, setValue] = useState(initialValue);
  const [selectedItems, optionItems] = partition(options, (option) => option.value === value);
  const selectedItem = selectedItems[0] || {};
  const hoverStyleOverride = readOnly
    ? {
        '&:hover': {
          backgroundColor:
            selectedItem.bgHover ||
            selectedItem.bgColor ||
            theme.palette.StatusDropdown.Closed.BgHover,
          cursor: 'default',
        },
      }
    : {
        '&:hover': {
          backgroundColor: darken(
            selectedItem.bgHover ||
              selectedItem.bgColor ||
              theme.palette.StatusDropdown.Closed.BgHover,
            0.02,
          ),
        },
      };
  const sizeStyle = getSizeStyle(theme, size);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    // Prevent navigating to link if placed inside a link item
    event.preventDefault();
    if (readOnly) {
      return;
    }
    setAnchorDropdown(event.currentTarget);
  };

  const handleCloseDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorDropdown(null);
  };

  const handleClickDropdownItem = (event: React.MouseEvent, item: string) => {
    event.stopPropagation();
    setValue(item);
    onClick && onClick(item);
    setAnchorDropdown(null);
  };

  return (
    <>
      <StyledButton
        aria-label="status-dropdown"
        sx={{
          color:
            selectedItem.color || theme.palette.StatusDropdown.Closed.LabelAndIconDefault,
          backgroundColor:
            selectedItem.bgColor || theme.palette.StatusDropdown.Closed.BgDefault,
          minWidth: readOnly || size === 'xsmall' ? 0 : 58,
          ...sizeStyle,
          ...hoverStyleOverride,
        }}
        disableRipple={readOnly}
        disabled={disabled}
        onClick={handleOpenDropdown}
      >
        {capitalize(selectedItem.label)}
        {!readOnly && <KeyboardArrowDownIcon sx={{ fontSize: 14, height: 'inherit' }} />}
      </StyledButton>
      <StyledMenu
        anchorEl={anchorDropdown}
        open={open}
        onClose={handleCloseDropdown}
        disableScrollLock={true}
      >
        {optionItems?.map((option: Option, index: number) => (
          <StyledMenuItem
            key={index}
            value={option.value}
            color={option.color}
            sx={sizeStyle}
            onClick={(e: React.MouseEvent) => handleClickDropdownItem(e, option.value)}
          >
            {capitalize(option.dropdownLabel)}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default StatusDropdownButton;
