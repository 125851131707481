import { PropsWithChildren } from 'react';
import { TreeItem as MUITreeItem } from '@mui/x-tree-view';
import { CXTreeItemProps } from '../types/cx-tree.types';

function CXTreeItem<T>({
  ContentComponent,
  ...props
}: PropsWithChildren<CXTreeItemProps<T>>) {
  return <MUITreeItem ContentComponent={ContentComponent} {...props} />;
}

export default CXTreeItem;
