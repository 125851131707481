import React from 'react';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Card, Divider, Stack, Tooltip, Typography } from 'common/components/material';
import { CardContent, CardProps } from 'common/components/material/Card';
import Collapse from 'libs/mui/collapse/collapse.component';
import { TooltipProps } from '../material/Tooltip';

const CollapsibleCard: React.FC<
  {
    label: string;
    subLabel?: string;
    headerActions?: React.ReactNode;
    isConciseView?: boolean;
    tooltip?: Omit<TooltipProps, 'children'>;
    hideDivider?: boolean;
    children: React.ReactNode;
  } & CardProps
> = ({
  label,
  subLabel,
  isConciseView = false,
  tooltip,
  children,
  headerActions,
  hideDivider,
  ...props
}) => {
  const { palette } = useTheme();

  return (
    <Card noElevation={true} className="full-width" {...props}>
      <Collapse in={!isConciseView} timeout={150}>
        <CardContent
          sx={{ paddingX: 1.5, '&:first-of-type': { pt: 0 }, '&:last-child': { pb: 0 } }}
        >
          <Stack
            direction="row"
            py={1.5}
            justifyContent="space-between"
            alignItems="center"
            whiteSpace={'nowrap'}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography
                py={0.5}
                color={palette.Text.Headline}
                variant="body2"
                fontWeight={700}
              >
                {label}
              </Typography>
              {!!subLabel && (
                <Typography py={0.5} color={palette.Text.Caption} variant="caption">
                  {subLabel}
                </Typography>
              )}
              {!!tooltip && (
                <Tooltip enterTouchDelay={0} {...tooltip}>
                  <InfoOutlinedIcon sx={{ color: palette.Text.SubHeadline, height: 15 }} />
                </Tooltip>
              )}
            </Stack>
            {headerActions}
          </Stack>
          {!hideDivider && <Divider />}
        </CardContent>
      </Collapse>
      <CardContent sx={{ paddingX: 1.5, pt: 0, '&:last-child': { pb: 1.5 } }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default CollapsibleCard;
