import { pick, toLower } from 'lodash';
import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { createHierarchicalDataMapping } from '../utils/data-explorer.utils';
import { GetExplorerDataParams } from '../types/get-explorer-data-params.type';
import { ExplorerDataMapItem } from '../types/explorer-data.type';
import { ExplorerDataTypeEnum } from '../data-explorer.enum';

export const useGetExplorerData = (
  params: GetExplorerDataParams,
  options: UseQueryOptions = {},
) => {
  const { account } = useSession();
  const organizationId = account.organizationId || '';
  const {
    config,
    type,
    includeMapping,
    searchTerm,
    contentType,
    isReadView,
    ...selectedSearchFilter
  } = params;
  const apiParams = pick(params, config.getApiParamKeys);

  const isValidExplorerDataType =
    !!type && Object.values(ExplorerDataTypeEnum).includes(type);

  /**
   * IsReadView flag identifies if this hook is used on Explore screen or on Manage Static data.
   * On Manager static data, we are not required to have content type as well as selectedSearchFilters.
   */
  const cacheKey = !isReadView
    ? [organizationId, 'static-data', type]
    : [organizationId, contentType, 'static-data', type, selectedSearchFilter];

  const queryResponse = useQuery(
    cacheKey,
    () => isValidExplorerDataType && config.getApiFunction(apiParams),
    {
      enabled: !!organizationId && isValidExplorerDataType,
      showErrorSnackbar: true,
      select: (result) => {
        const staticDataList = result.data.result;

        const filteredList = searchTerm
          ? staticDataList.filter((staticData: ExplorerDataMapItem) => {
              return toLower(staticData.fullName).includes(toLower(searchTerm));
            })
          : staticDataList;

        const transformedRawList = config.transform
          ? config.transform(staticDataList)
          : staticDataList;

        const transformedList = config.transform
          ? config.transform(filteredList)
          : filteredList;

        return {
          ...result,
          data: {
            isEnabled: result.data.isEnabled,
            result: transformedList,
            mapping:
              includeMapping && createHierarchicalDataMapping(transformedRawList, type),
          },
        };
      },
      ...options,
    },
  );

  return {
    ...queryResponse,
    data: queryResponse?.data?.data,
  };
};
