import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { camelCase, isEmpty, mapKeys, mapValues, map, get } from 'lodash';
import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { ActiveFilters, Filter } from '../types/filters.type';
import { mapSearchString } from '../search.utils';
import { fetchFilterMetadataApi } from '../search.service';

// Returns the names of the filter ids in the search params on page load

// Returns the names of the filter ids in the search params only on page load,
// but can be manually refetched if necessary
export const useFetchFilterMetadata = ({ onSuccess, ...options }: UseQueryOptions = {}) => {
  const { account: user } = useSession();
  const { search } = useLocation();
  const [isActive, setIsActive] = useState(true);

  const queryResponse = useQuery(
    [user?.organizationId, 'filter-metadata'],
    () => fetchFilterMetadataApi({ searchString: search }),
    {
      select: ({ statusCode, data }) => {
        const filters = mapSearchString(search);
        const excludeChildrenIds: string[] = get(filters, 'excludeChildren') || [];
        const excludeChildren: Filter[] = [];

        const filtersWithoutExcludedChildren: ActiveFilters = mapKeys(
          data.result,
          (value, key) => {
            return camelCase(key);
          },
        );

        const activeFilters = mapValues(filtersWithoutExcludedChildren, (value) => {
          if (!isEmpty(excludeChildrenIds)) {
            return map(value, (filter) => {
              const excludeChildrenIndex = excludeChildrenIds.indexOf(filter.id);
              if (excludeChildrenIndex > -1) {
                excludeChildrenIds.splice(excludeChildrenIndex, 1);
                const excludeChildrenFilter = {
                  ...filter,
                  name: `${filter.name} (unassigned)`,
                  excludeChildren: true,
                };
                excludeChildren.push(excludeChildrenFilter);
                return excludeChildrenFilter;
              }
              return filter;
            });
          }

          return value;
        });

        if (!isEmpty(excludeChildren)) {
          activeFilters.excludeChildren = excludeChildren;
        }

        return {
          statusCode,
          data: {
            result: activeFilters,
          },
        };
      },
      enabled: !!search && isActive,
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
        setIsActive(false);
      },
      ...options,
    },
  );

  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};
