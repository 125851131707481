import LeaveFormConfirmationDrawer, {
  INIT_PROPS as LeaveFormConfirmationDrawerProps,
} from 'common/components/drawer/LeaveFormConfirmationDrawer';
import MenuDrawer from 'common/components/drawer/menu-drawer.component';
import SettingsDrawer from 'common/components/drawer/settings-drawer.component';
import SubmitFormConfirmationDrawer, {
  INIT_PROPS as SubmitFormConfirmationDrawerProps,
} from 'common/components/drawer/SubmitFormConfirmationDrawer';
import ReviewDrawer, {
  INIT_PROPS as ReviewDrawerInitProps,
} from 'features/org-root/components/Review/ReviewDrawer';
import CompanyUpdateDrawer, {
  INIT_PROPS as CompanyUpdateDrawerInitProps,
} from 'features/company-updates/components/company-update-drawer.component';
import {
  CompanyUpdateConfirmationDrawer,
  INIT_PROPS as CompanyUpdateConfirmationDrawerInitProps,
} from 'features/company-updates/components/company-update-confirmation-drawer.component';
import NotificationDrawer, {
  INIT_PROPS as NotificationDrawerInitProps,
} from './Notifications/NotificationDrawer';
import PraiseDrawer, { INIT_PROPS as PraiseDrawerInitProps } from './Praise/PraiseDrawer';
import SkillsActionDrawer, {
  INIT_PROPS as SkillActionDrawerInitProps,
} from './SkillAction/SkillActionDrawer';
import FilterDrawer from 'features/explore/components/FilterDrawer';
import StaticDataItemActionConfirmationDrawer, {
  INIT_PROPS as StaticDataItemActionConfirmationDrawerProps,
} from 'common/components/drawer/StaticDataItemActionConfirmationDrawer';
import OrganizationRoleDrawer, {
  INIT_PROPS as OrganizationRoleDrawerInitProps,
} from 'features/organization-roles/components/organization-role-drawer.component';
import OrganizationEventDrawer, {
  INIT_PROPS as OrganizationEventDrawerInitProps,
} from 'features/organization-events/components/organization-event-drawer.component';
import PostDrawer, {
  INIT_PROPS as PostDrawerInitProps,
} from 'features/posts/components/posts-drawer.component';

import { PrinciplesFilterDrawer } from 'features/principles/components/principles-filter-drawer.component';
import OpportunityDrawer from 'features/opportunities/components/opportunity-drawer.component';
import ProjectDrawer from './Project/ProjectDrawer';
import { DrawerIdEnum } from '../enums/drawer-id.enum';
import DeleteConfirmationDrawer from 'common/components/drawer/DeleteConfirmationDrawer';
import { ManageWinListDrawer } from 'features/wins-expiration/components/manage-win-list-drawer.component';

export const DRAWER_FC_MAPPING = {
  [DrawerIdEnum.MENU]: MenuDrawer,
  [DrawerIdEnum.SETTINGS]: SettingsDrawer,
  [DrawerIdEnum.SUBMIT_FORM]: SubmitFormConfirmationDrawer,
  [DrawerIdEnum.LEAVE_FORM]: LeaveFormConfirmationDrawer,
  [DrawerIdEnum.DELETE_CONFIRMATION]: DeleteConfirmationDrawer,
  [DrawerIdEnum.NOTIFICATION]: NotificationDrawer,
  [DrawerIdEnum.PRAISE]: PraiseDrawer,
  [DrawerIdEnum.SKILLS_ACTION]: SkillsActionDrawer,
  [DrawerIdEnum.PROJECT]: ProjectDrawer,
  [DrawerIdEnum.OPPORTUNITY]: OpportunityDrawer,
  [DrawerIdEnum.REVIEW]: ReviewDrawer,
  [DrawerIdEnum.COMPANY_UPDATE]: CompanyUpdateDrawer,
  [DrawerIdEnum.COMPANY_UPDATE_CONFIRMATION]: CompanyUpdateConfirmationDrawer,
  [DrawerIdEnum.FILTER]: FilterDrawer,
  [DrawerIdEnum.STATIC_DATA_ITEM_ACTION_CONFIRMATION]: StaticDataItemActionConfirmationDrawer,
  [DrawerIdEnum.ORGANIZATION_ROLE]: OrganizationRoleDrawer,
  [DrawerIdEnum.ORGANIZATION_EVENT]: OrganizationEventDrawer,
  [DrawerIdEnum.PRINCIPLES_FILTER]: PrinciplesFilterDrawer,
  [DrawerIdEnum.POST]: PostDrawer,
  [DrawerIdEnum.MANAGE_WIN_LIST]: ManageWinListDrawer,
};

export type DrawerType = {
  drawerId: DrawerIdEnum;
  drawerProps: any;
  open: boolean;
};

export const DRAWERS: DrawerType[] = [
  {
    open: false,
    drawerId: DrawerIdEnum.MENU,
    drawerProps: {},
  },
  {
    open: false,
    drawerId: DrawerIdEnum.SETTINGS,
    drawerProps: {},
  },
  {
    open: false,
    drawerId: DrawerIdEnum.SUBMIT_FORM,
    drawerProps: SubmitFormConfirmationDrawerProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.LEAVE_FORM,
    drawerProps: LeaveFormConfirmationDrawerProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.DELETE_CONFIRMATION,
    drawerProps: DeleteConfirmationDrawer,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.NOTIFICATION,
    drawerProps: NotificationDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.PRAISE,
    drawerProps: PraiseDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.SKILLS_ACTION,
    drawerProps: SkillActionDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.PROJECT,
    drawerProps: {},
  },
  {
    open: false,
    drawerId: DrawerIdEnum.OPPORTUNITY,
    drawerProps: {},
  },
  {
    open: false,
    drawerId: DrawerIdEnum.REVIEW,
    drawerProps: ReviewDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.COMPANY_UPDATE,
    drawerProps: CompanyUpdateDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.COMPANY_UPDATE_CONFIRMATION,
    drawerProps: CompanyUpdateConfirmationDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.FILTER,
    drawerProps: {},
  },
  {
    open: false,
    drawerId: DrawerIdEnum.STATIC_DATA_ITEM_ACTION_CONFIRMATION,
    drawerProps: StaticDataItemActionConfirmationDrawerProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.ORGANIZATION_ROLE,
    drawerProps: OrganizationRoleDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.ORGANIZATION_EVENT,
    drawerProps: OrganizationEventDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.PRINCIPLES_FILTER,
    drawerProps: {},
  },
  {
    open: false,
    drawerId: DrawerIdEnum.POST,
    drawerProps: PostDrawerInitProps,
  },
  {
    open: false,
    drawerId: DrawerIdEnum.MANAGE_WIN_LIST,
    drawerProps: {},
  },
];
