import { Theme } from '@mui/material/styles';

export const getCommonEditorContentStyles = ({ palette }: Theme) => {
  return {
    '& .toastui-editor-contents strong': {
      fontWeight: 600,
    },
    '& .toastui-editor-contents a': {
      color: palette.LinkText.Active,
    },
  };
};
