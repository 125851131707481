import { RectangularSkeleton } from 'common/components/loaders';
import { Stack } from 'common/components/material';

const ExploreSelectorLoader = () => {
  return (
    <Stack spacing={1}>
      <RectangularSkeleton height="1.5rem" />
      <RectangularSkeleton height="1.5rem" />
      <RectangularSkeleton height="1.5rem" />
    </Stack>
  );
};

export default ExploreSelectorLoader;
