import { toUpper } from 'lodash';
import { useEvents, useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { OrganizationalTagPagesTypeEnum } from 'features/static-data';
import { getPraiseLeaderboardApi } from '../praise.service';
import { GetPraiseLeaderboardParams } from '../types';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { EVENTS_TO_REFETCH_PRAISES } from '../praise.utils';

export const useGetPraiseLeaderboard = (
  params: GetPraiseLeaderboardParams,
  options: UseQueryOptions = {},
) => {
  const { account } = useSession();
  const organizationId = account.organizationId || '';
  const { type, id, duration } = params;
  const staticDataType = type && (toUpper(type) as OrganizationalTagPagesTypeEnum);

  const isValidStaticDataType =
    !!staticDataType &&
    Object.values(OrganizationalTagPagesTypeEnum).includes(staticDataType);

  const queryResponse = useQuery(
    [organizationId, staticDataType, id, 'praise-leaderboard'],
    () =>
      isValidStaticDataType &&
      getPraiseLeaderboardApi({ type: staticDataType, id, duration }),
    {
      enabled: !!organizationId && isValidStaticDataType,
      placeholderData: { data: { topGivers: [], topReceivers: [] } },
      showErrorSnackbar: true,
      ...options,
      select: ({ statusCode, data }) => {
        return {
          statusCode,
          data: {
            topGivers: data?.topGivers,
            topReceivers: data?.topReceivers,
          },
        };
      },
    },
  );

  useEvents(EVENTS_TO_REFETCH_PRAISES as Event[], () => queryResponse.refetch());

  return {
    ...queryResponse,
    data: queryResponse?.data?.data,
  };
};
