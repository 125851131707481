import React from 'react';
import Skeleton from '../material/Skeleton';
import { SxProps } from '@mui/material';

const RectangularSkeleton: React.FC<{ height: string; additionalStyle?: SxProps }> = ({
  height,
  additionalStyle = {},
}) => {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      sx={{ borderRadius: '0.75rem', ...additionalStyle }}
      height={height}
    />
  );
};

export default RectangularSkeleton;
