import { useTreeItem as MUIUseTreeItem } from '@mui/x-tree-view';

const useCXTreeItem = (nodeId: string) => {
  const { disabled, expanded, selected, focused, handleExpansion, handleSelection } =
    MUIUseTreeItem(nodeId);

  return { disabled, expanded, selected, focused, handleExpansion, handleSelection };
};

export default useCXTreeItem;
