import { ExperimentalFeatureEnum } from 'features/feature-access/experimental-feature.enum';
import { useGetAccount } from 'features/accounts';
import { useGetProfile } from 'features/profiles/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import useOrganizationStorage from 'features/accounts/hooks/useOrganizationStorage';

const ignoreNotAcceptedError = (statusCode: number) => {
  return statusCode !== 406;
};

let lastProfileId: string | null = null;
const pushProfileIdIntoDataLayer = (profileId: string) => {
  if (profileId !== lastProfileId) {
    (window as any)?.dataLayer?.push({ userProfileId: profileId });
    lastProfileId = profileId;
  }
};

/**
 * This hooks uses the profile API service to validate
 * the user's session and get their active org sub URL.
 */
const useSession = () => {
  const account = useGetAccount();
  const { organizationOnSession, setOrganizationOnSession } = useOrganizationStorage();

  const profile = useGetProfile(
    {},
    {
      enabled: true,
      showErrorSnackbar: true,
      shouldShowErrorSnackbar: ignoreNotAcceptedError,
      onSuccess: (data: any) => {
        /*
         * Every time the we fetch the logged in user's profile, push it into GA' dataLayer
         * so information about the logged in user can be attached to any custom
         * event we might end up generating
         */
        if (data?.id) {
          pushProfileIdIntoDataLayer(data?.id);
        }
      },
    },
  );

  useEffect(() => {
    if (
      account.isSuccess &&
      !account.isLoading &&
      !account.isFetching &&
      !profile.isSuccess &&
      !profile.isLoading
    ) {
      profile.refetch();
    }
  }, [
    account.isSuccess,
    account.isLoading,
    account.isFetching,
    profile,
    organizationOnSession.organizationId,
  ]);

  const currentOrganization = useCallback(() => {
    if (!!profile.data?.organizations?.length) {
      return profile.data?.organizations?.find(
        (org) => org.id === profile.data?.organizationProfile?.data?.companyId,
      );
    }
    return;
  }, [profile.data]);

  const hasAccessToExperimentalFeature = useCallback(
    (feature: ExperimentalFeatureEnum, ...otherFeatures: ExperimentalFeatureEnum[]) => {
      const features = [feature, ...otherFeatures];
      const entitledFeatures = new Set(
        profile.data?.organizationProfile?.data?.entitledFeatures || [],
      );
      return features.some((feature) => entitledFeatures.has(feature));
    },
    [profile.data],
  );

  return useMemo(
    () => ({
      isInitialized: account.isSuccess || account.isError,
      isInitialLoading:
        account.isLoading || profile.isLoading || (account.isSuccess && !profile.isSuccess),
      isAccountLoading: account.isLoading,
      isProfileLoading: profile.isLoading,
      hasSession: !!(
        account.isSuccess &&
        !account.isFetching &&
        !account.isLoading &&
        account.data
      ),
      account: account.data,
      profile: profile.data,
      hasProfile: !!(account.isSuccess && account.data?.profileId),
      hasOrganization: !!(
        account.isSuccess &&
        !account.isFetching &&
        !account.isLoading &&
        organizationOnSession.organizationId
      ),
      organizationProfile: profile.data?.organizationProfile?.data,
      currentOrganization,
      isProfileFetching: profile.isFetching,
      profileError: profile.error,
      isAccountFetching: account.isFetching,
      refetchProfile: profile.refetch,
      refetchAccount: account.refetch,
      organizationIdOnSession: organizationOnSession.organizationId,
      subUrlOnSession: organizationOnSession.subUrl,
      setOrganizationOnSession: setOrganizationOnSession,
      hasAccessToExperimentalFeature: hasAccessToExperimentalFeature,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      account.status,
      profile.status,
      account.data,
      profile.data,
      organizationOnSession.organizationId,
    ],
  );
};

export default useSession;
