import React from 'react';
import { MUIRef } from 'common/types/MUIRef.type';
import { ExplorerLevelConfig, HierarchicalExplorerItemAction } from 'features/data-explorer';
import {
  ExplorerDataTypeEnum,
  MenuItemArrowDirectionEnum,
} from 'features/data-explorer/data-explorer.enum';
import { ExplorerDataMapItem } from 'features/data-explorer/types/explorer-data.type';
import FilterListItem from 'features/explore/components/FilterListItem';
import { ActiveFilterMap } from 'features/search';
import { CXTreeItemContentProps } from 'libs/mui/tree-view/types/cx-tree.types';
import useCXTreeItem from 'libs/mui/tree-view/hooks/useCXTreeItem';

export const ExploreHierarchicalTreeItem = React.forwardRef(
  (
    props: CXTreeItemContentProps<{
      mapping: Record<string, any>;
      onNodeClicked: (action: HierarchicalExplorerItemAction) => void;
      activeFilterMap?: ActiveFilterMap;
      type: ExplorerDataTypeEnum;
    }>,
    ref: MUIRef,
  ) => {
    const { nodeId, node, customTreeItemProps } = props;
    const { mapping, activeFilterMap, type, onNodeClicked } = customTreeItemProps as Record<
      string,
      any
    >;
    const { handleExpansion, expanded } = useCXTreeItem(nodeId);

    const onItemClicked = (operation: HierarchicalExplorerItemAction) => {
      const { event } = operation;
      event && handleExpansion(event as React.MouseEvent<HTMLDivElement>);
      onNodeClicked(operation);
    };

    return (
      <FilterListItem
        data={node as ExplorerDataMapItem}
        onItemAction={onItemClicked}
        activeFilterMap={activeFilterMap}
        mapping={mapping}
        dataType={type}
        labelKey="name"
        config={{} as ExplorerLevelConfig}
        showArrow={!!node?.children?.length}
        size="small"
        arrowDirection={
          expanded ? MenuItemArrowDirectionEnum.DOWN : MenuItemArrowDirectionEnum.RIGHT
        }
      />
    );
  },
);
