import React from 'react';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import MenuItemButton from './MenuItemButton';

const ManageOrganizationStaticDataMenuItem: React.FC = () => {
  const { goTo } = useRouter();

  const handleClick = () => {
    goTo(
      AppRoutesEnum.ADMIN_MANAGE_STATIC_DATA_ROOT,
      {},
      { state: { resetHistoryIndex: true } },
    );
  };

  return (
    <MenuItemButton onClick={handleClick} label="Manage Static Data">
      <TextSnippetOutlinedIcon />
    </MenuItemButton>
  );
};

export default ManageOrganizationStaticDataMenuItem;
