import { useQuery } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getPraiseHeadlineOptionsApi } from '../praise.service';

export const useGetHeadlineOptions = (options: UseQueryOptions = {}) => {
  const queryResponse = useQuery(['headline-options'], () => getPraiseHeadlineOptionsApi(), {
    /*
     * `initialData` is persisted to the cache and is considered as reliable as data fetched from the backend.
     * It is stored in the cache as soon as the first observer mounts and remains unchanged regardless of additional observers.
     * `placeholderData`, however, is not persisted to the cache and is used to provide temporary data while the real data is being fetched.
     */
    placeholderData: { data: { headlineOptions: [] } },
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse?.data?.data?.headlineOptions,
  };
};
