import React from 'react';
import { SxProps, useTheme } from '@mui/material';

import { Typography } from 'common/components/material';
import { ListItemText } from '../material/ListItemButton';
import { StyledListItem, StyledListItemIcon } from './styles/menu-item-button.styles';

type MenuItemButtonProps = {
  onClick: () => void;
  children?: React.ReactElement;
  label: string;
  listItemSx?: SxProps;
  listItemIconSx?: SxProps;
  listItemTextSx?: SxProps;
};

const MenuItemButton: React.FC<MenuItemButtonProps> = ({
  onClick,
  children,
  label,
  listItemSx,
  listItemIconSx,
  listItemTextSx,
}) => {
  const { palette } = useTheme();

  return (
    <StyledListItem onClick={onClick} sx={{ ...listItemSx }}>
      {children && (
        <StyledListItemIcon
          sx={{
            color: palette.Icons.Tertiary.IconFillDefault,
            minWidth: '2rem',
            ...listItemIconSx,
          }}
        >
          {children}
        </StyledListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography
            variant="body3"
            fontWeight={500}
            sx={{ color: palette.LinkText.Default, ...listItemTextSx }}
          >
            {label}
          </Typography>
        }
      />
    </StyledListItem>
  );
};

export default MenuItemButton;
