import React from 'react';

import { Button, Stack, Typography } from 'common/components/material';
import { ConfirmationIconType } from 'common/enum/ConfirmationIconType.enum';
import BottomConfirmationDrawer from './BottomConfirmationDrawer';

type ActionConfirmationDrawerProps = {
  confirmButtonHandler: () => void;
  toggleDrawer: (e: React.MouseEvent | React.KeyboardEvent) => void;
  headerTitle: string;
  subTitle: string;
  confirmButtonText?: string;
  disabled?: boolean;
};

const DeleteConfirmationDrawer: React.FC<ActionConfirmationDrawerProps> = ({
  toggleDrawer,
  confirmButtonHandler,
  headerTitle,
  subTitle,
  confirmButtonText,
  disabled,
}) => {
  const title = (
    <Stack textAlign="center" spacing={2}>
      <Typography variant="h3">{headerTitle}</Typography>
      <Typography variant="body2">{subTitle}</Typography>
    </Stack>
  );

  const cancelButton = (
    <Button btntype="tertiary" size="large" onClick={toggleDrawer}>
      Cancel
    </Button>
  );

  const confirmButton = (
    <Button
      btntype="primary"
      size="large"
      color="error"
      onClick={confirmButtonHandler}
      disabled={disabled}
    >
      {confirmButtonText || 'Delete'}
    </Button>
  );

  return (
    <BottomConfirmationDrawer
      title={title}
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      iconType={ConfirmationIconType.WARN}
    />
  );
};

export default DeleteConfirmationDrawer;
