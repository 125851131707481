import React from 'react';

import { FormAutocomplete } from 'common/components/input';
import { AutocompleteProps } from 'common/types/Form.type';
import { useGetOrganizationEventTypes } from '../hooks';

type OrganizationEventTypeSelectorProps = Omit<AutocompleteProps, 'options'>;

const OrganizationEventTypeSelector: React.FC<OrganizationEventTypeSelectorProps> = ({
  ...props
}) => {
  const { data } = useGetOrganizationEventTypes();

  return (
    <FormAutocomplete
      {...props}
      options={data || []}
      getOptionLabel={(option) => option.subType}
      groupBy={(option) => option.type}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      showArrowIcon={true}
    />
  );
};

export default OrganizationEventTypeSelector;
