import React from 'react';
import { styled } from '@mui/material/styles';

import { ToggleButtonGroup } from 'common/components/material';
import ToggleButton from 'common/components/material/ToggleButton';
import { InteractiveEndorsedSkillRatingProps, getAllRatings } from 'features/skills';

const RatingButton = styled(ToggleButton)(({ theme }) => ({
  ...theme.typography.body4,
  fontWeight: 500,
  color: theme.palette.Text.Headline,
  textTransform: 'none',
  '&.MuiToggleButtonGroup-grouped': {
    borderRadius: '8px',
    paddingTop: '0.5625rem',
    paddingBottom: '0.5625rem',
  },
  '&.MuiToggleButtonGroup-grouped.Mui-selected': {
    backgroundColor: theme.palette.Buttons.Primary.BgPressed,
    color: theme.palette.Buttons.Primary.LabelDefault,
  },
  '&:hover': {
    backgroundColor: theme.palette.Buttons.Tertiary.BgHover,
    color: theme.palette.LinkText.Active,
  },
}));

const InteractiveEndorsedSkillRating: React.FC<InteractiveEndorsedSkillRatingProps> = ({
  score,
  action,
}) => {
  const ratings = getAllRatings();
  return (
    <ToggleButtonGroup value={score} fullWidth={true} exclusive onChange={action}>
      {ratings.map((rating) => (
        <RatingButton key={rating.score} value={rating.score}>
          {rating.label}
        </RatingButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default InteractiveEndorsedSkillRating;
