import { useTheme } from '@mui/material';
import { useView } from 'common/hooks';
import React from 'react';

type SvgIconType = {
  DisabledIcon: React.FC<any>;
  ActiveIcon: React.FC<any>;
  isActive?: boolean;
  color?: string;
  hoverColor?: string;
};

const SvgIconState: React.FC<SvgIconType> = ({
  DisabledIcon,
  ActiveIcon,
  isActive = false,
  color,
  hoverColor,
}) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();

  const size = isMobileView ? 20 : 24;

  color = color || palette.Icons.Tertiary.IconFillPressed;
  hoverColor = hoverColor || palette.Icons.Tertiary.IconFillHover;
  return (
    <>
      {!isActive && (
        <DisabledIcon
          sx={{
            width: size,
            height: size,
            color: 'inherit',
            '&:hover': {
              color: hoverColor,
            },
            '&:active': {
              color,
            },
          }}
        />
      )}
      {isActive && (
        <ActiveIcon
          sx={{
            width: size,
            height: size,
            color,
          }}
        />
      )}
    </>
  );
};
export default SvgIconState;
