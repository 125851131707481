import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import BottomConfirmationDrawer from 'common/components/drawer/BottomConfirmationDrawer';
import { Button, Link, Skeleton, Stack, Typography } from 'common/components/material';
import { ConfirmationIconType } from 'common/enum/ConfirmationIconType.enum';
import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { useGetFollowerCount } from 'features/followings/hooks/useGetFollowerCount';
import { Tag } from 'features/tags/types';

type CompanyUpdateConfirmationDrawerProps = {
  confirmButtonHandler: () => void;
  toggleDrawer: (e: React.MouseEvent | React.KeyboardEvent) => void;
  tags: Tag[];
  sendToAllEmployees: boolean;
};

export const INIT_PROPS: CompanyUpdateConfirmationDrawerProps = {
  confirmButtonHandler: () => {},
  toggleDrawer: () => {},
  tags: [],
  sendToAllEmployees: false,
};

export const CompanyUpdateConfirmationDrawer: React.FC<
  CompanyUpdateConfirmationDrawerProps
> = ({ toggleDrawer, confirmButtonHandler, tags, sendToAllEmployees }) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const tagIds = tags.map((tag) => tag.id);
  const {
    data: followerCount,
    isSuccess,
    isLoading,
  } = useGetFollowerCount(tagIds, {
    enabled: !!tagIds.length,
  });
  const { getOrgRoute } = useRouter();
  const theme = useTheme();
  const exploreRoute = getOrgRoute(
    AppRoutesEnum.EXPLORE,
    {},
    tagIds.length && !sendToAllEmployees ? { following: tagIds.join(',') } : {},
  );
  const drawerTitle = (
    <Stack textAlign="center" spacing={2}>
      <Typography variant="h3">
        Email{' '}
        {isLoading && tagIds.length ? (
          <Typography variant="inherit" width="3rem" display="inline-block">
            <Skeleton />
          </Typography>
        ) : isSuccess && tagIds.length && !sendToAllEmployees ? (
          followerCount
        ) : (
          'all'
        )}{' '}
        employees?
      </Typography>
      <Typography variant="body2">
        Before sending, make sure you are adhering to all company communication policies
      </Typography>
      <Typography variant="body2">
        <Link
          href={exploreRoute}
          target="_blank"
          color={theme.palette.LinkText.Active}
          variant="inherit"
        >
          Click here
        </Link>{' '}
        to view the recipients
      </Typography>
    </Stack>
  );

  const cancelButton = (
    <Button btntype="label" onClick={toggleDrawer}>
      Don't Send
    </Button>
  );

  const onPrimaryActionClick = () => {
    setDisabled(true);
    confirmButtonHandler();
  };

  const confirmButton = (
    <Button btntype="primary" onClick={onPrimaryActionClick} disabled={disabled}>
      Yes, Confirm
    </Button>
  );

  return (
    <BottomConfirmationDrawer
      title={drawerTitle}
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      iconType={ConfirmationIconType.SAVE}
    />
  );
};
