import { useQuery, useSession } from 'common/hooks';
import { getOrganizationPrivacyPolicyApi } from '../organization-privacy-policy.service';
import { UseQueryOptions } from 'common/types/Api.type';

export const useOrganizationPrivacyPolicy = (options: UseQueryOptions = {}) => {
  const { account } = useSession();
  const organizationId = account?.organizationId || '';
  const queryKey = [organizationId, 'organization-privacy-policy'];

  const userEnabled = options.hasOwnProperty('enabled') ? options.enabled : true;
  const queryResponse = useQuery(queryKey, () => getOrganizationPrivacyPolicyApi(), {
    enabled: userEnabled && !!organizationId,
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse?.data?.data.privacyPolicy : {},
  };
};
