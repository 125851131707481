import React from 'react';
import { FormProvider } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { useForm } from 'common/hooks';
import { FormTextField } from 'common/components/input';
import { InputAdornment } from 'common/components/material';
import { InlineIconButton } from 'features/org-root/org-root.styles';
import { useCreateProject } from '../hooks';
import { InlineProject, ProjectFormValue } from '../types';

const StyledForm = styled('form')({
  width: '100%',
});

type InlineProjectFormProps = {
  data: ProjectFormValue;
  skipProjectCreation?: boolean;
  onClose: () => void;
  onSubmit?: (title: string) => void;
  onOpen: () => void;
};

const InlineProjectForm: React.FC<InlineProjectFormProps> = ({
  data,
  skipProjectCreation,
  onClose,
  onSubmit,
  onOpen: reopenNextWinListInput,
}) => {
  const { mutate: createProject, isLoading: isCreatingProject } = useCreateProject();
  const { typography } = useTheme();

  const form = useForm<InlineProject>({});

  const handleSubmit = ({ title }: InlineProject) => {
    if (isCreatingProject || !title) {
      return;
    }

    onSubmit && onSubmit(title);
    if (skipProjectCreation) {
      onClose();
      reopenNextWinListInput();
      return;
    }

    const dataToSubmit = { ...data, title };
    createProject(dataToSubmit, { onSuccess: onClose });
  };

  return (
    <FormProvider {...form}>
      <StyledForm noValidate onSubmit={form.handleSubmit(handleSubmit)}>
        <FormTextField
          autoFocus
          label="Add WIN"
          name="title"
          fullWidth
          disabled={isCreatingProject}
          sx={{
            '.MuiOutlinedInput-root input.MuiInputBase-input': {
              fontSize: typography.body3.fontSize,
              paddingTop: '0.625rem',
              paddingBottom: '0.625rem',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InlineIconButton sx={{ marginRight: '0.25rem' }} type="submit">
                  <CheckIcon />
                </InlineIconButton>
                <InlineIconButton onClick={onClose}>
                  <CloseIcon />
                </InlineIconButton>
              </InputAdornment>
            ),
          }}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default InlineProjectForm;
