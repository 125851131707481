import React from 'react';
import * as _ from 'lodash';
import { SEARCH_SKELETON_LENGTH } from '../search.constants';
import RectangularSkeleton from 'common/components/loaders/RectangularSkeleton';

const SearchSkeletonView: React.FC = () => {
  return (
    <>
      {_.range(SEARCH_SKELETON_LENGTH).map((key) => (
        <RectangularSkeleton key={key} height="13.75rem" />
      ))}
    </>
  );
};

export default SearchSkeletonView;
