import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Box, Grid, Typography } from 'common/components/material';
import ButtonBase from 'common/components/material/ButtonBase';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import { useView } from 'common/hooks';
import { ProjectDrawerTabEnum } from 'features/projects';

type DrawerTabProps = {
  value: ProjectDrawerTabEnum;
  label: string;
  subLabel: string;
  defaultIcon: React.ReactNode;
  activeIcon: React.ReactNode;
  isActive: boolean;
};

export type FromDrawerTabConfig = Omit<DrawerTabProps, 'isActive' | 'onClick'>;

type TabButtonProps = {
  isActive: boolean;
};

const TabButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => {
    return prop !== 'isActive';
  },
})<TabButtonProps>(({ theme, isActive }) => {
  const activeStyles = isActive
    ? {
        borderBottom: `2px solid ${theme.palette.Tabs.Primary.StrokeActive}`,
        boxShadow: AppBoxShadows.shadow2,
        borderRadius: '8px',
        backgroundColor: theme.palette.Tabs.Primary.BgDefault,
      }
    : {};
  return {
    width: '100%',
    padding: '.5rem',
    borderRadius: '.25rem',
    '& .active-icon': {
      display: isActive ? 'block' : 'none',
    },
    '& .default-icon': {
      display: isActive ? 'none' : 'block',
    },
    '&:hover': {
      backgroundColor: theme.palette.Tabs.Primary.BgDefault,
      '& .active-icon': {
        display: 'block',
      },
      '& .default-icon': {
        display: 'none',
      },
    },
    ...activeStyles,
  };
});

const DrawerTab: React.FC<DrawerTabProps> = ({
  value,
  label,
  subLabel,
  defaultIcon,
  activeIcon,
  isActive,
}) => {
  const { isMobileView } = useView();
  const { palette } = useTheme();
  return (
    <TabButton isActive={isActive} className="drawer-tab" data-tab={value}>
      <Grid container spacing={2}>
        {!isMobileView && (
          <Grid item xs={3}>
            <Box paddingY={0.5} className="active-icon">
              {activeIcon}
            </Box>
            <Box paddingY={0.5} className="default-icon">
              {defaultIcon}
            </Box>
          </Grid>
        )}
        <Grid item xs={9} textAlign="left">
          <Typography
            color={palette.Text.Headline}
            variant="body1"
            fontWeight={700}
            fontSize={{ xs: 14, md: 18 }}
          >
            {label}
          </Typography>
          <Typography
            variant={isMobileView ? 'caption2' : 'body3'}
            color={palette.Text.SubHeadline}
          >
            {subLabel}
          </Typography>
        </Grid>
      </Grid>
    </TabButton>
  );
};

export default DrawerTab;
