import { isNil } from 'lodash';

import {
  DEFAULT_FEATURE_ACCESS_PERMISSION,
  featureAccessStrategies,
} from '../feature-access.constants';
import { useFeatureAccessContext } from '../feature-accesses.provider';
import { FeaturesEnum } from '../features.enum';
import { FeatureAccessPermissionType } from '../types/feature-access.type';
import { SystemFeatureAccessEnum } from '../enums/feature-access.enum';
import { useGetProfile } from 'features/profiles/hooks/useGetProfile';

/**
 * This hook is responsible for handling following types of permissions (System Features and UI features):
 * - System Features : COMPANY_UPDATES,PRAISE etc
 * - UI features (composite of System Features) : SEARCH_BAR, USER_SETTINGS etc
 */
export const useFeatureAccess = (
  feature: FeaturesEnum,
  args?: any,
): FeatureAccessPermissionType => {
  const { data: loggedInUser } = useGetProfile({}, { enabled: false });
  const permissions = { ...DEFAULT_FEATURE_ACCESS_PERMISSION };
  const { getAccessPermission } = useFeatureAccessContext();

  if (isNil(getAccessPermission)) {
    return permissions;
  }

  /**
   * Feature Access Strategies are used to build composite UI feature related permissions.
   */
  const featureAccessStrategy = featureAccessStrategies[feature];
  if (!isNil(featureAccessStrategy)) {
    return featureAccessStrategy(getAccessPermission, loggedInUser);
  }

  const featurePermission = getAccessPermission(feature);

  permissions.canRead = featurePermission[SystemFeatureAccessEnum.READ];
  permissions.canWrite = featurePermission[SystemFeatureAccessEnum.WRITE];

  if (feature === FeaturesEnum.SOME_STATIC_DATA_PAGES && args?.pageId) {
    permissions.canWrite =
      permissions.canWrite && loggedInUser.adminPageIds?.includes(args?.pageId);
  }

  return permissions;
};
