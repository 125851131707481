import React from 'react';

import { Stack } from 'common/components/material';
import { FiltersMenuProps } from '../types/filter-menu-props.type';
import FilterMenuListItem from './filter-menu-item.component';

const FilterMenu: React.FC<FiltersMenuProps> = ({ activeFilters, onSelect, items }) => {
  return (
    <Stack p={1} spacing={1}>
      {items.map((menuItem) => {
        return (
          <FilterMenuListItem
            key={menuItem.key}
            menuItem={menuItem}
            activeFilters={activeFilters}
            onSelect={onSelect}
          />
        );
      })}
    </Stack>
  );
};

export default FilterMenu;
