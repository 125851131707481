import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';

import { Button, Stack, Typography } from 'common/components/material';
import EmptyOrganizationEventView from './empty-organization-event-view.component';
import { RectangularSkeleton } from 'common/components/loaders';
import { VerticalScrollableContainer } from 'common/components/container';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { useDrawer } from 'common/hooks';
import StickyHeaderContainer from 'common/components/container/StickyHeaderContainer';
import OrganizationEventPanelListView from './organization-event-panel-list-view.component';
import useGetWindowDimensions from 'common/hooks/useGetWindowDimensions';
import { DEFAULT_APP_BAR_HEIGHT } from 'common/components/header/ApplicationBar';
import OrganizationEventPanelCard from './organization-events-panel-card.component';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

const OrganizationEventsSideBarPanel: React.FC<{
  organizationEventData: any;
  isLoading: boolean;
}> = ({ organizationEventData, isLoading }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const { palette } = useTheme();
  const { openDrawer } = useDrawer();
  const { height: viewPortHeight } = useGetWindowDimensions();

  const { canWrite: canEditAllEvents } = useFeatureAccess(
    FeaturesEnum.ALL_ORGANIZATION_EVENTS,
  );

  const urlParams = params['*'] ? params['*'].split('/') : [];
  const selectedDate = searchParams.get('selectedDate');
  const month = selectedDate ? moment(selectedDate).format('MMM') : urlParams[2];

  const appBarHeightInPixel = parseInt(DEFAULT_APP_BAR_HEIGHT) * 16;

  const height = Math.min(viewPortHeight - appBarHeightInPixel - 48, 872);

  const openOrganizationEventDrawer = () => {
    openDrawer(DrawerIdEnum.ORGANIZATION_EVENT, {
      organizationEvent: null,
      selectedTags: [],
    });
  };

  const OrganizationEventsSideBarPanelHeader = (
    <Stack
      justifyContent="space-between"
      alignItems="flex-start"
      paddingLeft={1}
      paddingTop={1}
    >
      <Typography
        fontWeight="700"
        color={palette.Text.Headline}
        variant="body2"
        textAlign="center"
      >
        All Events
      </Typography>

      <Typography
        color={palette.Text.SubHeadline}
        lineHeight="18px"
        variant="caption"
        textAlign="center"
      >
        {month}
      </Typography>
    </Stack>
  );

  const eventCreateButton = (
    <Button
      btntype="tertiary"
      onClick={openOrganizationEventDrawer}
      sx={{ fontSize: '14px', fontWeight: '500' }}
      startIcon={<AddIcon />}
    >
      Add New
    </Button>
  );

  if (isLoading) {
    return <RectangularSkeleton height={height ? `${height}rem` : '26rem'} />;
  }

  return (
    <StickyHeaderContainer topOffset={1}>
      <Stack
        flexDirection="column"
        sx={{
          background: palette.Container.Bg,
          borderRadius: '8px',
          border: `1px solid ${palette.Container.Stroke}`,
        }}
        height={height}
      >
        {OrganizationEventsSideBarPanelHeader}
        <Stack height="100%">
          {_.isEmpty(organizationEventData) ? (
            <Stack px={2} alignItems="center" justifyContent="center" height="100%" mb={10}>
              <EmptyOrganizationEventView
                createButton={canEditAllEvents ? eventCreateButton : <></>}
              />
            </Stack>
          ) : (
            <VerticalScrollableContainer disableGutters maxHeight={`${height - 55}px`}>
              {Object.keys(organizationEventData)
                .sort()
                .map((currentDate) => {
                  return (
                    <OrganizationEventPanelListView
                      key={currentDate}
                      date={currentDate}
                      events={organizationEventData[currentDate].events}
                      OrganizationEventPanelCardComponent={OrganizationEventPanelCard}
                    />
                  );
                })}
            </VerticalScrollableContainer>
          )}
        </Stack>
      </Stack>
    </StickyHeaderContainer>
  );
};

export default OrganizationEventsSideBarPanel;
