import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Button from 'common/components/material/Button';
import { Typography } from 'common/components/material';
import { useParamsProfileId } from 'common/hooks';

import AddRemoveButton from 'features/profiles/components/AddRemoveButton';
import { useDeleteEndorsedSkill, useUpdateEndorsedSkill } from 'features/profiles';
import { EndorseButtonProps } from 'features/skills';

const EndorseButton: React.FC<EndorseButtonProps> = ({
  endorsedByMe,
  endorseeId,
  skillId,
  ...props
}) => {
  const { isLoggedInUser } = useParamsProfileId();

  const { submit: addEndorsement, isLoading: updatingEndorsement } = useUpdateEndorsedSkill(
    endorseeId,
    skillId,
  );
  const { submit: deleteEndorsement, isLoading: deletingEndorsement } =
    useDeleteEndorsedSkill(endorseeId, skillId);

  const EndorseIcon = endorsedByMe ? (
    <CheckCircleRoundedIcon />
  ) : (
    <AddCircleIcon
      color={updatingEndorsement || deletingEndorsement ? 'disabled' : 'primary'}
    />
  );

  const handleEndorseSkill = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (endorsedByMe) {
      deleteEndorsement();
    } else {
      addEndorsement({ rating: 0 });
    }
  };

  if (isLoggedInUser)
    return (
      <AddRemoveButton
        disabled={updatingEndorsement || deletingEndorsement}
        isAddOperation={!endorsedByMe}
        onClick={handleEndorseSkill}
      />
    );

  return (
    <Button
      startIcon={EndorseIcon}
      disabled={updatingEndorsement || deletingEndorsement}
      onClick={handleEndorseSkill}
      size="small"
      btntype={endorsedByMe ? 'primary' : 'label'}
      {...props}
    >
      <Typography variant="body3" fontWeight={700}>
        {endorsedByMe ? 'Endorsed' : 'Endorse'}
      </Typography>
    </Button>
  );
};

export default EndorseButton;
