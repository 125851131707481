import { styled } from '@mui/material/styles';

type EmojiIconProps = {
  dimension: string;
};

const StyledEmojiIcon = styled('img')(() => ({
  position: 'relative',
  height: '1.3rem',
  right: '0.5rem',
}));

const EmojiIcon: React.FC<EmojiIconProps> = ({ dimension }) => {
  const dimensionIcons = {
    Cognitive: '/images/trait_brain.png',
    Interpersonal: '/images/trait_handshake.png',
    Motivational: '/images/trait_target.png',
  };

  const iconUrl = dimensionIcons[dimension as keyof typeof dimensionIcons];
  return <StyledEmojiIcon src={iconUrl} alt={dimension} />;
};

export default EmojiIcon;
