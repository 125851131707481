import { TimeZone } from '@vvo/tzdb';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { times } from 'lodash';

/**
 * when multiple timezones share the same offset, there will be no timezone abbreviation (abbr).
 * it will give us the timezone offset instead of the abbr name.
 * so, when there's no abbr name, we would like to use the timezone fullname.
 */
export const zoneAbbr = (timezone: string) => {
  const abbr = moment.tz(timezone).zoneAbbr();
  const timezoneOffsetPattern = /(\+|-)?\d{1,2}.*/;
  return timezoneOffsetPattern.test(abbr) ? timezone : abbr;
};

export const getTimeStampString = (timestamp: string, timestampFormat = 'MMM DD YYYY') => {
  const differenceInMin = moment().diff(moment(timestamp), 'minutes');
  if (differenceInMin <= 59) {
    return `${moment.duration(differenceInMin, 'minutes').humanize()} ago`;
  }

  const differenceInHours = moment().diff(moment(timestamp), 'hours');
  if (differenceInHours <= 23) {
    return `${moment.duration(differenceInHours, 'hours').humanize()} ago`;
  }
  return moment(timestamp).format(timestampFormat);
};

export const formatDate = (date: Date | string, dateFormat = 'DD/MM/YYYY') => {
  return moment.utc(date).format(dateFormat);
};

export const convertDateTimeToUTC = (timezone: string, date: string, time?: string) => {
  if (!date || !time) {
    return {
      date,
      time,
    };
  }

  const dateString = `${date} ${time}`;
  const utcDate = momentTz.tz(dateString, timezone).utc();
  return { date: utcDate.format('YYYY-MM-DD'), time: utcDate.format('HH:mm') };
};

export const convertToDateString = (date?: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const convertToSpecificTimezone = (
  date?: string,
  time?: string,
  timezone?: TimeZone,
) => {
  if (!date || !time || !timezone) {
    return {
      date,
      time,
    };
  }

  const dateString = `${date} ${time}`;

  // Convert the UTC time to specific timezone
  const dateInTimezone = momentTz.utc(dateString).tz(timezone.name);

  return {
    date: dateInTimezone.format('YYYY-MM-DD'),
    time: dateInTimezone.format('HH:mm'),
  };
};

export const convertBetweenTimezones = (
  originalTimezone: string,
  targetTimezone: string,
  date: string,
  time: string,
) => {
  const formattedStartDate = formatDate(date, 'YYYY-MM-DD');
  const dateTimeString = `${formattedStartDate} ${time}`;

  const timeInOriginalTimezone = momentTz.tz(
    dateTimeString,
    'YYYY-MM-DD h:mm A', //specify format for dateTimeString for moment to successfully parse it
    originalTimezone,
  );

  return timeInOriginalTimezone.clone().tz(targetTimezone);
};

const HALF_HOURS_IN_DAY = 48;

// Creates a list of time options in 30 minute increments (12:00 AM, 12:30 AM... etc.)
export const generateTimeOptions = (minTime?: string) => {
  const timeOptions = times(HALF_HOURS_IN_DAY, (index) => {
    // 30 minutes in milliseconds
    const time = index * 1000 * 60 * 30;
    const momentObj = moment().startOf('day').add(time, 'millisecond');

    return {
      label: momentObj.format('h:mm A'),
      // Format we send to the server
      value: momentObj.format('HH:mm'),
    };
  });

  // If minTime is set, we remove any options before this time
  if (minTime) {
    const minTimeIndex = timeOptions.findIndex((item) => {
      return item.value === minTime;
    });

    if (minTimeIndex > -1) {
      return timeOptions.slice(minTimeIndex);
    }
  }

  return timeOptions;
};

export const getMonthIndexFromString = (month: string) => {
  return parseInt(moment().month(month).format('M')) - 1; //for 0 based indexing
};

export const getFormattedMonth = (month: number, format?: string) => {
  return moment()
    .month(month)
    .format(format ? format : 'MMM');
};

export const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

//returns total number of days in month
export const getDaysInMonth = (month: any, year: any) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getCalendarDates = (month: number, year: number) => {
  const daysInMonth = getDaysInMonth(month, year);

  const firstDayOfMonth = moment(new Date(year, month, 1)).format('ddd');
  const lastDayOfMonth = moment(new Date(year, month, daysInMonth)).format('ddd');

  const prefixPaddingDays = daysOfWeek.indexOf(firstDayOfMonth);
  const suffixPaddingDays = 6 - daysOfWeek.indexOf(lastDayOfMonth);

  const startDateOfMonth = new Date(year, month, 1);
  const endDateOfMonth = moment(new Date(year, month, daysInMonth));

  const totalDaysToRender = prefixPaddingDays + daysInMonth + suffixPaddingDays;

  // Date from which the calender will start rendering.. not necessarily belonging to the current month.
  const calendarStartDate = moment(startDateOfMonth)
    .subtract(prefixPaddingDays, 'days')
    .toDate();

  // Date until which the calender will render.. not necessarily belonging to the current month.
  const calendarEndDate = moment(endDateOfMonth).add(suffixPaddingDays, 'days').toDate();

  return {
    totalDaysToRender,
    calendarStartDate,
    calendarEndDate,
  };
};
