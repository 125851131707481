export enum DrawerIdEnum {
  MENU = 'MENU',
  SETTINGS = 'SETTINGS',
  SUBMIT_FORM = 'SUBMIT_FORM',
  LEAVE_FORM = 'LEAVE_FORM',
  DELETE_CONFIRMATION = 'DELETE_CONFIRMATION',
  NOTIFICATION = 'NOTIFICATION',
  PRAISE = 'PRAISE',
  SKILLS_ACTION = 'SKILLS_ACTION',
  PROJECT = 'PROJECT',
  OPPORTUNITY = 'OPPORTUNITY',
  REVIEW = 'REVIEW',
  COMPANY_UPDATE = 'COMPANY_UPDATE',
  COMPANY_UPDATE_CONFIRMATION = 'COMPANY_UPDATE_CONFIRMATION',
  FILTER = 'FILTER',
  STATIC_DATA_ITEM_ACTION_CONFIRMATION = 'STATIC_DATA_ITEM_ACTION_CONFIRMATION',
  ORGANIZATION_ROLE = 'ORGANIZATION_ROLE',
  ORGANIZATION_EVENT = 'ORGANIZATION_EVENT',
  PRINCIPLES_FILTER = 'PRINCIPLES_FILTER',
  POST = 'POST',
  MANAGE_WIN_LIST = 'MANAGE_WIN_LIST',
}
