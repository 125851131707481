import React from 'react';
import { useTheme } from '@mui/material/styles';

import { RecommendedSkills, SkillSelector } from 'common/components/input';
import { Stack, Typography } from 'common/components/material';
import {
  PRAISE_SKILLS_INPUT_LABEL,
  PRIASE_SKILLS_INPUT_NAME,
  PraiseFormSkillsProps,
} from 'features/praise';

const PraiseFormSkills: React.FC<PraiseFormSkillsProps> = ({
  onChange,
  value,
  maxSkillsCount,
}) => {
  const { palette } = useTheme();

  return (
    <Stack>
      <SkillSelector
        name={PRIASE_SKILLS_INPUT_NAME}
        label={PRAISE_SKILLS_INPUT_LABEL}
        value={value}
        onChange={onChange}
        limit={maxSkillsCount}
        getOptionDisabled={() => !!maxSkillsCount && value?.length >= maxSkillsCount}
      />
      <Stack direction="row" mt={1.5} justifyContent={'space-between'}>
        <RecommendedSkills
          name={PRIASE_SKILLS_INPUT_NAME}
          label="Suggestions"
          selectedSkills={value}
          disabled={value?.length >= maxSkillsCount}
          onChange={onChange}
        />
        <Typography
          variant="caption"
          color={value?.length > 0 ? palette.Success : palette.TextFields.HintText}
        >
          {`${value?.length || 0} / ${maxSkillsCount} skills`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PraiseFormSkills;
