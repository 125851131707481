export enum ExplorerDataTypeEnum {
  DEPARTMENT = 'DEPARTMENT',
  DIVISION = 'DIVISION',
  PRODUCT = 'PRODUCT',
  TEAM = 'TEAM',
  GROUP = 'GROUP',
  WORK_LOCATION = 'WORK_LOCATION',
  ASPIRATION = 'ASPIRATION',
  ASPIRATION_DEPARTMENT = 'ASPIRATION_DEPARTMENT',
  ASPIRATION_DIVISION = 'ASPIRATION_DIVISION',
  ASPIRATION_PRODUCT = 'ASPIRATION_PRODUCT',
  ASPIRATION_TEAM = 'ASPIRATION_TEAM',
  ASPIRATION_COUNTRY = 'ASPIRATION_COUNTRY',
  ASPIRATION_CITY = 'ASPIRATION_CITY',
  ASPIRATION_SKILL = 'ASPIRATION_SKILL',
  REPORTS_TO = 'REPORTS_TO',
  SKILL = 'SKILL',
  PRIOR_COMPANIES = 'PRIOR_COMPANIES',
  EVENT_TYPE = 'EVENT_TYPE',
  WIN_PERIOD = 'WIN_PERIOD',
}

export enum PaginationType {
  PAGE = 'PAGE',
  CURSOR = 'CURSOR',
}

export enum MenuItemArrowDirectionEnum {
  UP = 'UP',
  DOWN = 'DOWN',
  RIGHT = 'RIGHT',
}
