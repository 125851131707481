import { CompanyUpdateFeed } from 'features/company-updates';
import { MediaType } from '../media-type.enum';
import { MediableType } from '../media.enum';
import { PostsFeed } from 'features/posts';

export type FileInfo = {
  url: string;
  name: string;
  file: File | null;
  size: number | null;
};

export type Media = {
  id?: string | null;
  type: MediaType;
  name?: string | null;

  fileName?: string | null;
  fileUrl?: string | null;
  file?: FileInfo | null;
  size?: number | null;

  url?: string | null;
  label?: string | null;

  thumbnailFileName?: string;
  thumbnailUrl?: string | null;
  thumbnailFile?: FileInfo | null;

  order?: number;
};

export type Mediable = {
  attachments?: Media[];
};

export type VideoSearchResponse = {
  mediableType: MediableType;
  mediable: CompanyUpdateFeed | PostsFeed;
} & Media;

export type MediaRequestDto = {
  id?: string | null;
  type: MediaType;
  fileName?: string | null;
  thumbnailFileName?: string | null;
  url?: string | null;
  label?: string | null;
};

export const toMediaRequestDto = (media: Media): MediaRequestDto => {
  return {
    id: media.id,
    type: media.type,
    fileName: media.fileName,
    thumbnailFileName: media.thumbnailFileName,
    url: media.url,
    label: media.label,
  };
};
