export enum AssessmentStatusType {
  UNKNOWN = 'UNKNOWN_ASSESSMENT_STATUS',
  INCOMPLETE = 'INCOMPLETE_ASSESSMENT_STATUS',
  COMPLETE = 'COMPLETE_ASSESSMENT_STATUS',
  NOT_STARTED = 'NOT_STARTED_ASSESSMENT_STATUS',
}

export enum ArchetypeTendency {
  MOST_LIKE_YOU = 'MOST_LIKE_YOU',
  LEAST_LIKE_YOU = 'LEAST_LIKE_YOU',
}

export enum PrinciplesRoutesEnum {
  REPORTS = 'REPORTS',
  ASSESSMENTS = 'ASSESSMENTS',
}

export enum PrinciplesFilterTypeEnum {
  PROFILE = 'PROFILE'
}
