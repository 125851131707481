export enum AccountsRoutesEnum {
  ACCOUNTS = '/',
  LOGIN = '/login',
  SIGNUP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  VERIFY_EMAIL = '/verify-email',
  CHECK_EMAIL = '/check-email',
  RESET_PASSWORD = '/reset-password',
  INCOMPLETE_SETUP = '/incomplete-setup',
  NO_PROFILE = '/no-profile',
  VERIFICATION = '/verification',
  SWITCH_ORGANIZATION = '/switch-organization',
  FORCE_LOGOUT = '/force-logout',
  SSO_REDIRECT = '/sso-redirect',
  DEFAULT = '*',
}
