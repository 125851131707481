import { useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { GuardRouteProps } from 'common/routes/Route.type';
import React from 'react';
import RootContainer from 'features/org-root/components/RootContainer';
import { CircularProgress } from 'common/components/material';
import { NavigateToOrgRoute } from 'common/components/navigation';

const UnauthenticatedRoute: React.FC<GuardRouteProps> = ({ element }) => {
  const { hasSession, isAccountFetching, isProfileFetching } = useSession();

  if (isAccountFetching || isProfileFetching) {
    return (
      <RootContainer headerNavButton={<></>}>
        <CircularProgress sx={{ alignSelf: 'center' }} size={100} />
      </RootContainer>
    );
  }

  if (hasSession) {
    return <NavigateToOrgRoute route={AppRoutesEnum.HOME} />;
  }

  return element;
};

export default UnauthenticatedRoute;
