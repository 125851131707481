import React from 'react';
import { Organization } from 'common/types/Organization.type';
import { Stack, Typography } from '../material';
import { useTheme } from '@mui/material';
import { CXChip } from '../cx-chip';
import { useFormContext } from 'react-hook-form';
import { FieldName } from 'common/types/Form.type';

const VisibleToSuggestion: React.FC<{
  resources: Organization[];
  selectedResources: Organization[];
  name?: string;
  onResourceSelected?: (resources: Organization[]) => void;
  label?: string;
}> = ({ name, resources, selectedResources, onResourceSelected, label = 'Suggestion' }) => {
  const { palette } = useTheme();
  // Only relevant if component is inside a FormProvider
  const { setValue, trigger } = useFormContext() || {};

  if (!resources.length) {
    return <></>;
  }

  const handleOnChipClick = (updatedSelectedResources: Organization[]) => {
    if (name) {
      setValue(name as FieldName, updatedSelectedResources, { shouldDirty: true });
      trigger(name as FieldName);
    }
    onResourceSelected && onResourceSelected(updatedSelectedResources);
  };

  return (
    <Stack spacing={1}>
      <Typography pl={1} variant="caption" color={palette.Text.Caption}>
        {label}
      </Typography>
      <Stack gap={1} direction="row">
        {resources.map((resource) => (
          <CXChip
            key={resource.id}
            sx={{ px: 0.25, py: 1.5 }}
            label={resource.name}
            variant="outlined"
            onClick={() => handleOnChipClick([...selectedResources, resource])}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default VisibleToSuggestion;
