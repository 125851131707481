import axios from 'common/utils/axios';
import { Project } from '../types';
import { OpenProjectLimitParams } from '../types/project-server-request.type';

const projectsApi = axios('projects');

export const createProjectApi = ({ ...data }: any) => {
  return projectsApi.post('', data);
};

export const deleteProjectApi = ({ id }: Project) => {
  return projectsApi.delete(id);
};

export const updateProjectApi = ({ id, ...data }: Project) => {
  return projectsApi.put(`${id}`, data);
};

export const manageProjectsApi = ({ ...data }: any) => {
  return projectsApi.post('manage', data);
};

export const getProjectApi = (id?: string) => {
  return id ? projectsApi.get(id) : null;
};

export const getProfileProjectsApi = (profileId: string, filters = {}) => {
  return projectsApi.get('', { params: { profileId, ...filters } });
};

export const getSummaryLatestProjectsApi = (profileId: string) => {
  return projectsApi.get('recent', { params: { profileId } });
};

export const getLatestProjectsApi = (tagId?: string) => {
  return projectsApi.get('latest', { params: { tagId } });
};

export const getSearchProjectsApi = (params: any) => {
  return projectsApi.get('search', { params });
};

export const checkOpenProjectLimitApi = (params: OpenProjectLimitParams) => {
  return projectsApi.get('check-open-limit', { params });
};

export const getWinsExpiryStatusApi = () => {
  return projectsApi.get('expiry-status');
};
