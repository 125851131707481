import { styled } from '@mui/material/styles';
import { Stack } from 'common/components/material';
import { Card } from 'common/components/material';

export const QuickLinkItem = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '0.625rem',
  color: theme.palette.LinkText.Default,
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '2rem',
  padding: '0.25rem',
  '&:hover span .MuiSvgIcon-root': {
    color: theme.palette.LinkText.Active,
  },
  [theme.breakpoints.up('md')]: {
    ' .actionIcon': {
      display: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.LinkContainer.BgHover,
      ' .actionIcon': {
        display: 'inherit',
      },
      ' .targetLink': {
        color: theme.palette.LinkText.Active,
      },
    },
  },
}));

export const StyledQuickLinkCard = styled(Card)(({ theme }) => ({
  flexDirection: 'column',
  padding: '1rem',
  minHeight: '7.875rem',
  '&:hover span .MuiSvgIcon-root': {
    color: theme.palette.LinkText.Active,
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: '0',
    borderLeft: 'none',
    borderRight: 'none',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    ' .actionIcon': {
      visibility: 'hidden',
    },
    '&:hover': {
      cursor: 'pointer',
      ' .actionIcon': {
        visibility: 'visible',
      },
      ' .targetLink': {
        color: theme.palette.LinkText.Active,
      },
    },
  },
}));

export const LabelTextSpan = styled('span')({
  width: '1rem',
  display: 'inline-block',
  marginRight: '1rem',
});
