import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { get } from 'lodash';
import { useTheme } from '@mui/material/styles';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import {
  FormTextField,
  SkillSelector,
  StaticDataSelector,
  StyledForm,
} from 'common/components/input';
import { Stack, Tooltip, Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import { useDeferredState, useDrawer, useForm, useView } from 'common/hooks';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { OrganizationRoleForm } from '../types/organization-role-form.type';
import { OrganizationRoleFormSchema } from '../utils/organization-role.schemas';
import {
  StaticDataTypeEnum,
  extractLocationIQDetailsFromWorkLocation,
} from 'features/static-data';
import { useCreateOrganizationRole, useUpdateOrganizationRole } from '../hooks';
import YearPicker from 'common/components/material/YearPicker';
import { OrganizationRole } from '../types';
import {
  AUTOFOCUS_FIELD,
  OrganizationRoleDrawerProps,
} from '../types/organization-role-drawer-props.type';
import { Skill } from 'features/skills';
import CXCheckbox from 'common/components/cx-checkbox/cx-checkbox.component';
import { TITLE_CHARACTER_LIMIT } from 'common/constants/common.constants';
import OrganizationRoleStaticDataFormFields from './organization-role-static-data-form-fields.component';
import { useGetProfile } from 'features/profiles';
import { CXEditorField } from 'libs/toast-ui/components/cx-editor-field.component';
import MonthSelector from 'common/components/month-selector/month-selector.component';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureAccessEnum } from 'features/feature-access/enums/feature-access.enum';

const DRAWER_TITLE = 'Role';

export const INIT_PROPS: OrganizationRoleDrawerProps = {
  organizationRole: {} as OrganizationRole,
  profileId: '' as string,
  isRoleNotRemovable: false as boolean,
  isNewRole: true as boolean,
  autoFocusField: AUTOFOCUS_FIELD.TITLE as AUTOFOCUS_FIELD,
};

const OrganizationRoleDrawer: React.FC<OrganizationRoleDrawerProps> = ({
  organizationRole,
  profileId,
  isRoleNotRemovable,
  isNewRole,
  autoFocusField = AUTOFOCUS_FIELD.TITLE,
}) => {
  const { palette } = useTheme();
  const { closeDrawer } = useDrawer();
  const { isDesktopView } = useView();
  const { data: profileData } = useGetProfile({
    id: profileId,
  });
  const organizationProfile = profileData?.organizationProfile?.data;

  const department = get(organizationProfile, 'department');
  const division = get(organizationProfile, 'division');
  const product = get(organizationProfile, 'product');
  const workLocation = get(organizationProfile, 'workLocation');

  const [skills, setSkills] = useDeferredState<Skill[]>(organizationRole?.skills);
  const [startYear, setStartYear] = useDeferredState<number>(organizationRole?.startYear);
  const [endYear, setEndYear] = useDeferredState<number>(organizationRole?.endYear);

  const defaultLocation =
    organizationRole?.location ||
    (workLocation ? extractLocationIQDetailsFromWorkLocation(workLocation) : undefined);

  const form = useForm<OrganizationRoleForm>({
    defaultValues: {
      ...organizationRole,
      department: get(organizationRole, 'department', department),
      division: get(organizationRole, 'division', division),
      products: get(organizationRole, 'products', product ? [product] : []),
      location: defaultLocation,
      profileId,
      startYear: organizationRole?.startYear
        ? organizationRole.startYear
        : new Date().getFullYear(),
      endYear: organizationRole?.endYear,
      isCurrentRole:
        organizationRole?.isCurrentRole === undefined
          ? true
          : organizationRole?.isCurrentRole,
    },
    mode: 'onChange',
    resolver: yupResolver(OrganizationRoleFormSchema),
  });

  const {
    formState: { isValid },
    getValues,
    reset,
    setValue,
    trigger,
  } = form;

  const [isChecked, setIsChecked] = useState<boolean>(getValues().isCurrentRole);

  const { submit: createOrganizationRole, isLoading: isCreating } =
    useCreateOrganizationRole(profileId);
  const { submit: updateOrganizationRole, isLoading: isUpdating } =
    useUpdateOrganizationRole();

  const handleStartYearInputChange = (newStartYear: number | null) => {
    setStartYear(newStartYear!);
  };
  const handleEndYearInputChange = (newEndYear: number | null) => {
    setEndYear(newEndYear!);
  };

  const actionLabel = organizationRole?.id ? 'Edit' : 'Add New';

  const shouldDisableActions = isCreating || isUpdating;

  const onDiscard = () => {
    reset();
    closeDrawer(DrawerIdEnum.ORGANIZATION_ROLE);
  };

  const onSubmit = () => {
    const data = getValues();
    if (organizationRole?.id) {
      updateOrganizationRole(data);
    } else {
      createOrganizationRole({ ...data });
    }
    onDiscard();
  };

  const onIsCurrentRoleChanged = () => {
    setIsChecked((isChecked) => {
      let endYear = undefined;
      if (isChecked) {
        /**
         * YearPicker would default to the current UTC year if the input is left black;
         * that unfortunately would only change the displayed value and not the underlying
         * model property, ultimately leading to weird validation issues (e.g., users would
         * select the end month, but the submit button would stay disabled, because from the
         * model perspective, end year is still undefined, and you cannot edit a role if
         * end month is there but end year is not; note: the end year field is not dirty,
         * so no error would be displayed to the user...)
         */
        endYear = new Date().getFullYear();
      }
      setValue('endMonth', undefined);
      setValue('endYear', endYear);
      setEndYear(endYear);
      return !isChecked;
    });
  };

  const header = (
    <DrawerHeader
      sx={{
        display: 'block',
        borderBottom: `1px solid ${palette.Divider}`,
        padding: 2,
      }}
    >
      <Typography
        variant={isDesktopView ? 'h2' : 'h4'}
        fontWeight={700}
        color={palette.Text.Headline}
      >
        {`${actionLabel} ${DRAWER_TITLE}`}
      </Typography>
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isCreating || isUpdating}
      isPrimaryActionDisabled={!isValid || shouldDisableActions}
      secondaryAction={onDiscard}
      primaryAction={onSubmit}
      primaryActionLabel={'Save'}
    />
  );

  return (
    <FormDrawer header={header} footer={footer}>
      <FormProvider {...form}>
        <StyledForm noValidate>
          <Stack spacing={3} m={2} pb={2}>
            <FormTextField
              name="jobTitle"
              label="Profile Headline"
              placeholder="e.g. ‘Head of Client Support for Product ABC"
              autoFocus
              inputProps={{
                maxLength: TITLE_CHARACTER_LIMIT,
              }}
            />
            <StaticDataSelector
              name="jobFunction"
              label="Job Function"
              type={StaticDataTypeEnum.JOB_TITLES}
            />
            <OrganizationRoleStaticDataFormFields />
            <FeatureGuard
              features={[{ feature: FeaturesEnum.SKILLS, access: FeatureAccessEnum.WRITE }]}
            >
              <SkillSelector
                name="skills"
                label="Skills Utilized"
                value={skills}
                onChange={setSkills}
                placeholder="Type to search"
                autoFocus={autoFocusField === AUTOFOCUS_FIELD.SKILLS}
              />
            </FeatureGuard>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CXCheckbox
                name="isCurrentRole"
                value={isChecked}
                onChange={onIsCurrentRoleChanged}
                disabled={isRoleNotRemovable && organizationRole?.isCurrentRole}
                label={
                  <Typography variant="body3" color={palette.Text.Headline} ml="0.375rem">
                    I am currently working in this role
                  </Typography>
                }
              />
              {isRoleNotRemovable && organizationRole?.isCurrentRole && (
                <Tooltip title="At least 1 current role required">
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{
                      color: palette.Text.SubHeadline,
                      paddingLeft: '6px',
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
            <Stack display="flex" flexDirection="row" justifyContent="space-between">
              <MonthSelector
                name="startMonth"
                label="Role Start Month"
                sx={{ width: '50%', paddingRight: '10px' }}
              />
              <YearPicker
                name="startYear"
                initYear={startYear}
                label="Role Start Year"
                onDateSelected={handleStartYearInputChange}
                sx={{ width: '50%' }}
              />
            </Stack>
            {!isChecked && (
              <Stack display="flex" flexDirection="row" justifyContent="space-between">
                <MonthSelector
                  name="endMonth"
                  label="End Month"
                  sx={{ width: '50%', paddingRight: '10px' }}
                  onChange={() => {
                    trigger('endMonth');
                    endYear && trigger('endYear');
                  }}
                />
                <YearPicker
                  name="endYear"
                  displayDefaultDate={!isNewRole}
                  initYear={endYear}
                  label="End Year"
                  onDateSelected={handleEndYearInputChange}
                  sx={{ width: '50%' }}
                  onYearChange={() => {
                    trigger('endMonth');
                  }}
                />
              </Stack>
            )}
            <CXEditorField
              name="jobDescription"
              placeholder="Briefly describe your key activities and responsibilities"
              autoFocus={autoFocusField === AUTOFOCUS_FIELD.DESCRIPTION}
            />
          </Stack>
        </StyledForm>
      </FormProvider>
    </FormDrawer>
  );
};

export default OrganizationRoleDrawer;
