import { some, isEmpty } from 'lodash';

import Videocam from '@mui/icons-material/Videocam';
import LinkIcon from '@mui/icons-material/Link';
import { Media } from '../../types/media.types';
import { getStyledIcon, getTitleAndSubLabelColor } from '../../utils/media.utils';
import MediaCard from '../media-card.component';
import { useTheme } from '@mui/material';
import { MediaType } from '../../media-type.enum';
import { useState } from 'react';
import MediaDetailModal from './media-detail-modal.component';
import { useSnackbar } from 'common/hooks';

export const MediaVideoLoader: React.FC<{
  title: string;
  label?: string;
  type: MediaType.LINK | MediaType.VIDEO;
  existingListOfMedia: Media[];
  disabled?: boolean;
  onChange: (mediaAttachments: Media[]) => void;
  onClick?: () => void;
}> = ({ title, label, type, existingListOfMedia, disabled, onChange, onClick }) => {
  const { palette } = useTheme();
  const { openSnackbar } = useSnackbar();
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const icon = type === MediaType.VIDEO ? Videocam : LinkIcon;

  const onAddNew = (media: Media) => {
    const isNewMedia = !some(
      existingListOfMedia,
      (existingMedia) =>
        (!isEmpty(media.fileName) && existingMedia.fileName === media.fileName) ||
        (!isEmpty(media.url) && existingMedia.url === media.url),
    );

    if (isNewMedia) {
      existingListOfMedia.push(media);
      onChange(existingListOfMedia);
    } else {
      openSnackbar('Video is already uploaded/used', 'warning');
    }

    setOpenDetailModal(false);
  };

  const onClickCard = () => {
    if (disabled) {
      return;
    }

    setOpenDetailModal(true);
    onClick && onClick();
  };

  return (
    <>
      <MediaCard
        title={title}
        subLabel={label}
        iconElement={getStyledIcon(icon, palette, false, disabled)}
        titleColor={getTitleAndSubLabelColor(palette, false, disabled, false)}
        subLabelColor={getTitleAndSubLabelColor(palette, false, disabled, true)}
        onClick={onClickCard}
        isActive={false}
        disabled={disabled}
      />
      {openDetailModal && (
        <MediaDetailModal
          type={type}
          open={openDetailModal}
          onAdd={onAddNew}
          onClose={() => setOpenDetailModal(false)}
        />
      )}
    </>
  );
};
