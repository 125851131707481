import { useTheme } from '@mui/material/styles';
import { Link, Stack } from '../material';
import { LinkTileContentContainer } from './link-tile-with-avatar.style';
import { LinkTileContainerProps } from './link-tile-with-avatar.type';

export const LinkTileContainer: React.FC<LinkTileContainerProps> = ({
  route,
  children,
  sx,
  showBorder = true,
  onClick,
  readonly = false,
}) => {
  const { palette } = useTheme();
  const borderStyle = showBorder
    ? {
        border: `1px solid ${palette.Stroke.Default}`,
      }
    : {};

  if (!route) {
    return <></>;
  }

  if (readonly) {
    return <Stack sx={sx}>{children}</Stack>;
  }

  return (
    <Link href={route} clickHandler={onClick}>
      <LinkTileContentContainer direction="row" sx={sx} {...borderStyle}>
        {children}
      </LinkTileContentContainer>
    </Link>
  );
};
