import { filter } from 'lodash';
import { ExplorerDataMapItem } from './types/explorer-data.type';

export const BASE_EXPLORER_LEVEL_CONFIG = {
  header: '',
  // Prop that determines the displayed name for each item
  itemLabelKey: 'name',
  // Same as above, but for search mode (single-column view)
  searchItemLabelKey: 'fullName',
  select: undefined,
  deleteApiFunction: undefined,
};

export const defaultTopLevelSelect = (items: ExplorerDataMapItem[]) => {
  return filter(items, (item) => !item.parentId);
};

export const defaultChildSelect = (items: ExplorerDataMapItem[], parentId?: string) => {
  if (!parentId) {
    return [];
  }
  return filter(items, { parentId });
};
