import { useMutation, useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { signupApi } from '../accounts.service';

export const useSignup = () => {
  const { goTo } = useRouter();

  return useMutation(signupApi, {
    onSuccess: ({ data }) => {
      goTo(
        AppRoutesEnum.ACCOUNTS_VERIFY_EMAIL,
        {},
        { state: { email: data.email } },
      );
    },
  });
};
