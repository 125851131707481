import { EditorProps } from '@toast-ui/react-editor';

const toolbarItems = [['bold', 'italic'], ['quote'], ['ul', 'ol'], ['link']];

const linkAttributes = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const defaultEditorConfig = {
  initialEditType: 'wysiwyg',
  useCommandShortcut: true,
  usageStatistics: false,
  toolbarItems: toolbarItems,
  hideModeSwitch: true,
  placeholder: 'Please enter the text',
  linkAttributes,
} as EditorProps;

export const defaultViewerConfig = {
  linkAttributes,
  usageStatistics: false,
};
