import PubSub from './PubSub';
import { EventType, EventTypeSettled } from '../types/Event-type.type';

//TODO: HL: Need to find a better place for these files.
export default class EventManager {
  private activeEvents = {} as {
    [prop in EventType | EventTypeSettled]: PubSub;
  };

  emit<D>(eventType: EventType | EventTypeSettled, data: D): void {
    const activeEvent = this.activeEvents[eventType];

    if (!activeEvent) {
      return;
    }

    activeEvent.publish(data);
  }

  subscribe<D>(eventType: EventType | EventTypeSettled, callback: (data: D) => void): void {
    let subject = this.activeEvents[eventType];

    if (!subject) {
      subject = new PubSub();
      this.activeEvents[eventType] = subject;
    }

    subject.subscribe(callback);
  }

  unsubscribe<D>(eventType: EventType | EventTypeSettled, callback: (data: D) => void): void {
    const subject = this.activeEvents[eventType];

    if (!subject) {
      return;
    }

    subject.unsubscribe(callback);
  }
}
