import { get } from 'lodash';

import { useRouter, useMutation, useSession, useLocalStorage } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { transformCodeVerificationError } from '../accounts.utils';
import { changePasswordApi } from '../accounts.service';
import { useLogin } from './useLogin';

export const useChangePassword = (email: string, subUrl?: string) => {
  const { goTo } = useRouter();
  const { submit: login } = useLogin();
  const { value: recentSubUrl } = useLocalStorage('recent-suburl');
  const { subUrlOnSession } = useSession();
  subUrl = subUrl || subUrlOnSession || recentSubUrl;
  return useMutation(changePasswordApi, {
    showSuccessSnackbar: true,
    onSuccess: (result, { password }) => {
      // If there is active session, it means user has created password for non-sso organization, hence redirect user to required sub-url
      if (email) {
        const loginParams = {
          email,
          password,
          subUrl,
        };

        login(loginParams);
      } else {
        goTo(AppRoutesEnum.ACCOUNTS_LOGIN, {}, { state: { subUrl } });
      }
    },
    onError: (error) => {
      const transformedError = transformCodeVerificationError(error);
      // We'll get an email in the error message if the code is expired
      const email = get(transformedError, 'email');

      if (email) {
        // Show the expired code screen
        goTo(
          {
            pathname: AppRoutesEnum.ACCOUNTS_CHECK_EMAIL,
            search: {
              email,
              isExpired: true,
            },
          },
          {},
          { state: { subUrl } },
        );
      }
    },
  });
};
