import { styled } from '@mui/material/styles';
import { RadioGroup } from 'common/components/input';

export const LocationTypeRadioGroup = styled(RadioGroup)(({ theme }) => ({
  paddingLeft: '1rem',
  '& .MuiFormControlLabel-root': {
    paddingRight: '0.5rem',
    marginRight: '24px',
  },
  '& .MuiFormControlLabel-label': {
    color: theme.palette.Text.SubHeadline,
  },
  '& .Mui-checked+.MuiFormControlLabel-label, & .MuiFormControlLabel-root:hover .MuiFormControlLabel-label':
    {
      color: theme.palette.Text.Headline,
    },
  '& .MuiFormControlLabel-root.selected, & .MuiFormControlLabel-root:hover': {
    backgroundColor: theme.palette.Buttons.Tertiary.BgHover,
    borderRadius: '12px',
  },
  '& .MuiRadio-root:hover': {
    background: 'none',
  },
}));
