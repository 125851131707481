import React from 'react';

import MUISkeleton, { SkeletonProps } from '@mui/material/Skeleton';

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const { children, ...skeletonProps } = props;

  return <MUISkeleton {...skeletonProps}>{children}</MUISkeleton>;
};

export default Skeleton;
