import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Typography, Stack } from 'common/components/material';
import { EmptyOrganizationEventImage } from 'common/components/image';

const EmptyOrganizationEventView: React.FC<{
  createButton?: React.ReactNode;
  showLabel?: boolean;
}> = ({ createButton = <></>, showLabel = true }) => {
  const { palette } = useTheme();

  return (
    <Stack alignItems="center" justifyContent="center" spacing={2.5} data-analytics-tag="search_no_results_found">
      <Stack spacing={1.5} alignItems="center" justifyContent="center">
        <EmptyOrganizationEventImage />
        <Typography variant="body3" fontWeight={500} color={palette.Text.Headline}>
          No Events
        </Typography>
        {showLabel && <Typography variant="body4" color={palette.Text.SubHeadline} textAlign='center'>
          No events at this moment. Please check back later.
        </Typography>}
        {createButton}
      </Stack>
    </Stack>
  );
};

export default EmptyOrganizationEventView;
