import React from 'react';

import { Stack, Typography } from 'common/components/material';
import { BaseDataExplorerColumnProps } from '../types/base-data-explore-props.type';

const BaseDataExplorerColumn: React.FC<BaseDataExplorerColumnProps> = ({
  label,
  children,
}) => {
  const labelComponent = label && (
    <Typography variant="caption" fontWeight={600}>
      {label}
    </Typography>
  );
  return (
    <Stack flex={1} p={1} height="100%" sx={{ overflowY: 'auto' }}>
      <Stack spacing={1}>
        {labelComponent}
        {children}
      </Stack>
    </Stack>
  );
};

export default BaseDataExplorerColumn;
