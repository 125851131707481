import { useSearchParams } from 'react-router-dom';
import { compact } from 'lodash';
import { useEvents, useInfiniteQuery, useSession } from 'common/hooks';
import {
  createCursorQueryParams,
  getNextCursorParam,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import { getPraiseApi } from '../praise.service';
import { GetPraisesRequest } from '../types';
import { mapSearchParamsObject } from 'features/search';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { EVENTS_TO_REFETCH_PRAISES } from '../praise.utils';

export const useGetPraises = (params: GetPraisesRequest) => {
  const { account: user, hasOrganization } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const filters = mapSearchParamsObject(searchParams) as { [prop: string]: any };
  const tags = compact(params?.tags);
  if (params?.tags && !tags.includes(user?.organizationId)) {
    filters.tags = params.tags;
  }
  if (params?.receiverId) {
    filters.receiverId = params.receiverId;
  }
  if (params?.giverId) {
    filters.giverId = params.giverId;
  }

  const getPraisesApi = (params: any) => {
    const queryParams = createCursorQueryParams(params.pageParam, {
      term: query,
      ...filters,
    });
    return getPraiseApi(queryParams);
  };

  const queryKey = [user?.organizationId, 'praises', filters];

  if (query) {
    queryKey.push(query);
  }

  const queryResponse = useInfiniteQuery(queryKey, getPraisesApi, {
    getNextPageParam: getNextCursorParam,
    entityKey: 'praises',
    enabled: hasOrganization,
  });

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  useEvents(EVENTS_TO_REFETCH_PRAISES as Event[], () => queryResponse.refetch());

  return {
    ...queryResponse,
    data,
  };
};

export default useGetPraises;
