import axios from 'common/utils/axios';
import { DEFAULT_PAGE_SIZE } from './notifications.constants';
import { MarkNotificationIdsRequest } from './types';

const notificationsApi = axios('notifications');

export const getNotificationsApi = (params: any) => {
  const page = params.pageParam?.page || 1;
  const size = params.pageParam?.size || DEFAULT_PAGE_SIZE;
  return notificationsApi.get('recent', { params: { page, size } });
};

// This API does 2 tasks:
// 1. If we pass `all: true`, it marks all unread notifications as read
// 2. If we pass `all: false` or we don't even pass the param, the request body in form of: { ids: [$notificationIds$]} is mandatory
//    which updates only selected notifications as read which we passed in body.
export const markNotificationsToReadApi = (ids?: MarkNotificationIdsRequest, all = true) => {
  return notificationsApi.post('mark-as-read', ids ? { ids } : undefined, {
    params: !ids ? { all } : null,
  });
};

export const getTotalUnreadNotificationsApi = () => {
  return notificationsApi.get('total-unread');
};
