import React from 'react';
import { useTheme } from '@mui/material/styles';
import MUIList, { ListProps as MUIListProps } from '@mui/material/List';
import MUIListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';

export type ListProps = MUIListProps;

const List: React.FC<MUIListProps> = (props) => {
  const { children, ...listProps } = props;

  return <MUIList {...listProps}>{children}</MUIList>;
};

export const ListSubheader: React.FC<ListSubheaderProps> = (props) => {
  const { children, ...listSubheaderProps } = props;
  const { palette } = useTheme();

  return (
    <MUIListSubheader
      sx={{
        backgroundColor: palette.Container.Bg,
      }}
      {...listSubheaderProps}
    >
      {children}
    </MUIListSubheader>
  );
};

export default List;
