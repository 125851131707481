import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'react-query';
import { addRecentSearchesApi } from '../search.service';

export const useAddRecentSearches = (options: UseMutationOptions = {}) => {
  return useMutation(addRecentSearchesApi, {
    showSuccessSnackbar: false,
    showErrorSnackbar: false,
    ...options,
  });
};
