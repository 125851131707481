import { useTheme } from '@mui/material/styles';
import { FormProvider } from 'react-hook-form';
import { OpportunityFormProps } from '../types';
import { Box, Stack, Typography } from 'common/components/material';
import {
  PeopleSelector,
  RecommendedSkills,
  SkillSelector,
  StyledForm,
} from 'common/components/input';
import { useDeferredState, useSession } from 'common/hooks';
import { Skill } from 'features/skills';
import { Tag } from 'features/tags/types';
import TagSelector from 'features/tags/components/tag-selector.component';
import { TitleInput } from 'features/projects/styles/project-form.styles';
import { CXEditorField } from 'libs/toast-ui';
import { SKILLS_MAX_LIMIT, TITLE_CHARACTER_LIMIT } from 'common/constants/common.constants';
import { OPPORTUNITY_DRAWER_HELPER } from '../opportunities.constants';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import VisibileToSelector from 'common/components/role-access/VisibleToSelector';
import { ContentType } from 'common/enum/ContentType.enum';

const OpportunityForm: React.FC<OpportunityFormProps> = ({
  opportunity,
  form,
  disabledTagIds,
}) => {
  const { palette } = useTheme();
  const [requiredSkills, setRequiredSkills] = useDeferredState<Array<Skill>>(
    opportunity?.skills,
  );
  const [tags, setTags] = useDeferredState<Tag[]>(opportunity?.tags);
  const { profile, currentOrganization } = useSession();
  const company = currentOrganization();
  return (
    <FormProvider {...form}>
      <StyledForm noValidate>
        <Stack spacing={3} m={2} pb={2}>
          <TitleInput
            name="title"
            label="Project or Initiative Name"
            autoFocus
            inputProps={{
              maxLength: TITLE_CHARACTER_LIMIT,
            }}
          />
          <CXEditorField name="description" placeholder="Add description" />
          <PeopleSelector
            name="manager"
            label="Manager"
            limit={1}
            selectedProfiles={[profile]}
          />
          <FeatureGuard features={[{ feature: FeaturesEnum.SKILLS }]}>
            <Box>
              <SkillSelector
                name="skills"
                label="Add Required Skills"
                placeholder="Type to search"
                value={requiredSkills}
                onChange={(skills) => {
                  setRequiredSkills(skills);
                }}
              />
              <Stack direction="row" mt={1.5} justifyContent={'space-between'}>
                <RecommendedSkills
                  name="skills"
                  selectedSkills={requiredSkills}
                  onChange={(skills) => {
                    setRequiredSkills(skills);
                  }}
                />
                <Typography
                  variant="caption"
                  color={
                    (requiredSkills?.length || 0) > 0
                      ? palette.Success
                      : palette.TextFields.HintText
                  }
                >
                  {`${requiredSkills?.length || 0} / ${SKILLS_MAX_LIMIT} skills`}
                </Typography>
              </Stack>
            </Box>
          </FeatureGuard>
          <FeatureGuard features={[{ feature: FeaturesEnum.TAGGINGS }]}>
            <Box>
              <TagSelector
                value={tags}
                onChange={(tags) => {
                  setTags(tags);
                }}
                disabledIds={disabledTagIds}
              />
              <Box mt={1}>
                <Typography variant="body4" color={palette.Text.Caption}>
                  <b>Note: </b>
                  {OPPORTUNITY_DRAWER_HELPER}
                </Typography>
              </Box>
            </Box>
          </FeatureGuard>
          <VisibileToSelector
            name="visibleTo"
            label="Visible to"
            contentType={ContentType.OPPORTUNITIES}
            value={opportunity?.visibleTo || [company]}
            onChange={() => {
              form.trigger('visibleTo');
            }}
          />
        </Stack>
      </StyledForm>
    </FormProvider>
  );
};

export default OpportunityForm;
