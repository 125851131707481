import { useCallback } from 'react';
import { findIndex } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { useEvents } from 'common/hooks';
import queryClient from 'common/utils/QueryClient';
import {
  deleteEndorsedSkill,
  updateEndorsedSkill,
} from 'features/profiles/helpers/endorsed-skills';
import { SkillEndorsementEventData } from 'common/types/Event-Data.type';
import { SearchResults } from 'features/profiles';

const updateSearchProfileSkillsCache = (
  response: SkillEndorsementEventData,
  query: string,
  callback: Function,
) => {
  const profileId = response.profileId;
  const skillId = response.skillId;

  if (!profileId) {
    return;
  }

  const cacheKey = ['search', response.currentOrganization, query];
  const searchResult = queryClient.getQueryData(cacheKey) as SearchResults;

  if (!searchResult) {
    return;
  }

  const resultIndex = findIndex(searchResult.data.profiles, ['id', profileId]);

  searchResult.data.profiles[resultIndex].endorsedSkills = callback(
    searchResult.data.profiles[resultIndex].endorsedSkills,
    skillId,
    response.data,
  );

  queryClient.setQueryData(cacheKey, searchResult);
};

export const useSyncSearchProfileSkillsCache = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';

  useEvents<SkillEndorsementEventData>(
    'SkillEndorsement',
    useCallback(
      (response) => {
        updateSearchProfileSkillsCache(response, query, updateEndorsedSkill);
      },
      [query],
    ),
  );

  useEvents<SkillEndorsementEventData>(
    'SkillUnEndorsement',
    useCallback(
      (response) => {
        updateSearchProfileSkillsCache(response, query, deleteEndorsedSkill);
      },
      [query],
    ),
  );
};
