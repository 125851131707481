import React from 'react';

import { Stack } from 'common/components/material';
import TagChip from 'features/tags/components/tag-chip.component';
import { Tag } from 'features/tags/types';
import { StyledChip, TagLabel } from 'features/tags/styles/tag-chip.styles';

export const OrganizationEventDesktopTags: React.FC<{
  tags?: Tag[];
  onMoreClick?: (e: React.MouseEvent) => void;
}> = ({ tags, onMoreClick }) => {
  if (!tags?.length) {
    return <></>;
  }

  const visibleTag = tags[0];
  const hiddenTagCount = tags.length - 1;

  return (
    <Stack direction="row" alignItems="center" justifyContent="start" spacing={0.5}>
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        spacing={0.5}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <TagChip
          key={visibleTag.id}
          tag={visibleTag}
          sx={{
            '& .MuiChip-label': { pl: '0.125rem', pr: '0.275rem' },
            '.MuiAvatar-root, .MuiSvgIcon-root, .tag-icon': { display: 'none' },
          }}
        />
        {!!hiddenTagCount && (
          <StyledChip
            clickable
            onClick={onMoreClick}
            label={
              <TagLabel variant="body4" fontWeight={500}>
                {`+${hiddenTagCount}`}
              </TagLabel>
            }
            variant="outlined"
            sx={{ '& .MuiChip-label': { pl: '0.25rem', pr: '0.375rem' } }}
          />
        )}
      </Stack>
    </Stack>
  );
};
