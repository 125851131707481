import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getCurrentWinPeriodApi } from '../services/win-periods.service';

export const useGetCurrentWinPeriod = (options?: UseQueryOptions) => {
  const { account: user } = useSession();
  const queryResponse = useQuery(
    ['current-win-period', user.organizationId],
    () => getCurrentWinPeriodApi(),
    options,
  );

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse?.data.data : {},
  };
};
