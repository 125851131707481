import React from 'react';

import MUIToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = (props) => {
  const { children, ...toggleButtonGroupProps } = props;

  return <MUIToggleButtonGroup {...toggleButtonGroupProps}>{children}</MUIToggleButtonGroup>;
};

export default ToggleButtonGroup;
