export enum NotificationTypeEnum {
  PRAISE = 'PRAISE',
  ENDORSEMENT = 'ENDORSEMENT',
  ORGANIZATION_EVENT = 'ORGANIZATION_EVENT',
  LIKE = 'LIKE',
  COMMENT = 'COMMENT',
  PROJECT = 'PROJECT',
  OPPORTUNITY = 'OPPORTUNITY',
  COMPANY_UPDATE = 'COMPANY_UPDATE',
  POST = 'POST',
  BOB = 'BOB',
}
