import axios from 'common/utils/axios';
import {
  AccountRequestParams,
  ChangePasswordRequestParams,
  EmailRequestParam,
  PreLoginRequestParams,
} from './types';
import { AxiosRequestConfig } from 'axios';

const authApi = axios('auth');

export const signupApi = (data: AccountRequestParams) => {
  return authApi.post('signup', data);
};

export const changePasswordApi = (data: ChangePasswordRequestParams) => {
  return authApi.post('change-password', data);
};

export const loginApi = (data: AccountRequestParams) => {
  return authApi.post('login', data);
};

export const logoutApi = () => {
  return authApi.get('logout');
};

export const preLoginApi = (params: PreLoginRequestParams, options?: AxiosRequestConfig) => {
  return authApi.get(`pre-login`, {
    params,
    ...options,
  });
};

export const csrfTokenApi = (options?: AxiosRequestConfig) => {
  return authApi.get(`csrf-token`, options);
};

export const sendChangePasswordEmailApi = (data: EmailRequestParam) => {
  return authApi.post('forgot-password', data);
};

export const sendVerificationEmailApi = (email: string) => {
  return authApi.get(`verification/${email}`);
};

export const sendCompleteAccountSetupLinkApi = (params: { email: string }) => {
  return authApi.get(`complete-account-link`, {
    params,
  });
};

export const verifyPasswordResetCodeApi = (resetCode: string) => {
  return authApi.get(`reset-password/verify/${resetCode}`);
};

export const verifyAccountApi = (secretCode: string) => {
  return authApi.get(`verification/verify/${secretCode}`);
};

export const getAccountApi = () => {
  return authApi.get('me');
};
