import { isEmpty } from 'lodash';

import { FloatingMenuItem } from 'common/components/core/FloatingMenu';
import { OrganizationRole } from '../types';
import { OrganizationalStaticDataTypeEnum } from 'features/static-data';

export const DEFAULT_OPTIONS: FloatingMenuItem[] = [
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
];

export const transformOrganizationRole = (role: OrganizationRole) => {
  const { tags } = role;

  role.products = [];

  !isEmpty(tags) &&
    tags.forEach((tag) => {
      const fullName = tag.completeName;
      if (tag.type === OrganizationalStaticDataTypeEnum.DEPARTMENT) {
        role.department = { ...tag, fullName, isVisible: true };
      }
      if (tag.type === OrganizationalStaticDataTypeEnum.DIVISION) {
        role.division = { ...tag, fullName, isVisible: true };
      }
      if (tag.type === OrganizationalStaticDataTypeEnum.PRODUCT) {
        role.products?.push({ ...tag, fullName, isVisible: true });
      }
    });

  return role;
};

export const transformOrganizationRoles = (roles: OrganizationRole[] = []) => {
  return roles.map(transformOrganizationRole);
};
