import React from 'react';

import VerticalScrollableContainer from 'common/components/container/VerticalScrollableContainer';

type FormDrawerProps = {
  children: React.ReactElement;
  header: React.ReactElement;
  footer: React.ReactElement;
};

const FormDrawer: React.FC<FormDrawerProps> = ({ children, header, footer }) => {
  return (
    <>
      {header}
      <VerticalScrollableContainer
        disableGutters
        sx={{
          flexDirection: 'column',
          padding: 0,
          display: 'flex',
          flex: '1 1 0',
          width: '100%',
        }}
      >
        {children}
      </VerticalScrollableContainer>
      {footer}
    </>
  );
};

export default FormDrawer;
