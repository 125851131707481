import React, { useState } from 'react';
import { reduce } from 'lodash';
import { useTheme } from '@mui/material/styles';

import DataExplorerListItem from 'features/data-explorer/components/base-data-explorer-list-Item.component';
import { CXChip } from 'common/components/cx-chip';
import { ActiveFilters, getFilterCount } from 'features/search';
import { Box, Stack } from 'common/components/material';
import Collapse from 'libs/mui/collapse/collapse.component';
import { FilterMenuItem } from '../types/filter-menu-item.type';
import {
  ExplorerDataTypeEnum,
  MenuItemArrowDirectionEnum,
} from 'features/data-explorer/data-explorer.enum';

const FilterMenuListItem: React.FC<{
  menuItem: FilterMenuItem;
  activeFilters: ActiveFilters;
  onSelect: (filterType: ExplorerDataTypeEnum) => void;
}> = ({ menuItem, activeFilters, onSelect }) => {
  const { palette } = useTheme();
  const { key, label, children = [] } = menuItem;

  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = !!children.length;

  const handleClick = () => {
    hasChildren ? setIsExpanded(!isExpanded) : onSelect(key);
  };

  const getArrowDirection = () => {
    if (!hasChildren) {
      return MenuItemArrowDirectionEnum.RIGHT;
    }

    return isExpanded ? MenuItemArrowDirectionEnum.UP : MenuItemArrowDirectionEnum.DOWN;
  };

  const childFilterCount = reduce(
    children,
    (count, child) => {
      const childCount = getFilterCount(activeFilters, child.key);
      if (childCount) {
        count += childCount;
      }
      return count;
    },
    0,
  );

  const filterCount = getFilterCount(activeFilters, key) + childFilterCount;

  return (
    <Box>
      <DataExplorerListItem
        label={label}
        showArrow={true}
        arrowDirection={getArrowDirection()}
        onClick={handleClick}
        data-testid={`${key}-filter-key`}
        sx={{
          backgroundColor: isExpanded ? palette.Buttons.Tertiary.BgHover : 'none',
          '&:hover': {
            color: palette.Text.Headline,
            backgroundColor: palette.Buttons.Tertiary.BgHover,
          },
        }}
      >
        {!!filterCount && <CXChip label={filterCount} variant="filled" />}
      </DataExplorerListItem>
      <Collapse in={isExpanded}>
        <Stack bgcolor={palette.CommentBg} borderRadius="0.5rem" mt="0.5rem" spacing={0.5}>
          {children.map((childItem) => (
            <FilterMenuListItem
              key={childItem.key}
              menuItem={childItem}
              activeFilters={activeFilters}
              onSelect={onSelect}
            />
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default FilterMenuListItem;
