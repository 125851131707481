import { useSearchParams } from 'react-router-dom';

import { compact } from 'lodash';
import { useEvents, useInfiniteQuery, useSession } from 'common/hooks';
import { GetOpportunitiesRequest } from 'features/opportunities/types';
import {
  createCursorQueryParams,
  getNextCursorParam,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import { getOpportunitiesApi } from '../opportunities.service';
import { mapSearchParamsObject } from 'features/search';
import { EVENTS_TO_REFETCH_OPPORTUNITIES } from '../opportunities.utils';
import { Event } from 'common/hooks/eventHooks/useEvents';

export const useGetOpportunities = (params: GetOpportunitiesRequest) => {
  const { account: user, hasOrganization } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const filters = mapSearchParamsObject(searchParams) as { [prop: string]: any };
  const tags = compact(params?.tags);
  if (tags && !tags.includes(user?.organizationId)) {
    filters.tags = tags;
  }

  const getOpportunities = (params: any) => {
    const queryParams = createCursorQueryParams(params.pageParam, {
      term: query,
      ...filters,
    });
    return getOpportunitiesApi(queryParams);
  };

  const queryKey = ['opportunities', user?.organizationId, filters];

  if (query) {
    queryKey.push(query);
  }

  const queryResponse = useInfiniteQuery(queryKey, getOpportunities, {
    getNextPageParam: getNextCursorParam,
    entityKey: 'opportunities',
    enabled: hasOrganization,
  });

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  useEvents(EVENTS_TO_REFETCH_OPPORTUNITIES as Event[], () => queryResponse.refetch());

  return {
    ...queryResponse,
    data,
  };
};
