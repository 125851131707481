import { useEvents, useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getSummaryLatestProjectsApi } from '../services/projects.service';
import { EVENTS_TO_REFETCH_PROJECTS } from '../project.utils';
import { Event } from 'common/hooks/eventHooks/useEvents';

export const useGetLatestProfileProjects = (
  profileId: string,
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();
  const queryResponse = useQuery(
    [user.organizationId, profileId, 'projects', 'latest'],
    () => getSummaryLatestProjectsApi(profileId),
    {
      select: (response) => {
        return {
          ...response,
          data: {
            projects: response.data.projects,
          },
        };
      },
      enabled: !!profileId,
      ...options,
    },
  );

  useEvents(EVENTS_TO_REFETCH_PROJECTS as Event[], () => queryResponse.refetch());

  return {
    ...queryResponse,
    data: queryResponse?.data?.data?.projects,
  };
};
