import ColorPalette from 'common/theme/colorPalette';

export const REVIEW_SECTION_HEIGHT = '20rem';

export const REVIEW_CARD_STATUS = {
  NOT_STARTED: {
    label: 'NOT STARTED',
    color: ColorPalette.ORANGE800,
    bgColor: ColorPalette.ORANGE100,
  },
  COMPLETED: {
    label: 'COMPLETED',
    color: ColorPalette.BLUEGRAY500,
    bgColor: ColorPalette.GRAY100,
  },
};

export const REVIEW_RATINGS = [
  {
    value: 1,
    image: 'unsatisfactory',
    color: ColorPalette.REVIEWRATINGS.UNSATISFACTORY,
    displayText: 'Unsatisfactory',
  },
  {
    value: 2,
    image: 'justFine',
    color: ColorPalette.REVIEWRATINGS.JUSTFINE,
    displayText: 'Just Fine',
  },
  {
    value: 3,
    image: 'great',
    color: ColorPalette.REVIEWRATINGS.GREAT,
    displayText: 'Great',
  },
  {
    value: 4,
    image: 'outstanding',
    color: ColorPalette.REVIEWRATINGS.OUTSTANDING,
    displayText: 'Outstanding',
  },
];

export enum ReviewRoutesEnum {
  REVIEW = ':reviewId',
}

export const REVIEW_RATING_IMAGE_PATH = '/images/ReviewRatings/';
export const REVIEW_SUBMIT_DRAWER_SUBTITLE = 'Reviews cannot be edited once submitted';
