import { styled } from '@mui/material/styles';
import theme from 'common/theme';
import ListItemButton from 'common/components/material/ListItemButton';

// Selects the end adornment (right arrow container) when checkbox or text is hovered
const endAdornmentSiblingSelectors = [
  '& .text-container:has(.MuiListItemText-root:hover) + .end-adornment .MuiSvgIcon-root[data-testid=KeyboardArrowRightIcon]',
  '& .start-adornment:hover ~ .end-adornment .MuiSvgIcon-root[data-testid=KeyboardArrowRightIcon]',
].join(', ');

const UNASSIGNED_STYLE = {
  color: theme.palette.Failure,
  '&:hover, &.Mui-selected, &:hover.Mui-selected': {
    backgroundColor: '#FFE6E6',
    '&:hover': {
      backgroundColor: '#FFE6E6',
      '& .MuiListItemText-root': {
        color: theme.palette.Failure,
      },
      '& .MuiSvgIcon-root[data-testid=KeyboardArrowRightIcon]': {
        backgroundColor: 'transparent',
        fill: theme.palette.Icons.Tertiary.IconFillHover,
      },
    },
  },
};

export const ExplorerListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => {
    return prop !== 'showBorder';
  },
})<{
  showBorder?: boolean;
}>(({ showBorder = false, onClick, theme }) => {
  const customStyle = {
    height: '40px',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    borderRadius: '0.75rem',
    color: theme.palette.LinkText.Default,
    '&.unassigned': { ...UNASSIGNED_STYLE },
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiListItemText-root': {
        color: theme.palette.LinkText.Default,
      },
      '& .MuiSvgIcon-root[data-testid=KeyboardArrowRightIcon]': {
        backgroundColor: theme.palette.Icons.Tertiary.BgHover,
        fill: theme.palette.Icons.Tertiary.IconFillHover,
      },
      '&.Mui-selected .MuiSvgIcon-root[data-testid=KeyboardArrowRightIcon]': {
        backgroundColor: theme.palette.Icons.Tertiary.BgHover,
      },
    },
    '&.Mui-selected, &:hover.Mui-selected': {
      backgroundColor: theme.palette.LinkContainer.BgHover,
    },
    '& .MuiListItemIcon-root .MuiSvgIcon-root': {
      fill: theme.palette.Icons.Tertiary.IconFillDefault,
    },
    '& .MuiListItemText-root:hover': {
      color: theme.palette.LinkText.Active,
      cursor: 'pointer',
    },
    /**
     * By default, hovering over any part of the list item highlights the right arrow.
     * This selector reverts the right arrow to its original color if the text or checkbox is hovered over
     * to show they perform different actions (selecting the checkbox vs expanding children).
     */
    [endAdornmentSiblingSelectors]: {
      fill: theme.palette.Icons.Tertiary.IconFillDefault,
      background: 'none',
    },
    cursor: !!onClick ? 'pointer' : 'default',
  };

  if (!showBorder) {
    return customStyle;
  }

  return {
    ...customStyle,
  };
});
