import { DrawerProps } from 'common/components/material/Drawer';
import { useView } from 'common/hooks';
import { get } from 'lodash';
import { DrawerIdEnum } from '../enums/drawer-id.enum';
import { DRAWER_DEFAULT_PROPS } from '../constants/drawer.constants';

export const useDrawerConfig = () => {
  const { isDesktopView } = useView();

  const getDrawerConfig = (drawerId: DrawerIdEnum): DrawerProps => {
    const open = false;
    const drawerConfig = DRAWER_DEFAULT_PROPS[drawerId] as DrawerProps;
    const anchor = isDesktopView
      ? get(drawerConfig, 'anchor', 'right')
      : get(drawerConfig, 'mobileAnchor', 'bottom');
    return { ...drawerConfig, open, anchor };
  };

  return {
    getDrawerConfig,
  };
};
