import { settled } from 'common/types/Event-type.type';

export const EVENTS_TO_REFETCH_VIDEOS = [
  settled('CompanyUpdateAdded'),
  settled('CompanyUpdateUpdated'),
  settled('CompanyUpdateDeleted'),
  settled('PostsModified'),
  settled('PostDeleted'),
];

export const getFileNameAndFileExtension = (value: string | undefined) => {
  const texts = value?.split('.') || [];
  const extension = texts.pop();
  const fileName = texts.join('.');

  return { fileName, extension };
};

export const getOptimizedImageUrl = (
  src: string | null | undefined,
  width?: number,
  height?: number,
) => {
  let srcURL;
  if (src && width) {
    console.assert(width > 0, 'Optimized image width must be positive');
    try {
      srcURL = new URL(src ?? '');
    } catch {
      srcURL = new URL('http://localhost:3000');
    }
    const srcURLSearchParams = srcURL.searchParams;
    srcURLSearchParams.append('w', `${width}`);
    if (height) {
      console.assert(height > 0, 'Optimized image height must be positive');
      srcURLSearchParams.append('h', `${height}`);
    }
    srcURLSearchParams.append('dpr', `${window.devicePixelRatio ?? 1}`);
  }
  return srcURL ? srcURL.toString() : srcURL;
};
