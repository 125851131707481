import React from 'react';

import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import SkillActionButton from './SkillActionButton';
import { SkillActionSearchCardProps } from 'features/skills';

const SEARCH_ENDORSEMENT_TITLE = 'Find people and opportunities with this skill';
const SEARCH_ASPIRATION_TITLE = 'Find others aspiring to learn this skill';

const SkillActionSearchCard: React.FC<SkillActionSearchCardProps> = ({
  skill,
  toggleDrawer,
}) => {
  const { goTo } = useRouter();

  const handleSearchSkill = (e: React.MouseEvent | React.KeyboardEvent) => {
    toggleDrawer(e);
    goTo({
      pathname: AppRoutesEnum.EXPLORE,
      search: {
        query: skill.name,
      },
    });
  };

  return (
    <SkillActionButton
      label="Explore"
      subLabel={skill.isAspiredSkill ? SEARCH_ASPIRATION_TITLE : SEARCH_ENDORSEMENT_TITLE}
      variant="outlined"
      action={(e) => handleSearchSkill(e)}
    />
  );
};

export default SkillActionSearchCard;
