import { VisibilityEnum } from 'common/enum/App.enum';
import { FloatingMenuItem } from 'common/components/core/FloatingMenu';
import { ExplorerDataTypeEnum } from './data-explorer.enum';
import { FormOperationsEnum } from 'common/enum/Form.enum';
import { Palette } from '@mui/material/styles';

export const UNASSIGNED = 'Unassigned - Unassigned';

export const CITY_OPTIONS: FloatingMenuItem[] = [
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
];

export const UNASSIGNED_WORK_LOCATION_OPTIONS: FloatingMenuItem[] = [
  {
    value: FormOperationsEnum.ADD_OR_UPDATE_CITY,
    label: 'Add City',
  },
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
];

export const ASSIGNED_WORK_LOCATION_OPTIONS: FloatingMenuItem[] = [
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
  {
    value: FormOperationsEnum.ADD_OR_UPDATE_CITY,
    label: 'Update City',
  },
];

export const getExplorerStatusOptions = (palette: Palette) => [
  {
    value: VisibilityEnum.HIDDEN,
    label: VisibilityEnum.HIDDEN,
    dropdownLabel: VisibilityEnum.HIDDEN,
    bgColor: palette.StatusDropdown.Closed.BgDefault,
    color: palette.StatusDropdown.Closed.LabelAndIconDefault,
  },
  {
    value: VisibilityEnum.VISIBLE,
    label: VisibilityEnum.VISIBLE,
    dropdownLabel: VisibilityEnum.VISIBLE,
    bgColor: palette.StatusDropdown.Open.BgDefault,
    color: palette.StatusDropdown.Open.LabelAndIconDefault,
  },
];

export const EDITABLE_EXPLORER_DATA_TYPES = [
  ExplorerDataTypeEnum.DEPARTMENT,
  ExplorerDataTypeEnum.DIVISION,
  ExplorerDataTypeEnum.PRODUCT,
  ExplorerDataTypeEnum.TEAM,
  ExplorerDataTypeEnum.WORK_LOCATION,
  ExplorerDataTypeEnum.GROUP,
];

// This is required to convert initial filters (url query string) to ExploreDataTypeEnum
export const INITIAL_FILTERS_TYPE_MAP = {
  reportsTo: ExplorerDataTypeEnum.REPORTS_TO,
  priorCompanies: ExplorerDataTypeEnum.PRIOR_COMPANIES,
  country: ExplorerDataTypeEnum.WORK_LOCATION,
  city: ExplorerDataTypeEnum.WORK_LOCATION,
  workLocation: ExplorerDataTypeEnum.WORK_LOCATION,
  aspirationCountry: ExplorerDataTypeEnum.ASPIRATION_CITY,
  aspirationCity: ExplorerDataTypeEnum.ASPIRATION_CITY,
  aspirationDepartment: ExplorerDataTypeEnum.ASPIRATION_DEPARTMENT,
  aspirationDivision: ExplorerDataTypeEnum.ASPIRATION_DIVISION,
  aspirationProduct: ExplorerDataTypeEnum.ASPIRATION_PRODUCT,
  aspirationTeam: ExplorerDataTypeEnum.ASPIRATION_TEAM,
  aspirationSkill: ExplorerDataTypeEnum.ASPIRATION_SKILL,
  winPeriod: ExplorerDataTypeEnum.WIN_PERIOD,
} as { [prop: string]: ExplorerDataTypeEnum };

export const FILTER_QUERY_PARAM_KEY = {
  [ExplorerDataTypeEnum.REPORTS_TO]: 'reportsTo',
  [ExplorerDataTypeEnum.PRIOR_COMPANIES]: 'priorCompanies',
  [ExplorerDataTypeEnum.WORK_LOCATION]: 'workLocation',
  [ExplorerDataTypeEnum.ASPIRATION_CITY]: 'aspirationCity',
  [ExplorerDataTypeEnum.ASPIRATION_DEPARTMENT]: 'aspirationDepartment',
  [ExplorerDataTypeEnum.ASPIRATION_DIVISION]: 'aspirationDivision',
  [ExplorerDataTypeEnum.ASPIRATION_PRODUCT]: 'aspirationProduct',
  [ExplorerDataTypeEnum.ASPIRATION_TEAM]: 'aspirationTeam',
  [ExplorerDataTypeEnum.ASPIRATION_SKILL]: 'aspirationSkill',
} as { [prop: string]: string };
