import { ActiveFilters, Filter } from 'features/search';
import { camelCase, clone, cloneDeep } from 'lodash';
import { useReducer } from 'react';

type FilterAction = {
  type: string;
  filterType?: string | null;
  filter?: Filter;
  filterId?: string;
  state?: ActiveFilters;
  excludeChildren?: boolean;
};

type FilterReducerInitializer = (initialState: ActiveFilters) => ActiveFilters;

const INITIAL_FILTERS = {
  department: [],
  division: [],
  product: [],
  team: [],
} as ActiveFilters;

const searchFilterReducer = (state: ActiveFilters, action: FilterAction) => {
  const filterType = camelCase(action.filterType || '');
  const targetFilterList = clone(state[filterType]) || [];
  switch (action.type) {
    case 'add':
      action?.filter && targetFilterList.push(action?.filter);
      return {
        ...state,
        [filterType]: targetFilterList,
      };
    case 'remove':
      const filterId = action?.filterId || action?.filter?.id;
      return {
        ...state,
        [filterType]: targetFilterList.filter((filter) => filter.id !== filterId),
      };
    case 'update':
      return {
        ...action.state,
      };
    case 'clear':
      return cloneDeep(INITIAL_FILTERS);
    default:
      return state;
  }
};

export const useFilterReducer = (
  initialFilters = INITIAL_FILTERS,
  initializer?: FilterReducerInitializer,
) => {
  // useReducer's type doesn't allow passing undefined as the third argument even though it's optional
  return useReducer(
    searchFilterReducer,
    initialFilters,
    initializer || (() => initialFilters),
  );
};
