import React from 'react';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import MenuItemButton from './MenuItemButton';

const ManageOrganizationUsersMenuItem: React.FC = () => {
  const { goTo } = useRouter();

  const handleClick = () => {
    goTo(AppRoutesEnum.ADMIN_MANAGE_USERS, {}, { state: { resetHistoryIndex: true } });
  };

  return (
    <MenuItemButton onClick={handleClick} label="Manage Users">
      <GroupAddOutlinedIcon />
    </MenuItemButton>
  );
};

export default ManageOrganizationUsersMenuItem;
