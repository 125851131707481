import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TimeZone } from '@vvo/tzdb';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';

import { HorizontalFieldContainer } from 'common/components/container';
import { TimeZoneSelector } from 'common/components/input';
import { DatePicker, TimePicker, Typography } from 'common/components/material';

// Part of the form in OrganizationEventDrawer
const OrganizationEventDateTimeFields: React.FC<{ timezone?: TimeZone }> = ({ timezone }) => {
  const { palette } = useTheme();
  const { getValues, setValue, formState, trigger } = useFormContext();

  const today = moment();

  const [startDateObject, setStartDateObject] = useState<Date>(today.toDate());
  const [minEndTime, setMinEndTime] = useState<string>();

  const handleStartDateChange = (startDate: string | null) => {
    const endDate = getValues('endDate');

    if (!startDate || !endDate) {
      setStartDateObject(today.toDate());
      return;
    }

    const endDateIsBefore = moment(endDate).isBefore(startDate, 'day');

    if (endDateIsBefore) {
      setValue('endDate', startDate);
    }

    const newStartDateObject = moment(startDate, 'YYYY-MM-DD').toDate();
    setStartDateObject(newStartDateObject);

    const startTime = getValues('startTime');
    calculateMinEndTime(startDate, endDate, startTime);
    trigger('endTime');
  };

  const handleEndDateChange = (endDate: string | null) => {
    const startDate = getValues('startDate');
    const startTime = getValues('startTime');
    calculateMinEndTime(startDate, endDate || undefined, startTime);
    trigger('endTime');
  };

  const handleStartTimeChange = (startTime: string | null) => {
    const isInvalid = startTime === 'Invalid date';

    if (isInvalid) {
      return;
    }

    const endTime = getValues('endTime');
    const endTimeFieldIsDirty = formState.dirtyFields.endTime;
    const hasInitiallySetEndTime = !!formState.defaultValues?.endTime;
    const shouldUpdateEndTime =
      !endTime || (!endTimeFieldIsDirty && !!endTime && !hasInitiallySetEndTime);

    if (startTime && shouldUpdateEndTime) {
      const defaultEndTime = moment(startTime, 'HH:mm').add(1, 'hour').format('HH:mm');
      setValue('endTime', defaultEndTime);
    }

    // Revalidate `endTime`, regardless:
    // - If `endTime` was dirty, when `startTime` got updated, then this trigger
    //   will make sure `endTime` is not before `startTime`
    // - If `endTime` was not dirty and say had an error message, then this
    //   trigger will make sure that once `startTime` is blanked, the error
    //   message on `endDate` is blanked too
    trigger('endTime');

    const startDate = getValues('startDate');
    const endDate = getValues('endDate');
    calculateMinEndTime(startDate, endDate, startTime);
  };

  const handleEndTimeChange = (endTime: string | null) => {
    if (endTime !== 'Invalid date') {
      trigger('startTime');
    }
  };

  const calculateMinEndTime = (
    startDate?: string,
    endDate?: string,
    startTime?: string | null,
  ) => {
    const isSameDayEvent = startDate && endDate && moment(endDate).isSame(startDate, 'day');

    if (isSameDayEvent && startTime) {
      setMinEndTime(startTime);
    } else {
      setMinEndTime(undefined);
    }
  };

  return (
    <>
      <Typography variant="body1" fontWeight={500} color={palette.Text.Headline}>
        Date & Time
      </Typography>
      <TimeZoneSelector label="Time Zone" name="timeZone" value={timezone} />
      <HorizontalFieldContainer>
        <DatePicker
          name="startDate"
          label="Start Date"
          disablePast={true}
          onDateSelected={handleStartDateChange}
        />
        <TimePicker
          label="Start Time"
          name="startTime"
          onTimeSelected={handleStartTimeChange}
        />
      </HorizontalFieldContainer>
      <HorizontalFieldContainer>
        <DatePicker
          name="endDate"
          label="End Date"
          minDate={startDateObject}
          onDateSelected={handleEndDateChange}
        />
        <TimePicker
          name="endTime"
          label="End Time"
          minTime={minEndTime}
          onTimeSelected={handleEndTimeChange}
        />
      </HorizontalFieldContainer>
    </>
  );
};

export default OrganizationEventDateTimeFields;
