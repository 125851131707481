import { useTheme } from '@mui/material/styles';

import List, { ListSubheader } from 'common/components/material/List';
import { Grid, Typography } from 'common/components/material';

type PeopleSuggestionsListProps = {
  onViewAllClick?: () => void;
};

const PeopleSuggestionsList: React.FC<PeopleSuggestionsListProps> = ({
  onViewAllClick,
  children,
}) => {
  const { palette } = useTheme();

  return (
    <List
      subheader={
        <ListSubheader sx={{ lineHeight: '2.5rem', backgroundColor: palette.Container.Bg }}>
          <Grid display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body3" fontWeight={700} color={palette.DropdownList.Label}>
              People
            </Typography>
            <Typography
              onMouseDown={onViewAllClick}
              color={palette.Buttons.Tertiary.LabelDefault}
              variant="body3"
              fontWeight={500}
              display="flex"
              sx={{ cursor: 'pointer' }}
            >
              View All
            </Typography>
          </Grid>
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
};

export default PeopleSuggestionsList;
