import { UpdateOpportunityRequest } from 'features/opportunities/types';
import axios from 'common/utils/axios';
import { CreateOpportunityRequest } from './types';

const opportunitiesApi = axios('opportunity');

export const createOpportunityApi = ({ ...data }: CreateOpportunityRequest) => {
  return opportunitiesApi.post('', data);
};

export const deleteOpportunityApi = ({ id }: UpdateOpportunityRequest) => {
  return opportunitiesApi.delete(id);
};

export const updateOpportunityApi = ({ id, ...data }: UpdateOpportunityRequest) => {
  return opportunitiesApi.put(id, data);
};

export const getOpportunitiesApi = (params: any) => {
  return opportunitiesApi.get('search', { params });
};

export const getLatestOpportunitiesByTagApi = (tagId?: string) => {
  return opportunitiesApi.get('latest', { params: { tagId } });
};
