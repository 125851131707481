import { styled } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

export type IconProps = {
  muiIcon?: typeof SvgIcon;
  svg?: React.FC;
};

const getStyledSvg = (svg: React.FC, color: string): React.ReactElement => {
  const StyledSvg = styled(svg, {
    shouldForwardProp: (prop) => {
      return prop !== 'color';
    },
  })<{ color?: string }>(({ color }) => ({
    height: '16px',
    width: '16px',
    fontWeight: '800',
    fill: color || 'currentcolor',
  }));

  return <StyledSvg color={color} />;
};

const CXIcon: React.FC<{
  Icon?: typeof SvgIcon;
  svg?: React.FC;
  color: string;
}> = ({ Icon, svg, color }) => {
  const StyledSvg = svg ? getStyledSvg(svg, color) : <></>;

  return <>{Icon ? <Icon sx={{ color: color, fontSize: '16px' }} /> : StyledSvg}</>;
};

export default CXIcon;
