import { Person } from '../types';
import { WorkLocation } from 'features/static-data';

export class ProfilePreviewModel {
  constructor(private readonly _data: Person) {}

  get id(): string {
    return this._data.id;
  }

  get fullName(): string {
    return this._data.fullName || '';
  }

  get photo(): string {
    return this._data.photo || '';
  }

  get profileHeadline(): string {
    return this._data.profileHeadline as string;
  }

  get workLocation(): WorkLocation {
    return this._data.workLocation as WorkLocation;
  }
}
