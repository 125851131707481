import { yupResolver } from '@hookform/resolvers/yup';
import { get, omit } from 'lodash';
import React from 'react';
import { useTheme } from '@mui/material/styles';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import { FormOperationsEnum } from 'common/enum/Form.enum';
import { useDrawer, useForm, useSession, useView } from 'common/hooks';
import {
  OpportunityFormValue,
  useCreateOpportunity,
  useUpdateOpportunity,
} from 'features/opportunities';

import OpportunityForm from 'features/opportunities/components/opportunity-form.component';
import { OpportunityDrawerProps } from '../types/opportunity-drawer-props.type';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { Person } from 'features/profiles';
import { useOpportunityFormSchema } from '../hooks/useOpportunityFormSchema';
import VisibleToOrganizationText from 'features/org-root/components/VisibleToOrganizationText';

const OpportunityDrawer: React.FC<OpportunityDrawerProps> = ({
  opportunity,
  selectedTags = [],
  disabledTagIds,
}) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();
  const { closeDrawer } = useDrawer();
  const { currentOrganization, profile } = useSession();
  const company = currentOrganization();
  const opportunityFormSchema = useOpportunityFormSchema();

  const opportunityId = get(opportunity, 'id');
  const operation = opportunityId ? FormOperationsEnum.UPDATE : FormOperationsEnum.CREATE;
  const drawerTitle = `${
    operation === FormOperationsEnum.CREATE ? 'Create' : 'Edit'
  } Opportunity`;

  const { submit: createOpportunity, isLoading: isSubmittingOpportunity } =
    useCreateOpportunity();
  const { submit: updateOpportunity, isLoading: isUpdatingOpportunity } =
    useUpdateOpportunity();

  const isLoading = isSubmittingOpportunity || isUpdatingOpportunity;
  const isExistingOpportunity = !!opportunityId;

  const defaultValues = {
    title: '',
    description: '',
    skills: [],
    tags: selectedTags,
  };

  const opportunityDefaultValues = Object.assign(
    {},
    { ...defaultValues, visibleTo: opportunity?.visibleTo || [company] },
    omit(opportunity, 'manager'),
    { manager: isExistingOpportunity ? [opportunity?.manager as Person] : [profile] },
  );

  const form = useForm<OpportunityFormValue>({
    defaultValues: opportunityDefaultValues,
    mode: 'onChange',
    resolver: yupResolver(opportunityFormSchema),
  });

  const {
    formState: { isDirty, isValid },
    getValues,
    reset,
  } = form;

  const shouldDisableActions = !isDirty || isLoading;

  const onDiscard = () => {
    closeDrawer(DrawerIdEnum.OPPORTUNITY);
    reset();
  };

  const onSubmit = () => {
    const data = getValues();
    if (isExistingOpportunity) {
      updateOpportunity(data);
    } else {
      createOpportunity(data);
    }
    onDiscard();
  };

  const header = (
    <DrawerHeader
      sx={{
        display: 'block',
        borderBottom: `1px solid ${palette.Divider}`,
        padding: 2,
      }}
    >
      <Typography
        variant={isMobileView ? 'body1' : 'h2'}
        fontWeight={700}
        color={palette.Text.Headline}
      >
        {drawerTitle}
      </Typography>
      <VisibleToOrganizationText visibleTo={form.getValues().visibleTo} />
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isLoading}
      isPrimaryActionDisabled={!isValid || shouldDisableActions}
      secondaryAction={onDiscard}
      primaryAction={onSubmit}
      primaryActionLabel={operation === FormOperationsEnum.UPDATE ? 'Save' : 'Create'}
    />
  );

  return (
    <FormDrawer header={header} footer={footer}>
      <OpportunityForm
        opportunity={opportunityDefaultValues}
        form={form}
        disabledTagIds={disabledTagIds}
      />
    </FormDrawer>
  );
};

export default OpportunityDrawer;
