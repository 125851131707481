import { UseQueryOptions } from 'common/types/Api.type';

import { useQuery } from 'common/hooks';
import { getSkillEndorsementApi } from 'features/skills/services';
import { EndorsedSkillWithEndorsers } from 'features/skills';

export const useGetEndorsedSkill = (
  profileId: string,
  skillId?: string | null,
  options: UseQueryOptions = {},
) => {
  const queryKey = ['profile', 'skill-endorsements', profileId];

  if (skillId) {
    queryKey.push(skillId);
  }

  const queryResponse = useQuery(queryKey, () => getSkillEndorsementApi(profileId, skillId), {
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.endorsedSkills as EndorsedSkillWithEndorsers[],
  };
};
