import React from 'react';
import { times } from 'lodash';

import { Divider, Stack } from 'common/components/material';

const HierarchicalExplorerColumnContainer: React.FC<{
  columnCount: number;
  renderColumn: (index: number) => React.ReactNode;
  showColumnDividers?: boolean;
}> = ({ columnCount, renderColumn, showColumnDividers }) => {
  return (
    <Stack direction="row" minHeight={0} height="100%">
      {times(columnCount, (index: number) => {
        const column = renderColumn(index);
        const isLastColumn = index + 1 === columnCount;
        return (
          <React.Fragment key={index}>
            {column}
            {showColumnDividers && !isLastColumn && (
              <Divider orientation="vertical" flexItem />
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default HierarchicalExplorerColumnContainer;
