import { useContext } from "react";
import { ApplicationContext } from "../application-context";
import { ThemeProvider as MUiThemeProvider, createTheme } from "@mui/material";
import { getDesignTokens } from "common/theme";

export const ThemeProvider: React.FC = ({ children }) => {
   const { themeContext } = useContext(ApplicationContext);

   return (
      <MUiThemeProvider theme={createTheme(getDesignTokens(themeContext.theme))}>{children}</MUiThemeProvider>
   );
};
