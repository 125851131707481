import { styled } from '@mui/material/styles';

import Stack from 'common/components/material/Stack';

export const LinkTileContentContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  padding: '0.75rem',
  borderRadius: '0.75rem',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.Container.Bg,
  '.link-title': {
    color: theme.palette.LinkText.Default,
  },
  '.link-sub-title': {
    color: theme.palette.Text.SubHeadline,
  },
  '.show-on-hover': {
    color: theme.palette.Icons.Tertiary.IconFillHover,
    marginRight: '0.5rem',
  },
  '.hide-on-hover': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.5rem',
  },
  '&:hover': {
    backgroundColor: theme.palette.LinkContainer.BgHover,
    borderColor: theme.palette.LinkContainer.StrokeHover,
    '.show-on-hover': {
      color: theme.palette.Icons.Tertiary.IconFillHover,
      opacity: 1,
      transform: 'translateX(0.3rem);',
      transition: 'all 0.25s ease-in',
    },
    '.hide-on-hover': {
      display: 'none',
    },
    '.link-title': {
      color: theme.palette.LinkText.Active,
    },
  },
}));
