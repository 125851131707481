import React from 'react';

import { AutocompleteProps } from 'common/types/Form.type';
import FormAutocomplete from './FormAutocomplete';
import { useTimeZones } from 'common/hooks';
import theme from 'common/theme';

type TimeZoneSelectorProps = Omit<AutocompleteProps, 'options'>;

const TimeZoneSelector: React.FC<TimeZoneSelectorProps> = ({ onChange, ...props }) => {
  const { timeZones } = useTimeZones();

  return (
    <FormAutocomplete
      options={timeZones || []}
      optionKey="name"
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.currentTimeFormat}
      openOnFocus={true}
      optionTextProps={{
        fontSize: theme.typography.body3.fontSize,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      showArrowIcon={true}
      {...props}
    />
  );
};

export default TimeZoneSelector;
