import { Stack, styled } from '@mui/material';

export const StyledListItem = styled(Stack)(({ theme }) => ({
  padding: '.3125rem',
  color: theme.palette.LinkText.Default,
  '& .list-item-icon': {
    display: 'flex',
    color: theme.palette.Icons.Tertiary.IconFillDefault,
  },
  '&:hover, &.selected': {
    cursor: 'pointer',
    color: theme.palette.LinkText.Active,
    backgroundColor: theme.palette.LinkContainer.BgHover,
    borderRadius: '0.75rem',
    '& .list-item-icon': {
      color: theme.palette.Icons.Tertiary.IconFillHover,
    },
    '#arrow-icon': {
      opacity: 1,
      transform: 'translateX(0.3rem);',
      transition: 'all 0.25s ease-in',
    },
  },
}));
