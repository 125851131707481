import axios from 'common/utils/axios';
import { GetEndorsedSkillsRequestParams } from '../types/skills-request-params.type';

const skillEndorsementsApi = axios('skill-endorsements');

export const getSkillEndorsersApi = (endorseeId: string, skillId: string) => {
  return skillEndorsementsApi.get('endorsers', {
    params: { endorseeId, skillId },
  });
};

// Get a list of skills that are linked (endorsed) to a user in the organization
export const getEndorsedSkillsApi = (params: GetEndorsedSkillsRequestParams) => {
  const { term, page = 1, size = 10, contentType, ...selectedFilters } = params;
  return skillEndorsementsApi.get('skills', {
    params: {
      term,
      page,
      size,
      contentType,
      ...selectedFilters,
    },
  });
};

export const getSkillEndorsementApi = (profileId: string, skillId?: string | null) => {
  return skillEndorsementsApi.get('endorsed-skills', {
    params: {
      profileId,
      skillId,
    },
  });
};
