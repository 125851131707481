import { SxProps, Theme, styled } from '@mui/material/styles';
import React from 'react';

import { CXChip } from 'common/components/cx-chip';
import { SvgIconState } from 'common/components/image';
import { Stack, Typography } from 'common/components/material';
import ButtonBase from 'common/components/material/ButtonBase';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export type ButtonTabConfig = {
  value: string;
  label: string;
  defaultIcon?: React.FC;
  activeIcon?: React.FC;
  iconSize?: number;
  fontSize?: number;
  pathName?: string;
  adminOnly?: boolean;
  IconComponent?: React.FC<any>;
  onClick?: () => void;
  badgeLabel?: string;
  sx?: SxProps<Theme>;
};

type ButtonTabProps = ButtonTabConfig & {
  isActive: boolean;
};

export const StyledButtonTab = styled(ButtonBase)(({ theme }) => {
  return {
    paddingBlock: '0.25rem',
    borderRadius: '0.375rem',
    paddingInline: '0.5rem',
    backgroundColor: theme.palette.Tabs.Primary.BgDefault,
    border: `1px solid ${theme.palette.Tabs.Primary.StrokeDefault}`,
    color: theme.palette.Tabs.Primary.LabelAndIconDefault,
    '&.is-active': {
      color: theme.palette.Tabs.Primary.LabelAndIconClicked,
      border: `2px solid ${theme.palette.Tabs.Primary.LabelAndIconClicked}`,
      backgroundColor: theme.palette.Tabs.Primary.BgActive,
      boxShadow: AppBoxShadows.shadow3,
    },

    '&:hover': {
      color: theme.palette.Tabs.Primary.LabelAndIconHover,
      borderColor: theme.palette.Tabs.Primary.StrokeHover,
      boxShadow: AppBoxShadows.shadow3,
    },
  };
});

const ButtonTab: React.FC<ButtonTabProps> = ({
  value,
  label,
  defaultIcon,
  activeIcon,
  isActive,
  fontSize = 12,
  onClick,
  badgeLabel,
  sx,
  IconComponent,
}) => {
  return (
    <StyledButtonTab
      className={`${isActive ? 'button-tab is-active' : 'button-tab'}`}
      data-tab={value}
      onClick={onClick}
      sx={sx}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {activeIcon && defaultIcon && (
          <SvgIconState
            ActiveIcon={activeIcon}
            DisabledIcon={defaultIcon}
            isActive={isActive}
          />
        )}

        {IconComponent && IconComponent}

        <Typography variant="body3" whiteSpace="nowrap" fontWeight={500} fontSize={fontSize}>
          {label}
        </Typography>
        {!!badgeLabel && <CXChip label={badgeLabel} />}
      </Stack>
    </StyledButtonTab>
  );
};

export default ButtonTab;
