import useInfiniteQuery from 'common/hooks/useInfiniteQuery';
import { useParamsProfileId, useSession } from 'common/hooks';
import {
  getNextPageParam,
  getTotalCount,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import { getReviewsApi } from '../reviews.service';

export const useGetProfileReviews = (profileId: string) => {
  const { profileId: defaultProfileId } = useParamsProfileId();
  profileId = profileId || defaultProfileId;

  const { account: user } = useSession();

  const getReviews = (params: any) => {
    return getReviewsApi(params, profileId);
  };

  const queryKey = ['reviews', profileId, user?.organizationId];

  const queryResponse = useInfiniteQuery(queryKey, getReviews, {
    enabled: !!profileId,
    getNextPageParam,
    entityKey: 'reviews',
  });

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse.data.pages : [],
    total: queryResponse.isSuccess ? getTotalCount(queryResponse.data) : 0,
  };
};
