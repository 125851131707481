import Stack from '../material/Stack';
import CircularProgress from '../material/CircularProgress';
import ModalContainer from './ModalContainer';
import { useGetSkillEndorsers } from 'features/skills';
import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { LinkTileWithAvatar } from '../link-tile-with-avatar/link-tile-with-avatar.component';

export type EndorsersModalProps = {
  endorseeId: string;
  skillId: string;
  open: boolean;
  onClose: () => void;
  onClick: () => void;
};

const EndorsersModal: React.FC<EndorsersModalProps> = (props) => {
  const { endorseeId, skillId, onClick, ...otherProps } = props;
  const { getOrgRoute } = useRouter();

  const { data: endorsers = [], isLoading } = useGetSkillEndorsers(endorseeId, skillId);

  return (
    <ModalContainer title="Endorsers" {...otherProps}>
      <>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          endorsers.map((endorser) => {
            const route = getOrgRoute(AppRoutesEnum.PROFILE, {
              profileId: endorser.id,
            });
            return (
              <LinkTileWithAvatar
                key={endorser.id}
                route={route}
                title={endorser.fullName}
                subtitle={endorser.profileHeadline}
                photo={endorser.photo}
                showBorder={false}
                onClick={onClick}
              />
            );
          })
        )}
      </>
    </ModalContainer>
  );
};
export default EndorsersModal;
