import React, { useState } from 'react';

import { FormAutocomplete } from 'common/components/input';
import { Stack } from 'common/components/material';
import { useDebounce } from 'common/hooks';
import { UseQueryResponse } from 'common/types/Api.type';
import { AutocompleteProps } from 'common/types/Form.type';
import { validateMinLength } from 'common/utils/app.utils';

import PersonChip from 'features/profiles/components/PersonChip';
import { useFetchProfilesByName } from 'features/search';
import { SearchProfile } from 'features/profiles';

type PeopleSelectorProps = Omit<AutocompleteProps, 'options' | 'onChange'> & {
  selectedProfiles?: SearchProfile[];
  readOnly?: boolean;
  limit?: number;
  excludePeople?: string[];
  includeInactive?: boolean;
  apiParams?: Record<string, string | boolean | undefined>;
  optionsApiHook?: (params?: any, options?: any) => UseQueryResponse;
  onChange?: (items: SearchProfile[]) => void;
};

const PeopleSelector: React.FC<PeopleSelectorProps> = ({
  formFieldProps,
  onChange,
  selectedProfiles,
  readOnly,
  limit,
  optionsApiHook = useFetchProfilesByName,
  excludePeople,
  multiple = true,
  includeInactive = true,
  apiParams = {},
  ...multiTextInputProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue);
  const { data: people, isLoading } = optionsApiHook(
    {
      term: debouncedInputValue,
      excludeIds: excludePeople || [],
      includeInactive,
      ...apiParams,
    },
    {
      enabled: validateMinLength(debouncedInputValue, 1),
    },
  );

  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);

  return (
    <Stack spacing={1}>
      <FormAutocomplete
        autoComplete
        multiple={multiple}
        placeholder="Type to perform a search"
        loading={isLoading}
        limit={limit}
        options={people || []}
        getOptionLabel={(option) => option?.fullName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionAvatar={(option) => option?.photo}
        ChipComponent={PersonChip}
        open={openSearchDropdown}
        onInputChange={(e, value, reason) => {
          value.length === 0 ? setOpenSearchDropdown(false) : setOpenSearchDropdown(true);
          /**
           * This callback fired on input blur or when the
           * dropdown appeared, even with clearOnBlur set to false.
           * Had to ignore these reset events to prevent clearing
           * the input prematurely.
           */
          if (reason === 'reset') {
            return;
          }
          setInputValue(value);
        }}
        onChange={(value) => {
          setInputValue('');
          onChange && onChange(value);
        }}
        inputValue={inputValue}
        value={selectedProfiles}
        hasIconChip={true}
        customReadOnly={readOnly}
        formFieldProps={formFieldProps}
        {...multiTextInputProps}
      />
    </Stack>
  );
};

export default PeopleSelector;
