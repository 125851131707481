import { WorkLocationSubdivisionEnum } from 'features/static-data/static-data.enum';
import { camelCase, each, set, split, toLower, has } from 'lodash';
import {
  ActiveFilterMap,
  ActiveFilters,
  BaseFilterType,
  FilterType,
} from './types/filters.type';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import {
  FILTER_QUERY_PARAM_KEY,
  INITIAL_FILTERS_TYPE_MAP,
} from 'features/data-explorer/data-explorer.constants';

export const mapSearchParamsObject = (
  searchParams: URLSearchParams,
  asArray?: boolean,
  omitKeys?: string[],
): { [prop: string]: any } => {
  const params = {};

  searchParams.forEach((value, key) => {
    if (key !== 'query' && !omitKeys?.includes(key)) {
      const value = searchParams.get(key);
      const transformedValue = asArray ? split(value, ',') : value;
      set(params, key, transformedValue);
    }
  });

  return params;
};

// Creates a map of currently active filters with the filter id as the key for easy reference
export const mapSearchFiltersObject = (activeFilters: ActiveFilters) => {
  const activeFiltersMap = {} as ActiveFilterMap;

  each(activeFilters, (filters, filterType) => {
    if (filterType === 'excludeChildren') {
      return;
    }

    each(filters, (filter) => {
      const filterKey = `${filter.id}_${toLower(
        INITIAL_FILTERS_TYPE_MAP[filterType] || filterType,
      )}`;
      activeFiltersMap[filterKey] = filter;
    });
  });

  return activeFiltersMap;
};

// Search string to filter object
export const mapSearchString = (searchString?: string) => {
  if (!searchString) {
    return {};
  }
  const params = new URLSearchParams(searchString);
  return mapSearchParamsObject(params, true);
};

export const getFilterCount = (activeFilters: ActiveFilters, filterType: BaseFilterType) => {
  const filterTypeProperty = camelCase(filterType);

  switch (filterType) {
    case ExplorerDataTypeEnum.WORK_LOCATION:
      return (
        (activeFilters.country?.length || 0) +
        (activeFilters.city?.length || 0) +
        (activeFilters.workLocation?.length || 0)
      );
    case ExplorerDataTypeEnum.ASPIRATION_CITY:
      return (
        (activeFilters.aspirationCountry?.length || 0) +
        (activeFilters.aspirationCity?.length || 0)
      );
    case ExplorerDataTypeEnum.ASPIRATION_DEPARTMENT:
    case ExplorerDataTypeEnum.ASPIRATION_DIVISION:
    case ExplorerDataTypeEnum.ASPIRATION_PRODUCT:
    case ExplorerDataTypeEnum.ASPIRATION_TEAM:
    case ExplorerDataTypeEnum.ASPIRATION_SKILL:
      return activeFilters[FILTER_QUERY_PARAM_KEY[filterType]]?.length || 0;
    default:
      return activeFilters[filterTypeProperty]?.length || 0;
  }
};

export const getParentFilterType = (filterType: FilterType): FilterType => {
  switch (filterType) {
    case WorkLocationSubdivisionEnum.WORK_LOCATION:
      return WorkLocationSubdivisionEnum.CITY;
    case WorkLocationSubdivisionEnum.CITY:
      return WorkLocationSubdivisionEnum.COUNTRY;
    default:
      return filterType;
  }
};

export const transformActiveFiltersMapToObject = (
  activeFilterMap: ActiveFilterMap = {},
  excludeFilterTypes: FilterType[] = [],
) => {
  const result: { [key: string]: string } = {};

  for (const filterKey in activeFilterMap) {
    if (has(activeFilterMap, filterKey)) {
      const selectedFilter = activeFilterMap[filterKey];
      const { filterType, id, excludeChildren } = selectedFilter;

      if (excludeFilterTypes.includes(filterType)) {
        continue;
      }

      if (excludeChildren) {
        if (!result['excludeChildren']) {
          result['excludeChildren'] = id;
        } else {
          result['excludeChildren'] += `,${id}`;
        }
      }

      const key = camelCase(filterType.toLowerCase());
      if (!result[key]) {
        result[key] = id;
      } else {
        result[key] += `,${id}`;
      }
    }
  }

  return result;
};
