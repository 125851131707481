import { pick, get } from 'lodash';

import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { updateCompanyApi } from 'features/companies/companies.service';

export const useUpdateCompany = (options: UseMutationOptions = {}) => {
  const mutationResponse = useMutation(updateCompanyApi, {
    showSuccessSnackbar: true,
    transformInput: (companyForm: any) => {
      const company = pick(companyForm, [
        'id',
        'name',
        'website',
        'description',
        'supportChannelUrl',
        'followUsPageUrl',
        'subUrl',
        'idpType',
        'idpDetails',
        'privacyPolicyUrl',
      ]);
      return {
        ...company,
        industryId: get(companyForm, 'industry.id'),
      };
    },
    ...options,
  });
  return mutationResponse;
};
