import React from 'react';

import MUIAvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';

const AvatarGroup: React.FC<AvatarGroupProps> = (props) => {
  const { children, ...avatarGroupProps } = props;

  return <MUIAvatarGroup {...avatarGroupProps}>{children}</MUIAvatarGroup>;
};

export default AvatarGroup;
