import React from 'react';
import { times } from 'lodash';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';

import { Stack } from 'common/components/material';
import Skeleton from 'common/components/material/Skeleton';
import { INITIAL_RELATED_PAGE_SIZE } from '../related-pages.constants';
import LinkTileWithAvatarLoader from 'common/components/link-tile-with-avatar/link-tile-with-avatar-loader.component';

const RelatedPagesSkeleton: React.FC<{ size?: number }> = ({ size }) => {
  const { palette, typography } = useTheme();
  return (
    <Stack spacing={1} py={1}>
      {times(size || INITIAL_RELATED_PAGE_SIZE, (index) => {
        return (
          <Stack spacing={0.5} key={index}>
            <LinkTileWithAvatarLoader />
            <Stack pl={7} direction="row" alignItems="center">
              <Skeleton variant="circular" height="1.5rem" width="1.5rem" />
              <CircleIcon
                sx={{
                  height: '3px',
                  width: '3px',
                  lineHeight: 1,
                  verticalAlign: 'middle',
                  marginX: '0.5rem',
                  color: palette.Text.Caption,
                }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: typography.caption.fontSize }}
                width="6rem"
              />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default RelatedPagesSkeleton;
