import React from 'react';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';

import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import MenuItemButton from './MenuItemButton';

const CreateOrganizationMenuItem: React.FC = () => {
  const { goTo } = useRouter();

  const handleClick = () => {
    goTo(AppRoutesEnum.CREATE_ORGANIZATION, {}, { state: { resetHistoryIndex: true } });
  };

  return (
    <MenuItemButton onClick={handleClick} label="Create Organization">
      <AddBusinessOutlinedIcon />
    </MenuItemButton>
  );
};

export default CreateOrganizationMenuItem;
