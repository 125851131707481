import React from 'react';
import MUISwitch, { SwitchProps } from '@mui/material/Switch/';

const Switch: React.FC<SwitchProps> = (props) => {
  const { children, ...switchProps } = props;

  return <MUISwitch {...switchProps}>{children}</MUISwitch>;
};

export default Switch;
