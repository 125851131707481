import React from 'react';
import { styled } from '@mui/material/styles';

import { Grow, Stack } from 'common/components/material';
import Typography from 'common/components/material/Typography';
import { DrawerHeader } from 'common/components/material/Drawer';
import CloseButton from 'common/components/navigation/CloseButton';
import { useDrawer, useSession } from 'common/hooks';
import { CXAvatar } from 'common/components/cx-avatar/cx-avatar';

import SkillActionOverviewCard from './SkillActionOverviewCard';
import SkillActionPraiseCard from './SkillActionPraiseCard';
import SkillActionRateCard from './SkillActionRateCard';
import SkillActionSearchCard from './SkillActionSearchCard';
import { DrawerIdEnum } from '../../enums/drawer-id.enum';
import { Person, useGetEndorsedSkill } from 'features/profiles';
import { Skill, SkillActionDrawerProps } from 'features/skills';

export const INIT_PROPS: SkillActionDrawerProps = {
  skill: {} as Skill,
  person: {} as Person,
};

const DrawerTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: 700,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

const SkillActionDrawer: React.FC<SkillActionDrawerProps> = ({ skill, person }) => {
  const { profile: loggedInProfile } = useSession();
  const { closeDrawer } = useDrawer();

  const fullName = person?.fullName;

  const isLoggedInUser = person?.id === loggedInProfile?.id;
  let { data, isLoading } = useGetEndorsedSkill(person?.id ?? '', skill?.id, {
    enabled: !!(person?.id && skill?.id),
  });

  let skillInfo = data?.[0];
  //TODO: create a generic API to get one skill info and remove below if block
  if (skill && !skillInfo?.skill) {
    skillInfo = {
      ...skillInfo,
      skill,
    };
  }

  const closeSkillActionDrawer = () => {
    closeDrawer(DrawerIdEnum.SKILLS_ACTION);
  };

  return (
    <>
      <DrawerHeader
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row" alignItems="center" pl={1}>
          <CXAvatar src={person?.photo} alt={person?.fullName} />
          <DrawerTitle ml={2}>{fullName}</DrawerTitle>
        </Stack>
        <CloseButton clickHandler={closeSkillActionDrawer}></CloseButton>
      </DrawerHeader>
      {person && skillInfo?.skill?.id && (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
          mx={1}
          flex={1}
        >
          <Stack spacing={2}>
            <SkillActionOverviewCard
              endorsedSkill={skillInfo}
              endorsee={person}
              isLoading={isLoading}
            />
            <Grow
              style={{ transformOrigin: 'top center' }}
              in={skillInfo?.isEndorsedByLoggedInUser}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <SkillActionRateCard
                  endorsedSkill={skillInfo}
                  endorsee={person}
                  isLoggedInUser={isLoggedInUser}
                />
              </div>
            </Grow>
            <Grow
              style={{ transformOrigin: 'top center' }}
              in={!isLoggedInUser && !!skillInfo?.loggedInUserRating}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <SkillActionPraiseCard skill={skillInfo} person={person} />
              </div>
            </Grow>
          </Stack>

          <Stack pb={3}>
            <SkillActionSearchCard skill={skill} toggleDrawer={closeSkillActionDrawer} />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default SkillActionDrawer;
