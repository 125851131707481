import React, { ChangeEvent, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Stack } from 'common/components/material';
import { CXAvatarUploaderProps } from './cx-avatar.types';
import { CXAvatar } from './cx-avatar';
import {
  CXAvatarHiddenFileInput,
  CXAvatarLink,
  CXAvatarUploadAddIcon,
  CXAvatarUploadDeleteIcon,
  StyledCXAvatar,
} from './cx-avatar.styles';
import { useEvents } from 'common/hooks';

export const CXAvatarUploader: React.FC<CXAvatarUploaderProps> = ({
  label,
  onChange,
  src,
  isDisabled,
  readOnly = true,
  showLabel,
  size,
  ...avatarProps
}) => {
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const formContext = useFormContext();

  useEvents('OpenPhotoUploader', () => openFileBrowser());

  if (readOnly) {
    return (
      <Stack alignItems="center" spacing={2}>
        <CXAvatar {...avatarProps} src={src} size={size || 'xlarge'} />
      </Stack>
    );
  }

  const formRegister = formContext.register('file');

  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    formRegister.onChange(event);
    const files = event.target.files;
    const file = files?.[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const result = reader.result as string;
      onChange && onChange(result);
    };
  };

  const openFileBrowser = () => {
    if (!isDisabled) {
      imageInputRef?.current?.click();
    }
  };

  const deleteExistingFile = () => {
    if (!isDisabled && imageInputRef?.current) {
      imageInputRef.current.value = '';
      onChange && onChange('');
    }
  };

  CXAvatarUploader.defaultProps = {
    size: 'large',
  };

  let avatarOnClickHandler;
  let avatarActionIcon;
  if (!src) {
    avatarOnClickHandler = openFileBrowser;
    avatarActionIcon = <CXAvatarUploadAddIcon className="action-icon add-icon" />;
  } else {
    avatarOnClickHandler = deleteExistingFile;
    avatarActionIcon = <CXAvatarUploadDeleteIcon className="action-icon delete-icon" />;
  }

  return (
    <Stack alignItems="center" spacing={2}>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <StyledCXAvatar
          {...avatarProps}
          size={size}
          src={src}
          onClick={avatarOnClickHandler}
        />
        {avatarActionIcon}
      </Stack>
      {label && (
        <CXAvatarLink
          variant="button2"
          clickHandler={openFileBrowser}
          isDisabled={isDisabled}
        >
          {label}
        </CXAvatarLink>
      )}
      <CXAvatarHiddenFileInput
        type="file"
        accept="image/*"
        {...formRegister}
        ref={(instance: any) => {
          formRegister.ref(instance);
          imageInputRef.current = instance;
        }}
        onChange={onImageChange}
      />
    </Stack>
  );
};
