import LinkIcon from '@mui/icons-material/Link';
import React from 'react';
import { useTheme } from '@mui/material/styles';

import QuickLinkLabelImage from 'common/components/image/QuickLinkLabelImage';
import { Stack, Tooltip, Typography } from 'common/components/material';

import { isSocialLink, splitLinkLabelChar } from '../quick-links.utils';
import { LabelTextSpan } from '../styles';
import { QuickLinkLabelProps } from '../types';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';

const QuickLinkLabel: React.FC<QuickLinkLabelProps> = ({
  rawLabel,
  subLabel,
  showDescription = true,
}) => {
  /* Text-Only Example (Social Link Included)
    rawLabel = "LinkedIn"
    prefixChar = "L"
    label = "LinkedIn"
    hasPrefixCharEmoji = false

    Emoji Example
    rawLabel = "🎨 Design"
    prefixChar = "🎨"
    label = "Design"
    hasPrefixCharEmoji = true

  */
  const { prefixChar, label, hasPrefixCharEmoji } = splitLinkLabelChar(rawLabel);
  const isSocialLinkLabel = isSocialLink(rawLabel);
  const { palette } = useTheme();

  const socialPrefixChar = (
    <Stack alignItems="center" justifyContent="center" width="1.2rem">
      <QuickLinkLabelImage label={label} style={{ transform: 'translate(0%, 15%)' }} />
    </Stack>
  );

  const commonPrefixChar = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: palette.Icons.Secondary.BgDefault,
        borderRadius: '0.4rem',
        height: '1.2rem',
        width: '1.2rem',
      }}
    >
      <LinkIcon sx={{ color: palette.Icons.Secondary.IconFill, fontSize: '0.7rem' }} />
    </Stack>
  );

  const defaultPrefixChar = isSocialLinkLabel ? socialPrefixChar : commonPrefixChar;

  const tooltipContent = (
    <>
      <Typography variant="caption" display="block">
        {label}
      </Typography>
      <Typography variant="caption2" display="block">
        {subLabel}
      </Typography>
    </>
  );

  return (
    <Stack display="flex" justifyContent="center" flexDirection="row">
      <Stack justifyContent="center">
        <LabelTextSpan>{!hasPrefixCharEmoji ? defaultPrefixChar : prefixChar}</LabelTextSpan>
      </Stack>
      <Tooltip title={tooltipContent} enterDelay={500}>
        <Stack
          flexDirection={showDescription ? 'column' : 'row'}
          alignItems={showDescription ? 'initial' : 'center'}
        >
          <EllipsisText
            variant="body3"
            fontSize="0.75rem"
            className="targetLink"
            fontWeight={500}
            lineclamp={1}
            showTooltip={false}
          >
            {label}
          </EllipsisText>
          {showDescription && (
            <EllipsisText
              lineclamp={1}
              variant="body3"
              fontSize="0.75rem"
              color={palette.Text.Caption}
              showTooltip={false}
            >
              {subLabel}
            </EllipsisText>
          )}
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default QuickLinkLabel;
