import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { differenceWith, isEqual, shuffle } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { Paper, Stack, Typography } from 'common/components/material';
import { FieldName } from 'common/types/Form.type';
import { Skill } from 'features/skills';
import { ReactComponent as DeleteChipIcon } from 'common/svg-icons/cancelIcon.svg';

import SkillChip from 'features/org-root/components/SkillChip';
import { RecommededSkillsProps, useGetRecommendedSkills } from 'features/skills';

const MAX_VISIBLE_SKILLS = 3;
const LABEL = 'Recommended';

const RecommendedSkills: React.FC<RecommededSkillsProps> = ({
  name,
  label = LABEL,
  disabled,
  onChange,
  selectedSkills = [],
  maxVisibleSkills = MAX_VISIBLE_SKILLS,
}) => {
  const { palette } = useTheme();
  const [displayedSkills, setDisplayedSkills] = useState<Array<Skill>>([]);
  const [removedSkillRecommendations, setRemovedSkillRecommendations] = useState<Skill[]>([]);
  const [shuffledSkills, setShuffledSkills] = useState<Skill[]>([]);

  const { data: skills, isLoading: isRecommendedSkillsLoading } = useGetRecommendedSkills();
  // Only relevant if component is inside a FormProvider
  const { setValue, trigger } = useFormContext() || {};

  useEffect(() => {
    if (!shuffledSkills.length && !isRecommendedSkillsLoading) {
      setShuffledSkills(shuffle(skills));
    }
  }, [skills, shuffledSkills, setShuffledSkills, isRecommendedSkillsLoading]);

  useEffect(() => {
    const skillsToHide = [...selectedSkills, ...removedSkillRecommendations];
    const skillsToDisplay = differenceWith(shuffledSkills, skillsToHide, isEqual);

    if (
      differenceWith(skillsToDisplay, displayedSkills, isEqual).length !== 0 ||
      differenceWith(displayedSkills, skillsToDisplay, isEqual).length !== 0
    ) {
      setDisplayedSkills(skillsToDisplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkills, shuffledSkills, removedSkillRecommendations, displayedSkills]);

  const handleChange = (value: Skill[]) => {
    if (name) {
      setValue(name as FieldName, value, { shouldDirty: true });
      trigger(name as FieldName);
    }
    onChange(value);
  };

  const handleRemoveRecommendation = (skill: Skill) => {
    setRemovedSkillRecommendations((removedSkillRecommendations) => [
      ...removedSkillRecommendations,
      skill,
    ]);
  };

  return (
    <Stack spacing={1}>
      {!!displayedSkills.length && (
        <Typography pl={1} variant="caption" color={palette.Text.Caption}>
          {label}
        </Typography>
      )}
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          boxShadow: 'none',
          backgroundColor: palette.Container.Bg,
        }}
      >
        {displayedSkills.slice(0, maxVisibleSkills).map((skill) => (
          <SkillChip
            key={skill.id}
            skill={skill}
            sx={{
              margin: 0.5,
              color: palette.SelectionChips.Secondary.LabelAndIconDefault,
              fontWeight: 400,
              cursor: 'pointer',
            }}
            variant="outlined"
            disabled={disabled}
            deleteIcon={<DeleteChipIcon onMouseDown={(e) => e.stopPropagation()} />}
            onDelete={() => handleRemoveRecommendation(skill)}
            onMouseDown={() => {
              handleChange([...selectedSkills, skill]);
            }}
          />
        ))}
      </Paper>
    </Stack>
  );
};

export default RecommendedSkills;
