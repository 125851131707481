import { FloatingMenuItem } from 'common/components/core/FloatingMenu';
import { FilterViewTabEnum } from 'common/enum/filter-view-tab.enum';

export const FEEDS_SKELETON_LENGTH = 3;
export const DEFAULT_FEED_CARD_OPTIONS: FloatingMenuItem[] = [
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
];

export const FEED_CARD_OPTIONS_REMOVE_BOB: FloatingMenuItem[] = [
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'remove_from_bob',
    label: 'Remove from your Best of Best',
  },
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
];

export const FEED_CARD_OPTIONS_WITH_BOB: FloatingMenuItem[] = [
  {
    value: 'edit',
    label: 'Edit',
  },
  {
    value: 'add to bob',
    label: 'Add to my Best of Best',
  },
  {
    value: 'delete',
    label: 'Delete',
    palettePath: 'Failure',
  },
];

export const FEED_CARD_OPTIONS_FOR_RESOURCES: FloatingMenuItem[] = [
  {
    value: 'find-resources',
    label: 'Find Resources',
  },
];

export const FEED_FOOTER_TEXT: { [key: string]: string } = {
  PRAISE: 'Sent by',
  PROJECT: 'Created by',
  REVIEW: 'Reviewer',
  OPPORTUNITY: 'Created by',
};

export const FEED_HEADER_SUBSTRING = {
  PRAISE: 'received praise',
  /* Last minute changes for Show & Tell */
  PROJECT: `added a Win`,
  REVIEW: '',
  OPPORTUNITY: 'created an opportunity',
  COMPANY_UPDATE: 'posted an update',
  NEW_JOINER: 'joined',
  ORGANIZATION_EVENT: 'scheduled an event',
  POST: '',
  WIN_LIST: ``,
};

export const FEED_TAB_CONFIG = [
  {
    value: FilterViewTabEnum.FOLLOWING,
    label: 'Following',
  },
  {
    value: FilterViewTabEnum.ALL,
    label: 'All Activity',
  },
];
