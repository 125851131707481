import React from 'react';
import AddIcon from '@mui/icons-material/Add';

import Button, { ButtonProps } from 'common/components/material/Button';

export const AddCityButton: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <Button
      btntype="label"
      size="small"
      onClick={onClick}
      startIcon={<AddIcon />}
      {...props}
    >
      Add City
    </Button>
  );
};
