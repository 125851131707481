import React from 'react';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pick } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { PeopleSelector, StyledForm } from 'common/components/input';
import { Box, Stack, Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import { useDeferredState, useDrawer, useForm, useSession } from 'common/hooks';
import { Skill } from 'features/skills';

import { DrawerIdEnum } from '../../enums/drawer-id.enum';
import ProjectSelector from '../Project/ProjectSelector';
import PraiseFormDesc from './PraiseFormDesc';
import PraiseFormSkills from './PraiseFormSkills';
import {
  PRAISE_DRAWER_HELPER,
  PRAISE_LIMIT,
  PRIASE_DRAWER_TITLE,
  PRIASE_EDIT_DRAWER_TITLE,
  Praise,
  PraiseDrawerProps,
  PraiseForm,
  useCreatePraise,
  useUpdatePraise,
} from 'features/praise';
import { Project } from 'features/projects';
import { Person } from 'features/profiles';
import { Tag } from 'features/tags/types';
import TagSelector from 'features/tags/components/tag-selector.component';
import { PraiseHeadlineField } from 'features/praise/components/praise-headline-field.component';
import { SKILLS_MAX_LIMIT } from 'common/constants/common.constants';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { usePraiseFormSchema } from 'features/praise/hooks/usePraiseFormSchema';
import VisibileToSelector from 'common/components/role-access/VisibleToSelector';
import { ContentType } from 'common/enum/ContentType.enum';
import VisibleToOrganizationText from '../VisibleToOrganizationText';

export const INIT_PROPS: PraiseDrawerProps = {
  praise: {} as Praise,
};

const PraiseDrawer: React.FC<PraiseDrawerProps> = ({ praise = {} as Praise }) => {
  const { profile: loggedInProfile, currentOrganization } = useSession();
  const { closeDrawer } = useDrawer();
  const { palette } = useTheme();
  const praiseFormSchema = usePraiseFormSchema();
  const company = currentOrganization();
  const initialReceiver = praise?.receiver ? [praise?.receiver] : [];
  const initialProject = praise?.relatedProject
    ? pick(praise?.relatedProject, ['id', 'title'])
    : undefined;
  const initialHeadline = praise?.headline;
  const hasReceiver = !!praise?.receiver?.id;
  const [tags, setTags] = useDeferredState<Tag[]>(praise?.tags);
  const [receiver, setReceiver] = useDeferredState<Person[]>(initialReceiver);
  const [project, setProject] = useDeferredState<Partial<Project> | null>(initialProject);
  const [praiseDesc, setPraiseDesc] = useDeferredState<string | undefined>(
    praise?.description,
  );
  const [skills, setSkills] = useDeferredState<Skill[]>(praise?.skills);
  const { submit: createPraise, isLoading: isCreatingPraise } = useCreatePraise();
  const { submit: updatePraise, isLoading: isUpdatingPraise } = useUpdatePraise();

  const form = useForm<PraiseForm>({
    defaultValues: {
      ...praise,
      visibleTo: praise?.visibleTo || [company],
      headline: initialHeadline,
      receiver: initialReceiver,
      relatedProject: initialProject,
    },
    mode: 'onChange',
    resolver: yupResolver(praiseFormSchema),
  });
  const {
    formState: { isDirty, isValid },
    getValues,
    resetField,
    clearErrors,
    trigger,
  } = form;

  const isLoading = isCreatingPraise || isUpdatingPraise;
  const shouldDisableActions = !isDirty || isLoading;

  const onDiscard = () => {
    closeDrawer(DrawerIdEnum.PRAISE);
  };

  const onSubmit = () => {
    const data = getValues();
    if (praise?.id) {
      updatePraise(data);
    } else {
      createPraise(data);
    }
    onDiscard();
  };

  const header = (
    <DrawerHeader
      sx={{
        display: 'block',
        borderBottom: `1px solid ${palette.Divider}`,
        padding: 2,
      }}
    >
      <Typography variant="h2" fontWeight="bold" color={palette.Text.Headline}>
        {praise?.id ? PRIASE_EDIT_DRAWER_TITLE : PRIASE_DRAWER_TITLE}
      </Typography>
      <VisibleToOrganizationText visibleTo={getValues().visibleTo} />
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isLoading}
      isPrimaryActionDisabled={!isValid || shouldDisableActions}
      secondaryAction={onDiscard}
      primaryAction={onSubmit}
    />
  );

  return (
    <FormDrawer header={header} footer={footer}>
      <FormProvider {...form}>
        <StyledForm noValidate>
          <Stack spacing={3} m={2} pb={2}>
            <PeopleSelector
              name="receiver"
              label="Who would you like to praise?"
              autoFocus
              selectedProfiles={receiver || []}
              readOnly={hasReceiver}
              formFieldProps={{ autoFocus: !hasReceiver }}
              excludePeople={loggedInProfile ? [loggedInProfile.id] : []}
              onChange={(value) => {
                setReceiver(value);
                // Automatically remove relatedProject when the person input changes
                if (!!project) {
                  setProject(null);
                  resetField('relatedProject', { keepDirty: true });
                }
              }}
              limit={PRAISE_LIMIT}
              sx={{ '.MuiChip-root.Mui-disabled': { opacity: 1 } }}
            />
            <ProjectSelector
              name="relatedProject"
              label="Project or Initiative Name"
              personId={receiver?.[0]?.id || ''}
              project={project}
              onChange={setProject}
            />
            <PraiseHeadlineField />
            <PraiseFormDesc
              value={praiseDesc}
              onChange={(e) => {
                clearErrors('headline');
                setPraiseDesc(e);
              }}
            />
            <FeatureGuard features={[{ feature: FeaturesEnum.SKILLS }]}>
              <PraiseFormSkills
                value={skills}
                onChange={setSkills}
                maxSkillsCount={SKILLS_MAX_LIMIT}
              />
            </FeatureGuard>
            <Box>
              <Box mt={!praise?.id ? -2.5 : 0}>
                {!!praise?.id && (
                  <FeatureGuard features={[{ feature: FeaturesEnum.ALL_STATIC_DATA_PAGES }]}>
                    <TagSelector value={tags} onChange={setTags} sx={{ pb: 1 }} />
                  </FeatureGuard>
                )}
              </Box>
              <Typography variant="body4" color={palette.Text.Caption}>
                <b>Note: </b>
                {PRAISE_DRAWER_HELPER}
              </Typography>
            </Box>
            <VisibileToSelector
              name="visibleTo"
              label="Visible to"
              contentType={ContentType.PRAISES}
              value={getValues().visibleTo}
              onChange={() => {
                trigger('visibleTo');
              }}
            />
          </Stack>
        </StyledForm>
      </FormProvider>
    </FormDrawer>
  );
};

export default PraiseDrawer;
