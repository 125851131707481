import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { ElevationScroll, ToggleVisibilityContainer } from 'common/components/container';
import ConnectXLogo from 'common/components/image/ConnectXLogo';
import { Grid, Stack } from 'common/components/material';
import {
  BackButton,
  NavigationIconButton,
  OrganizationDropdown,
  SearchBar,
} from 'common/components/navigation';
import { useDrawer, useSession } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import ApplicationBar from './ApplicationBar';
import { isInExplorePage } from 'common/utils/appBar.utils';

type MobileApplicationBarProps = {
  showAllMenuItems?: boolean;
  isLoading?: boolean;
};

const MobileApplicationBar: React.FC<MobileApplicationBarProps> = ({
  showAllMenuItems = true,
  isLoading,
}) => {
  const { openDrawer } = useDrawer();
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const canShowAllMenuItems = showAllMenuItems && !isLoading;
  // In explore page we want to hide search bar instead of destroy it so it
  // retains the filter state that is initialized by the FiltersUpdated event.
  const keepSearchBarActive = isInExplorePage(window.location.pathname) || isSearchBarVisible;

  const [searchParams] = useSearchParams();
  const urlQuery = searchParams.get('query') || '';

  const { profile } = useSession();
  const showOrganizationDropdown = profile?.organizationProfiles.length > 1;

  useEffect(() => {
    if (urlQuery) setIsSearchBarVisible(true);
    return () => {
      setIsSearchBarVisible(false);
    };
  }, [urlQuery]);

  const toggleSearchBar = () => {
    setIsSearchBarVisible((isSearchBarVisible) => !isSearchBarVisible);
  };

  const toggleMenuDrawer = () => {
    if (isLoading) {
      return;
    }
    openDrawer(DrawerIdEnum.MENU, {});
  };

  const toggleSettingsDrawer = () => {
    if (isLoading) {
      return;
    }
    openDrawer(DrawerIdEnum.SETTINGS, {});
  };

  const closeSearchBar = () => {
    if (!urlQuery) setIsSearchBarVisible(false);
  };

  const handleBackButton = () => {
    setIsSearchBarVisible(false);
  };

  return (
    <>
      <ElevationScroll>
        <ApplicationBar>
          <Grid container alignItems="center" spacing={2}>
            {isSearchBarVisible && (
              <Grid item xs={1}>
                <BackButton size="small" clickHandler={handleBackButton} />
              </Grid>
            )}
            <Grid container item xs={11}>
              {!canShowAllMenuItems && <ConnectXLogo />}
              {!isSearchBarVisible && canShowAllMenuItems && (
                <Grid container item justifyContent="space-between">
                  <Grid item xs={10}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <NavigationIconButton onClick={toggleMenuDrawer} tooltip="Menu">
                        <MenuOutlinedIcon />
                      </NavigationIconButton>
                      {showOrganizationDropdown ? (
                        <>
                          <ConnectXLogo />
                          <OrganizationDropdown />
                        </>
                      ) : (
                        <Stack display="block" width="100%" textAlign="center">
                          <ConnectXLogo device="DESKTOP" />
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  <Stack direction="row" spacing={1}>
                    <NavigationIconButton
                      aria-label="Search"
                      onClick={toggleSearchBar}
                      tooltip="Search"
                      id="global-search-icon"
                    >
                      <SearchIcon />
                    </NavigationIconButton>
                  </Stack>
                </Grid>
              )}
              {keepSearchBarActive && (
                <ToggleVisibilityContainer
                  isVisible={isSearchBarVisible}
                  display={isSearchBarVisible ? 'block' : 'none'}
                  flex={1}
                >
                  <SearchBar autoFocus placeholder="Search..." onClose={closeSearchBar} />
                </ToggleVisibilityContainer>
              )}
            </Grid>
            {!isSearchBarVisible && (
              <Grid item xs={1}>
                <NavigationIconButton onClick={toggleSettingsDrawer} tooltip="Settings">
                  <SettingsOutlinedIcon />
                </NavigationIconButton>
              </Grid>
            )}
          </Grid>
        </ApplicationBar>
      </ElevationScroll>
    </>
  );
};

export default MobileApplicationBar;
