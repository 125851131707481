import { useEffect, useState } from 'react';

const useIntersectionObserver = (root = null, rootMargin = '0px', threshold = 0.75) => {
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useState<IntersectionObserverEntry>();
  const [node, setNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersectionObserverEntry(entry),
      {
        root,
        rootMargin,
        threshold,
      },
    );

    if (node) {
      observer.observe(node);
    }

    return () => observer.disconnect();
  }, [node, root, rootMargin, threshold]);

  return { setNode, intersectionObserverEntry };
};
export default useIntersectionObserver;
