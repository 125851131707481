import { styled } from '@mui/material/styles';
import React from 'react';

import { Tooltip } from 'common/components/material';
import IconButton, { IconButtonProps } from 'common/components/material/IconButton';

type NavigationIconButtonProps = IconButtonProps & {
  isActive?: boolean;
  onClick?: () => void;
  tooltip?: string;
};

type StyledIconButtonProp = {
  isActive?: boolean;
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => {
    return prop !== 'isActive';
  },
})<StyledIconButtonProp>(({ theme, isActive }) => {
  const color = isActive
    ? theme.palette.Icons.Tertiary.IconFillPressed
    : theme.palette.Icons.Tertiary.IconFillDefault;
  return {
    color,
    backgroundColor: 'transparent',
  };
});

const NavigationIconButton: React.FC<NavigationIconButtonProps> = ({
  isActive = false,
  onClick,
  children,
  tooltip = '',
  sx,
  ...props
}) => {
  return (
    <StyledIconButton
      isActive={isActive}
      onClick={onClick}
      disableFocusRipple={true}
      disableRipple={true}
      color="inherit"
      edge="start"
      sx={sx}
      {...props}
    >
      <Tooltip title={tooltip}>
        {/*
          According to Tooltip docs here, https://mui.com/material-ui/react-tooltip/#custom-child-element
          if we pass custom component as children, we need every custom component with "forwardRef". 
          Workaround:
          Wrapping children with div prevent getting forwardRef error triggered by MUI Tooltip component
        */}
        <div style={{ display: 'flex' }}>{children}</div>
      </Tooltip>
    </StyledIconButton>
  );
};

export default NavigationIconButton;
