import { orderBy } from 'lodash';
import {
  OrganizationalStaticDataTypeEnum,
  OrganizationalTagPagesTypeEnum,
} from 'features/static-data/static-data.enum';
import { Tag } from '../types';
import { RelatedPage } from 'features/related-pages/types/related-page.type';
import { WorkLocation } from 'features/static-data';

const TAG_TYPE_LABEL_CONFIG = {
  [OrganizationalTagPagesTypeEnum.DEPARTMENT]: 'DEPARTMENT',
  [OrganizationalTagPagesTypeEnum.DIVISION]: 'DIVISION',
  [OrganizationalTagPagesTypeEnum.PRODUCT]: 'PRODUCT',
  [OrganizationalTagPagesTypeEnum.TEAM]: 'TEAM',
  [OrganizationalTagPagesTypeEnum.GROUP]: 'GROUP',
  [OrganizationalTagPagesTypeEnum.WORK_LOCATION]: 'WORK LOCATION',
  [OrganizationalTagPagesTypeEnum.CITY]: 'CITY',
  [OrganizationalTagPagesTypeEnum.COUNTRY]: 'COUNTRY',
};

export const transformTags = (tags: Tag[]) => {
  if (!tags.length) {
    return [];
  }
  return orderBy(
    tags.map((tag) => {
      return {
        ...tag,
        typeLabel: TAG_TYPE_LABEL_CONFIG[tag.type as OrganizationalTagPagesTypeEnum],
      };
    }),
    ['typeLabel'],
  );
};

export const convertRelatedPagesToTags = (relatedPages: RelatedPage[]): Tag[] => {
  if (!relatedPages.length) {
    return [];
  }
  return relatedPages.map((relatedPage) => {
    const { id, name, completeName, type, photo } = relatedPage;
    return {
      id,
      name,
      displayName: getTagDisplayName(relatedPage),
      // TODO: return complete name from related page
      completeName: completeName || name,
      type,
      typeLabel: TAG_TYPE_LABEL_CONFIG[type as OrganizationalTagPagesTypeEnum],
      icon: photo,
    };
  });
};

export const getTagDisplayName = (tag: Tag): string => {
  return tag.type === OrganizationalStaticDataTypeEnum.GROUP ? tag.completeName : tag.name;
};

export const convertWorkLocationToTag = (workLocation: WorkLocation): Tag => {
  const { id, name, photo, city, country } = workLocation;
  const completeName = `${country.name} - ${city.name} - ${name}`;

  return {
    id,
    name,
    displayName: completeName,
    completeName,
    type: OrganizationalTagPagesTypeEnum.WORK_LOCATION,
    icon: photo,
    autoSelected: true,
  };
};
