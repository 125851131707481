import { InputBaseProps } from '@mui/material/InputBase';
import React from 'react';
import { IMaskInput } from 'react-imask';

type CustomProps = Omit<InputBaseProps, 'onChange'> & {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask?: string;
  definitions?: any;
};

const TextFieldMask = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, name, mask, definitions, ...other } = props;

  // Default mask & definitions for phone number
  return (
    <IMaskInput
      mask={mask || '+####################'}
      definitions={definitions || { '#': /[0-9 ()-]/ }}
      inputRef={ref as React.RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(value: any) => onChange({ target: { name, value } })}
      // React is throwing the following error message:
      //
      //     Warning: You provided a `value` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultValue`. Otherwise, set either `onChange` or `readOnly`.

      // I tried to extract `value` from `other` and inject it as `defaultValue`;
      // that made the warning message disappear, however, it also made
      // it impossible to preload the `IMaskInput` component with an
      // existing value (that's because the component only knows about `value`,
      // and not `defaultValue`).
      //
      // What we are left with is injecting a dummy `onChange` handler...
      onChange={() => false}
      overwrite
      {...other}
    />
  );
});

export default TextFieldMask;
