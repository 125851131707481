import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { deleteProjectApi } from '../services/projects.service';

export const useDeleteProject = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter('ProjectDeleted');

  return useMutation(deleteProjectApi, {
    showSuccessSnackbar: true,
    onSuccess: ({ data }) => {
      // This is done to prevent displaying empty feed for WIN list if any WIN is deleted, as it could be possible that this WIN was user's last WIN, and it resulted in deleting the WinList feed only.
      emitWithSettledAfter(3000, data);
    },
    ...options,
  });
};
