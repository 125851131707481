import React from 'react';
import { Stack, Typography } from 'common/components/material';
import { StackProps } from 'common/components/material/Stack';
import { useTheme } from '@mui/material/styles';

type StepContainerProps = {
  heading:React.ReactNode;
  subheading: string;
};

const StepContainer: React.FC<StepContainerProps & StackProps> = ({
  heading,
  subheading,
  children,
  ...props
}) => {
  const { palette } = useTheme();
  return (
    <Stack spacing={2} pb={{ xs: 15, lg: 0 }} {...props}>
      <Stack spacing={1}>
        <Typography variant="h4" color={palette.Text.Headline}>
          {heading}
        </Typography>
        <Typography variant="body3" color={palette.Text.SubHeadline}>
          {subheading}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default StepContainer;
