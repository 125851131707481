import { Theme } from '@mui/material/styles';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const getDefaultChipStyle = (theme: Theme) => {
  return {
    height: '24px',
    color: theme.palette.SkillChip.TextDefault,
    border: `1px solid ${theme.palette.SkillChip.StrokeDefault}`,
    '&:hover.MuiChip-root': {
      backgroundColor: theme.palette.SkillChip.BgDefault,
      boxShadow: AppBoxShadows.shadow2,
    },
    '&:active': {
      borderColor: 'transparent',
      backgroundColor: theme.palette.SkillChip.BgPressed,
      color: theme.palette.SkillChip.TextDefault,
    },
  };
};

export const getSelectedChipStyle = (theme: Theme) => {
  return {
    color: theme.palette.SkillChip.TextActive,
    border: `1px solid ${theme.palette.SkillChip.StrokeDefault}`,
    height: '24px',
    '& .skill-chip-icon': {
      fill: theme.palette.SkillChip.TextActive,
    },
    '&:active': {
      backgroundColor: theme.palette.SkillChip.BgPressed,
      color: theme.palette.SkillChip.TextDefault,
    },
  };
};
