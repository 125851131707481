import { styled } from '@mui/material/styles';

import SearchOffIcon from '@mui/icons-material/SearchOff';

const SearchIconContainer = styled('div')(({ theme }) => {
  return {
    backgroundColor: theme.palette.EmptyPlaceholderIcons.Background,
    color: theme.palette.EmptyPlaceholderIcons.IconFill,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '50%',
  };
});

const EmptySearchIcon: React.FC = () => {
  return (
    <SearchIconContainer>
      <SearchOffIcon />
    </SearchIconContainer>
  );
};

export default EmptySearchIcon;
