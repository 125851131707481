export enum InstancePermissionsResourcesEnum {
  COMPANY_UPDATE = 'company-update',
  PROFILE = 'profile',
  PROJECT = 'project',
  PRAISE = 'praise',
  REVIEW = 'review',
  OPPORTUNITY = 'opportunity',
  QUICK_LINK = 'quick-link',
  STATIC_DATA = 'static-data',
  ORGANIZATION_EVENT = 'organization-event',
  POST = 'post',
  GROUP = 'group',
}
