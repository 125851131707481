import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Button, Stack } from 'common/components/material';

type FormDrawerFooterProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  isSecondaryActionDisabled?: boolean;
  isPrimaryActionDisabled?: boolean;
};

const FormDrawerFooter: React.FC<FormDrawerFooterProps> = ({
  secondaryAction,
  primaryAction,
  secondaryActionLabel = 'Cancel',
  primaryActionLabel = 'Send',
  isSecondaryActionDisabled,
  isPrimaryActionDisabled,
}) => {
  const { palette } = useTheme();
  return (
    <Stack
      direction="row-reverse"
      spacing={2}
      paddingX={3}
      paddingY={{ xs: 2, md: 4 }}
      sx={{
        backgroundColor: palette.Container.Bg,
        borderTop: `1px solid ${palette.Container.Stroke}`,
      }}
      justifyContent={{ sm: 'flex-start', xs: 'space-around' }}
    >
      <Button
        type="submit"
        btntype="primary"
        size="large"
        disabled={isPrimaryActionDisabled}
        onClick={primaryAction}
      >
        {primaryActionLabel}
      </Button>
      <Button
        btntype="tertiary"
        size="large"
        disabled={isSecondaryActionDisabled}
        onClick={secondaryAction}
      >
        {secondaryActionLabel}
      </Button>
    </Stack>
  );
};

export default FormDrawerFooter;
