import axios from 'common/utils/axios';
import { CompanyUpdateFeed } from './types';

const companyUpdatesApi = axios('company-updates');

export const deleteCompanyUpdateApi = ({ id }: CompanyUpdateFeed) => {
  return companyUpdatesApi.delete(id);
};

export const createCompanyUpdateApi = (data: FormData) => {
  return companyUpdatesApi.post('', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateCompanyUpdateApi = (data: FormData) => {
  const id = `${data.get('id')}`;
  return companyUpdatesApi.put(id, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getCompanyUpdatesApi = (params: any) => {
  return companyUpdatesApi.get('search', { params });
};

export const getLatestCompanyUpdatesApi = (tagId?: string) => {
  return companyUpdatesApi.get('latest', { params: { tagId } });
};
