import React from 'react';

import MUIGrow, { GrowProps } from '@mui/material/Grow';

// MUI Transition
const Grow: React.FC<GrowProps> = (props) => {
  const { children, ...growProps } = props;

  return <MUIGrow {...growProps}>{children}</MUIGrow>;
};

export default Grow;
