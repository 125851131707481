import React from 'react';

import LeaveFormConfirmationDrawer from './LeaveFormConfirmationDrawer';
import useGetProfileCount from 'common/hooks/useGetProfileCount';
import { OrganizationalStaticData } from 'features/static-data';
import { FilterSearchParams } from 'features/search';
import { FormOperationsEnum } from 'common/enum/Form.enum';
import _ from 'lodash';

type ActionConfirmationDrawerProps = {
  confirmButtonHandler: () => void;
  toggleDrawer: (e: React.MouseEvent | React.KeyboardEvent) => void;
  staticData?: OrganizationalStaticData;
  param?: string;
  staticDataLabel?: string;
  actionType: FormOperationsEnum;
};

export const INIT_PROPS: ActionConfirmationDrawerProps = {
  confirmButtonHandler: () => {},
  toggleDrawer: () => {},
  actionType: FormOperationsEnum.DELETE,
};

const StaticDataItemActionConfirmationDrawer: React.FC<ActionConfirmationDrawerProps> = ({
  toggleDrawer,
  confirmButtonHandler,
  staticData,
  param,
  staticDataLabel,
  actionType,
}) => {
  const params = {} as FilterSearchParams;

  if (param && staticData) {
    params[param] = [staticData.id];
  }

  const { data, isLoading } = useGetProfileCount(params);
  const currentVisibility = staticData?.isVisible;
  const staticDataType = _.get(staticData, 'type', '').toLowerCase();

  const getDeleteConfirmationText = (userCount: number, isLoading?: boolean) => {
    if (isLoading) {
      return 'Calculating affected users...';
    }
    if (userCount) {
      return `${userCount} user(s) will have their ${staticDataLabel} set to unassigned.`;
    }
    return `This ${staticDataLabel} will be permanently deleted`;
  };

  const getToggleVisibilityConfirmationText = (userCount: number, isLoading?: boolean) => {
    if (isLoading) {
      return 'Calculating affected users...';
    }
    if (userCount) {
      return `${userCount} user(s) will have their ${staticDataType} ${
        currentVisibility ? 'hidden' : 'visible'
      }.`;
    }
    return `${staticDataLabel} and all its decendents will now be ${
      currentVisibility ? 'hidden' : 'visible'
    }.`;
  };

  const getSubtitleText = () => {
    return actionType === FormOperationsEnum.DELETE
      ? getDeleteConfirmationText(data?.count, isLoading)
      : getToggleVisibilityConfirmationText(data?.count, isLoading);
  };

  const headerTitle =
    actionType === FormOperationsEnum.DELETE
      ? `Delete ${staticData?.name}?`
      : `${currentVisibility ? 'Hide' : 'Unhide'} ${staticData?.name} ?`;

  const confirmationButtonText =
    actionType === FormOperationsEnum.DELETE ? 'Delete' : 'Confirm';

  return (
    <LeaveFormConfirmationDrawer
      toggleDrawer={toggleDrawer}
      confirmButtonHandler={confirmButtonHandler}
      headerTitle={headerTitle}
      subTitle={getSubtitleText()}
      confirmButtonText={confirmationButtonText}
      disabled={isLoading}
    />
  );
};

export default StaticDataItemActionConfirmationDrawer;
