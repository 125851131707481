import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react';

import { useHistoryIndex } from 'common/context/hooks/historyIndex';
import { useRouter } from 'common/hooks';
import { ButtonProps } from '../material/Button';
import { NavBtnSizeType, StyledNavButton, StyledNavIcon } from './styles';

const StyledArrowBackIcon = StyledNavIcon(ArrowBackIcon);

type BackButtonProps = {
  clickHandler?: Function;
  size?: NavBtnSizeType;
  route?: string;
};

const BackButton: React.FC<BackButtonProps & ButtonProps> = ({
  clickHandler,
  size = 'medium',
  route,
  ...buttonProps
}) => {
  const { goBack, goTo } = useRouter();
  const { historyIndex } = useHistoryIndex();
  const [isVisible, setIsVisible] = useState(!!clickHandler || !!route);

  const handleClick = () => {
    if (clickHandler) {
      clickHandler();
      return;
    }
    if (route) {
      goTo(route);
      return;
    }

    goBack();
  };

  useEffect(() => {
    if (!clickHandler && !route) {
      setIsVisible(!!historyIndex);
    }
  }, [historyIndex, clickHandler, route]);

  if (!isVisible) {
    return <></>;
  }

  return (
    <StyledNavButton
      size={size}
      startIcon={<StyledArrowBackIcon size={size} />}
      onClick={handleClick}
      variant="outlined"
      {...buttonProps}
    ></StyledNavButton>
  );
};

export default BackButton;
