import colorPalette from './colorPalette';

export const LightColors = {
  Background: colorPalette.GRAY50,
  Container: {
    Bg: colorPalette.WHITE,
    Stroke: colorPalette.GRAY300,
  },
  Card: {
    Bg: colorPalette.WHITE,
    Stroke: colorPalette.GRAY300,
  },
  Divider: colorPalette.GRAY300,
  LinkContainer: {
    BgDefault: colorPalette.WHITE,
    BgHover: colorPalette.PRIMARY50,
    BgPressed: colorPalette.PRIMARY50,
    StrokeDefault: colorPalette.GRAY300,
    StrokeHover: colorPalette.PRIMARY100,
  },
  Buttons: {
    Primary: {
      BgDefault: colorPalette.PRIMARY400,
      BgHover: colorPalette.PRIMARY300,
      BgPressed: colorPalette.PRIMARY500,
      BgDisabled: colorPalette.GRAY100,
      LabelDefault: colorPalette.WHITE,
      LabelDisabled: colorPalette.GRAY400,
    },
    Secondary: {
      BgDefault: colorPalette.PRIMARY50,
      BgHover: colorPalette.PRIMARY100,
      BgPressed: colorPalette.PRIMARY100,
      BgDisabled: colorPalette.GRAY100,
      BgPressedStroke: colorPalette.PRIMARY600,
      SecondaryLabel: colorPalette.PRIMARY400,
      LabelDisabled: colorPalette.GRAY400,
    },
    Tertiary: {
      BgDefault: colorPalette.WHITE,
      BgHover: colorPalette.PRIMARY50,
      BgPressed: colorPalette.PRIMARY100,
      LabelDefault: colorPalette.PRIMARY400,
      LabelDisabled: colorPalette.GRAY400,
      LabelHover: colorPalette.PRIMARY400,
      LabelPressed: colorPalette.PRIMARY450,
    },
    Label: {
      BgDefault: colorPalette.TRANSPARENT,
      BgHover: colorPalette.TRANSPARENT,
      BgPressed: colorPalette.PRIMARY50,
      LabelDefault: colorPalette.PRIMARY400,
      LabelDisabled: colorPalette.GRAY400,
      LabelHover: colorPalette.PRIMARY450,
      LabelPressed: colorPalette.PRIMARY450,
    },
  },
  Text: {
    Headline: colorPalette.BLUEGRAY900,
    Paragraph: colorPalette.BLUEGRAY800,
    SubtleText: colorPalette.GRAY700,
    SubHeadline: colorPalette.BLUEGRAY700,
    Caption: colorPalette.BLUEGRAY700,
    Disabled: colorPalette.GRAY400,
  },
  LinkText: {
    Default: colorPalette.BLUEGRAY900,
    Active: colorPalette.PRIMARY400,
  },
  SkillChip: {
    BgDefault: colorPalette.WHITE,
    StrokeDefault: colorPalette.GRAY300,
    TextDefault: colorPalette.BLUEGRAY600,
    TextActive: colorPalette.PRIMARY400,
    BgPressed: colorPalette.PRIMARY50,
    TextPressed: colorPalette.PRIMARY500,
  },
  StaticDataChip: {
    BgDefault: colorPalette.WHITE,
    StrokeDefault: colorPalette.GRAY300,
    TextDefault: colorPalette.BLUEGRAY600,
    TextActive: colorPalette.PRIMARY400,
    BgPressed: colorPalette.PRIMARY50,
    TextPressed: colorPalette.PRIMARY500,
  },
  Icons: {
    Primary: {
      BgDefault: colorPalette.PRIMARY400,
      BgHover: colorPalette.PRIMARY300,
      BgPressed: colorPalette.PRIMARY400,
      IconFill: colorPalette.WHITE,
    },
    Secondary: {
      BgDefault: colorPalette.PRIMARY50,
      BgHover: colorPalette.PRIMARY50,
      BgPressed: colorPalette.PRIMARY50,
      IconFill: colorPalette.PRIMARY400,
    },
    Tertiary: {
      BgDefault: colorPalette.WHITE,
      IconFillDefault: colorPalette.BLUEGRAY600,
      IconFillHover: colorPalette.PRIMARY300,
      IconFillPressed: colorPalette.PRIMARY400,
      BgHover: colorPalette.PRIMARY50,
      BgPressed: colorPalette.PRIMARY50,
    },
  },
  Badge: {
    BgDefault: colorPalette.PRIMARY50,
    CountDefault: colorPalette.PRIMARY400,
    BgHover: colorPalette.PRIMARY400,
    CountHover: colorPalette.WHITE,
  },
  Stroke: {
    Default: colorPalette.GRAY300,
    Active: colorPalette.PRIMARY400,
  },
  Tabs: {
    Primary: {
      BgDefault: colorPalette.WHITE,
      BgActive: colorPalette.PRIMARY50,
      StrokeDefault: colorPalette.GRAY300,
      StrokeHover: colorPalette.PRIMARY400,
      StrokeActive: colorPalette.PRIMARY400,
      LabelAndIconDefault: colorPalette.BLUEGRAY700,
      LabelAndIconHover: colorPalette.PRIMARY400,
      LabelAndIconClicked: colorPalette.PRIMARY400,
    },
    Secondary: {
      ContainerBg: colorPalette.WHITE,
      ContainerStroke: colorPalette.GRAY300,
      BgDefault: colorPalette.WHITE,
      BgHover: colorPalette.PRIMARY50,
      BgClicked: colorPalette.PRIMARY50,
      LabelDefault: colorPalette.BLUEGRAY600,
      LabelClicked: colorPalette.PRIMARY400,
      LabelHover: colorPalette.PRIMARY400,
    },
  },
  Success: '#099300',
  Failure: '#E80000',
  CoversBackground: {
    ProfileCoverBg: colorPalette.TEAL50,
    StaticDataPageCoverBg: colorPalette.PRIMARY50,
    KeyContactProfileCoverBg: colorPalette.BLUEGRAY100,
  },
  CommentBg: colorPalette.GRAY50,
  ToolTip: {
    Bg: colorPalette.BLUEGRAY900,
    Text: colorPalette.WHITE,
  },
  OrgChart: {
    ContainerBg: colorPalette.WHITE,
    ContainerStrokeDefault: colorPalette.GRAY300,
    ContainerStrokeSelected: colorPalette.PRIMARY400,
    Divider: colorPalette.GRAY300,
    PrimaryCountBg: colorPalette.PRIMARY400,
    SecondaryCountBg: colorPalette.BLUEGRAY600,
    CountFill: colorPalette.WHITE,
    Headline: colorPalette.BLUEGRAY900,
    SubHeadline: colorPalette.BLUEGRAY700,
    BackgroundOne: colorPalette.WHITE,
    BackgroundTwo: colorPalette.GRAY50,
  },
  StatusDropdown: {
    Open: {
      BgDefault: colorPalette.GREEN50,
      BgHover: '#E0FCE2',
      BgClicked: '#BDF5C1',
      LabelAndIconDefault: colorPalette.GREEN400,
    },
    Closed: {
      BgDefault: colorPalette.GRAY100,
      BgHover: '#E9E9E9',
      BgClicked: '#E1E0E0',
      LabelAndIconDefault: colorPalette.BLUEGRAY500,
    },
  },
  SelectionChips: {
    Primary: {
      BgDefault: colorPalette.PRIMARY400,
      BgHover: colorPalette.PRIMARY300,
      BgPressed: colorPalette.PRIMARY500,
      LabelAndIconDefault: colorPalette.WHITE,
    },
    Secondary: {
      BgDefault: colorPalette.WHITE,
      StrokeDefault: colorPalette.GRAY300,
      StrokeHover: colorPalette.PRIMARY400,
      StrokeClicked: colorPalette.PRIMARY400,
      LabelAndIconDefault: colorPalette.GRAY900,
      LabelAndIconHover: colorPalette.PRIMARY400,
    },
  },
  MarkdownEditor: {
    Background: colorPalette.BLUEGRAY100,
    IconDefault: colorPalette.BLUEGRAY800,
    IconHover: colorPalette.PRIMARY300,
    IconClicked: colorPalette.PRIMARY500,
  },
  TextFields: {
    Default: colorPalette.BLUEGRAY500,
    Hover: colorPalette.PRIMARY400,
    Clicked: colorPalette.PRIMARY300,
    Disabled: colorPalette.GRAY200,
    LabelDefault: colorPalette.BLUEGRAY700,
    LabelClicked: colorPalette.PRIMARY400,
    HintText: colorPalette.BLUEGRAY800,
    InputText: colorPalette.BLUEGRAY900,
  },
  SpecialButtons: {
    Delete: {
      BgDefault: colorPalette.WHITE,
      BgHover: '#FAE2E2',
      BgPressed: '#DC7878',
      LabelDefault: colorPalette.GRAY400,
      LabelHover: '#CD0202',
      LabelPressed: '#CD0202',
    },
    Like: {
      BgDefault: colorPalette.WHITE,
      BgHover: '#F5F8FE',
      BgPressed: colorPalette.WHITE,
      LabelDefault: colorPalette.BLUEGRAY700,
      LabelHover: colorPalette.BLUEGRAY700,
      LabelActive: colorPalette.YELLOW400,
    },
    Comment: {
      BgDefault: colorPalette.WHITE,
      BgHover: '#F5F8FE',
      BgPressed: colorPalette.WHITE,
      LabelDefault: colorPalette.BLUEGRAY700,
      LabelHover: colorPalette.BLUEGRAY700,
      LabelActive: colorPalette.BLUEGRAY700,
    },
  },
  EmptyPlaceholderIcons: {
    Background: colorPalette.BLUEGRAY100,
    IconFill: colorPalette.BLUEGRAY600,
  },
  EventTagChip: {
    Background: colorPalette.GRAY75,
    Text: colorPalette.BLUEGRAY800,
    SecondaryBackground: colorPalette.BLUEGRAY300,
    Collaboration: {
      IconFill: '#4CB9E7',
      Background: '#D3F0FD',
    },
    'Company Updates': {
      IconFill: '#65B741',
      Background: '#F1FEEB',
    },
    Culture: {
      IconFill: '#FB8B24',
      Background: '#FFF2E7',
    },
    Customer: {
      IconFill: '#29ADB2',
      Background: '#DBFEFF',
    },
    Learning: {
      IconFill: '#FFC436',
      Background: '#FFEDC3',
    },
    'Public Holiday': {
      IconFill: '#B70404',
      Background: '#FFDDDD',
    },
    Recruitment: {
      IconFill: '#793FDF',
      Background: '#DDCAFF',
    },
  },
  DropdownList: {
    Bg: colorPalette.WHITE,
    Hover: colorPalette.PRIMARY50,
    Label: colorPalette.BLUEGRAY800,
  },
  Extras: {
    Extra1: colorPalette.BLUEGRAY600,
    Extra2: '#E8BD00',
    Extra3: colorPalette.YELLOW100,
    Extra4: colorPalette.PURPLE100,
    Extra5: '#C100DB',
    Extra6: colorPalette.TEAL100,
    Extra7: colorPalette.TEAL600,
    Extra8: '#FFEFE6',
    Extra9: '#FF5D00',
    Extra10: '#F4F5FF',
    Extra11: '#565CB9',
    Extra12: 'rgb(0, 0, 0, 0.45)',
  },
};
