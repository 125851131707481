import React from 'react';
import { useTheme } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';

import Avatar from 'common/components/material/Avatar';
import { MUIRef } from 'common/types/MUIRef.type';
import { CXAvatarProps } from './cx-avatar.types';
import { SIZE_STYLE } from './cx-avatar.constants';
import { getOptimizedImageUrl } from 'features/media/media.utils';

export const CXAvatar: React.FC<CXAvatarProps> = React.forwardRef(
  (
    {
      size = 'medium',
      sx = {},
      boxShadow,
      children,
      icon: Icon,
      src,
      imgProps,
      ...avatarProps
    },
    ref: MUIRef,
  ) => {
    const sizeStyle = SIZE_STYLE[size];
    const { palette } = useTheme();
    Icon = Icon || PersonIcon;

    const srcURL = getOptimizedImageUrl(src, sizeStyle.avatar.width, sizeStyle.avatar.height);
    imgProps = imgProps ?? {};
    imgProps.loading = 'lazy';
    return (
      <Avatar
        sx={{
          backgroundColor: palette.EmptyPlaceholderIcons.Background,
          boxShadow: boxShadow && src ? boxShadow : 'none',
          border: `1px solid ${palette.Container.Stroke}`,
          color: palette.EmptyPlaceholderIcons.IconFill,
          ...sizeStyle.avatar,
          ...sx,
        }}
        ref={ref}
        src={srcURL ?? ''}
        imgProps={imgProps}
        {...avatarProps}
      >
        <Icon
          sx={{
            height: sizeStyle.icon.size,
            width: sizeStyle.icon.size,
          }}
        />
        {children}
      </Avatar>
    );
  },
);
