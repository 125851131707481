import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { createOrganizationEventApi } from '../organization-events.service';
import { Tag } from 'features/tags/types';
import { transformOrganizationEventRequest } from '../utils';

export const useCreateOrganizationEvent = (
  tags: Tag[] = [],
  options: UseMutationOptions = {},
) => {
  const { emitWithSettledAfter } = useEventEmitter('OrganizationEventsModified');

  const mutationResponse = useMutation(createOrganizationEventApi, {
    showSuccessSnackbar: true,
    transformInput: transformOrganizationEventRequest,
    onSuccess: () => {
      emitWithSettledAfter(3000);
    },
    ...options,
  });

  return mutationResponse;
};
