import axios from 'common/utils/axios';
import { mapKeys, omit, snakeCase, toUpper } from 'lodash';
import { mapSearchString } from './search.utils';
import {
  FetchFilterMetaDataRequestParams,
  FetchProfilesByNameRequestParams,
  FetchReporteesRequestParams,
} from './types';
import { SearchSuggestionType } from './enums/search-suggestion-type.enum';

const searchApi = axios('search');

export const fetchFilterMetadataApi = ({
  searchString,
}: FetchFilterMetaDataRequestParams) => {
  const filters = mapSearchString(searchString);
  const directFilters = omit(
    filters,
    'excludeChildren',
    'includeIndirectReports',
    'sort',
    'columns',
  );
  return searchApi.post('filter-metadata', {
    filters: mapKeys(directFilters, (value, key) => toUpper(snakeCase(key))),
  });
};

export const freeTextApi = (queryParams: any) => {
  return searchApi.get('', { params: { ...queryParams } });
};

export const getSearchRelatedPageApi = (params: any) => {
  return searchApi.get('related-pages', { params });
};

export const fetchProfilesByNameApi = (data: FetchProfilesByNameRequestParams) => {
  return searchApi.post('names', data);
};

export const fetchOrganizationRoleProfilesApi = (data: FetchProfilesByNameRequestParams) => {
  return searchApi.post('organization-role-profiles', data);
};

export const fetchSearchSuggestionsApi = (debouncedTerm: string) => {
  return searchApi.post('suggest', { term: debouncedTerm });
};

export const fetchSearchCategoriesResultsCount = (queryParams: any) => {
  return searchApi.get('search-categories-results-count', { params: { ...queryParams } });
};

export const getReporteesApi = (data: FetchReporteesRequestParams) => {
  return searchApi.post('reportees', data);
};

export const recentSearchesApi = () => {
  return searchApi.get('recent');
};

export const addRecentSearchesApi = (data: { id: string; type: SearchSuggestionType }) => {
  return searchApi.post('recent', data);
};
