import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getOrganizationEventFilterTabs } from '../utils/organization-events-tabs.config';
import { ButtonTabs } from 'common/components/navigation';
import { useRouter, useView } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { FilterViewTabEnum } from 'common/enum/filter-view-tab.enum';

const OrganizationEventsViewToggleTabs = () => {
  const params = useParams();
  const { isMobileView } = useView();
  const { goTo } = useRouter();
  const [searchParams] = useSearchParams();

  const urlParams = params['*']?.split('/') ?? [];
  const [filterType, selectedTab, month, year] = urlParams;

  const [eventFilterType, setEventFilterType] = useState(filterType);

  const onTabChange = (value: FilterViewTabEnum) => {
    setEventFilterType(value);

    const pathname: string = AppRoutesEnum.EXPLORE_EVENTS;

    goTo(
      { pathname, search: searchParams.toString() },
      {
        eventFilterType: value,
        selectedTab: selectedTab,
        month: month,
        year: year,
      },
    );
  };

  return (
    <ButtonTabs
      selectedValues={[eventFilterType]}
      tabs={getOrganizationEventFilterTabs(isMobileView)}
      onChange={onTabChange}
      sx={{
        gap: 0.75,
      }}
    />
  );
};

export default OrganizationEventsViewToggleTabs;
