import React from 'react';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { useTheme } from '@mui/material/styles';
import { Stack } from '../material';

const EmptyPraiseImage: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Stack
      bgcolor={palette.EmptyPlaceholderIcons.Background}
      width={56}
      height={56}
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
    >
      <MilitaryTechOutlinedIcon
        sx={{ color: palette.EmptyPlaceholderIcons.IconFill, fontSize: 24 }}
      />
    </Stack>
  );
};

export default EmptyPraiseImage;
