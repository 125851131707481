import React, { createContext } from 'react';
import {
  NotificationsContextProviderProps,
  useGetTotalUnreadNotifications,
  useMarkAllNotificationsAsRead,
} from 'features/notifications';

export const NotificationsContext = createContext({} as NotificationsContextType);

type NotificationsContextType = {
  unreadCount?: number;
  markAllAsRead: () => void;
};

export const NotificationsContextProvider: React.FC<NotificationsContextProviderProps> = (
  props,
) => {
  const { data: notificationsCount, refetch: refetchNotificationsCount } =
    useGetTotalUnreadNotifications({
      refetchInterval: 30_000,
    });
  const { submit: markAllNotificationsToRead } = useMarkAllNotificationsAsRead({
    onSuccess: () => {
      refetchNotificationsCount();
    },
  });
  const ctx = {
    unreadCount: notificationsCount,
    markAllAsRead: markAllNotificationsToRead,
  };
  return (
    <NotificationsContext.Provider value={ctx}>
      {props.children}
    </NotificationsContext.Provider>
  );
};
