import React from 'react';
import { toString } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { Stack, Typography } from 'common/components/material';
import { ReactComponent as CheckIcon } from 'common/svg-icons/checkIcon.svg';

import { StyledSkillChipProps, StyledSkillChip } from 'features/skills';
import { MUIRef } from 'common/types/MUIRef.type';

const EndorsedSkillChip: React.FC<StyledSkillChipProps> = React.forwardRef(
  (
    { label, isEndorsedByLoggedInUser, isSelected, variant = 'outlined', ...props },
    ref: MUIRef,
  ) => {
    const { breakpoints } = useTheme();
    const content = (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography
          variant="body4"
          fontWeight={500}
          sx={{
            overflow: 'hidden',
            maxWidth: '12rem',
            textOverflow: 'ellipsis',
            [breakpoints.down('sm')]: { maxWidth: '9rem' },
          }}
        >
          {label}
        </Typography>
        {isEndorsedByLoggedInUser && <CheckIcon className="skill-chip-icon" />}
      </Stack>
    );

    return (
      <StyledSkillChip
        ref={ref}
        clickable
        title={toString(label)}
        label={content}
        isSelected={isSelected}
        isEndorsedByLoggedInUser={isEndorsedByLoggedInUser}
        variant={variant}
        {...props}
      />
    );
  },
);

export default EndorsedSkillChip;
