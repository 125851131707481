import { useMutation, useCountdown } from 'common/hooks';
import { sendChangePasswordEmailApi } from '../accounts.service';
import { EMAIL_RESEND_DELAY } from '../accounts.constants';

export const useResendChangePasswordEmail = () => {
  const { countdownDisplay, countdownTime, setCountdownTime } = useCountdown();
  const mutationResponse = useMutation(sendChangePasswordEmailApi, {
    showSuccessSnackbar: true,
    onSuccess: () => {
      setCountdownTime(EMAIL_RESEND_DELAY);
    },
  });
  return {
    ...mutationResponse,
    countdownDisplay,
    countdownTime,
  };
};
