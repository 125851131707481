import React from 'react';

import MUIPopover, { PopoverProps as MUIPopoverProps } from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';

export type PopoverProps = MUIPopoverProps;

const Popover: React.FC<PopoverProps> = (props) => {
  const { children, ...popoverProps } = props;
  const { palette } = useTheme();
  return (
    <MUIPopover
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: palette.Container.Bg,
        },
      }}
      {...popoverProps}
    >
      {' '}
      {children}
    </MUIPopover>
  );
};

export default Popover;
