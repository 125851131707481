import { styled } from '@mui/material/styles';

import Typography from 'common/components/material/Typography';

export const ProfileStyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.Text.Headline,
  fontSize: theme.typography.body3.fontSize,
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body4.fontSize,
  },
}));

export const ProfileStyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.Text.Caption,
  fontSize: theme.typography.body4.fontSize,
  paddingBottom: '0.25rem',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body4.fontSize,
  },
}));

export const StyledName = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontWeight: 600,
  color: theme.palette.Text.Headline,
  mb: 1,
  fontSize: '1.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.Text.Headline,
  fontWeight: 700,
  lineHeight: 2,
  paddingLeft: 2,
}));
