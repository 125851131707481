import { FilterViewTabEnum } from "common/enum/filter-view-tab.enum";

export const ORGANIZATION_EVENTS_TABS = [
  { value: 'month', label: 'Month' },
  { value: 'quarter', label: 'Quarter' },
];

export const getOrganizationEventFilterTabs = (isMobileView: boolean) => [
  {
    value: FilterViewTabEnum.FOLLOWING,
    label: 'Following',
    ...(isMobileView && { sx: { width: '100%' } }),
  },
  {
    value: FilterViewTabEnum.ALL,
    label: 'All Events',
    ...(isMobileView && { sx: { width: '100%' } }),
  },
];
