import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener } from '@mui/material';
import { camelCase, isEmpty } from 'lodash';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';

import { Button, InputAdornment, Stack } from 'common/components/material';
import { useEventEmitter, useEvents, useRouter, useView } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { isInExplorePage } from 'common/utils/appBar.utils';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import FilterChipList from 'features/explore/components/FilterChipList';
import { getTransformedFilterList, omitFilterParams } from 'features/explore/explore.utils';
import { InlineIconButton } from 'features/org-root/org-root.styles';
import {
  ActiveFilters,
  DEFAULT_PLACEHOLDER,
  Filter,
  SearchBarProps,
  SMALLER_SCREEN_PLACEHOLDER,
} from 'features/search';
import { SearchSuggestionType } from 'features/search/enums/search-suggestion-type.enum';
import { useAddRecentSearches } from 'features/search/hooks/useAddRecentSearches';
import { ISearchSuggestion } from 'features/search/interfaces/search-suggestion.interface';
import { IStaticDataSearchSuggestion } from 'features/static-data/interfaces/static-data-search-suggestion.interface';
import AppBarSearchInput from '../input/app-bar-search-input.component';
import SearchSuggestions from './search-suggestions';
import { useExploreTabs } from 'features/explore/hooks/useExploreTabs';
import { ExploreOrganizationTabType } from 'features/org-root/config/explore-organization-tabs.config';
import { useGetFilterMenuItems } from 'features/explore';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';

const SearchBar: React.FC<SearchBarProps> = ({
  onClose,
  placeholder,
  autoFocus,
  onFocus,
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { goTo } = useRouter();
  const urlQuery = searchParams.get('query') || '';
  const { isDesktopView, isLargeScreenView } = useView();
  placeholder =
    placeholder || isLargeScreenView ? DEFAULT_PLACEHOLDER : SMALLER_SCREEN_PLACEHOLDER;
  const searchInputRef = React.useRef<HTMLInputElement>();

  /**
   * Here we are fetching allowed Explore tabs for the loggedin user,
   * once we have the allowed explore tabs, we select the first and let the user
   * route to that explore view.
   */
  const { exploreTabs } = useExploreTabs();
  const exploreTab = exploreTabs[0] ?? ({} as ExploreOrganizationTabType);
  const exploreTabRoute = exploreTab.pathName;
  /**
   * Here on the basis of allowed explore tabs, we get the allowed/permissined filterMenuItems
   * If there are allowed filter menu items, then only we display filter button.
   */
  const { menuItems: allowedFilterItems } = useGetFilterMenuItems(
    exploreTab.contentType as ExploreTabEnum,
  );
  const hasAccessToFilters = !!allowedFilterItems.length;

  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({});
  const [filtersList, setFiltersList] = useState<Filter[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInputHeightOffset, setSearchInputHeightOffset] = useState(0);
  const [hasJustSelectedFilter, setHasJustSelectedFilter] = useState(false);
  const hasTextOrFilters = !!filtersList.length || !!searchText;

  const { emit: emitFiltersOpen } = useEventEmitter('FiltersOpen');
  const { emit: emitFiltersUpdated } = useEventEmitter<ActiveFilters>('FiltersUpdated');

  const { submit: addRecentSearches } = useAddRecentSearches();
  useEvents('FiltersUpdated', (filters: ActiveFilters) => updateFilters(filters));

  useEffect(() => {
    setSearchText(urlQuery);
  }, [location.pathname, urlQuery, isDesktopView]);

  useEffect(() => {
    const searchInputHeight = searchInputRef?.current?.parentElement?.clientHeight || 40;
    setSearchInputHeightOffset(searchInputHeight);
  }, [isExpanded, isModalOpen]);

  useEffect(() => {
    if (hasJustSelectedFilter) {
      searchInputRef.current?.focus();
    }
  }, [hasJustSelectedFilter]);

  const searchKeyword = (searchText: string) => {
    onClose && onClose();
    setIsModalOpen(false);
    if (!isInExplorePage(location.pathname)) {
      goTo(
        {
          pathname: exploreTabRoute,
          search: {
            query: searchText,
          },
        },
        {},
      );
      return;
    }

    if (searchText) {
      searchParams.set('query', searchText);
    } else {
      searchParams.delete('query');
    }

    setSearchParams(searchParams, { replace: true });
  };

  const openFilters = () => {
    if (!isInExplorePage(location.pathname)) {
      goTo(exploreTabRoute, {}, { state: { openFilters: true } });
    } else {
      emitFiltersOpen();
    }
  };

  const clearSearchParam = () => {
    if (isModalOpen && !isLargeScreenView) {
      setSearchText('');
      return;
    }
    const search = createSearchParams(location.search);
    search.delete('query');
    onClose && onClose();
    goTo({ pathname: location.pathname, search });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsExpanded(false);
  };

  const onSearchInputChange = (value: string) => {
    openModal();
    setSearchText(value);
  };

  const expandSearchBar = () => {
    setIsExpanded(true);
    searchInputRef?.current?.focus();
  };

  const updateFilters = (filters: ActiveFilters) => {
    setActiveFilters(filters);
    const newFiltersList = getTransformedFilterList(filters);
    setFiltersList(newFiltersList);

    if (newFiltersList.length < 2) {
      setIsExpanded(false);
    }
  };

  const handleDeleteFilter = (filter: Filter) => {
    const filterTypeKey = camelCase(filter.filterType);
    const filtersInCategorySet = new Set(searchParams.get(filterTypeKey)?.split(','));
    const excludeFiltersInCategorySet = new Set(
      searchParams.get('excludeChildren')?.split(','),
    );
    filtersInCategorySet.delete(filter.id);
    excludeFiltersInCategorySet.delete(filter.id);

    const filtersInCategory: string[] = Array.from(filtersInCategorySet);
    const excludeFiltersInCategory: string[] = Array.from(excludeFiltersInCategorySet);
    const newFilters = { ...activeFilters };

    if (!isEmpty(filtersInCategory)) {
      searchParams.set(filterTypeKey, filtersInCategory.join(','));
      newFilters[filterTypeKey] = newFilters[filterTypeKey].filter((f) => f.id !== filter.id);
    } else {
      searchParams.delete(filterTypeKey);
      delete newFilters[filterTypeKey];
    }

    if (!isEmpty(excludeFiltersInCategory)) {
      searchParams.set('excludeChildren', excludeFiltersInCategory.join(','));
      newFilters['excludeChildren'] = newFilters['excludeChildren'].filter(
        (f) => f.id !== filter.id,
      );
    } else {
      searchParams.delete('excludeChildren');
      delete newFilters['excludeChildren'];
    }

    setSearchParams(searchParams);
    emitFiltersUpdated(newFilters);
  };

  const appendFilter = (filter: Filter) => {
    const filterTypeKey = camelCase(filter.filterType);
    const filtersInCategory = searchParams.get(filterTypeKey)?.split(',') || [];

    const newFilters = { ...activeFilters };

    if (!newFilters[filterTypeKey]) {
      newFilters[filterTypeKey] = [];
    }

    filtersInCategory.push(filter.id);
    newFilters[filterTypeKey].push(filter);

    searchParams.set(filterTypeKey, filtersInCategory.join(','));
    setSearchParams(searchParams);

    if (filtersList.length) {
      // Ensure we can see the filter we just added
      setIsExpanded(true);
    }

    emitFiltersUpdated(newFilters);
  };

  const handleFocus = () => {
    onFocus && onFocus();
    searchInputRef.current?.select();
    if (!hasJustSelectedFilter) {
      openModal();
    }
    setHasJustSelectedFilter(false);
  };

  const _onEmptySelection = () => {
    if (searchText) {
      addRecentSearches({
        value: searchText,
        type: SearchSuggestionType.TEXT,
      });
      searchKeyword(searchText);
    }
  };

  const _onSuggestionSelection = (
    selectedSuggestion: ISearchSuggestion,
    navigationChangePending?: boolean,
  ) => {
    const { id, type, data } = selectedSuggestion;
    addRecentSearches({
      value: type === SearchSuggestionType.TEXT ? data : id,
      type,
    });

    if (navigationChangePending) {
      return;
    }
    switch (selectedSuggestion?.type) {
      case SearchSuggestionType.STATIC_DATA:
        const staticData = selectedSuggestion.data as IStaticDataSearchSuggestion;
        appendFilter({
          id: staticData.id,
          name: staticData.name,
          filterType: staticData.type as unknown as ExplorerDataTypeEnum,
        });
        setSearchText('');
        setIsModalOpen(false);
        setHasJustSelectedFilter(true);

        if (!isInExplorePage(window.location.pathname)) {
          const filterTypeKey = camelCase(staticData.type);
          goTo({
            pathname: exploreTabRoute,
            search: {
              [filterTypeKey]: staticData.id,
            },
          });
        }
        break;
      case SearchSuggestionType.PROFILE:
        goTo(AppRoutesEnum.PROFILE, { profileId: selectedSuggestion?.id });
        break;
      case SearchSuggestionType.TEXT:
        searchKeyword(selectedSuggestion.data as string);
        break;
    }
  };

  const onSelect = (
    selectedSuggestion?: ISearchSuggestion,
    navigationChangePending?: boolean,
  ) => {
    if (!selectedSuggestion) {
      _onEmptySelection();
    } else {
      _onSuggestionSelection(selectedSuggestion, navigationChangePending);
    }
    closeModal();
    onClose && onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Delete filters with backspace if pressed with no search text left
    if (event.key === 'Backspace' && !searchText && filtersList.length) {
      handleDeleteFilter(filtersList[filtersList.length - 1]);
    }
    if (event.key === 'ArrowDown' && !hasTextOrFilters && !isModalOpen) {
      openModal();
    }
  };

  const clearAll = () => {
    const nonFilterParams = omitFilterParams(searchParams);
    setSearchText('');
    setSearchParams(nonFilterParams);
    emitFiltersUpdated({});
  };

  const filterChips = (
    <FilterChipList
      filters={filtersList}
      size="small"
      isExpanded={isExpanded || !isDesktopView}
      onDelete={handleDeleteFilter}
      onExpand={expandSearchBar}
      width={isExpanded ? '100%' : 'auto'}
      maxWidth={isExpanded || !isDesktopView ? '100%' : '50%'}
    />
  );

  return (
    <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={closeModal}>
      <Stack direction="row" position={{ xs: 'static', md: 'relative' }} width="100%">
        <AppBarSearchInput
          autoComplete="one-time-code"
          id="global-search-bar"
          onFocus={handleFocus}
          autoFocus={autoFocus}
          onChange={onSearchInputChange}
          value={searchText}
          placeholder={placeholder}
          fullWidth={true}
          onSubmit={() => {
            addRecentSearches({
              value: searchText,
              type: SearchSuggestionType.TEXT,
            });
            searchKeyword(searchText);
          }}
          onClear={clearSearchParam}
          isExpanded={isDesktopView && isExpanded}
          inputRef={searchInputRef}
          startAdornment={isDesktopView && filterChips}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              {hasTextOrFilters && (
                <InlineIconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ marginRight: '0.5rem' }}
                  onClick={clearAll}
                >
                  <CloseIcon color="primary" />
                </InlineIconButton>
              )}
              {hasAccessToFilters && (
                <Button btntype="primary" size="xsmall" onClick={openFilters}>
                  Filter
                </Button>
              )}
            </InputAdornment>
          }
        />

        {isModalOpen && (
          <SearchSuggestions
            searchTerm={searchText}
            topOffset={searchInputHeightOffset}
            onSelect={onSelect}
            excludeIds={filtersList.map((filter) => filter.id)}
          >
            {!isDesktopView && !!filtersList.length && filterChips}
          </SearchSuggestions>
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default SearchBar;
