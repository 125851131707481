import React from 'react';
import { useTheme } from '@mui/material';

import { AppBar, Container, Stack, Typography } from 'common/components/material';
import { CloseButton } from 'common/components/navigation';
import { useDrawer } from 'common/hooks';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { WinListTooltip } from 'features/win-lists/components/win-list-tooltip.component';

export const ManageWinListHeader: React.FC<{ subText: string }> = ({ subText }) => {
  const { closeDrawer } = useDrawer();
  const { palette } = useTheme();

  const handleCloseManageWinsDrawer = () => {
    closeDrawer(DrawerIdEnum.MANAGE_WIN_LIST);
  };

  return (
    <AppBar
      sx={{
        height: '4.625rem',
        backgroundColor: palette.Container.Bg,
        boxShadow: AppBoxShadows.shadow1,
      }}
    >
      <Container maxWidth="md" sx={{ m: 'auto' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography
              display="flex"
              alignItems="center"
              variant="h6"
              fontWeight={700}
              color={palette.Text.Headline}
            >
              Update your WIN List (What's Important Now)
              <WinListTooltip sx={{ width: '1.25rem', marginLeft: '0.25rem' }} />
            </Typography>
            <Typography variant="caption" fontWeight={400} color={palette.Text.SubHeadline}>
              {subText}
            </Typography>
          </Stack>
          <CloseButton clickHandler={handleCloseManageWinsDrawer} />
        </Stack>
      </Container>
    </AppBar>
  );
};
