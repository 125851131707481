import { useMutation, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import queryClient from 'common/utils/QueryClient';
import { toggleStaticDataVisibilityApi } from '../services/static-data.service';

export const useToggleStaticDataVisibility = (options: UseMutationOptions = {}) => {
  const { profile } = useSession();
  const organizationId = profile?.getOrgId() || '';
  return useMutation(toggleStaticDataVisibilityApi, {
    showSuccessSnackbar: true,
    onSuccess: () => {
      queryClient.invalidateQueries([organizationId, 'enabled-org-static-data-types'], {
        refetchInactive: true,
      });
    },
    ...options,
  });
};
