import { getTotalOrganizationEventsApi } from '../organization-events.service';
import { useEvents, useSession, useQuery } from 'common/hooks';
import { useSearchParams } from 'react-router-dom';
import { mapSearchParamsObject } from 'features/search';
import { GetTotalOrganizationEventsParams } from '../types';
import { settled } from 'common/types/Event-type.type';
import { UseQueryOptions } from 'common/types/Api.type';

export const useGetTotalOrganizationEvents = (
  params: GetTotalOrganizationEventsParams,
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const filters = mapSearchParamsObject(searchParams) as { [prop: string]: any };
  // we are anyway passing eventTypes via params, so no need to pass the URL searchParam to api.
  delete filters.eventType;
  delete filters.selectedDate;

  if (params?.tags) {
    filters.tags = params?.tags;
  }

  if (params?.eventTypes?.length) {
    filters.eventTypes = params?.eventTypes;
  }

  if (params?.period) {
    filters.period = params?.period;
  }

  if (params?.eventFilterType) {
    filters.eventFilterType = params?.eventFilterType;
  }

  filters.month = params.month;
  filters.year = params.year;

  const queryKey = [user.organizationId, 'organization-events', filters];

  const queryParams = {
    term: query,
    ...filters,
  };

  const queryResponse = useQuery(queryKey, () => getTotalOrganizationEventsApi(queryParams), {
    ...options,
  });

  const response = {
    ...queryResponse,
    totalEvents: queryResponse.isSuccess
      ? (queryResponse?.data?.data.totalEvents as number)
      : 0,
  };
  useEvents(settled('OrganizationEventsModified'), () => response.refetch());

  return response;
};
