import { some } from 'lodash';

import { FileInfo, Media } from '../../types/media.types';
import { MediaType } from '../../media-type.enum';
import { FileLoader } from './file-loader';

export const MediaPhotoLoader: React.FC<{
  existingListOfMedia: Media[];
  disabled?: boolean;
  onChange: (mediaAttachments: Media[]) => void;
  onClick?: () => void;
}> = ({ existingListOfMedia, disabled, onChange, onClick }) => {
  const onLoaded = (photos: FileInfo[] = []) => {
    let isChanged = false;

    for (const photo of photos) {
      const isNewMedia = !some(
        existingListOfMedia,
        (media) => media.type === MediaType.PHOTO && media.fileName === photo?.name,
      );

      if (isNewMedia) {
        isChanged = true;
        existingListOfMedia.push({
          name: photo?.name,
          type: MediaType.PHOTO,
          size: photo?.size,
          file: photo,
          fileName: photo?.name,
          fileUrl: photo?.url,
        });
      }
    }

    if (isChanged) {
      onChange && onChange(existingListOfMedia);
    }
  };

  return (
    <FileLoader
      title="Upload Photos"
      label="Max 10MB, recommended 564 X 295px"
      inputProps={{ name: 'photo', multiple: true }}
      onLoaded={onLoaded}
      disabled={disabled}
      onClick={onClick}
    />
  );
};
