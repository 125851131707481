import React, { ForwardedRef } from 'react';
import { Checkbox } from '../material';
import { CXCheckboxRendererProps } from './cx-checkbox-renderer-props.type';
import { CXCheckboxProps } from './cx-checkbox-props.type';

export function CXCheckboxRenderer(
  props: CXCheckboxProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return ({ field }: CXCheckboxRendererProps) => {
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      field.onChange(event);
      props.onChange && props.onChange(value);
    };

    return (
      <Checkbox
        ref={ref}
        checked={field.value || props.value || false}
        onChange={changeHandler}
        disabled={props.disabled}
        sx={{
          ml: '-3px', // offset a 3px space from checkbox svg
        }}
      />
    );
  };
}
export default CXCheckboxRenderer;
