import React from 'react';
import { styled } from '@mui/material/styles';

import Card from 'common/components/material/Card';
import { ProfileDataListItemCardContent } from '../styles';
import { ProfileDataListItemCardProps } from '../types';

const StyledListItemCard = styled(Card)(({ theme }) => ({
  '&.attention': {
    animation: 'attention 1s',
  },
  '@keyframes attention': {
    '0%': {
      backgroundColor: theme.palette.LinkContainer.BgHover,
    },
    '100%': {
      backgroundColor: theme.palette.Container.Bg,
    },
  },
}));

const StackedDataListItemCard: React.FC<ProfileDataListItemCardProps> = ({
  children,
  onClick,
  sx,
  callToAttention,
}) => {
  return (
    <StyledListItemCard
      className={callToAttention ? 'attention' : ''}
      noElevation={true}
      onClick={onClick}
      sx={{ flexDirection: 'column', paddingY: 0.5, ...sx }}
    >
      <ProfileDataListItemCardContent>{children}</ProfileDataListItemCardContent>
    </StyledListItemCard>
  );
};

export default StackedDataListItemCard;
