import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { FetchProfilesByNameRequestParams } from '../types';
import { fetchProfilesByNameApi } from '../search.service';
import { ProfileSortByOption } from '../enums';

export const useFetchProfilesByName = (
  {
    term,
    includeIds,
    excludeIds,
    includeInactive,
    sortBy = ProfileSortByOption.NAMES,
  }: FetchProfilesByNameRequestParams,
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();

  const queryResponse = useQuery(
    ['search', 'names', user?.organizationId, term, excludeIds, includeIds, includeInactive],
    () => fetchProfilesByNameApi({ term, includeIds, excludeIds, sortBy, includeInactive }),
    {
      ...options,
    },
  );
  const searchModel = queryResponse.data?.data?.profiles;

  return {
    ...queryResponse,
    data: searchModel,
  };
};
