import { OrganizationRole } from './organization-role.type';

export type OrganizationRoleDrawerProps = {
  organizationRole?: OrganizationRole;
  profileId?: string;
  isRoleNotRemovable?: boolean;
  isNewRole?: boolean;
  autoFocusField?: AUTOFOCUS_FIELD;
};

export enum AUTOFOCUS_FIELD {
  TITLE = 'TITLE',
  SKILLS = 'SKILLS',
  DESCRIPTION = 'DESCRIPTION',
}
