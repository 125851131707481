import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';

import { Typography, Stack, Button } from 'common/components/material';
import EmptyStaticDataImage from 'common/components/image/EmptyStaticDataImage';
import { EmptyStaticDataViewProps } from 'features/static-data';

const HEADING_TEXT = "Let's get started";
const SUBHEADING_TEXT = 'Enrich employee profiles with organizational static data';

const EmptyStaticDataView: React.FC<EmptyStaticDataViewProps> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={4}
      flex={1}
      height={{ xs: 'auto', md: '40rem' }}
    >
      <EmptyStaticDataImage />

      <Stack width="24rem" textAlign="center" spacing={2}>
        <Typography variant="h3" fontWeight={600} color={theme.palette.Text.Headline}>
          {HEADING_TEXT}
        </Typography>
        <Typography variant="body1" color={theme.palette.Text.SubHeadline}>
          {SUBHEADING_TEXT}
        </Typography>
      </Stack>

      <Button
        btntype="primary"
        size="medium"
        startIcon={<AddIcon sx={{ height: '1.5rem', width: '1.5rem' }} />}
        onClick={onClick}
      >
        Add New
      </Button>
    </Stack>
  );
};

export default EmptyStaticDataView;
