import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { deletePraiseApi } from '../praise.service';
import { Praise, PraiseEventData } from '../types';

export const useDeletePraise = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter<PraiseEventData>('PraiseDeleted');

  return useMutation(deletePraiseApi, {
    showSuccessSnackbar: true,
    ...options,
    onSuccess: async (result, data: Praise) => {
      emitWithSettledAfter(5000, { data });
    },
  });
};
