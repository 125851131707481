import { styled } from '@mui/material/styles';
import { Stack } from '../material';

// Container for evenly-sized row of form fields
const HorizontalFieldContainer = styled(Stack)({
  '& .MuiFormControl-root': {
    flex: 1,
  },
});

HorizontalFieldContainer.defaultProps = {
  direction: 'row',
  spacing: 3,
};

export default HorizontalFieldContainer;
