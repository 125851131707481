import React from 'react';

import { Stack, Typography } from 'common/components/material';

import { CXAvatar } from 'common/components/cx-avatar/cx-avatar';
import { StyledPersonChip } from '../styles/person-chip.styles';

const PersonChip: React.FC<any> = ({ label, avatar, ...props }) => {
  const content = (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CXAvatar src={avatar} alt={label} size="xsmall" />
      <Typography variant="body3">{label}</Typography>
    </Stack>
  );

  return <StyledPersonChip label={content} {...props} />;
};

export default PersonChip;
