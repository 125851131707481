import { NotificationContainerProp } from 'features/notifications';
import EmptyNotifications from './EmptyNotifications';
import NotificationActivityLabel from './NotificationActivityLabel';
import NotificationItem from './NotificationItem';

const NotificationContainer: React.FC<NotificationContainerProp> = ({ data }) => {
  if (data?.length === 0) {
    return <EmptyNotifications />;
  }

  return (
    <>
      <NotificationActivityLabel />
      {data.map((item: any) => (
        <NotificationItem key={item.id} {...item} />
      ))}
    </>
  );
};

export default NotificationContainer;
