import React from 'react';
import { toString, isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';

import { Stack, Typography } from 'common/components/material';
import { ReactComponent as CheckIcon } from 'common/svg-icons/checkIcon.svg';

import { StyledSkillChip, StyledSkillChipProps } from 'features/skills';

export const AspirationsChipIcon = styled('img')(() => ({
  position: 'relative',
  height: '20px',
}));

const AspirationChip: React.FC<StyledSkillChipProps> = ({
  isSelected,
  label,
  icon,
  isEndorsedByLoggedInUser,
  variant = 'outlined',
  ...props
}) => {
  const content = (
    <Stack direction="row" alignItems="center">
      <Typography variant="body4" fontWeight={500}>
        {label}
      </Typography>
      {isEndorsedByLoggedInUser && <CheckIcon className="skill-chip-icon" />}
    </Stack>
  );

  return (
    <StyledSkillChip
      avatar={!isEmpty(icon) ? icon : undefined}
      icon={
        isEmpty(icon) ? (
          <AspirationsChipIcon
            src="/images/aspirationChip.png"
            alt="aspiration chip"
            sx={{ right: '4px' }}
          />
        ) : undefined
      }
      clickable
      title={toString(label)}
      isSelected={isSelected}
      label={content}
      isEndorsedByLoggedInUser={isEndorsedByLoggedInUser}
      variant={variant}
      {...props}
    />
  );
};

export default AspirationChip;
