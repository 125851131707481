import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { PeopleSelector, StyledForm } from 'common/components/input';
import { Stack, Typography } from 'common/components/material';
import DateRangePicker from 'common/components/material/DateRangePicker';
import { DrawerHeader } from 'common/components/material/Drawer';
import { useDrawer, useForm } from 'common/hooks';
import moment from 'moment';
import { DrawerIdEnum } from '../../enums/drawer-id.enum';
import {
  ReviewDrawerProps,
  ReviewForm,
  ReviewFormSchema,
  useCreateReview,
} from 'features/reviews';
import { Person, useGetProfile } from 'features/profiles';
import { convertToDateString } from 'common/utils/date-time.utils';

const DRAWER_TITLE = 'Create Review';

export const INIT_PROPS: ReviewDrawerProps = {
  reviewee: '',
};

const ReviewDrawer: React.FC<ReviewDrawerProps> = ({ reviewee }) => {
  const { data: profileData } = useGetProfile({ id: reviewee });
  const { palette } = useTheme();

  const { closeDrawer } = useDrawer();
  const { submit: createReview, isLoading } = useCreateReview(profileData?.id);
  const initialFromDate = convertToDateString();
  //to date should be 3 months in future
  const initialToDate = convertToDateString(moment().add(3, 'month').toDate());
  const [selectedReviewee, setSelectedReviewee] = useState<Person[]>(
    reviewee ? [profileData] : [],
  );
  const [selectedReviewer, setSelectedReviewer] = useState<Person[]>([]);
  const [reviewStartDate, setReviewStartDate] = useState<string>(initialFromDate);
  const [reviewEndDate, setReviewEndDate] = useState<string>(initialToDate);

  const form = useForm<ReviewForm>({
    defaultValues: {
      reviewees: selectedReviewee,
      reviewer: [],
      reviewStartDate,
      reviewEndDate,
    },
    resolver: yupResolver(ReviewFormSchema),
  });

  const {
    getValues,
    setValue,
    formState: { isValid },
  } = form;
  const onDiscard = () => {
    closeDrawer(DrawerIdEnum.REVIEW);
  };

  const onSubmit = () => {
    createReview(getValues());
    onDiscard();
  };

  const handleDatesSelection = (startDate: string, endDate: string) => {
    setReviewStartDate(startDate);
    setValue('reviewStartDate', startDate);
    setReviewEndDate(endDate);
    setValue('reviewEndDate', endDate);
  };

  const header = (
    <DrawerHeader
      sx={{
        display: 'block',
        backgroundColor: palette.Background,
        padding: 4,
      }}
    >
      <Typography variant="h2" color={palette.Text.Headline} fontWeight="bolder">
        {DRAWER_TITLE}
      </Typography>
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isLoading}
      isPrimaryActionDisabled={!isValid || isLoading}
      secondaryAction={onDiscard}
      primaryAction={onSubmit}
      primaryActionLabel="Create"
    />
  );
  return (
    <FormDrawer header={header} footer={footer}>
      <FormProvider {...form}>
        <StyledForm noValidate>
          <Stack spacing={4} paddingTop={3} paddingBottom={3} mx={2}>
            <PeopleSelector
              name="reviewees"
              label="Reviewee"
              size="medium"
              limit={1}
              excludePeople={selectedReviewer.map((person) => person.id)}
              selectedProfiles={selectedReviewee || []}
              onChange={setSelectedReviewee}
              readOnly={!!reviewee}
            />
            <PeopleSelector
              name="reviewer"
              label="Reviewer"
              size="medium"
              limit={1}
              excludePeople={selectedReviewee.map((person) => person.id)}
              selectedProfiles={selectedReviewer || []}
              onChange={setSelectedReviewer}
            />
            <DateRangePicker
              label="Review Period"
              onDatesSelected={handleDatesSelection}
              addMonthInToDate={3}
            ></DateRangePicker>
          </Stack>
        </StyledForm>
      </FormProvider>
    </FormDrawer>
  );
};

export default ReviewDrawer;
