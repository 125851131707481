import React from 'react';

import MenuItemButton from './MenuItemButton';

const CONNECTX_ABOUT_US_URL = 'https://connect-x.io/about-us/';

const AboutConnectXMenuItem: React.FC = () => {
  const handleClick = () => {
    window.open(CONNECTX_ABOUT_US_URL, '_blank');
  };

  return (
    <MenuItemButton onClick={handleClick} label="About ConnectX">
      <img height="24px" src="/images/connectx.svg" alt="ConnectX" />
    </MenuItemButton>
  );
};

export default AboutConnectXMenuItem;
