import MUIInputBase, { InputBaseProps } from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledInput = styled(MUIInputBase)({
  '[type="search"]::-webkit-search-cancel-button': {
    WebkitAppearance: 'none',
    appearance: 'none',
  },
  '[type="search"]::-webkit-search-decoration': {
    WebkitAppearance: 'none',
    appearance: 'none',
  },
});

const InputBase: React.FC<InputBaseProps> = (props) => {
  const { children, ...inputBaseProps } = props;

  return <StyledInput {...inputBaseProps}>{children}</StyledInput>;
};

export default InputBase;
