import { UnauthenticatedRoute } from 'common/routes/components';
import { suspendable } from 'common/utils/suspendable';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AccountsRoutesEnum } from './accounts.enums';

const Accounts = suspendable(React.lazy(() => import('./accounts.component')));
const Login = suspendable(React.lazy(() => import('./components/LoginFormContainer')));
const Signup = suspendable(React.lazy(() => import('./components/SignupForm')));
const ForgotPassword = suspendable(React.lazy(() => import('./components/ForgotPassword')));
const NoProfile = suspendable(React.lazy(() => import('./components/NoProfile')));
const ResetPasswordForm = suspendable(
  React.lazy(() => import('./components/ResetPasswordForm')),
);
const IncompleteAccountSetup = suspendable(
  React.lazy(() => import('./components/IncompleteSetup')),
);
const CheckEmail = suspendable(React.lazy(() => import('./components/CheckEmail')));
const VerifyEmail = suspendable(React.lazy(() => import('./components/VerifyEmail')));
const VerifyingScreen = suspendable(
  React.lazy(() => import('features/accounts/components/VerifyingScreen')),
);

const AccountsRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path={AccountsRoutesEnum.ACCOUNTS}
          element={<UnauthenticatedRoute element={<Accounts />} />}
        >
          <Route path={AccountsRoutesEnum.LOGIN} element={<Login />} />
          {process.env.REACT_APP_SIGNUP_ENABLED === 'true' && (
            <Route path={AccountsRoutesEnum.SIGNUP} element={<Signup />} />
          )}
          <Route path={AccountsRoutesEnum.FORGOT_PASSWORD} element={<ForgotPassword />} />
        </Route>
        <Route path={AccountsRoutesEnum.ACCOUNTS} element={<Accounts />}>
          <Route path={AccountsRoutesEnum.NO_PROFILE} element={<NoProfile />} />
          <Route path={AccountsRoutesEnum.RESET_PASSWORD} element={<ResetPasswordForm />} />
          <Route
            path={AccountsRoutesEnum.INCOMPLETE_SETUP}
            element={<IncompleteAccountSetup />}
          />
          <Route path={AccountsRoutesEnum.CHECK_EMAIL} element={<CheckEmail />} />
          <Route path={AccountsRoutesEnum.VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route path={AccountsRoutesEnum.VERIFICATION} element={<VerifyingScreen />} />
          <Route
            path={AccountsRoutesEnum.DEFAULT}
            element={<Navigate to={AccountsRoutesEnum.LOGIN} />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AccountsRouter;
