import * as _ from 'lodash';
import { TagDetails } from '../types';
import { InstancePermissionsType } from 'features/feature-access/types/instance-permissions.type';
import { BaseStaticDataModel } from './base-static-data.model';
import {
  OrganizationalStaticDataTypeEnum,
  OrganizationalTagPagesTypeEnum,
} from '../static-data.enum';
import { StaticDataTypeDisplayName } from '../static-data.constants';

//TODO: In future, whenever we convert static data pages to tag pages, we have to rename / move everything related to static data pages to tags.
export class StaticDataInfoModel extends BaseStaticDataModel {
  protected _data: TagDetails;

  constructor(data: TagDetails) {
    super(data);
    this._data = data || {};
  }

  get description(): string | undefined {
    return this._data.description;
  }

  get parent(): StaticDataInfoModel | undefined {
    if (!_.isEmpty(this._data.parent)) {
      return new StaticDataInfoModel(this._data?.parent as TagDetails);
    }

    return undefined;
  }

  get children(): StaticDataInfoModel[] {
    if (!_.isEmpty(this._data.children)) {
      return this._data.children.map(
        (staticData) => new StaticDataInfoModel(staticData as any),
      );
    }

    return [];
  }

  get type(): OrganizationalTagPagesTypeEnum {
    return this._data?.type || OrganizationalTagPagesTypeEnum.WORK_LOCATION;
  }

  get tagTypeDisplayName(): string {
    return StaticDataTypeDisplayName[
      this._data?.type || OrganizationalStaticDataTypeEnum.WORK_LOCATION
    ];
  }

  canEditDescription(permissions: InstancePermissionsType): boolean {
    return permissions.edit;
  }
}
