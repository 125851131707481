import { camelCase, get, reduce, uniqBy, flatMap, toLower, set } from 'lodash';

import { titleCase } from 'common/utils/app.utils';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { CompanyProfileInfo } from 'features/profiles/types/company-profile-info.type';
import {
  ORDERED_PROFILE_STATIC_DATA,
  OrganizationalStaticDataTypeEnum,
} from 'features/static-data/static-data.enum';
import { Tag } from 'features/tags/types/tag.type';
import {
  DATA_GRID_PARAM_KEYS,
  GLOBAL_FILTER_MENU_ITEMS,
  NO_FILTERS_TEXT,
  NO_RESULTS_TEXT,
  STATIC_DATA_FILTERS,
} from './explore.constants';
import { getTagDisplayName } from 'features/tags/utils';
import { ActiveFilters } from 'features/search';

export const getEmptyFilterText = (isSearchResult?: boolean) => {
  return isSearchResult ? NO_RESULTS_TEXT : NO_FILTERS_TEXT;
};

export const getVisibleFilterMenuItems = (
  currentTabMenuConfig: ExplorerDataTypeEnum[],
  visibleStaticDataTypes: ExplorerDataTypeEnum[],
) => {
  const visibleStaticDataTypesSet = new Set(visibleStaticDataTypes);
  const staticDataFilters = new Set(STATIC_DATA_FILTERS);

  const filterItemKeys = new Set();

  for (const item of currentTabMenuConfig) {
    if (!staticDataFilters.has(item)) {
      filterItemKeys.add(item);
    }

    if (staticDataFilters.has(item) && visibleStaticDataTypesSet.has(item)) {
      filterItemKeys.add(item);
    }
  }

  return GLOBAL_FILTER_MENU_ITEMS.filter((filter) => filterItemKeys.has(filter.key));
};

export const getCompanyProfileStaticDataAsTags = (
  companyProfile: CompanyProfileInfo,
): Tag[] => {
  return reduce<OrganizationalStaticDataTypeEnum, Tag[]>(
    ORDERED_PROFILE_STATIC_DATA,
    (staticData, staticDataType) => {
      if (staticDataType === OrganizationalStaticDataTypeEnum.WORK_LOCATION)
        return staticData;

      const data = get(companyProfile, camelCase(staticDataType));

      if (data) {
        staticData.push({
          id: data.id,
          name: data.name,
          displayName: getTagDisplayName(data),
          completeName: data.fullName,
          type: staticDataType,
          typeLabel: titleCase(staticDataType),
          icon: data.photo,
          highlighted: data.isMatch,
        });
      }

      return staticData;
    },
    [],
  );
};

export const getTransformedFilterList = (activeFilters: ActiveFilters) => {
  const uniqueFilters = uniqBy(
    flatMap(activeFilters),
    (item) => `${item.id}-${item.filterType}`,
  );

  return uniqueFilters.map((filter) => {
    const updatedFilterItem = { ...filter };
    const isAspiration = toLower(filter.filterType).includes('aspiration');
    const displayName = filter.fullName || filter.name;
    updatedFilterItem.name = isAspiration ? `Aspiration - ${displayName}` : displayName;
    return updatedFilterItem;
  });
};

// Clear filter-related params and return the rest
export const omitFilterParams = (searchParams: URLSearchParams) => {
  const nonFilterParams = {};

  // Currently, only data grid params are not filter-related
  DATA_GRID_PARAM_KEYS.forEach((paramKey) => {
    const dataGridParam = searchParams.get(paramKey);
    if (dataGridParam) {
      set(nonFilterParams, paramKey, dataGridParam);
    }
  });

  return nonFilterParams;
};

export const deleteDataGridParams = (
  searchParams: URLSearchParams,
  preserveSort?: boolean,
) => {
  DATA_GRID_PARAM_KEYS.forEach((paramKey) => {
    if (preserveSort && paramKey === 'sort') {
      return;
    }
    searchParams.delete(paramKey);
  });
};
