import { get } from 'lodash';

import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';

import { createReviewApi } from '../reviews.service';
import { useGetProfileReviews } from './useGetProfileReviews';

export const useCreateReview = (profileId: string, options: UseMutationOptions = {}) => {
  const { refetch: refetchProfileReviews } = useGetProfileReviews(profileId);
  const mutationResponse = useMutation(createReviewApi, {
    showSuccessSnackbar: true,
    transformInput: (input: any) => {
      const revieweeIds = get(input, 'reviewees').map((reviewee: any) => reviewee.id);
      return {
        revieweeIds,
        reviewerId: get(input, 'reviewer[0].id'),
        periodStart: get(input, 'reviewStartDate'),
        periodEnd: get(input, 'reviewEndDate'),
      };
    },
    onSuccess: () => {
      refetchProfileReviews();
    },
    ...options,
  });
  return mutationResponse;
};
