import React from 'react';
import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import useForm from 'common/hooks/useForm';
import FormTextField from 'common/components/input/FormTextField';
import InputAdornment from 'common/components/material/InputAdornment';
import { InlineIconButton } from 'features/org-root/org-root.styles';
import { WorkLocationRequestParams } from 'features/static-data/types/static-data-server-request.type';
import { HierarchicalExplorerFormProps } from 'features/data-explorer/types/hierarchical-explorer-form-props.type';

const StyledForm = styled('form')({
  width: '100%',
});

const ExplorerWorkLocationForm: React.FC<HierarchicalExplorerFormProps> = ({
  data,
  mapping = {},
  parentId,
  disabled,
  onChange,
  onClose,
}) => {
  const form = useForm<WorkLocationRequestParams>({
    defaultValues: {
      ...data,
    },
  });

  const handleSubmit = ({ name }: WorkLocationRequestParams) => {
    if (disabled) {
      return;
    }

    const parent = mapping[parentId as string];
    const dataToSubmit = {
      ...data,
      osmId: parent?.entityData.osmId,
      osmType: parent?.entityData.osmType,
      city: parent?.name,
      name,
    };

    onChange && onChange(dataToSubmit);
  };

  return (
    <FormProvider {...form}>
      <StyledForm noValidate onSubmit={form.handleSubmit(handleSubmit)}>
        <FormTextField
          autoFocus
          name="name"
          fullWidth
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InlineIconButton sx={{ marginRight: '0.25rem' }} type="submit">
                  <CheckIcon color="primary" />
                </InlineIconButton>
                <InlineIconButton onClick={onClose}>
                  <CloseIcon color="primary" />
                </InlineIconButton>
              </InputAdornment>
            ),
          }}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default ExplorerWorkLocationForm;
