import * as yup from 'yup';
import { useMemo } from 'react';

import { SKILLS_MAX_LIMIT } from 'common/constants/common.constants';
import { SkillSchema } from 'features/skills/skills.schemas';
import { TagSchema } from 'features/tags/schemas/tag.schema';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

export const usePraiseFormSchema = () => {
  const { canWrite: canAddSkills } = useFeatureAccess(FeaturesEnum.SKILLS);

  const skillYupCondition = yup
    .array()
    .of(SkillSchema)
    .label('skills')
    .max(SKILLS_MAX_LIMIT, `Max ${SKILLS_MAX_LIMIT} skills allowed`)
    .min(1, 'At least 1 skill is required')
    .required('At least 1 skill is required');

  const schemaShape = {
    receiver: yup
      .array()
      .label('receiver')
      .min(1, 'Praised person is required')
      .required('Praised person is required'),
    headline: yup
      .string()
      .nullable()
      .when('description', {
        is: (description: string) => !description,
        then: (schema) => schema.required('Headline is required when description is empty'),
        otherwise: (schema) => schema.notRequired(),
      }),
    description: yup
      .string()
      .nullable()
      .when('headline', {
        is: (headline: string) => !headline,
        then: (schema) => schema.required('Description is required when headline is empty'),
        otherwise: (schema) => schema.notRequired(),
      }),
    tags: yup.array().of(TagSchema).label('tags'),
    relatedProject: yup
      .array()
      .of(yup.object({ id: yup.string(), title: yup.string() }))
      .label('relatedProject')
      .nullable(),
  } as Record<string, any>;

  if (canAddSkills) {
    schemaShape['skills'] = skillYupCondition;
  }

  return useMemo(() => {
    return yup
      .object()
      .shape(schemaShape, [
        /*
         * Don't validate description when headline changes, and vice versa; each
         * field already checks the other, to make the field required or not, so if we don't
         * disable these below, yup will spot a dependency loop and error out.
         */
        ['headline', 'description'],
        ['description', 'headline'],
      ])
      .required();
  }, [canAddSkills]);
};
