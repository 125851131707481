import React from 'react';
import MUIAccordion, { AccordionProps } from '@mui/material/Accordion';
import MUIAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MUIAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';

const Accordion: React.FC<AccordionProps> = (props) => {
  const { children, ...accordionProps } = props;

  return <MUIAccordion {...accordionProps}>{children}</MUIAccordion>;
};

export const AccordionSummary: React.FC<AccordionSummaryProps> = (props) => {
  const { children, ...accordionSummaryProps } = props;

  return <MUIAccordionSummary {...accordionSummaryProps}>{children}</MUIAccordionSummary>;
};

export const AccordionDetails: React.FC<AccordionDetailsProps> = (props) => {
  const { children, ...accordionDetailsProps } = props;

  return <MUIAccordionDetails {...accordionDetailsProps}>{children}</MUIAccordionDetails>;
};

export default Accordion;
