import { TimeZone, getTimeZones } from '@vvo/tzdb';
import moment from 'moment';
import { isEmpty, find, filter } from 'lodash';

let timeZoneData: {
  timeZones: TimeZone[];
  localTimeZone: TimeZone | undefined;
  selectedTimezone: TimeZone | undefined;
} = {
  timeZones: [],
  localTimeZone: undefined,
  selectedTimezone: undefined,
};

const timezoneOffsetRegex = /[+-]?\d{2}:\d{2}/;

const getTimezoneMetadata = (name?: string) => {
  const rawTimeZones = getTimeZones({ includeUtc: true });
  const timeZones = rawTimeZones.map((timeZone) => {
    const formattedName = timeZone.currentTimeFormat;
    return {
      ...timeZone,
      currentTimeFormat: formattedName.replace(timezoneOffsetRegex, (timeZoneOffset) => {
        return `(GMT ${timeZoneOffset})`;
      }),
    };
  });

  const localOffset = moment().utcOffset();
  const localTimeZones = filter(timeZones, { currentTimeOffsetInMinutes: localOffset });
  const localTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTimeZone =
    find(localTimeZones, { name: localTimeZoneName }) || localTimeZones[0];

  // Default selectedTimezone to browser-detected timeZone if name not provided
  const selectedTimezone = find(timeZones, { name: name || localTimeZoneName });

  return {
    timeZones,
    localTimeZone,
    selectedTimezone,
  };
};

const useTimeZones = (timezoneName?: string) => {
  if (isEmpty(timeZoneData.timeZones) || timezoneName) {
    timeZoneData = getTimezoneMetadata(timezoneName);
  }

  return timeZoneData;
};

export default useTimeZones;
