import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { deleteOrganizationRoleApi } from '../organization-role.service';

export const useDeleteOrganizationRole = (options: UseMutationOptions = {}) => {
  const { emit } = useEventEmitter('OrganizationRoleDeleted');

  return useMutation(deleteOrganizationRoleApi, {
    showSuccessSnackbar: true,
    ...options,
    onSuccess: () => {
      emit();
    },
  });
};
