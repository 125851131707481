import React, { ChangeEvent, MutableRefObject, useState } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { isEmpty, map, every, filter, join } from 'lodash';
import { IMAGE_UPLOADER_PROMPT_STATES as UploaderState } from '../../media.constants';
import { useApiErrorHandler, useSnackbar, useVerifyImageMetaData } from 'common/hooks';
import { AxiosError } from 'axios';
import { FileInfo } from '../../types/media.types';

export type RenderFieldProps = ControllerRenderProps<any, string>;
export type LoadedFile = {
  status: string;
  message?: string;
  fileInfo: FileInfo;
};

export type FileInputProps = {
  name: string;
  multiple?: boolean;
  accept?: string;
};

export type HiddenUploaderProps = FileInputProps & {
  imageInputRef: MutableRefObject<HTMLInputElement | null>;
  clearOnLoaded?: boolean;
  onError?: (errorFiles: LoadedFile[]) => void;
  onLoading?: () => void;
  onLoaded?: (files: FileInfo[]) => void;
};

export const HiddenFileInput: React.FC<HiddenUploaderProps> = ({
  name,
  imageInputRef,
  clearOnLoaded,
  onError,
  onLoading,
  onLoaded,
  multiple = false,
  accept = 'image/*',
}) => {
  const { control } = useFormContext();
  const { formatError, getErrorMessage } = useApiErrorHandler();
  const { openSnackbar } = useSnackbar();
  const [fileList, setFileList] = useState<FileList | null>();

  const { mutateAsync: verifyImageMetaData } = useVerifyImageMetaData({
    showErrorSnackbar: false,
    showValidationErrorSnackbar: false,
  });

  const renderUploadInput = (field: RenderFieldProps) => {
    const changeHandler = async (e: ChangeEvent<HTMLInputElement>) => {
      onLoading && onLoading();
      const files = (e.target as HTMLInputElement).files;

      if (isEmpty(files)) {
        return;
      }

      const loadedFiles = await Promise.all(
        map(files, async (file) => {
          return new Promise<LoadedFile>(async (resolve) => {
            try {
              await verifyImageMetaData({ fileSize: file.size, fileType: file.type });

              if (file.type.split('/')[0] !== 'image') {
                resolve({
                  status: UploaderState.SUCCESS,
                  fileInfo: { url: '', name: file.name, file, size: file.size },
                });
              } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  const result = reader.result as string;
                  resolve({
                    status: UploaderState.SUCCESS,
                    fileInfo: { url: result, name: file.name, file, size: file.size },
                  });
                };
              }
            } catch (error) {
              const formattedError = formatError(error as AxiosError);
              resolve({
                status: UploaderState.ERROR,
                message: `file: ${file.name}, error: ${getErrorMessage(formattedError)}`,
                fileInfo: { url: '', name: file.name, file: null, size: 0 },
              });
            }
          });
        }),
      );

      if (every(loadedFiles, (loadedFile) => loadedFile.status === UploaderState.SUCCESS)) {
        onLoaded && onLoaded(map(loadedFiles, (loadedFile) => loadedFile.fileInfo));

        if (clearOnLoaded) {
          e.target.value = '';
        } else {
          field.onChange(files);
          setFileList(files);
        }
      } else {
        const errorFiles = filter(
          loadedFiles,
          (loadedFile) => loadedFile.status === UploaderState.ERROR,
        );
        const errorMessages = join(
          map(errorFiles, (result) => result.message),
          '; ',
        );

        openSnackbar(errorMessages, 'warning');
        onError && onError(errorFiles);
      }
    };

    return (
      <input
        type="file"
        accept={accept || 'image/*'}
        style={{ display: 'none' }}
        multiple={multiple}
        onChange={(event) => changeHandler(event)}
        ref={(instance: any) => {
          imageInputRef.current = instance;
        }}
      />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={fileList}
      render={({ field }) => renderUploadInput(field)}
    />
  );
};
