import { useGetVisibleOrganizationalStaticData } from 'features/static-data';
import { ACTIVE_TAB_FILTERS_CONFIG } from '../explore.constants';
import { getVisibleFilterMenuItems } from '../explore.utils';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { FeatureAccessContext } from 'features/feature-access/feature-accesses.provider';
import { useContext, useMemo } from 'react';
import { ContentTypeFiltersMenuItemConfig } from '../types/filter-explorer-props.type';

export const useGetFilterMenuItems = (
  selectedTab: ExploreTabEnum,
  contentTypesFiltersConfig: ContentTypeFiltersMenuItemConfig = ACTIVE_TAB_FILTERS_CONFIG,
) => {
  const { hasAccessPermission } = useContext(FeatureAccessContext);
  const { data: visibleStaticDataTypes = [], isLoading } =
    useGetVisibleOrganizationalStaticData();
  const currentTabMenuConfigNew =
    selectedTab in contentTypesFiltersConfig ? contentTypesFiltersConfig[selectedTab] : [];

  const filteredTabs = currentTabMenuConfigNew.filter((tab) => {
    return hasAccessPermission(tab.feature);
  });

  return {
    menuItems: useMemo(() => {
      return isLoading
        ? []
        : getVisibleFilterMenuItems(
            filteredTabs.map((tab) => tab.name) as ExplorerDataTypeEnum[],
            visibleStaticDataTypes,
          );
    }, [isLoading, filteredTabs, visibleStaticDataTypes]),
    isLoading,
  };
};
