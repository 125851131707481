import { styled } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

export const HoverArrowIcon = styled(EastRoundedIcon)({
  width: 15,
  height: 15,
  marginLeft: 'auto',
  marginRight: '0.25rem',
  opacity: 0,
  transform: 'none',
});
