import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Box, Container, Stack } from 'common/components/material';
import { OrgRootContainerProps } from '../types';
import { DEFAULT_APP_BAR_HEIGHT } from 'common/components/header/ApplicationBar';
import { useView } from 'common/hooks';

const verticalPadding = 1.5; //rem

const RootContainer: React.FC<OrgRootContainerProps> = ({
  header,
  headerNavButton,
  maxWidth,
  slimContent,
  fullWidth,
  children,
  spacing = 3,
  disableVerticalScroll,
}) => {
  const { palette } = useTheme();
  const { isDesktopView } = useView();
  const hasMaxHeight = disableVerticalScroll && isDesktopView;
  const maxHeight = hasMaxHeight
    ? `calc(100vh - ${DEFAULT_APP_BAR_HEIGHT} - ${verticalPadding * 2}rem)`
    : 'none';

  return (
    <Stack
      sx={{ backgroundColor: palette.Background }}
      py={`${verticalPadding}rem`}
      px={{ xs: fullWidth ? 0 : '1rem', md: '3rem' }}
    >
      <Container maxWidth={maxWidth || 'lg'} disableGutters>
        <Stack
          spacing={spacing}
          height="100%"
          px={{ xl: slimContent ? 10 : 0 }}
          maxHeight={maxHeight}
        >
          <Box sx={{ display: 'flex', px: { xs: fullWidth ? '1rem' : 0, md: 0 } }}>
            {headerNavButton}
            {header}
          </Box>
          {children}
        </Stack>
      </Container>
    </Stack>
  );
};

export default RootContainer;
