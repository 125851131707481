import React from 'react';
import MUICheckbox, { CheckboxProps as MUICheckboxProps } from '@mui/material/Checkbox';

export type CheckboxProps = MUICheckboxProps;

const Checkbox: React.FC<CheckboxProps> = (props) => {
  return <MUICheckbox {...props} sx={{ p: 0, ...props.sx }}></MUICheckbox>;
};

export default Checkbox;
