import { FieldName } from 'common/types/Form.type';
import React from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
  useTheme,
} from '@mui/material';

import { RadioGroupOption } from 'common/types/radio-group.type';
import { Box } from '../material';

type RenderRadioGroupProps = {
  field: ControllerRenderProps<any, string>;
  fieldState: ControllerFieldState;
};

// This component is a controlled text field and must be placed inside a FormProvider
const RadioGroup = React.forwardRef((props: any, ref: any) => {
  const {
    name,
    errorMessage,
    onChange,
    defaultValue,
    value,
    labelPlacement,
    label: groupLabel,
    options,
    initialValue,
    radioSize,
    ...otherProps
  } = props;

  const { palette } = useTheme();
  const { control } = useFormContext();

  const renderRadioGroup = ({ field }: RenderRadioGroupProps) => {
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      field.onChange(e);
      onChange && onChange(value);
    };

    return (
      <MUIRadioGroup
        row
        name={groupLabel}
        value={value}
        defaultValue={defaultValue}
        ref={ref}
        {...otherProps}
      >
        {options.map(
          ({ label, value: optionValue, disabled }: RadioGroupOption, index: number) => {
            const selected = optionValue === value;
            const isSmall = radioSize === 'small';
            const radioLabel = isSmall ? (
              <Box display="flex" alignItems="center" marginLeft="-4px" fontSize="0.75rem">
                {label}
              </Box>
            ) : (
              label
            );
            return (
              <FormControlLabel
                key={index}
                value={optionValue}
                name={label}
                className={selected ? 'selected' : ''}
                control={
                  <Radio
                    sx={{
                      color: palette.Tabs.Secondary.LabelDefault,
                      '&.Mui-checked': {
                        color: disabled
                          ? palette.Buttons.Primary.LabelDisabled
                          : palette.Tabs.Secondary.LabelClicked,
                      },
                    }}
                    onChange={onChangeHandler}
                    size={radioSize || 'medium'}
                    disabled={disabled}
                    disableRipple={disabled}
                  />
                }
                labelPlacement={labelPlacement}
                label={radioLabel}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: palette.Tabs.Secondary.LabelDefault,
                    '&.Mui-disabled': {
                      color: palette.Text.Disabled,
                    },
                  },
                  '&.MuiFormControlLabel-root:hover .MuiFormControlLabel-label': {
                    color: disabled
                      ? palette.Text.Disabled
                      : palette.Tabs.Secondary.LabelHover,
                  },
                }}
              />
            );
          },
        )}
      </MUIRadioGroup>
    );
  };

  return (
    <Controller
      control={control}
      name={name as FieldName}
      defaultValue={defaultValue || value}
      render={renderRadioGroup}
    />
  );
});

export default RadioGroup;
