import { useTheme } from '@mui/material/styles';
import Typography from 'common/components/material/Typography';
import { RenderTextFieldProps } from 'common/types/renderer-text-field-props.type';
import { CXEditorRendererProps } from '../types';
import { CXEditor } from './cx-editor.component';

/**
 * This component is consumed in react form field, which is a combination of CXEditor and Typography. Here CXEditor emits the changes to its parent component and
 * shows the error in typography component whenever provided.
 */
export function CXEditorRenderer(props: CXEditorRendererProps) {
  const { palette } = useTheme();
  return ({ field }: RenderTextFieldProps) => {
    const onEditorChange = (value: string) => {
      field.onChange(value);
      props.onChange && props.onChange(value);
    };

    const onAddImageHookTrigger = () => {
      props.onAddImageHookTrigger && props.onAddImageHookTrigger();
    };

    return (
      <div data-role="cx-editor">
        <CXEditor
          ref={props.ref}
          onChange={onEditorChange}
          autoFocus={props.autoFacus}
          placeholder={props?.placeholder}
          initialValue={field.value || ''}
          onAddImageHookTrigger={onAddImageHookTrigger}
        />
        {props.errorMessage && (
          <Typography
            color={palette.Failure}
            lineHeight="0.937rem"
            variant="caption"
            margin="0 14px"
          >
            {props.errorMessage}
          </Typography>
        )}
      </div>
    );
  };
}
