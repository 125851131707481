import { ReactElement } from 'react';

import { FeaturesEnum } from '../features.enum';
import { FeatureAccessEnum } from '../enums/feature-access.enum';
import { useFeatureAccess } from '../hooks/useFeatureAccess';

export const FeatureGuard: React.FC<{
  features: { feature: FeaturesEnum; access?: FeatureAccessEnum }[];
  fallback?: ReactElement;
}> = ({ features, children, fallback = <></> }) => {
  let hasAccess = false;
  for (let { feature, access } of features) {
    access = access ?? FeatureAccessEnum.READ;
    // When we call useFeatureAccess() inside a loop, eslint complains because
    // React Hooks must be called in the exact same order during every component render.
    //
    // However, we know that the features we pass to this guard are static and
    // will always have the exact same order, so it’s safe to ignore this warning.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const featureAccess = useFeatureAccess(feature);
    hasAccess ||= featureAccess[access];
  }
  return hasAccess ? <>{children}</> : fallback;
};
