import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React from 'react';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { useDeferredState } from 'common/hooks';
import { FieldName } from 'common/types/Form.type';
import TextField, { TextFieldProps } from './TextField';

type YearPickerProps = {
  label?: string;
  name?: string;
  initYear?: number | null;
  onDateSelected: (value: number | null) => void;
  displayDefaultDate?: boolean;
  onYearChange?: () => void;
  maxDate?: Date;
  minDate?: Date;
} & TextFieldProps;

const YearPicker: React.FC<YearPickerProps> = ({
  label = 'Select Date',
  name = '',
  initYear,
  onDateSelected,
  displayDefaultDate = true,
  onYearChange,
  maxDate,
  minDate,
  ...otherProps
}) => {
  const getUTCYear = (date: Date | null) => {
    const dateMoment = moment(date);
    return dateMoment.isValid() ? dateMoment.utc().toDate().getFullYear() : null;
  };

  const getUTCDate = (year: number | null) => {
    return year ? new Date(year, 0) : !displayDefaultDate ? null : new Date();
  };

  const [date, setDate] = useDeferredState<Date | null>(getUTCDate(initYear || null));

  const { control, formState, trigger } = useFormContext();
  const errorMessage = get(formState.errors, `${name}.message`);

  return (
    <Controller
      control={control}
      name={name as FieldName}
      render={({ field: { onChange } }: any) => {
        const changeHandler = (newValue: Date | null) => {
          const selectedYear = getUTCYear(newValue);
          onChange(selectedYear);
          onYearChange && onYearChange();
          onDateSelected(selectedYear);
          setDate(newValue);
          trigger(name);
        };

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year']}
              openTo="year"
              label={label}
              value={date}
              onChange={(newValue: Date | null) => {
                changeHandler(newValue);
              }}
              maxDate={maxDate}
              minDate={minDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...otherProps}
                  error={!!errorMessage}
                  helperText={errorMessage}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
export default YearPicker;
