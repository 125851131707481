import { useTheme } from '@mui/material';
import { Typography } from 'common/components/material';

export const BannerDurationText: React.FC<{ days?: number; isExpired?: boolean }> = ({
  days = 0,
  isExpired = false,
}) => {
  const { palette } = useTheme();

  if (days <= 1) {
    return (
      <Typography variant="caption" fontWeight={500} color={palette.Failure}>
        {days === 0 ? `today` : isExpired ? `yesterday` : `tomorrow`}
      </Typography>
    );
  }

  if (days <= 7) {
    return (
      <Typography variant="caption" fontWeight={500} color={palette.Failure}>
        {days} days{isExpired && ' ago'}
      </Typography>
    );
  }

  const weeks = Math.ceil(days / 7);
  return (
    <Typography
      variant="caption"
      fontWeight={600}
      color={isExpired ? palette.Failure : palette.Text.Headline}
    >
      {weeks} weeks{isExpired && ' ago'}
    </Typography>
  );
};
