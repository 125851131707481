import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Chip, { ChipProps } from 'common/components/material/Chip';

import { ReactComponent as DeleteChipIcon } from 'common/svg-icons/cancelIcon.svg';
import { MUIRef } from 'common/types/MUIRef.type';

type FilterChipProps = ChipProps;

const StyledChip = styled(Chip)(({ theme, size }) => ({
  backgroundColor: theme.palette.SelectionChips.Primary.BgDefault,
  color: theme.palette.SelectionChips.Primary.LabelAndIconDefault,
  paddingRight: '0.25rem',
  // undefined fontSize here means it will just use the default MUI Chip fontSize
  fontSize: size === 'small' ? theme.typography.caption.fontSize : undefined,
  '&:hover': {
    backgroundColor: theme.palette.SelectionChips.Primary.BgHover,
  },
}));

const FilterChip: React.FC<FilterChipProps> = React.forwardRef(
  (props: FilterChipProps, ref: MUIRef) => {
    const { palette } = useTheme();
    return (
      <StyledChip
        {...props}
        ref={ref}
        deleteIcon={
          <DeleteChipIcon
            fill={palette.SelectionChips.Primary.LabelAndIconDefault}
            style={{ flexShrink: 0 }}
          />
        }
      />
    );
  },
);

export default FilterChip;
