import { ErrorResponse } from 'common/types/Api.type';

// Extracts the first valid email inside a string
export const extractEmail = (text = '') => {
  const emailMatch = text.match(/([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,})/);
  return emailMatch && emailMatch[0];
};

export const transformCodeVerificationError = (error?: ErrorResponse) => {
  if (!error) {
    return;
  }

  if (error.statusCode !== 400) {
    return error;
  }

  return {
    ...error,
    email: extractEmail(error.message),
  };
};

export const stringToBoolean = (value?: string | null) => {
  if (!value) {
    return false;
  }
  switch (value) {
    case 'false':
    case '0':
    case 'null':
    case 'undefined':
      return false;
    default:
      return true;
  }
};
