import React from 'react';

import EventManager from 'common/utils/EventManager';
import { useHistoryIndexContext } from './hooks/historyIndex';
import { useScrollPositionContext } from './hooks/useScrollPositionContext';
import { useAuthentication } from './hooks/useAuthentication';
import { ApplicationContext } from './application-context';
import { useThemeContext } from './hooks/useThemeContext';

type ApplicationContextProviderProps = {
  children: React.ReactElement;
};

const ApplicationContextProvider: React.FC<ApplicationContextProviderProps> = ({
  children,
}) => {
  const authenticationContext = useAuthentication();
  const eventManager = new EventManager();
  const historyIndex = useHistoryIndexContext();
  const scrollPosition = useScrollPositionContext();
  const themeContext = useThemeContext();

  const context = {
    authenticationContext,
    eventManager,
    historyIndex,
    scrollPosition,
    themeContext
  };

  return (
    <ApplicationContext.Provider value={context}>{children}</ApplicationContext.Provider>
  );
};

export default ApplicationContextProvider;
