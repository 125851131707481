import { Typography } from 'common/components/material';
import { CXChipProps } from './cx-chip-props.type';
import { StyledChip } from './cx-chip.styles';

export const CXChip: React.FC<CXChipProps> = ({ label, ...chipProps }) => {
  return (
    <StyledChip
      size="small"
      label={
        <Typography variant="body4" fontWeight={500}>
          {label}
        </Typography>
      }
      {...chipProps}
    />
  );
};
