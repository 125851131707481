import { QueryFunction, QueryKey, useQuery as _useQuery } from 'react-query';

import { UseQueryResponse, UseQueryOptions } from 'common/types/Api.type';
import { useApiErrorHandler, useApiSuccessHandler } from './';

/**
 * A wrapper for React Query's useQuery which manages the
 * state for API calls that request data from the server.
 * This is where we'll setup defaults and generic handlers for all queries.
 */
const useQuery = (
  queryKey: QueryKey,
  queryFn: QueryFunction<unknown, any>,
  options?: UseQueryOptions,
): UseQueryResponse => {
  const {
    showSuccessSnackbar = false,
    showErrorSnackbar = false,
    showValidationErrorSnackbar = false,
    shouldShowErrorSnackbar,
    ...opts
  } = options || {};
  const { formatError, errorHandler } = useApiErrorHandler(
    opts?.onError,
    showErrorSnackbar,
    showValidationErrorSnackbar,
    shouldShowErrorSnackbar,
  );
  const successHandler = useApiSuccessHandler(opts?.onSuccess, showSuccessSnackbar);
  /**
   * Following are the reasoning behind to add stale time to react query:
   * ----------------------------------------------------------------------------------------------------------------------------
   * Problem: Duplicate API request.
   * Analysis: This is mainly because staleTime defaults to zero, which means that every time we e.g. mount a new component instance,
   * we will get a background refetch. If we do this a lot, especially with mounts in short succession that are not in the same render cycle,
   * we see duplicate fetches in the network tab. That's because React Query can't deduplicate in such situations.
   * (https://tkdodo.eu/blog/react-query-as-a-state-manager#letting-react-query-do-its-magic)
   *
   * Solution: StaleTime (https://tkdodo.eu/blog/react-query-as-a-state-manager#customize-staletime)
   * How It works: By setting staleTime to a minimum, we ensure that the cached data is considered fresh for a very short period. During this time,
   * any hook instance requesting the data will use the cached version instead of making a new request to the server. This helps deduplicate requests
   * and avoid multiple API calls, as React Query can serve the cached data to all instances within the specified staleTime.
   *
   */
  const query = _useQuery(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    staleTime: 3_000,
    ...opts,
    onError: errorHandler,
    onSuccess: successHandler,
  });
  return {
    ...query,
    error: formatError(query.error),
  };
};

export default useQuery;
