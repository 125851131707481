import { CardProps, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import CollapsibleCard from 'common/components/cards/collapsible-card.component';
import { VerticalScrollableContainer } from 'common/components/container';
import EmptyStaticDataImage from 'common/components/image/empty-static-data-image.component';
import { Button, Stack, Typography } from 'common/components/material';
import ModalContainer from 'common/components/modals/ModalContainer';
import { useView } from 'common/hooks';
import { useGetSearchRelatedPages } from 'features/search/hooks/useGetSearchRelatedPages';
import { RelatedPage } from '../types/related-page.type';
import RelatedPagesSkeleton from './related-pages-skeleton.component';
import StaticDataPageLinkItem from './static-data-page-link-item.component';

export const SearchRelatedPages: React.FC<CardProps> = (props) => {
  const { data, isFetching } = useGetSearchRelatedPages();
  const { palette } = useTheme();
  const { isMobileView } = useView();
  const [openAllRelatedPagesModal, setOpenAllRelatedPagesModal] = useState(false);

  const content = isFetching ? (
    <RelatedPagesSkeleton size={isMobileView ? 1 : 2} />
  ) : isEmpty(data) ? (
    <Stack display={'flex'} height="100%" alignItems="center" padding={2} spacing={1}>
      <EmptyStaticDataImage />
      <Typography variant="h4" fontSize={14} fontWeight={600} color={palette.Text.Headline}>
        No Related Pages
      </Typography>
    </Stack>
  ) : isMobileView && !openAllRelatedPagesModal ? (
    <StaticDataPageLinkItem key={data[0].id} relatedPage={data[0]} />
  ) : (
    <>
      {data.map((relatedPage: RelatedPage) => (
        <StaticDataPageLinkItem key={relatedPage.id} relatedPage={relatedPage} />
      ))}
    </>
  );

  return (
    <>
      <CollapsibleCard
        label="Related Pages"
        headerActions={
          isMobileView &&
          !isEmpty(data) && (
            <Button
              btntype="label"
              size="small"
              onClick={() => setOpenAllRelatedPagesModal(true)}
            >
              See All
            </Button>
          )
        }
        {...props}
      >
        <VerticalScrollableContainer disableGutters maxHeight="35vh" minHeight="10vh">
          {content}
        </VerticalScrollableContainer>
      </CollapsibleCard>
      {isMobileView && openAllRelatedPagesModal && (
        <ModalContainer
          title="Related Pages"
          open={isMobileView && openAllRelatedPagesModal}
          onClose={() => setOpenAllRelatedPagesModal(false)}
        >
          {content}
        </ModalContainer>
      )}
    </>
  );
};
