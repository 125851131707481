import React from 'react';
import { CXAvatarUploader } from 'common/components/cx-avatar/cx-avatar-uploader';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import LocationIcon from '@mui/icons-material/PlaceOutlined';
import { StaticDataAvatarProps } from '../types';

export const StaticDataAvatarComponent: React.FC<StaticDataAvatarProps> = ({
  fullName,
  size = 'medium',
  readOnly = true,
  isLocation = false,
  sx,
  ...avatarProps
}) => {
  return (
    <CXAvatarUploader
      alt={fullName}
      size={size}
      icon={isLocation ? LocationIcon : ApartmentRoundedIcon}
      readOnly={readOnly}
      sx={sx}
      {...avatarProps}
    ></CXAvatarUploader>
  );
};
