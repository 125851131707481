import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';

import { getTotalUnreadNotificationsApi } from '../notifications.service';

export const useGetTotalUnreadNotifications = (options: UseQueryOptions = {}) => {
  const {
    isAccountFetching,
    isProfileFetching,
    profile: loggedInProfile,
    organizationProfile,
    account: user,
  } = useSession();

  const queryResponse = useQuery(
    ['unreadNotifications', loggedInProfile?.id, user?.organizationId],
    getTotalUnreadNotificationsApi,
    {
      // - when Switching organization, a GET /api/profiles request is fired, and before returning from it, the server _updates_ the user session to set the new organization as _current_
      // - if other requests are fired (like this one) before the GET /api/profiles is completed, they will load the old session value; and if for whatever reason these ended up completing _after_ the GET /api/profiles one (the one that updates the user session) they would reset the current organization to the _old_ value
      // - so here we wait for the profile to have reloaded -- tho ideally, we should stop tampering w/ the user session
      enabled: !isAccountFetching && !isProfileFetching && !!organizationProfile?.isOnboarded,
      ...options,
    },
  );

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.total || 0,
  };
};
