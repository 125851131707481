import {
  OrganizationalStaticDataTypeEnum,
  StaticDataTypeEnum,
  useGetVisibleOrganizationalStaticData,
} from 'features/static-data';
import { OrgStaticDataSelector, StaticDataSelector } from 'common/components/input';
import { isEmpty } from 'lodash';
import MultiOrgStaticDataSelector from 'common/components/input/MultiOrgStaticDataSelector';
import { OrganizationRoleStaticDataFormFieldsProps } from '../types/organization-role-static-data-form-fields-props.type';

const OrganizationRoleStaticDataFormFields: React.FC<
  OrganizationRoleStaticDataFormFieldsProps
> = ({ valueFieldPrefix = '' }) => {
  const { data: visibleStaticData } = useGetVisibleOrganizationalStaticData();

  if (isEmpty(visibleStaticData)) {
    return <></>;
  }

  return (
    <>
      {visibleStaticData.includes(OrganizationalStaticDataTypeEnum.DEPARTMENT) && (
        <OrgStaticDataSelector
          name={`${valueFieldPrefix}department`}
          label="Department"
          fullWidth
          type={OrganizationalStaticDataTypeEnum.DEPARTMENT}
        />
      )}
      {visibleStaticData.includes(OrganizationalStaticDataTypeEnum.DIVISION) && (
        <OrgStaticDataSelector
          name={`${valueFieldPrefix}division`}
          label="Division"
          fullWidth
          type={OrganizationalStaticDataTypeEnum.DIVISION}
        />
      )}

      {visibleStaticData.includes(OrganizationalStaticDataTypeEnum.PRODUCT) && (
        <MultiOrgStaticDataSelector
          name={`${valueFieldPrefix}products`}
          label="Products"
          fullWidth
          type={OrganizationalStaticDataTypeEnum.PRODUCT}
        />
      )}

      <StaticDataSelector
        name={`${valueFieldPrefix}location`}
        label="Location"
        fullWidth
        type={StaticDataTypeEnum.LOCATIONS}
      />
    </>
  );
};

export default OrganizationRoleStaticDataFormFields;
