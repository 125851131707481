import React from 'react';

import MUIBox, { BoxProps as MUIBoxProps } from '@mui/material/Box';

export type BoxProps = MUIBoxProps;

const Box: React.FC<MUIBoxProps> = React.forwardRef((props, ref: any) => {
  const { children, ...boxProps } = props;

  return (
    <MUIBox {...boxProps} ref={ref}>
      {children}
    </MUIBox>
  );
});

export default Box;
