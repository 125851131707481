import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import React from 'react';
import { styled } from '@mui/material/styles';

import { Stack } from 'common/components/material';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { useView } from 'common/hooks';
import { OpportunitySearchViewProps, useGetOpportunities } from 'features/opportunities';
import InfiniteScrollContainer from 'features/org-root/components/InfiniteScrollContainer';
import ComingSoonView from './ComingSoonView';
import ExploreContainer from './ExploreContainer';
import SearchSkeletonView from 'features/search/components/search-skeleton-view.component';
import { useGetPraises } from 'features/praise';
import { COMING_SOON_TEXT } from '../explore.constants';
import { OpportunityCreateActionButton } from 'features/opportunities/components/opportunity-create-button.component';
import { useExploreTabs } from '../hooks/useExploreTabs';
import { ExploreOrganizationTabType } from 'features/org-root/config/explore-organization-tabs.config';
import OrganizationEventsSideBarPanelWrapper from 'features/organization-events/components/organization-events-sidebar-panel-wrapper.component';
import { suspendable } from 'common/utils/suspendable';

const PeopleSearchRouter = suspendable(
  React.lazy(() => import('./people-search-router.component')),
);
const OpportunitySearchView = suspendable(
  React.lazy(() => import('./OpportunitySearchView')),
);
const PraiseSearchView = suspendable(React.lazy(() => import('./PraiseSearchView')));
const CompanyUpdateSearchView = suspendable(
  React.lazy(() => import('./CompanyUpdateSearchView')),
);
const OrganizationEventsRouter = suspendable(
  React.lazy(
    () =>
      import('features/organization-events/components/organization-events-routes.component'),
  ),
);
const VideoSearchView = suspendable(
  React.lazy(() => import('features/videos/components/video-search-view')),
);
const QuickLinksSearchView = suspendable(
  React.lazy(() => import('./quick-links-search-view.component')),
);
const WinListsSearchView = suspendable(
  React.lazy(() => import('features/win-lists/components/win-list-search-view.component')),
);

export const ButtonTabsContainer = styled('div')(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  backgroundColor: theme.palette.Background,
}));

const Explore: React.FC<{
  selectedTab?: ExploreTabEnum;
}> = ({ selectedTab = ExploreTabEnum.PEOPLE }) => {
  const { isDesktopView } = useView();

  const { exploreTabs } = useExploreTabs();

  const getSelectedTabIcon = (selectedTab: string) => {
    const tabIcon = exploreTabs.find(
      (tab: ExploreOrganizationTabType) => tab.value === selectedTab,
    );
    return tabIcon?.activeIcon || ListAltRoundedIcon;
  };

  const OpportunitySearchViewWrapper = (props: OpportunitySearchViewProps) => {
    return (
      <OpportunitySearchView createButton={<OpportunityCreateActionButton />} {...props} />
    );
  };

  const SideBarPanel =
    isDesktopView && selectedTab === ExploreTabEnum.ORGANIZATION_EVENTS
      ? OrganizationEventsSideBarPanelWrapper
      : null;

  return (
    <ExploreContainer selectedTab={selectedTab} SideBarPanel={SideBarPanel}>
      <Stack pt={{ xs: 2, md: 1.5 }} spacing={2}>
        {(() => {
          switch (selectedTab) {
            case ExploreTabEnum.PEOPLE:
              return <PeopleSearchRouter />;
            case ExploreTabEnum.OPPORTUNITIES:
              return (
                <InfiniteScrollContainer
                  key={ExploreTabEnum.OPPORTUNITIES}
                  infiniteApiHook={useGetOpportunities}
                  InfiniteListContainer={OpportunitySearchViewWrapper}
                  LoaderComponent={SearchSkeletonView}
                  showFooter={false}
                />
              );
            case ExploreTabEnum.PRAISE:
              return (
                <InfiniteScrollContainer
                  key={ExploreTabEnum.PRAISE}
                  infiniteApiHook={useGetPraises}
                  InfiniteListContainer={PraiseSearchView}
                  LoaderComponent={SearchSkeletonView}
                  showFooter={false}
                />
              );
            case ExploreTabEnum.COMPANY_UPDATES:
              return <CompanyUpdateSearchView />;
            case ExploreTabEnum.ORGANIZATION_EVENTS:
              return <OrganizationEventsRouter />;
            case ExploreTabEnum.VIDEOS:
              return <VideoSearchView />;
            // case ExploreTabEnum.QUICK_LINKS:
            // return <QuickLinksSearchView />;
            case ExploreTabEnum.WIN_LIST:
              return <WinListsSearchView />;
            default:
              return (
                <ComingSoonView
                  subText={COMING_SOON_TEXT[selectedTab]}
                  Icon={getSelectedTabIcon(selectedTab)}
                />
              );
          }
        })()}
      </Stack>
    </ExploreContainer>
  );
};

export default Explore;
