import { useLocalStorage } from 'common/hooks';
import useSessionStorage from 'common/hooks/useSessionStorage';
import { ORGANIZATION_ID_KEY, ORGANIZATION_SUBURL_KEY } from 'common/utils/app.utils';

const useOrganizationStorage = () => {
  const { value: defaultOrganizationId, setItemValue: setDefaultOrganizationId } =
    useLocalStorage(ORGANIZATION_ID_KEY);
  const { value: defaultSubUrl, setItemValue: setDefaultSubUrl } =
    useLocalStorage(ORGANIZATION_SUBURL_KEY);
  const { value: sessionOrganizationId, setItemValue: setSessionOrganizationId } =
    useSessionStorage(ORGANIZATION_ID_KEY);
  const { value: sessionSubUrl, setItemValue: setSessionSubUrl } =
    useSessionStorage(ORGANIZATION_SUBURL_KEY);

  const setOrganizationOnSession = (
    organizationId: string | null,
    subUrl?: string | null,
  ) => {
    setDefaultOrganizationId(organizationId);
    setSessionOrganizationId(organizationId);
    setDefaultSubUrl(subUrl);
    setSessionSubUrl(subUrl);
  };

  return {
    organizationOnSession: {
      subUrl: sessionSubUrl || defaultSubUrl,
      organizationId: sessionOrganizationId || defaultOrganizationId,
    },
    setOrganizationOnSession,
  };
};

export default useOrganizationStorage;
