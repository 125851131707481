import { useEvents, useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getProjectApi } from '../services/projects.service';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { EVENTS_TO_REFETCH_PROJECTS } from '../project.utils';
import { Project } from '../types';

export const useGetProject = (projectId?: string, options: UseQueryOptions = {}) => {
  const { account: user } = useSession();
  const queryResponse = useQuery(
    [user.organizationId, 'project', projectId],
    () => getProjectApi(projectId),
    {
      enabled: !!projectId,
      select: (response) => {
        return {
          ...response,
          data: response.data,
        };
      },
      ...options,
    },
  );

  useEvents(EVENTS_TO_REFETCH_PROJECTS as Event[], (data: Project) => {
    if (data.id === projectId) {
      return queryResponse.refetch();
    }
  });

  return {
    ...queryResponse,
    data: queryResponse.isSuccess ? queryResponse?.data.data : {},
  };
};
