import { Stack, styled } from '@mui/material';

export const HoverableItem = styled(Stack)(({ theme }) => ({
  padding: '.5rem',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.LinkContainer.BgHover,
    borderRadius: '0.75rem',
    '#arrow-icon': {
      opacity: 1,
      transform: 'translateX(0.3rem);',
      transition: 'all 0.25s ease-in',
    },
  },
}));
