import useTheme from '@mui/material/styles/useTheme';
import { styled } from '@mui/system';

import { Stack, Typography } from 'common/components/material';

const NoNotificationsImage = styled('img')({
  height: '12rem',
  width: 'auto',
});

const EmptyNotifications: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: '40rem' }}>
      <NoNotificationsImage src="/images/noNotifications.svg" />
      <Typography
        variant="h4"
        sx={{ color: palette.Text.Headline, fontWeight: 600, marginTop: '3rem' }}
      >
        No notifications yet!
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '0.75rem' }}>
        Check back later for updates
      </Typography>
    </Stack>
  );
};

export default EmptyNotifications;
