import { List, Stack } from 'common/components/material';
import { SearchProfile } from 'features/profiles';

const PrinciplesFilterColumn = ({
  data,
  children,
  renderItem,
}: {
  data: SearchProfile[];
  children?: React.ReactNode;
  renderItem: (data: SearchProfile) => void;
}) => {
  return (
    <Stack flex={1} p={1} height="100%" sx={{ overflowY: 'auto' }} spacing={1}>
      {!!data.length && (
        <List disablePadding>{data.map((staticData) => renderItem(staticData))}</List>
      )}
      {children}
    </Stack>
  );
};

export default PrinciplesFilterColumn;
