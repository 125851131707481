import React from 'react';

import { ElevationScroll } from 'common/components/container';
import { BackButton } from 'common/components/navigation';

import ApplicationBar from './ApplicationBar';

type CrossBarProps = {
  backButton?: React.ReactElement;
  closeButton: React.ReactElement;
  showBackButton?: boolean;
  Header?: React.ReactElement;
  customHeight?: string;
};

const CrossBar: React.FC<CrossBarProps> = ({
  showBackButton = true,
  backButton,
  closeButton,
  Header,
  customHeight,
}) => {
  return (
    <>
      <ElevationScroll>
        <ApplicationBar customHeight={customHeight}>
          <>
            {showBackButton && (backButton || <BackButton />)}
            {Header}
            {closeButton}
          </>
        </ApplicationBar>
      </ElevationScroll>
    </>
  );
};

export default CrossBar;
