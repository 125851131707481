import { cloneDeep } from 'lodash';
import { useTheme } from '@mui/material';

import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { ExplorerLevelConfig, HierarchicalExplorerItemAction } from 'features/data-explorer';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { useGetExplorerData } from 'features/data-explorer/hooks/useGetExplorerData';
import { ActiveFilterMap, transformActiveFiltersMapToObject } from 'features/search';
import { FILTER_EXPLORER_CONFIG } from 'features/data-explorer/filter-explorer.config';
import FilterListItem from 'features/explore/components/FilterListItem';
import ExploreSelectorLoader from './explore-selector-loader.component';
import CXTreeView from 'libs/mui/tree-view/components/cx-tree-view.component';
import { ExploreHierarchicalTreeItem } from './explore-hierarchical-tree-item.component';
import CXIcon from 'common/components/cx-icon/cx-icon.component';
import { EVENT_TYPE_ICONS_MAPPING } from 'features/organization-events/utils';

export const ExploreSelectorHierarchicalComponent: React.FC<{
  contentType: ExploreTabEnum;
  type: ExplorerDataTypeEnum;
  searchTerm?: string;
  onItemAction: (action: HierarchicalExplorerItemAction) => void;
  activeFilterMap?: ActiveFilterMap;
}> = ({
  contentType = ExploreTabEnum.PEOPLE,
  type,
  searchTerm,
  onItemAction,
  activeFilterMap,
}) => {
  const { palette } = useTheme();
  const config = FILTER_EXPLORER_CONFIG[type];
  const selectedSearchFilters = transformActiveFiltersMapToObject(
    activeFilterMap,
    config.excludeFilterTypes || [type],
  );

  const { data, isLoading } = useGetExplorerData({
    config,
    type,
    includeMapping: true,
    searchTerm,
    contentType: contentType,
    ...selectedSearchFilters,
  });

  if (isLoading) {
    return <ExploreSelectorLoader />;
  }

  const staticDataList = data?.result || [];
  const mapping = cloneDeep(data?.mapping) || {};

  if (!staticDataList?.length) {
    return <>No Result</>;
  }

  if (searchTerm) {
    return staticDataList.map((data: any) => (
      <FilterListItem
        key={data.id}
        data={data}
        onItemAction={(itemAction) => {
          onItemAction({ ...itemAction, mapping });
        }}
        activeFilterMap={activeFilterMap}
        mapping={mapping}
        dataType={type}
        labelKey="fullName"
        config={{} as ExplorerLevelConfig}
        size="small"
      />
    ));
  }

  const rootNodes = mapping?.root?.children;

  if (rootNodes.length && type === ExplorerDataTypeEnum.EVENT_TYPE) {
    rootNodes?.forEach((eventType: string) => {
      const iconColor = palette.EventTagChip[eventType]?.IconFill;
      const icon = EVENT_TYPE_ICONS_MAPPING[eventType];
      mapping[eventType].IconComponent = (
        <CXIcon Icon={icon?.muiIcon} svg={icon?.svg} color={iconColor} />
      );
    });
  }

  return (
    <CXTreeView
      data={mapping}
      CustomTreeItemComponent={ExploreHierarchicalTreeItem}
      customTreeItemProps={{ mapping, onNodeClicked: onItemAction, activeFilterMap, type }}
    />
  );
};
