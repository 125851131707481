import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import ExploreView from 'features/explore/components/ExploreView';
import { ExploreRoutesEnum } from './explore.enums';
import NavigateToOrgRoute from 'common/components/navigation/NavigateToOrgRoute';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

const ExploreRouter: React.FC = () => {
  const defaultPeopleRoute = () => {
    if (!window.location.search) {
      return ExploreRoutesEnum.PEOPLE;
    }
    return `${ExploreRoutesEnum.PEOPLE}${window.location.search}`;
  };
  return (
    <Routes>
      <Route
        path={ExploreRoutesEnum.PEOPLE}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.OTHER_PROFILE }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView />
          </FeatureGuard>
        }
      />
      <Route
        path={ExploreRoutesEnum.OPPORTUNITIES}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.OPPORTUNITIES }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.OPPORTUNITIES} />
          </FeatureGuard>
        }
      />
      <Route
        path={ExploreRoutesEnum.ALUMNI}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.OTHER_PROFILE }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.ALUMNI} />
          </FeatureGuard>
        }
      />
      <Route
        path={ExploreRoutesEnum.BEHAVIORS}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.PRINCIPLES_US }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.BEHAVIOR} />
          </FeatureGuard>
        }
      />
      <Route
        path={ExploreRoutesEnum.PRAISE}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.PRAISES }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.PRAISE} />
          </FeatureGuard>
        }
      />
      {/* <Route
        path={ExploreRoutesEnum.REVIEWS}
        element={
        <ExploreView selectedTab={ExploreTabEnum.REVIEWS} />}
      /> */}
      <Route
        path={ExploreRoutesEnum.COMPANY_UPDATES}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.ALL_COMPANY_UPDATES }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.COMPANY_UPDATES} />
          </FeatureGuard>
        }
      />
      <Route
        path={ExploreRoutesEnum.ORGANIZATION_EVENTS}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.ALL_ORGANIZATION_EVENTS }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.ORGANIZATION_EVENTS} />
          </FeatureGuard>
        }
      />
      <Route
        path={ExploreRoutesEnum.VIDEOS}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.VIDEOS }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.VIDEOS} />
          </FeatureGuard>
        }
      />
      {/* <Route
        path={ExploreRoutesEnum.QUICK_LINKS}
        element={<ExploreView selectedTab={ExploreTabEnum.QUICK_LINKS} />}
      /> */}
      <Route
        path={ExploreRoutesEnum.WIN_LIST}
        element={
          <FeatureGuard
            features={[{ feature: FeaturesEnum.WINS }]}
            fallback={<NavigateToOrgRoute route={AppRoutesEnum.HOME} />}
          >
            <ExploreView selectedTab={ExploreTabEnum.WIN_LIST} />
          </FeatureGuard>
        }
      />
      <Route
        path="*"
        element={<NavigateToOrgRoute route={defaultPeopleRoute()} replace={true} />}
      />
    </Routes>
  );
};

export default ExploreRouter;
