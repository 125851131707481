import { styled } from '@mui/material/styles';
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import React from 'react';

import Typography from './Typography';

export type TextFieldProps = MUITextFieldProps;

const StyledTextField = styled(MUITextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root,.MuiOutlinedInput-root.MuiAutocomplete-inputRoot,.MuiOutlinedInput-root.MuiInputBase-sizeSmall.MuiAutocomplete-inputRoot':
    {
      padding: '0.5rem 0rem',
      borderRadius: '0.75rem',
    },
  '& .MuiFormLabel-root': {
    color: theme.palette.TextFields.LabelDefault,
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.TextFields.LabelClicked,
  },
  '& .MuiInputLabel-shrink': { fontSize: '0.75rem' },
  '& .MuiOutlinedInput-root': {
    color: theme.palette.TextFields.InputText,
    '& fieldset': {
      borderRadius: '0.75rem',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '1.125rem',

      '& .MuiTypography-root': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
      },
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.TextFields.Default,
    },
    '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.TextFields.Disabled,
    },
    '& input.MuiInputBase-input.MuiInputBase-inputSizeSmall': {
      padding: '0.4rem 1rem',
    },
    '& input.MuiInputBase-input, .MuiSelect-select': {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      padding: '0.844rem',
      height: '0.5rem',
    },
    '& .MuiSelect-outlined': {
      padding: '0.375rem 0.844rem',
    },
    '& input, textarea, .MuiSelect-select': {
      boxShadow: 'none',
      color: theme.palette.TextFields.InputText,
      '&:-webkit-autofill': {
        borderRadius: 0,
        boxShadow: `0 0 0px 100px ${theme.palette.LinkContainer.BgHover} inset`,
      },
    },
    '& input.Mui-disabled, & textarea.Mui-disabled, & .MuiSelect-select.Mui-disabled': {
      WebkitTextFillColor: theme.palette.TextFields.InputText,
      color: theme.palette.TextFields.InputText,
    },
    '& input::placeholder': {
      color: theme.palette.Text.SubtleText,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.Stroke.Active,
    },
    '&.Mui-error fieldset': {
      borderColor: theme.palette.Failure,
    },
    '&:has(.MuiChip-root)': {
      paddingLeft: '0.3rem',
    },
    '& textarea': {
      padding: '0.375rem 0.844rem',
    },
  },

  '& label': {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1.125rem',
    color: theme.palette.TextFields.LabelDefault,
    '&.Mui-focused': {
      color: theme.palette.TextFields.LabelClicked,
    },
    '&.Mui-error': {
      color: theme.palette.Failure,
    },
    '&.Mui-disabled': {
      color: theme.palette.Text.SubtleText,
    },
  },

  '& .MuiFormHelperText-root': {
    color: theme.palette.TextFields.HintText,
    '&.Mui-error': {
      color: theme.palette.Failure,
    },
  },

  '& .MuiInputAdornment-positionStart': {
    paddingLeft: '0.75rem',
  },
  '& .MuiInputAdornment-positionEnd': {
    paddingRight: '0.75rem',
  },
  '[type="search"]::-webkit-search-cancel-button': {
    WebkitAppearance: 'none',
    appearance: 'none',
  },
  '[type="search"]::-webkit-search-decoration': {
    WebkitAppearance: 'none',
    appearance: 'none',
  },
}));

const TextField: React.FC<TextFieldProps> = React.forwardRef((props, ref) => {
  const label = props?.label && <Typography variant="body4">{props.label}</Typography>;

  const helperText = props?.helperText && (
    <Typography fontSize="0.625rem" fontWeight="400" lineHeight="0.937rem" variant="caption">
      {props.helperText}
    </Typography>
  );

  return (
    <StyledTextField
      label={label}
      helperText={helperText}
      variant="outlined"
      ref={ref}
      {...props}
    ></StyledTextField>
  );
});

export default TextField;
