import React from 'react';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import MenuItemButton from './MenuItemButton';

const ManageOrganizationMenuItem: React.FC = () => {
  const { goTo } = useRouter();

  const handleClick = () => {
    goTo(AppRoutesEnum.ADMIN_MANAGE_ORGANIZATION, {}, { state: { resetHistoryIndex: true } });
  };

  return (
    <MenuItemButton onClick={handleClick} label="Manage Organization">
      <CorporateFareIcon />
    </MenuItemButton>
  );
};

export default ManageOrganizationMenuItem;
