import { useTheme } from '@mui/material/styles';
import { HttpStatusCode } from 'axios';
import { ResponsiveHeaderBar } from 'common/components/header';
import { CircularProgress, Stack } from 'common/components/material';
import { useLocalStorage, useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { GuardRouteProps } from 'common/routes/Route.type';
import { extractOrgSubUrl } from 'common/utils/app.utils';
import { useFeatureAccessContext } from 'features/feature-access/feature-accesses.provider';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { isEmpty, isNil, toLower } from 'lodash';
import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

const ProtectedRoute: React.FC<GuardRouteProps> = ({ element }) => {
  const {
    account,
    hasOrganization,
    hasSession,
    isAccountFetching,
    profile,
    isProfileFetching,
    profileError,
  } = useSession();
  const { goTo } = useRouter();
  const { value: redirectUrl, setItemValue: setRedirectUrl } =
    useLocalStorage('redirect-url');

  const { palette } = useTheme();
  const location = useLocation();
  const state = location.state as any;

  const { isOrgPath, subUrl } = extractOrgSubUrl(location.pathname);

  const { checkFeatureEnabled } = useFeatureAccessContext();

  const organizationProfile = profile?.getOrganizationProfile({ subUrl });
  const isOnboarded = organizationProfile?.isOnboarded || false;
  const isGuestProfile = organizationProfile?.isGuestProfile || false;

  const [params] = useSearchParams();
  const verificationCode = params.get('verificationCode') || state?.verificationCode || null;

  const isSwitchingOrganization =
    isOrgPath && (isEmpty(subUrl) || toLower(subUrl) !== toLower(account?.subUrl));

  // Navigation/refetching that is done when user is in an organization page
  const isInternalNavigation = isOnboarded && !isSwitchingOrganization;

  if (isAccountFetching || isProfileFetching) {
    // Don't trigger loading if we're invalidating the profile due to some update within the org.
    if (isInternalNavigation) {
      return element;
    }

    return (
      <Stack height="100%" bgcolor={palette.Background}>
        <ResponsiveHeaderBar isLoading={true} />
        <CircularProgress sx={{ alignSelf: 'center', marginTop: 20 }} size={100} />
      </Stack>
    );
  }

  if (!hasSession) {
    return (
      <Navigate
        to={AppRoutesEnum.ACCOUNTS_LOGIN}
        state={{
          from: location,
          subUrl,
          verificationCode,
        }}
      />
    );
  }

  if (!account.isVerified) {
    return verificationCode ? (
      <Navigate
        to={AppRoutesEnum.ACCOUNTS_VERIFICATION}
        state={{ verificationCode, subUrl }}
      />
    ) : (
      <Navigate to={AppRoutesEnum.ACCOUNTS_VERIFY_EMAIL} state={{ subUrl }} />
    );
  }

  if (profileError?.statusCode === HttpStatusCode.Forbidden) {
    return (
      <Navigate to={AppRoutesEnum.SWITCH_ORGANIZATION} state={{ subUrl, verificationCode }} />
    );
  }

  if (
    isSwitchingOrganization &&
    !!profile &&
    location.pathname !== AppRoutesEnum.CREATE_PROFILE
  ) {
    const targetSubUrl = !isNil(organizationProfile)
      ? subUrl
      : profile?.getCurrentOrganization()?.subUrl;

    if (!isEmpty(targetSubUrl)) {
      return (
        <Navigate
          to={AppRoutesEnum.SWITCH_ORGANIZATION}
          state={{ subUrl: targetSubUrl, verificationCode }}
        />
      );
    }
  }

  if (!hasOrganization || isEmpty(account.profileId)) {
    return <Navigate to={AppRoutesEnum.ACCOUNTS_NO_PROFILE} />;
  }

  if (
    isOrgPath &&
    !isOnboarded &&
    !isGuestProfile &&
    checkFeatureEnabled(FeaturesEnum.ONBOARDING_STEPS) &&
    location.pathname !== AppRoutesEnum.CREATE_PROFILE
  ) {
    return <Navigate to={AppRoutesEnum.CREATE_PROFILE} />;
  }

  if (redirectUrl) {
    setRedirectUrl(null);
    const { pathname, search } = redirectUrl;
    if (pathname !== location.pathname) {
      goTo({ pathname, search }, {}, { replace: true });
      return <></>;
    }
  }

  return element;
};

export default ProtectedRoute;
