import axios from 'common/utils/axios';
import { AddAspirationParams } from './types';
import { GetAspiredSkillsRequestParams } from './types/get-aspired-skills-request-params.type';

const aspirationsApi = axios('aspirations');

export const addAspirationApi = (data: AddAspirationParams) => {
  return aspirationsApi.post('', data);
};

export const deleteAspirationApi = (id: string) => {
  return aspirationsApi.delete(id);
};

export const getAspirationsApi = (profileId: string, includeAll?: boolean) => {
  // 'summary' limits to 10 aspirations per type
  const path = includeAll ? 'details' : 'summary';
  return aspirationsApi.get(`${path}?profileId=${profileId}`);
};

export const getAspiredSkillsApi = (params: GetAspiredSkillsRequestParams) => {
  const { term, page = 1, size = 10, ...selectedFilters } = params;
  return aspirationsApi.get('skills', {
    params: {
      term,
      page,
      size,
      ...selectedFilters,
    },
  });
};
