import React from 'react';
import { styled } from '@mui/material/styles';
import { ReactComponent as HomeIconOutlined } from 'common/svg-icons/homeIconOutlined.svg';
import { ReactComponent as HomeIconFilled } from 'common/svg-icons/homeIconFilled.svg';
import SvgIconState from './SvgIconState';

// We need to convert these custom SVGs to styled components to work with SvgIconState's styling
const StyledHomeIcon = styled(HomeIconOutlined)({});
const StlyedHomeIconFilled = styled(HomeIconFilled)({});

const HomeIcon: React.FC<{ isActive?: boolean }> = ({ isActive }) => {
  return (
    <SvgIconState
      ActiveIcon={StlyedHomeIconFilled}
      DisabledIcon={StyledHomeIcon}
      isActive={isActive}
    />
  );
};

export default HomeIcon;
