import { get, pick } from 'lodash';

import { useMutation, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { createOrganizationRoleApi } from '../organization-role.service';
import { OrganizationRoleForm } from '../types/organization-role-form.type';
import { Skill } from 'features/skills';
import { useGetOrganizationRole } from './useGetOrganizationRole';
import { OrgStaticData } from 'features/static-data';

export const useCreateOrganizationRole = (
  profileId: string | undefined,
  options: UseMutationOptions = {},
) => {
  const { account: user } = useSession();
  const { refetch: refetchOrganizationRole } = useGetOrganizationRole(
    profileId,
    user.organizationId,
    { enabled: !!profileId },
  );

  const mutationResponse = useMutation(createOrganizationRoleApi, {
    showSuccessSnackbar: true,
    transformInput: (organizationRoleForm: OrganizationRoleForm) => {
      const role = pick(organizationRoleForm, [
        'jobTitle',
        'jobDescription',
        'startMonth',
        'endMonth',
        'isCurrentRole',
        'location',
      ]);
      const skills = get(organizationRoleForm, 'skills', []) as Skill[];
      const products = get(organizationRoleForm, 'products', []) as OrgStaticData[];

      return {
        ...role,
        startYear: get(organizationRoleForm, 'startYear', null),
        endYear: get(organizationRoleForm, 'endYear', null),
        jobFunctionId: get(organizationRoleForm, 'jobFunction.id', null),
        skillIds: skills.map((skill: Skill) => skill.id),
        profileId: organizationRoleForm.profileId,
        departmentId: get(organizationRoleForm, 'department.id', null),
        divisionId: get(organizationRoleForm, 'division.id', null),
        productIds: products.map((product: OrgStaticData) => product.id),
      };
    },
    onSuccess: () => {
      if (!profileId) {
        return;
      }
      refetchOrganizationRole();
    },
    ...options,
  });
  return mutationResponse;
};
