import React, { useContext, useState } from 'react';
import FilledNotificationsIcon from '@mui/icons-material/Notifications';
import OutlinedNotificationsIcon from '@mui/icons-material/NotificationsNone';
import { useTheme } from '@mui/material/styles';

import { SvgIconState } from 'common/components/image';
import { Badge } from 'common/components/material';
import { NotificationsContext } from 'common/context/NotificationsContextProvider';
import { useDrawer } from 'common/hooks';
import { DrawerIdEnum } from '../../enums/drawer-id.enum';

const NotificationIcon: React.FC = () => {
  const { openDrawer } = useDrawer();
  const { palette } = useTheme();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const { unreadCount: notificationsCount, markAllAsRead: markAllNotificationsToRead } =
    useContext(NotificationsContext);

  const handleNotificationIconClick = () => {
    setIsNotificationOpen(true);
    openDrawer(DrawerIdEnum.NOTIFICATION, {
      onClose: () => {
        markAllNotificationsToRead();
        setIsNotificationOpen(false);
      },
    });
  };

  return (
    <Badge
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: notificationsCount ? palette.Failure : 'transparent',
          border: '1.5px solid transparent',
          marginTop: 0.9,
          marginRight: 0.9,
          minWidth: 0,
          height: 7,
          width: 7,
        },
      }}
      variant="dot"
      onClick={handleNotificationIconClick}
      data-analytics-tag="notifications_bell_header"
    >
      <SvgIconState
        ActiveIcon={FilledNotificationsIcon}
        DisabledIcon={OutlinedNotificationsIcon}
        isActive={isNotificationOpen}
      />
    </Badge>
  );
};

export default NotificationIcon;
