import { useMutation, useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

import queryClient from 'common/utils/QueryClient';
import { Account } from 'features/accounts/types/accounts.type';
import { useLocation } from 'react-router-dom';
import { loginApi } from '../accounts.service';

export const useLogin = () => {
  const { goTo } = useRouter();

  const location = useLocation();
  const { setOrganizationOnSession } = useSession();
  const state = location.state as any;

  return useMutation(loginApi, {
    onSuccess: (data: { data: Account }) => {
      const { data: account } = data;

      queryClient.setQueryData(['accounts', 'me'], data);

      if (account.organizationId && account.subUrl) {
        setOrganizationOnSession(account.organizationId, account.subUrl);
      }
      /**
       * If a user attempted to access a protected page,
       * redirect them there after login. The ProtectedRoute
       * component will handle further checks from there.
       */
      if (state?.from && state?.subUrl === account.subUrl) {
        goTo(state.from, {}, { state });
        return;
      }
      goTo(AppRoutesEnum.HOME, { organizationName: account.subUrl }, { state });
    },
  });
};
