import InsertPhoto from '@mui/icons-material/InsertPhoto';

import { FileInfo } from '../../types/media.types';
import { getStyledIcon, getTitleAndSubLabelColor } from '../../utils/media.utils';
import MediaCard from '../media-card.component';
import { SvgIconTypeMap, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { FileInputProps, HiddenFileInput, LoadedFile } from './hidden-file-input.component';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { CircularProgress, Stack } from 'common/components/material';

export const FileLoader: React.FC<{
  title: string;
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  labelColor?: string;
  inputProps?: FileInputProps;
  disabled?: boolean;
  onLoaded?: (files: FileInfo[]) => void;
  onError?: (errorFiles: LoadedFile[]) => void;
  onClick?: () => void;
}> = ({
  title,
  label,
  icon,
  labelColor,
  disabled,
  inputProps,
  onLoaded,
  onError,
  onClick,
}) => {
  icon = icon ?? InsertPhoto;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { palette } = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const openBrowser = () => {
    if (disabled) {
      return;
    }

    if (inputRef.current) {
      inputRef?.current?.click();
    }
    onClick && onClick();
  };

  const loadingElm = (
    <Stack
      width="2.625rem"
      height="2.625rem"
      justifyContent="center"
      alignItems="center"
      borderRadius=".375rem"
    >
      <CircularProgress />
    </Stack>
  );

  const handleOnError = (errorFiles: LoadedFile[]) => {
    setIsLoading(false);
    onError && onError(errorFiles);
  };

  const handleOnLoaded = (files: FileInfo[]) => {
    setIsLoading(false);
    onLoaded && onLoaded(files);
  };

  return (
    <>
      <MediaCard
        title={title}
        subLabel={label}
        iconElement={isLoading ? loadingElm : getStyledIcon(icon, palette, false, disabled)}
        titleColor={getTitleAndSubLabelColor(palette, false, disabled, false)}
        subLabelColor={labelColor ?? getTitleAndSubLabelColor(palette, false, disabled, true)}
        onClick={openBrowser}
        isActive={false}
        disabled={disabled}
      />
      <HiddenFileInput
        clearOnLoaded={true}
        name={inputProps?.name || 'photo'}
        accept={inputProps?.accept}
        multiple={inputProps?.multiple}
        imageInputRef={inputRef}
        onError={handleOnError}
        onLoading={() => setIsLoading(true)}
        onLoaded={handleOnLoaded}
      />
    </>
  );
};
