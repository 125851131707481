import React from 'react';

import MIUCircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

const CircularProgress: React.FC<CircularProgressProps> = props => {
  const { children, ...circularProgressProps } = props;

  return <MIUCircularProgress {...circularProgressProps}>{children}</MIUCircularProgress>;
};

export default CircularProgress;
