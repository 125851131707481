import { styled } from '@mui/material/styles';

import Typography, { TypographyProps } from 'common/components/material/Typography';
import Tooltip from 'common/components/material/Tooltip';

const StyledText = styled(Typography)<{ lineclamp: number }>((props) => ({
  WebkitLineClamp: props.lineclamp,
  WebkitBoxOrient: 'vertical',
  /**
   * There's an edge case where we want to have EllipsisText inline in between
   * other text (ex. SearchSuggestions) and the default display doesn't properly render the ellipsis.
   * In such a case, lineclamp will also not work but it won't be needed for a single line.
   */
  display: (props.display as string) || '-webkit-box',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  /**
   *
   * If you only have one line available, we would like to show as much content as possible,
   * follewed by an ellipsis in case it overflowed (i.e., `word-break: break-all`).
   * However, when you have more than one line available, we would like to break all the
   * available lines by word boundaries (i.e.,`word-break: normal`).
   *
   * Note: the original requirement was that if you had more than one line available, all
   * the lines **except the last one** would need to be broken by word boundaries and for
   * the last one we should have shown as much content as possible.  Unfortunately
   * that's impossile to implement as the two behaviors require different values for the
   * same CSS propertly.
   */
  wordBreak: props.lineclamp === 1 ? 'break-all' : 'normal',
}));

export const EllipsisText: React.FC<
  Omit<TypographyProps, 'title'> & {
    lineclamp: number;
    title?: string | React.ReactElement;
    showTooltip?: boolean;
  }
> = ({ title, children, showTooltip: showToolTip = true, ...props }) => {
  const text = typeof children === 'string' ? children : '';
  const toolTipTitle = title || text;

  let element = (
    <StyledText maxWidth="100%" {...props}>
      {children}
    </StyledText>
  );
  if (showToolTip && toolTipTitle) {
    element = (
      <Tooltip title={toolTipTitle} enterDelay={500}>
        {element}
      </Tooltip>
    );
  }

  return element;
};
