import React from 'react';
import { useTheme } from '@mui/material/styles';
import { getThemeImagePath } from 'common/utils/file.utils';
import { titleCase } from 'common/utils/app.utils';
import { SOCIAL_LINKS_ALIAS } from 'features/quick-links/quick-links.constants';

const QuickLinkLabelImage: React.FC<{ label: string; style?: React.CSSProperties }> = ({
  label,
  style = {},
}) => {
  const { palette } = useTheme();
  const fileName = titleCase(label);
  return (
    <img
      height="14"
      src={getThemeImagePath(
        'SocialMedia',
        palette.mode,
        `${SOCIAL_LINKS_ALIAS[fileName] || fileName}.svg`,
      )}
      alt={label}
      style={{ ...style }}
    />
  );
};

export default QuickLinkLabelImage;
