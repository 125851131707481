import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { updateOrganizationEventApi } from '../organization-events.service';
import { transformOrganizationEventRequest } from '../utils';

export const useUpdateOrganizationEvent = (
  feedId?: string,
  options: UseMutationOptions = {},
) => {
  const { emitWithSettledAfter } = useEventEmitter('OrganizationEventsModified');

  return useMutation(updateOrganizationEventApi, {
    showSuccessSnackbar: true,
    transformInput: transformOrganizationEventRequest,
    onSuccess: () => {
      emitWithSettledAfter(3000);
    },
    ...options,
  });
};
