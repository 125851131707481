import React from 'react';

import { LargeCircularProgress } from 'common/components/loaders';
import { Stack } from 'common/components/material';

type BaseDataExplorerContentContainerProps = {
  isLoading: boolean;
  showEmptyView: boolean;
  emptyView: React.ReactNode;
};

const BaseDataExplorerContentContainer: React.FC<BaseDataExplorerContentContainerProps> = ({
  isLoading,
  showEmptyView,
  emptyView,
  children,
}) => {
  if (isLoading) {
    return (
      <Stack alignItems="center" minHeight="50vh">
        <LargeCircularProgress />
      </Stack>
    );
  }

  if (showEmptyView) {
    return <>{emptyView}</>;
  }

  return <>{children}</>;
};

export default BaseDataExplorerContentContainer;
