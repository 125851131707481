import { useTheme } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Typography } from 'common/components/material';
import { useView } from 'common/hooks';
import { getVisibleToLabelText } from 'common/utils/app.utils';
import { VisibleTo } from 'common/types/visible-to.type';

const VisibleToOrganizationText: React.FC<{ visibleTo: VisibleTo[] }> = ({
  visibleTo,
}) => {
  const { isMobileView } = useView();
  const { palette } = useTheme();

  return (
    <Typography variant={isMobileView ? 'body4' : 'body3'} color={palette.Text.SubHeadline}>
      <VisibilityOutlinedIcon sx={{ verticalAlign: 'middle', pr: 0.5 }} />
      Visible to everyone in {getVisibleToLabelText(visibleTo)}
    </Typography>
  );
};

export default VisibleToOrganizationText;
