const PRIMARY = {
  PRIMARY50: '#F4F5FF',
  PRIMARY100: '#D4D6FF',
  PRIMARY200: '#999EF4',
  PRIMARY300: '#656CD8',
  PRIMARY400: '#565CB9',
  PRIMARY450: '#5156A7',
  PRIMARY500: '#4348A1',
  PRIMARY600: '#292D68',
  PRIMARY700: '#292D68',
  PRIMARY800: '#1B1D4B',
  PRIMARY900: '#1B1D4B',
};

const SECONDARY = {
  SECONDARY50: '#DFF2EE',
  SECONDARY100: '#B1DED4',
  SECONDARY200: '#7FC9B9',
  SECONDARY300: '#00AE97',
  SECONDARY400: '#27A28A',
  SECONDARY500: '#019277',
  SECONDARY600: '#00856B',
  SECONDARY700: '#00755C',
  SECONDARY800: '#00654E',
  SECONDARY900: '#004934',
};

const TEAL = {
  TEAL50: '#E6F6F7',
  TEAL100: '#B0E4E5',
  TEAL400: '#33B9BB',
  TEAL500: '#00A7AA',
  TEAL600: '#00989B',
  TEAL700: '#007779',
};

const BLUE_GRAY = {
  BLUEGRAY50: '#FBFCFF',
  BLUEGRAY100: '#ECF2FF',
  BLUEGRAY200: '#DDE8FF',
  BLUEGRAY300: '#C7D3EB',
  BLUEGRAY400: '#ADB9D2',
  BLUEGRAY500: '#94A0B8',
  BLUEGRAY600: '#7D889F',
  BLUEGRAY700: '#667085',
  BLUEGRAY800: '#50596B',
  BLUEGRAY900: '#3C4352',
  BLUEGRAYA100: '#ECEFF1',
  BLUEGRAYA900: '#101828',
};

const GRAY = {
  GRAY50: '#FAFAFA',
  GRAY75: '#F7F7F7',
  GRAY100: '#EEEEEE',
  GRAY200: '#E8E8E8',
  GRAY300: '#DCDCDC',
  GRAY400: '#D2D2D2',
  GRAY500: '#C9C9C9',
  GRAY600: '#BFBFBF',
  GRAY700: '#B5B5B5',
  GRAY800: '#AAAAAA',
  GRAY900: '#A0A0A0',
};

const WHITE = '#FFFFFF';
const BLACK = '#000000';
const TRANSPARENT = '#FF000000'

const SUCCESS = '#2E7D32';
const ERROR = '#BF360C';

const ORANGE = {
  ORANGE50: '#FFEFE6',
  ORANGE100: '#FFE0B2',
  ORANGE400: '#FF5D00',
  ORANGE500: '#FF8E00',
  ORANGE800: '#EF6C00',
};

const YELLOW = {
  YELLOW100: '#FFF0B0',
  YELLOW400: '#EAAF00',
};

const RED = {
  RED50: '#FFECEC',
  RED100: '#FFC1C2',
  RED200: '#FF9798',
  RED300: '#FF6C6E',
  RED400: '#FF4145',
  RED500: '#DD2C2F',
  RED600: '#BB1B1D',
  RED700: '#990D0F',
  RED800: '#770305',
  RED900: '#550002',
};

const GREEN = {
  GREEN50: '#E8F5E9',
  GREEN100: '#C8E6C9',
  GREEN200: '#A5D6A7',
  GREEN300: '#81C784',
  GREEN400: '#66BB6A',
  GREEN500: '#4CAF50',
  GREEN600: '#43A047',
  GREEN700: '#388E3C',
  GREEN800: '#2E7D32',
  GREEN900: '#1B5E20',
};

const PURPLE = {
  PURPLE100: '#F9E6FB',
  PURPLE300: '#8B33FF',
  PURPLE500: '#6E00FF',
};

const REVIEWRATINGS = {
  UNSATISFACTORY: '#E71D36',
  JUSTFINE: '#FFD670',
  GREAT: '#00BBF9',
  OUTSTANDING: '#62C370',
};

const USER_ACTION_CARDS = {
  ADD_SKILLS: '#FFE2F0',
  ADD_ASPIRATIONS: '#CCE3ED',
  ADD_PROFILE_PICTURE: '#FEDCCE',
  INVITE_PEOPLE: '#FFF2CC',
  ADD_COMPANY_PHOTO: '#CCFFCE',
  SEND_PRAISE: '#E0F2FF',
};

const USER_ACTION_CARDS_ICONS = {
  ADD_SKILLS: '#ED0072',
  ADD_ASPIRATIONS: '#0477A9',
  ADD_PROFILE_PICTURE: '#F68252',
  INVITE_PEOPLE: '#F2B500',
  ADD_COMPANY_PHOTO: '#38B03D',
};

const DISABLED_TEXT = '#D2D2D2';

const ColorPalette = {
  ...PRIMARY,
  ...SECONDARY,
  ...BLUE_GRAY,
  ...GRAY,
  ...ORANGE,
  ...YELLOW,
  ...RED,
  ...GREEN,
  ...TEAL,
  ...PURPLE,
  REVIEWRATINGS,
  BLACK,
  WHITE,
  TRANSPARENT,
  SUCCESS,
  ERROR,
  USER_ACTION_CARDS,
  USER_ACTION_CARDS_ICONS,
  DISABLED_TEXT,
};

export default ColorPalette;
