import { useTheme } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { Stack, Typography } from 'common/components/material';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import { UserAvatar } from 'features/profiles/components/user-avatar';
import { IProfileSearchSuggestion } from 'features/profiles/interfaces/profile-search-suggestion.interface';

export const ProfileSearchOptionItem: React.FC<{
  profile: IProfileSearchSuggestion;
  searchTerm?: string;
}> = ({ profile, searchTerm = '' }) => {
  const { fullName, profileHeadline, photo } = profile;
  const { palette } = useTheme();
  const matches = match(profileHeadline || '', searchTerm, { insideWords: true });
  const parts = parse(profileHeadline, matches);

  return (
    <Stack
      direction="row"
      display="flex"
      alignItems="center"
      spacing={1}
      data-analytics-tag="search_suggest_choose_person"
    >
      <UserAvatar boxShadow={AppBoxShadows.shadow3} size="small" src={photo} />
      <Typography
        variant="body4"
        fontWeight={500}
        color={palette.LinkText.Default}
        whiteSpace="pre"
        maxWidth={profileHeadline ? '75%' : '100%'}
        flexShrink={0}
      >
        {fullName}
      </Typography>
      {profileHeadline && (
        <EllipsisText variant="body4" lineclamp={1}>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                color: part.highlight ? palette.Text.Headline : palette.Text.SubHeadline,
                fontWeight: part.highlight ? 500 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </EllipsisText>
      )}
    </Stack>
  );
};
