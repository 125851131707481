import React from 'react';

import { Stack, Typography } from 'common/components/material';

export const WinListTooltipContent: React.FC = () => {
  return (
    <Stack spacing={2} p={1}>
      <Typography variant="h6">What is a WIN (What’s Important Now) List?</Typography>
      <Typography variant="body3">
        WIN Lists are a lightweight, real-time view of the key initiatives being worked on
        each quarter.
      </Typography>
      <Typography variant="h6">Why are WIN Lists important?</Typography>
      <Typography variant="body3">
        Teams use WIN Lists to calibrate priorities and ensure resources are allocated to
        areas of high impact.
      </Typography>
      <Typography variant="body3">
        Organizational WIN Lists (for divisions, products, etc.) make business priorities
        transparent to all, highlighting what everyone is collectively working toward.
      </Typography>
      <Typography variant="body3">
        Over time, WIN Lists help codify our collective experience and expertise, from which
        employees can share knowledge and connect dots.
      </Typography>
      <Typography variant="h6">What makes a good WIN List?</Typography>
      <Typography variant="body3">
        A good WIN List has:
        <ul>
          <Typography variant="body3" component="li">
            Succinct headlines that are comprehensible to others even without context (no
            project codes)
          </Typography>
          <Typography variant="body3" component="li">
            Initiatives to be executed during the specified quarter (i.e., more granular than
            a years-long project)
          </Typography>
          <Typography variant="body3" component="li">
            Supporting details like a description, skills, or URL for facilitating sharing of
            knowledge and best practices
          </Typography>
        </ul>
      </Typography>
    </Stack>
  );
};
