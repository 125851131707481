import React from 'react';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import MenuItemButton from './MenuItemButton';
import { useRouter, useSession } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

const EditProfileMenuItem: React.FC = () => {
  const { goTo } = useRouter();
  const { account } = useSession();
  const goToEditProfile = () => {
    goTo(AppRoutesEnum.PROFILE_EDIT, { profileId: account.profileId });
  };

  return (
    <MenuItemButton onClick={goToEditProfile} label="Edit Profile">
      <PersonOutlinedIcon />
    </MenuItemButton>
  );
};

export default EditProfileMenuItem;
