import { Theme, styled } from '@mui/material/styles';
import { getBaseChipStyle } from 'common/components/cx-chip/cx-chip.styles';
import { Chip, Typography } from 'common/components/material';
import { ChipProps } from 'common/components/material/Chip';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const AVATAR_SIZE_PX = 20;

const getDefaultTagChipStyle = ({ palette }: Theme) => {
  return {
    height: '24px',
    color: palette.StaticDataChip.TextDefault,
    backgroundColor: palette.StaticDataChip.BgDefault,
    border: `1px solid ${palette.StaticDataChip.StrokeDefault}`,
    '& .MuiChip-icon,.MuiChip-avatar': {
      cursor: 'pointer',
      width: AVATAR_SIZE_PX,
      height: AVATAR_SIZE_PX,
      marginLeft: '1px',
      marginRight: '0px',
      borderRadius: '16px',
      backgroundColor: palette.EmptyPlaceholderIcons.Background,
      color: palette.EmptyPlaceholderIcons.IconFill,
    },
    '& .MuiChip-icon': { padding: '2px' },
    '&.MuiChip-root': {
      paddingLeft: '0px',
    },
    '& .MuiChip-label': {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingRight: '3px',
      paddingLeft: '0px',
    },
    '&:hover .MuiChip-label': {
      color: palette.StaticDataChip.TextActive,
    },
    '&:hover.MuiChip-root': {
      backgroundColor: palette.StaticDataChip.BgDefault,
      boxShadow: AppBoxShadows.shadow2,
    },
    '&:active': {
      borderColor: 'transparent',
      backgroundColor: palette.StaticDataChip.BgPressed,
      color: palette.StaticDataChip.TextPressed,
    },
  };
};

export const TagLabel = styled(Typography)(({ theme }) => ({
  '&': {
    margin: '0 0 0 3px',
    overflow: 'hidden',
    maxWidth: '12rem',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: { maxWidth: '9rem' },
  },
}));

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => {
    return prop !== 'isSelected';
  },
})<ChipProps>(({ theme }) => ({
  ...getBaseChipStyle(theme),
  ...getDefaultTagChipStyle(theme),
}));
