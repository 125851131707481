import { useInfiniteQuery, useSession } from 'common/hooks';

import {
  createCursorQueryParams,
  getNextCursorParam,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import { useSearchParams } from 'react-router-dom';
import { mapSearchParamsObject } from '../search.utils';
import { SearchModel } from '../models';
import { freeTextApi } from '../search.service';
import { UseInfiniteQueryOptions } from 'common/types/Api.type';

export const useFetchFreeTextSearchResult = (
  size: number = 10,
  options: Partial<UseInfiniteQueryOptions> = {},
) => {
  const { account: user } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const filters = mapSearchParamsObject(searchParams, false, [
    'columns',
    'pinnedl',
    'pinnedr',
  ]);

  const freeText = (params: any) => {
    const queryParams = createCursorQueryParams(params.pageParam, {
      term: query,
      size,
      ...filters,
    });

    return freeTextApi(queryParams);
  };

  const queryResponse = useInfiniteQuery(
    ['search', user?.organizationId, query, filters],
    freeText,
    {
      getNextPageParam: getNextCursorParam,
      entityKey: 'profiles',
      // Prevent fetching again if switching tabs multiple times within 10 seconds
      staleTime: 10000,
      ...options,
    },
  );
  const searchModel = new SearchModel(
    queryResponse.isSuccess ? queryResponse.data.pages : [],
  );
  const total = queryResponse.isSuccess ? queryResponse.data.pageParams.total : 0;

  return {
    ...queryResponse,
    data: searchModel.getSearchResult(),
    isLoading: queryResponse.isLoading,
    total,
  };
};
