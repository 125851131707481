import { useState, useEffect, Dispatch, SetStateAction } from 'react';

// Used for setting the state where the initial value may change (e.g. pending api data)
const useDeferredState = <T>(
  value?: T,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  const [state, setState] = useState(value);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (value && !isInitialized) {
      setState(value);
      setIsInitialized(true);
    }
  }, [value, isInitialized]);

  return [state, setState];
};

export default useDeferredState;
