import { styled } from '@mui/material/styles';
import Checkbox from 'common/components/material/Checkbox';

export const FilterCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: '0.25rem',
  '& .MuiSvgIcon-root:not([data-testid=CheckBoxIcon])': {
    fill: theme.palette.Icons.Tertiary.IconFillDefault,
  },
  '&:hover .MuiSvgIcon-root': {
    fill: theme.palette.Icons.Tertiary.IconFillHover,
  },
}));
