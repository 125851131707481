export const getNextPageParam = (lastPage: any) => {
  const isLastPage = lastPage.data?.pagination?.isLastPage;
  const lastPageNumber: number = lastPage.data?.pagination?.page || 0;
  return lastPageNumber && !isLastPage ? { page: lastPageNumber + 1 } : undefined;
};

export const getTotalCount = (data: { [prop: string]: any }): number => {
  return data?.pageParams?.total || 0;
};

export const createCursorQueryParams = (params?: any, opts = {}) => {
  const cursor = params?.cursor;
  const size = params?.size || 10;
  const queryParams = { cursor, size, ...opts };
  if (!cursor) {
    delete queryParams.cursor;
  }
  return queryParams;
};

export const getNextCursorParam = (lastPage: any) => {
  const paginationData = lastPage.data?.pagination;
  const isLastPage = paginationData?.isLastPage;
  const lastCursor = paginationData?.cursor;
  return lastCursor && !isLastPage ? { ...paginationData, cursor: lastCursor } : undefined;
};
