import React from 'react';
import { LocationTypeRadioGroup } from '../styles';
import { OrganizationEventLocationTypeEnum } from '../organization-events.enum';

type OrganizationEventLocationTypeSelectorProps = {
  name: string;
  value: OrganizationEventLocationTypeEnum;
  onChange: (locationType: OrganizationEventLocationTypeEnum) => void;
};

const OrganizationEventLocationTypeSelector: React.FC<
  OrganizationEventLocationTypeSelectorProps
> = ({ name, value, onChange }) => {
  return (
    <LocationTypeRadioGroup
      name={name}
      value={value}
      onChange={onChange}
      options={[
        { label: 'Office', value: OrganizationEventLocationTypeEnum.OFFICE },
        { label: 'Venue', value: OrganizationEventLocationTypeEnum.VENUE },
        { label: 'Online', value: OrganizationEventLocationTypeEnum.ONLINE },
      ]}
    />
  );
};

export default OrganizationEventLocationTypeSelector;
