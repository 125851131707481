import React from 'react';

import { Divider, Stack, Typography } from 'common/components/material';
import { BackButton } from 'common/components/navigation';
import BaseDataExplorerContentContainer from './base-data-explorer-content-container.component';
import { BaseDataExplorerProps } from '../types/base-data-explore-props.type';
import { useView } from 'common/hooks';

const BaseDataExplorer: React.FC<BaseDataExplorerProps> = ({
  title,
  /**
   * headerActions was split into two props since the layout for
   * the actions on the header was different for desktop and mobile.
   * headerActions sit to the right of the title on both views.
   * secondaryHeaderActions sit beside headerActions on desktop, but
   * below the tiltle on mobile view.
   *
   * TODO: We can refactor the header portion of the explorer to be
   * configurable above the explorer-level, but we'll need a way for it to
   * receive the title and back button click handler which is currently
   * defined in this component's immediate parent.
   */
  headerActions,
  secondaryHeaderActions,
  emptyView,
  isLoading,
  showEmptyView,
  onClickBack,
  children,
}) => {
  const { isDesktopView } = useView();
  return (
    <>
      <Stack p={2} spacing={0.5}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            {!!onClickBack && <BackButton size="small" clickHandler={onClickBack} />}
            <Typography variant="h4" fontWeight={600} whiteSpace="nowrap">
              {title}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {isDesktopView && secondaryHeaderActions}
            {headerActions}
          </Stack>
        </Stack>
        {!isDesktopView && secondaryHeaderActions}
      </Stack>
      {isDesktopView && <Divider />}
      <Stack minHeight={0} flex={1}>
        <BaseDataExplorerContentContainer
          isLoading={!!isLoading}
          emptyView={emptyView}
          showEmptyView={!!showEmptyView}
        >
          {children}
        </BaseDataExplorerContentContainer>
      </Stack>
    </>
  );
};

export default BaseDataExplorer;
