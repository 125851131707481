import React from 'react';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';

import { Card, Grid, CircularProgress, Stack } from 'common/components/material';
import Typography from 'common/components/material/Typography';
import { CardBoxShadow } from 'common/components/material/Card';
import EndorsersAvatarGroup from 'features/org-root/components/EndorsersAvatarGroup';

import EndorseButton from '../EndorseButton';
import { CardContent } from 'common/components/material/Card';
import SectionLabel from './SectionLabel';
import { SkillActionOverviewCardProps } from 'features/skills';

const OverviewEndorseButton = styled(EndorseButton)(({ theme }) => ({
  borderRadius: 0,
  height: '4rem',
  '&.MuiButton-text': {
    borderTop: `1px solid ${theme.palette.Buttons.Primary.BgDefault}`,
  },
  '&[btntype="primary"]': {
    color: theme.palette.Buttons.Primary.LabelDefault,
    '.MuiSvgIcon-root': {
      color: theme.palette.Icons.Primary.IconFill,
    },
  },
  '&[btntype="secondary"]': {
    color: theme.palette.Buttons.Secondary.SecondaryLabel,
  },
  '&.Mui-disabled': {
    color: theme.palette.Buttons.Primary.LabelDisabled,
    '.MuiSvgIcon-root': {
      color: theme.palette.Buttons.Primary.LabelDisabled,
    },
  },
}));

const SkillName = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 700,
  color: theme.palette.Text.Headline,
  minHeight: '3.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
  },
}));

const SkillActionOverviewCard: React.FC<SkillActionOverviewCardProps> = ({
  endorsedSkill,
  endorsee,
  isLoading,
}) => {
  const endorsedByMe = !!endorsedSkill?.isEndorsedByLoggedInUser;

  return (
    <Card boxShadow={CardBoxShadow.SHADOW3}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={10}>
            <SectionLabel>Skill name</SectionLabel>
            <SkillName>{endorsedSkill?.skill?.name}</SkillName>
          </Grid>
          {!isEmpty(endorsedSkill?.topEndorsers) && (
            <Grid item xs={12} md={2} textAlign={{ xs: 'left', md: 'right' }}>
              <SectionLabel>Endorsed by</SectionLabel>
              <EndorsersAvatarGroup
                endorsers={endorsedSkill?.topEndorsers}
                totalEndorsers={endorsedSkill?.totalEndorsers}
                endorseeId={endorsee.id}
                skillId={endorsedSkill?.skill.id}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      {isLoading ? (
        <Stack alignItems="center" mt={1} mb={2}>
          <CircularProgress />
        </Stack>
      ) : (
        <OverviewEndorseButton
          btntype={endorsedByMe ? 'secondary' : 'primary'}
          endorsedByMe={endorsedByMe}
          endorseeId={endorsee.id}
          skillId={endorsedSkill?.skill.id}
          fullWidth={true}
        />
      )}
    </Card>
  );
};

export default SkillActionOverviewCard;
