import { isNil } from 'lodash';

import { useSession } from 'common/hooks';
import { GetStaticDataDetailsParams, useGetStaticDataDetails } from 'features/static-data';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

export const useGetOnBehalfOfValue = ({
  isComingFromGlobalCreateButton,
  onBehalfOfPage,
}: {
  isComingFromGlobalCreateButton: boolean;
  onBehalfOfPage?: { pageId: string; type: string };
}) => {
  const { currentOrganization } = useSession();
  const company = currentOrganization();
  const { canWrite: canTagAnyPage } = useFeatureAccess(FeaturesEnum.ALL_STATIC_DATA_PAGES);
  const hasOnBehalfOfPage = !isNil(onBehalfOfPage);
  const { data: page } = useGetStaticDataDetails(
    {
      id: onBehalfOfPage?.pageId,
      type: onBehalfOfPage?.type,
    } as GetStaticDataDetailsParams,
    { enabled: hasOnBehalfOfPage },
  );

  if (
    (isComingFromGlobalCreateButton || isNil(onBehalfOfPage)) &&
    canTagAnyPage &&
    !isNil(company)
  ) {
    return {
      id: company.id,
      name: company.name,
      displayName: company.name,
      completeName: company.name,
      icon: company.logo,
      type: 'COMPANY',
    };
  } else if (!isNil(onBehalfOfPage) && !isNil(page)) {
    return {
      id: page.id,
      name: page.name,
      displayName: page.name,
      completeName: page.fullName,
      icon: page.photo,
      type: onBehalfOfPage.type,
    };
  }
};
