import { useEventEmitter, useMutation, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { createPostApi } from '../posts.service';

export const useCreatePost = (options: UseMutationOptions = {}) => {
  const { account: user } = useSession();

  const { emitWithSettledAfter } = useEventEmitter('PostsModified');

  return useMutation(createPostApi, {
    showSuccessSnackbar: true,
    onSuccess: ({ data }) => {
      emitWithSettledAfter(3000, { data, organizationId: user?.organizationId });
    },
    ...options,
  });
};
