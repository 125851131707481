import React from 'react';

import { styled } from '@mui/material/styles';

import { UserAvatar } from 'features/profiles/components/user-avatar';

type StateProfileProps = {
  isActive?: boolean;
};

const StyledUserAvatar = styled(UserAvatar, {
  shouldForwardProp: (prop) => {
    return prop !== 'isActive';
  },
})<StateProfileProps>(({ isActive, theme }) => ({
  border: isActive
    ? `2px solid ${theme.palette.Icons.Tertiary.IconFillPressed}`
    : `2px solid ${theme.palette.Icons.Tertiary.IconFillDefault}`,
  '&:hover': {
    borderColor: isActive
      ? theme.palette.Icons.Tertiary.IconFillPressed
      : theme.palette.Icons.Tertiary.IconFillHover,
  },
  width: 24,
  height: 24,
}));

const EmptyProfile: React.FC<StateProfileProps> = ({ isActive }) => {
  return isActive ? (
    <img src="/images/profileIconFilled.svg" alt="Profile" />
  ) : (
    <img
      src="/images/profileIconOutlined.svg"
      alt="Profile"
      onMouseOver={(e): void => {
        e.currentTarget.src = '/images/profileIconHover.svg';
      }}
      onMouseOut={(e): void => {
        e.currentTarget.src = '/images/profileIconOutlined.svg';
      }}
    />
  );
};

type ProfileIconProps = {
  photo?: string;
  isActive?: boolean;
};

const ProfileIcon: React.FC<ProfileIconProps> = ({ isActive, photo }) => {
  return (
    <>
      {photo ? (
        <StyledUserAvatar src={photo} size="small" isActive={isActive} />
      ) : (
        <EmptyProfile isActive={isActive} />
      )}
    </>
  );
};

export default ProfileIcon;
