import MUIDialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import MUIDialogActions, {
  DialogActionsProps as MUIDialogActionsProps,
} from '@mui/material/DialogActions';
import MUIDialogContent, {
  DialogContentProps as MUIDialogContentProps,
} from '@mui/material/DialogContent';
import MUIDialogTitle, {
  DialogTitleProps as MUIDialogTitleProps,
} from '@mui/material/DialogTitle';
import { styled, useTheme } from '@mui/material/styles';
import { CloseButton } from 'common/components/navigation';
import * as React from 'react';

const StyledDialog = styled(MUIDialog)(({ theme }) => ({
  '& .MuiDialogActions-root': {
    background: theme.palette.Container.Bg,
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.Container.Bg,
  },
}));

export type DialogTitleProps = MUIDialogTitleProps & {
  titleElement?: React.ReactNode;
  closeButtonEnabled?: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
};

const DialogTitle = (props: DialogTitleProps) => {
  const { titleElement, closeButtonEnabled, onClose, ...other } = props;
  const { palette } = useTheme();

  return (
    <MUIDialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        m: 0,
        p: 2,
        color: palette.Text.Headline,
        fontSize: '1.625rem', // 26px
        fontWeight: 600,
      }}
      {...other}
    >
      {titleElement}
      {closeButtonEnabled && onClose && (
        <CloseButton
          aria-label="close"
          clickHandler={(event) => onClose(event, 'backdropClick')}
        ></CloseButton>
      )}
    </MUIDialogTitle>
  );
};

export type DialogContentProps = MUIDialogContentProps;
export const DialogContent: React.FC<MUIDialogContentProps> = (props) => {
  const { children, ...dialogContentProps } = props;
  return <MUIDialogContent {...dialogContentProps}>{children}</MUIDialogContent>;
};

export type DialogActionsProps = MUIDialogActionsProps;
export const DialogActions: React.FC<DialogActionsProps> = (props) => {
  const { children, ...dialogActionsProps } = props;
  return <MUIDialogActions {...dialogActionsProps}>{children}</MUIDialogActions>;
};

export type DefaultDialogProps = MUIDialogProps;
export type DialogProps = DefaultDialogProps & {
  title?: string;
  closeButtonEnabled?: boolean;
  onClose?: (event: any) => void;
  children: React.ReactElement<DialogContentProps>;
  actions?: React.ReactElement<DialogActionsProps>;
};

const Dialog: React.FC<DialogProps> = (props) => {
  const { palette } = useTheme();
  const { title, closeButtonEnabled, children, open, onClose, actions, ...dialogProps } =
    props;

  return (
    <StyledDialog
      {...dialogProps}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: palette.Container.Bg,
          border: `1px solid ${palette.Container.Stroke}`,
        },
      }}
    >
      {title && (
        <DialogTitle
          titleElement={title}
          closeButtonEnabled={closeButtonEnabled}
          onClose={onClose}
        />
      )}
      {children}
      {actions || null}
    </StyledDialog>
  );
};
export default Dialog;
