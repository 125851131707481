export enum FormOperationsEnum {
  CREATE = 'create',
  UPDATE = 'update',
  EDIT = 'edit',
  DELETE = 'delete',
  CLOSE_EDIT = 'close-edit',
  ADD_OR_UPDATE_CITY = 'add-or-update-city',
  EXPAND = 'expand',
  TOGGLE_VISIBILITY = 'toggle-visibility',
}
