import React from 'react';
import { Navigate } from 'react-router-dom';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { useRouter, useSession } from 'common/hooks';

type NavigateToOrgProfileProps = {
  route?: string;
  profileId?: string;
  state?: any;
};
const NavigateToOrgProfile: React.FC<NavigateToOrgProfileProps> = ({
  route = AppRoutesEnum.PROFILE,
  profileId,
  state = {},
}) => {
  const { account } = useSession();
  profileId = profileId || account?.profileId;
  const { getOrgRoute } = useRouter();
  const profileRoute = getOrgRoute(route, { profileId });

  return <Navigate to={profileRoute} state={state} />;
};

export default NavigateToOrgProfile;
