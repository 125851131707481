import { styled } from '@mui/material/styles';
import { getCommonEditorContentStyles } from './cx-editor-common.styles';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const CXEditorStyledContainer = styled('div')(({ theme }) => ({
  '& .toastui-editor-defaultUI': {
    borderRadius: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    borderColor: theme.palette.TextFields.Default,
  },
  ...getCommonEditorContentStyles(theme),
  '& .toastui-editor-ww-container': {
    backgroundColor: theme.palette.Container.Bg,
    borderBottomLeftRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
  },
  '& .toastui-editor-toolbar-divider': {
    backgroundColor: theme.palette.TextFields.Default,
  },
  '& .toastui-editor-contents': {
    fontFamily: theme.typography.fontFamily,
    padding: '0.844rem',
    color: theme.palette.TextFields.InputText,
  },
  '& .toastui-editor-toolbar': {
    overflow: 'hidden',
    borderTopLeftRadius: '0.75rem',
    borderTopRightRadius: '0.75rem',
  },
  '& .toastui-editor-defaultUI-toolbar': {
    backgroundColor: theme.palette.MarkdownEditor.Background,
    borderColor: theme.palette.TextFields.Default,
  },
  '& .toastui-editor-defaultUI-toolbar button': {
    borderColor: 'transparent',
  },
  '& .toastui-editor-defaultUI-toolbar button:not(:disabled):hover': {
    backgroundColor: 'transparent',
    backgroundPositionY: '-31px',
  },
  '& .toastui-editor-toolbar-icons': {
    backgroundImage: `url('/images/toastUIEditorToolbar-${theme.palette.mode}.svg')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '466px 107px',
  },
  '& .toastui-editor-toolbar-icons:not(:disabled).active': {
    backgroundPositionY: '-65px',
  },
  '& .toastui-editor-toolbar-icons.heading': {
    backgroundPositionX: '3px',
  },
  '& .toastui-editor-toolbar-icons.bold': {
    backgroundPositionX: '5px',
  },
  '& .toastui-editor-toolbar-icons.italic': {
    backgroundPositionX: '-60px',
  },
  '& .toastui-editor-toolbar-icons.quote': {
    backgroundPositionX: '-124px',
  },
  '& .toastui-editor-toolbar-icons.bullet-list': {
    backgroundPositionX: '-187px',
  },
  '& .toastui-editor-toolbar-icons.ordered-list': {
    backgroundPositionX: '-252px',
  },
  '& .toastui-editor-toolbar-icons.link': {
    backgroundPositionX: '-317px',
  },
  '& .toastui-editor-toolbar-icons.more': {
    backgroundPositionX: '-381px',
  },
  '& .toastui-editor-contents p': {
    color: theme.palette.TextFields.InputText,
    '& > .placeholder': {
      color: theme.palette.TextFields.HintText,
    },
  },
  '& .toastui-editor-contents blockquote': {
    borderColor: theme.palette.TextFields.InputText,
    color: theme.palette.TextFields.InputText,
  },
  '& .toastui-editor-contents blockquote p': {
    color: theme.palette.TextFields.InputText,
  },
  '& .toastui-editor-contents ul > li::before': {
    marginTop: '9px',
    backgroundColor: theme.palette.TextFields.InputText,
  },
  '& .toastui-editor-contents ol > li::before': {
    color: theme.palette.TextFields.InputText,
  },
  '& .toastui-editor-popup': {
    backgroundColor: theme.palette.Card.Bg,
    boxShadow: AppBoxShadows.shadow3,
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  '& .toastui-editor-popup-body': {
    color: theme.palette.TextFields.InputText,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 10px',
    },
  },
  '& .toastui-editor-popup-body label': {
    color: theme.palette.TextFields.InputText,
  },
  '& .toastui-editor-popup-body input[type="text"]': {
    width: '100%',
    color: theme.palette.TextFields.InputText,
    borderColor: theme.palette.TextFields.Default,
    background: 'none',
  },
  '& .toastui-editor-popup-body input[type="text"]:focus': {
    borderColor: theme.palette.TextFields.Hover,
    outlineColor: theme.palette.TextFields.Hover,
  },
  '& .toastui-editor-defaultUI .toastui-editor-ok-button': {
    backgroundColor: theme.palette.Buttons.Primary.BgDefault,
  },
  '& .toastui-editor-defaultUI .toastui-editor-ok-button:hover': {
    backgroundColor: theme.palette.Buttons.Primary.BgHover,
  },
  '& .toastui-editor-defaultUI .toastui-editor-close-button': {
    borderColor: 'transparent',
    outlineColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.Buttons.Tertiary.LabelDefault,
  },
  '& .toastui-editor-defaultUI .toastui-editor-close-button:hover': {
    backgroundColor: theme.palette.Buttons.Tertiary.BgHover,
  },
}));
