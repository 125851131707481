import React from 'react';
import Videocam from '@mui/icons-material/Videocam';
import LinkIcon from '@mui/icons-material/Link';
import { Box } from 'common/components/material';
import { Stack, useTheme } from '@mui/material';
import { CloseButton } from 'common/components/navigation';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import theme from 'common/theme';
import { MediaType } from 'features/media/media-type.enum';
import ColorPalette from 'common/theme/colorPalette';
import { getThemeImagePath } from 'common/utils/file.utils';
import { Media } from 'features/media/types/media.types';

const sizeText = (size: number) => {
  if (!size) {
    return '-';
  }

  if (size < 1000) {
    return `${Math.floor(size)} Bites`;
  } else if (size < 1000000) {
    return `${Math.floor(size / 1000)} Kb`;
  } else if (size < 1000000000) {
    return `${Math.floor(size / 1000000)} Mb`;
  } else {
    return `${Math.floor(size / 1000000000)} Gb`;
  }
};

const MediaPreview: React.FC<{
  media: Media;
  showCloseButton?: boolean;
  width?: number | string;
  onClose?: (id: string, type: MediaType) => void;
}> = ({ media, showCloseButton, width, onClose }) => {
  const { palette } = useTheme();
  const type = media.type;
  const isALink = type === MediaType.LINK || type === MediaType.VIDEO_URL;
  const isAVideo = type === MediaType.VIDEO;
  const isAPhoto = type === MediaType.PHOTO;

  const id = media.id || (isALink ? media.url : media.name) || '';
  const photo = (isAPhoto ? media.fileUrl : media.thumbnailUrl) || '';
  const label = media.label || media.name;
  const subLabel = (isAPhoto || isAVideo ? sizeText(media.size || 0) : media.url) || '-';

  showCloseButton = showCloseButton ?? true;

  return (
    <Stack
      key={id}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      position="relative"
      sx={{
        background: `url(${
          photo ? photo : getThemeImagePath('BestOf', palette.mode, 'mediaBackground.svg')
        })`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'hidden',
        width: width || '100%',
        aspectRatio: 1 / 1,
        border: 'solid 2px',
        borderColor: theme.palette.Container.Stroke,
        borderRadius: '0.75rem',
      }}
    >
      <Stack
        display="flex"
        flexDirection={'row-reverse'}
        justifyContent={'space-between'}
        padding={0.75}
      >
        {showCloseButton ? (
          <CloseButton onClick={() => onClose && onClose(id, type)} />
        ) : (
          <Box />
        )}
        {type !== MediaType.PHOTO && (
          <Box
            width={25}
            height={25}
            sx={{ backgroundColor: ColorPalette.WHITE, opacity: '75%' }}
          >
            {type === MediaType.VIDEO ? (
              <Videocam sx={{ color: palette.Text.Paragraph }} />
            ) : (
              <LinkIcon sx={{ color: palette.Text.Paragraph }} />
            )}
          </Box>
        )}
      </Stack>
      <Stack
        padding={0.75}
        sx={{
          backgroundColor: palette.Container.Bg,
        }}
        spacing={0}
      >
        <EllipsisText
          variant="body3"
          lineclamp={1}
          color={isALink ? palette.LinkText.Active : palette.Text.Paragraph}
        >
          {label}
        </EllipsisText>
        <EllipsisText variant="body3" width={'80%'} lineclamp={1}>
          {subLabel}
        </EllipsisText>
      </Stack>
    </Stack>
  );
};

export default MediaPreview;
