import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import EditIcon from '@mui/icons-material/Edit';

import { Stack } from 'common/components/material';
import { EditIconTypeEnum } from './cx-edit-icon.enum';
import { OutlinedIconButton, StyledIconButton } from './cx-edit-icon.style';
import { CXEditIconProps } from './cx-edit-icon.type';

export const CXEditIcon: React.FC<CXEditIconProps> = ({
  onClick,
  isVisible = true,
  iconType = EditIconTypeEnum.FILLED_BACKGROUND,
  size = 'small',
  disabled,
  className,
}) => {
  if (!isVisible) {
    return <></>;
  }

  if (iconType === EditIconTypeEnum.OUTLINED) {
    return (
      <Stack className={className} direction="row-reverse">
        <OutlinedIconButton size={size} onClick={onClick} disabled={disabled}>
          <CreateOutlinedIcon fontSize="inherit" />
        </OutlinedIconButton>
      </Stack>
    );
  }

  if (iconType === EditIconTypeEnum.FILLED) {
    return (
      <Stack className={className} direction="row-reverse">
        <OutlinedIconButton size={size} onClick={onClick} disabled={disabled}>
          <EditIcon fontSize="inherit" />
        </OutlinedIconButton>
      </Stack>
    );
  }

  return (
    <Stack className={className} direction="row-reverse">
      <StyledIconButton size={size} onClick={onClick} disabled={disabled}>
        <EditIcon fontSize="inherit" />
      </StyledIconButton>
    </Stack>
  );
};
