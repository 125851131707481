import AppRoutesEnum from 'common/routes/AppRoutes.enum';

export const API_TAG = {
  locations: 'locations/details',
  industries: 'industries',
  jobTitles: 'job-titles',
  institutions: 'institutions',
};

export const DEFAULT_MAX_STATIC_DATA_COLUMNS = 3;

export const StaticDataRoutePath = '/:organizationName/static-data/:type/:staticDataId';
export const StaticDataSummaryRoutePath = '/:organizationName/static-data/:type/:staticDataId/summary';

export const StaticDataTypeDisplayName = {
  DEPARTMENT: 'Department',
  DIVISION: 'Division',
  WORK_LOCATION: 'Work Location',
  TEAM: 'Team',
  PRODUCT: 'Product',
  GROUP: 'Group',
  CITY: 'City',
  COUNTRY: 'Country',
};

export const STATIC_DATA_ADD_DESCRIPTION = 'Add Description';
export const STATIC_DATA_EDIT_DESCRIPTION = 'Edit Description';

export const HIGHLIGHT_TILES_CONFIG = {
  PEOPLE: {
    key: 'people',
    label: 'People',
    pathName: AppRoutesEnum.EXPLORE_PEOPLE,
  },
  CITY: {
    key: 'city',
    label: 'Cities',
  },
  PRODUCT: {
    key: 'product',
    label: 'Products',
  },
  DEPARTMENT: {
    key: 'department',
    label: 'Departments',
  },
  DIVISION: {
    key: 'division',
    label: 'Divisions',
  },
  TEAM: {
    key: 'team',
    label: 'Teams',
  },
  GROUP: {
    key: 'group',
    label: 'Groups',
  },
};

export const HIGHLIGHT_TILES_ORDER = [
  HIGHLIGHT_TILES_CONFIG.PEOPLE,
  HIGHLIGHT_TILES_CONFIG.CITY,
  HIGHLIGHT_TILES_CONFIG.PRODUCT,
  HIGHLIGHT_TILES_CONFIG.DEPARTMENT,
  HIGHLIGHT_TILES_CONFIG.DIVISION,
  HIGHLIGHT_TILES_CONFIG.TEAM,
  HIGHLIGHT_TILES_CONFIG.GROUP,
];
export const STATIC_DATA_OPPORTUNITIES_TITLE = 'Opportunities';

export const ASPIRATION_STATICDATA_TYPE = {
  ASPIRATION_DEPARTMENT: 'DEPARTMENT',
  ASPIRATION_DIVISION: 'DIVISION',
  ASPIRATION_CITY: 'CITY',
  ASPIRATION_TEAM: 'TEAM',
  ASPIRATION_PRODUCT: 'PRODUCT',
};
