import { Button, Divider, Stack } from 'common/components/material';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { ExploreSelectorComponent } from 'features/explore-selector/explore-selector.component';
import OrganizationEventsViewToggleTabs from 'features/organization-events/components/organization-events-view-toggle-tabs.component';
import { ActiveFilters } from 'features/search';
import { HORIZONTAL_FILTERS_CONFIG } from '../explore.constants';
import FilterTextButton from './FilterTextButton';
import { ProfilesExportButton } from './profiles-export-button.component';
import { useGetFilterMenuItems } from '../hooks';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

export const DesktopExploreHeaderComponent: React.FC<{
  contentType: ExploreTabEnum;
  activeFilters: ActiveFilters;
  onFiltersClick: () => void;
  clearFilters: () => void;
  applyFilters: (activeFilters: ActiveFilters, type: ExplorerDataTypeEnum) => void;
  isFilterApplied: () => boolean;
}> = ({
  contentType,
  activeFilters,
  onFiltersClick,
  clearFilters,
  applyFilters,
  isFilterApplied,
  children,
}) => {
  const { canRead: canExport } = useFeatureAccess(FeaturesEnum.PROFILE_EXPORT);
  const { menuItems: filters } = useGetFilterMenuItems(
    contentType,
    HORIZONTAL_FILTERS_CONFIG,
  );

  const ViewSelector =
    contentType === ExploreTabEnum.ORGANIZATION_EVENTS
      ? OrganizationEventsViewToggleTabs
      : null;

  return (
    <>
      <Stack direction="row" alignItems="center">
        {!!ViewSelector && (
          <>
            <ViewSelector />
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
          </>
        )}

        {!ViewSelector && (
          <FilterTextButton highlightText onClick={onFiltersClick} label="All Filters" />
        )}
        {!!filters.length && (
          <Stack spacing={1} direction="row">
            {filters.map((filterType) => (
              <ExploreSelectorComponent
                key={`${contentType}_${filterType.key}`}
                contentType={contentType}
                type={filterType.key as ExplorerDataTypeEnum}
                initialFilters={activeFilters}
                onChange={applyFilters}
              />
            ))}
          </Stack>
        )}
        {isFilterApplied() && (
          <Button
            btntype="label"
            size="small"
            onClick={clearFilters}
            sx={{ ml: 1 }}
            data-testid="clear-all-filters"
          >
            Clear All
          </Button>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {children}
        {contentType === ExploreTabEnum.PEOPLE && canExport && <ProfilesExportButton />}
      </Stack>
    </>
  );
};
