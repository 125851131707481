import React from 'react';
import { matchPath, Outlet, useLocation } from 'react-router-dom';

import { BackButton } from 'common/components/navigation';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

import RootContainer from 'features/org-root/components/RootContainer';
import { useView } from 'common/hooks';

export const Profile: React.FC = () => {
  const location = useLocation();
  const { isMobileView } = useView();

  const fullWidth = !!(matchPath(AppRoutesEnum.PROFILE, location.pathname) || isMobileView);

  const hideBackButton = !!matchPath(AppRoutesEnum.PROFILE_EDIT, location.pathname);

  return (
    <RootContainer
      spacing={1}
      fullWidth={fullWidth}
      headerNavButton={!hideBackButton ? <BackButton /> : <></>}
      disableVerticalScroll
    >
      <Outlet />
    </RootContainer>
  );
};
