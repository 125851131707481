import { settled } from 'common/types/Event-type.type';
import { ReadViewProject } from './types';
import { validateUrlProtocol } from 'common/utils/app.utils';

export const EVENTS_TO_REFETCH_PROJECTS = [
  'ProjectAdded',
  settled('ProjectUpdated'),
  settled('ProjectDeleted'),
];

export const isProjectContentAvailable = (
  project: ReadViewProject,
  considerUrl: boolean = true,
) => {
  if (!considerUrl) {
    return !!project.description || !!project.skills.length;
  }

  const validatedUrl = validateUrlProtocol(project.url);
  return !!project.description || !!project.skills.length || !!validatedUrl;
};
