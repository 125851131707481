import React from 'react';

import { Stack } from 'common/components/material';
import { SearchInput } from 'common/components/input';
import { useView } from 'common/hooks';
import { FilterExplorerHeaderProps } from '../types';
import FilterExploreViewToggleButton from './filter-explore-view-toggle-button.component';

const FilterExplorerHeader: React.FC<FilterExplorerHeaderProps> = ({
  showFlatList,
  searchTerm,
  searchCollapsed,
  showExploreViewToggle,
  children,
  onToggleView,
  onChangeTerm,
  onToggleSearchCollapse,
}) => {
  const { isDesktopView } = useView();
  const searchInputRef = React.useRef<HTMLInputElement>();

  const handleClear = () => {
    onChangeTerm('');
  };

  const handleSearchBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const clickedOnClearInput = e.relatedTarget?.className.includes('clear-icon');

    // Maintain focus after clearing search input
    if (clickedOnClearInput) {
      searchInputRef?.current?.focus();
    }
  };

  const toggleSearchCollapse = (collapsed: boolean) => {
    onToggleSearchCollapse(collapsed);
    if (!collapsed && searchInputRef?.current) {
      searchInputRef?.current?.focus();
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {children}
      {showExploreViewToggle && (
        <FilterExploreViewToggleButton
          showFlatList={showFlatList}
          disableColumnView={!!searchTerm}
          onToggleView={onToggleView}
        />
      )}
      <SearchInput
        inputRef={searchInputRef}
        collapsed={searchCollapsed && !isDesktopView}
        collapsible={!isDesktopView}
        value={searchTerm}
        onChange={onChangeTerm}
        onClear={handleClear}
        onBlur={handleSearchBlur}
        onToggleCollapse={toggleSearchCollapse}
        data-testid="filter-modal-search-input"
      />
    </Stack>
  );
};

export default FilterExplorerHeader;
