import { useContext, useCallback } from 'react';

import { ApplicationContext } from 'common/context/application-context';
import { EventType, settled } from 'common/types/Event-type.type';

type UseEventEmitterResponse<T> = {
  emit: (data?: T) => void;
  emitWithSettledAfter: (delayMs: number, data?: T) => void;
};

const useEventEmitter = <T>(eventType: EventType): UseEventEmitterResponse<T> => {
  const { eventManager } = useContext(ApplicationContext);

  const emit = useCallback(
    (data?: T) => {
      eventManager.emit(eventType, data);
    },
    [eventType, eventManager],
  );

  /**
   * We fetch data from 2 kinds of data sources: DB and ES.
   * DB is to be treated as the single source of truth in terms of data, whereas
   * ES is for search and faster manipulations for read operations.
   * Whenever an entity is created/deleted/updated, it is updated in DB, and
   * asynchronously, the event is pushed to update the ES document as well, but
   * as a user, a successful update in DB should be enough to notify them about the change,
   * ES update is sort of a side effect.
   * Because of the data being in these 2 different sources, and there can some time lag between
   * data successfully getting into DB, and ES document update, it gets tricky to know exactly
   * when to refetch the data for components which are fetching data from ES.
   *
   * What if, we arbitrarily waited a certain number of seconds (one representative
   * of the asynchronous operation getting completed), and simply generated a synthetic
   * event, one that cache listeners could subscribe to and say, refetch?
   * This solution is too simple to be true, and considering all the additional complexty
   * required to manually update React Query caches when something changes, a simple
   * timeout + refetch definitely seems like a win.
   *
   * Few other things worth exploring, if the following created more troubles
   * than it set out to solve:
   *
   * 1. Refetch not just once, but two or three times in a row, until a 200 is returned
   *    (if the update is still being processed, the API should return 304 instead)
   * 2. Come up with an API clients could use to know if one or multiple background jobs
   *    have been completed or not.  A simple implementation of this, would be for the
   *    client to pull X-Request-Id from the repsonse, and then ask the server for
   *    the status of all the jobs registered during that request.  What if a job creates
   *    another job, and you only care about the first one?
   *
   * Again, not ideal, and definitely feels more as an hack than an actual solution;
   * but at least it's quite easy to reason about, and when things are easy to reason
   * about, they are also easy to extend.
   *
   * Time will tell.
   */
  const emitWithSettledAfter = useCallback(
    (delayMs: number, data?: T) => {
      eventManager.emit(eventType, data);
      setTimeout(() => {
        eventManager.emit(settled(eventType), data);
      }, delayMs);
    },
    [eventType, eventManager],
  );

  return { emit, emitWithSettledAfter };
};

export default useEventEmitter;
