import axios from 'common/utils/axios';
import {
  CreateOrganizationEventRequest,
  UpdateOrganizationEventRequest,
  OrganizationEvent,
  FetchOrganizationEventTypeParams,
} from './types';

const organizationEventsApi = axios('organization-events');

export const getPaginatedOrganizationEventsApi = (data?: any) => {
  return organizationEventsApi.post('search', data);
};
export const getOrganizationEventsSummaryApi = (data?: any) => {
  if (!('groupBy' in data)) {
    data['groupBy'] = 'date';
  }
  if (!('eventFilterType' in data)) {
    data['eventFilterType'] = 'following';
  }
  return organizationEventsApi.post('search-events-summary', data);
};

export const getTotalOrganizationEventsApi = (data?: any) => {
  if (!('period' in data)) {
    data['period'] = 'month';
  }

  if (!('eventFilterType' in data)) {
    data['eventFilterType'] = 'following';
  }
  return organizationEventsApi.post('total-events', data);
};

export const createOrganizationEventApi = (data: CreateOrganizationEventRequest) => {
  return organizationEventsApi.post('', data);
};

export const updateOrganizationEventApi = ({
  id,
  ...data
}: UpdateOrganizationEventRequest) => {
  return organizationEventsApi.put(id, data);
};

export const getOrganizationEventTypesApi = () => {
  return organizationEventsApi.get('types');
};

export const getOrganizationEventTypesHierarchy = (params: FetchOrganizationEventTypeParams) => {
  return organizationEventsApi.get('types/hierarchy', { params });
};

export const getUpcomingOrganizationEventsApi = (tagId?: string) => {
  const params: Record<string, string | Date> = {
    localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    startDate: new Date(),
  };

  if (tagId) {
    params.tagId = tagId;
  }

  return organizationEventsApi.get('upcoming-events', {
    params,
  });
};

export const getLatestOrganizationEventsApi = (tagId?: string) => {
  return organizationEventsApi.get('latest', {
    params: { tagId, localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
  });
};

export const deleteOrganizationEventsApi = ({ id }: OrganizationEvent) => {
  return organizationEventsApi.delete(id);
};
