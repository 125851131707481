import React, { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { FormTextField } from 'common/components/input';
import { IconButton, InputAdornment } from 'common/components/material';

import { FormTextFieldProps } from 'common/types/Form.type';

const DEFAULT_LABEL = 'Password';
const DEFAULT_NAME = 'password';

const Password: React.FC<FormTextFieldProps> = React.forwardRef(({ label, name, helperText }, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const fieldLabel = label || DEFAULT_LABEL;

  return (
    <FormTextField
      fullWidth
      name={name || DEFAULT_NAME}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      required
      label={fieldLabel}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword((showPassword) => !showPassword)}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
     ref={ref} 
    ></FormTextField>
  );
});

export default Password;
