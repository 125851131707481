import { useDrawer } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { useSearchTags } from 'features/tags/hooks/useSearchTags';

export const useOpenDrawerWithTag = (
  drawerId: DrawerIdEnum,
  tagId: string,
  options: { [prop: string]: any } = {},
) => {
  const { openDrawer } = useDrawer();
  const { data: tags } = useSearchTags(
    { tagId, includeDescandants: false },
    {
      enabled: !!tagId,
    },
  );

  const openDrawerWithTag = () => {
    const drawerOptions = { ...options };

    if (tagId) {
      drawerOptions.selectedTags = tags;
    }

    openDrawer(drawerId, drawerOptions);
  };

  return { openDrawerWithTag };
};
