import { VerticalScrollableContainer } from 'common/components/container';
import { Dialog, Divider } from 'common/components/material';
import { DialogProps } from 'common/components/material/Dialog';
import { useView } from 'common/hooks';
import BottomDrawer from './BottomDrawer';

const ModalContainer: React.FC<{ hideDivider?: boolean; } & DialogProps> = (props) => {
  const { closeButtonEnabled = true, children, hideDivider, ...otherProps } = props;

  const { isDesktopView } = useView();

  return isDesktopView ? (
    <Dialog
      disableScrollLock={true}
      scroll="body"
      closeButtonEnabled={closeButtonEnabled}
      {...otherProps}
    >
      <>
        {!hideDivider && <Divider variant="middle" />}
        <VerticalScrollableContainer
          disableGutters
          maxHeight="80vh"
          sx={{
            padding: '0.625rem 1rem 1rem',
            minWidth: '25rem',
            minHeight: '5.313rem',
            maxHeight: '28.125rem',
            borderRadius: '.5rem',
          }}
        >
          {children}
        </VerticalScrollableContainer>
      </>
    </Dialog>
  ) : (
    <BottomDrawer {...props}>{children}</BottomDrawer>
  );
};
export default ModalContainer;
