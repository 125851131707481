import SearchIcon from '@mui/icons-material/Search';
import { styled, useTheme } from '@mui/material/styles';
import React, { ChangeEvent } from 'react';

import { ToggleVisibilityContainer } from 'common/components/container';
import { Avatar, InputAdornment } from 'common/components/material';
import TextField from 'common/components/material/TextField';
import { TextFieldProps } from 'common/components/material/TextField';
import { KeyboardEventKeyEnums } from 'common/enum/KeyboardEventKey.enum';

import CloseIcon from '@mui/icons-material/Close';
import { InlineIconButton } from 'features/org-root/org-root.styles';

const DEFAULT_PLACEHOLDER = 'Search';

export type SearchInputProps = Omit<TextFieldProps, 'onChange'> & {
  collapsed?: boolean;
  collapsible?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onChange: (value: string) => void;
  onClear?: () => void;
  onSubmit?: () => void;
  onToggleCollapse?: (collapsed: boolean) => void;
};

type StyledTextFieldProps = TextFieldProps & {
  collapsible?: boolean;
};

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => {
    return prop !== 'collapsible';
  },
})<StyledTextFieldProps>(({ theme, collapsible }) => ({
  [theme.breakpoints.down('sm')]: {
    flex: collapsible ? 1 : 'initial',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.75rem',
    justifyContent: 'space-between',
    width: '100%',
    transitionProperty: 'padding-left, padding-right, width, background-color',
    transitionDuration: '0.4s',
    ...theme.typography.body4,
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.Stroke.Default,
    },
    '&.Mui-focused:not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.Stroke.Active,
    },
    '&:hover:not(.Mui-error):not(.Mui-disabled) fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.Stroke.Active,
    },
  },
  '& .MuiOutlinedInput-input': {
    boxShadow: 'none',
  },
  '.MuiOutlinedInput-root input.MuiInputBase-input': {
    height: '0.8rem',
    transitionProperty: 'padding-left, padding-right, width, background-color',
    transitionDuration: '0.4s',
  },
  '&.MuiFormControl-root': {
    alignItems: 'flex-end',
  },
  '&.collapsed.MuiFormControl-root': {
    '.MuiAvatar-root': {
      width: 24,
      height: 24,
    },
    '.MuiInputBase-input.MuiInputBase-inputSizeSmall, .clear-icon': {
      width: 0,
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    '.MuiOutlinedInput-root': {
      backgroundColor: 'transparent',
      width: '50px',
    },
    '.MuiOutlinedInput-notchedOutline, &:hover:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
      {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
  },
}));

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = DEFAULT_PLACEHOLDER,
  value,
  collapsed,
  collapsible,
  startAdornment,
  endAdornment,
  onChange,
  onSubmit,
  onClear,
  onToggleCollapse,
  ...searchInputProps
}) => {
  const { palette } = useTheme();

  /**
   * Previously, it was `handleKeyUP`, not `handleKeyDown`.
   * We change this because there's a race condition when we have other key press listeners on key down. in this case, pressing ENTER.
   *
   * There, in other components, we have a logic to do something on ENTER, but should not do anything here (in SearchInput) until we enabled the ENTER in SearchInput.
   * but unfortunately,  while on the ENTER key down we also want to enable the ENTER on SearchInput.
   * It turn outs it also trigger the event on SearchInput since it is OnKeyUp. 😃
   *
   * we decided to revert the ability to disable `ENTER` in here, and overwrite the hook from the caller,
   * but keep it as `handleKeyDown`.
   */
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === KeyboardEventKeyEnums.ENTER && value) {
      onSubmit && onSubmit();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onChange('');
    onClear && onClear();
  };

  const toggleCollapse = () => {
    onToggleCollapse && onToggleCollapse(!collapsed);
  };

  return (
    <StyledTextField
      type="search"
      size="small"
      className={collapsed ? 'collapsed' : ''}
      collapsible={collapsible}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      value={value}
      inputProps={{ enterKeyHint: 'search' }}
      placeholder={collapsed ? '' : placeholder}
      {...searchInputProps}
      InputProps={{
        startAdornment,
        endAdornment: endAdornment || (
          <InputAdornment position="end">
            {value ? (
              <ToggleVisibilityContainer display="flex" isVisible={!!value}>
                <InlineIconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ marginRight: '2px' }}
                  onMouseDown={handleClear}
                >
                  <CloseIcon color="primary" />
                </InlineIconButton>
              </ToggleVisibilityContainer>
            ) : (
              <Avatar
                sx={{
                  width: 22,
                  height: 22,
                  bgcolor: palette.Icons.Primary.BgDefault,
                  '&:hover': {
                    bgcolor: palette.Icons.Primary.BgHover,
                  },
                }}
                onClick={collapsible ? toggleCollapse : onSubmit}
              >
                <SearchIcon
                  sx={{
                    cursor: 'pointer',
                    width: 15,
                    color: palette.Icons.Primary.IconFill,
                  }}
                />
              </Avatar>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
