import { sortBy } from 'lodash';
import { useQuery } from 'common/hooks';
import { getOrganizationEventTypesApi } from '../organization-events.service';

export const useGetOrganizationEventTypes = () => {
  const queryResponse = useQuery(
    ['organization-event-types'],
    () => getOrganizationEventTypesApi(),
    {
      select: ({ statusCode, data }) => {
        return {
          statusCode,
          data: sortBy(data, ['type', 'subType']),
        };
      },
    },
  );

  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};
