import { useScrollPosition } from 'common/context/hooks/useScrollPosition';
import { useLocation } from 'react-router-dom';

const useScrollPositionManager = () => {
  const { resetScrollPosition, pushScrollPosition, popScrollPosition } = useScrollPosition();
  const location = useLocation();
  const setScrollPosition = (navigationType: string) => {
    const state = location.state as any;
    if (state?.resetHistoryIndex) {
      resetScrollPosition();
    } else if (navigationType === 'PUSH') {
      pushScrollPosition();
    } else if (navigationType === 'POP') {
      popScrollPosition();
    }
  };

  return setScrollPosition;
};

export default useScrollPositionManager;
