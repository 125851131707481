import { styled } from '@mui/system';
import { Container, Dialog } from 'common/components/material';

export const QuickLinkDialog = styled(Dialog)({
  '& .MuiPaper-root': { borderRadius: '0.75rem' },
  '& .MuiContainer-root': {
    padding: 0,
  },
});

export const QuickLinksForm = styled(Container)(({ theme }) => ({
  borderRadius: '.5rem',
  padding: 0.5,
  [theme.breakpoints.up('md')]: {
    width: '27rem',
  },
}));
