import { QueryFunction, QueryKey, useInfiniteQuery as _useInfiniteQuery } from 'react-query';

import { UseInfiniteQueryOptions, UseInfiniteQueryResponse } from 'common/types/Api.type';
import useApiErrorHandler from './useApiErrorHandler';
import { useMemo } from 'react';

/**
 * A wrapper for React Query's useInfiniteQuery which manages the
 * state of paginated response we get from the server to be used
 * for inifinte scrolling functionality.
 */
const useInfiniteQuery = (
  queryKey: QueryKey,
  queryFn: QueryFunction<any, QueryKey>,
  options?: UseInfiniteQueryOptions,
): UseInfiniteQueryResponse => {
  const { formatError } = useApiErrorHandler(options?.onError);
  const key = options?.entityKey ?? (queryKey[0] as string);
  const transformInput = options?.transformInput;
  const query = _useInfiniteQuery(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    ...options,
  });

  const allItems = useMemo(() => {
    const result = [];
    for (const page of query?.data?.pages ?? []) {
      for (const each of page?.data?.[key] ?? []) {
        result.push(transformInput?.(each) ?? each);
      }
    }
    return result;
  }, [key, transformInput, query?.data]);
  return {
    ...query,
    data: {
      ...query.data,
      pages: allItems,
      pageParams: query?.data?.pages?.[query?.data?.pages.length - 1].data.pagination,
    },
    error: formatError(query.error),
  };
};

export default useInfiniteQuery;
