import React, { SyntheticEvent, useState } from 'react';

import FormAutocomplete from 'common/components/input/FormAutocomplete';
import useDeferredState from 'common/hooks/useDeferredState';
import useDebounce from 'common/hooks/useDebounce';
import {
  getNoSelectorOptionsText,
  getString,
  validateMinLength,
} from 'common/utils/app.utils';
import { StaticDataSelectorProps } from 'features/static-data/types/static-data-selector-props.type';
import { StaticData } from 'features/static-data/types/static-data.type';
import { getStaticDataOptionProps } from 'features/static-data/utils/static-data.utils';
import useGetStaticData from 'features/static-data/hooks/useGetStaticDataApi';

const StaticDataSelector: React.FC<StaticDataSelectorProps> = ({
  label,
  formFieldProps,
  type,
  value = null,
  preload,
  onChange,
  ...staticDataSelectorProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const [staticData, setStaticData] = useDeferredState<StaticData | null>(value);
  const { keyProp, labelProp } = getStaticDataOptionProps(type);

  const debouncedInputValue = useDebounce(inputValue);
  const selectedLabel = getString(staticData, labelProp);

  const inputIsSelectedValue = selectedLabel === debouncedInputValue;
  const shouldFetchStaticData =
    // Prevent fetching when staticData is set from parent
    !inputIsSelectedValue &&
    validateMinLength(debouncedInputValue) &&
    // Prevent fetching immediately when inputValue is cleared
    validateMinLength(inputValue);

  const searchParams = preload ? {} : { term: debouncedInputValue };

  const {
    data,
    isLoading: isLoadingStaticData,
    isSuccess: hasLoadedStaticData,
  } = useGetStaticData(type, searchParams, {
    enabled: preload || shouldFetchStaticData,
  });

  return (
    <FormAutocomplete
      autoComplete
      loading={isLoadingStaticData}
      label={label}
      optionKey={keyProp}
      getOptionLabel={(option: StaticData) => getString(option, labelProp, '')}
      isOptionEqualToValue={(option: StaticData, value: StaticData) => {
        const optionId = getString(option, keyProp);
        const valueId = getString(value, keyProp);
        return optionId === valueId;
      }}
      onInputChange={(e: SyntheticEvent, inputValue: string) => {
        setInputValue(inputValue);
      }}
      onChange={(value: StaticData) => {
        setInputValue('');
        setStaticData(value);
        onChange && onChange(value);
      }}
      options={data || []}
      noOptionsText={getNoSelectorOptionsText({
        hasData: hasLoadedStaticData,
        isLoading: isLoadingStaticData,
        label,
        preload,
      })}
      formFieldProps={formFieldProps}
      value={staticData}
      {...staticDataSelectorProps}
    />
  );
};

export default StaticDataSelector;
