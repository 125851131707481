import { useQuery, useSession } from 'common/hooks';
import axios from 'common/utils/axios';
import { FilterSearchParams } from 'features/search';
import { isEmpty } from 'lodash';

const api = axios('search');

const getProfileCount = (params: FilterSearchParams) => {
  return api.get(`count`, { params });
};

const useGetProfileCount = (params: FilterSearchParams) => {
  const { account: user } = useSession();

  const queryResponse = useQuery(
    [user?.organizationId, 'search', 'count', params],
    () => getProfileCount(params),
    {
      enabled: !isEmpty(params),
    },
  );

  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};

export default useGetProfileCount;
