import CircularProgress from 'common/components/material/CircularProgress';
import { Suspense } from 'react';

export const suspendable =
  (
    Component: any,
    fallback = <CircularProgress sx={{ alignSelf: 'center', marginTop: 20 }} size={100} />,
  ) =>
  (props: any) => {
    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };


