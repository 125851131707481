import React from 'react';

import { Button, Stack } from 'common/components/material';
import { useDrawer } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';

export const ManageWinListDrawerActions: React.FC<{
  isActionDisabled: boolean;
  onClick: () => void;
}> = ({ isActionDisabled, onClick }) => {
  const { closeDrawer } = useDrawer();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      justifySelf="flex-end"
      sx={{ my: 5 }}
      spacing={3}
    >
      <Button btntype="label" onClick={() => closeDrawer(DrawerIdEnum.MANAGE_WIN_LIST)}>
        Remind me later
      </Button>
      <Button btntype="primary" disabled={isActionDisabled} onClick={onClick}>
        Add to my profile
      </Button>
    </Stack>
  );
};
