import { useSession } from 'common/hooks';
import {
  createSearchParams,
  generatePath,
  NavigateOptions,
  Path,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useParams,
  matchPath,
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import queryClient from 'common/utils/QueryClient';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import useScrollPositionManager from 'common/context/hooks/useScrollPositionManager';
import { NO_ORG } from 'common/utils/app.utils';

export type Params = Record<string, string | number | boolean | undefined>;

type RoutePath = Omit<Path, 'search' | 'hash'> & {
  hash?: string;
  search?: string | URLSearchParamsInit | Params;
};

const useRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationName: currentOrganizationName } = useParams();
  const { account, refetchAccount } = useSession();
  const setScrollPosition = useScrollPositionManager();

  // ML: should we wrap all these inside useCallback?
  // AM: it depends on the reason why you would want to do that: performance?
  // ML: it's not for performance reasons, really (at least not directly); it's to make function
  //     references, stable, so we can list them as dependencies w/ useEffect, without causing
  //     too many re-renders -- so maybe it's for performance reasons afterall.
  // MR: also, we had once this "infinite re-render loop" issue caused by an effect that listed
  //     one of these functions as a dependency; turns out the effect was updating the component
  //     state (triggering a re-render), but since these functions are created anew every time,
  //     the re-render caused the effect to fire again, which in turn caused the component state
  //     to be updated again, and so on and so forth, until the browser crashed
  // AM: we shouldn't be passing these functions to any useEffect that we're using to update
  //     component state. Only for the ones that destroy the component (by navigating away).

  /**
   * Replaces `:organizationName` in a route with the user's current organization if present.
   * Otherwise, it works the same as generatePath.
   */
  const getOrgRoute = (
    route: string,
    params: Params = {},
    searchParams: URLSearchParamsInit = {},
  ) => {
    const pathParams = { ...params };

    if (route.includes(':organizationName')) {
      pathParams.organizationName = params.organizationName || account?.subUrl || NO_ORG;
    }

    const basePath = generatePath(route, pathParams as Record<string, string>);

    if (isEmpty(searchParams)) {
      return basePath;
    } else {
      return basePath + `?${createSearchParams(searchParams)}`;
    }
  };

  const goTo = (
    route: string | RoutePath,
    params: Params = {},
    options?: NavigateOptions,
  ) => {
    const basePath = typeof route === 'object' ? route.pathname : route;
    const pathname = getOrgRoute(basePath, params);
    const path: Partial<Path> = {
      pathname,
    };

    if (typeof route === 'object' && route.search) {
      path.search = `?${createSearchParams(route.search as URLSearchParamsInit)}`;
    }

    setScrollPosition('PUSH');
    navigate(path, options);
  };

  const goBack = () => {
    setScrollPosition('POP');
    navigate(-1);
  };

  const goHome = (params: Params = {}, options?: NavigateOptions) => {
    /*
     * Currently, /home redirects to /home/following; so if the user
     * wants to "go home", the router would first go /home, and then
     * /home/following, creating unnecessary page reload.  Here
     * we try to avoid that by looking at the current path.
     * TODO: in the future we could persist what tab the user prefers
     * to navigate to, and take the user there, instead of /home.
     */
    const onHome =
      matchPath(AppRoutesEnum.HOME_FOLLOWING, location.pathname) ||
      matchPath(AppRoutesEnum.HOME_ALL, location.pathname);
    const homePath = onHome ? location.pathname : AppRoutesEnum.HOME;
    goTo(homePath, params, options);
  };

  const updateOrganization = (subUrl: string) => {
    queryClient.clear();
    refetchAccount();
    if (currentOrganizationName) {
      navigate(location.pathname.replace(currentOrganizationName, subUrl));
    } else {
      goTo(AppRoutesEnum.HOME, { organizationName: subUrl });
    }
  };

  return {
    getOrgRoute,
    goTo,
    goHome,
    goBack,
    updateOrganization,
  };
};

export default useRouter;
