import { useEffect } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

import { NavigationTabEnum } from 'common/enum/NavigationTab.enum';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import useSession from './useSession';
import useLocalStorage from './useLocalStorage';

export type NavigationState = {
  currentTab?: NavigationTabEnum;
  previousTab?: NavigationTabEnum;
};

const useSetActiveNavigationTab = (setActiveNavigationTab: Function) => {
  const location = useLocation();
  const { value: recentSuburl } = useLocalStorage('recent-suburl');
  const { account, hasOrganization, subUrlOnSession } = useSession();

  useEffect(() => {
    if (
      matchPath(AppRoutesEnum.HOME_FOLLOWING, location.pathname) ||
      matchPath(AppRoutesEnum.HOME_ALL, location.pathname)
    ) {
      setActiveNavigationTab((navigationState: NavigationState) => ({
        currentTab: NavigationTabEnum.HOME,
        previousTab: navigationState.currentTab,
      }));
      return;
    }

    if (!account || !hasOrganization || !account?.profileId) return;

    const loggedInUserProfileRoute = generatePath(AppRoutesEnum.PROFILE, {
      profileId: account?.profileId,
      organizationName: account?.subUrl || subUrlOnSession || recentSuburl,
    });

    if (matchPath(loggedInUserProfileRoute, location.pathname)) {
      setActiveNavigationTab((navigationState: NavigationState) => ({
        currentTab: NavigationTabEnum.PROFILE,
        previousTab: navigationState.currentTab,
      }));
      return;
    }

    if (matchPath(AppRoutesEnum.ADMIN, location.pathname)) {
      setActiveNavigationTab((navigationState: NavigationState) => ({
        currentTab: NavigationTabEnum.ADMIN,
        previousTab: navigationState.currentTab,
      }));
      return;
    }

    if (matchPath(AppRoutesEnum.ORG_CHART_ROOT, location.pathname)) {
      setActiveNavigationTab((navigationState: NavigationState) => ({
        currentTab: NavigationTabEnum.ORG_CHART,
        previousTab: navigationState.currentTab,
      }));
      return;
    }

    if (matchPath(AppRoutesEnum.EXPLORE, location.pathname)) {
      setActiveNavigationTab((navigationState: NavigationState) => ({
        currentTab: NavigationTabEnum.EXPLORE,
        previousTab: navigationState.currentTab,
      }));
      return;
    }

    setActiveNavigationTab((navigationState: NavigationState) => ({
      currentTab: NavigationTabEnum.NOTIFICATIONS,
      previousTab: navigationState.currentTab,
    }));
  }, [
    location,
    setActiveNavigationTab,
    account,
    hasOrganization,
    recentSuburl,
    subUrlOnSession,
  ]);
};

export default useSetActiveNavigationTab;
