import { createContext } from 'react';

import EventManager from 'common/utils/EventManager';
import { AuthenticationContext } from 'common/types/authentication-context.type';
import { ThemeContext } from './types/theme-context.type';

export const ApplicationContext = createContext(
  {} as {
    authenticationContext: AuthenticationContext;
    eventManager: EventManager;
    historyIndex?: any;
    scrollPosition?: any;
    themeContext: ThemeContext;
  },
);
