import { defaults } from 'lodash';
import axios from 'common/utils/axios';
import { SearchParams } from 'features/search';
import { API_TAG } from '../static-data.constants';
import { OrganizationalStaticDataTypeEnum, StaticDataTypeEnum } from '../static-data.enum';
import {
  CreateStaticDataApiParams,
  DeleteStaticDataParams,
  GetOrganizationalAspirationStaticDataParams,
  GetOrganizationalStaticDataParams,
  GetStaticDataDetailsParams,
  ToggleStaticDataVisibilityApiParams,
  UpdateStaticDataApiParams,
} from '../types/static-data-server-request.type';

const staticDataApi = axios('');
const api = axios('static-data');

export const createStaticDataApi = (params: CreateStaticDataApiParams) => {
  return api.post('', params);
};

export const deleteStaticDataApi = ({ staticDataType, id }: DeleteStaticDataParams) => {
  return api.delete(`${staticDataType}/${id}`);
};

export const toggleVisibilityApi = ({
  id,
  ...params
}: ToggleStaticDataVisibilityApiParams) => {
  return api.put(`${params.type}/${id}/visibility`, params);
};

export const getManagedStaticDataApi = (params: GetOrganizationalStaticDataParams) => {
  return api.get('managed-static-data', { params });
};

export const getOrganizationalStaticDataApi = (params: GetOrganizationalStaticDataParams) => {
  return api.get('', { params });
};

// Here we are transforming all the aspirations staticdata type to static data types that server understands.
export const getOrganizationalAspirationStaticDataApi = (
  params: GetOrganizationalAspirationStaticDataParams,
) => {
  return api.get('', { params });
};

export const getVisibleOrganizationalStaticDataApi = () => {
  return api.get('static-data-types');
};

export const toggleStaticDataVisibilityApi = (
  staticDataType: OrganizationalStaticDataTypeEnum,
) => {
  return api.put(`toggle-visibility/${staticDataType}`);
};

export const updateStaticDataApi = ({ id, ...params }: UpdateStaticDataApiParams) => {
  return api.put(id, params);
};

export const getStaticDataApi = (
  staticDataType: StaticDataTypeEnum,
  params: SearchParams,
) => {
  return staticDataApi.get(API_TAG[staticDataType], { params });
};

export const hasUserInteractionStaticDataApi = () => {
  return api.get('has-user-interacted-with-static-data');
};

export const getStaticDataHighlightsApi = (id?: string) => {
  return api.get('highlights', { params: { id } });
};

export const getHighlightedStaticDataApi = (
  type: OrganizationalStaticDataTypeEnum,
  id?: string,
) => {
  const params = defaults({ type, id });
  return api.get(`highlight-details`, { params });
};

export const getLocationAsStaticDataApi = (params: any) => {
  return api.get('locations', { params });
};

export const getTopStaticDataApi = (params: GetStaticDataDetailsParams) => {
  return api.get('top-static-data', { params });
};
