import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import ViewHeadlineOutlinedIcon from '@mui/icons-material/ViewHeadlineOutlined';

import { ToggleButton, ToggleButtonGroup, Tooltip } from 'common/components/material';
import { useRouter, useView } from 'common/hooks';
import { PeopleSearchRoutesEnum } from '../explore.enums';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { deleteDataGridParams } from '../explore.utils';

const PeopleExploreViewToggleButton: React.FC = () => {
  const { isDesktopView } = useView();
  const { goTo } = useRouter();
  const [searchParams] = useSearchParams();
  const params = useParams();

  const selectedTab = (params['*'] as PeopleSearchRoutesEnum) || PeopleSearchRoutesEnum.CARD;

  const handleToggleView = (e: React.MouseEvent, tab: PeopleSearchRoutesEnum) => {
    deleteDataGridParams(searchParams, true);
    goTo(
      { pathname: AppRoutesEnum.EXPLORE_PEOPLE_ROOT, search: searchParams.toString() },
      { '*': tab },
      { replace: true },
    );
  };

  return (
    <ToggleButtonGroup color="primary" value={selectedTab} fullWidth={!isDesktopView}>
      <ToggleButton
        value={PeopleSearchRoutesEnum.CARD}
        sx={{ height: '1.75rem' }}
        size="small"
        onClick={handleToggleView}
        fullWidth
      >
        <Tooltip title="Card view">
          <ViewDayOutlinedIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        value={PeopleSearchRoutesEnum.TABLE}
        sx={{ height: '1.75rem' }}
        size="small"
        onClick={handleToggleView}
        fullWidth
      >
        <Tooltip title="Table view">
          <ViewHeadlineOutlinedIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PeopleExploreViewToggleButton;
