import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { ExplorerHeaderActionComponentProps } from 'features/data-explorer';
import { Stack, Typography } from 'common/components/material';
import { FilterCheckbox } from '../styles/filter-checkbox.component';
import { useSearchParams } from 'react-router-dom';

const IncludeIndirectReportsCheckbox: React.FC<ExplorerHeaderActionComponentProps> = ({
  onAction,
}) => {
  const { palette } = useTheme();
  const [searchParams] = useSearchParams();
  const initialState = searchParams.get('includeIndirectReports');
  const [checked, setChecked] = useState(!!initialState);

  const toggleCheckbox = () => {
    const newState = !checked;
    setChecked(newState);
    onAction({
      data: {
        includeIndirectReports: newState,
      },
      actionType: 'toggleMetaFilter',
    });
  };

  return (
    <Stack direction="row" alignItems="center" pr={1}>
      <FilterCheckbox size="small" checked={checked} onClick={toggleCheckbox} />
      <Typography variant="caption" fontWeight={500} color={palette.Text.Caption}>
        Include Indirect Reports
      </Typography>
    </Stack>
  );
};

export default IncludeIndirectReportsCheckbox;
