import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Children, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { compact } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { FormAutocomplete } from 'common/components/input';
import { Typography } from 'common/components/material';
import Paper, { PaperProps } from 'common/components/material/Paper';
import { useGetHeadlineOptions } from '../hooks/useGetHeadlineOptions';
import { PRAISE_HEADLINE_MAX_LENGTH } from '../praise.constants';

const CustomPaper = (props: PaperProps) => {
  const { palette } = useTheme();
  const hasChildren = compact(Children.toArray(props.children)).length > 0;
  if (!hasChildren) return <></>;

  return (
    <Paper elevation={3} {...props} sx={{ backgroundColor: palette.DropdownList.Bg }}>
      <Typography ml={2} variant="caption" color={palette.Text.Caption}>
        Suggestions
      </Typography>
      {props.children}
    </Paper>
  );
};

export const PraiseHeadlineField: React.FC = () => {
  const { getValues, setValue, clearErrors } = useFormContext();
  const [formHeadline, setFormHeadline] = useState<string | undefined>(getValues('headline'));
  const [headlineOptions, setHeadlineOptions] = useState<string[]>([]);
  const { data: defaultHeadlineOptions, isLoading } = useGetHeadlineOptions();
  const editMode = getValues('receiver')?.[0];
  const [openOnEmptyInput, setOpenOnEmptyInput] = useState(false);

  useEffect(() => {
    if (defaultHeadlineOptions) {
      setHeadlineOptions(defaultHeadlineOptions);
    }
  }, [defaultHeadlineOptions]);

  const addOptionOnInputChange = (value?: string) => {
    setFormHeadline(value);

    if (!value?.length) {
      setValue('headline', undefined);
      return;
    }
    clearErrors('description');

    if (!headlineOptions.includes(value)) {
      setHeadlineOptions([...headlineOptions, value]);
    }
    setValue('headline', value, {
      /*
       * we can't undo isDirty without resetting the whole form without isValidSync
       * once we interact with the input, isDirty will always be true hence there's no
       * use of `shouldDirty: false`
       */
      shouldDirty: true,
      /*
       * If don't explicitly trigger the validation, only the field errors will go away and
       * the form will stil be marked as invalid (i.e., isValid flag will be false).
       *
       * This flag makes sure that users typing into the text field (and not selecting items
       * from the dropdown) will see the Send button getting enabled immediately as they type
       * and not on blur events.
       */
      shouldValidate: true,
    });
  };

  return (
    <FormAutocomplete
      name="headline"
      label="Headline"
      freeSolo
      open={openOnEmptyInput}
      PaperComponent={CustomPaper}
      options={headlineOptions}
      loading={isLoading}
      value={formHeadline}
      formFieldProps={{
        autoFocus: !!editMode,
        inputProps: { maxLength: PRAISE_HEADLINE_MAX_LENGTH },
        InputProps: {
          onBlur: () => {
            setOpenOnEmptyInput(false);
          },
          onFocus: () => {
            setOpenOnEmptyInput(!formHeadline);
          },
        },
      }}
      onInputChange={(e, value: string) => {
        addOptionOnInputChange(value);
        setOpenOnEmptyInput(!value);
      }}
      filterOptions={(options = []) =>
        options.filter(
          (label: string) => defaultHeadlineOptions.includes(label) && formHeadline !== label,
        )
      }
      renderOption={(props, label: string, { inputValue }) => {
        const matches = match(label, inputValue, { insideWords: true });
        const prunedTexts = parse(label, matches);

        return (
          <li {...props} key={label} className={`MuiAutocomplete-option highlight`}>
            <Typography variant="body3">
              {prunedTexts.map((item, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: item.highlight ? 600 : 400,
                  }}
                >
                  {item.text}
                </span>
              ))}
            </Typography>
          </li>
        );
      }}
    />
  );
};
