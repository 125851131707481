import { useMemo } from 'react';

import { SuccessResponse, UseQueryOptions } from 'common/types/Api.type';
import { Profile } from 'features/profiles/models/profile.model';

import { useQuery } from 'common/hooks';
import { getProfileApi } from '../profiles.service';
import { useGetAccount } from 'features/accounts';
import { GetProfileParams } from '../types';

export const useGetProfile = (
  params: GetProfileParams = {},
  options: UseQueryOptions = {},
) => {
  const { data: account } = useGetAccount();

  const id = params.id || account?.profileId;
  params.id = id;

  const isOwnProfile = !!id && id === account?.profileId;

  const queryKey = [];
  if (account?.organizationId) {
    queryKey.push(account.organizationId);
  }

  queryKey.push('profile', params.id);

  // Disable the query if:
  // - we do not have an ID (e.g. we have an authenticated account, but the profile still needs to be created)
  // - the caller of the hook sais so
  const enabled = !!id && (options.enabled ?? true);
  const response = useQuery(queryKey, () => (id ? getProfileApi(params) : null), {
    showErrorSnackbar: isOwnProfile,
    refetchOnMount: !isOwnProfile,
    ...options,
    enabled,
    select: (response: SuccessResponse) => {
      return response?.data;
    },
  });

  const profile = useMemo<Profile>(
    () => response.data && new Profile(response.data, account),
    [response.data, account],
  );

  return {
    ...response,
    data: profile,
  };
};
