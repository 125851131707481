import { get, isArray } from 'lodash';

import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { Skill } from 'features/skills';
import { updateOpportunityApi } from '../opportunities.service';
import { VisibleTo } from 'common/types/visible-to.type';

export const useUpdateOpportunity = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter('OpportunityUpdated');

  const mutationResponse = useMutation(updateOpportunityApi, {
    showSuccessSnackbar: true,
    transformInput: (input) => {
      const { id, title, description, skills, status, tags, manager, visibleTo } = input;
      return {
        id,
        title,
        description,
        skillIds: skills.map((skill: Skill) => skill.id),
        assigneeId: get(input, 'assignee[0].id'),
        managerId: isArray(manager) ? get(input, 'manager[0].id') : get(input, 'manager.id'),
        status,
        tags,
        visibleTo: visibleTo.map((v: VisibleTo) => v.id),
      };
    },

    onSuccess: () => {
      emitWithSettledAfter(5000);
    },
    ...options,
  });
  return mutationResponse;
};
