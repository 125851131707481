import React, { useEffect, useRef, useState } from 'react';
import { map, isEmpty, isNil, filter } from 'lodash';

import { Box, Stack, Tooltip } from 'common/components/material';
import { MediaType } from '../../media-type.enum';
import { Media } from '../../types/media.types';
import MediaPreview from './media-preview';
import { MediaPhotoLoader } from './media-photo-loader';
import { MediaVideoLoader } from './media-video-loader';
import { HorizontalScrollableContainer } from 'common/components/container';

const DISABLED_NON_LINK_TAB_TOOLTIP_MESSAGE =
  'Delete the existing media/webLink to upload this media type';
const DISABLED_LINK_TAB_TOOLTIP_MESSAGE =
  'Delete the existing media/weblink. You can only have a single weblink';

export const MediaLoader: React.FC<{
  media: Media[];
  onChange: (mediaAttachments: Media[]) => void;
}> = ({ media: existingMedia, onChange }) => {
  const [isMediaChanged, setIsMediaChanged] = useState(false);
  const [selectedType, setSelectedType] = useState<MediaType | null>(
    existingMedia?.[0]?.type,
  );
  const isLinkSelected = selectedType === MediaType.LINK;

  const mediaPreviewRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isMediaChanged && !isEmpty(existingMedia)) {
      mediaPreviewRef.current?.scrollIntoView({ behavior: 'smooth' });
      setIsMediaChanged(false);
    }
  }, [existingMedia, isMediaChanged, setIsMediaChanged]);

  const onSelectType = (type: MediaType) => {
    if (isEmpty(existingMedia) && selectedType !== type) {
      setSelectedType(type);
    }
  };

  const deleteMedia = (id: string, type: MediaType) => {
    const media = filter(existingMedia, (media) => {
      const mediaId =
        media.id || (type === MediaType.LINK ? media.url : media.fileName) || '';
      return mediaId !== id;
    });

    if (isEmpty(media)) {
      setSelectedType(null);
    }

    onListOfMediaChanged(media);
  };

  const onListOfMediaChanged = (listOfMedia: Media[]) => {
    setIsMediaChanged(true);
    onChange([...listOfMedia]);
  };

  const renderMediaPreview = (listOfMedia: Media[]) => {
    return (
      <HorizontalScrollableContainer
        scrolledListElement={
          <Stack width="fit-content" direction={'row'} spacing={1}>
            {map(listOfMedia, (media) => {
              return (
                <MediaPreview
                  width={'200px'}
                  key={media.id || media.fileName || media.url}
                  media={media}
                  onClose={deleteMedia}
                />
              );
            })}
          </Stack>
        }
      />
    );
  };

  const isTabSelected = !isEmpty(existingMedia) && !isNil(selectedType);
  const isPhotoOrVideoDisabled = isLinkSelected && isTabSelected;
  const isLinkDisabled = (!isLinkSelected && isTabSelected) || !isEmpty(existingMedia);

  return (
    <Stack direction="column" spacing={1} gap={1}>
      <Tooltip title={isPhotoOrVideoDisabled ? DISABLED_NON_LINK_TAB_TOOLTIP_MESSAGE : ''}>
        <Box>
          <MediaPhotoLoader
            existingListOfMedia={existingMedia}
            disabled={isPhotoOrVideoDisabled}
            onClick={() => onSelectType(MediaType.PHOTO)}
            onChange={onListOfMediaChanged}
          />
        </Box>
      </Tooltip>
      <Tooltip title={isPhotoOrVideoDisabled ? DISABLED_NON_LINK_TAB_TOOLTIP_MESSAGE : ''}>
        <Box>
          <MediaVideoLoader
            key="video-loader"
            title="Upload Videos"
            label="Max 5GB"
            type={MediaType.VIDEO}
            existingListOfMedia={existingMedia}
            disabled={isLinkSelected && !isNil(selectedType)}
            onClick={() => onSelectType(MediaType.VIDEO)}
            onChange={onListOfMediaChanged}
          />
        </Box>
      </Tooltip>
      <Tooltip title={isLinkDisabled ? DISABLED_LINK_TAB_TOOLTIP_MESSAGE : ''}>
        <Box>
          <MediaVideoLoader
            key="link-loader"
            title="Add Web Link"
            label="Add URL"
            type={MediaType.LINK}
            existingListOfMedia={existingMedia}
            disabled={isLinkDisabled}
            onClick={() => onSelectType(MediaType.LINK)}
            onChange={onListOfMediaChanged}
          />
        </Box>
      </Tooltip>
      <Box ref={mediaPreviewRef}>
        {!isEmpty(existingMedia) && renderMediaPreview(existingMedia)}
      </Box>
    </Stack>
  );
};
