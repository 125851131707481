import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { isNil, isEmpty } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { ToggleVisibilityContainer } from 'common/components/container';
import { Box, Button, Stack } from 'common/components/material';
import Card, { CardContent } from 'common/components/material/Card';
import { FormContainer } from 'common/components/container/form-container.component';
import { getErrorsPerFormField } from 'common/hooks';
import { StepElementProps } from 'features/create-profile/types/step-element-props.type';

export type Step = {
  buttonLabel?: string;
  element?: React.ReactElement;
  getElement?: (props: StepElementProps) => React.ReactElement;
  fields?: string[];
  requiredTouchedFields?: string[];
  formContainerDisabled?: boolean;
};

type StepperProps = {
  currentStepNumber?: number;
  steps: Step[];
  submitHandler: () => void;
  showBackButton?: boolean;
  disableButton?: boolean;
  handleStepChange?: (currentStepNumber: number) => void;
  validate?: (fields: string[]) => Promise<boolean>;
};

const Stepper: React.FC<StepperProps> = ({
  currentStepNumber = 1,
  steps,
  submitHandler,
  showBackButton = true,
  disableButton = false,
  handleStepChange,
  validate,
}) => {
  const { palette } = useTheme();
  const [step, setStep] = useState<Step>();
  const {
    getValues,
    clearErrors,
    formState: { dirtyFields, errors },
  } = useFormContext();

  const totalSteps = steps.length;

  useEffect(() => {
    setStep(steps[currentStepNumber - 1]);
  }, [steps, currentStepNumber]);

  const handleNextClick = async () => {
    let canProceed = true;

    if (validate && step?.fields) {
      canProceed = await validate(step.fields);
    }

    if (!canProceed) {
      return;
    }

    const isSubmitStep = currentStepNumber === totalSteps;

    if (isSubmitStep) {
      submitHandler();
      return;
    }

    handleStepChange && handleStepChange(currentStepNumber + 1);
  };

  const goBack = () => {
    clearErrors();
    handleStepChange && handleStepChange(currentStepNumber - 1);
  };

  const hasUntouchedFields = step?.requiredTouchedFields?.some(
    (field) =>
      (isNil(dirtyFields[field]) || !dirtyFields[field]) && isEmpty(getValues(field)),
  );
  const fieldErrors = getErrorsPerFormField(errors);
  const hasErrors = !!Object.keys(fieldErrors).length;

  return (
    <FormContainer disabled={step?.formContainerDisabled}>
      <Card sx={{ marginTop: { xs: 0, lg: 2 } }} className="full-width">
        <CardContent>
          <Stack spacing={1}>
            <Box display="flex" marginBottom="1.5rem">
              {Array.from({ length: currentStepNumber }).map((_, index) => (
                <Box
                  sx={{ backgroundColor: palette.Stroke.Active }}
                  width="1.75rem"
                  height="0.25rem"
                  borderRadius="0.125rem"
                  marginRight="0.125rem"
                  key={index}
                ></Box>
              ))}
              {Array.from({ length: totalSteps - currentStepNumber }).map((_, index) => (
                <Box
                  sx={{ backgroundColor: palette.Stroke.Default }}
                  width="1.75rem"
                  height="0.25rem"
                  borderRadius="0.125rem"
                  marginRight="0.125rem"
                  key={index}
                ></Box>
              ))}
            </Box>
            {!isNil(step?.getElement) ? (
              step?.getElement({
                back: goBack,
                next: handleNextClick,
                step,
                currentStepNumber,
                disableButton,
                showBackButton,
              })
            ) : (
              <>
                <Box marginBottom={{ xs: '5rem', lg: 0 }}>{step?.element}</Box>
                <Box
                  position={{ xs: 'fixed', lg: 'static' }}
                  bottom="0"
                  left="0"
                  display="flex"
                  justifyContent="space-between"
                  maxWidth="lg"
                  padding={{ xs: '1.5rem', lg: 0 }}
                  width="100%"
                  sx={{ backgroundColor: { xs: palette.Container.Bg, lg: 'none' } }}
                  zIndex={10}
                >
                  <ToggleVisibilityContainer
                    isVisible={showBackButton && currentStepNumber !== 1}
                  >
                    <Button onClick={goBack}>Back</Button>
                  </ToggleVisibilityContainer>
                  <Button
                    btntype="primary"
                    type="submit"
                    disabled={disableButton || hasUntouchedFields || hasErrors}
                    onClick={handleNextClick}
                  >
                    {step?.buttonLabel || 'Next'}
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </FormContainer>
  );
};

export default Stepper;
