import { styled } from '@mui/material';
import { Card } from 'common/components/material';

export const MediaTileCard = styled(Card, {
  shouldForwardProp: (prop) => {
    return prop !== 'isActive' && prop !== 'disabled';
  },
})<{
  isActive?: boolean;
  disabled?: boolean;
}>(({ isActive, disabled, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  height: '4.688rem',
  border: 'none',
  outline: `1px dashed ${
    isActive ? theme.palette.Tabs.Primary.StrokeActive : theme.palette.Extras.Extra1
  }`,
  '.MuiAvatar-img:not(.add-icon)': {
    opacity: 0.4,
  },
  '&:hover': !disabled
    ? {
        cursor: 'pointer',
        backgroundColor: theme.palette.Tabs.Primary.BgActive,
      }
    : { cursor: 'pointer' },
  ...(isActive && { backgroundColor: theme.palette.Tabs.Primary.BgActive }),
}));
