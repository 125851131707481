import React from 'react';

import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar';
import { MUIRef } from 'common/types/MUIRef.type';

export type AvatarProps = MUIAvatarProps;

const Avatar: React.FC<AvatarProps> = React.forwardRef((props, ref: MUIRef) => {
  const { children, ...avatarProps } = props;

  return (
    <MUIAvatar ref={ref} {...avatarProps}>
      {children}
    </MUIAvatar>
  );
});

export default Avatar;
