import { OrganizationRoleDrawerTabEnum } from '../organization-role.enums';
import { OrganizationRoleFormSchema } from './organization-role.schemas';

export const DRAWER_TABS = [
  {
    value: OrganizationRoleDrawerTabEnum.CREATE,
    label: 'Add New Role',
  },
  {
    value: OrganizationRoleDrawerTabEnum.UPDATE,
    label: 'Edit Role',
  },
];

export const DRAWER_TITLES = {
  create: 'Add New Role',
  update: 'Edit Role',
};

export const DRAWER_TAB_CONFIG = {
  [OrganizationRoleDrawerTabEnum.CREATE]: {
    actionLabel: 'Create',
    formSchema: OrganizationRoleFormSchema,
  },
  [OrganizationRoleDrawerTabEnum.UPDATE]: {
    actionLabel: 'Update',
    formSchema: OrganizationRoleFormSchema,
  },
};
