import IconButton from 'common/components/material/IconButton';
import { styled } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.Buttons.Primary.LabelDefault,
  backgroundColor: theme.palette.Buttons.Primary.BgDefault,
  '&:hover': {
    backgroundColor: theme.palette.Buttons.Primary.BgHover,
  },
}));

export const OutlinedIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.Buttons.Tertiary.LabelDefault,
  backgroundColor: theme.palette.Buttons.Tertiary.BgDefault,
  '&:hover': {
    backgroundColor: theme.palette.Buttons.Tertiary.BgHover,
  },
}));
