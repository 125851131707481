import { Theme, styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AppBoxShadows from 'common/theme/AppBoxShadows';

const scrollButtonBaseStyle = {
  width: '2.125rem',
  height: '2.125rem',
  padding: '0.375rem',
  borderRadius: '0.5rem',
  boxShadow: AppBoxShadows.shadow4,
  cursor: 'pointer',
};

const getThemedStyle = (theme: Theme) => {
  return {
    border: `1px solid ${theme.palette.Tabs.Primary.StrokeDefault}`,
    backgroundColor: theme.palette.Tabs.Primary.BgDefault,
    color: theme.palette.Tabs.Primary.LabelAndIconHover,
  };
};

export const ScrollLeftButton = styled(KeyboardBackspaceIcon)<{
  onClick: (e: React.MouseEvent) => void;
}>(({ theme }) => ({
  ...scrollButtonBaseStyle,
  ...getThemedStyle(theme),
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translate(-75%, -50%)',
}));

export const ScrollRightButton = styled(KeyboardBackspaceIcon)<{
  onClick: (e: React.MouseEvent) => void;
}>(({ theme }) => ({
  ...scrollButtonBaseStyle,
  ...getThemedStyle(theme),
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(75%, -50%) scaleX(-1)',
}));
