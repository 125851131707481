import { useRouter } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import ModalContainer from 'common/components/modals/ModalContainer';
import { KeyContact } from 'features/key-contacts/types/key-contact';
import { LinkTileWithAvatar } from 'common/components/link-tile-with-avatar/link-tile-with-avatar.component';

const KeyContactsModal: React.FC<{
  keyContacts: KeyContact[];
  open: boolean;
  onClose: () => void;
}> = (props) => {
  const { keyContacts = [], onClose, ...otherProps } = props;
  const { getOrgRoute } = useRouter();

  return (
    <ModalContainer title="Key Contacts" onClose={onClose} {...otherProps}>
      <>
        {keyContacts.map((keyContact) => {
          const route = getOrgRoute(AppRoutesEnum.PROFILE, {
            profileId: keyContact.profileId,
          });
          return (
            <LinkTileWithAvatar
              key={keyContact.id}
              route={route}
              title={keyContact.fullName}
              photo={keyContact.photo}
              showBorder={false}
              onClick={onClose}
            />
          );
        })}
      </>
    </ModalContainer>
  );
};
export default KeyContactsModal;
