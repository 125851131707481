export enum OrganizationEventLocationTypeEnum {
  OFFICE = 'OFFICE',
  VENUE = 'VENUE',
  ONLINE = 'ONLINE',
}

export enum OrganizationEventRoutesEnum {
  MONTH = ':eventFilterType/month/:month/:year',
  QUARTER = ':eventFilterType/quarter/:month/:year',
}

export enum OrganizationEventTabEnum {
  MONTH = 'month',
  QUARTER = 'quarter',
}
