import * as yup from 'yup';
import { SkillSchema } from 'features/skills';
import moment from 'moment';
import { SKILLS_MAX_LIMIT } from 'common/constants/common.constants';

const getMonthIndexFromString = (month: string) => {
  return new Date(Date.parse(month + ' 1, 1900')).getMonth();
};

const validateStartEndDate = (context: any) => {
  // Skip validation if both fields are empty _and_ the user is editing a role marked as current
  // Without this, it would be impossible to edit a role marked as current which starts in the future.
  // Why would we need that?  Not sure.  Admins might want to set up someone's profile ahead
  // of their join date.  Plus people might have already in their systems roles with start
  // date in the future, so this will allow them to get unstuck while editing.
  if (!context.parent.endYear && !context.parent.endMonth && context.parent.isCurrentRole) {
    return true;
  }

  const startDate = moment(
    new Date(context.parent.startYear, getMonthIndexFromString(context.parent.startMonth)),
  );
  var endDate = null;
  if (context.parent.endYear && context.parent.endMonth) {
    endDate = moment(
      new Date(context.parent.endYear, getMonthIndexFromString(context.parent.endMonth)),
    );
  } else {
    endDate = new Date();
  }

  return startDate.isBefore(endDate);
};

const StaticDataSchema = yup.object({
  id: yup.string().label('id'),
  name: yup.string().label('name'),
  fullName: yup.string().label('fullName'),
});

export const CurrentOrganizationRoleSchema = yup.object({
  jobTitle: yup.string().label('Job Title').required(),
  jobDescription: yup.string().label('Job Description').optional().nullable(),
  jobFunction: yup
    .object({ id: yup.string(), name: yup.string() })
    .label('Job Function')
    .required()
    .nullable(),
  department: StaticDataSchema.label('Department')
    .required('Department is required')
    .nullable(),
  division: StaticDataSchema.label('Division').required('Division is required').nullable(),
  products: yup.array().of(StaticDataSchema).label('Product').optional().nullable(),
  location: yup.object().required('Location is required').nullable(),
  skills: yup
    .array()
    .of(SkillSchema)
    .label('skills')
    .max(SKILLS_MAX_LIMIT, `Max ${SKILLS_MAX_LIMIT} skills allowed`)
    .optional(),
  startMonth: yup.string().label('Role Start Month').required(),
  startYear: yup
    .number()
    .typeError('')
    .label('Role Start Year')
    .min(1900, 'Min year is 1900')
    .required()
    .nullable(),
});

export const LatestOrganizationRoleSchema = yup.object({
  latestjobTitle: yup
    .string()
    .label('Job Title')
    .when('hasNewRole', {
      is: (hasNewRole: boolean) => hasNewRole,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  latestjobDescription: yup.string().label('Job Description').optional().nullable(),
  latestdepartment: StaticDataSchema.label('Department').when('hasNewRole', {
    is: (hasNewRole: boolean) => hasNewRole,
    then: (schema) => schema.required().nullable(),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  latestdivision: StaticDataSchema.label('Division').when('hasNewRole', {
    is: (hasNewRole: boolean) => hasNewRole,
    then: (schema) => schema.required().nullable(),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  latestproducts: yup.array().of(StaticDataSchema).label('Product').optional().nullable(),
  latestlocation: yup.object().when('hasNewRole', {
    is: (hasNewRole: boolean) => hasNewRole,
    then: (schema) => schema.required('Location is required').nullable(),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  latestskills: yup
    .array()
    .label('skills')
    .when('hasNewRole', {
      is: (hasNewRole: boolean) => hasNewRole,
      then: (schema) =>
        schema
          .of(SkillSchema)
          .max(SKILLS_MAX_LIMIT, `Max ${SKILLS_MAX_LIMIT} skills allowed`)
          .optional(),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  lateststartMonth: yup
    .string()
    .label('Role Start Month')
    .when('hasNewRole', {
      is: (hasNewRole: boolean) => hasNewRole,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  lateststartYear: yup
    .number()
    .label('Role Start Year')
    .when('hasNewRole', {
      is: (hasNewRole: boolean) => hasNewRole,
      then: (schema) =>
        schema.typeError('').min(1900, 'Min year is 1900').required().nullable(),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
});

export const OrganizationRoleFormSchema = CurrentOrganizationRoleSchema.concat(
  yup.object().shape({
    isCurrentRole: yup.bool().required(),
    endMonth: yup
      .string()
      .label('End Month')
      .when(['isCurrentRole'], {
        is: false,
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      })
      .test('endDate', 'End date must be after start date', (value, context) =>
        validateStartEndDate(context),
      ),
    endYear: yup
      .number()
      .label('End Year')
      .min(yup.ref('startYear'))
      .when(['isCurrentRole'], {
        is: false,
        then: yup.number().typeError('').required().nullable(),
        otherwise: yup.number().typeError('').nullable(),
      })
      .test('endDate', 'End date must be after start date', (value, context) =>
        validateStartEndDate(context),
      ),
  }),
);
