import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';

import useIntersectionObserver from 'features/org-root/hooks/useIntersectionObserver';
import { CircularProgress, Stack, Typography } from 'common/components/material';
import { LargeCircularProgress } from 'common/components/loaders';
import { InfiniteScrollContainerProps } from '../types';

const FlexContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const InfiniteScrollContainer: React.FC<InfiniteScrollContainerProps> = ({
  InfiniteListContainer,
  infiniteApiHook,
  LoaderComponent = LargeCircularProgress,
  NextPageLoader = CircularProgress,
  infinityListContainerProps = {},
  loaderComponentProps = {},
  footerText = 'You’re all caught up!',
  showFooter = true,
  apiParams,
  apiOptions,
}) => {
  const {
    data,
    total,
    isSuccess,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = infiniteApiHook(apiParams, apiOptions);
  const { setNode, intersectionObserverEntry } = useIntersectionObserver();
  const { palette } = useTheme();

  useEffect(() => {
    if (intersectionObserverEntry?.isIntersecting) {
      fetchNextPage();
    }
  }, [intersectionObserverEntry, fetchNextPage]);

  if (isLoading) {
    return <LoaderComponent {...loaderComponentProps} />;
  }

  return (
    <>
      {isSuccess && (
        <InfiniteListContainer
          data={data}
          isLoading={isLoading}
          total={total}
          {...infinityListContainerProps}
        />
      )}
      <Stack>
        {hasNextPage && (
          <div ref={setNode} style={{ minHeight: showFooter ? '1rem' : 0 }}></div>
        )}
        <FlexContainer>
          {isFetchingNextPage && <NextPageLoader />}
          {!isLoading && !hasNextPage && data?.length > 0 && showFooter && (
            <Typography
              variant="body4"
              color={palette.Text.SubHeadline}
              sx={{ paddingBottom: 2 }}
            >
              {footerText}
            </Typography>
          )}
        </FlexContainer>
      </Stack>
    </>
  );
};
export default InfiniteScrollContainer;
