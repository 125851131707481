import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
import { get, isEmpty, omit } from 'lodash';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import { FormOperationsEnum } from 'common/enum/Form.enum';
import { useDrawer, useForm, useSession, useView } from 'common/hooks';
import {
  WIN_TITLE,
  Project,
  ProjectDrawerProps,
  ProjectFormSchema,
  ProjectFormValue,
  useCreateProject,
  useUpdateProject,
} from 'features/projects';
import ProjectForm from 'features/projects/components/project-form.component';
import { StaticDataRoutePath } from 'features/static-data';
import { DrawerIdEnum } from '../../enums/drawer-id.enum';
import { useGetCurrentWinPeriod } from 'features/projects/hooks/useGetCurrentWinPeriod';
import { useCheckOpenProjectLimit } from 'features/projects/hooks/useCheckOpenProjectLimit';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import VisibleToOrganizationText from '../VisibleToOrganizationText';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';

const ProjectDrawer: React.FC<ProjectDrawerProps> = ({
  project,
  selectedTags = [],
  titleAutoFocus,
}) => {
  const { isMobileView } = useView();
  const { closeDrawer } = useDrawer();
  const { currentOrganization } = useSession();
  const location = useLocation();
  const routeInStaticDataPage = matchPath(
    { path: StaticDataRoutePath, end: false },
    location.pathname,
  );
  const { palette } = useTheme();

  const { canRead: hasAccessToTagging } = useFeatureAccess(FeaturesEnum.TAGGINGS);

  const company = currentOrganization();
  const projectId = get(project, 'id', '');
  const operation = projectId ? FormOperationsEnum.UPDATE : FormOperationsEnum.CREATE;

  const { submit: createProject, isLoading: isSubmittingProject } = useCreateProject();
  const { submit: updateProject, isLoading: isUpdatingProject } = useUpdateProject({
    meta: {
      currentOwnerId: get(project, 'owner.id'),
      projectId,
      currentTags: get(project, 'tags', []),
    },
  });

  const isLoading = isSubmittingProject || isUpdatingProject;
  const isExistingProject = !!projectId;

  /* Last minute changes for Show & Tell */
  const defaultValues = {
    title: '',
    description: '',
    url: '',
    skills: [],
    tags: selectedTags,
  };
  const projectDefaultValues = Object.assign(
    { owner: [], canCreateOpenWinForPeriod: false },
    defaultValues,
    omit(project, 'owner'),
    { owner: (project as Project).owner ? [(project as Project).owner] : [] },
  );

  /**
   * From a data model perspective, a project / WIN is valid so long as it
   * has either a owner or it's been tagged with one or more tags.
   * However, when users are on a static data page we want the 'tags' field
   * to be required irrespective of whether the 'owner' is filled in or not.
   * However (2), what if the user is creating a project / WIN using the global
   * "Create" button while on the static data page?  Well, in that case
   * `tags` should not be a required field.
   * Shit is complicated.
   */
  const isCreatingFromStaticDataPage = selectedTags.length > 0;
  const isEditingFromStaticDataPage = routeInStaticDataPage && project?.id;
  const isTagsRequired =
    hasAccessToTagging && (isCreatingFromStaticDataPage || isEditingFromStaticDataPage);
  const form = useForm<ProjectFormValue>({
    defaultValues: { ...projectDefaultValues, visibleTo: project?.visibleTo || [company] },
    mode: 'onChange',
    resolver: yupResolver(ProjectFormSchema(!!isTagsRequired)),
  });

  const {
    formState: { isDirty, isValid },
    getValues,
    reset,
  } = form;

  if (isValid && !isEmpty(form.formState.errors)) {
    /**
     * We have a lot of conditional validation logic which might
     * result in `formContext.errors` containing entries for
     * stale errors. Explicitly calling `clearErrors` will
     * help get rid of that clutter.
     *
     * Example:
     *
     * - Edit Project (one with an owner)
     * - Change the period and select an entry for which the owner already
     *   has 3 or more active wins (i.e., make sure the validation kick in)
     * - Confirm the field is indeed marked as error
     * - Now reset the period back to its original value
     * - Confirm `formState.isValid` is true, even though `formState.errors`
     *   still has an entry for `period`
     *
     * Note: the same won't be happening when _creating_ a new project.
     * That's because in that case `period` will always start as null; so any
     * selected value will mark the field as _dirty_; and since we forcibly
     * revalidate `period` and `owner` if dirty, that will cause a re-render
     * which will make the error disappear.
     */
    form.clearErrors();
  }

  useGetCurrentWinPeriod({
    enabled: !project?.period,
    onSuccess: ({ data }) => {
      if (data) {
        // Set the current period as the default selection for new WINs
        form.setValue('period', data);
      }
    },
  });

  const owner = form.watch('owner');
  const ownerId = get(owner, '[0].id');
  const period = form.watch('period');
  const periodId = period?.id;

  const { isLoading: isLoadingOpenLimitCheck } = useCheckOpenProjectLimit(
    { profileId: ownerId, periodId },
    {
      onSuccess: (canCreateOpenWin) => {
        /**
         * Ignore `canCreateOpenWin` when editing an existing project
         * and the currently selected period is the same as the persisted one.
         * TODO: pass the current project ID to the server, so the server
         * can exclude the current WIN, i.e., the one we are editing, from the
         * list of active WINs -- it would returns `false` otherwise
         */
        if (project?.id && project?.period?.id === periodId) {
          canCreateOpenWin = true;
        }

        /**
         * Update model's `canCreateOpenWinForPeriod` and retrigger validation:
         * - If `owner` is dirty, revalidate
         * - If `period` is dirty, revalidate
         * - If neither one of `owner` or `period` is dirty, marking either
         *   for revalidation would do just fine; `owner` comes first, so let's
         *   mark that.
         */
        form.setValue('canCreateOpenWinForPeriod', canCreateOpenWin);
        if (form.formState.dirtyFields.owner) {
          form.setValue('owner', owner, { shouldValidate: true });
        }
        if (form.formState.dirtyFields.period) {
          form.setValue('period', period, { shouldValidate: true });
        }
        if (!form.formState.dirtyFields.owner && !form.formState.dirtyFields.period) {
          form.setValue('owner', owner, { shouldValidate: true });
        }
      },
    },
  );

  const shouldDisableActions = !isDirty || isLoading || isLoadingOpenLimitCheck;

  const onDiscard = () => {
    closeDrawer(DrawerIdEnum.PROJECT);
    reset();
  };

  const onSubmit = () => {
    const data = getValues();
    if (isExistingProject) {
      const projectToUpdate = {
        ...data,
      };
      updateProject(projectToUpdate);
    } else {
      createProject(data);
    }
    onDiscard();
  };

  const header = (
    <DrawerHeader
      sx={{
        display: 'block',
        borderBottom: `1px solid ${palette.Divider}`,
        padding: 2,
      }}
    >
      <Typography
        variant={isMobileView ? 'body1' : 'h2'}
        fontWeight={700}
        color={palette.Text.Headline}
      >
        {WIN_TITLE}
      </Typography>
      <VisibleToOrganizationText visibleTo={getValues().visibleTo} />
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isLoading}
      isPrimaryActionDisabled={!isValid || shouldDisableActions}
      secondaryAction={onDiscard}
      primaryAction={onSubmit}
      primaryActionLabel={operation === FormOperationsEnum.UPDATE ? 'Save' : 'Create'}
    />
  );
  return (
    <FormDrawer header={header} footer={footer}>
      <ProjectForm
        form={form}
        project={projectDefaultValues}
        forceTitleAutoFocus={titleAutoFocus}
      ></ProjectForm>
    </FormDrawer>
  );
};

export default ProjectDrawer;
