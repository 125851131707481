import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { ListItemText, ListItemIcon } from 'common/components/material/ListItemButton';
import { Stack } from 'common/components/material';
import { PrinciplesDataExplorerItemProps } from '../types';
import { ExplorerListItem } from 'features/data-explorer';

const PrinciplesDataExplorerListItem: React.FC<PrinciplesDataExplorerItemProps> = ({
  label,
  showArrow,
  showBorder,
  children,
  startAdornment,
  endAdornment,
  sx,
  onClick,
  ...listItemButtonProps
}) => {
  return (
    <ExplorerListItem disableRipple onClick={onClick} {...listItemButtonProps} sx={{ ...sx }}>
      <Stack
        className="start-adornment"
        direction="row"
        alignItems="center"
        pr={startAdornment ? 0.5 : 0}
      >
        {startAdornment}
      </Stack>
      <Stack
        className="text-container"
        direction="row"
        alignItems="center"
        flex="1 1 auto"
        spacing={1}
      >
        <ListItemText
          primary={label}
          primaryTypographyProps={{ variant: 'body3', fontWeight: 600 }}
          sx={{ flexGrow: 0 }}
        />
        {children}
      </Stack>
      <Stack className="end-adornment" direction="row" alignItems="center" spacing={1}>
        {showArrow && (
          <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
            <KeyboardArrowRightIcon sx={{ borderRadius: '50%' }} />
          </ListItemIcon>
        )}
        {endAdornment}
      </Stack>
    </ExplorerListItem>
  );
};

export default PrinciplesDataExplorerListItem;
