import React from 'react';
import { Theme, createTheme } from '@mui/material/styles';
import AppBoxShadows from './AppBoxShadows';
import { DarkColors } from './DarkColors';
import { LightColors } from './LightColors';
import { PaletteMode } from '@mui/material';
import {
  BadgeOptions,
  ButtonOptions,
  ChipOptions,
  ContainerOptions,
  CardOptions,
  CoversBackgroundOptions,
  DropdownListOptions,
  EmptyPlaceholderIconOptions,
  EventTagChipOptions,
  IconOptions,
  LinkContainer,
  LinkTextOptions,
  MarkdownEditorOptions,
  ExtraOptions,
  OrgChartOptions,
  SelectionChipOptions,
  SpecialButtonOptions,
  StatusDropdownOptions,
  StrokeOptions,
  TabOptions,
  TextFieldOptions,
  TextOptions,
  ToolTipOptions,
} from './theme';
import { ThemeEnum } from './theme-type.enum';
import colorPalette from './colorPalette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  // allow configuration using 'createTheme'
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    button1?: React.CSSProperties;
    button2?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    backgroundColor: string;
    textFieldFocusColor: string;
    Background: string;
    Container: ContainerOptions;
    Card: CardOptions;
    Divider: string;
    LinkContainer: LinkContainer;
    Buttons: ButtonOptions;
    Text: TextOptions;
    LinkText: LinkTextOptions;
    SkillChip: ChipOptions;
    StaticDataChip: ChipOptions;
    Icons: IconOptions;
    Badge: BadgeOptions;
    Stroke: StrokeOptions;
    Tabs: TabOptions;
    Success: string;
    Failure: string;
    CoversBackground: CoversBackgroundOptions;
    CommentBg: string;
    ToolTip: ToolTipOptions;
    OrgChart: OrgChartOptions;
    StatusDropdown: StatusDropdownOptions;
    SelectionChips: SelectionChipOptions;
    MarkdownEditor: MarkdownEditorOptions;
    TextFields: TextFieldOptions;
    SpecialButtons: SpecialButtonOptions;
    EventTagChip: EventTagChipOptions;
    EmptyPlaceholderIcons: EmptyPlaceholderIconOptions;
    DropdownList: DropdownListOptions;
    Extras: ExtraOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    button1: true;
    button2: true;
    caption2: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    xsmall: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xsmall: true;
  }
}

const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

export const getDesignTokens = (mode?: PaletteMode) => {
  let themedColors;

  switch (mode) {
    case ThemeEnum.LIGHT:
      themedColors = LightColors;
      break;
    case ThemeEnum.DARK:
      themedColors = DarkColors;
      break;
    default:
      themedColors = LightColors;
  }

  const theme = {
    palette: {
      ...themedColors,
      mode,
      primary: {
        main: colorPalette.PRIMARY400,
        light: colorPalette.PRIMARY200,
        dark: colorPalette.PRIMARY800,
      },
      secondary: {
        main: colorPalette.SECONDARY400,
        light: colorPalette.SECONDARY50,
        dark: colorPalette.SECONDARY700,
      },
    },
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
      /**
       * Designs define typopgraphy in pixels. Converted to rem using the default 1rem = 16px.
       * Font weights and mobile view sizings here are based on the most commonly used
       * in the designs for their variant.
       */
      h1: {
        fontSize: '2.125rem', // 34px
        fontWeight: 700,
        [breakpoints.down('md')]: {
          fontSize: '1.625rem',
        },
      },
      h2: {
        fontSize: '1.875rem', // 30px
        fontWeight: 500,
        [breakpoints.down('md')]: {
          fontSize: '1.625rem',
          fontWeight: 500,
        },
      },
      h3: {
        fontSize: '1.625rem', // 26px
        fontWeight: 600,
        [breakpoints.down('md')]: {
          fontSize: '1.375rem',
        },
      },
      h4: {
        fontSize: '1.375rem', // 22px
        fontWeight: 500,
      },
      h6: {
        fontSize: '1rem', // 16px
        fontWeight: 700,
      },
      // Default font size. Copied from body2
      body: {
        fontSize: '1rem', // 16px
        fontWeight: 400,
      },
      body1: {
        fontSize: '1.125rem', // 18px
        fontWeight: 400,
        [breakpoints.down('md')]: {
          fontSize: '1rem',
        },
      },
      body2: {
        fontSize: '1rem', // 16px
        fontWeight: 400,
        [breakpoints.down('md')]: {
          fontSize: '0.875rem',
        },
      },
      body3: {
        fontSize: '0.875rem', // 14px
        fontWeight: 400,
      },
      body4: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
      },
      caption: {
        fontSize: '0.75rem', // 12px
        fontWeight: 400,
      },
      caption2: {
        fontSize: '0.625rem', // 10px
        fontWeight: 400,
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 600,
        letterSpacing: '0em',
      },
      button1: {
        fontSize: '1.125rem', // 18px
        fontWeight: 700,
      },
      button2: {
        fontSize: '1rem', // 16px
        fontWeight: 500,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 600,
            boxShadow: 'none',
            '&:focus': {
              boxShadow: 'none',
            },
            minWidth: 0,
          },
        },
        variants: [
          {
            props: { size: 'large' as const },
            style: {
              borderRadius: '8px',
              padding: '0.625rem 1rem',
            },
          },
          {
            props: { size: 'medium' as const },
            style: {
              borderRadius: '7px',
              padding: '0.375rem 0.75rem',
            },
          },
          {
            props: { size: 'small' as const },
            style: {
              borderRadius: '6px',
              padding: '0.25rem 0.625rem',
              fontSize: '0.875rem',
              lineHeight: '1.3125rem',
            },
          },
          {
            props: { size: 'xsmall' as const },
            style: {
              borderRadius: '5px',
              padding: '0 0.25rem',
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: '1.3125rem',
            },
          },
          {
            props: { btntype: 'primary' as const },
            style: ({ theme }: { theme: Theme }) => ({
              backgroundColor: theme.palette.Buttons.Primary.BgDefault,
              color: theme.palette.Buttons.Primary.LabelDefault,
              border: '1px solid transparent',
              '&:hover': {
                backgroundColor: theme.palette.Buttons.Primary.BgHover,
                boxShadow: AppBoxShadows.shadow5,
              },
              '&:active': {
                backgroundColor: theme.palette.Buttons.Primary.BgPressed,
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.Buttons.Primary.BgDisabled,
                color: theme.palette.Buttons.Primary.LabelDisabled,
                boxShadow: 'none',
              },
              '&.MuiButton-textError': {
                backgroundColor: theme.palette.Failure,
              },
            }),
          },
          {
            props: { btntype: 'secondary' as const },
            style: ({ theme }: { theme: Theme }) => ({
              backgroundColor: theme.palette.Buttons.Secondary.BgDefault,
              border: '1px solid transparent',
              borderRadius: '12px',
              color: theme.palette.Buttons.Secondary.SecondaryLabel,
              '&:hover': {
                backgroundColor: theme.palette.Buttons.Secondary.BgHover,
              },
              '&:active': {
                backgroundColor: theme.palette.Buttons.Secondary.BgPressed,
                borderColor: theme.palette.Buttons.Secondary.BgPressedStroke,
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.Buttons.Secondary.BgDisabled,
                color: theme.palette.Buttons.Secondary.LabelDisabled,
                boxShadow: 'none',
              },
            }),
          },
          {
            props: { btntype: 'tertiary' as const },
            style: ({ theme }: { theme: Theme }) => ({
              backgroundColor: theme.palette.Buttons.Tertiary.BgDefault,
              border: '1px solid transparent',
              borderRadius: '12px',
              color: theme.palette.Buttons.Tertiary.LabelDefault,
              '&:hover': {
                color: theme.palette.Buttons.Tertiary.LabelHover,
                backgroundColor: theme.palette.Buttons.Tertiary.BgHover,
              },
              '&:active': {
                color: theme.palette.Buttons.Tertiary.LabelPressed,
                backgroundColor: theme.palette.Buttons.Tertiary.BgPressed,
                borderColor: theme.palette.Buttons.Tertiary.LabelDefault,
              },
              '&.Mui-disabled': {
                color: theme.palette.Buttons.Tertiary.LabelDisabled,
              },
              '&.MuiButton-textError': {
                color: theme.palette.Failure,
                '&:hover, &:active': {
                  backgroundColor: theme.palette.Buttons.Tertiary.BgPressed,
                  border: '1px solid transparent',
                },
              },
            }),
          },
          {
            props: { btntype: 'label' as const },
            style: ({ theme }: { theme: Theme }) => ({
              backgroundColor: 'transparent',
              border: '1px solid transparent',
              fontWeight: 500,
              color: theme.palette.Buttons.Label.LabelDefault,
              '&:hover': {
                color: theme.palette.Buttons.Label.LabelHover,
                backgroundColor: theme.palette.Buttons.Label.BgHover,
              },
              '&:active': {
                color: theme.palette.Buttons.Label.LabelPressed,
                backgroundColor: theme.palette.Buttons.Label.BgPressed,
              },
              '&.Mui-disabled': {
                color: theme.palette.Buttons.Tertiary.LabelDisabled,
              },
              '&.MuiButton-textError': {
                color: theme.palette.Failure,
                '&:hover, &:active': {
                  backgroundColor: 'transparent',
                  border: '1px solid transparent',
                },
              },
            }),
          },
        ],
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            // Remove light blue autofill background on Chrome
            WebkitBoxShadow: '0 0 0 30px white inset',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
          elevation1: {
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
          },
          elevation24: {
            boxShadow: `0px 24px 23px rgba(0, 0, 0, 0.01),
            0px 9px 27px rgba(0, 0, 0, 0.05),
            0px 11px 15px rgba(0, 0, 0, 0.04)`,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            [breakpoints.up('md')]: {
              top: 98,
            },
          },
        },
      },
      MuiIconButton: {
        variants: [
          {
            props: { size: 'xsmall' as const },
            style: {
              width: 20,
              height: 20,
              fontSize: 12,
            },
          },
        ],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            '&:hover:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.TextFields.Hover,
            },
            '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.TextFields.Hover,
            },
            '&:hover .MuiInputAdornment-positionStart:first-of-type .MuiSvgIcon-root': {
              color: theme.palette.Icons.Tertiary.IconFillHover,
            },
            '&.Mui-focused .MuiInputAdornment-positionStart:first-of-type .MuiSvgIcon-root': {
              color: theme.palette.Icons.Tertiary.IconFillPressed,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.Failure,
            },
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          asterisk: ({ theme }: { theme: Theme }) => ({
            color: theme.palette.Failure,
          }),
          root: ({ theme }: { theme: Theme }) => ({
            '&.Mui-error:not(.MuiInputLabel-shrink)': {
              color: theme.palette.TextFields.HintText,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.Failure,
            },
          }),
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            marginLeft: '0.25rem',
            fontSize: '0.625rem',
            '&.Mui-error': {
              color: theme.palette.Failure,
            },
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingBottom: 16,
            },
            [breakpoints.down('md')]: {
              padding: 13,
              '&:last-child': {
                paddingBottom: 13,
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.MuiTab-root': {
              textTransform: 'capitalize',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            '&.Mui-disabled': {
              opacity: 1,
            },
            '&:hover.MuiChip-outlined .MuiChip-label': {
              color: theme.palette.Text.SubtleText,
            },
          }),
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            '& .MuiTypography-caption': {
              fontWeight: 'bolder',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: ({ theme }: { theme: Theme }) => ({
            '& .highlight': {
              '&:hover, &.Mui-focused': {
                backgroundColor: theme.palette.DropdownList.Hover,
              },
            },
          }),
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
        xxl: 1536,
      },
    },
  };
  return theme;
};

export default createTheme(getDesignTokens());
