import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Box, Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import CloseButton from 'common/components/navigation/CloseButton';
import { useView } from 'common/hooks';

const MenuDrawerHeader: React.FC<{
  label: string;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
}> = ({ label, onClick }) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();

  return (
    <DrawerHeader
      sx={{
        borderBottom: `1px solid ${palette.Divider}`,
        display: 'block',
        position: 'sticky',
        top: 0,
        zIndex: 3,
        width: '100%',
        backgroundColor: palette.Container.Bg,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: { xs: 1.5, md: 2.5 },
        }}
      >
        <Typography variant="h3" fontWeight={700} ml={2} color={palette.Text.Headline}>
          {label}
        </Typography>
        <CloseButton size={isMobileView ? 'small' : 'medium'} clickHandler={onClick} />
      </Box>
    </DrawerHeader>
  );
};

export default MenuDrawerHeader;
