import { Avatar, Container, Stack } from 'common/components/material';
import { ConfirmationIconType } from 'common/enum/ConfirmationIconType.enum';
import React from 'react';

type BottomConfirmationDrawerProps = {
  title: React.ReactElement;
  cancelButton: React.ReactElement;
  confirmButton: React.ReactElement;
  iconType?: ConfirmationIconType;
};

const ICONS = {
  [ConfirmationIconType.WARN]: '/images/icons/warning.svg',
  [ConfirmationIconType.SAVE]: '/images/icons/save.svg',
};

const BottomConfirmationDrawer: React.FC<BottomConfirmationDrawerProps> = ({
  title,
  cancelButton,
  confirmButton,
  iconType,
}) => {
  return (
    <>
      <Container data-role="bottom-confirmation-drawer">
        <Stack direction="column" spacing={4} pb={8} alignItems="center">
          {iconType && <Avatar src={ICONS[iconType]} sx={{ mb: -2 }}></Avatar>}
          {title}
          <Stack direction="row" spacing={2}>
            {cancelButton}
            {confirmButton}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default BottomConfirmationDrawer;
