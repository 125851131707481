import React from 'react';
import Stack from '../material/Stack';
import { CXAvatar } from '../cx-avatar/cx-avatar';
import { LinkTileWithAvatarContentProps } from './link-tile-with-avatar.type';
import { useTheme } from '@mui/material/styles';
import { EllipsisText } from '../ellipsis-text/ellipsis-text.component';

const LinkTileWithAvatarContent: React.FC<LinkTileWithAvatarContentProps> = ({
  title,
  subtitle,
  photo,
  icon,
  defaultAvatar,
  variant = 'body3',
  subtitleVariant,
  iconSize = "medium",
  showTooltip = false,
}) => {
  const { palette } = useTheme();
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <CXAvatar
        src={photo}
        alt={title || subtitle}
        size={iconSize}
        icon={icon ?? defaultAvatar}
        sx={{ color: icon && palette.Icons.Secondary.IconFill }}
      ></CXAvatar>
      {/** Setting height here to prevent collapsing when parentFullName is null */}
      <Stack minHeight="2.5rem" justifyContent="center">
        {title && (
          <EllipsisText
            variant={variant}
            className="link-title"
            fontWeight={500}
            lineclamp={1}
            showTooltip={showTooltip}
          >
            {title}
          </EllipsisText>
        )}
        {subtitle && (
          <EllipsisText
            variant={subtitleVariant || variant}
            color={palette.Text.Caption}
            fontWeight={500}
            lineclamp={1}
            showTooltip={showTooltip}
          >
            {subtitle}
          </EllipsisText>
        )}
      </Stack>
    </Stack>
  );
};

export default LinkTileWithAvatarContent;
