import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { styled, useTheme } from '@mui/material/styles';

import { Button, Stack, Typography } from 'common/components/material';
import { useView } from 'common/hooks';
import { SkillActionButtonProps } from 'features/skills';

const StyledButton = styled(Button)(({ variant, theme }) => ({
  backgroundColor:
    variant === 'outlined'
      ? theme.palette.Buttons.Tertiary.BgDefault
      : theme.palette.Buttons.Secondary.BgDefault,
  border: `1.4px solid ${
    variant === 'outlined' ? theme.palette.Stroke.Default : 'transparent'
  }`,
  borderRadius: '12px',
  '&:hover': {
    border: `1.4px solid ${theme.palette.Stroke.Active}`,
    backgroundColor:
      variant === 'outlined'
        ? theme.palette.Buttons.Tertiary.BgDefault
        : theme.palette.Buttons.Secondary.BgDefault,
  },
}));

const SkillActionButton: React.FC<SkillActionButtonProps> = ({
  label,
  subLabel,
  action,
  variant = 'text',
}) => {
  const { isMobileView } = useView();
  const { palette } = useTheme();

  return (
    <StyledButton variant={variant} fullWidth={true} onClick={action}>
      <Stack direction="row" width={'100%'} spacing={0.5}>
        <Stack textAlign="left" flex={1}>
          <Typography
            color={palette.Buttons.Secondary.SecondaryLabel}
            variant={isMobileView ? 'button2' : 'button1'}
            fontWeight={700}
          >
            {label}
          </Typography>
          <Typography
            color={
              variant === 'outlined' || isMobileView
                ? palette.Text.SubHeadline
                : palette.Text.Headline
            }
            variant={isMobileView ? 'body4' : 'body3'}
          >
            {subLabel}
          </Typography>
        </Stack>
        <Stack justifyContent="center">
          <ArrowRightAltIcon />
        </Stack>
      </Stack>
    </StyledButton>
  );
};

export default SkillActionButton;
