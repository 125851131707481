import { useFormContext, get } from 'react-hook-form';

const useErrorsFromForm = (formErrors: any) => {
  const methods = useFormContext();
  const errorFields = Object.keys(formErrors);

  return errorFields.reduce((acc: any, errorField) => {
    const errorObject = get(formErrors || methods.formState.errors, errorField);
    acc[errorField] = errorObject.message;

    return acc;
  }, {});
};

export default useErrorsFromForm;
