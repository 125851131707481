import React from 'react';

import IndeterminateCheckBoxTwoToneIcon from '@mui/icons-material/IndeterminateCheckBoxTwoTone';
import { useSession, useView } from 'common/hooks';
import PrinciplesDataExplorerListItem from './principles-data-explorer-list-item';
import { PrinciplesFilterListItemProps } from '../types';
import { StyledCheckbox } from '../styles/principles-filter-list-item.style';

export const PrinciplesFilterListItem: React.FC<PrinciplesFilterListItemProps> = ({
  data,
  active,
  onClick,
  selected,
  showArrow,
  onToggleCheckbox,
  isAssessmentCompleted,
  isAssessmentPrivate,
}) => {
  const { isDesktopView } = useView();
  const { profile: loggedInUser } = useSession();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    // Clicking on the text toggles the checkbox instead of expanding the children
    if (
      e.target instanceof HTMLElement &&
      e.target.className.includes('MuiTypography-root')
    ) {
      onToggleCheckbox(!selected);
    } else {
      showArrow && onClick && onClick(data);
    }
  };

  const canBeSelected =
    isAssessmentCompleted && (!isAssessmentPrivate || data.id === loggedInUser.id);
  return (
    <PrinciplesDataExplorerListItem
      label={data?.fullName}
      selected={isDesktopView && active}
      showArrow={showArrow}
      onClick={handleClick}
      showBorder={!isDesktopView}
      startAdornment={
        <>
          {canBeSelected && (
            <StyledCheckbox
              className="filter-checkbox"
              checked={!!selected}
              indeterminate={false}
              indeterminateIcon={<IndeterminateCheckBoxTwoToneIcon />}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onToggleCheckbox(!selected);
              }}
            />
          )}
        </>
      }
      endAdornment={
        !canBeSelected ? 'Not completed and/or private' : undefined
      }
      sx={{ cursor: showArrow ? 'pointer' : 'default' }}
      disabled={!canBeSelected}
    />
  );
};
