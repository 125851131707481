import React from 'react';

import MUIToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';

const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { children, ...toggleButtonProps } = props;

  return <MUIToggleButton {...toggleButtonProps}>{children}</MUIToggleButton>;
};

export default ToggleButton;
