import React from 'react';
import { styled } from '@mui/material/styles';

import Typography, { TypographyProps } from 'common/components/material/Typography';

const StyledLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.body4,
  textTransform: 'uppercase',
  color: theme.palette.Text.Headline,
  marginBottom: '0.5rem',
  [theme.breakpoints.down('md')]: {
    ...theme.typography.caption,
  },
}));

const SectionLabel: React.FC<TypographyProps> = ({ children, ...props }) => {
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

export default SectionLabel;
