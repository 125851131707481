import { styled } from '@mui/material/styles';
import { Stack, Typography } from 'common/components/material';

export const MobileHeaderContainer = styled(Stack)({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
});

export const MobileHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.Text.Headline,
  paddingLeft: '1rem',
  lineHeight: 1,
}));
