import queryClient from 'common/utils/QueryClient';
import { useEventEmitter, useMutation, useSession } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { SkillEndorsementEventData } from 'common/types/Event-Data.type';
import { deleteEndorsedSkillApi } from '../profiles.service';
import { SkillEndorsementsResponse } from 'features/skills';

export const useDeleteEndorsedSkill = (
  profileId: string,
  skillId: string,
  options: UseMutationOptions = {},
) => {
  const { account: user } = useSession();
  const { emit } = useEventEmitter<SkillEndorsementEventData>('SkillUnEndorsement');

  return useMutation(() => deleteEndorsedSkillApi(profileId, skillId), {
    showSuccessSnackbar: true,
    onSuccess: async () => {
      const skillEndorsementCacheKey = ['profile', 'skill-endorsements', profileId, skillId];

      const endorsementDetail = queryClient.getQueryData(
        skillEndorsementCacheKey,
      ) as SkillEndorsementsResponse;

      if (endorsementDetail) {
        await queryClient.invalidateQueries(skillEndorsementCacheKey, {
          refetchInactive: true,
        });
      }

      const updatedEndorsementDetail = queryClient.getQueryData(
        skillEndorsementCacheKey,
      ) as SkillEndorsementsResponse;

      emit({
        currentOrganization: user?.organizationId,
        profileId,
        skillId,
        data: updatedEndorsementDetail?.data?.endorsedSkills[0],
      });
    },
    ...options,
  });
};
