import { useQuery, useSession } from 'common/hooks';

import { useSearchParams } from 'react-router-dom';
import { mapSearchParamsObject } from '../search.utils';
import { getSearchRelatedPageApi } from '../search.service';
import { UseQueryOptions } from 'common/types/Api.type';
import { RelatedPage } from 'features/related-pages/types/related-page.type';

export const useGetSearchRelatedPages = (options: UseQueryOptions = {}) => {
  const { account: user } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') || '';
  const filters = mapSearchParamsObject(searchParams);

  const queryKey = ['search-related-pages', user?.organizationId, query, filters] as string[];

  const response = useQuery(
    queryKey,
    () =>
      getSearchRelatedPageApi({
        term: query,
        ...filters,
      }),
    {
      ...options,
    },
  );

  return {
    ...response,
    data: response.data?.data?.relatedPages as RelatedPage[],
  };
};
