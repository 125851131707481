import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'react-query';
import { markNotificationsToReadApi } from '../notifications.service';

export const useMarkAllNotificationsAsRead = (opts?: UseMutationOptions) => {
  const mutationResponse = useMutation(markNotificationsToReadApi, {
    showErrorSnackbar: false,
    ...opts,
  });
  return mutationResponse;
};
