import { get, isArray, pick, omit } from 'lodash';

import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { Skill } from 'features/skills';
import { updateProjectApi } from '../services/projects.service';
import { ProjectFormValue } from '../types';
import { useGetProject } from './useGetProject';
import { VisibleTo } from 'common/types/visible-to.type';

export const useUpdateProject = (options: UseMutationOptions = {}) => {
  const projectId = get(options, 'meta.projectId');
  const { refetch: refetchDetailedProject } = useGetProject(projectId, { enabled: false });
  const { emitWithSettledAfter } = useEventEmitter('ProjectUpdated');

  // TODO: PUT API update
  // - CreatedDate is changed in every update
  const mutationResponse = useMutation(updateProjectApi, {
    showSuccessSnackbar: true,
    transformInput: (input: ProjectFormValue) => {
      const projectFormValue = pick(input, [
        'id',
        'title',
        'description',
        'url',
        'owner',
        'period',
        'status',
        'skills',
        'tags',
        'visibleTo',
      ]);
      const ownerId = isArray(projectFormValue.owner)
        ? get(projectFormValue, 'owner[0].id')
        : get(projectFormValue, 'owner.id');
      const skills = get(projectFormValue, 'skills', []) as Skill[];
      return {
        ...omit(projectFormValue, 'owner', 'period'),
        ownerId,
        periodId: get(projectFormValue, 'period.id'),
        skillIds: skills.map((skill: Skill) => skill.id),
        visibleTo: get(projectFormValue, 'visibleTo')?.map((v: VisibleTo) => v.id),
      };
    },
    onSuccess: ({ data }) => {
      // This is done to prevent displaying empty feed for WIN list if any WIN is edited and owner is changed, as it could be possible that this WIN was user's last WIN, and it resulted in deleting the WinList feed only.
      emitWithSettledAfter(3000, data);
      refetchDetailedProject();
    },
    ...options,
  });
  return mutationResponse;
};

export default useUpdateProject;
