import { useEvents, useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { checkOpenProjectLimitApi } from '../services/projects.service';
import { OpenProjectLimitParams } from '../types/project-server-request.type';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { EVENTS_TO_REFETCH_PROJECTS } from '../project.utils';
import { Project } from '../types';

export const useCheckOpenProjectLimit = (
  { profileId, periodId }: OpenProjectLimitParams,
  options: UseQueryOptions = {},
) => {
  const { account: user } = useSession();

  const queryResponse = useQuery(
    ['check-open-project-limit', user?.organizationId, profileId, periodId],
    () => checkOpenProjectLimitApi({ profileId, periodId }),
    {
      select: ({ data }) => data.canCreateOpenProject,
      enabled: !!profileId && !!periodId,
      ...options,
    },
  );

  useEvents(EVENTS_TO_REFETCH_PROJECTS as Event[], (data: Project) => {
    if (data.owner?.id === profileId) {
      return queryResponse.refetch();
    }
  });

  return queryResponse;
};
