import { styled } from '@mui/material/styles';

import Container from 'common/components/material/Container';
import colorPalette from 'common/theme/colorPalette';

type VerticalScrollableContainerProps = {
  maxHeight?: string;
  minHeight?: string;
};

const VerticalScrollableContainer = styled(Container, {
  shouldForwardProp: (prop) => {
    return prop !== 'maxHeight' && prop !== 'minHeight';
  },
})<VerticalScrollableContainerProps>(({ maxHeight, minHeight }) => {
  return {
    maxHeight,
    minHeight,
    overflowY: 'auto',

    '::-webkit-scrollbar': {
      width: '0.4rem',
    },

    /* Track */
    '::-webkit-scrollbar-track': {
      boxShadow: 'unset',
      borderRadius: '0.4rem',
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: colorPalette.GRAY400,
      borderRadius: '0.4rem',
    },

    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: colorPalette.GRAY500,
    },
  };
});

export default VerticalScrollableContainer;
