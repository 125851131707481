import { MONTH } from './month-selector.constants';
import { FormSelect } from '../input';
import { SxProps, Theme } from '@mui/material';
import { FormTextFieldProps } from 'common/types/Form.type';

const MonthSelector: React.FC<
  FormTextFieldProps & {
    name: string;
    label: string;
    sx?: SxProps<Theme>;
    autoFocus?: boolean;
    onChange?: () => void;
  }
> = ({ name = '', label, sx, autoFocus = false, onChange, ...props }) => {
  return (
    <FormSelect
      name={name}
      label={label}
      items={MONTH}
      sx={sx}
      autoFocus={autoFocus}
      onChange={onChange}
      {...props}
    />
  );
};

export default MonthSelector;
