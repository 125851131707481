import { FeedTypeEnum } from 'features/feeds/enums/feed-type.enum';
import { PraiseFeed } from 'features/praise';
import { OpportunityFeed } from 'features/opportunities';
import { CompanyUpdateFeed } from 'features/company-updates';
import { PostsFeed } from '../posts/types/posts-feed.type';
import { Feed, NewJoinersFeed } from './types';
import { OrganizationEventFeed } from 'features/organization-events/types/organization-event-feed.type';
import { WinListFeed } from 'features/win-lists/interface/win-list-feed.type';
import { settled } from 'common/types/Event-type.type';

export const transformFeed = (
  feed: Feed,
):
  | PraiseFeed
  | OpportunityFeed
  | CompanyUpdateFeed
  | NewJoinersFeed
  | OrganizationEventFeed
  | PostsFeed
  | WinListFeed
  | undefined => {
  if (!feed) {
    return feed;
  }
  switch (feed.feedType) {
    case FeedTypeEnum.PRAISE:
      return getTransformedPraiseFeed(feed);
    case FeedTypeEnum.OPPORTUNITY:
      return getTransformedOpportunityFeed(feed);
    case FeedTypeEnum.COMPANY_UPDATE:
      return getTransformedCompanyUpdateFeed(feed);
    case FeedTypeEnum.NEW_JOINER:
      return getTransformedNewJoinerFeed(feed);
    case FeedTypeEnum.POST:
      return getTransformedFeed(feed);
    case FeedTypeEnum.ORGANIZATION_EVENT:
      return getTransformedEventFeed(feed);
    case FeedTypeEnum.WIN_LIST:
      return getTransformedWinListFeed(feed);
  }
};

export const EVENTS_TO_REFETCH_FEEDS = [
  settled('CompanyUpdateAdded'),
  settled('CompanyUpdateUpdated'),
  settled('CompanyUpdateDeleted'),
  settled('OrganizationEventsModified'),
  settled('OrganizationEventDeleted'),
  settled('PostsModified'),
  settled('PostDeleted'),
  'PraiseAdded',
  settled('PraiseUpdated'),
  settled('PraiseDeleted'),
  settled('OpportunityAdded'),
  settled('OpportunityUpdated'),
  settled('OpportunityDeleted'),
  settled('ProjectAdded'),
  settled('ProjectUpdated'),
  settled('ProjectDeleted'),
];

export const EVENTS_TO_REFETCH_FEED_DATA = [
  settled('CompanyUpdateUpdated'),
  settled('OrganizationEventsModified'),
  settled('PostsModified'),
  'PraiseUpdated',
  'BobModified',
  settled('OpportunityUpdated'),
  'ProjectAdded',
  settled('ProjectUpdated'),
  settled('ProjectDeleted'),
];

const getTransformedOpportunityFeed = (feed: Feed) => {
  const entityValue = feed?.linkedEntities?.[0];
  const assigner = entityValue?.assigner;
  const assignee = entityValue?.assignee;
  const manager = entityValue?.manager;

  return { ...feed, ...entityValue, assigner, assignee, feedId: feed.id, manager };
};

const getTransformedPraiseFeed = (feed: Feed) => {
  const entityValue = feed?.linkedEntities?.[0];
  const giver = entityValue?.giver;
  const receiver = entityValue?.receiver;

  return { ...feed, ...entityValue, giver, receiver, feedId: feed.id };
};

const getTransformedEventFeed = (feed: Feed) => {
  const entityValue = feed?.linkedEntities?.[0];
  const organizer = entityValue?.organizer;
  const creator = entityValue?.creator;

  return { ...feed, ...entityValue, organizer, creator, feedId: feed.id };
};

const getTransformedWinListFeed = (feed: Feed) => {
  const entityValue = feed?.linkedEntities?.[0];

  return { ...feed, ...entityValue, feedId: feed.id };
};

const getTransformedCompanyUpdateFeed = (feed: Feed) => {
  const entityValue = feed?.linkedEntities?.[0];
  return { ...feed, ...entityValue, feedId: feed.id };
};

const getTransformedFeed = (feed: Feed) => {
  const entityValue = feed?.linkedEntities?.[0];

  return { ...feed, ...entityValue, feedId: feed.id };
};

const getTransformedNewJoinerFeed = (feed: Feed) => {
  const entityValue = feed?.linkedEntities?.[0];

  if (!feed.createdDate) {
    feed.createdDate = entityValue?.welcomeDate;
  }

  return { ...feed, ...entityValue, feedId: feed.id };
};
