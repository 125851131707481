import React, { useState } from 'react';

import FilterListItem from './FilterListItem';
import { useView } from 'common/hooks';
import FilterExplorerHeader from './FilterExplorerHeader';

import { FilterExplorerProps } from '../types';
import HierarchicalExplorer from 'features/data-explorer/components/hierarchical-explorer.component';
import { HierarchicalDataMapping } from 'features/data-explorer/types/explorer-data.type';
import { HierarchicalExplorerItemAction } from 'features/data-explorer/types/hierarchical-explorer-item-action.type';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import PaginatedExplorer from 'features/data-explorer/components/paginated-explorer.component';
import { ActiveFilterMap } from 'features/search';
import { FILTER_EXPLORER_CONFIG } from 'features/data-explorer/filter-explorer.config';

const FilterExplorer: React.FC<FilterExplorerProps> = ({
  filterType,
  activeFilterMap,
  onToggleSelection,
  onToggleMetaFilter,
  onClickBack,
  contentType,
}) => {
  const { isDesktopView } = useView();
  const [searchTerm, setSearchTerm] = useState('');
  const [showFlatList, setShowFlatList] = useState(false);
  const [searchCollapsed, setSearchCollapsed] = useState(!isDesktopView);
  const config = FILTER_EXPLORER_CONFIG[filterType];
  const { CustomHeaderActionComponent, levels, isPaginated } = config;
  const isSingleList = levels.length === 1;
  const showExploreViewToggle = isDesktopView && !isSingleList;

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setShowFlatList(true);
  };

  const handleItemAction = ({
    data,
    value,
    mapping,
    actionType,
    type,
  }: HierarchicalExplorerItemAction) => {
    switch (actionType) {
      case 'toggleFilter':
        onToggleSelection(
          data,
          value,
          mapping as HierarchicalDataMapping,
          type as ExplorerDataTypeEnum,
        );
        break;
      case 'toggleMetaFilter':
        onToggleMetaFilter(data);
        break;
    }
  };

  const customHeaderActionComponent = CustomHeaderActionComponent ? (
    <CustomHeaderActionComponent onAction={handleItemAction} />
  ) : null;

  const filterHeaderActionComponent = (
    <FilterExplorerHeader
      searchTerm={searchTerm}
      showExploreViewToggle={showExploreViewToggle}
      showFlatList={showFlatList}
      searchCollapsed={searchCollapsed}
      onChangeTerm={handleSearch}
      onToggleView={setShowFlatList}
      onToggleSearchCollapse={setSearchCollapsed}
    ></FilterExplorerHeader>
  );

  if (isPaginated) {
    return (
      <PaginatedExplorer
        showTitle={isDesktopView || searchCollapsed}
        dataType={filterType}
        searchTerm={searchTerm}
        headerActions={filterHeaderActionComponent}
        secondaryHeaderActions={customHeaderActionComponent}
        activeFilterMap={activeFilterMap as ActiveFilterMap}
        onItemAction={handleItemAction}
        onClickBack={onClickBack}
        contentType={contentType}
      />
    );
  }

  return (
    <HierarchicalExplorer
      showTitle={isDesktopView || searchCollapsed}
      dataType={filterType}
      activeFilterMap={activeFilterMap}
      isReadView={true}
      searchTerm={searchTerm}
      showFlatList={showFlatList}
      ListItemComponent={FilterListItem}
      headerActions={filterHeaderActionComponent}
      onItemAction={handleItemAction}
      onClickBack={onClickBack}
      contentType={contentType}
    />
  );
};

export default FilterExplorer;
