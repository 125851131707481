import moment from 'moment';
import * as yup from 'yup';

export const ReviewFormSchema = yup.object({
  reviewees: yup
    .array()
    .label('reviewee')
    .min(1, 'Reviewee is required')
    .required('Reviewee is required'),
  reviewer: yup
    .array()
    .label('reviewer')
    .min(1, 'Reviewer is required')
    .required('Reviewer is required'),
  reviewStartDate: yup
    .string()
    .label('Review start date')
    .required('Review start date is required')
    .test(
      'Valid Date',
      'Date format should be yyyy-mm-dd',
      (val) => !!val && moment(val, 'YYYY-MM-DD', true).isValid(),
    ),
  reviewEndDate: yup
    .string()
    .label('Review end date')
    .required('Review end date is required')
    .test(
      'Valid Date',
      'Date format should be yyyy-mm-dd',
      (val) => !!val && moment(val, 'YYYY-MM-DD', true).isValid(),
    ),
});
