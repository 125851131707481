import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Typography } from 'common/components/material';
import List, { ListProps, ListSubheader } from 'common/components/material/List';

const MenuItemsHeader: React.FC<ListProps & { label: string }> = ({
  label,
  children,
  ...props
}) => {
  const { palette } = useTheme();

  return (
    <List
      subheader={
        <ListSubheader sx={{ pl: 2.5, lineHeight: '2.25rem', bgcolor: palette.Container.Bg }}>
          <Typography variant="caption" color={palette.Text.SubHeadline} fontWeight={500}>
            {label}
          </Typography>
        </ListSubheader>
      }
      {...props}
    >
      {children}
    </List>
  );
};

export default MenuItemsHeader;
