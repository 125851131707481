import { useInfiniteQuery, useSession } from 'common/hooks';
import { getNextPageParam } from 'features/org-root/utils/infiniteScrollApi.utils';
import { getFeedsByProfileIdApi } from '../profiles.service';
import { transformFeed } from 'features/feeds/feeds.utils';

export const useGetFeedsByProfileId = (profileId: string) => {
  const { account: user } = useSession();

  const queryResponse = useInfiniteQuery(
    [user?.organizationId, 'profile', profileId, 'feeds'],
    (params) => getFeedsByProfileIdApi(profileId, params),
    {
      getNextPageParam,
      entityKey: 'feeds',
      transformInput: transformFeed,
    },
  );

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  return {
    ...queryResponse,
    data,
  };
};
