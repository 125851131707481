import { useState } from 'react';
import { kebabCase } from 'lodash';

import SearchInput from 'common/components/input/SearchInput';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { HierarchicalExplorerItemAction } from 'features/data-explorer';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { ActiveFilterMap } from 'features/search';
import { ExploreSelectorHierarchicalComponent } from './explore-selector-hierarchical.component';
import { FILTER_EXPLORER_CONFIG } from 'features/data-explorer/filter-explorer.config';
import { ExploreSelectorPaginated } from './explore-selector-paginated.component';
import { StyledExploreSelectorDropdown } from '../styles/styled-explore-selector-dropdown.styles';
import VerticalScrollableContainer from 'common/components/container/VerticalScrollableContainer';
import IncludeIndirectReportsCheckbox from 'features/explore/components/IncludeIndirectReportsCheckbox';
import { useSearchParams } from 'react-router-dom';

export const ExplorerSelectorDropdownComponent: React.FC<{
  contentType: ExploreTabEnum;
  type: ExplorerDataTypeEnum;
  activeFilterMap?: ActiveFilterMap;
  onItemAction: (action: HierarchicalExplorerItemAction) => void;
}> = ({ contentType, type, activeFilterMap, onItemAction }) => {
  const config = FILTER_EXPLORER_CONFIG[type];
  const { isPaginated } = config;
  const [searchTerm, setSearchTerm] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = !!searchParams.get('includeIndirectReports');
  const [checked, setIsChecked] = useState(initialState);

  const addToSearchParams = (key: any, value?: any) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, value);
    setSearchParams(params);
  };

  const removeFromSearchParams = (key: any) => {
    const params = new URLSearchParams(searchParams);
    params.delete(key);
    setSearchParams(params);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!checked);
    const key = 'includeIndirectReports';
    const value = true;
    if (!checked) {
      addToSearchParams(key, value);
    } else {
      removeFromSearchParams(key);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
  };

  const renderChild = () => {
    if (isPaginated) {
      return (
        <ExploreSelectorPaginated
          contentType={contentType}
          type={type}
          searchTerm={searchTerm}
          onItemAction={onItemAction}
          activeFilterMap={activeFilterMap}
        />
      );
    }

    return (
      <ExploreSelectorHierarchicalComponent
        contentType={contentType}
        type={type}
        searchTerm={searchTerm}
        onItemAction={onItemAction}
        activeFilterMap={activeFilterMap}
      />
    );
  };

  return (
    <StyledExploreSelectorDropdown spacing={1}>
      {type === ExplorerDataTypeEnum.REPORTS_TO && (
        <IncludeIndirectReportsCheckbox onAction={handleCheckboxChange} />
      )}
      <SearchInput
        sx={{
          '& .MuiInputBase-root': {
            maxHeight: '2.25rem',
          },
          '& input::placeholder': {
            fontSize: '0.75rem',
          },
        }}
        value={searchTerm}
        onChange={setSearchTerm}
        onClear={handleClear}
        data-testid={`explore-selector-${kebabCase(type)}-search-input`}
      />
      <VerticalScrollableContainer
        disableGutters
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        {renderChild()}
      </VerticalScrollableContainer>
    </StyledExploreSelectorDropdown>
  );
};
