import { styled, useTheme } from '@mui/material';
import { Stack, Typography } from 'common/components/material';
import { ComingSoonViewType } from '../types';
import ComingSoonIcon from './ComingSoonIcon';

const HEADING_TEXT = 'Coming Soon...';

const StyledContainer = styled(Stack)({
  alignItems: 'center',
  height: '50vh',
  justifyContent: 'center',
});

const ComingSoonView: React.FC<ComingSoonViewType> = ({ subText, Icon }) => {
  const { palette } = useTheme();

  return (
    <StyledContainer>
      <ComingSoonIcon Icon={Icon} />
      <Typography variant="h3" fontWeight={500} color={palette.Text.Headline}>
        {HEADING_TEXT}
      </Typography>
      <Typography
        maxWidth="25rem"
        variant="body1"
        fontWeight={400}
        color={palette.Text.SubHeadline}
        textAlign="center"
        mt={1.5}
      >
        {subText}
      </Typography>
    </StyledContainer>
  );
};

export default ComingSoonView;
