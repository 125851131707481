import { useContext } from 'react';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { FormControlLabel, styled, useTheme } from '@mui/material';

import Switch from 'common/components/material/Switch';
import { Box, Typography, Divider } from 'common/components/material';
import { titleCase } from 'common/utils/app.utils';
import { ApplicationContext } from 'common/context/application-context';
import MenuItemsHeader from 'common/components/menu-items/menu-items-header.component';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: '50px',
  padding: '10px 8px',
  '& .MuiSwitch-switchBase': {
    width: '24px',
    height: '24px',
    top: '50%',
    marginTop: 'calc(-24px / 2)',
    backgroundColor: theme.palette.Extras.Extra2,
    transform: 'translateX(3px) rotate(180deg)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: `${theme.palette.Extras.Extra2} `,
    },
    '&.Mui-checked': {
      transform: 'translateX(24px) rotate(360deg)',
      backgroundColor: theme.palette.Extras.Extra11,
      '&:hover': {
        backgroundColor: `${theme.palette.Extras.Extra11}`,
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '20px',
    backgroundColor: theme.palette.Extras.Extra2,
  },
}));

const ThemeSwitchListItem: React.FC = () => {
  const { palette, typography } = useTheme();
  const { themeContext } = useContext(ApplicationContext);
  const { theme, toggleTheme } = themeContext;
  const Label = (
    <Typography
      variant="body2"
      color={palette.Tabs.Secondary.LabelDefault}
      sx={{ paddingLeft: 1 }}
    >
      {titleCase(theme)}
    </Typography>
  );
  return (
    <>
      <MenuItemsHeader label="Theme">
        <Box
          sx={{
            paddingLeft: '26px',
            paddingRight: '26px',
          }}
        >
          <FormControlLabel
            onClick={(e) => e.stopPropagation()}
            control={
              <StyledSwitch
                checked={theme !== 'light'}
                onChange={toggleTheme}
                checkedIcon={
                  <DarkModeOutlinedIcon
                    sx={{
                      pr: '0.5px',
                      color: 'white',
                      fontSize: typography.button1.fontSize,
                    }}
                  />
                }
                icon={
                  <LightModeOutlinedIcon
                    sx={{
                      pr: '0.5px',
                      color: 'white',
                      fontSize: typography.button1.fontSize,
                    }}
                  />
                }
              />
            }
            sx={{
              '& .MuiTypography-root': {
                color: palette.LinkText.Default,
                fontWeight: 500,
                fontSize: typography.body3.fontSize,
              },
            }}
            label={Label}
          />
        </Box>
      </MenuItemsHeader>
      <Divider />
    </>
  );
};

export default ThemeSwitchListItem;
