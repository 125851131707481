import { styled } from '@mui/material';
import Stack from 'common/components/material/Stack';
import { getThemeImagePath } from 'common/utils/file.utils';

export const StyledEventBackground = styled(Stack, {
  shouldForwardProp: (prop) => {
    return prop !== 'backgroundImageName';
  },
})<{
  backgroundImageName: string;
}>(({ backgroundImageName, theme }) => ({
  borderRadius: '0.75rem',
  justifyContent: 'space-between',
  backgroundImage: `url(${getThemeImagePath(
    'Events',
    theme.palette.mode,
    `${backgroundImageName.replaceAll(' ', '')}.svg`,
  )})`,
  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${getThemeImagePath(
      'Events',
      theme.palette.mode,
      `${backgroundImageName}.svg`,
    )})`,
  },
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
}));
