import { styled } from '@mui/material/styles';
import { StyledSkillChipProps } from '../types';
import Chip from 'common/components/material/Chip';
import { getDefaultChipStyle, getSelectedChipStyle } from '../configs';
import { getBaseChipStyle } from 'common/components/cx-chip/cx-chip.styles';

export const StyledSkillChip = styled(Chip, {
  shouldForwardProp: (prop) => {
    return prop !== 'isSelected' && prop !== 'isEndorsedByLoggedInUser' && prop !== 'score';
  },
})<StyledSkillChipProps>(({ isSelected, theme }) => ({
  ...getBaseChipStyle(theme),
  backgroundColor: theme.palette.SkillChip.BgDefault,
  '& .skill-chip-icon': {
    fill: theme.palette.SkillChip.TextDefault,
  },
  '&:hover .MuiChip-label': {
    color: theme.palette.SkillChip.TextActive,
  },
  '&:hover .skill-chip-icon': {
    fill: theme.palette.SkillChip.TextActive,
  },
  '&.MuiChip-filled .MuiChip-label, &:hover.MuiChip-filled .MuiChip-label': {
    color: theme.palette.SelectionChips.Primary.LabelAndIconDefault,
  },
  '&.MuiChip-outlined .MuiChip-deleteIcon': {
    fill: theme.palette.SelectionChips.Secondary.LabelAndIconDefault,
  },
  '&:hover.MuiChip-outlined.MuiChip-deletable': {
    borderColor: theme.palette.SelectionChips.Secondary.StrokeHover,
  },
  '&:hover.MuiChip-outlined.MuiChip-deletable .MuiChip-label': {
    color: theme.palette.SelectionChips.Secondary.LabelAndIconHover,
  },
  '&:hover.MuiChip-outlined .MuiChip-deleteIcon': {
    fill: theme.palette.SelectionChips.Secondary.LabelAndIconHover,
  },
  '& .MuiTouchRipple-root .MuiTouchRipple-child': {
    backgroundColor: theme.palette.SelectionChips.Primary.BgDefault,
    opacity: 0.2,
  },
  ...(isSelected ? getSelectedChipStyle(theme) : getDefaultChipStyle(theme)),
}));
