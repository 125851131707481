import { createRef, useState } from 'react';
import { Viewer as ToastUIViewer } from '@toast-ui/react-editor';

import { useCXViewerTruncateMarkdown } from '../hooks';
import { CXViewerProps } from '../types';
import { CXViewerStyledContainer, CXViewerExpandBtn } from '../styles';
import { defaultViewerConfig } from '../toast-ui-editor.config';
import { useView } from 'common/hooks';

/**
 * This component wraps the ToastUiVIewer component that understands the markdown text and format to human readble text on UI.
 */

const MAX_DESKTOP_DESC_LENGTH = 150;
const MAX_MOBILE_DESC_LENGTH = 80;

export const CXViewer = ({ value, isInitiallyExpanded = false, ...props }: CXViewerProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isInitiallyExpanded);
  const isDesktopView = useView();

  //We don't necessarily need to send maxLength props, If we don't send maxLength then it will be set based on predefined values according to the view
  const maxLength = props.maxLength
    ? props.maxLength
    : isDesktopView
    ? MAX_DESKTOP_DESC_LENGTH
    : MAX_MOBILE_DESC_LENGTH;

  const ref = createRef<ToastUIViewer>();
  useCXViewerTruncateMarkdown(value, ref, isExpanded, maxLength);

  const onExpandBtnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const expandBtn =
    value?.length > maxLength ? (
      <CXViewerExpandBtn onClick={onExpandBtnClick}>
        See {isExpanded ? 'Less' : 'More'}
      </CXViewerExpandBtn>
    ) : (
      <></>
    );

  return (
    <>
      <CXViewerStyledContainer>
        <ToastUIViewer ref={ref} {...defaultViewerConfig} initialValue={value} />
      </CXViewerStyledContainer>
      {expandBtn}
    </>
  );
};
