import React from 'react';
import { useSearchParams } from 'react-router-dom';

import MenuItemButton from 'common/components/menu-items/MenuItemButton';
import { Box } from 'common/components/material';
import { SvgIconState } from 'common/components/image';
import { useRouter } from 'common/hooks';

import { EXPLORE_HOME_TABS } from 'features/explore/explore.constants';
import MenuItemsHeader from './menu-items-header.component';
import { EntitledTabs, useEntitledTabsGuard } from 'common/hooks/useEntitledTabsGuard';
import { ExploreOrganizationTabType } from 'features/org-root/config/explore-organization-tabs.config';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

const ExploreMenuItems: React.FC = () => {
  const { goTo } = useRouter();
  const [searchParams] = useSearchParams();
  const guardEntitledTabs = useEntitledTabsGuard();
  const entitledTabs = guardEntitledTabs(
    EXPLORE_HOME_TABS as EntitledTabs[],
  ) as ExploreOrganizationTabType[];

  const redirectToExploreTab = (value: string) => {
    let pathname = AppRoutesEnum.EXPLORE as string;
    if (value) {
      pathname += `/${value}`;
    }

    searchParams.delete('eventType');
    searchParams.delete('selectedDate');

    goTo({
      pathname,
      search: searchParams,
    });
  };

  return (
    <MenuItemsHeader label="Explore" dense>
      {entitledTabs.map(
        ({
          value,
          IconComponent,
          defaultIcon = React.Fragment,
          activeIcon = React.Fragment,
          label,
        }) => {
          return (
            <MenuItemButton
              key={label}
              onClick={() => redirectToExploreTab(value)}
              label={label}
              listItemSx={{ lineHeight: '1rem' }}
            >
              <Box className="list-item-icon">
                {IconComponent ? (
                  <IconComponent />
                ) : (
                  <SvgIconState DisabledIcon={defaultIcon} ActiveIcon={activeIcon} />
                )}
              </Box>
            </MenuItemButton>
          );
        },
      )}
    </MenuItemsHeader>
  );
};

export default ExploreMenuItems;
