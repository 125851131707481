import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Button, Typography } from 'common/components/material';
import { SkillRatingReadViewProps, getRatingLabel } from 'features/skills';

const SkillRatingReadView: React.FC<SkillRatingReadViewProps> = ({ rating = 0 }) => {
  const { palette } = useTheme();
  const colorByRating = rating ? palette.Text.Caption : palette.Text.Disabled;
  return (
    <Button
      sx={{
        height: '1.5rem',
        borderRadius: '0.375rem',
        pointerEvents: 'none',
        borderColor: colorByRating,
      }}
      variant="outlined"
      disabled={!rating}
      size="small"
    >
      <Typography color={colorByRating} variant="body4" fontWeight={400}>
        {rating ? getRatingLabel(rating) : 'No Rating'}
      </Typography>
    </Button>
  );
};

export default SkillRatingReadView;
