import React from 'react';

import { FormAutocomplete } from 'common/components/input';
import { Stack } from 'common/components/material';

import { ReadViewProject } from 'features/projects/types/project.type';
import { getString } from 'common/utils/app.utils';
import { ProjectSelectorProps, useGetProfileProjects } from 'features/projects';

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  personId,
  project,
  onChange,
  ...projectSelectorProps
}) => {
  const { data, isLoading } = useGetProfileProjects(personId);
  const userProjects = data.map(({ id, title }: Partial<ReadViewProject>) => ({ id, title }));

  return (
    <Stack>
      <FormAutocomplete
        name="project"
        loading={isLoading}
        options={userProjects || []}
        getOptionLabel={(option) => option?.title || ''}
        isOptionEqualToValue={(option, value) =>
          getString(option, 'id') === getString(value, 'id')
        }
        onChange={(value) => {
          onChange(value);
        }}
        value={project || null}
        {...projectSelectorProps}
      />
    </Stack>
  );
};

export default ProjectSelector;
