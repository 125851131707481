import { styled } from '@mui/material/styles';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import { Typography } from '../material';
import Chip from '../material/Chip';

type StyledChipProps = {
  mainColor: string;
  backgroundColor: string;
};

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => {
    return prop !== 'mainColor' && prop !== 'backgroundColor';
  },
})<StyledChipProps>(({ mainColor, backgroundColor }) => ({
  color: mainColor,
  backgroundColor,
  borderColor: mainColor,
  height: '1.125rem',
  boxShadow: AppBoxShadows.shadow1,
}));

type CustomBadgeProps = {
  color: string;
  backgroundColor: string;
  text: string;
};

const CustomBadge: React.FC<CustomBadgeProps> = ({ color, backgroundColor, text }) => {
  return (
    <StyledChip
      size="small"
      variant="outlined"
      mainColor={color}
      backgroundColor={backgroundColor}
      label={<Typography variant="caption">{text}</Typography>}
    />
  );
};

export default CustomBadge;
