import React from 'react';
import { useTheme } from '@mui/material';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { Stack, Tooltip, Typography } from 'common/components/material';
import { useView } from 'common/hooks';
import { WinPeriod } from '../types';
import { BannerDurationText } from 'features/wins-expiration/components/banner-duration-text.component';

const EXPIRING_WINS_TOOLTIP_TEXT =
  'Copy will allow you to duplicate your current WINs to next quarter with existing data';

export const ExistingWinsContainerHeader: React.FC<{
  period: WinPeriod;
  daysToExpire: number;
  isExpired: boolean;
}> = ({ period, daysToExpire, isExpired }) => {
  const { palette } = useTheme();
  const { isDesktopView } = useView();

  return (
    <Stack direction="row" justifyContent="space-between">
      {period && (
        <Stack direction="row">
          <Typography variant="caption" fontWeight={500} color={palette.Text.Headline}>
            Review {period?.name} WINs (
            {isExpired ? 'expired ' : daysToExpire <= 1 ? 'expire ' : 'expire in '}
            <BannerDurationText days={daysToExpire} isExpired={isExpired} />)
          </Typography>
        </Stack>
      )}
      {isDesktopView && (
        <Tooltip title={EXPIRING_WINS_TOOLTIP_TEXT} sx={{ textAlign: 'center' }}>
          <Stack direction="row" alignItems="center" spacing={0.5} sx={{ cursor: 'help' }}>
            <Typography variant="caption" fontWeight={500} color={palette.Text.SubHeadline}>
              Why Copy
            </Typography>
            <HelpOutlineRoundedIcon
              sx={{ color: palette.Icons.Tertiary.IconFillDefault, width: 17 }}
            />
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};
