import Chip from 'common/components/material/Chip';
import { styled } from '@mui/material/styles';
import { getBaseChipStyle } from 'common/components/cx-chip/cx-chip.styles';

export const StyledPersonChip = styled(Chip)(({ theme }) => ({
  ...getBaseChipStyle(theme),
  '& .MuiChip-label': {
    ...theme.typography.body4,
    fontWeight: 500,
  },
  '&.MuiChip-filled .MuiChip-label, &:hover.MuiChip-filled .MuiChip-label': {
    color: theme.palette.SelectionChips.Primary.LabelAndIconDefault,
    textTransform: 'capitalize',
  },
}));
