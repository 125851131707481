import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Menu,
  MenuItem,
  MenuProps,
  MenuItemProps,
} from 'common/components/material/MenuList';

export type FloatingMenuItem<ValueType = string> = {
  value: ValueType;
  label?: string;
  color?: string;
  palettePath?: string;
};

const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  marginTop: 3,
  '.MuiPaper-root': {
    backgroundColor: theme.palette.LinkContainer.BgDefault,
    borderRadius: 6,
  },
  '.MuiList-root': {
    paddingTop: 4,
    paddingBottom: 4,
    minWidth: 127,
  },
}));

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => {
    return prop !== 'color';
  },
})<MenuItemProps>(({ color, theme }) => ({
  color: color || theme.palette.LinkText.Default,
  fontSize: theme.typography.body4.fontSize,
  fontWeight: 500,
  '&:hover': {
    backgroundColor: theme.palette.LinkContainer.BgHover,
    color: color || theme.palette.LinkText.Active,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body4.fontSize,
    minHeight: 'auto',
  },
}));

type FloatingMenuProps = {
  items?: FloatingMenuItem[];
  onSelect?: (item: FloatingMenuItem) => void;
  disabled?: boolean;
  defaultItemColor?: string;
  itemProps?: MenuItemProps;
} & Omit<MenuProps, 'open' | 'onSelect'>;

const FloatingMenu: React.FC<FloatingMenuProps> = ({
  disabled,
  items,
  onSelect,
  defaultItemColor,
  anchorEl,
  itemProps = {},
  children,
  ...menuProps
}) => {
  return (
    <StyledMenu disableScrollLock={true} open={!!anchorEl} anchorEl={anchorEl} {...menuProps}>
      {children}
      {items?.map((item: FloatingMenuItem, index: number) => (
        <StyledMenuItem
          key={index}
          value={typeof item === 'string' ? item : item.value}
          color={(item as FloatingMenuItem).color || defaultItemColor}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onSelect && onSelect(item);
          }}
          {...itemProps}
        >
          {typeof item === 'string' ? item : item.label || item.value}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export default FloatingMenu;
