import { UseQueryOptions } from 'react-query';

import { useQuery } from 'common/hooks';
import { getFollowerCountApi } from '../followings.service';

export const useGetFollowerCount = (followeeIds: string[], options?: UseQueryOptions) => {
  const queryResponse = useQuery(
    ['follower', 'count', ...followeeIds.sort()],
    () => getFollowerCountApi(followeeIds),
    {
      select: (data) => {
        if (data) {
          return data.data.followerCount;
        }
        return 0;
      },
      ...options,
    },
  );

  return queryResponse;
};
