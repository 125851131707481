import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';

import { getWorkLocationsApi } from '../services/work-location.service';
import { OrganizationalStaticDataTypeEnum } from '../static-data.enum';
import { transformWorkLocations } from '../utils/static-data.utils';

export const useGetWorkLocations = (options: UseQueryOptions = {}) => {
  const { account } = useSession();
  const organizationId = account.organizationId || '';

  const queryResponse = useQuery(
    [organizationId, 'static-data', OrganizationalStaticDataTypeEnum.WORK_LOCATION],
    () => getWorkLocationsApi(),
    {
      enabled: !!organizationId,
      showErrorSnackbar: true,
      select: (result) => {
        return {
          ...result,
          data: {
            isEnabled: result?.data.isEnabled,
            workLocations: transformWorkLocations(result?.data?.workLocations),
          },
        };
      },
      ...options,
    },
  );

  return {
    ...queryResponse,
    data: queryResponse?.data?.data,
  };
};
