import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { updateCompanyUpdateApi } from '../company-updates.service';
import { CompanyUpdateEventData } from '../types';

export const useUpdateCompanyUpdate = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } =
    useEventEmitter<CompanyUpdateEventData>('CompanyUpdateUpdated');

  return useMutation(updateCompanyUpdateApi, {
    showSuccessSnackbar: true,
    onSuccess: () => {
      emitWithSettledAfter(5000);
    },
    ...options,
  });
};
