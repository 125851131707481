import React from 'react';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import { toUpper } from 'lodash';

import { Stack, Tooltip } from 'common/components/material';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { useRouter, useView } from 'common/hooks';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';

import { UserAvatar } from 'features/profiles/components/user-avatar';
import { getEventLocationName, getEventTimezoneOffset } from '../utils';
import { OrganizationEvent } from '../types';
import OrganizationEventTypeChip from './organization-event-type-chip.component';
import { OrganizationEventLocationTypeEnum } from '../organization-events.enum';
import { OrganizationEventDesktopTags } from './organization-event-desktop-tags.component';
import { OrganizationEventMobileTags } from './organization-event-mobile-tags.component';
import { StaticDataAvatarComponent } from 'features/static-data/components/static-data-avatar.component';
import { OrganizationalTagPagesTypeEnum } from 'features/static-data/static-data.enum';
import { StyledListItem as StyledCard } from 'common/components/container/StyledListItem';
import { zoneAbbr } from 'common/utils/date-time.utils';

const OrganizationEventPanelCard: React.FC<{
  event: OrganizationEvent;
}> = ({ event }) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();
  const { goTo } = useRouter();

  const {
    title,
    startTime,
    organizer,
    feedId,
    onBehalfOf,
    tags,
    startDate,
    eventLocationType,
    originTimezone,
  } = event;

  const locationName = getEventLocationName(event);
  const timezoneString =
    eventLocationType === OrganizationEventLocationTypeEnum.ONLINE
      ? moment.tz.guess()
      : originTimezone;
  const timezone = zoneAbbr(timezoneString);
  const timezoneOffsetLabel = startTime && getEventTimezoneOffset(startDate, startTime, originTimezone, eventLocationType);
  const eventInfoLabel = startTime
    ? `${startTime} ${timezone} · ${locationName}`
    : locationName;

  const handleEventCardClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (feedId) {
      goTo(AppRoutesEnum.HOME_FEED, { feedId });
    }
  };

  const goToStaticDataPage = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (onBehalfOf.type === 'COMPANY') {
      goTo(AppRoutesEnum.COMPANY_OVERVIEW_SUMMARY);
    } else {
      goTo(
        AppRoutesEnum.STATIC_DATA_SUMMARY,
        {
          staticDataId: onBehalfOf.id,
          staticDataType: toUpper(onBehalfOf.type),
        },
        { state: {} },
      );
    }
  };

  const goToProfile = (e: React.MouseEvent) => {
    e.stopPropagation();

    goTo(AppRoutesEnum.PROFILE, { profileId: organizer.id });
  };

  return (
    <StyledCard
      spacing={1}
      paddingX={0.5}
      paddingY={0.75}
      sx={{ cursor: 'pointer' }}
      onClick={handleEventCardClick}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <OrganizationEventTypeChip event={event} />
        <Tooltip title={organizer.fullName}>
          <UserAvatar
            fullName={organizer.fullName}
            src={organizer.photo}
            size="xsmall"
            onClick={goToProfile}
          />
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="flex-start">
        <StaticDataAvatarComponent
          src={onBehalfOf?.icon}
          fullName={onBehalfOf?.completeName || onBehalfOf?.name}
          size="medium"
          isLocation={
            onBehalfOf?.type === OrganizationalTagPagesTypeEnum.CITY ||
            onBehalfOf?.type === OrganizationalTagPagesTypeEnum.COUNTRY
          }
          onClick={goToStaticDataPage}
        />
        <Stack direction="column" justifyContent="center">
          <EllipsisText
            variant="caption"
            fontWeight={500}
            lineclamp={2}
            color={palette.Text.Headline}
            lineHeight="1.125rem"
          >
            {title}
          </EllipsisText>
          <Stack direction="row" alignItems="center">
            <EllipsisText
              variant="body4"
              fontWeight={400}
              color={palette.Text.Caption}
              lineclamp={1}
              lineHeight="1.125rem"
            >
              {eventInfoLabel}
            </EllipsisText>
            {timezoneOffsetLabel && (
              <Tooltip title={timezoneOffsetLabel}>
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    color: palette.Text.Caption,
                    ml: '0.125rem',
                    width: '0.875rem',
                    height: '0.875rem',
                  }}
                />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Stack>
      {isMobileView ? (
        <OrganizationEventMobileTags tags={tags} />
      ) : (
        <OrganizationEventDesktopTags tags={tags} onMoreClick={handleEventCardClick} />
      )}
    </StyledCard>
  );
};

export default OrganizationEventPanelCard;
