import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from 'common/components/material';
import { InlineIconButton } from 'features/org-root/org-root.styles';
import { ManageProjectRequest } from 'features/projects';

export const DraftProjectHeaderActions: React.FC<{
  project: ManageProjectRequest;
  removeProject: (project: ManageProjectRequest) => void;
}> = ({ project, removeProject }) => {
  const handleRemoveProject = (e: React.MouseEvent) => {
    e.stopPropagation();
    removeProject(project);
  };

  return (
    <Tooltip title="Remove">
      <div>
        <InlineIconButton onClick={handleRemoveProject} sx={{ mr: 2 }}>
          <CloseIcon />
        </InlineIconButton>
      </div>
    </Tooltip>
  );
};
