import { styled } from '@mui/material/styles';

import { Stack } from 'common/components/material';
import Typography from 'common/components/material/Typography';
import IconButton from 'common/components/material/IconButton';
import Card from 'common/components/material/Card';
import theme from 'common/theme';

export const MobileChildContainer = styled(Stack)({
  paddingBottom: 80,
  flex: 1,
});

export const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body4.fontSize,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body4.fontSize,
    fontWeight: 400,
  },
}));

export const InlineIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => {
    return prop !== 'height' && prop !== 'width';
  },
})<{
  height?: string;
  width?: string;
}>(({ height, width, theme }) => ({
  border: `1px solid ${theme.palette.Stroke.Default}`,
  color: theme.palette.Icons.Secondary.IconFill,
  borderRadius: `6px`,
  height: height ? height : '24px',
  width: width ? width : '24px',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
  },
}));

export const SecondaryIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.Icons.Secondary.IconFill,
    backgroundColor: theme.palette.Icons.Secondary.BgHover,
  },
}));

// Fills the whole page on mobile view
export const PageContentCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [theme.breakpoints.down('md')]: {
    borderLeft: 'none',
    borderRight: 'none',
    paddingBottom: '10px',
    flex: 1,
  },
});

export const NavigationLink = styled(Typography)(({ theme }) => ({
  ...theme.typography.button2,
  color: theme.palette.Buttons.Tertiary.LabelDefault,
  cursor: 'pointer',
  textTransform: 'capitalize',
  fontSize: '0.875rem',
  '&:hover': {
    color: theme.palette.Buttons.Tertiary.LabelHover,
  },
}));
