import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

import { ListItemText, ListItemIcon } from 'common/components/material/ListItemButton';
import { Stack } from 'common/components/material';
import { BaseDataExplorerListItemProps } from '../types/base-data-explore-props.type';
import { MenuItemArrowDirectionEnum } from '../data-explorer.enum';
import { getMenuItemArrowRotation } from '../utils';
import { ExplorerListItem } from '../styles';

const BaseDataExplorerListItem: React.FC<BaseDataExplorerListItemProps> = ({
  label,
  showArrow,
  arrowDirection = MenuItemArrowDirectionEnum.RIGHT,
  showBorder,
  children,
  startAdornment,
  endAdornment,
  isUnassigned,
  isVisible,
  sx,
  onClick,
  size,
  ...listItemButtonProps
}) => {
  const arrowRotation = getMenuItemArrowRotation(arrowDirection);
  const { palette } = useTheme();

  /**
   * Following styles is to make font size and arrow size smaller in case we want to use it in dropdown
   */
  const labelStyle =
    size === 'small'
      ? { variant: 'body4', fontWeight: 500 }
      : { variant: 'body3', fontWeight: 600 };
  const arrowFontSize = size === 'small' ? { fontSize: '1.2rem' } : {};
  const sxWithSpacing = size === 'small' ? { ...sx, padding: '0.2rem 0' } : sx;

  return (
    <ExplorerListItem
      disableRipple
      showBorder={showBorder}
      onClick={onClick}
      className={isUnassigned ? 'unassigned' : ''}
      {...listItemButtonProps}
      sx={
        isVisible === false
          ? { ...sxWithSpacing, color: palette.Buttons.Secondary.LabelDisabled }
          : sxWithSpacing
      }
    >
      <Stack
        className="start-adornment"
        direction="row"
        alignItems="center"
        pr={startAdornment ? 0.5 : 0}
      >
        {startAdornment}
      </Stack>
      <Stack
        className="text-container"
        direction="row"
        alignItems="center"
        flex="1 1 auto"
        spacing={1}
      >
        <ListItemText
          primary={label}
          primaryTypographyProps={labelStyle as any}
          sx={{ flexGrow: 0 }}
        />
        {children}
      </Stack>
      <Stack className="end-adornment" direction="row" alignItems="center" spacing={1}>
        {showArrow && (
          <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
            <KeyboardArrowRightIcon
              sx={{
                borderRadius: '50%',
                transform: `rotate(${arrowRotation}deg)`,
                transition: 'transform 0.3s',
                ...arrowFontSize,
              }}
            />
          </ListItemIcon>
        )}
        {endAdornment}
      </Stack>
    </ExplorerListItem>
  );
};

export default BaseDataExplorerListItem;
