import React, { useState } from 'react';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { Button, Stack, Typography } from 'common/components/material';
import { BackButton } from 'common/components/navigation';
import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { useDrawer } from 'common/hooks';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { FilterSearchParams } from 'features/search';
import { useFilterState } from '../hooks';
import { FilterDrawerProps } from '../types';
import FilterChipList from './FilterChipList';
import FilterExplorer from './FilterExplorer';
import FilterMenu from './FilterMenu';
import FilterTextButton from './FilterTextButton';

export const INIT_PROPS: FilterDrawerProps = {
  items: [],
  applyButtonHandler: () => {},
  initialFilters: {},
  selectedTab: ExploreTabEnum.PEOPLE,
};

const FilterDrawer: React.FC<FilterDrawerProps> = ({
  items,
  applyButtonHandler,
  initialFilters,
  selectedTab,
  isFilterApplied,
}) => {
  const { closeDrawer } = useDrawer();
  const [filterType, setFilterType] = useState<ExplorerDataTypeEnum | null>(null);
  const [showFilterList, setShowFilterList] = useState(false);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<FilterSearchParams>(
    {},
  );

  const { activeFilters, activeFilterMap, filterList, toggleFilterSelection, clearFilters } =
    useFilterState(filterType, initialFilters);

  const goBackToMenu = () => {
    setFilterType(null);
  };

  const closeFilterDrawer = () => {
    closeDrawer(DrawerIdEnum.FILTER);
  };

  const handleApply = () => {
    applyButtonHandler(activeFilters, additionalSearchParams);
    closeFilterDrawer();
  };

  const header = (
    <Stack direction="row" justifyContent="space-between" px={1} pt={1}>
      {showFilterList ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <BackButton
            size="small"
            clickHandler={() => {
              setShowFilterList(false);
            }}
          />
          <Typography variant="h4" fontWeight={600}>
            Filters
          </Typography>
        </Stack>
      ) : (
        <FilterTextButton
          filterCount={filterList.length}
          onClick={() => {
            setShowFilterList(true);
          }}
        />
      )}
      {isFilterApplied && isFilterApplied() && (
        <Button
          btntype="label"
          size="small"
          onClick={clearFilters}
          data-testid="clear-all-filters"
        >
          Clear All
        </Button>
      )}
    </Stack>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={false}
      isPrimaryActionDisabled={false}
      secondaryAction={closeFilterDrawer}
      primaryAction={handleApply}
      primaryActionLabel="Apply"
    />
  );
  const renderFilterContent = () => {
    if (!filterType) {
      return (
        <FilterMenu
          items={items}
          activeFilters={activeFilters}
          onSelect={setFilterType}
          selectedTab={selectedTab as ExploreTabEnum}
        />
      );
    }

    return (
      <FilterExplorer
        filterType={filterType}
        activeFilterMap={activeFilterMap}
        onToggleSelection={toggleFilterSelection}
        onToggleMetaFilter={setAdditionalSearchParams}
        onClickBack={goBackToMenu}
        contentType={selectedTab as ExploreTabEnum}
      />
    );
  };

  return (
    <FormDrawer header={header} footer={footer}>
      <>
        {showFilterList ? (
          <FilterChipList
            filters={filterList}
            onDelete={(filter) => toggleFilterSelection(filter, false, undefined, filterType)}
          />
        ) : (
          renderFilterContent()
        )}
      </>
    </FormDrawer>
  );
};

export default FilterDrawer;
