import axios from 'common/utils/axios';
import { FollowingTypeEnum } from './followings.enum';

import { GetFollowingsParams } from './types/get-followings-params.type';
import { UseFollowParams } from './types/use-follow-params.type';

const followingsApi = axios('followings');

export const getFollowingStatusApi = (id: string) => {
  return followingsApi.get(`status/${id}`);
};

export const getFolloweesCountApi = (followerId: string, type: FollowingTypeEnum) => {
  return followingsApi.get(`followees/count`, { params: { followerId, type } });
};

export const getFollowingsApi = ({
  size = 10,
  cursor,
  followerId,
  followeeType,
}: GetFollowingsParams) => {
  return followingsApi.get('followee', {
    params: {
      size,
      cursor,
      followerId,
      type: followeeType,
    },
  });
};

export const followApi = (data: UseFollowParams) => {
  return followingsApi.post('follow', data);
};

export const unfollowApi = (data: UseFollowParams) => {
  return followingsApi.post('unfollow', data);
};

export const getFollowerCountApi = (followeeIds: string[]) => {
  return followingsApi.get(`follower/count`, { params: { followeeIds } });
};
