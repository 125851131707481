import { styled } from '@mui/material/styles';
import { CardContent } from 'common/components/material/Card';

export const ProfileDataListItemCardContent = styled(CardContent)({
  display: 'flex',
  padding: 0,
  '&.MuiCardContent-root': {
    padding: 0,
  },
  '&:last-child': {
    paddingBottom: 0,
  },
});
