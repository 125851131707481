import React from 'react';
import { styled } from '@mui/material/styles';

import { Stack } from 'common/components/material';
import Box from 'common/components/material/Box';
import FormDrawerTab, { FromDrawerTabConfig } from './DrawerTab';
import { ProjectDrawerTabEnum } from 'features/projects';

type FormDrawerTabsProps = {
  tabs: FromDrawerTabConfig[];
  value: ProjectDrawerTabEnum;
  onChange: (value: ProjectDrawerTabEnum) => void;
};

const TabContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.Tabs.Secondary.ContainerBg,
  border: `1px solid ${theme.palette.Tabs.Secondary.ContainerStroke}`,
  borderRadius: '12px',
}));

const FormDrawerTabs: React.FC<FormDrawerTabsProps> = ({ tabs, value, onChange }) => {
  const handleTabClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const tabElement = target.closest('.drawer-tab') as HTMLElement;
    const tabValue = tabElement.dataset.tab as ProjectDrawerTabEnum;
    if (tabValue) {
      onChange(tabValue);
    }
  };

  return (
    <TabContainer paddingY={1} paddingX={{ xs: 2, md: 1 }}>
      <Stack direction="row" spacing={1} onClick={handleTabClick}>
        {tabs.map((tabProps: FromDrawerTabConfig) => (
          <FormDrawerTab
            key={tabProps.value}
            {...tabProps}
            isActive={tabProps.value === value}
          />
        ))}
      </Stack>
    </TabContainer>
  );
};

export default FormDrawerTabs;
