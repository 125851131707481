import { styled } from '@mui/material/styles';
import { Stack } from 'common/components/material';
import { getThemeImagePath } from 'common/utils/file.utils';

export const StyledPraiseBackground = styled(Stack)(({ theme }) => ({
  borderRadius: '0.5rem',
  height: '100%',
  justifyContent: 'space-between',
  backgroundImage: `url(${getThemeImagePath('Praise', theme.palette.mode, 'Praise.svg')})`,
  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${getThemeImagePath(
      'Praise',
      theme.palette.mode,
      'PraiseMobile.svg',
    )})`,
  },
  backgroundSize: 'cover',
}));
