import React from 'react';

import { FormAutocomplete } from 'common/components/input';
import { useGetWinPeriods } from '../hooks/useGetWinPeriods';
import { WinPeriodSelectorProps } from '../types/win-period-selector-props.type';

const WinPeriodSelector: React.FC<WinPeriodSelectorProps> = (props) => {
  const { data } = useGetWinPeriods();

  return (
    <FormAutocomplete
      {...props}
      options={data || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      showArrowIcon={true}
    />
  );
};

export default WinPeriodSelector;
