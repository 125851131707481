import { Box, Stack } from '../material';
import { HoverArrowIcon } from '../navigation/HoverArrowIcon';
import { LinkTileContainer } from './link-tile-container.component';
import { LinkTileWithAvatarProps } from './link-tile-with-avatar.type';
import LinkTileWithAvatarContent from './link-tile-with-avatar-content.component';

export const LinkTileWithAvatar: React.FC<LinkTileWithAvatarProps> = ({
  route,
  title,
  subtitle,
  photo,
  icon,
  defaultAvatar,
  sx,
  variant,
  showBorder = true,
  readonly = false,
  endAdornment,
  onClick,
}) => {
  return (
    <LinkTileContainer
      route={route}
      readonly={readonly}
      showBorder={showBorder}
      sx={sx}
      onClick={onClick}
    >
      <LinkTileWithAvatarContent
        title={title}
        subtitle={subtitle}
        photo={photo}
        icon={icon}
        defaultAvatar={defaultAvatar}
        variant={variant}
      />
      {!readonly && (
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          <HoverArrowIcon className="show-on-hover" />
          {endAdornment && <Box className="hide-on-hover">{endAdornment}</Box>}
        </Stack>
      )}
    </LinkTileContainer>
  );
};
