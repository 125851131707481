import axios from 'common/utils/axios';
import { OrganizationRole } from './types';
import {
  CreateOrganizationRoleRequest,
  UpdateOrganizationRoleRequest,
} from './types/organization-role-server-request.type';

const organizationRoleApi = axios('organization-roles');

export const createOrganizationRoleApi = ({
  profileId,
  ...data
}: CreateOrganizationRoleRequest) => {
  return organizationRoleApi.post('', { profileId, ...data });
};

export const updateOrganizationRoleApi = ({ id, ...data }: UpdateOrganizationRoleRequest) => {
  return organizationRoleApi.put(id, data);
};

export const deleteOrganizationRoleApi = ({ id }: OrganizationRole) => {
  return organizationRoleApi.delete(id);
};

export const getOrganizationRoleApi = (profileId: string, organizationId?: string) => {
  return organizationRoleApi.get('', {
    params: { profileId, organizationId: organizationId || null },
  });
};
