import { styled, useTheme } from '@mui/material';
import { ComingSoonIconType } from '../types';

const StyledIconContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.Container.Bg,
  borderRadius: '50%',
  padding: '1.3rem 1.875rem',
  marginBottom: 35,
}));

const ComingSoonIcon: React.FC<ComingSoonIconType> = ({ Icon }) => {
  const { palette } = useTheme();

  return (
    <StyledIconContainer>
      <Icon
        sx={{ width: 75, height: 75, color: palette.Icons.Tertiary.IconFillDefault, mt: 1 }}
      />
    </StyledIconContainer>
  );
};

export default ComingSoonIcon;
