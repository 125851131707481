import React from 'react';
import MUIDivider, { DividerProps } from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

const Divider: React.FC<DividerProps> = (props) => {
  const { children, ...dividerProps } = props;
  const { palette } = useTheme();

  return (
    <MUIDivider sx={{ borderColor: palette.Divider }} {...dividerProps}>
      {children}
    </MUIDivider>
  );
};

export default Divider;
