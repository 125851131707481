import { useTheme } from '@mui/material/styles';
import { Divider, Typography } from 'common/components/material';
import { DialogProps } from 'common/components/material/Dialog';
import Drawer, { DrawerHeader } from 'common/components/material/Drawer';
import CloseButton from 'common/components/navigation/CloseButton';

const initOnClose = () => {};

const BottomDrawer: React.FC<DialogProps> = (props) => {
  const { closeButtonEnabled = true, children, onClose = initOnClose, open, title } = props;
  const { palette } = useTheme();

  const drawerHeader = (
    <DrawerHeader
      sx={{
        backgroundColor: palette.Container.Bg,
        justifyContent: 'space-between',
        padding: 2,
      }}
    >
      <Typography variant="h3" color={palette.Text.Headline}>
        {title}
      </Typography>
      {closeButtonEnabled ? <CloseButton clickHandler={onClose} /> : null}
    </DrawerHeader>
  );

  return (
    <Drawer
      variant="persistent"
      anchor="bottom"
      toggleDrawer={onClose}
      open={open}
      header={drawerHeader}
      showHeader={true}
      customPaperStyle={{
        height: 'calc(100%)',
        borderRadius: '0.75rem 0.75rem 0 0',
        overflowX: 'hidden',
      }}
      keepBackdrop={true}
    >
      <Divider variant="middle" />
      {children}
    </Drawer>
  );
};
export default BottomDrawer;
