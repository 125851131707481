import React from 'react';
import { Stack, Tooltip } from 'common/components/material';
import FilterChip from './FilterChip';
import { FilterChipListProps } from '../types';

const FilterChipList: React.FC<FilterChipListProps> = ({
  filters,
  maxHeight,
  size = 'medium',
  isExpanded = true,
  onDelete,
  onExpand,
  ...containerProps
}) => {
  // Not using Stack's spacing since it overrides vertical margins
  const spacingStyle = {
    marginLeft: size === 'small' ? '0.25rem' : '0.5rem',
    marginY: '0.2rem',
    minWidth: isExpanded ? 'auto' : 0,
  };
  const visibleFilters = isExpanded ? filters : filters.slice(0, 1);
  const hiddenFilterCount = filters.length - visibleFilters.length;

  return (
    <Stack
      direction="row"
      flexWrap={isExpanded ? 'wrap' : 'nowrap'}
      alignItems="center"
      minHeight="40px"
      maxHeight={maxHeight}
      overflow="auto"
      {...containerProps}
    >
      {visibleFilters.map((filter) => (
        <Tooltip
          key={`${filter.id}-${filter.filterType}`}
          title={isExpanded ? null : filter.name}
        >
          <FilterChip
            label={filter.name}
            size={size}
            sx={spacingStyle}
            onDelete={() => onDelete(filter)}
          />
        </Tooltip>
      ))}
      {!isExpanded && !!hiddenFilterCount && (
        <FilterChip
          key="expand-button"
          label={`+${hiddenFilterCount}`}
          sx={{ ...spacingStyle, cursor: 'pointer', flexShrink: 0 }}
          size={size}
          onClick={onExpand}
        />
      )}
    </Stack>
  );
};

export default FilterChipList;
