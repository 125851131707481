import { NotificationDrawerLabel } from 'features/followings/components/notification-drawer-label.component';
import { DrawerIdEnum } from '../enums/drawer-id.enum';

export const DRAWER_DEFAULT_PROPS = {
  [DrawerIdEnum.MENU]: {
    anchor: 'left',
    mobileAnchor: 'bottom',
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.SETTINGS]: {
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.SUBMIT_FORM]: {
    anchor: 'bottom',
    showCloseButton: false,
  },
  [DrawerIdEnum.LEAVE_FORM]: {
    anchor: 'bottom',
    showCloseButton: false,
  },
  [DrawerIdEnum.DELETE_CONFIRMATION]: {
    anchor: 'bottom',
    showCloseButton: false,
  },
  [DrawerIdEnum.NOTIFICATION]: {
    label: <NotificationDrawerLabel />,
  },
  [DrawerIdEnum.PRAISE]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.POST]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.PROJECT]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.OPPORTUNITY]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.REVIEW]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.SKILLS_ACTION]: {
    variant: 'temporary',
    /**
     * Differently from any other content creation drawer (e.g., prase, WIN, event)
     * here there is no risk of losing draft data, so we can just retain
     * the original `variant=temporary` behavior, which is to close the drawer
     * on click-away/esc key pressed.
     * Also, take a look at CNXT-1141 for more context around the request.
     */
    keepBackdrop: false,
    showHeader: false,
  },
  [DrawerIdEnum.COMPANY_UPDATE]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.COMPANY_UPDATE_CONFIRMATION]: {
    anchor: 'bottom',
    showCloseButton: false,
  },
  [DrawerIdEnum.FILTER]: {
    variant: 'temporary',
    anchor: 'bottom',
    showHeader: false,
    showCloseButton: false,
    keepBackdrop: true,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.STATIC_DATA_ITEM_ACTION_CONFIRMATION]: {
    anchor: 'bottom',
    showCloseButton: false,
  },
  [DrawerIdEnum.ORGANIZATION_ROLE]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.ORGANIZATION_EVENT]: {
    variant: 'temporary',
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.PRINCIPLES_FILTER]: {
    variant: 'temporary',
    anchor: 'bottom',
    showHeader: false,
    showCloseButton: false,
    keepBackdrop: true,
    customPaperStyle: { height: '100vh' },
  },
  [DrawerIdEnum.MANAGE_WIN_LIST]: {
    anchor: 'bottom',
    showCloseButton: false,
    keepBackdrop: true,
    showHeader: false,
    customPaperStyle: { height: '100vh' },
  },
};
