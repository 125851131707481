import { useState } from 'react';

export const useScrollPositionContext = () => {
  const [scrollPosition, setScrollPosition] = useState<number[]>([]);

  const resetScrollPosition = () => {
    setScrollPosition([]);
  };

  const pushScrollPosition = () => {
    const currentScroll = document.querySelector('body')!.scrollTop;
    setScrollPosition((scrollPosition) => [...scrollPosition, currentScroll]);
  };

  const popScrollPosition = () => {
    const currentScroll = scrollPosition[scrollPosition.length - 1];
    document.querySelector('body')!.scrollTo(0, currentScroll);
    scrollPosition.splice(scrollPosition.length - 1, 1);
    setScrollPosition(scrollPosition);
  };

  return {
    resetScrollPosition,
    pushScrollPosition,
    popScrollPosition,
  };
};
