import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { regURL } from 'common/validation-schemas/Common.schema';
import { MediaType } from './media-type.enum';

export const MediaSchema = (type: MediaType) => {
  return yup.object().shape({
    url: yup
      .string()
      .transform((value: string) => {
        return isEmpty(value) ? null : value;
      })
      .nullable()
      .when('type', {
        is: () => type === MediaType.LINK,
        then: (schema) => {
          return schema.required('Url is required').matches(regURL, 'Invalid URL');
        },
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
  });
};
