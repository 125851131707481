import axios from 'common/utils/axios';
import { PostsFeed } from './types';

const postApi = axios('posts');

export const deletePostApi = ({ id }: PostsFeed) => {
  return postApi.delete(id);
};
export const createPostApi = (data: FormData) => {
  return postApi.post('', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updatePostApi = (data: FormData) => {
  const id = `${data.get('id')}`;
  return postApi.put(id, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
