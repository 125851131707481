import { useMutation } from 'react-query';
import moment from 'moment';

import { useSession } from 'common/hooks';
import { exportProfiles } from '../profiles.service';
import { openDownloadPrompt } from 'common/utils/file.utils';

export const useExportProfiles = () => {
  const { account: user } = useSession();

  const queryKey = [user.organizationId, 'export-profiles'];

  const queryResponse = useMutation(
    queryKey,
    (searchFilters: any = {}) => exportProfiles(searchFilters),
    {
      onSuccess: ({ data, headers }) => {
        const contentDisposition = headers['content-disposition'];
        let filename: string;

        try {
          // Get the filename from the response header
          // 1. Split the header text, all text after "filename=" is on index 1
          // 2. Split again to isolate the actual filename from any other params;
          filename = contentDisposition.split('filename=')[1].split('; ')[0];
        } catch (e) {
          const today = moment().format('YYYY-MM-DD');
          filename = `profiles-export_${today}.xlsx`;
        }

        openDownloadPrompt(data, filename);
      },
    },
  );

  return queryResponse;
};
