import { useTheme } from '@mui/material';
import { isEmpty } from 'lodash';

import { MediaType } from '../../media-type.enum';
import { Button, Stack } from 'common/components/material';
import { FormTextField } from 'common/components/input';
import ModalContainer from 'common/components/modals/ModalContainer';
import { useState } from 'react';
import { isNil } from 'lodash';
import { FileInfo, Media } from '../../types/media.types';
import { useForm, useSnackbar, useView } from 'common/hooks';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MediaSchema } from '../../media.schemas';
import { FileLoader } from './file-loader';

const MediaDetailModal: React.FC<{
  type: MediaType.LINK | MediaType.VIDEO;
  open: boolean;
  onClose: () => void;
  onAdd: (media: Media) => void;
}> = ({ type, open, onClose, onAdd }) => {
  const { palette } = useTheme();
  const { openSnackbar } = useSnackbar();
  const { isDesktopView } = useView();

  const [media, setMedia] = useState<Media>({ type });

  const isLinkSelected = type === MediaType.LINK;

  const [label, setLabel] = useState<string | null>(null);
  const [url, setUrl] = useState<string | null>(null);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false);

  const onVideoLoaded = (videos: FileInfo[] = []) => {
    const video = videos?.[0];
    if (!isNil(video)) {
      media.file = video;
      media.fileName = video.name;
      media.size = video.size;

      media.name = media.fileName;
      setMedia(media);
      setIsVideoLoaded(true);
    }
  };
  const onThumbnailLoaded = (thumbnails: FileInfo[] = []) => {
    const thumbnail = thumbnails?.[0];
    if (!isNil(thumbnail)) {
      media.thumbnailFile = thumbnail;
      media.thumbnailFileName = thumbnail.name;
      media.thumbnailUrl = thumbnail.url;
      setMedia(media);
      setIsThumbnailLoaded(true);
    }
  };

  const clear = () => {
    setLabel(null);
    setUrl(null);
    setIsVideoLoaded(false);
    setIsThumbnailLoaded(false);
    setMedia({ type });
  };

  const onAddClicked = () => {
    media.label = label;
    media.url = url;
    media.type = type;

    if (isLinkSelected) {
      media.name = media.label || media.url;
    }

    if (validate(media)) {
      onAdd(media);
      clear();
    }
  };

  const onCloseClicked = () => {
    onClose();
    clear();
  };

  const validate = (media: Media) => {
    const errorMessage = {
      [MediaType.VIDEO]: 'Video is required.',
      [MediaType.LINK]: 'Url and label is required.',
    };

    const isValid =
      type === MediaType.VIDEO
        ? !isEmpty(media.fileName)
        : !isEmpty(media.url) && !isEmpty(media.label);

    if (isValid) {
      return true;
    }

    openSnackbar(errorMessage[type], 'warning');
    return false;
  };

  const form = useForm<Media>({
    defaultValues: media,
    mode: 'onChange',
    resolver: yupResolver(MediaSchema(type)),
  });

  const {
    formState: { isValid },
  } = form;

  const disabled = !isValid || (type === MediaType.VIDEO && isEmpty(media.fileName));

  return (
    <ModalContainer
      title={isLinkSelected ? 'Add Web Link' : 'Upload Videos'}
      hideDivider
      open={open}
      onClose={onCloseClicked}
    >
      <FormProvider {...form}>
        <Stack direction="column" spacing={2} gap={1} paddingX={isDesktopView ? 0 : 2}>
          {isLinkSelected ? (
            <>
              <FormTextField name="url" label="Add Web Link" onChange={setUrl} />
              <FormTextField name="label" label="Label" onChange={setLabel} />
            </>
          ) : (
            <FileLoader
              title={isVideoLoaded ? media.fileName || '' : 'Upload Video'}
              label={isVideoLoaded ? 'Remove' : 'Max 5GB'}
              disabled={false}
              labelColor={
                isVideoLoaded
                  ? palette.SpecialButtons.Delete.LabelPressed
                  : palette.Tabs.Secondary.LabelDefault
              }
              inputProps={{ name: 'video', accept: 'video/*', multiple: false }}
              onLoaded={onVideoLoaded}
            />
          )}

          <FileLoader
            title={
              isThumbnailLoaded
                ? media.thumbnailFileName || ''
                : 'Upload Thumbnail (Optional)'
            }
            label={isThumbnailLoaded ? 'Remove' : 'Max 5MB, recommended 564 X 295px'}
            disabled={false}
            labelColor={
              isThumbnailLoaded
                ? palette.SpecialButtons.Delete.LabelPressed
                : palette.Tabs.Secondary.LabelDefault
            }
            inputProps={{ name: 'thumbnail', multiple: false }}
            onLoaded={onThumbnailLoaded}
          />

          <Stack
            direction="row-reverse"
            spacing={2}
            padding={1}
            justifyContent={{ sm: 'flex-start', xs: 'space-around' }}
          >
            <Button
              disabled={disabled}
              btntype="primary"
              size="large"
              onClick={onAddClicked}
            >
              Add
            </Button>
            <Button
              btntype="tertiary"
              size="large"
              onClick={onCloseClicked}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </ModalContainer>
  );
};

export default MediaDetailModal;
