import React from 'react';

import { Stack } from 'common/components/material';
import HorizontalScrollableContainer from 'common/components/container/HorizontalScrollableContainer';
import TagChip from 'features/tags/components/tag-chip.component';
import { Tag } from 'features/tags/types';

export const OrganizationEventMobileTags: React.FC<{ tags?: Tag[] }> = ({ tags }) => {
  if (!tags?.length) {
    return <></>;
  }

  const chips = (
    <Stack
      pt={0}
      direction="row"
      spacing={1}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {tags.map((tag) => (
        <TagChip
          key={tag.id}
          tag={tag}
          sx={{
            '& .MuiChip-label': { pl: '0.125rem', pr: '0.25rem' },
            '.MuiAvatar-root, .MuiSvgIcon-root, .tag-icon': { display: 'none' },
          }}
        />
      ))}
    </Stack>
  );

  return (
    <Stack width="100%" py={0} overflow="hidden">
      <HorizontalScrollableContainer scrolledListElement={chips} />
    </Stack>
  );
};
