import React from 'react';

type FormContainerProps = {
  disabled?: boolean;
}

export const FormContainer: React.FC<FormContainerProps> = ({disabled, children}) => {
    if (disabled) {
      return <>{children}</>;
    }

    return (
      <form
        noValidate
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault(); // Handled by handleNextClick
        }}
      >
        {children}
      </form>
    );
}
