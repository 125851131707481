import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { ManageProjectRequest, Project } from 'features/projects';
import { manageProjectsApi } from 'features/projects/services/projects.service';

export const useManageProjects = (options: UseMutationOptions = {}) => {
  const { emit } = useEventEmitter<Project>('ProjectAdded');

  const mutationResponse = useMutation(manageProjectsApi, {
    showSuccessSnackbar: true,
    transformInput: (projects: ManageProjectRequest[]) => {
      const periodId = projects.length ? projects[0].period?.id : null;
      const transformedProjects = projects.map((project) => {
        return {
          sourceProjectId: project.id,
          title: project.title,
        };
      });
      return { projects: transformedProjects, periodId };
    },
    onSuccess: ({ data }) => {
      emit(data);
    },
    ...options,
  });

  return mutationResponse;
};
