import { styled, useTheme } from '@mui/material/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { IconButton, Link, Stack } from 'common/components/material';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { titleCase } from 'common/utils/app.utils';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { SIZE_STYLE } from 'common/components/cx-avatar/cx-avatar.constants';
import { IStaticDataSearchSuggestion } from '../interfaces/static-data-search-suggestion.interface';

const VisitPageLink = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.SelectionChips.Secondary.LabelAndIconDefault,
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
    // Fill up the height of the row to make it easier to click
    alignSelf: 'stretch',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '0.5rem',
    '&:hover': {
      color: theme.palette.SelectionChips.Secondary.LabelAndIconHover,
    },
    '&:after': {
      content: '"\u00A0>\u00A0"',
    },
  };
});

const StaticDataSearchOptionItem: React.FC<{
  staticData: IStaticDataSearchSuggestion;
  searchTerm?: string;
  onVisitPageClick?: () => void;
}> = ({ staticData, searchTerm = '', onVisitPageClick }) => {
  const { palette } = useTheme();
  const { id, completeName, name, type } = staticData;

  const displayedType = titleCase(type);
  const hasParent = completeName !== name;
  let description = displayedType;

  if (hasParent) {
    const index = completeName.lastIndexOf(` - ${name}`);
    const parentCompleteName = completeName.slice(0, index);
    description += ` in ${parentCompleteName}`;
  }

  const matches = match(description, searchTerm, { insideWords: true });
  const parts = parse(description, matches);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      position="relative"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" spacing={1} position="relative" data-analytics-tag="search_suggest_add_filter">
        <IconButton
          sx={{
            padding: 0,
            backgroundColor: palette.Icons.Secondary.BgDefault,
            color: palette.Icons.Secondary.IconFill,
            ...SIZE_STYLE.small.avatar,
            '&:hover': {
              backgroundColor: palette.Icons.Secondary.BgHover,
            },
          }}
        >
          <FilterAltIcon sx={{ fontSize: '1.125rem ' }} />
        </IconButton>
        <EllipsisText
          lineclamp={1}
          variant="body4"
          fontWeight={500}
          color={palette.LinkText.Default}
          maxWidth="75%"
          flexShrink={0}
          sx={{ wordBreak: 'break-all' }}
        >
          {name}
        </EllipsisText>
        <EllipsisText
          lineclamp={1}
          variant="body4"
          fontWeight={500}
          color={palette.StaticDataChip.TextDefault}
          title={description}
          sx={{ wordBreak: 'break-all' }}
        >
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                color: part.highlight ? palette.Text.Headline : palette.Text.SubHeadline,
                fontWeight: part.highlight ? 500 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </EllipsisText>
      </Stack>

      <VisitPageLink
        data-testid="visit-page-link"
        href={AppRoutesEnum.STATIC_DATA_SUMMARY}
        params={{
          staticDataId: id,
          staticDataType: type,
        }}
        onClick={(e) => {
          e.stopPropagation();
          onVisitPageClick && onVisitPageClick();
        }}
        data-analytics-tag="search_suggest_visit_page"
      >
        Visit Page
      </VisitPageLink>
    </Stack>
  );
};

export default StaticDataSearchOptionItem;
