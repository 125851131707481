import { useEffect } from 'react';

import PubSub from 'common/utils/PubSub';

import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';

const drawerPubSub = new PubSub();

const openDrawer = (drawerId: DrawerIdEnum, drawerProps: any) => {
  if (!drawerProps.toggleDrawer) {
    drawerProps.toggleDrawer = () => closeDrawer(drawerId);
  }
  drawerPubSub.publish({
    open: true,
    drawerId,
    drawerProps,
  });
};

const closeDrawer = (drawerId: DrawerIdEnum) => {
  drawerPubSub.publish({
    drawerId,
    open: false,
  });
};

const useDrawer = (subscriber?: Function) => {
  useEffect(() => {
    if (subscriber) {
      drawerPubSub.subscribe(subscriber);
      return () => {
        drawerPubSub.unsubscribe(subscriber);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    openDrawer,
    closeDrawer,
  };
};

export default useDrawer;
