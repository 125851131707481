import { styled } from '@mui/material/styles';
import Button from 'common/components/material/Button';
import React from 'react';
import ListItemButton from '../material/ListItemButton';
import Link, { LinkProps } from '../material/Link';

export type NavBtnSizeType = 'small' | 'medium' | 'large';
export type NavIconBtnProps = { size: NavBtnSizeType };

export const NAV_ICON_STYLE = {
  small: {
    width: '0.9rem',
    height: '0.9rem',
  },
  medium: {
    width: '1.2rem',
    height: '1.2rem',
  },
  large: {},
} as any;

export const NAV_BUTTON_STYLE = {
  small: {
    minWidth: '1.5rem',
    width: '1.5rem',
    height: '1.5rem',
    padding: 0,
  },
  medium: {
    minWidth: '2.15rem',
    width: '2.15rem',
    height: '2.15rem',
    padding: 0,
  },
  large: {},
} as any;

export const StyledNavIcon = (icon: React.FC) =>
  styled(icon, {
    shouldForwardProp: (prop) => prop !== 'size',
  })<NavIconBtnProps>(({ size }) => ({
    ...(size && NAV_ICON_STYLE[size]),
  }));

export const StyledNavButton = styled(Button)(({ size, theme }) => ({
  '.MuiButton-startIcon': {
    margin: 0,
  },
  borderColor: theme.palette.Stroke.Default,
  backgroundColor: theme.palette.LinkContainer.BgDefault,
  borderRadius: '6px',
  color: theme.palette.Icons.Tertiary.IconFillDefault,
  '&:hover, &:focus': {
    borderColor: theme.palette.Stroke.Active,
    color: theme.palette.Icons.Tertiary.IconFillHover,
    backgroundColor: theme.palette.LinkContainer.BgHover,
  },
  '&.Mui-disabled': {
    color: theme.palette.Buttons.Tertiary.LabelDisabled,
    '& .MuiSvgIcon-root': {
      color: theme.palette.Buttons.Tertiary.LabelDisabled,
    },
  },
  ...(size && NAV_BUTTON_STYLE[size]),
}));

export const StyledSearchOption = styled(ListItemButton)(({ theme }) => {
  return {
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: `${theme.palette.DropdownList.Hover}`,
    },
  };
});

export const BreadcrumbLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'separator',
})<LinkProps & { separator?: string }>(
  ({ theme, color = theme.palette.LinkText.Default, separator = '>' }) => ({
    display: 'inherit',
    color: color as string,
    '&:hover': {
      color: theme.palette.LinkText.Active,
    },
    '&:not(:last-child):after': {
      content: `"\u00A0${separator}\u00A0"`,
    },
  }),
);
