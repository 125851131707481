import { isNil } from 'lodash';
import React from 'react';

import { HorizontalScrollableContainer } from 'common/components/container';
import { Stack } from 'common/components/material';
import ButtonTab, { ButtonTabConfig } from './ButtonTab';
import { SxProps, Theme } from '@mui/material';

type ButtonTabsProps = {
  tabs: ButtonTabConfig[];
  selectedValues?: string[];
  onChange: (value: any) => void;
  scroll?: boolean;
  sx?: SxProps<Theme>;
};

const ButtonTabs: React.FC<ButtonTabsProps> = ({ tabs, selectedValues, onChange, scroll, sx }) => {
  const handleTabClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    const tabElement = target.closest('.button-tab') as HTMLElement;
    const tabValue = tabElement?.dataset?.tab as any;
    !isNil(tabValue) && onChange(tabValue);
  };

  const selectedValuesSet = new Set(selectedValues);

  const listElement = (
    <Stack direction="row" onClick={handleTabClick} sx={sx}>
      {tabs.map((tabProps: ButtonTabConfig) => (
        <ButtonTab
          key={tabProps.value}
          {...tabProps}
          isActive={selectedValuesSet.has(tabProps.value)}
        />
      ))}
    </Stack>
  );

  if (scroll) {
    return <HorizontalScrollableContainer scrolledListElement={listElement} />;
  }
  return listElement;
};

export default ButtonTabs;
