import React from 'react';
import MUIStack, { StackProps as MUIStackProps } from '@mui/material/Stack';

export type StackProps = MUIStackProps;

const Stack: React.FC<MUIStackProps> = React.forwardRef((props, ref) => {
  const { children, ...stackProps } = props;

  return (
    <MUIStack ref={ref} {...stackProps}>
      {children}
    </MUIStack>
  );
});

export default Stack;
