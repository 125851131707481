import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Grid } from 'common/components/material';
import { getThemeImagePath } from 'common/utils/file.utils';

const Logo = styled('img')(({ theme }) => ({
  height: 30,
  [theme.breakpoints.up('md')]: {
    height: 50,
  },
}));

const SplashScreen: React.FC<any> = () => {
  const { palette } = useTheme();
  return (
    <Grid container height="100vh" width="100vw" justifyContent="center" alignItems="center">
      <Logo
        src={getThemeImagePath('ConnectX', palette.mode, 'ConnectXLogo.png')}
        alt="ConnectX"
      />
    </Grid>
  );
};

export default SplashScreen;
