import React from 'react';
import { useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import { ManageWinListHeader } from './manage-win-list-header.component';
import {
  StackedListItemContainer,
  VerticalScrollableContainer,
} from 'common/components/container';
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from 'common/components/material';
import { useDrawer, useSession, useView } from 'common/hooks';
import { useGetCurrentWinPeriod } from 'features/projects/hooks/useGetCurrentWinPeriod';
import { useGetNextWinPeriod } from '../hooks/useGetNextWinPeriod';
import { useManageDraftWins } from '../hooks/useManageDraftWins';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { useManageProjects } from '../hooks/useManageProjects';
import {
  ManageProjectRequest,
  ProjectStatusEnum,
  useGetProfileProjects,
} from 'features/projects';
import { ExistingWinsContainerHeader } from 'features/projects/components/existing-wins-container-header.component';
import StackedDataListItemCard from 'features/profiles/components/StackedDataListItemCard';
import { ProjectItemView } from 'features/projects/components/project-item-view.component';
import { InlineProject } from 'features/projects/components/inline-project.component';
import { ManageWinListDrawerActions } from './manage-win-list-drawer-actions.component';
import { ExistingProjectHeaderActions } from './existing-project-header-actions.component';
import { DraftProjectHeaderActions } from './draft-projects-header-actions.component';
import { useGetPreviousWinPeriod } from '../hooks/useGetPreviousWinPeriod';
import moment from 'moment';

export const ManageWinListDrawer: React.FC = () => {
  const { palette } = useTheme();
  const { closeDrawer } = useDrawer();
  const { profile: loggedInProfile } = useSession();
  const { data: existingCurrentProjects, isLoading: isExistingCurrentProjectsLoading } =
    useGetProfileProjects(loggedInProfile?.id, {
      period: 'current',
      status: ProjectStatusEnum.OPEN,
    });
  const { data: existingPreviousProjects, isLoading: isExistingPreviousProjectsLoading } =
    useGetProfileProjects(
      loggedInProfile?.id,
      { period: 'previous', status: ProjectStatusEnum.OPEN },
      { enabled: isEmpty(existingCurrentProjects) && !isExistingCurrentProjectsLoading },
    );

  const { data: currentWinPeriod, isLoading: isCurrentWinPeriodLoading } =
    useGetCurrentWinPeriod();
  const { data: nextWinPeriod, isLoading: isNextWinPeriodLoading } = useGetNextWinPeriod();
  const { data: previousWinPeriod, isLoading: isPreviousWinPeriodLoading } =
    useGetPreviousWinPeriod({
      enabled: isEmpty(existingCurrentProjects) && !isExistingCurrentProjectsLoading,
    });
  const { mutateAsync: copyProjects, isLoading: isCopyingProjects } = useManageProjects();
  const { isDesktopView } = useView();

  const newWinPeriod =
    !isEmpty(previousWinPeriod) || isEmpty(nextWinPeriod) ? currentWinPeriod : nextWinPeriod;

  const {
    draftProjects,
    isMaxLimitReached,
    createProject,
    removeProject,
    checkIfProjectAlreadyCopied,
  } = useManageDraftWins(newWinPeriod);

  const isLoading =
    isExistingCurrentProjectsLoading ||
    isExistingPreviousProjectsLoading ||
    isCurrentWinPeriodLoading ||
    isNextWinPeriodLoading ||
    isPreviousWinPeriodLoading;

  const handleCopyProjects = async () => {
    await copyProjects(draftProjects);
    closeDrawer(DrawerIdEnum.MANAGE_WIN_LIST);
  };

  const handleInlineProjectCreation = (title: string) => {
    createProject({ title, skills: [], tags: [], owner: [loggedInProfile], visibleTo: [] });
  };

  const headerSubText = !!existingCurrentProjects?.length
    ? 'List your key initiatives for next quarter'
    : 'List your key initiatives for current quarter';

  const existingProjects = isEmpty(existingCurrentProjects)
    ? existingPreviousProjects
    : existingCurrentProjects;

  const remainingDaysToExpire =
    isEmpty(existingCurrentProjects) && previousWinPeriod
      ? moment.utc().diff(moment.utc(previousWinPeriod.endDate), 'days')
      : moment.utc(currentWinPeriod.endDate).diff(moment.utc(), 'days');

  const areWinsExpired = isEmpty(existingCurrentProjects);

  return (
    <Box sx={{ bgcolor: palette.Background }}>
      <VerticalScrollableContainer maxWidth={false} sx={{ height: '100vh' }}>
        <ManageWinListHeader subText={headerSubText} />
        <Stack sx={{ mt: 6 }}>
          {isLoading && (
            <Stack alignItems="center" sx={{ mt: 15 }}>
              <CircularProgress />
            </Stack>
          )}
          <Stack mt={5}>
            {!!existingProjects?.length && (
              <Container maxWidth="md" disableGutters={!isDesktopView} sx={{ mb: 2 }}>
                <Stack spacing={1.5}>
                  <ExistingWinsContainerHeader
                    period={
                      isEmpty(existingCurrentProjects) && previousWinPeriod
                        ? previousWinPeriod
                        : currentWinPeriod
                    }
                    daysToExpire={remainingDaysToExpire}
                    isExpired={areWinsExpired}
                  />
                  <StackedListItemContainer>
                    {existingProjects.map((project: ManageProjectRequest) => (
                      <StackedDataListItemCard
                        key={project.title}
                        sx={{ borderColor: palette.Stroke.Default }}
                      >
                        <ProjectItemView
                          defaultExpanded={false}
                          project={project}
                          headerActionElement={
                            <ExistingProjectHeaderActions
                              project={project}
                              winPeriod={newWinPeriod}
                              isDisabled={isMaxLimitReached}
                              isCopyButtonVisible={!checkIfProjectAlreadyCopied(project)}
                              createProject={createProject}
                            />
                          }
                        />
                      </StackedDataListItemCard>
                    ))}
                  </StackedListItemContainer>
                </Stack>
              </Container>
            )}
            {!isLoading && (
              <>
                <Container maxWidth="md" disableGutters={!isDesktopView}>
                  <Stack direction="row" sx={{ my: 1.5 }}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      color={palette.Text.Headline}
                    >
                      Add {newWinPeriod?.name} WINs
                    </Typography>
                  </Stack>
                  <StackedListItemContainer>
                    {draftProjects.map((project: ManageProjectRequest) => (
                      <StackedDataListItemCard
                        key={project.title}
                        sx={{ borderColor: palette.Stroke.Default }}
                      >
                        <ProjectItemView
                          defaultExpanded={false}
                          project={project}
                          headerActionElement={
                            <DraftProjectHeaderActions
                              project={project}
                              removeProject={removeProject}
                            />
                          }
                        />
                      </StackedDataListItemCard>
                    ))}
                    {!isMaxLimitReached && (
                      <StackedDataListItemCard
                        sx={{ py: 1.5, px: 3, borderColor: palette.Stroke.Default }}
                      >
                        <InlineProject
                          period={newWinPeriod}
                          skipProjectCreation
                          onSubmit={handleInlineProjectCreation}
                          autoFocusAddNewWinField
                        />
                      </StackedDataListItemCard>
                    )}
                  </StackedListItemContainer>
                  <Stack sx={{ mt: 1.5 }}>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      color={palette.Text.SubHeadline}
                    >
                      Note: WIN Lists are published on the home feed and your manager will be
                      notified to stay in sync
                    </Typography>
                  </Stack>
                </Container>
                <ManageWinListDrawerActions
                  isActionDisabled={!draftProjects.length || isCopyingProjects}
                  onClick={handleCopyProjects}
                />
              </>
            )}
          </Stack>
        </Stack>
      </VerticalScrollableContainer>
    </Box>
  );
};
