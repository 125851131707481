import PrinciplesFilterColumn from 'features/principles/components/principles-filter-column.component';
import { PrinciplesFilterListItem } from './principles-filter-list-item.component';
import { PrinciplesFilterExplorerProps } from '../types/principles-filter.type';
import { SearchProfile } from 'features/profiles';
import ProfileFilter from '../profile-filter.model';

export const PrinciplesFilterExplorer: React.FC<PrinciplesFilterExplorerProps> = ({
  data = [],
  activeFilterMap,
  toggleFilterSelection,
}) => (
  <PrinciplesFilterColumn
    data={data as SearchProfile[]}
    renderItem={(profile: SearchProfile) => {
      const item = {
        id: profile.id,
        name: profile.fullName,
        fullName: profile.fullName,
        photo: profile.photo,
      };

      const profileFilter = new ProfileFilter(profile.id, profile.fullName);

      return (
        <PrinciplesFilterListItem
          key={profile.id}
          data={item}
          active={false}
          showArrow={false}
          selected={!!activeFilterMap[`${profile.id}_profile`]}
          onToggleCheckbox={(selected: boolean) => {
            toggleFilterSelection(profileFilter, selected, null, 'profile');
          }}
          isAssessmentCompleted={profile?.isPrUsAssessmentCompleted}
          isAssessmentPrivate={profile?.isPrUsAssessmentPrivate}
        />
      );
    }}
  ></PrinciplesFilterColumn>
);
