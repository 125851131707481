import React from 'react';
import { useTheme } from '@mui/material/styles';

import { FormTextField } from 'common/components/input';
import { Stack, Typography } from 'common/components/material';
import { FormTextFieldProps } from 'common/types/Form.type';
import {
  PRAISE_DESC_MAX_LENGTH,
  PRAISE_INPUT_LABEL,
  PRAISE_INPUT_NAME,
  PRAISE_INPUT_PLACEHOLDER,
} from 'features/praise';

const PraiseFormDesc: React.FC<FormTextFieldProps> = ({ onChange, value = '', ...props }) => {
  const { palette } = useTheme();

  return (
    <Stack>
      <FormTextField
        name={PRAISE_INPUT_NAME}
        label={PRAISE_INPUT_LABEL}
        placeholder={PRAISE_INPUT_PLACEHOLDER}
        multiline
        rows={8}
        value={value || ''}
        onChange={onChange}
        {...props}
        sx={{ textarea: { fontSize: '14px', lineHeight: '18px' } }}
        inputProps={{
          maxLength: PRAISE_DESC_MAX_LENGTH,
        }}
      />
      <Typography
        display="block"
        textAlign="right"
        variant="caption"
        sx={{ marginTop: 1, marginBottom: -1 }}
        color={value?.length > 0 ? palette.Success : palette.TextFields.HintText}
      >
        {`${value?.length} / ${PRAISE_DESC_MAX_LENGTH} characters`}
      </Typography>
    </Stack>
  );
};

export default PraiseFormDesc;
