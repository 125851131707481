import { EndorsedSkill, EndorsedSkillWithEndorsers, Skill } from 'features/skills';

export const updateEndorsedSkill = (
  endorsedSkills: EndorsedSkill[],
  skillId: string,
  data: EndorsedSkillWithEndorsers,
): EndorsedSkillWithEndorsers[] => {
  let isSkillUpdated = false;

  const updatedEndorsedSkills = endorsedSkills.map((endorsedSkill: EndorsedSkill) => {
    if (endorsedSkill.skill.id === skillId) {
      isSkillUpdated = true;
      return data;
    }

    return endorsedSkill;
  });

  if (!isSkillUpdated) {
    updatedEndorsedSkills.push(data);
  }

  return updatedEndorsedSkills;
};

export const deleteEndorsedSkill = (
  endorsedSkills: EndorsedSkillWithEndorsers[],
  skillId: string,
  data: EndorsedSkillWithEndorsers,
  profileId?: string,
): EndorsedSkillWithEndorsers[] => {
  return endorsedSkills.map((endorsedSkill: EndorsedSkillWithEndorsers) => {
    if (endorsedSkill.skill.id !== skillId) {
      return endorsedSkill;
    }

    // Return updated endorsedSkill
    if (data) {
      return data;
    }

    // If Response.data is empty that means no other endorsers and we need to make its score to zero
    // ML and HL: We were thinking to move this logic to somwhere in service or something,
    // but for now we are not sure where to put it, so time being it is here.
    if (endorsedSkill.totalEndorsers && endorsedSkill.isEndorsedByLoggedInUser) {
      endorsedSkill.totalEndorsers--;
    }
    endorsedSkill.isEndorsedByLoggedInUser = false;
    endorsedSkill.loggedInUserRating = undefined;
    endorsedSkill.score = 0;
    if (endorsedSkill.topEndorsers) {
      endorsedSkill.topEndorsers = endorsedSkill.topEndorsers.filter(
        (endorser) => endorser?.id !== profileId,
      );
    }
    return endorsedSkill;
  });
};

export const convertToEndorsedSkill = (skill: Skill) => {
  return {
    skill,
  } as EndorsedSkillWithEndorsers;
};
