import { useEventEmitter, useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { deleteOrganizationEventsApi } from '../organization-events.service';

export const useDeleteOrganizationEvent = (options: UseMutationOptions = {}) => {
  const { emitWithSettledAfter } = useEventEmitter('OrganizationEventDeleted');

  return useMutation(deleteOrganizationEventsApi, {
    showSuccessSnackbar: true,
    onSuccess: () => {
      emitWithSettledAfter(3000);
    },
    ...options,
  });
};
