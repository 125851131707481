import { useEvents, useQuery, useSession } from 'common/hooks';
import { getLatestProjectsApi } from '../services/projects.service';
import { EVENTS_TO_REFETCH_PROJECTS } from '../project.utils';
import { Event } from 'common/hooks/eventHooks/useEvents';
import { UseQueryOptions } from 'react-query';

export const useGetLatestProjects = (tagId?: string, options: UseQueryOptions = {}) => {
  const { account: user } = useSession();
  if (tagId === user.organizationId) tagId = undefined;

  const queryKey = [user.organizationId, tagId || user.organizationId, 'projects', 'latest'];

  const queryResponse = useQuery(queryKey, () => getLatestProjectsApi(tagId), {
    ...options,
    select: (response) => {
      return {
        ...response,
        data: {
          projects: response.data.projects,
        },
      };
    },
  });

  useEvents(EVENTS_TO_REFETCH_PROJECTS as Event[], () => queryResponse.refetch());

  return {
    ...queryResponse,
    data: queryResponse?.data?.data?.projects,
  };
};
