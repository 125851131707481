import { styled } from '@mui/material/styles';
import { Box } from 'common/components/material';

export const PraiseLeaderboardBannerWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.Extras.Extra6,
  borderRadius: '0.75rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
