import { useQuery } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { getSkillEndorsersApi } from '../services';
import { Person } from 'features/profiles';
import { ProfilePreviewModel } from 'features/profiles/models/profile-preview.model';

export const useGetSkillEndorsers = (
  endorseeId: string,
  skillId: string,
  options: UseQueryOptions = {},
) => {
  const queryKey = ['skill-endorsers', endorseeId, skillId];

  const response = useQuery(queryKey, () => getSkillEndorsersApi(endorseeId, skillId), {
    showErrorSnackbar: false,
    select: (result) => {
      return result.data.endorsers.map(
        (endorser: Person) => new ProfilePreviewModel(endorser),
      );
    },
    ...options,
  });

  return {
    ...response,
    data: response.data as ProfilePreviewModel[],
  };
};
