import React from 'react';
import IndeterminateCheckBoxTwoToneIcon from '@mui/icons-material/IndeterminateCheckBoxTwoTone';

import { useView } from 'common/hooks';
import BaseDataExplorerListItem from 'features/data-explorer/components/base-data-explorer-list-Item.component';
import { FilterCheckbox } from '../styles/filter-checkbox.component';
import {
  convertExplorerDataToFilter,
  hasSelectedChild,
  isSelectedFilter,
} from 'features/static-data';
import { HierarchicalExplorerListItemProps } from 'features/data-explorer/types/hierarchical-explorer-list-item-props.type';
import { HierarchicalDataMapping } from 'features/data-explorer/types/explorer-data.type';
import { FormOperationsEnum } from 'common/enum/Form.enum';

const FilterListItem: React.FC<HierarchicalExplorerListItemProps> = ({
  data,
  active,
  activeFilterMap = {},
  mapping,
  showArrow,
  labelKey = 'name',
  onItemAction,
  dataType,
  arrowDirection,
  size,
}) => {
  const { isDesktopView } = useView();
  const mapItem = mapping ? mapping[data.id] : data;
  const filter = convertExplorerDataToFilter(data);
  const IconComponent = data?.IconComponent;

  const selected = isSelectedFilter(filter, activeFilterMap, dataType, mapping);
  const selectedChild =
    !data.excludeChildren &&
    hasSelectedChild(mapItem, activeFilterMap, mapping as HierarchicalDataMapping, dataType);

  const toggleCheckbox = () => {
    onItemAction({
      data: filter,
      actionType: 'toggleFilter',
      mapping,
      value: !selected,
    });
  };

  return (
    <>
      <BaseDataExplorerListItem
        label={data[labelKey as keyof typeof data] as string}
        selected={isDesktopView && active}
        showArrow={showArrow}
        arrowDirection={arrowDirection}
        size={size}
        data-testid="filter-list-item"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          // Clicking on the text toggles the checkbox instead of expanding the children
          if (
            e.target instanceof HTMLElement &&
            e.target.className.includes('MuiTypography-root')
          ) {
            toggleCheckbox();
          } else {
            showArrow &&
              onItemAction({ data, actionType: FormOperationsEnum.EXPAND, event: e });
          }
        }}
        showBorder={!isDesktopView}
        startAdornment={
          <>
            <FilterCheckbox
              checked={!!selected}
              indeterminate={selectedChild && !selected}
              indeterminateIcon={<IndeterminateCheckBoxTwoToneIcon />}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                toggleCheckbox();
              }}
              size={size}
            />
            {IconComponent}
          </>
        }
        sx={{ cursor: showArrow ? 'pointer' : 'default' }}
      />
    </>
  );
};

export default FilterListItem;
