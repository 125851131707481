import { useMutation } from 'common/hooks';
import { UseMutationOptions } from 'common/types/Api.type';
import { uploadLogoApi } from 'features/companies/companies.service';

export const useUploadLogo = (options: UseMutationOptions = {}) => {
  const mutationResponse = useMutation(uploadLogoApi, {
    ...options,
  });
  return { ...mutationResponse };
};
