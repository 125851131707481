import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { find, get, isNil, some, pick, reject } from 'lodash';
import { useTheme } from '@mui/material/styles';

import { FormDrawer, FormDrawerFooter } from 'common/components/drawer';
import { Box, Divider, Stack, Typography } from 'common/components/material';
import { DrawerHeader } from 'common/components/material/Drawer';
import { FormTextField, PeopleSelector, StyledForm } from 'common/components/input';
import {
  useDeferredState,
  useDrawer,
  useForm,
  useSession,
  useTimeZones,
  useView,
} from 'common/hooks';
import { convertToSpecificTimezone } from 'common/utils/date-time.utils';
import { TITLE_CHARACTER_LIMIT } from 'common/constants/common.constants';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { Tag } from 'features/tags/types';
import TagSelector from 'features/tags/components/tag-selector.component';
import { convertWorkLocationToTag } from 'features/tags/utils';
import { TagContentType } from 'features/tags/enums/tag-content-type.enum';
import { WorkLocation } from 'features/static-data';
import OnBehalfOfSelector from 'features/on-behalf-of/components/on-behalf-of-selector.component';
import { useGetOnBehalfOfValue } from 'features/on-behalf-of/hooks/useGetOnBehalfOfValue';
import { CXEditorField } from 'libs/toast-ui';

import {
  OrganizationEvent,
  OrganizationEventDrawerProps,
  OrganizationEventForm,
} from '../types';
import { useUpdateOrganizationEvent, useCreateOrganizationEvent } from '../hooks';
import { ORGANIZATION_EVENT_DRAWER_HELPER } from '../organization-events.constants';
import { OrganizationEventFormSchema } from '../organization-events.schema';
import { OrganizationEventLocationTypeEnum } from '../organization-events.enum';
import EventTypeSelector from './organization-event-type-selector.component';
import OrganizationEventDateTimeFields from './organization-event-date-time-fields.component';
import OrganizationEventLocationFields from './organization-event-location-fields.component';
import VisibileToSelector from 'common/components/role-access/VisibleToSelector';
import { ContentType } from 'common/enum/ContentType.enum';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { FeatureGuard } from 'features/feature-access/components/feature-guard.component';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';

export const INIT_PROPS: OrganizationEventDrawerProps = {
  organizationEvent: {} as OrganizationEvent,
  selectedTags: [] as Tag[],
  isComingFromGlobalCreateButton: false,
};

const OrganizationEventDrawer: React.FC<OrganizationEventDrawerProps> = ({
  organizationEvent = {} as OrganizationEvent,
  selectedTags = [],
  feedId,
  eventStartDate,
  isComingFromGlobalCreateButton = false,
  onBehalfOfPage,
}) => {
  const { palette } = useTheme();
  const { isMobileView } = useView();
  const { closeDrawer } = useDrawer();
  const { profile, currentOrganization } = useSession();
  const today = moment();
  const initDate = eventStartDate ? eventStartDate : today.format('YYYY-MM-DD');
  const { selectedTimezone } = useTimeZones(get(organizationEvent, 'originTimezone'));
  const { canWrite: canTagAnyPage } = useFeatureAccess(FeaturesEnum.ALL_STATIC_DATA_PAGES);

  organizationEvent = organizationEvent ?? {};

  const { date: initialStartDate, time: initialStartTime } = convertToSpecificTimezone(
    organizationEvent?.startDate,
    organizationEvent?.startTime,
    selectedTimezone,
  );

  const { date: initialEndDate, time: initialEndTime } = convertToSpecificTimezone(
    organizationEvent?.endDate,
    organizationEvent?.endTime,
    selectedTimezone,
  );

  const onBehalfOfDefaultValue = useGetOnBehalfOfValue({
    isComingFromGlobalCreateButton,
    onBehalfOfPage,
  });
  organizationEvent.onBehalfOf = organizationEvent?.onBehalfOf || onBehalfOfDefaultValue;

  const [tags, setTags] = useDeferredState<Tag[]>(selectedTags || organizationEvent?.tags);
  const [onBehalfOf, setOnBehalfOf] = useState<Tag | null>(organizationEvent?.onBehalfOf);

  const { submit: createOrganizationEvent, isLoading: isCreatingEvent } =
    useCreateOrganizationEvent(tags);
  const { submit: updateOrganizationEvent, isLoading: isUpdatingEvent } =
    useUpdateOrganizationEvent(feedId);

  const organizationEventId = get(organizationEvent, 'id');
  const actionLabel = organizationEventId ? 'Update' : 'Create';
  const headerLabel = organizationEventId ? 'Update Event' : 'Create Event';

  const defaultValues = organizationEventId
    ? {
        ...pick(organizationEvent, [
          'title',
          'description',
          'eventType',
          'eventUrl',
          'onBehalfOf',
        ]),
        venue: organizationEvent.venue || 'TBC',
        startDate: initialStartDate,
        startTime: initialStartTime,
        endDate: initialEndDate,
        endTime: initialEndTime,
        organizer: [organizationEvent.organizer],
        visibleTo: organizationEvent.visibleTo || [currentOrganization()],
      }
    : {
        title: '',
        organizer: [profile],
        eventLocationType: OrganizationEventLocationTypeEnum.OFFICE,
        eventUrl: '',
        venue: 'TBC',
        startDate: initDate,
        endDate: initDate,
        description: '',
        onBehalfOf: onBehalfOfDefaultValue,
        visibleTo: [currentOrganization()],
      };

  const form = useForm<OrganizationEventForm>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(OrganizationEventFormSchema(canTagAnyPage)),
  });

  const {
    formState: { isDirty, isValid, dirtyFields },
    clearErrors,
    getValues,
  } = form;

  const shouldDisableActions = !isDirty || isCreatingEvent || isUpdatingEvent || !isValid;

  const onCloseDrawer = () => {
    closeDrawer(DrawerIdEnum.ORGANIZATION_EVENT);
  };

  const onSubmit = () => {
    const data = form.getValues();
    const onBehalfOfValue: Tag = onBehalfOf || data.onBehalfOf;
    data.onBehalfOfId = onBehalfOfValue?.id || '';
    data.onBehalfOfType = onBehalfOfValue?.type || '';

    if (organizationEventId) {
      updateOrganizationEvent({ ...data, id: organizationEventId });
    } else {
      createOrganizationEvent(data);
    }
    onCloseDrawer();
  };

  const addOfficeLocationAsTag = (officeLocation: WorkLocation | null) => {
    if (officeLocation) {
      const existingTag = find(tags, { id: officeLocation.id });

      if (existingTag) {
        return;
      }

      // Replace any tag that was previously added automatically from office selection
      const newTags = reject(tags, { autoSelected: true });
      const tag = convertWorkLocationToTag(officeLocation);

      newTags.push(tag);
      setTags(newTags);
      form.setValue('tags', newTags);
    }
  };

  // POBO => Publish On Behalf Of
  const onPOBOChanged = (tag: Tag | null) => {
    setOnBehalfOf(tag);
    if (tag && tag.type !== 'COMPANY') {
      const newTags = tags || [];
      if (!some(tags, (t) => t.id === tag.id)) {
        newTags?.push(tag);
        setTags(newTags);
        form.setValue('tags', newTags);
        if (isDirty && dirtyFields['tags']) {
          clearErrors();
        }
      }
    }
  };

  const onTagsChanged = (tags: Tag[]) => {
    const newTags = tags || [];
    if (
      onBehalfOf &&
      onBehalfOf.type !== 'COMPANY' &&
      !(tags || []).some((t) => t.id === onBehalfOf.id)
    ) {
      newTags.push(onBehalfOf);
    }
    setTags(newTags);
  };

  const header = (
    <DrawerHeader sx={{ display: 'block', p: 2, pb: 0 }}>
      <Typography
        variant={isMobileView ? 'h4' : 'h2'}
        fontWeight={700}
        color={palette.Text.Headline}
      >
        {headerLabel}
      </Typography>
    </DrawerHeader>
  );

  const footer = (
    <FormDrawerFooter
      isSecondaryActionDisabled={isCreatingEvent || isUpdatingEvent}
      isPrimaryActionDisabled={shouldDisableActions}
      secondaryAction={onCloseDrawer}
      primaryAction={onSubmit}
      primaryActionLabel={actionLabel}
    />
  );

  return (
    <FormDrawer header={header} footer={footer}>
      <FormProvider {...form}>
        <StyledForm noValidate>
          <Stack spacing={3} mx={2} pb={2}>
            <Stack direction="column" justifyContent="center">
              <OnBehalfOfSelector
                label="Posting on behalf of"
                tag={onBehalfOf}
                onChange={onPOBOChanged}
                contentType={TagContentType.EVENT}
                sx={{ width: '20rem' }}
              />
              <Typography variant="body4" color={palette.Text.Caption} mt={1.5}>
                <b>Note</b>: your name will be displayed as the creator
              </Typography>
            </Stack>
            <Divider />
            <Typography variant="body1" fontWeight={500} color={palette.Text.Headline}>
              Basic Information
            </Typography>
            <FormTextField
              name="title"
              label="Event Title"
              autoFocus={!isNil(onBehalfOf)}
              inputProps={{
                maxLength: TITLE_CHARACTER_LIMIT,
              }}
            />
            <EventTypeSelector name="eventType" label="Event Type" />
            <PeopleSelector
              name="organizer"
              label="Organizer"
              limit={1}
              // Set logged in user as the default organizer
              selectedProfiles={[profile]}
            />
            <FeatureGuard features={[{ feature: FeaturesEnum.TAGGINGS }]}>
              <Box>
                <TagSelector
                  value={tags}
                  onChange={onTagsChanged}
                  disabledIds={onBehalfOf ? [onBehalfOf.id] : []}
                  contentType={TagContentType.EVENT}
                />
                <Box mt={1}>
                  <Typography variant="body4" color={palette.Text.Caption}>
                    <b>Note: </b>
                    {ORGANIZATION_EVENT_DRAWER_HELPER}
                  </Typography>
                </Box>
              </Box>
            </FeatureGuard>
            {onBehalfOf?.type === 'COMPANY' && (
              <VisibileToSelector
                name="visibleTo"
                label="Visible to"
                contentType={ContentType.ORGANIZATION_EVENTS}
                value={getValues().visibleTo}
              />
            )}
            <CXEditorField name="description" placeholder="Add Event Details" />
            <OrganizationEventLocationFields
              eventLocationType={organizationEvent?.eventLocationType}
              officeLocation={organizationEvent?.officeLocation}
              onLocationChange={addOfficeLocationAsTag}
            />
            <OrganizationEventDateTimeFields timezone={selectedTimezone} />
          </Stack>
        </StyledForm>
      </FormProvider>
    </FormDrawer>
  );
};

export default OrganizationEventDrawer;
