import React from 'react';
import { styled } from '@mui/material';

import { ReactComponent as OrgChartIconSVG } from 'common/svg-icons/orgChartIcon.svg';

const StyledOrgChartIcon = styled(OrgChartIconSVG, {
  shouldForwardProp: (prop) => {
    return prop !== 'isActive';
  },
})<{ isActive?: boolean }>(({ isActive, color, theme }) => ({
  fill: isActive ? theme.palette.Icons.Tertiary.IconFillPressed : color || 'currentcolor',
  rect: {
    fill: isActive ? theme.palette.Icons.Tertiary.IconFillPressed : 'none',
  },
  '&:hover': {
    fill: theme.palette.Icons.Tertiary.IconFillHover,
  },
}));

type OrgChartIconProps = {
  isActive?: boolean;
  color?: string;
};

const OrgChartIcon: React.FC<OrgChartIconProps> = ({ isActive, color }) => {
  return (
    <StyledOrgChartIcon
      isActive={isActive}
      onMouseDown={(e) => e.stopPropagation()}
      color={color}
    />
  );
};

export default OrgChartIcon;
