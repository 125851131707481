import { ContentType } from 'common/enum/ContentType.enum';
import { AutocompleteProps } from 'common/types/Form.type';
import { Organization } from 'common/types/Organization.type';
import FormAutocomplete from '../input/FormAutocomplete';
import useGetEntitledOrganizations from 'common/hooks/useGetEntitledOrganizations';
import VisibleToSuggestion from './VisibleToSuggestion';
import { Stack } from '../material';
import { useEffect, useState } from 'react';

const DEFAULT_NAME = 'visibleTo';

type VisibleToSelectorProps = Omit<AutocompleteProps, 'options' | 'onChange'> & {
  onChange?: (organization: Organization[]) => void;
  contentType: ContentType;
  preload?: boolean;
  readOnly?: boolean;
  limit?: number;
};

const VisibileToSelector: React.FC<VisibleToSelectorProps> = ({
  name,
  value,
  label,
  formFieldProps,
  contentType,
  onChange,
  readOnly = false,
  limit,
  ...entitledResourceProps
}) => {
  const { data, isLoading } = useGetEntitledOrganizations(contentType);

  const [selectedResources, setSelectedResources] = useState<Organization[]>(
    value ? (Array.isArray(value) ? value : [value]) : [],
  );
  const [suggestedResources, setSuggestedResources] = useState<Organization[]>([]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const selectedResourceIdsSet = new Set<string>(
      selectedResources.map((resource) => resource.id),
    );
    const remainingResources = data.filter(
      (resource: Organization) => !selectedResourceIdsSet.has(resource.id),
    );
    setSuggestedResources(remainingResources);
    onChange && onChange(selectedResources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResources.length, data?.length]);

  if (isLoading || !data) {
    return <></>;
  }

  return (
    <Stack spacing={1}>
      <FormAutocomplete
        name={name || DEFAULT_NAME}
        multiple
        loading={isLoading}
        limit={limit}
        label={label}
        options={data || []}
        getOptionLabel={(option) => option?.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={setSelectedResources}
        formFieldProps={{ ...formFieldProps }}
        value={selectedResources}
        customReadOnly={readOnly}
        baseInputProps={{ disabled: true }}
        minimum={1}
        {...entitledResourceProps}
      />
      {data && data.length && (
        <VisibleToSuggestion
          name={name || DEFAULT_NAME}
          selectedResources={selectedResources}
          resources={suggestedResources}
          onResourceSelected={setSelectedResources}
        />
      )}
    </Stack>
  );
};

export default VisibileToSelector;
