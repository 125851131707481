import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps, useTheme } from '@mui/material/styles';

import { Tooltip } from 'common/components/material';
import { useView } from 'common/hooks';
import { WinListTooltipContent } from './win-list-tooltip-content.component';

export const WinListTooltip: React.FC<{ sx?: SxProps }> = ({ sx = {} }) => {
  const { palette } = useTheme();
  const { isDesktopView } = useView();

  return (
    <Tooltip
      title={<WinListTooltipContent />}
      placement={isDesktopView ? 'right-end' : 'bottom'}
      maxWidth="600px"
      enterTouchDelay={0}
    >
      <InfoOutlinedIcon sx={{ color: palette.Icons.Primary.BgDefault, ...sx }} />
    </Tooltip>
  );
};
