import { styled } from '@mui/material';
import { Stack } from 'common/components/material';
import AppBoxShadows from 'common/theme/AppBoxShadows';

export const StyledExploreSelectorDropdown = styled(Stack)(({ theme }) => {
  return {
    top: '2.5rem',
    left: '50%',
    transform: 'translate(-50%, 0)',
    padding: '1rem 1rem 0.125rem 1rem',
    background: theme.palette.DropdownList.Bg,
    minWidth: '20.3125rem', // 325px
    position: 'absolute',
    borderRadius: '0.25rem',
    boxShadow: AppBoxShadows.shadow2,
    // set to 9999 to be above most things on the explore page.
    zIndex: 9999,
    height: '18rem', // 288px
    overflow: 'hidden',
  };
});
