import axios from 'common/utils/axios';
import { GetProfilePriorCompaniesRequestParams } from './types/company-name-request-params.type';
import { SearchParams } from 'features/search';

const companyNameApi = axios('company-names');

export const getCompanyNamesApi = (params: SearchParams) => {
  return companyNameApi.get('', { params });
};

export const getProfilePriorCompanyNamesApi = (
  params: GetProfilePriorCompaniesRequestParams,
) => {
  const { term, page = 1, size = 10, contentType, ...selectedSearchFilters } = params;
  return companyNameApi.get('profile-prior-companies', {
    params: {
      term,
      page,
      size,
      contentType,
      ...selectedSearchFilters,
    },
  });
};
