import { some } from 'lodash';

import { SOCIAL_LINKS, SOCIAL_LINKS_ALIAS } from './quick-links.constants';
import { QuickLink } from './types';

export const isSocialLink = (label: string) => {
  return SOCIAL_LINKS.includes(label) || !!SOCIAL_LINKS_ALIAS[label];
};

export const isLinkExist = (quickLinks: QuickLink[], defaultLabel: string, label: string) => {
  return some(quickLinks, ['label', label]) && defaultLabel !== label;
};

export const isPrefixCharAnEmoji = (label: string) => {
  const prefixChar = Array.from(label)[0];
  return /\p{Extended_Pictographic}/u.test(prefixChar);
};

export const splitLinkLabelChar = (linkLabel: string) => {
  const hasPrefixCharEmoji = isPrefixCharAnEmoji(linkLabel);
  const strArray = Array.from(linkLabel);
  let prefixChar = strArray[0];
  let label = (hasPrefixCharEmoji ? strArray.slice(1) : strArray).join('');
  // https://stackoverflow.com/questions/39059655/javascript-length-returning-1-rather-than-0
  // An edge case for certain emojis
  // After first character emoji has been removed, there's seemingly empty space with width = 1
  const hasInvisibleSeparator = label.charAt(0) === '️' && label.charAt(0).length > 0;
  prefixChar += hasInvisibleSeparator ? label.charAt(0) : '';
  label = (hasInvisibleSeparator ? label.substring(1) : label).trim();
  return { prefixChar, label, hasPrefixCharEmoji };
};
