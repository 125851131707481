import { Transition } from 'history';
import React from 'react';

import { Box, Button, Stack } from 'common/components/material';
import Card, { CardContent } from 'common/components/material/Card';
import { useBlocker, useDrawer, useView } from 'common/hooks';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import VerticalScrollableContainer from './VerticalScrollableContainer';

type EditFormContainerProps = {
  isDiscardDisabled?: boolean;
  isSubmitDisabled?: boolean;
  onDiscard: () => void;
  onSubmit: () => void;
  onCancel?: () => void;
  skipSubmitConfirmation?: boolean;
  confirmationSubtitle?: string;
  shouldBlockNavigation?: boolean;
  discardLabel?: string;
  isDirty?: boolean;
};

const EditFormContainer: React.FC<EditFormContainerProps> = ({
  children,
  isDiscardDisabled,
  isSubmitDisabled,
  onDiscard,
  onSubmit,
  skipSubmitConfirmation,
  confirmationSubtitle,
  shouldBlockNavigation,
  discardLabel,
  isDirty,
  onCancel,
}) => {
  const { openDrawer, closeDrawer } = useDrawer();
  const { isDesktopView } = useView();

  useBlocker((transition: Transition) => {
    openDiscardConfirmation(transition);
  }, shouldBlockNavigation);

  const openDiscardConfirmation = (transition?: Transition) => {
    const handleDiscard = () => {
      if (transition) {
        transition.retry();
      }
      onDiscard && onDiscard();
      closeDrawer(DrawerIdEnum.LEAVE_FORM);
    };

    openDrawer(DrawerIdEnum.LEAVE_FORM, {
      confirmButtonHandler: handleDiscard,
    });
  };

  const openSubmitConfirmation = () => {
    openDrawer(DrawerIdEnum.SUBMIT_FORM, {
      confirmButtonHandler: onConfirm,
      subtitle: confirmationSubtitle,
    });
  };

  const onConfirm = () => {
    closeDrawer(DrawerIdEnum.SUBMIT_FORM);
    onSubmit();
  };

  return (
    <Card
      className="full-width"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <VerticalScrollableContainer>{children}</VerticalScrollableContainer>
        <Box pt={isDesktopView ? 2 : 1}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={isDesktopView ? 'flex-end' : 'space-around'}
          >
            <Button
              btntype="tertiary"
              size="large"
              disabled={isDiscardDisabled}
              onClick={
                isDirty ? () => openDiscardConfirmation() : () => onCancel && onCancel()
              }
            >
              {discardLabel || 'Discard'}
            </Button>
            <Button
              btntype="primary"
              size="large"
              disabled={isSubmitDisabled}
              onClick={skipSubmitConfirmation ? onSubmit : openSubmitConfirmation}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EditFormContainer;
