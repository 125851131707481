import { styled } from '@mui/material';
import ListItemButton, { ListItemIcon } from 'common/components/material/ListItemButton';

export const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  ':hover': {
    background: theme.palette.LinkContainer.BgHover,
    '.MuiTypography-root': { color: theme.palette.LinkText.Active },
    '.MuiSvgIcon-root, .MuiListItemIcon-root': {
      color: theme.palette.Icons.Tertiary.IconFillHover,
    },
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ color }) => ({
  minWidth: 48,
  '.MuiSvgIcon-root': { color },
}));
