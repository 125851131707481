import React from 'react';

import { MenuItem } from 'common/components/material/MenuList';
import { FormTextFieldProps } from 'common/types/Form.type';
import FormTextField from './FormTextField';

type Option = {
  value: string;
  label: string;
};

type FormSelectProps = FormTextFieldProps & {
  items: Option[];
};

const FormSelect: React.FC<FormSelectProps> = ({ items, onChange, ...props }) => {
  return (
    <FormTextField select onChange={onChange} {...props}>
      {items.map((o: any) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </FormTextField>
  );
};

export default FormSelect;
