import React from 'react';

import { OverlayWrapper } from 'common/styles/overlay.styles';
import ColorPalette from 'common/theme/colorPalette';
import LinearProgress from 'common/components/material/LinearProgress';
import { Container, Stack, Typography } from '../material';

const PublishingProgress: React.FC = () => (
  <OverlayWrapper
    sx={{
      backgroundColor: ColorPalette.BLACK,
      opacity: '65%',
    }}
  >
    <Stack
      display={'flex'}
      spacing={1.5}
      direction={'column'}
      alignItems={'center'}
      width={'65%'}
    >
      <Typography variant="body2" fontWeight={500} color={ColorPalette.WHITE}>
        Publishing . . . . .
      </Typography>
      <Container>
        <LinearProgress color="info" />
      </Container>
    </Stack>
  </OverlayWrapper>
);

export default PublishingProgress;
