import React, { ForwardedRef } from 'react';
import { FieldName } from 'common/types/Form.type';
import { Controller, useFormContext } from 'react-hook-form';
import { CXCheckboxProps } from './cx-checkbox-props.type';
import CXCheckboxRenderer from './cx-checkbox-renderer.component';
import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/material';

const StyledLabel = styled(FormControlLabel)({
  marginLeft: 0,
});

// This component is a controlled checkbox and must be placed inside a FormProvider

const CXCheckbox = React.forwardRef(
  (props: CXCheckboxProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { control } = useFormContext();

    return (
      <StyledLabel
        control={
          <Controller
            control={control}
            name={props.name as FieldName}
            render={CXCheckboxRenderer(props, ref)}
          />
        }
        label={props.label}
      />
    );
  },
);

export default CXCheckbox;
