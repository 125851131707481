import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import { useDebounce } from 'common/hooks';
import { HierarchicalExplorerItemAction } from 'features/data-explorer';
import { ExplorerDataTypeEnum } from 'features/data-explorer/data-explorer.enum';
import { FILTER_EXPLORER_CONFIG } from 'features/data-explorer/filter-explorer.config';
import { useGetPaginatedExplorerData } from 'features/data-explorer/hooks/useGetPaginatedExplorerData';
import FilterListItem from 'features/explore/components/FilterListItem';
import InfiniteScrollContainer from 'features/org-root/components/InfiniteScrollContainer';
import { ActiveFilterMap, transformActiveFiltersMapToObject } from 'features/search';
import ExploreSelectorLoader from './explore-selector-loader.component';

export const ExploreSelectorPaginated: React.FC<{
  contentType: ExploreTabEnum;
  type: ExplorerDataTypeEnum;
  searchTerm?: string;
  onItemAction: (action: HierarchicalExplorerItemAction) => void;
  activeFilterMap?: ActiveFilterMap;
}> = ({ contentType, type, searchTerm, onItemAction, activeFilterMap }) => {
  const config = FILTER_EXPLORER_CONFIG[type];
  const { levels, excludeFilterTypes } = config;
  const levelConfig = levels?.[0] || {};
  const debouncedSearchTerm = useDebounce(searchTerm);

  const selectedSearchFilters = transformActiveFiltersMapToObject(
    activeFilterMap,
    excludeFilterTypes || [type],
  );

  return (
    <InfiniteScrollContainer
      key={type}
      LoaderComponent={ExploreSelectorLoader}
      infiniteApiHook={useGetPaginatedExplorerData}
      InfiniteListContainer={({ data = [] }) => {
        if (!data.length) {
          return <>No Result</>;
        }

        const dataList = levelConfig.select ? levelConfig.select(data) : data;
        return (
          <>
            {dataList.map((data: any) => (
              <FilterListItem
                key={data.id}
                data={data}
                dataType={type}
                activeFilterMap={activeFilterMap}
                labelKey={levelConfig.itemLabelKey}
                config={levelConfig}
                onItemAction={(itemAction) => onItemAction({ ...itemAction, type })}
                size="small"
              />
            ))}
          </>
        );
      }}
      apiParams={{
        config,
        type: type,
        term: debouncedSearchTerm,
        contentType: contentType || 'people',
        ...selectedSearchFilters,
      }}
      footerText=""
    />
  );
};
