export enum ContentType {
  QUICK_LINKS = 'QUICK_LINKS',
  POSTS = 'POSTS',
  COMPANY_UPDATES = 'COMPANY_UPDATES',
  OPPORTUNITIES = 'OPPORTUNITIES',
  PRAISES = 'PRAISES',
  ORGANIZATION_EVENTS = 'ORGANIZATION_EVENTS',
  WINS = 'WINS',
  WIN_LIST = 'WIN_LIST',
}
