import { UseQueryOptions } from 'common/types/Api.type';

import { useQuery, useSession } from 'common/hooks';
import { getL0ManagerIdApi } from '../profiles.service';

export const useGetL0ManagerId = (options: UseQueryOptions = {}) => {
  const user = useSession();
  const organizationId = user.organizationIdOnSession;
  const queryKey = [organizationId, 'l0-manager-id'];

  const queryResponse = useQuery(queryKey, getL0ManagerIdApi, {
    ...options,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data?.l0ManagerId,
  };
};
