import React from 'react';
import { Skeleton, Stack } from 'common/components/material';

const LinkTileWithAvatarLoader: React.FC = () => {
  return (
    <Stack spacing={1} direction="row" alignItems="center" px={1.5}>
      <Skeleton variant="circular" height="2rem" width="2rem" />
      <Stack justifyContent="center" width="60%">
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Stack>
    </Stack>
  );
};

export default LinkTileWithAvatarLoader;
