import { useCallback } from 'react';

import { useEvents } from 'common/hooks';
import queryClient from 'common/utils/QueryClient';
import { updateEndorsedSkill, deleteEndorsedSkill } from '../helpers/endorsed-skills';
import { SkillEndorsementEventData } from 'common/types/Event-Data.type';
import { SkillsResponse } from 'features/skills';

const updateProfileSkillsCache = (
  response: SkillEndorsementEventData,
  callback: Function,
) => {
  const profileId = response.profileId;
  const skillId = response.skillId;

  if (!profileId) {
    return;
  }

  const cacheKey = ['profile', 'skills', profileId];
  const skills = queryClient.getQueryData(cacheKey) as SkillsResponse;

  if (!skills) {
    return;
  }

  skills.data.endorsedSkills = callback(skills.data.endorsedSkills, skillId, response.data);

  queryClient.setQueryData(cacheKey, skills);
};

export const useSyncProfileSkillsCache = () => {
  useEvents<SkillEndorsementEventData>(
    'SkillEndorsement',
    useCallback((response) => {
      updateProfileSkillsCache(response, updateEndorsedSkill);
    }, []),
  );

  useEvents<SkillEndorsementEventData>(
    'SkillUnEndorsement',
    useCallback((response) => {
      updateProfileSkillsCache(response, deleteEndorsedSkill);
    }, []),
  );
};
