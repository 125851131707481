import React, { SyntheticEvent, useState } from 'react';
import { toLower } from 'lodash';

import { FormAutocomplete } from 'common/components/input';
import { useDeferredState } from 'common/hooks';
import { getNoSelectorOptionsText, getString } from 'common/utils/app.utils';
import {
  useGetWorkLocations,
  WorkLocation,
  WorkLocationSelectorProps,
} from 'features/static-data';

const WorkLocationSelector: React.FC<WorkLocationSelectorProps> = ({
  label,
  formFieldProps,
  value = null,
  preload,
  onChange,
  ...workLocationSelectorProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const [workLocation, setWorkLocation] = useDeferredState<WorkLocation | null>(value);
  const [initialized, setInitialized] = useState(false);

  const selectedLabel = getString(workLocation, 'location');
  const inputIsSelectedValue = selectedLabel === inputValue;

  const {
    data,
    isLoading: isLoadingWorkLocation,
    isSuccess: hasLoadedWorkLocation,
  } = useGetWorkLocations({
    enabled: preload || initialized,
  });

  return (
    <FormAutocomplete
      autoComplete
      loading={isLoadingWorkLocation}
      label={label}
      getOptionLabel={(option: WorkLocation) => getString(option, 'location', '')}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onInputChange={(e: SyntheticEvent, inputValue: string) => {
        setInputValue(inputValue);
      }}
      onChange={(value: WorkLocation) => {
        setInputValue('');
        setWorkLocation(value);
        onChange && onChange(value);
      }}
      options={data?.workLocations || []}
      filterOptions={(options = []) => {
        return inputValue && !inputIsSelectedValue
          ? options.filter((workLocation: WorkLocation) => {
              return toLower(workLocation.location).includes(toLower(inputValue));
            })
          : options;
      }}
      noOptionsText={getNoSelectorOptionsText({
        hasData: hasLoadedWorkLocation,
        isLoading: isLoadingWorkLocation,
        label,
        preload,
      })}
      formFieldProps={formFieldProps}
      value={workLocation}
      onFocus={() => {
        if (!initialized) {
          setInitialized(true);
        }
      }}
      showArrowIcon={true}
      {...workLocationSelectorProps}
    />
  );
};

export default WorkLocationSelector;
