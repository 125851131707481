import { useEffect, useState } from 'react';

const padZero = function (n: number) {
  return (n < 10 ? '0' + n : n);
};

const formatCountdown = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const displayedSeconds = Math.floor(seconds - minutes * 60);
  return minutes + ':' + padZero(displayedSeconds);
};

const useCountdown = (initialTime = 0) => {
  const [time, setTime] = useState(initialTime);
  const [countdownDisplay, setCountdownDisplay] = useState('0:00');

  useEffect(
    () => {
      const countdown = formatCountdown(time);
      setCountdownDisplay(countdown);

      if (!time) {
        return;
      }

      const timer = setTimeout(() => {
        setTime(time - 1);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    },
    [time]
  );

  return {
    countdownTime: time,
    countdownDisplay,
    setCountdownTime: setTime,
    setCountdownDisplay,
  }
}

export default useCountdown;
