import { kebabCase } from 'lodash';
import { CXChip } from 'common/components/cx-chip';
import { Stack, Typography } from 'common/components/material';
import { StyledButtonTab } from 'common/components/navigation/ButtonTab';

export const ExploreSelectorButtonComponent: React.FC<{
  value: string;
  label: string;
  onClick?: () => void;
  badgeLabel?: string;
  isActive: boolean;
}> = ({ value, label, onClick, badgeLabel, isActive }) => {
  return (
    <StyledButtonTab
      className={`${isActive ? 'button-tab is-active' : 'button-tab'}`}
      data-tab={value}
      data-testid={`explore-selector-${kebabCase(value)}-button`}
      onClick={onClick}
      sx={{ padding: '0 0.375rem', height: '1.875rem', borderRadius: '0.375rem' }}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {!!badgeLabel && <CXChip label={badgeLabel} sx={{ flexDirection: 'column' }} />}
        <Typography variant="body4" whiteSpace="nowrap" fontWeight={500}>
          {label}
        </Typography>
      </Stack>
    </StyledButtonTab>
  );
};
