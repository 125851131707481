import { styled } from '@mui/system';
import React from 'react';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import useTheme from '@mui/material/styles/useTheme';

import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import { Divider, Link, Stack, Typography } from 'common/components/material';
import { useDrawer, useView } from 'common/hooks';
import { Params } from 'common/hooks/useRouter';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { getTimeStampString } from 'common/utils/date-time.utils';
import {
  Notification,
  NotificationTypeEnum,
  StyledNotificationItemContainerProps,
} from 'features/notifications';
import { CXAvatar } from 'common/components/cx-avatar/cx-avatar';
import { DrawerIdEnum } from 'features/org-root/enums/drawer-id.enum';
import { FormattedPhrase } from 'common/components/formatted-phrase/formatted-phrase.component';
import { FEED_HEADER_SUBSTRING } from 'features/feeds/feeds.constants';

const StyledNotificationContainer = styled('div', {
  shouldForwardProp: (prop) => {
    return prop !== 'isMobileView';
  },
})<StyledNotificationItemContainerProps>(({ isMobileView }) => ({
  display: 'flex',
  flexDirection: isMobileView ? 'column' : 'row',
  justifyContent: 'space-between',
  minHeight: '4rem',
  cursor: 'pointer',
  alignItems: isMobileView ? 'left' : 'center',
  paddingRight: isMobileView ? '1rem' : 'unset',
}));

const UnreadIcon = styled('span')(({ theme }) => ({
  display: 'inline-block',
  height: 7,
  width: 7,
  minWidth: 7,
  backgroundColor: theme.palette.Container.Bg, // for some reason palette properties aren't available here
  borderRadius: '50%',
  marginRight: 7,
}));

const NotificationItem: React.FC<Notification> = ({
  type,
  photo,
  readDate,
  createdDate,
  title,
  description,
  targetId,
}) => {
  const { isMobileView } = useView();
  const { closeDrawer } = useDrawer();
  const { palette } = useTheme();
  const timestampString = getTimeStampString(createdDate, 'MMM D');

  let href = '';
  let params: Params = {};

  switch (type) {
    case NotificationTypeEnum.PRAISE:
    case NotificationTypeEnum.LIKE:
    case NotificationTypeEnum.COMMENT:
    case NotificationTypeEnum.OPPORTUNITY:
    case NotificationTypeEnum.ORGANIZATION_EVENT:
    case NotificationTypeEnum.POST:
    case NotificationTypeEnum.COMPANY_UPDATE:
    case NotificationTypeEnum.BOB:
    case NotificationTypeEnum.PROJECT:
      href = AppRoutesEnum.HOME_FEED;
      params = { feedId: targetId };
      break;
    case NotificationTypeEnum.ENDORSEMENT:
      href = AppRoutesEnum.PROFILE_SKILLS;
      params = { profileId: targetId };
      break;
  }

  const isStaticData = !!title?.find((phrase) => {
    const trimmedText = phrase.text.toLowerCase();
    return (
      trimmedText.includes(FEED_HEADER_SUBSTRING.COMPANY_UPDATE) ||
      trimmedText.includes(FEED_HEADER_SUBSTRING.ORGANIZATION_EVENT)
    );
  });

  const closeNotificationDrawer = () => {
    closeDrawer(DrawerIdEnum.NOTIFICATION);
  };

  return (
    <>
      <Link
        href={href}
        params={params}
        paddingLeft={1}
        onClick={closeNotificationDrawer}
        state={type === NotificationTypeEnum.COMMENT ? { autoFocusCommentBox: true } : {}}
      >
        <StyledNotificationContainer isMobileView={isMobileView} data-analytics-tag="notifications_list_item">
          <Stack direction="row" alignItems="center" ml={0}>
            <UnreadIcon sx={readDate ? {} : { backgroundColor: palette.Failure }} />
            <CXAvatar
              src={photo}
              sx={{ marginRight: '1rem' }}
              icon={isStaticData && ApartmentRoundedIcon}
            ></CXAvatar>
            <EllipsisText
              lineclamp={2}
              variant="body2"
              fontWeight={600}
              color={palette.Text.Headline}
              sx={{ verticalAlign: 'middle' }}
              title={description}
            >
              <FormattedPhrase content={title} />
              {description && (
                <Typography variant={'body3'} color={palette.Text.Headline}>
                  {': '}
                  {description}
                </Typography>
              )}
            </EllipsisText>
          </Stack>
          <Stack
            marginLeft={isMobileView ? '3.85rem' : 0}
            textAlign={isMobileView ? 'unset' : 'end'}
            minWidth="5.5rem"
          >
            <Typography
              sx={{ marginTop: isMobileView ? 1 : 0, color: palette.Text.SubtleText }}
              variant="body4"
            >
              {timestampString}
            </Typography>
          </Stack>
        </StyledNotificationContainer>
      </Link>
      <Divider />
    </>
  );
};

export default NotificationItem;
