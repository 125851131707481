import { PropsWithChildren } from 'react';
import { TreeView as MUITreeView } from '@mui/x-tree-view';

import { CollapseIcon, ExpandIcon } from '../styles/cx-tree-view.styles';
import { CXTreeViewProps } from '../types/cx-tree.types';
import CXTreeViewItemRenderer from './cx-tree-view-item-renderer.component';

function CXTreeView<T>({
  expandIcon = <ExpandIcon />,
  collapseIcon = <CollapseIcon />,
  data,
  ...props
}: PropsWithChildren<CXTreeViewProps<T>>) {
  return (
    <MUITreeView
      aria-label="tree view"
      defaultExpanded={['root']}
      defaultCollapseIcon={collapseIcon}
      defaultExpandIcon={expandIcon}
    >
      {data?.root?.children.map((nodeId: string) => {
        return (
          <CXTreeViewItemRenderer
            key={nodeId}
            nodeId={nodeId}
            data={data}
            {...props}
          ></CXTreeViewItemRenderer>
        );
      })}
    </MUITreeView>
  );
}

export default CXTreeView;
