import { AxiosError } from 'axios';
import * as _ from 'lodash';
import { AlertColor } from '@mui/material/Alert';

import { ErrorResponse, ValidationError } from 'common/types/Api.type';
import { useSnackbar } from 'common/hooks';

const DEFAULT_ERROR_MESSAGE = 'Looks like something went wrong';
// 503 Service Unavailable. Could also work for Internet connection issues.
const DEFAULT_ERROR_CODE = 503;

const createFallbackErrorResponse = (error: AxiosError): ErrorResponse => {
  return {
    errors: [],
    message: error.message || DEFAULT_ERROR_MESSAGE,
    statusCode: error.response?.status || DEFAULT_ERROR_CODE,
  };
};

const getSnackbarSeverity = (statusCode = DEFAULT_ERROR_CODE): AlertColor => {
  return statusCode < 500 ? 'warning' : 'error';
};

const getValidationErrorMessage = (errors: ValidationError[]) => {
  return _.join(_.flatten(_.map(errors, (error) => error.messages)), '; ');
};

// The error response should be the same we defined on the server
const formatError = (error?: AxiosError | null): ErrorResponse | undefined => {
  if (!error) {
    return;
  }
  return (error.response?.data || createFallbackErrorResponse(error)) as ErrorResponse;
};

const getErrorMessage = (error?: ErrorResponse) => {
  return _.isEmpty(error?.errors)
    ? _.get(error, 'message', DEFAULT_ERROR_MESSAGE)
    : getValidationErrorMessage(error?.errors || []);
};

const useApiErrorHandler = (
  onError?: any,
  showErrorSnackbar?: boolean,
  showValidationErrorSnackbar?: boolean,
  shouldShowErrorSnackbar?: (statusCode: number) => boolean,
) => {
  const { openSnackbar } = useSnackbar();
  const errorHandler = (error: AxiosError, data?: any, context?: any) => {
    const formattedError = formatError(error);

    if (onError) {
      onError(formattedError, data, context);
    }

    if (formattedError && (showValidationErrorSnackbar || showErrorSnackbar)) {
      if (shouldShowErrorSnackbar && !shouldShowErrorSnackbar(formattedError?.statusCode)) {
        return;
      }
      const errorMessage = getErrorMessage(formattedError);

      const severity = getSnackbarSeverity(formattedError?.statusCode);
      openSnackbar(errorMessage, severity);
    }
  };
  return {
    formatError,
    getErrorMessage,
    errorHandler,
  };
};

export default useApiErrorHandler;
