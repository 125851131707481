import { Box, styled } from '@mui/material';
import { Stack } from 'common/components/material';
import AddStaticDataButton from 'features/org-root/components/StaticData/AddStaticDataButton';

export const ProjectActionButton = styled(AddStaticDataButton)({
  padding: '0px 5px',
  borderRadius: '4px',
  alignSelf: 'center',
  flexWrap: 'nowrap',
});

export const ProjectItemHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '50px',
  paddingRight: theme.spacing(1),
}));

export const NonAccordionProjectHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  marginLeft: theme.spacing(4),
}));
