import { useTheme } from '@mui/material/styles';
import { ResponsiveHeaderBar } from 'common/components/header';
import { SplashScreen } from 'common/components/image';
import { CircularProgress, Stack } from 'common/components/material';
import { useSession } from 'common/hooks';
import React from 'react';

const PageLoader: React.FC = () => {
  const { account } = useSession();
  const { palette } = useTheme();

  if (!account) {
    return <SplashScreen />;
  }

  return (
    <Stack height="100%" bgcolor={palette.Background}>
      <ResponsiveHeaderBar isLoading={true} />
      <CircularProgress sx={{ alignSelf: 'center', marginTop: 20 }} size={100} />
    </Stack>
  );
};

export default PageLoader;
