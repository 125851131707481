import { isEmpty } from 'lodash';
import { useQuery } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { preLoginApi } from '../accounts.service';

export const usePreLogin = (
  params: {
    csrfToken?: string | null;
    email?: string;
    subUrl?: string;
    verificationCode?: string;
  } = {},
  options: UseQueryOptions = {},
) => {
  const { email, subUrl, verificationCode, csrfToken } = params;
  const queryKey = ['pre-login', csrfToken, subUrl || '-', email || '-'];

  const queryResponse = useQuery(
    queryKey,
    () =>
      preLoginApi(
        { email, subUrl, verificationCode },
        { headers: { 'x-csrf-token': csrfToken } },
      ),
    {
      showErrorSnackbar: false,
      enabled: !isEmpty(csrfToken) && (!isEmpty(email) || !isEmpty(subUrl)),
      ...options,
    },
  );

  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};
